/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-empty,
  no-param-reassign,
  no-restricted-syntax
*/
import sa from 'superagent';

/**
 * actually makes the requests to the server
 */

module.exports = function (ops) {
  return new Promise(((resolve, reject) => {

    const method = ops.method.toLowerCase();

    const r = sa[{
      delete: 'del',
    }[method] || method](ops.url);

    if (ops.form) {
      r.type('form');
    }

    if (ops.headers) {
      for (const key in ops.headers) {
        r.set(key, ops.headers[key]);
      }
    }

    if (ops.query) {
      r.query(ops.query);
    }

    if (ops.data) {
      r.send(ops.data);
    }

    r.end((err, res) => {

      if (err) {
        return reject(err);
      }

      res = res.text;

      try {
        res = JSON.parse(res);
      } catch (e) { }

      resolve(res);
    });
  }));
};
