// @flow
import * as React from 'react';
import { withStyle } from '../../style';
import style from './style';

// TODO: Flow doesn't seem to be picking up the default props when wrapped with
// a HOC...
type Props = {

    /**
     * If true, the content will be rendered
     */
    active: boolean,

    /**
     * Aligns the content vertically.  This only takes effect when the position
     * is 'left' or 'right'.
     */
    align?: 'top' | 'center' | 'bottom',

    children: React.Node,

    /**
     * Aligns the content horizontally.  This only takes effect when the
     * position is 'top' or 'bottom'.
     *
     */
    justify?: 'left' | 'center' | 'right',

    /**
     * The element to position the content around.
     */
    anchor: React.Node,

    /**
     * The position of the content around the anchor.
     */
    position?: 'top' | 'bottom' | 'left' | 'right',

    styles: {
        root: string,
        content: string,
    },

    /**
     * The theme of the content.  The theme defines any baseline style for the
     * content wrapper.  Right now there's just "light" but in the future this
     * here for future additions.
     */
    theme?: 'light'
};

/**
 * Tethered Component
 *
 * A tethered component is a component that positions itself around another
 * element on the page.  Things like tooltips and dropdown menus can be tethered
 * compoents.  This component takes in an `anchor` and renders the provided
 * children around the anchor in different locations depending on the props.
 */
export function TetheredComponent(props: Props) {
  const {
    active,
    anchor,
    children,
    styles,
  } = props;

  return (
    <div className={styles.root}>
      {anchor}
      {active
                    && <div className={styles.content}>
                        {children}
                    </div>}
    </div>
  );
}

TetheredComponent.defaultProps = {
  align: 'top',
  justify: 'left',
  position: 'bottom',
  theme: 'light',
};

export default withStyle(style)(TetheredComponent);
