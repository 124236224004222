import PropTypes from 'prop-types';
import { propTypes } from 'styled-system';

const string = PropTypes.string;
const number = PropTypes.number;
const stringOrNumber = PropTypes.oneOfType([string, number]);
const maybeStringArray = PropTypes.oneOfType([
  string,
  PropTypes.arrayOf(string),
]);
const maybeNumberArray = PropTypes.oneOfType([
  number,
  PropTypes.arrayOf(number),
]);
const maybeStringOrNumberArray = PropTypes.oneOfType([
  stringOrNumber,
  PropTypes.arrayOf(stringOrNumber),
]);

export default {

  // styled-system
  ...propTypes.alignItems,
  ...propTypes.borderColor,
  ...propTypes.borderWidth,
  ...propTypes.color,
  ...propTypes.flex,
  ...propTypes.flexDirection,
  ...propTypes.flexWrap,
  ...propTypes.fontSize,
  ...propTypes.hover,
  ...propTypes.justifyContent,
  ...propTypes.space,
  ...propTypes.textAlign,
  ...propTypes.width,

  // custom
  borderColor: maybeStringArray,
  display: maybeStringArray,
  fontWeight: maybeStringOrNumberArray,
  order: maybeNumberArray,
  position: maybeStringArray,
  textTransform: maybeStringArray,
  verticalAlign: maybeStringArray,

  // TODO: Styled-system should be including this but it may be a bug...
  textAlign: maybeStringArray,
};
