/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-string-refs,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';
import ContactUsModal from './contact-us-modal';

const ContactUsButton = createReactClass({
  propTypes: {
    timePeriod: PropTypes.string,
    pricingSource: PropTypes.string,
    buttonText: PropTypes.string,
    modalHeaderText: PropTypes.string,
    onSuccess: PropTypes.func,
    buttonAttrs: PropTypes.object,
  },
  componentDidMount() {

    if (window.location.search.indexOf('showContactUs=1') > -1) {
      this.handleClick();
    }

  },

  getInitialState() {
    return {
      timePeriod: 'Y',
    };
  },

  handleClick(e) {
    const attachPoint = HfReactHelper.getRevealAttachPoint('contact_us_modal');
    ReactDOM.render(
      (
        <ContactUsModal
          isApi={this.props.isApi}
          timePeriod={this.state.timePeriod}
          pricingSource={this.props.pricingSource}
          headerText={this.props.modalHeaderText}
          onSuccess={this.props.onSuccess}
        >
        </ContactUsModal>), attachPoint,
    );

    // Pass the event to a click handler if it was provided.
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  },
  render() {
    const text = this.props.buttonText !== undefined ? this.props.buttonText : 'Contact Us';
    return <Button
      ref='contactUsButton'
      buttonId='contactUsButton'
      buttonText={text}
      buttonHoverColor={HfReactHelper.isHelloSign() ? 'cerulean' : 'goldenrod'}
      onClick={this.handleClick}
      buttonAttrs={this.props.buttonAttrs}
    >
    </Button>;
  },
});


module.exports = ContactUsButton;
