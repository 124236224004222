import React from 'react';
import compose from 'lodash/function/compose';
import cx from 'classnames';
import propTypes from './util/prop-types';
import { withStyle } from '../../style';
import filterProps from './util/filter-props';
import style from './style';

const validRuleName = (str) => str.replace(/[()]/g, '-');
const isDev = process.env.NODE_ENV === 'development';

/**
 * UI Block higher-order-component.  This function will return a component that
 * can handle any of the defined style attributes as props.  The style props
 * that are accepted can be found in the `lib/prop-types.js` file.  You can use
 * this function to turn any component into a component that can accept style
 * props.  It will provide the `className` prop will all of the correct styles
 * applied.
 *
 * @example
 * function Box(props) {
 *      const { is = 'div', ...rest } = props;
 *      return React.createElement(props.is, rest)
 * }
 *
 * export default asBlock(Box);
 *
 * // later...
 * // style props coverted to the appropriate CSS classes and applied
 * accordingly
 * <Box m2 padding={2}>My Stuff</Box>
 */
export function asBlock(BlockComponent) {
  function UiBlock(props) {
    const {
      styleDebug, styles, className, ...rest
    } = props;
    const debugName = isDev && styleDebug && validRuleName(`${styleDebug}-${styles.box}`);
    const cn = cx(styles.box, debugName, className);
    const filteredProps = filterProps(rest);

    return <BlockComponent {...filteredProps} className={cn} />;
  }

  UiBlock.displayName = `UiBlock(${BlockComponent.displayName
        || BlockComponent.name})`;
  UiBlock.propTypes = propTypes;

  return UiBlock;
}

export default compose(withStyle(style), asBlock);
