/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  max-len,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';

class EmbeddedTweets extends React.Component {
    static displayName = 'Embedded Tweets';

    static defaultProps = {
      embeddedTweetsHeaderText: 'HAPPY TWEETS FROM HAPPY USERS',
    };

    componentDidMount() {
      const node = ReactDOM.findDOMNode(this);
      const twitterScript = document.createElement('script');
      twitterScript.src = '//platform.twitter.com/widgets.js';
      twitterScript.charset = 'utf-8';
      twitterScript.async = true;
      node.appendChild(twitterScript);
    }

    render() {
      return <div>
        <div className='small-12 small-centered small-text-center columns'>
          <h6 className='m-marketing-page--subheader-small c-ashen' style={{ fontSize: 18 }}>
            {this.props.embeddedTweetsHeaderText}
          </h6>
        </div>
        <div className='small-12 text-center m-marketing-page--embedded-tweets'>
          <div className='m-marketing-page--embedded-tweet'>
            <blockquote className='twitter-tweet' lang='en'>
              <p lang='en' dir='ltr'>
                            A few of my favorite things that have up leveled my business into the modern age.
                <a href='https://twitter.com/AcuitySchedulin'>@AcuitySchedulin</a>,
                <a href='https://twitter.com/freshbooks'>@freshbooks</a>,
                <a href='https://twitter.com/HelloSign'>@HelloSign</a>,
                <a href='https://twitter.com/BraidCreative'>@BraidCreative</a>
              </p>
                        &mdash; Jolene Park (@JolenePark)
              <a href='https://twitter.com/JolenePark/status/644242654952210432'>September 16, 2015</a>
            </blockquote>
          </div>
          <div className='m-marketing-page--embedded-tweet'>
            <blockquote className='twitter-tweet' lang='en'>
              <p lang='en' dir='ltr'>
                <a href='https://twitter.com/HelloSign'>@HelloSign</a>
                            has finally helped me get my contract game on track!
                            Now I&#39;ve got your signature.
                <a href='http://t.co/EewBKaXZCX'>http://t.co/EewBKaXZCX</a>
                <a href='https://twitter.com/hashtag/business?src=hash'>#business</a>
              </p>
                        &mdash; Melissa Carter (@wcornercreative)
              <a href='https://twitter.com/wcornercreative/status/638107795896844290'>August 30, 2015</a>
            </blockquote>
          </div>
          <div className='m-marketing-page--embedded-tweet'>
            <blockquote className='twitter-tweet' lang='en'>
              <p lang='en' dir='ltr'>
                <a href='https://twitter.com/HelloSign'>@HelloSign</a> You&#39;re brilliant.
              </p>
                        &mdash; Gloria Steinberg (@iprookie)
              <a href='https://twitter.com/iprookie/status/641273263092572160'>September 8, 2015</a>
            </blockquote>
          </div>
          <div className='m-marketing-page--embedded-tweet'>
            <blockquote className='twitter-tweet' lang='en'>
              <p lang='en' dir='ltr'>
                            Fast, Secure, and Legally Binding eSignatures for Business
                <a href='https://t.co/yZG4FoIFkR'>https://t.co/yZG4FoIFkR</a>
              </p>
                        &mdash; John Wojewoda (@D_digital_dog)
              <a href='https://twitter.com/D_digital_dog/status/637269988160749568'>August 28, 2015</a>
            </blockquote>
          </div>
        </div>
      </div>;
    }
}

module.exports = EmbeddedTweets;
