/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-use-before-define
*/
// @flow
import { config, combineRules } from '../../style';

/**
 * If an element has many variations you can combine rules
 * See the TetheredComponent for more info
 * For this simple example we are using just a single rootRule
 */

// Returns the type style rules (e.g. alert backgroundColor is red)
const typeRule = ({ type = 'success' }) => {
  const result = {
    backgroundColor: getBkgByType(type),
  };
  return result;
};

// Based on the type of label we get the color / e.g. (success = green, alert = red)
const getBkgByType = (type) => {
  return config.colors[type];
};

/**
 * Generating the basic styles for the box component
 * based on the current theme and some settings like scale
 */
const boxStyle = ({ theme }) => {
  return {
    display: 'inline-block',
    borderRadius: '4px',
    fontSize: theme.fontSizes[0],
    lineHeight: 1,
    color: '#FFFFFF',
    padding: '4px 8px',
  };
};

// Combining the rules creates a combination of all styles into one css class
const mainRule = combineRules(
  typeRule,
  boxStyle,
);

export default {
  main: mainRule,
};
