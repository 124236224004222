/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const SwapOutButton = createReactClass({

  propTypes: {
    initialButton: PropTypes.element.isRequired,
    swapInChildren: PropTypes.element.isRequired,
  },

  getInitialState() {
    return { isSwapped: false };
  },

  doSwap() {
    this.setState({ isSwapped: true });
  },

  render() {
    let children;
    if (this.state.isSwapped) {
      children = this.props.swapInChildren;
    } else {
      children = React.cloneElement(this.props.initialButton, {
        onClick: this.doSwap,
      });
    }
    return (<span>{ children }</span>);
  },

});
module.exports = SwapOutButton;
