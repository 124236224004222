import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import EntityAccessListItem from './entity-access-list-item';

/**
 * Filters and sorts the list of entities to show within
 * the access list.
 *
 * The sorting algorithm is a bit on the complex side:
 * Basically, the position of an entity within the access
 * list depends on a number of variables, namely whether
 * its access was modified by using the entity chooser
 * search field (rather than "undo"), and how recently its
 * access was modified. Entities whose access has been
 * modified via the entity chooser and which have been
 * search more recently appear closer to the top.
 *
 * @param {Entity[]} entities
 * @returns {Entity[]}
 */
function getScopedEntities(entities) {
  return entities
    .filter((entity) => (
      entity.isAdmin
        || entity.isOwner
        || entity.hasExplicitTemplateAccess
        || entity.isAccessDirty
    ))
    .sort((a, b) => {
      if (a.isAccessDirty) {
        if (b.isAccessDirty) {
          if (a.isAccessDirtyFromSearch) {
            if (b.isAccessDirtyFromSearch) {
              if (a.dateLastSearched > b.dateLastSearched) {
                return -1;
              } else if (a.dateLastSearched < b.dateLastSearched) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return -1;
            }
          } else {
            return b.isAccessDirtyFromSearch ? 1 : 0;
          }
        } else {
          return a.isAccessDirtyFromSearch ? -1 : 0;
        }
      } else {
        return b.isAccessDirtyFromSearch ? 1 : 0;
      }
    });
}

/**
 * Renders the team access modal access list.
 *
 * @param {Object} props
 * @param {Entity[]} props.entities
 * @param {?string} props.recentEntity
 * @param {boolean} props.isEditable
 * @param {Function} props.grantAccess
 * @param {Function} props.revokeAccess
 * @param {Function} props.resetAccess
 * @returns {JSX.Element}
 */
function EntityAccessList(props) {
  const {
    entities,
    recentEntity,
    isEditable,
    grantAccess,
    revokeAccess,
    resetAccess,
  } = props;
  const entitiesInScope = React.useMemo(() => getScopedEntities(entities), [entities]);

  return (
    <div className="team-access-modal__entity-access-list">
      <p className="team-access-modal__entity-access-list-header-text">
        <FormattedMessage
          id=""
          description="input header text of team access modal that shows the list of email that has access to this template"
          defaultMessage="Who has access" />
      </p>
      <div className="team-access-modal__entity-access-list-container">
        <ul>
          {entitiesInScope.map((entity) => {
            const {
              guid,
              isAccessDirtyFromSearch,
              hasExplicitTemplateAccess,
              willAccessBeGranted,
              willAccessBeRevoked,
            } = entity;

            const isRecent = recentEntity === guid;
            const key = `${entity.guid}${isRecent ? `-${Math.floor(Math.random() * 100000)}` : ''}`;

            const classes = classnames('team-access-modal__entity-access-list-item', {
              'team-access-modal__entity-access-list-item--is-recent': (isRecent
                && ((isAccessDirtyFromSearch && hasExplicitTemplateAccess && !willAccessBeRevoked)
                  || (!hasExplicitTemplateAccess && willAccessBeGranted))),
            });

            return (
              <li className={classes} key={key}>
                <EntityAccessListItem
                  entity={entity}
                  isEditable={isEditable}
                  grantAccess={grantAccess}
                  revokeAccess={revokeAccess}
                  resetAccess={resetAccess} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default EntityAccessList;
