
/**
  * Convert key-value pair to query params string
  *
  * @param {Object} obj
  * @returns {string}
  */
// eslint-disable-next-line import/prefer-default-export
export function queryParams(obj) {
  const search = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value
          .map((v) => `${key}=${encodeURIComponent(v)}`)
          .join('&');
      }

      if (value != null) {
        return `${key}=${encodeURIComponent(value)}`;
      }
      return null;
    })
    .filter((tmp) => tmp != null)
    .join('&');

  return (search.length > 0) ? `?${search}` : '';
}
