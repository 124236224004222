/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require
*/
import BaseCollection from 'common/models/base/collection';
import FaxLine from './fax-line';

module.exports = BaseCollection.extend({

  /**
    */

  mixins: [
    require('common/models/mixins/collection'),
  ],

  /**
    */

  apiEndpointAlias: 'faxLines',

  /**
    */

  createModel(properties) {
    properties.notifier = this.notifier;
    properties.user = this.user;
    return FaxLine.create(properties);
  },

  /**
    */

  addFaxLine(properties) {
    return this.createModel(properties).insert();
  },
});
