/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  global-require,
  no-console,
  no-return-assign,
  no-var,
  react/no-string-refs
*/
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'hellosign/components/hf-react-helper';
import createReactClass from 'create-react-class';
import ActionBar from './action-bar';
import CONSTANTS from './constants';

import {
  CommonTabProps as CommonTabPropTypes,
  OnSignatureData,
} from './prop-types';

function ChangeFontButton(props) {
  const changeLinkClasses = {
    'm-sign-modal--tabs--type--output--change-link': true,
    'm-button': true,
    'bg-white': true,
    'border-1-light-grey': true,
    'hbg-white': true,
    'hc-light-grey': true,
    'c-light-grey': true,
  };
  const spinner = <img src={require('./loading-spinner-16.gif')}
    className='m-sign-modal--tabs--type--output--spinner' />;

  const changeLink = <a className={cx(changeLinkClasses)} onClick={props.handleClick}>
    <FormattedMessage id='signModal.typed.changeFonts.linkText' />
  </a>;
  return <div className='m-sign-modal--tabs--type--output'>
    {changeLink}
    {props.showSpinner ? spinner : null}
  </div>;
}

const TypeInTab = createReactClass({

  propTypes: {
    ...CommonTabPropTypes,

    /**
         * Takes a callback that will be called when the network is checked and
         * verified to be working.
         */
    onCheckNetworkHealth: PropTypes.func,
    onSignatureData: OnSignatureData,
    initialValue: PropTypes.string.isRequired,
  },


  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      canInsertEverywhere: true,
      onCheckNetworkHealth: (cb) => cb(),
    };
  },

  getInitialState() {
    const { initialValue } = this.props;

    return {
      hasValue: !!initialValue,
      isSignature: (this.props.type === CONSTANTS.TYPE_SIGNATURE),
      isInitials: (this.props.type === CONSTANTS.TYPE_INITIALS),
      selectedFontIndex: 2,
      showSpinner: false,
      textValue: initialValue,
    };
  },

  componentDidMount() {

    this.adjustInputWidth();

    // Load the initial font family
    const n = (this.state.selectedFontIndex % CONSTANTS.CURSIVE_FONTS.length);
    const chosenFamily = CONSTANTS.CURSIVE_FONTS[n];
    this.loadFontFamily(chosenFamily);
  },

  componentDidUpdate() {
    this.adjustInputWidth();
  },


  //  ----  BEHAVIOR  -----------------------------------

  loadFontFamily(fontFamily, okCallback) {

    const onActive = function () {
      if (okCallback) {
        okCallback();
      }
    };

    var onInactive = function (stop) {

      if (stop === true) {
        console.error(`Could not load font ${fontFamily} at all`);
        return;
      }

      if (process.env.NODE_ENV !== 'test') {
        console.error(`Could not get font ${fontFamily} from Google, loading from our servers instead`);
      }

      // Load from CDN
      const cdnFontUrl = HfReactHelper.urlHelper(`/fonts/${fontFamily.toLowerCase().replace(/\s/g, '-')}.css`, 'hellosign');
      WebFont.load({
        custom: {
          families: [fontFamily],
          urls: [cdnFontUrl],
        },
        active: onActive,
        fontinactive: onInactive.bind(this, true),
      });
    };

    // Load font from google
    if (window.WebFont) {
      WebFont.load({
        google: {
          families: [fontFamily],
        },
        active: onActive,
        fontinactive: onInactive,
      });
    } else if (okCallback) {
      okCallback();
    }
  },

  selectNextFontFamily() {

    const self = this;
    const n = ((this.state.selectedFontIndex + 1) % CONSTANTS.CURSIVE_FONTS.length);
    const chosenFamily = CONSTANTS.CURSIVE_FONTS[n];

    this.setState({
      showSpinner: true,
    });

    const loadFont = function () {
      self.loadFontFamily(chosenFamily, () => {
        self.setState({
          showSpinner: false,
          selectedFontIndex: n, // Will trigger the new css font-family to change
        });
      });
    };

    this.props.onCheckNetworkHealth(loadFont);
  },

  handleInput(evt) {
    const v = evt.target.value;
    this.setState({
      textValue: v,
      hasValue: (v.replace(/\s/g, '').length > 0),
    });
  },

  insertSignature(insertEverywhere) {

    const text = HfReactHelper.trim(this.state.textValue);

    const signatureData = {
      type_code: this.props.type,
      create_type_code: CONSTANTS.SIGNATURE_TYPE_TYPED,
      signature: {
        text,
        font_family: CONSTANTS.CURSIVE_FONTS[this.state.selectedFontIndex],
      },
    };


    return this.props.onSignatureData(signatureData, insertEverywhere);
  },

  //  ----  HELPERS  ------------------------------------

  adjustInputWidth() {
    const root = this.node;
    let w = '100%';
    if (this.props.isMobile) {
      w = (this.props.deviceOrientation === CONSTANTS.ORIENTATION_LANDSCAPE) ? `${$('.m-sign-modal--tabs--type', root).width() - 140}px` : '100%'; // 140 = size of the btn
    }
    $('#typedSignatureInput', root).css('width', w);
  },

  isChinese() {
    const intl = this.props.intl;
    const locale = intl ? intl.locale : null;
    return locale === 'zh-CN';
  },


  //  ----  RENDERING  ----------------------------------

  render() {
    const { actionBarProps } = this.props;

    const fontFamily = CONSTANTS.CURSIVE_FONTS[this.state.selectedFontIndex];
    const inputStyle = {
      fontFamily,
    };
    const inputClasses = {
      'm-sign-modal--tabs--type--input': true,
    };
    const classes = {
      'm-sign-modal--tabs--type': true,
      'has-value': this.state.hasValue,
    };
    const containerClasses = {
      'm-sign-modal--tabs--tab': true,
      'is-selected': this.props.isSelected,
    };

    containerClasses[CONSTANTS.TAB_TYPE] = true;
    inputClasses[`font-${fontFamily.replace(/\s/g, '-')}`] = true;

    const changeFontButton = <ChangeFontButton {...this.props}
      data-test-ref='change-button'
      handleClick={this.selectNextFontFamily}
      showSpinner={this.state.showSpinner} />;


    return <div className={cx(containerClasses)} ref={(node) => this.node = node}>
      <span className='m-sign-modal--tabs--tab-title'>
        <FormattedMessage
          id='signModal.typed.title'
          values={{ type: this.props.type }} />
      </span>
      <div className={cx(classes)}>
        <div className='m-sign-modal--tabs--content' ref={this.props.captureTabContentRef}>
          <input
            data-test-ref='input'
            id='typedSignatureInput'
            type='text'
            className={cx(inputClasses)}
            style={inputStyle}
            value={this.state.textValue}
            onChange={this.handleInput}
            onKeyUp={this.handleInput}
            data-qa-ref="signing-modal--type-in-field"
            placeholder={this.props.intl.formatMessage({ id: `signModal.typed.placeholder.${this.props.type}` })} />
          {!this.isChinese() ? changeFontButton : null}
        </div>
      </div>
      <ActionBar
        {...actionBarProps}
        data-test-ref='action-bar'
        disableButtons={!this.state.hasValue}
        onInsert={this.insertSignature} />

    </div>;
  },
});

module.exports = TypeInTab;
