/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-restricted-syntax,
  no-script-url,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import asZendeskActivator from 'hellosign/common/zendesk/components/activator';
import { Box, Flex } from 'hello-ui/blocks';
import TetherToggle from 'hello-ui/tether-toggle';
import createReactClass from 'create-react-class';
import StyleProvider from 'hello-ui/style';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const MenuLink = (props) => <Box
  className='m-app-footer--menu-item'
  is='a'
  display='block'
  textAlign='left'
  px={3}
  py={2}
  {...props} />;

function HelpLink({ onActivate }) {
  return <Box p={3} className='m-app-footer--links--link' onClick={onActivate}>
    <Box is='a' href='javascript:;' id='footer_help' color='grey' hover={{ color: 'ashen' }}>
      <FormattedMessage id="" description="webapp, homepage footer help link" defaultMessage="Help" />
    </Box>
  </Box>;
}

const ZendeskActivator = asZendeskActivator(HelpLink);

// In-app footer
const AppFooter = createReactClass({
  propTypes: {
    siteCode: PropTypes.string.isRequired, // S or F
    termsURL: PropTypes.string.isRequired,
    privacyURL: PropTypes.string.isRequired,
  },

  SOCIAL_NAMES: ['linkedin', 'fb', 'twitter'],
  SOCIAL_URLS: {
    twitter: 'https://twitter.com/hellosign',
    fb: 'https://www.facebook.com/hellosignapp',
    linkedin: 'https://www.linkedin.com/company/hellosign',
  },

  getInitialState() {
    return {
      showProductMenu: false,
      showFinePrintMenu: false,
    };
  },

  getDefaultProps() {
    return {
      siteCode: HfReactHelper.HfSites.SITE_CODE_HS,
    };
  },

  componentDidMount() {
    const root = $(ReactDOM.findDOMNode(this));
    $('.product-link', root).hover(this.toggleProductMenu);
    $('.fine-print-link', root).hover(this.toggleFinePrintMenu);
  },

  toggleProductMenu() {
    const show = !this.state.showProductMenu;
    this.setState({
      showProductMenu: show,
    });
  },

  toggleFinePrintMenu() {
    const show = !this.state.showFinePrintMenu;
    this.setState({
      showFinePrintMenu: show,
    });
  },

  render() {
    const { responsive } = this.props;
    const cmsSubDomain = 'www';
    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    const isHf = HfReactHelper.HfSites.isHelloFax(this.props.siteCode);
    const pricingUrl = HfReactHelper.urlHelper('info/pricing', site);
    const homeUrl = HfReactHelper.urlHelper('home/index', site);
    const faqUrl = isHf ? 'http://faq.hellosign.com/hc/en-us/categories/200353237-HelloFax-Help' : 'http://faq.hellosign.com';
    const hfHomeUrl = HfReactHelper.urlHelper('home/bridge/to/F', HfReactHelper.HfSites.SITE_CODE_HS);
    const hsHomeUrl = HfReactHelper.urlHelper('home/bridge/to/S', HfReactHelper.HfSites.SITE_CODE_HF);
    const bridgeUrl = isHf ? hsHomeUrl : hfHomeUrl;

    const socialImageUrls = {
      twitter: require('./twitter@2x.png'),
      fb: require('./fb@2x.png'),
      linkedin: require('./linkedin@2x.png'),
    };

    // Social links
    let name;
    const social = [];
    for (const i in this.SOCIAL_NAMES) {
      name = this.SOCIAL_NAMES[i];
      social.push(
        <Box px={[3, 0]} py={3} ml={[0, 1]} key={`social-${i}`} className='m-app-footer--social--icon'>
          <Box is='a' href={this.SOCIAL_URLS[name]} target='_blank' rel='noopener noreferrer' display='block'>
            <Box is='img' verticalAlign='middle' className='m-app-footer--social--icon--img' src={socialImageUrls[name]} />
          </Box>
        </Box>,
      );
    }

    // Product menu
    const items = [
      isHf ? undefined : (<MenuLink key={0} href={HfReactHelper.urlHelper('api', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer product link dd api" defaultMessage="API" /></MenuLink>),
      isHf ? undefined : (<MenuLink key={1} href={HfReactHelper.urlHelper('gmail', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer product link dd gmail" defaultMessage="Gmail" /></MenuLink>),
      (<MenuLink key={2} href={HfReactHelper.urlHelper('googledocs', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer product link dd google docs" defaultMessage="Google Docs" /></MenuLink>),
      (<MenuLink key={3} href={HfReactHelper.urlHelper('googledrive', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer product link dd google drive" defaultMessage="Google Drive" /></MenuLink>),
      isHf ? undefined : (<MenuLink key={4} href={HfReactHelper.urlHelper('info/mobile', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer product link dd mobile" defaultMessage="Mobile" /></MenuLink>),
      (<MenuLink key={5} href={bridgeUrl} target='_blank' rel='noopener noreferrer'>
        <FormattedMessage
          id=""
          description="webapp, homepage footer product link dd API"
          defaultMessage="Hello{isHf, select, true {Sign} other {Fax} }"
          values={{
            isHf,
          }} />
      </MenuLink>),
    ];

    // Fine print menu
    const finePrintItems = [
      (<MenuLink key={0} href={HfReactHelper.urlHelper('info/security', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer fine print link dd security" defaultMessage="Security" /></MenuLink>),
      isHf ? undefined : (<MenuLink key={1} href={HfReactHelper.urlHelper('info/legal', site, cmsSubDomain)}><FormattedMessage id="" description="webapp, homepage footer fine print link dd legal" defaultMessage="Legal" /></MenuLink>),
      (<MenuLink key={2} href={this.props.privacyURL}><FormattedMessage id="" description="webapp, homepage footer fine print link dd privacy" defaultMessage="Privacy" /></MenuLink>),
      (<MenuLink key={3} href={this.props.termsURL}><FormattedMessage id="" description="webapp, homepage footer fine print link dd terms" defaultMessage="Terms" /></MenuLink>),
    ];

    return (
      <StyleProvider><Flex
        className='m-app-footer--container'
        flexDirection={responsive ? ['column', 'row'] : 'row'}
        justify={responsive ? [null, 'space-between'] : 'space-between'}
        mb={3}
      >
        <Flex
          textAlign={responsive ? ['center', 'left'] : 'center'}
          flexDirection={responsive ? ['column', 'row'] : 'row'}
          align='center'
        >
          <Box
            px={responsive ? [3, 0] : 0}
            py={responsive ? [3, 0] : 0}
            className='m-app-footer--logo'
          >
            <Box is='a' href={homeUrl} display='block'>
              <Box is='img' className='_footer__logo' src={isHf ? require('./hf_icon@2x.png') : require('./hs_icon@2x.png')} verticalAlign='middle' />
            </Box>
          </Box>
          <Flex justify='center' wrap className='_footer__links'>
            <Box p={3} className='m-app-footer--links--link'>
              <Box is='a' href={pricingUrl} color='grey' hover={{ color: 'ashen' }}>
                <FormattedMessage id="" description="webapp, homepage footer pricing link" defaultMessage="Pricing" />
              </Box>
            </Box>
            <Box p={3} className='m-app-footer--links--link product-link'>
              <TetherToggle
                anchor={
                  <Box is='a' color='grey' hover={{ color: 'ashen' }}>
                    <FormattedMessage id="" description="webapp, homepage footer products link" defaultMessage="Products" />
                  </Box>
                }
                position='top'
                justify='center'
              >
                { items }
              </TetherToggle>
            </Box>
            <Box p={3} className='m-app-footer--links--link fine-print-link'>
              <TetherToggle
                anchor={
                  <Box is='a' color='grey' hover={{ color: 'ashen' }}>
                    <FormattedMessage id="" description="webapp, homepage footer fine print link" defaultMessage="Fine print" />
                  </Box>
                }
                position='top'
                justify='center'
              >
                { finePrintItems }
              </TetherToggle>
            </Box>
            <ZendeskActivator />
            <Box p={3} className='m-app-footer--links--link'>
              <Box is='a' href={faqUrl} target='_blank' rel='noopener noreferrer' color='grey' hover={{ color: 'a' }}>
                <FormattedMessage id="" description="webapp, homepage footer faq link" defaultMessage="FAQ" />
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Flex justify='center' className='_footer__social m-app-footer--social'>
          { social }
        </Flex>
      </Flex></StyleProvider>
    );
  },

});


module.exports = AppFooter;
