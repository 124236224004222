// @flow
import { combineRules } from 'fela';
import { connect, createComponent, withTheme } from 'react-fela';
import Provider from './provider';

export {
  combineRules, createComponent, withTheme, Provider, connect as withStyle,
};
export { default as config } from './config';
export { default as defaultTheme } from './theme';
export { default as createRenderer } from './create-renderer';

export default Provider;
