import React from 'react';
import './loader-spinner.scss';
import loadingSpinner from './assets/loading-spinner.gif';

export default class LoaderSpinner extends React.Component {
  render() {
    return (
      <div>
        <img src={loadingSpinner}
          className="loader-spinner" />
      </div>
    );
  }
}
