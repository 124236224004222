import qs from 'qs';
import superagent from 'superagent';

const actions = ({ getState, setState }) => {

  /**
   *
   *
   * @param {Object} obj
   * @returns {void}
   * @public
   */
  const updateFormOptions = (obj) => {
    setState((state) => ({
      ...state,
      form: {
        ...state.form,
        ...obj,
      },
    }));
  };

  /**
   *
   *
   * @param {Object} obj
   * @returns {void}
   * @public
   */
  const hydrateStore = (obj) => {
    setState((state) => ({
      ...state,
      ...obj,
    }));
  };

  /**
   *
   *
   * @returns {void}
   * @private
   */
  const getSampleDocumentRequestStart = () => {
    setState((state) => ({
      ...state,
      isGettingSampleDocument: true,
      getSampleDocumentErrorMsg: null,
      form: {
        ...state.form,
        clientID: '',
        signURL: '',
      },
    }));
  };

  /**
   *
   *
   * @returns {void}
   * @private
   */
  const getSampleDocumentRequestFinish = () => {
    setState((state) => ({
      ...state,
      isGettingSampleDocument: false,
    }));
  };

  /**
   *
   *
   * @param {Response} response
   * @returns {void}
   * @private
   */
  const getSampleDocumentRequestSuccess = ({ body }) => {
    setState((state) => ({
      ...state,
      isSampleDocument: true,
      hasUsedSampleDocument: true,
      getSampleDocumentErrorMsg: null,
      form: {
        ...state.form,
        clientID: body.clientId,
        signURL: body.embeddedUrl,
      },
    }));
  };

  /**
   *
   *
   * @param {Response} response
   * @returns {void}
   * @private
   */
  const getSampleDocumentRequestFail = (response) => {
    let errMsg;

    switch (response.status) {
      case 429: {
        errMsg = 'Too many requests. Please wait and try again!';
        break;
      }
      default: {
        errMsg = 'There was an error fetching a sample document. Sorry!';
      }
    }

    setState((state) => ({
      ...state,
      getSampleDocumentErrorMsg: errMsg,
    }));
  };

  const getSampleDocumentResponse = (err, response) => {
    getSampleDocumentRequestFinish();

    if (err) {
      getSampleDocumentRequestFail(response);
    } else {
      getSampleDocumentRequestSuccess(response);
    }
  };

  /**
   *
   *
   * @returns {void}
   * @private
   */
  const getSampleDocumentRequest = () => {
    const { csrfToken, getSampleURL } = getState();

    const data = qs.stringify({
      csrf_token: csrfToken,
    });

    superagent
      .post(getSampleURL)
      .type('form')
      .set('X-Requested-With', 'XMLHttpRequest')
      .send(data)
      .on('request', () => {
        getSampleDocumentRequestStart();
      })
      .end(getSampleDocumentResponse);
  };

  /**
   *
   *
   * @returns {void}
   * @public
   */
  const getSampleDocument = () => {
    getSampleDocumentRequest();
  };

  /**
   *
   *
   * @param {boolean} [force]
   * @returns {void}
   * @public
   */
  const toggleAdvancedOptions = (force) => {
    setState((state) => {
      const showAdvancedOptions = typeof force !== 'undefined' ? force : !state.showAdvancedOptions;

      return {
        ...state,
        showAdvancedOptions,
      };
    });
  };

  /**
   *
   *
   * @returns {void}
   * @public
   */
  const resetEmbedded = () => {
    setState((state) => ({
      isSampleDocument: false,
      form: {
        ...state.form,
        clientID: state.isSampleDocument ? '' : state.form.clientID,
        signURL: state.isSampleDocument ? '' : state.form.signURL,
      },
    }));
  };

  const launchSampleEmbeddedDocument = () => {
    setState((state) => ({
      ...state,
      hasLaunchedSampleDocument: true,
    }));
  };

  return {
    hydrateStore,
    updateFormOptions,
    getSampleDocument,
    toggleAdvancedOptions,
    resetEmbedded,
    launchSampleEmbeddedDocument,
  };
};

export default actions;
