/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
require('../../scss/style.scss');

require('common/utils/log-uncaught-promises');
require('common/logger/track-logger');

const Application = require('./application');

// for server-side rendering (lib/vendor/react-server) (CC)
global.Application = Application;
global.app = new Application();

// Accessing 'window' breaks node.js server-side rendering.
// We MUST use 'typeof' to check for its existence. -Chris
if (typeof window !== 'undefined') {
  window.app = global.app;
}

exports.components = require('./components');
global.HfReactHelper = require('common/utils/hf-react-helper');
global.Validation = require('common/validation');
global.React = require('react');
global.ReactDOM = require('react-dom');

// hack to expose HS components to the react server (CC)
global.HSComponents = exports.components;
require('../jquery-hsIntl/docuploader-hsIntl');
