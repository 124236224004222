/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import createReactClass from 'create-react-class';
import Button from '../../../common/components/button';
import SignupOrLoginButton from './signup-or-login-button';

module.exports = createReactClass({
  displayName: 'SEO CTA Buttons',

  signupOrLoginButtonProps() {
    return {
      classNames: 'm-marketing-page--button',
      styleAttributes: 'width: 274px',
      buttonText: this.props.signupButtonText,
    };
  },

  secondButtonProps() {
    return {
      buttonText: this.props.secondButtonText,
      buttonLink: this.props.secondButtonUrl,
      buttonColor: 'plume',
      buttonTextColor: 'goldenrod',
      buttonTextUppercase: false,
      buttonBorderColor: 'goldenrod',
      buttonHoverColor: 'goldenrod',
      buttonHoverTextColor: 'white',
    };
  },

  render() {
    const props = this.props;
    return <div>
      <div className='columns small-12 small-centered small-text-center'>
        <div className='l-padding-t-40 l-padding-b-15'>
          <h2 className='m-marketing-page--get-started'>{props.title}</h2>
          <h4 className='m-marketing-page--subheader' style={{ maxWidth: 1100 }}>{props.subtitle}</h4>
        </div>
      </div>
      <div className='row l-padding-b-30'>
        <div className='small-12 columns text-center'>
          <div className='m-marketing-page--button-inline'>
            <SignupOrLoginButton {...this.signupOrLoginButtonProps()} {...props} />
          </div>
          <div className='m-marketing-page--button-inline' id='set-up-a-demo-link'>
            <Button {...this.secondButtonProps()} {...props} />
          </div>
        </div>
      </div>
    </div>;
  },
});
