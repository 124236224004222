/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import './field.scss';

/**
 * Form Field
 *
 * This is a low-level component meant to display a label and form control
 * combination.
 *
 * @example
 * import { Field } from 'common/components/forms';
 * import Input from 'common/components/input';
 *
 * <Field label='Email Address'>
 *      <Input {...someProps} />
 * </Field>
 */
export function FormField(props) {
  const { label } = props;

  return <div className='m-field'>
    {label && <label className='m-field--label'>{label}</label>}
    {props.children}
  </div>;
}

FormField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default FormField;
