/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  react/no-unescaped-entities
*/
import './success-test-drive-modal.scss';
import React, { Component } from 'react';
import FixedModal from 'common/components/fixed-modal';
import DotsLoader from 'signer/components/common/loader';
import testDriveIconSrc from './test_drive_illo-01.svg';


export default class SuccessTestDriveModal extends Component {

  componentDidMount() {
    const timeout = 4000; // 4 seconds
    let redirectUrl = '/info/chooseFreeOrEnterpriseSignupPlan';
    redirectUrl += '?enterprise=1';
    setTimeout(() => {
      window.location = redirectUrl;
    }, timeout);
  }

  render() {
    return (
      <FixedModal width={400}>
        <div className='m-success-test-drive-modal'>
          <div className='m-success-test-drive-modal--header'>
            <h2>
                            Thanks! We'll be in touch shortly.
            </h2>
            <h1>
                            In the meantime, feel free to take us for a test drive!
            </h1>
            <div className='loader'>
              <DotsLoader />
            </div>
          </div>
          <img src={testDriveIconSrc} />
        </div>
      </FixedModal>
    );
  }
}
