/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  import/no-extraneous-dependencies,
  no-redeclare,
  no-script-url,
  no-var,
  vars-on-top
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import LogInTrigger from '../../auth/login-trigger';

// TODO: Is there a way to extend the TopBarMenuLink component so that we don't
// have to duplicate the class designations?

const LogInButton = createReactClass({

  propTypes: {
    googleSignInClientId: PropTypes.string.isRequired,
    useWhite: PropTypes.bool,
    useBlack: PropTypes.bool,
    isMobile: PropTypes.bool,
    isCompact: PropTypes.bool,
    emailAddress: PropTypes.string,
    loginUrl: PropTypes.string,
    useRegularLink: PropTypes.bool,
    headerText: PropTypes.string,
    recaptchaPublicKey: PropTypes.string,
    displayCaptcha: PropTypes.bool,
    className: PropTypes.string,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    loginForm: PropTypes.object.isRequired, // Serialized sfForm
  },

  getInitialState() {
    return {
      useWhite: false,
    };
  },

  getDefaultProps() {
    return {
      headerText: 'Welcome back',
    };
  },

  render() {
    let classSet = classNames({
      'm-link-list--link': !this.props.isMobile,
      'm-link-list--log-in': !this.props.isMobile,
      'm-link-list--link-break-2': !this.props.isMobile,
      'm-mobile-menu--signin-link': this.props.isMobile,
      'is-compact': !this.props.isMobile && this.props.isCompact,
      'c-white': this.props.useWhite,
      'hc-warm-chinchilla': this.props.useWhite,
      'c-ashen': this.props.useBlack || !this.props.useWhite,
      'hc-cerulean': !this.props.useWhite,
    });

    if (this.props.className) {
      classSet += ` ${this.props.className}`;
    }

    const trigger = (
      <div className={'m-link-list--link--container'}>
        <a className={classSet} href={this.props.useRegularLink ? this.props.loginUrl : 'javascript:;'} tabIndex='-1'>Log In</a>
      </div>
    );

    if (this.props.useRegularLink) {
      var returnValue = trigger;
    } else {
      var returnValue = (
        <LogInTrigger
          trigger={trigger}
          header={this.props.headerText}
          buttonText='Sign In'
          recaptchaPublicKey={this.props.recaptchaPublicKey}
          displayCaptcha={this.props.displayCaptcha}
          googleSignInClientId={this.props.googleSignInClientId}
          emailAddress={this.props.emailAddress}
          loginUrl={this.props.loginUrl}
          recoverPasswordForm={this.props.recoverPasswordForm}
          loginForm={this.props.loginForm}
        >
        </LogInTrigger>
      );
    }
    return returnValue;
  },
});


module.exports = LogInButton;
