/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';

import BoatIcon from './assets/boat_icon.svg';
import CarIcon from './assets/car_icon.svg';
import ScooterIcon from './assets/scooter_icon.svg';

import './team-size-select.scss';

export default function TeamSizeSelect(params) {

  const baseClass = 'team-size-level';
  const selectedClass = ' team-size-level--selected';

  const individualLevel = (
    <div
      className={params.selected === 'individual' ? baseClass + selectedClass : baseClass}
      onClick={() => params.onTeamSizeSelect('individual')}
      data-qa-ref="choose-plan-individual"
    >
      <img className="team-size-level__image" src={ScooterIcon} />
      <div className='team-size-level__name'>
          Individual
      </div>
    </div>
  );

  const smallTeamLevel = (
    <div
      className={params.selected === 'small-team' ? baseClass + selectedClass : baseClass}
      onClick={() => params.onTeamSizeSelect('small-team')}
      data-qa-ref="choose-plan-small"
    >
      <img className="team-size-level__image" src={CarIcon} />
      <div className="team-size-level__name">
          Small team
      </div>
    </div>
  );

  const largeTeamLevel = (
    <div
      className={params.selected === 'large-team' ? baseClass + selectedClass : baseClass}
      onClick={() => params.onTeamSizeSelect('large-team')}
    >
      <img className="team-size-level__image" src={BoatIcon} />
      <div className="team-size-level__name">
          Large team
      </div>
    </div>
  );

  return (
    <div className={`team-size-select${params.selected.length ? ' team-size-select--dirty' : ''}`}>
      {individualLevel}
      {smallTeamLevel}
      {largeTeamLevel}
    </div>
  );
}
