import * as teamsAPI from 'hellospa/common/api/teams';
import * as usersAPI from 'hellospa/common/api/users';
import { getAllEntities } from 'hellospa/common/utils/api';
import { makeEndpointRequest } from 'hellospa/common/utils/endpoint';

export default function adminActions() {

  /**
   * Invites users in bulk via a CSV file.
   *
   * @async
   * @param {File} file
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {boolean} [opts.confirmPaidSeatCountIncrease]
   * @returns {void}
   */
  async function adminBulkInvite(file, csrfToken, opts) {
    await makeEndpointRequest(usersAPI.bulkInvite, [file, csrfToken, opts]);
  }

  /**
   * Cancels a user invite.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} emailAddress
   * @param {string} teamGuid
   * @param {string} csrfToken
   * @returns {void}
   */
  async function adminCancelInvite(emailAddress, teamGuid, csrfToken) {
    await makeEndpointRequest(usersAPI.cancelInvite, [emailAddress, teamGuid, csrfToken]);
  }

  /**
   * Creates a new team.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} teamName
   * @param {string} parentGuid
   * @param {string} csrfToken
   * @returns {void}
   */
  async function adminCreateTeam(teamName, parentGuid, csrfToken) {
    await makeEndpointRequest(teamsAPI.createTeam, [teamName, parentGuid, csrfToken]);
  }

  /**
   * Deletes a subteam.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {string} csrfToken
   * @returns {void}
   */
  async function adminDeleteTeam(guid, csrfToken) {
    await makeEndpointRequest(teamsAPI.deleteTeam, [guid, csrfToken]);
  }

  /**
   * Edits the information for the team with the given
   * GUID.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {string} [opts.name]
   * @param {string} [opts.parentGuid]
   * @returns {void}
   */
  async function adminEditTeam(guid, csrfToken, opts = {}) {
    await makeEndpointRequest(teamsAPI.editTeam, [guid, csrfToken, opts]);
  }

  /**
   * Edits the information for the user account with the
   * given GUID.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {Object} [opts]
   * @param {string} [opts.recipientGuid]
   * @param {string} [opts.role]
   * @param {string} [opts.teamGuid]
   * @returns {void}
   */
  async function adminEditUser(guid, csrfToken, opts = {}) {
    await makeEndpointRequest(usersAPI.editUser, [guid, csrfToken, opts]);
  }

  /**
   * Gets all eligible parent teams.
   *
   * @async
   * @throws if request is not successful.
   * @returns {TeamsAPI~Entity[]}
   */
  async function adminGetParentTeams() {
    const responseData = await makeEndpointRequest(teamsAPI.getParentTeams);

    // return responseData.data.teams;
    return responseData.data;
  }

  /**
   * Gets the user's subscription's root team.
   *
   * @async
   * @throws if request is not successful.
   * @returns {TeamsAPI~Team}
   */
  async function adminGetRootTeam() {
    const responseData = await makeEndpointRequest(teamsAPI.getRootTeam);

    return responseData.data;
  }

  /**
   * Gets a team within the user's subscription.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @returns {TeamsAPI~Team}
   */
  async function adminGetTeam(guid) {
    const responseData = await makeEndpointRequest(teamsAPI.getTeam, [guid]);

    return responseData.data;
  }

  /**
   * Gets all users who can receive documents, templates,
   * API apps, etc. from a user with the given guid.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @returns {UsersAPI~BasicUser[]}
   */
  async function adminGetTransferableUsers(guid) {
    const responseData = await makeEndpointRequest(usersAPI.getTransferableUsers, [guid]);

    return responseData.data;
  }

  /**
   * Gets all members within the current user's
   * subscription.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @returns {UsersAPI~User}
   */
  async function adminGetUser(guid) {
    const { data } = await makeEndpointRequest(usersAPI.getUser, [guid]);

    return data;
  }

  /**
   * @typedef {Object} AdminActions~TeamGeneralSettingsObj
   * @property {string} company_name
   * @property {string} custom_tagline
   * @property {number} industry_id
   * @property {string} custom_logo_file_path
   */

  /**
   * Gets the general settings for the user's team.
   *
   * @async
   * @throws if request is not successful
   * @returns {AdminActions~TeamGeneralSettingsObj}
   */
  async function adminGetTeamGeneralSettings() {
    const { data } = await makeEndpointRequest(teamsAPI.getTeamGeneralSettings);

    return data;
  }

  /**
   * @typedef {Object} AdminActions~TeamSecuritySettingsObj
   * @property {string} ssoEndpoint
   * @property {string} idpCert
   * @property {string} idpEntity
   * @property {boolean} optionAdminLoginPw
   * @property {boolean} optionJit
   * @property {boolean} optionAllowOverprovision
   * @property {boolean} multifactorAuthSms
   * @property {boolean} multifactorAuthApp
   */

  /**
   * Gets the security settings for the user's team.
   *
   * @async
   * @throws if request is not successful.
   * @returns {AdminActions~TeamSecuritySettingsObj}
   */
  async function adminGetTeamSecuritySettings() {
    const { data } = await makeEndpointRequest(teamsAPI.getTeamSecuritySettings);

    return data;
  }

  /**
   * @typedef {Object} AdminActions~TeamSignatureRequestSettingsObj
   * @property {boolean} customSigningRedirectEnabled
   * @property {string} customSigningRedirectUrl
   * @property {string} dateFormat
   * @property {string} everyoneHasSignedEmail
   * @property {boolean} isSignatureRemindersEnabled
   * @property {string} lockedSelfSignMessage
   * @property {string} lockedSelfSignTitle
   * @property {string} lockedSignatureRequestMessage
   * @property {string} lockedSignatureRequestTitle
   * @property {string} requestEmailFrom
   * @property {string} requestEmailSignature
   * @property {boolean} shouldEnableInPersonSigning
   * @property {boolean} shouldEnableTamperProof
   * @property {boolean} shouldOfferSignerAccessCode
   * @property {string} tzCode
   */

  /**
   * @typedef {Object} AdminActions~TeamDocumentsTemplatesSettingsObj
   * @property {boolean} lockTeamTemplateCreation
   */

  /**
   * Gets the signature request settings for the user's team.
   *
   * @async
   * @throws if request is not successful.
   * @returns {AdminActions~TeamSignatureRequestSettingsObj}
   */
  async function adminGetTeamSignatureRequestSettings() {
    const { data } = await makeEndpointRequest(teamsAPI.getTeamSignatureRequestSettings);

    return data;
  }

  /**
   * Gets the documents and template settings for the user's team.
   *
   * @async
   * @throws if request is not successful.
   * @returns {AdminActions~TeamDocumentsTemplatesSettingsObj}
   */
  async function adminGetTeamDocumentsTemplatesSettings() {
    const { data } = await makeEndpointRequest(teamsAPI.getTeamDocumentsTemplatesSettings);

    return data;
  }

  /**
   * @typedef {Object} AdminActions~ListInvitesObj
   * @param {usersAPI~Invite[]} invites
   * @param {number} numInvites
   * @param {number} numPerPage
   * @param {number} pageSize
   */

  /**
   * Gets a paginated list of invited users within the
   * user's subscription.
   *
   * @async
   * @throws if request is not successful.
   * @param {number} [page=1]
   * @param {number} [pageSize=50]
   * @param {string} [team]
   * @param {boolean} [recursive]
   * @returns {AdminActions~ListInvitesObj}
   */
  async function adminListInvites(page = 1, pageSize = 50, team, recursive) {
    const responseData = await makeEndpointRequest(usersAPI.listInvites, [
      page,
      pageSize,
      team,
      recursive,
    ]);

    return {
      invites: responseData.data.invites,
      pageSize: responseData.pageSize,
      numPerPage: responseData.numPerPage,
      numInvites: responseData.data.numInvites,
    };
  }

  /**
   * @typedef {Object} AdminActions~ListTeamsObj
   * @param {TeamsAPI~Team[]} teams
   * @param {number} numTeams
   * @param {number} numPerPage
   * @param {number} pageSize
   */

  /**
   * Gets all subteams within the current user's
   * subscription.
   *
   * @async
   * @throws if request is not successfull.
   * @param {number} [page=1]
   * @param {number} [pageSize=50]
   * @param {string} [team]
   * @returns {AdminActions~ListTeamsObj}
   */
  async function adminListSubteams(page = 1, pageSize = 50, team) {
    const responseData = await makeEndpointRequest(teamsAPI.listSubteams, [page, pageSize, team]);

    return {
      teams: responseData.data.teams,
      pageSize: responseData.pageSize,
      numPerPage: responseData.numPerPage,
      numTeams: responseData.data.numTeams,
    };
  }

  /**
   * Gets all teams, including the root team, within the
   * current user's subscription.
   *
   * @async
   * @throws if request is not successfull.
   * @param {number} [page=1]
   * @param {number} [pageSize=50]
   * @param {string} [team]
   * @returns {AdminActions~ListTeamsObj}
   */
  async function adminListTeams(page = 1, pageSize = 50, team) {
    const responseData = await makeEndpointRequest(teamsAPI.listTeams, [page, pageSize, team]);

    return {
      teams: responseData.data.teams,
      pageSize: responseData.pageSize,
      numPages: responseData.numPages,
      numPerPage: responseData.numPerPage,
      numTeams: responseData.data.numTeams,
    };
  }

  /**
   * Gets a list of all teams within a user's subscription.
   *
   * @async
   * @throws if request is not successful.
   * @returns {TeamsAPI~Team[]}
   */
  async function adminGetAllTeams() {
    return getAllEntities(async (page) => {
      const { teams, numPages } = await adminListTeams(page, 200);

      return [teams, numPages];
    });
  }

  /**
   * @typedef {Object} AdminActions~PaginatedObj
   * @property {number} pageSize
   * @property {number} numPerPage
   */

  /**
   * @typedef {AdminActions~PaginatedObj} AdminActions~ListUsers
   * @param {number} numUsers
   * @param {UsersAPI~User[]} users
   */

  /**
   * Gets all members within the current user's
   * subscription, or within a team with the given GUID if
   * specified.
   *
   * @async
   * @throws if request is not successful.
   * @param {number} [page=1]
   * @param {number} [pageSize=50]
   * @param {?string} [team=null]
   * @param {?boolean} [recursive=null]
   * @returns {AdminActions~ListUsers}
   */
  async function adminListUsers(page = 1, pageSize = 50, team = null, recursive = null) {
    const responseData = await makeEndpointRequest(usersAPI.listUsers, [
      page,
      pageSize,
      team,
      recursive,
    ]);

    return {
      users: responseData.data.users,
      pageSize: responseData.pageSize,
      numPages: responseData.numPages,
      numPerPage: responseData.numPerPage,
      numUsers: responseData.data.numUsers,
    };
  }

  /**
   * Gets all users within the given team.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} team
   * @returns {UsersAPI~User[]}
   */
  async function adminGetAllUsersWithinTeam(team) {
    return getAllEntities(async (page) => {
      const { users, numPages } = await adminListUsers(page, 20, team);

      return [users, numPages];
    });
  }

  /**
   * Gets reports for pretty charts
   *
   * @async
   * @throws if request is not successful.
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {string} [opts.metric_type]
   * @returns {void}
   */
  async function adminGetGraphReport(csrfToken, opts) {
    const responseData = await makeEndpointRequest(teamsAPI.getGraphReport, [csrfToken, opts]);
    return responseData.data;
  }

  /**
   * @typedef {AdminActions~PaginatedObj} AdminActions~ListUserTemplates
   * @param {number} numTemplates
   * @param {UsersAPI~Template[]} templates
   */

  /**
   * Gets all templates available to the user (owned or
   * shared).
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {number} [page=1]
   * @param {number} [pageSize=50]
   * @returns {AdminActions~ListUserTemplates}
   */
  async function adminListUserTemplates(guid, page = 1, pageSize = 50) {
    const responseData = await makeEndpointRequest(usersAPI.listUserTemplates, [
      guid,
      page,
      pageSize,
    ]);

    return {
      templates: responseData.data.templates,
      pageSize: responseData.pageSize,
      numPerPage: responseData.numPerPage,
      numTemplates: responseData.data.numTemplates,
    };
  }

  /**
   * Locks the user account with the given GUID.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {string} csrfToken
   * @returns {void}
   */
  async function adminLockUser(guid, csrfToken) {
    await makeEndpointRequest(usersAPI.lockUser, [guid, csrfToken]);
  }

  /**
   * Removes a user from the org and either converts them
   * to a free user or deletes the account entirely.
   *
   * @param {string} guid
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {number} [opts.delete]
   * @param {string} [opts.recipientGuid]
   */
  async function adminRemoveUser(guid, csrfToken, opts) {
    await makeEndpointRequest(usersAPI.removeUser, [guid, csrfToken, opts]);
  }

  /**
   * Resets the password for the user account with the
   * given GUID.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {string} csrfToken
   * @returns {void}
   */
  async function adminResetPassword(guid, csrfToken) {
    await makeEndpointRequest(usersAPI.resetPassword, [guid, csrfToken]);
  }

  /**
   * Initiates a compliance and/or usage report for a subscription.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {number} [opts.compliance]
   * @param {number} [opts.usage]
   * @returns {void}
   */
  async function adminRunReport(csrfToken, opts) {
    await makeEndpointRequest(teamsAPI.runReport, [csrfToken, opts]);
  }

  /**
   * Invites a user to the subscription.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} emailAddress
   * @param {string} csrfToken
   * @param {Object} [opts]
   * @param {string} [opts.role]
   * @param {string} [opts.teamGuid]
   * @param {number} [opts.confirmPaidSeatIncrease]
   * @returns {void}
   */
  async function adminSendInvite(emailAddress, csrfToken, opts = {}) {
    await makeEndpointRequest(usersAPI.sendInvite, [emailAddress, csrfToken, opts]);
  }

  /**
   * Unlocks the user account with the given GUID.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} guid
   * @param {string} csrfToken
   * @returns {void}
   */
  async function adminUnlockUser(guid, csrfToken) {
    await makeEndpointRequest(usersAPI.unlockUser, [guid, csrfToken]);
  }

  /**
   * Updates team general settings.
   *
   * NOTE: Delete custom logo by passing '' as `custom_logo_file_path`.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} csrfToken
   * @param {AdminActions~TeamGeneralSettingsObj} opts
   * @returns {void}
   */
  async function adminUpdateTeamGeneralSettings(csrfToken, opts) {
    await makeEndpointRequest(teamsAPI.updateTeamGeneralSettings, [csrfToken, opts]);
  }

  /**
   * Updates team security general settings.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} csrfToken
   * @param {AdminActions~TeamSecuritySettingsObj} opts
   * @returns {void}
   */
  async function adminUpdateTeamSecuritySettings(csrfToken, opts) {
    await makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
  }

  /**
   * Updates team general settings.
   *
   * NOTE: Delete custom logo by passing '' as `custom_logo_file_path`.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} csrfToken
   * @param {AdminActions~TeamSignatureRequestSettingsObj} opts
   * @returns {void}
   */
  async function adminUpdateTeamSignatureRequestSettings(csrfToken, opts) {
    await makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
  }

  /**
   * Updates team general settings.
   *
   * NOTE: Delete custom logo by passing '' as `custom_logo_file_path`.
   *
   * @async
   * @throws if request is not successful.
   * @param {string} csrfToken
   * @param {AdminActions~TeamGeneralSettingsObj} opts
   * @returns {void}
   */
  async function adminUpdateDocumentsTemplatesRequestSettings(csrfToken, opts) {
    await makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
  }


  return {
    adminBulkInvite,
    adminCancelInvite,
    adminCreateTeam,
    adminDeleteTeam,
    adminEditTeam,
    adminEditUser,
    adminGetGraphReport,
    adminGetParentTeams,
    adminGetRootTeam,
    adminGetTeam,
    adminGetTeamDocumentsTemplatesSettings,
    adminGetTeamGeneralSettings,
    adminGetTeamSecuritySettings,
    adminGetTeamSignatureRequestSettings,
    adminGetTransferableUsers,
    adminGetUser,
    adminListInvites,
    adminListSubteams,
    adminListTeams,
    adminGetAllTeams,
    adminListUsers,
    adminGetAllUsersWithinTeam,
    adminListUserTemplates,
    adminLockUser,
    adminRemoveUser,
    adminResetPassword,
    adminRunReport,
    adminSendInvite,
    adminUnlockUser,
    adminUpdateDocumentsTemplatesRequestSettings,
    adminUpdateTeamGeneralSettings,
    adminUpdateTeamSecuritySettings,
    adminUpdateTeamSignatureRequestSettings,
  };
}
