/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

/*
* A utility class that allows you to place elements directly on the body of the document, instead of placed
*   normally in the DOM. Useful for Modals, Popups, and other elements that need absolute screen positioning.
* See http://joecritchley.svbtle.com/portals-in-reactjs for the Portal concept
*/

const Portal = createReactClass({

  propTypes: {
    rerenderOnUpdate: PropTypes.bool, // For dynamic components
    className: PropTypes.string, // Optionally, creates a div to with specified class to wrap child components
  },

  getDefaultProps() {
    return {
      rerenderOnUpdate: false,
      className: null,
    };
  },

  componentDidMount() {
    this.element = document.createElement('div');
    document.body.appendChild(this.element);
    this._renderLayer();
  },

  // FIXME: This should be filled in to adapt to property changes
  componentWillReceiveProps() {
  },


  componentDidUpdate() {
    if (this.props.rerenderOnUpdate) {
      this._renderLayer();
    }
  },


  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.element);
    document.body.removeChild(this.element);
  },


  _renderLayer() {
    if (this.props.className) {
      ReactDOM.render(<div className={this.props.className}>{ this.props.children }</div>, this.element);
    } else {
      ReactDOM.render(this.props.children, this.element);
    }
  },

  // Placeholder to satisfy render
  render() {
    return null;
  },

});

module.exports = Portal;
