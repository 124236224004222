// eslint-disable-next-line import/prefer-default-export
export async function makeEndpointRequest(endpoint, args = []) {
  const responseData = await endpoint(...args);

  if (!responseData.success) {
    throw new Error(responseData.error);
  }

  return responseData;
}
