import React from 'react';

import Button from 'common/components/button';
import { withAppContext } from '../../contexts/app';

class Basic extends React.PureComponent {

  onClientIDChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      clientID: evt.target.value,
    });
  };

  onSignURLChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      signURL: evt.target.value,
    });
  };

  onGetSampleDocumentPress = (_evt) => {
    this.props.appContext.actions.getSampleDocument();
  };

  renderClientIDField() {
    const { isGettingSampleDocument, isSampleDocument, form } = this.props.appContext.state;
    const value = form.clientID;

    return (
      <div className="m-api-docs-sub-section">
        <div className="embedded-testing-tool__form-field">
          <div className="m-api-docs-sub-section--title">Client ID</div>
          <input
            name="client-id"
            type="text"
            pattern="[A-Fa-f0-9]{32}"
            value={value}
            disabled={isGettingSampleDocument || isSampleDocument}
            onChange={this.onClientIDChange} />
          <div className="m-api-docs-section--footnote">
            Enter your API app&rsquo;s Client ID here. If you do not have one you can create an API
            app by visting your account&rsquo;s
            <a
              href="/home/myAccount#api"
              target="_blank"
              rel="nofollow noreferrer"
            >
              {' '}API settings
            </a>
            , or you can generate a sample document by pressing the button below.
          </div>
        </div>
      </div>
    );
  }

  renderSignURLField() {
    const { isGettingSampleDocument, isSampleDocument, form } = this.props.appContext.state;
    const value = form.signURL;

    return (
      <div className="m-api-docs-sub-section">
        <div className="embedded-testing-tool__form-field">
          <div className="m-api-docs-sub-section--title">Embedded URL</div>
          <input
            name="sign-url"
            type="url"
            value={value}
            disabled={isGettingSampleDocument || isSampleDocument}
            onChange={this.onSignURLChange} />
          <div className="m-api-docs-section--footnote">
            If you already have an embedded <code>sign_url</code>, <code>claim_url</code>, or
            <code>edit_url</code>, you may enter it here, or you can generate a sample document by
            pressing the button below.
          </div>
        </div>
      </div>
    );
  }

  renderAutogenerateErrorMsg() {
    const { getSampleDocumentErrorMsg } = this.props.appContext.state;

    if (getSampleDocumentErrorMsg) {
      return (
        <div className="embedded-testing-tool__autogenerate-error-msg">
          {getSampleDocumentErrorMsg}
        </div>
      );
    }
  }

  renderAutogenerateSection() {
    const {
      hasUsedSampleDocument,
      isGettingSampleDocument,
      hasLaunchedSampleDocument,
    } = this.props.appContext.state;

    const isGetSampleDocumentDisabled = isGettingSampleDocument || hasUsedSampleDocument;

    return (
      <div className="m-api-docs-sub-section">
        <div className="embedded-testing-tool__autogenerate">
          <div className="embedded-testing-tool__autogenerate-or">or</div>
          <div className="embedded-testing-tool__autogenerate-button">
            <Button
              buttonText={`${isGettingSampleDocument ? 'Waiting…' : 'Get a sample document'}`}
              buttonColor="white"
              buttonHoverColor="cerulean"
              buttonTextColor="cerulean"
              buttonHoverTextColor="white"
              buttonBorderColor={isGetSampleDocumentDisabled ? 'transparent' : 'cerulean'}
              disabled={isGetSampleDocumentDisabled}
              onClick={this.onGetSampleDocumentPress} />
          </div>
          {hasUsedSampleDocument && hasLaunchedSampleDocument ? (
            <div className="m-api-docs-section--footnote embedded-testing-tool__autogenerate-reload-msg">Already used. <a href="">Reload the page</a> to get a new sample document.</div>
          ) : null}
          {this.renderAutogenerateErrorMsg()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="embedded-testing-tool__form-basic clearfix">
        <div className="m-api-docs-section">
          <div className="m-api-docs-section--content m-api-docs-section--narrow">
            {this.renderClientIDField()}
            {this.renderSignURLField()}
            {this.renderAutogenerateSection()}
          </div>
        </div>
      </div>
    );
  }
}

export default withAppContext(Basic);
