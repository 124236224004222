import React from 'react';
import createDebug from 'debug';
/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/prefer-default-export
*/

const debugShallow = createDebug('hs:shallowEqual');
export function shallowEqual(a, b) {

  if (typeof a !== typeof b || Array.isArray(a) !== Array.isArray(b)) {
    debugShallow('type mismatch', a, b);
    return false;
  }

  if (Array.isArray(a)) {
    if (a.length !== b.length) {
      debugShallow('key length');
      return false;
    }

    const mismatch = a.find((value, index) => value !== b[index]);
    if (mismatch != null) {
      debugShallow('Array mismatch', mismatch);
      return false;
    }
    return true;
  }

  const keys = Object.keys(a);
  if (Object.keys(b).length !== keys.length) {
    debugShallow('key length');
    return false;
  } else {
    // Find any key that contains a different object
    const key = keys.find((key) => b[key] !== a[key]);
    if (key != null) {
      debugShallow('key', key);
      return false;
    }
  }
  return true;
}

export const makeContextCacher = () => {
  let context = {};
  // If your context provides functions that act on a component's state, you may
  // need to force a context update when the state changes. One way would be to
  // place the state on the context, but then you've exposed your component
  // state to other components. With the keys, you can pass an array of objects
  // or values that will be compared to see if the context needs to update.
  let keys = [];

  return function contextCacher(nextContext, nextKeys = keys) {
    if (nextContext == null) {
      return context;
    }

    if (!shallowEqual(nextKeys, keys)) {
      keys = nextKeys;
      context = nextContext;
    }

    if (!shallowEqual(nextContext, context)) {
      context = nextContext;
    }

    return context;
  };

};

export function useContextCacher() {
  const ref = React.useRef();
  if (!ref.current) {
    ref.current = makeContextCacher();
  }
  return ref.current;
}

export function makeShallowCache(fn) {
  const cache = makeContextCacher();

  // memoize would compare the inputs and only recalculate if they change. It
  // works well as a performance improvement because you can skip the work of
  // the function. This shallowCache executes the function every time, but if
  // its result is shallowEqual to its previous object, it returns the old
  // object.
  return function shallowCache(...args) {
    return cache(fn.apply(this, args));
  };
}


export const withContext = ({ Consumer }, propName) => {
  // The default is to just pass the context down with the specified name
  function ctp(context) {
    return {
      [propName]: context,
    };
  }

  return (Component, contextToProps = ctp) => {
    // Using a named function will name the forwarded ref.
    // https://reactjs.org/docs/forwarding-refs.html#displaying-a-custom-name-in-devtools
    // eslint-disable-next-line react/display-name
    return React.forwardRef((props, ref) => {
      return (
        <Consumer>{(context) => (
          <Component
            {...props}
            {...contextToProps(context, props)}
            ref={ref} />
        )}
        </Consumer>
      );
    });
  };
};
