/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './free-plan-link.scss';
import React from 'react';

export default function FreePlanLink() {

  const url = '/info/chooseFreeOrEnterpriseSignupPlan';

  return (
    <div className='m-free-plan-link'>
            Just need to sign a document?
            Try our
      <a href={url} data-qa-ref="free-plan" da-category='signup' da-action='create account - plan selected - hs' da-label='free'> free plan.</a>
    </div>
  );
}
