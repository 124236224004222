/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
import React from 'react';
import ColorPicker from 'react-color';

const outlineStyle = '8px solid #3b99fc';

class ColorPickerUI extends React.Component {
  constructor(props) {
    super(props);
    this.intervals = [];
    this.name = 'ColorPickerUI';
    this.state = {
      isBlinking: false,
    };
  }

  clearIntervals() {
    this.intervals.forEach(clearInterval);
    this.intervals = [];
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  shouldStartBlinking() {
    return (this.props.blinkingComponent === this.name) && (this.intervals.length === 0);
  }

  startBlinking() {
    const blink = () => this.setState({ isBlinking: !this.state.isBlinking });
    this.intervals.push(setInterval(blink, 500));
  }

  handleChange(color) {
    this.clearIntervals();
    this.props.changeBlinkingComponent('ColorPickerUI');
    this.setState({ isBlinking: false });
    this.props.handleChange(color);
  }

  render() {
    if (this.shouldStartBlinking()) this.startBlinking();
    const style = this.state.isBlinking ? { outline: outlineStyle } : {};

    return <section className='m-colorpicker-container'>
      <div className='row'>
        <div>
          <div className='m-colorpicker-title' style={style}>
            <img src={require('./assets/2.png')} aria-hidden={true} />
            <span>Then, pick a new color.</span>
          </div>
          <img
            src={require('./assets/arrow_2.png')}
            aria-hidden={true}
            className='arrow-2' />
        </div>
        <ColorPicker
          className='m-colorpicker'
          display={true}
          type='chrome'
          onChange={(color) => this.handleChange(color)}
          color={this.props.selectedColor}
          positionCSS={{ position: 'static' }}
          style={{ width: 10, outline: outlineStyle }} />
      </div>
    </section>;
  }
}

module.exports = ColorPickerUI;
