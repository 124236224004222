/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len,
  react/jsx-no-duplicate-props,
  react/no-unescaped-entities,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FixedModal from 'common/components/fixed-modal';
import { Box, Text, Flex } from 'hello-ui/blocks';
import Button from 'common/components/button';
import CloseImageSrc from 'common/assets/modal-close@2x.png';
import './modal.scss';
import notificationIcon from 'common/images/info.svg';
import HfReactHelper from 'common/utils/hf-react-helper';
import StyleProvider from 'hello-ui/style';

export class UsageReportingModal extends React.Component {
    static defaultProps = {
      allowedOptions: ['compliance', 'usage'],
      rounded: true,
    };

    static propTypes = {
      allowedOptions: PropTypes.arrayOf(
        PropTypes.oneOf(['compliance', 'usage']),
      ),
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onClose: PropTypes.func,
      onSubmit: PropTypes.func,
      rounded: PropTypes.bool,
      successful: PropTypes.bool,
    };

    constructor(props) {
      super(props);

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

      this.state = {
        values: {
          compliance: false,
          usage: false,
        },
      };
    }

    handleChange(option) {
      return () => {
        this.setState((previousState) => {
          return {
            ...previousState,
            values: {
              ...previousState.values,
              [option]: !previousState.values[option],
            },
          };
        });
      };
    }

    handleSubmit(e) {
      e.preventDefault();

      this.props.onSubmit(this.state.values);
    }

    isValid() {
      const { values } = this.state;

      return values.compliance || values.usage;
    }

    render() {
      const {
        allowedOptions,
        busy,
        isEnabled,
        onClose,
        rounded,
      } = this.props;

      const { values } = this.state;
      const valid = this.isValid();

      const modalStyle = { borderRadius: rounded ? 5 : 0 };
      const usageAllowed = allowedOptions.includes('usage');
      const complianceDisabled = !isEnabled || !allowedOptions.includes('compliance') || busy;
      const usageDisabled = !isEnabled || !usageAllowed || busy;
      const submitDisabled = !isEnabled || !valid;

      const pricingUrl = HfReactHelper.urlHelper(
        'info/pricing?contact_sales=1',
        HfReactHelper.HfSites.SITE_CODE_HS,
      );

      return (
        <StyleProvider>
          <FixedModal width={600} modalStyle={modalStyle}>
            <div
              className="team-reporting-modal-close-button"
              onClick={onClose}
            >
              <img src={CloseImageSrc} />
            </div>
            <form onSubmit={this.handleSubmit}>
              <Box py={4} px={3}>
                <Text
                  fontSize={4}
                  fontWeight="semibold"
                  textTransform="uppercase"
                  textAlign="center"
                  mb={3}
                >
                  <FormattedMessage id='' defaultMessage='Reporting: Select Report' description='Team Page, header on modal when click on download report' />
                </Text>

                <Box p={3}>
                  <Box mb={4}>
                    <input
                      type="checkbox"
                      value="compliance"
                      name="compliance"
                      onChange={this.handleChange('compliance')}
                      checked={values.compliance}
                      id="teams-report-compliance"
                      disabled={complianceDisabled} />
                    <Box
                      is="label"
                      htmlFor="teams-report-compliance"
                      color={
                                            complianceDisabled ? 'light-grey' : ''
                                        }
                      fontWeight="normal"
                      fontSize={3}
                    >
                      <FormattedMessage id='' defaultMessage='Compliance' description='Team Page, header on modal when click on download report' />
                    </Box>
                    <Text
                      color={
                                            complianceDisabled ? 'light-grey' : ''
                                        }
                    >
                      <FormattedMessage id='' defaultMessage='The compliance report contains per-user account status and last login date.' description='Team Page, on modal when click on download report' />
                    </Text>
                  </Box>

                  <Box>
                    <input
                      type="checkbox"
                      value="usage"
                      name="usage"
                      checked={values.usage}
                      onChange={this.handleChange('usage')}
                      id="teams-report-usage"
                      disabled={usageDisabled} />
                    <Box
                      is="label"
                      htmlFor="teams-report-usage"
                      color={usageDisabled ? 'light-grey' : ''}
                      fontWeight="normal"
                      fontSize={3}
                    >
                      <FormattedMessage id='' defaultMessage='Usage' description='Team Page, how many template usage, on modal when click on download report' />
                    </Box>
                    <Text color={usageDisabled ? 'light-grey' : ''}>
                      <FormattedMessage id='' defaultMessage='The usage report includes per-user templates owned, requests sent over the last 6 months, and signed documents over the last 6 months.' description='Team Page, on modal when click on download report' />
                    </Text>

                  </Box>
                </Box>

                {isEnabled
                                && !usageAllowed
                                && <Box my={2} px={3}>
                                  <Flex align='center'>
                                    <Box
                                      mr={2}
                                      is="img"
                                      src={notificationIcon}
                                      mr={2}
                                      className="team-reporting-modal__info-icon" />
                                    <Text>
                                      <FormattedMessage id='' defaultMessage='Usage report was generated within the last hour. Please try again later.' description='Team Page, on modal when click on download report' />
                                    </Text>
                                  </Flex>
                                </Box>}

                {!isEnabled
                                && <Box my={2} px={3}>
                                  <Flex align='center'>
                                    <Box
                                      mr={2}
                                      is="img"
                                      src={notificationIcon}
                                      mr={2}
                                      className="team-reporting-modal__info-icon" />
                                    <Text>
                                      <FormattedMessage id='' defaultMessage="This feature isn't available on your current plan. {br} Please {contactSales} for more information about this and other HelloSign Enterprise features."
                                        description='Team Page, on modal when click on download report' values={{ br: <br />, contactSales: <a href={pricingUrl}>contact our Sales team</a> }} />
                                    </Text>
                                  </Flex>
                                </Box>}

              </Box>

              <Box p={3} className="team-reporting-modal-bar">
                <Flex justify='end' align='center'>
                  <Text
                    color="grey"
                    is="a"
                    fontSize={1}
                    px={2}
                    onClick={onClose}
                  >
                    <FormattedMessage id='' defaultMessage='Cancel' description='Team Page, text on button on modal when click on download report' />
                  </Text>

                  <Button
                    buttonColor="cerulean"
                    buttonText={busy
                      ? <FormattedMessage id='' defaultMessage='Submitting...' description='Team Page, text on button on modal when click on download report' />
                      : <FormattedMessage id='' defaultMessage='Submit' description='Team Page, text on button on modal when click on download report' />}
                    disabled={submitDisabled}
                    type="submit" />
                </Flex>
              </Box>
            </form>
          </FixedModal>
        </StyleProvider>
      );
    }
}

export default UsageReportingModal;
