/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-restricted-globals,
  no-script-url,
  no-useless-escape,
  react/no-find-dom-node,
  react/no-string-refs
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import classNames from 'classnames';
import setTimeout from 'common/utils/set-timeout';
import baseform from 'common/components/base-form';
import LogInCaptcha from './login-captcha';
import ForgotPasswordModal from './forgot-password-modal';
import GoogleSignInButton from './google-signin-button';
import DropboxSignInButton from './dropbox-signin-button';

/**
 * NOTE: This form does not follow the proper way to use
 * React and Symfony Forms, a better example can be found
 * by looking at ForgotPasswordForm
 */
const LogInForm = baseform.createFormClass({

  displayName: 'LogInForm',

  propTypes: {
    header: PropTypes.string,
    buttonText: PropTypes.string,
    googleSignInClientId: PropTypes.string,
    dropboxSSO: PropTypes.bool,
    redirectUrl: PropTypes.string,
    onErrorRedirectUrl: PropTypes.string,
    tabOffset: PropTypes.number,
    closeAfterLogin: PropTypes.bool,
    emailAddress: PropTypes.string, // FIXME: Should be refactored to come from loginForm.values (same with errors instead of parsing if from the URL)
    recaptchaPublicKey: PropTypes.string,
    displayCaptcha: PropTypes.bool,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    async: PropTypes.bool,
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
    csrfToken: PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      async: true,
      displayCaptcha: false,
      action: '/account/logIn',
    };
  },

  getInitialState() {
    let emailAddress = this.props.emailAddress;
    const href = decodeURIComponent(location.href);

    let matches = href.match(/email\=([^&]+)/i);
    if (matches && matches.length > 1) {
      emailAddress = matches[1];
    }

    if (!emailAddress) {
      matches = href.match(/login_default_email\=([^&]+)/i);
      if (matches && matches.length > 1) {
        emailAddress = matches[1];
      }
    }

    let redirectUrl = this.props.redirectUrl;
    if (!redirectUrl) {
      matches = href.match(/on_login_redirect_url\=([^&]+)/i);
      if (matches && matches.length > 1) {
        redirectUrl = matches[1];
      }
    }

    return {
      password: '',
      emailAddress,
      isProcessing: false,
      redirectUrl,
      closeAfterLogin: /\?close_after_login\=1|&close_after_login\=1/i.test(href),
      isCaptchaDisplayed: this.props.displayCaptcha,
    };
  },

  componentDidMount() {
    const root = $(ReactDOM.findDOMNode(this));
    const emailInput = root.find('input[name=\'login[email_address]\']');
    const pwdInput = root.find('input[name=\'login[password]\']');
    if (emailInput.length === 1 && !this.state.emailAddress) {
      setTimeout(() => {
        emailInput.focus();
      }, 500);
    } else if (this.state.emailAddress && pwdInput.length === 1) {
      setTimeout(() => {
        pwdInput.focus();
      }, 500);
    }
  },

  onEmailChange(event) {
    this.setState({
      emailAddress: event.target.value.trim(),
    });
  },

  onPasswordChange(event) {
    this.setState({
      password: event.target.value,
    });
  },

  onSubmit(evt) {

    evt.preventDefault();

    if (this.state.isProcessing === false) {

      // Retrieve the values from the email/password field one more time
      // prior to submit. This fixes an issue where Safari auto-complete
      // would not trigger the on*Change events for these fields.
      const root = ReactDOM.findDOMNode(this);
      const minPasswordLength = HfReactHelper.HfConstants.minPasswordLength;
      const emailAddress = $('#login_email_address_input', root).val();
      const password = $('#login_password_input', root).val();

      this.setState({
        errors: null,
        isProcessing: true,
        emailAddress,
        password,
      });

      if (!HfReactHelper.isValidEmailAddress(emailAddress)) {

        this.setState({
          errors: { named: { email_address: 'Invalid email address' } },
          isProcessing: false,
        });

      } else if (password.length < minPasswordLength) {

        this.setState({
          errors: { named: { password: `Your password must be at least ${minPasswordLength} characters long` } },
          isProcessing: false,
        });

      } else if (!this.props.async) {

        // Inline submit
        $('#login_form', root).submit();

      } else {

        // Async submit
        this.submitAsync(function (resp) {

          this.setState({
            isProcessing: false,
          });

          // Close the login modal
          if (resp.success) {
            $('.m-modal--close', '#login_modal').click();
          }

          // Display notification
          if (resp.notification) {
            hellofaxJS.createNotification({
              text: resp.notification.message,
              class_name: resp.notification.type,
            });
          }

          // Redirect to on_login_redirect_url
          if (resp.success === true && resp.on_login_redirect_url) {
            document.location = resp.on_login_redirect_url;
          }

          // Failure
          if (!resp.success) {

            const hasErrorMsg = (resp.error && resp.error.message);

            if (this.state.isCaptchaDisplayed) {
              // Refresh CAPTCHA
              grecaptcha.reset();

              if (hasErrorMsg) {
                this.setState({
                  errors: { global: resp.error.message },
                });
              }
            }

            if (resp.display_captcha) {
              if (!this.state.isCaptchaDisplayed) {
                // Show CAPTCHA
                this.setState({
                  isCaptchaDisplayed: true,
                });
              }
            } else if (resp.on_error_redirect_url) {
              // Redirect to on_error_redirect_url
              document.location = resp.on_error_redirect_url;
            } else if (hasErrorMsg && !resp.notification_message) {
              // Display error message
              this.setState({
                isCaptchaDisplayed: resp.display_captcha,
                errors: { global: resp.error.message },
              });
            }

          }

        }, function () {

          // Unexpected error
          this.setState({
            errors: { global: 'An unexpected error occurred' },
            isProcessing: false,
          });

        });

      }
    }
  },

  displayForgotPasswordModal() {

    // Note: version 1.4.4 of jQuery is incompatible with Foundation.
    // In this case we'll want to redirect to / to use the regular login form
    if ($.fn.jquery === '1.4.4') {
      window.location = '/';
      return;
    }

    $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'close');
    const attachPoint = HfReactHelper.getRevealAttachPoint('forgot_password_modal', 'tiny');
    ReactDOM.render(
      <ForgotPasswordModal
        emailAddress={this.state.emailAddress}
        recoverPasswordForm={this.props.recoverPasswordForm}
      ></ForgotPasswordModal>,
      attachPoint,
    );
  },

  render() {

    const tabOffset = parseInt(this.props.tabOffset || 0, 10);
    const rowClassNames = classNames({
      row: true,
      'text-center': true,
    });

    const isCaptchaDisplayed = (this.props.displayCaptcha || this.state.isCaptchaDisplayed);
    const buttonColumnClassNames = 'columns small-12 l-margin-v-20';
    let formColumnClassNames = `${buttonColumnClassNames} text-left m-login--sign-up`;
    formColumnClassNames = isCaptchaDisplayed ? `${formColumnClassNames} m-login--sign-up-tall` : formColumnClassNames;

    let captcha;
    let errors;
    if (isCaptchaDisplayed) {
      captcha = (
        <div className='l-margin-b-5 small-10 small-offset-1 text-center'>
          <LogInCaptcha recaptchaPublicKey={this.props.recaptchaPublicKey} tabIndex={tabOffset + 4} />
        </div>
      );
    }

    if (this.state.errors) {
      errors = (
        <div className='row'>
          <div className='columns l-margin-b-10 small-10 small-offset-1 text-center m-modal--error'>
            { this.renderErrorText() }
          </div>
        </div>
      );
    }

    let buttonText = this.props.buttonText ? this.props.buttonText : 'LOG IN';
    if (this.props.async && this.state.isProcessing) {
      buttonText = 'PROCESSING...';
    }

    let googleSignIn;
    if (!this.props.hideGoogleOpenId) {
      googleSignIn = (
        <div className={`${buttonColumnClassNames} m-login--modal-google-login`}>
          <div className={'m-login--modal-google-login-border'}>
            <div className={'m-login--modal-google-login-or-image'} />
          </div>
          <GoogleSignInButton
            clientId={this.props.googleSignInClientId}
            redirectUrl={this.props.redirectUrl}
            csrfToken={this.props.csrfToken} />
        </div>
      );
    }

    let rememberMe;
    if (!this.props.hideRememberMe) {
      rememberMe = (
        <div className='l-margin-b-10 small-10 small-offset-1 small-text-left'>
          <div className='m-login--centering-container'>
            <div className='m-login--alignment-wrapper'>
              { this.renderField('should_remember_me', {
                id: 'login[should_remember_me]',
                defaultChecked: 'checked',
                tabIndex: tabOffset + 3,
                className: 'm-login--text-input',
              }) }
              <label htmlFor='login[should_remember_me]'>Remember me</label>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='m-login'>
        <form ref='login_form' className='m-login--form' id='login_form' action={this.props.action} method='POST' onSubmit={this.onSubmit}>
          <div className={rowClassNames}>
            <div className='columns small-12'>
              <h3>{ this.props.header }</h3>
            </div>
            {googleSignIn}
            {this.props.dropboxSSO ? (
              <DropboxSignInButton redirectUrl={this.props.redirectUrl} />
            ) : null }
            <div className={formColumnClassNames}>
              <div className='l-margin-b-10 small-10 small-offset-1 text-center'>
                { this.renderField('email_address', {
                  id: 'login_email_address_input',
                  className: 'm-login--text-input',
                  placeholder: 'Email',
                  onChange: this.onEmailChange,
                  tabIndex: tabOffset,
                  value: this.state.emailAddress,
                }) }
              </div>
              <div className='l-margin-b-5 small-10 small-offset-1 text-center'>
                { this.renderField('password', {
                  id: 'login_password_input',
                  className: 'm-login--text-input m-login--login-password',
                  placeholder: 'Password',
                  onChange: this.onPasswordChange,
                  tabIndex: tabOffset + 1,
                }) }
              </div>
              <div className='m-login--forgot-pwd-link l-margin-b-10 small-10 small-offset-1 small-text-left'>
                <div className='m-login--centering-container'>
                  <div className='m-login--alignment-wrapper'>
                    <a href='javascript:;' tabIndex={tabOffset + 2} onClick={this.displayForgotPasswordModal}>Forgot password?</a>
                  </div>
                </div>
              </div>
              { rememberMe }
              { this.renderHiddenFields() }
              { captcha }
              { errors }
              <div className={`small-10 small-offset-1 text-center${this.props.isInline ? ' m-login--inline-google-login-button-container' : ' m-login--modal-google-login-button-container'}`}>
                <Button onClick={this.onSubmit}
                  buttonClass={this.props.isInline ? 'm-login--inline-google-login-button' : 'm-login--modal-google-login-button'}
                  buttonText={buttonText}
                  buttonColor='grasshopper'
                  buttonHoverColor='castle-rock'
                  buttonTabIndex={tabOffset + 5}
                ></Button>
              </div>

            </div>
          </div>
          <input type='hidden' name='on_error_redirect_url' value={this.props.onErrorRedirectUrl} />
          <input type='hidden' name='close_after_login' value={this.state.closeAfterLogin ? '1' : '0'} />
          <input type='hidden' name='login[redirect_url]' value={this.state.redirectUrl} />
          <input type='hidden' name='async' value={this.props.async ? '1' : '0'} />
        </form>
      </div>
    );
  },
});

module.exports = LogInForm;
