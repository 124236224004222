/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  camelcase,
  import/no-extraneous-dependencies,
  no-restricted-globals,
  no-useless-escape
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';
import LoginModal from './login-modal';

const LogInTrigger = createReactClass({

  propTypes: {
    header: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    trigger: PropTypes.element,
    googleSignInClientId: PropTypes.string,
    dropboxSSO: PropTypes.bool,
    buttonText: PropTypes.string,
    tabOffset: PropTypes.number,
    emailAddress: PropTypes.string,
    loginUrl: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    recaptchaPublicKey: PropTypes.string,
    displayCaptcha: PropTypes.bool,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    loginForm: PropTypes.object.isRequired, // Serialized sfForm
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
  },

  componentDidMount() {

    const self = this;

    if (window.handlingLogInTriggers) {
      // We only want to process this once per page
      return;
    }
    // If the 'login=1' URL parameter is present, display the modal
    if (/\?display_login\=1|&display_login\=1/i.test(location.href)) {
      window.handlingLogInTriggers = true;
      this.handleClick();
    }

    // Listen for trigger events
    $(document).bind('login-trigger', (evt, params) => {
      self.handleClick(null, null, {
        displayCaptcha: (params && params.displayCaptcha) || false,
      });
    });
  },

  handleClick(evt, react_id, extraProps) {
    const tabOffset = parseInt(this.props.tabOffset || 1000, 10);
    const attachPoint = HfReactHelper.getRevealAttachPoint('login_modal');
    const props = extraProps ? HfReactHelper.extend({}, this.props, extraProps) : this.props;
    ReactDOM.render(
      <LoginModal
        header={props.header}
        buttonText={props.buttonText}
        googleSignInClientId={props.googleSignInClientId}
        dropboxSSO={props.dropboxSSO}
        isFromApiPricing={props.isFromApiPricing}
        tabOffset={tabOffset}
        recaptchaPublicKey={props.recaptchaPublicKey}
        displayCaptcha={props.displayCaptcha}
        emailAddress={props.emailAddress}
        loginUrl={props.loginUrl}
        recoverPasswordForm={props.recoverPasswordForm}
        redirectUrl={props.redirectUrl}
        loginForm={props.loginForm}
        hideRememberMe={props.hideRememberMe}
        hideGoogleOpenId={props.hideGoogleOpenId}
      ></LoginModal>,
      attachPoint,
    );
  },

  renderTrigger() {
    if (this.props.trigger) {
      return this.props.trigger;
    } else {
      return (<a href='#'>{this.props.linkText}</a>);
    }
  },

  render() {
    return (
      <span className='log-in-trigger' onClick={this.handleClick}>
        { this.renderTrigger() }
      </span>
    );
  },
});

module.exports = LogInTrigger;
