/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  camelcase,
  import/named,
  import/no-extraneous-dependencies,
  max-len,
  no-console,
  no-param-reassign,
  no-undef,
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import superagent from 'superagent';
import { stringify } from 'qs';
import './google-signin-button.scss';
import uuidv4 from 'uuid/v4';
import HfReactHelper from 'common/utils/hf-react-helper';
import Raven from 'raven-js';
import GoogleLoginOrSignup from './google-login-or-signup';

/**
 * A top-level page layout component which tries to fill the entire page.
 * Features a simple header, a small centered card containing child content, and no footer.
 */
export default class GoogleSignInButton extends React.Component {
    static propTypes = {
      clientId: PropTypes.string.isRequired,
      forceFallback: PropTypes.bool,
      redirectUrl: PropTypes.string,
      getIsOptedIn: PropTypes.func,
      csrfToken: PropTypes.string.isRequired,
      formType: PropTypes.string,
      buttonAttrs: PropTypes.object,
    };

    // setting default prop for function because this
    // component is used in the SignIn page as well
    static defaultProps = {
      getIsOptedIn: () => {},
      buttonAttrs: {},
      forceFallback: false,
    };


    renderTimeout = null;

    constructor(props) {
      super(props);

      const randomId = uuidv4().substring(0, 6);

      // Initial state
      this.state = {
        id: `signin2-${randomId}`,
        renderStatus: 'waiting',
        hasAuthLoaded: false,
        mockedButtonText: <FormattedMessage id='' defaultMessage='Waiting for Google...' description='sign in with google button' />,
        buttonAttrs: this.props.buttonAttrs,
      };

      // Prebind methods
      this.gapiDidLoad = this.gapiDidLoad.bind(this);
      this.onSignIn = this.onSignIn.bind(this);
      this.onSignInFailure = this.onSignInFailure.bind(this);
    }

    fallbackGoogleButton(message) {
      message += ' Falling back to oauth google button.';
      console.warn(message);
      Raven.captureMessage(message);
      this.setState({ renderStatus: 'fallback' });
    }

    componentDidMount() {
      this.renderTimeout = setTimeout(() => {
        if (this.state.renderStatus === 'waiting') {
          this.fallbackGoogleButton('Google button didn\'t render in time.');
        }
      }, 3000);

      // We need to determine if gapi has already loaded or if we need to wait for it
      if (window.gapi) {
        this.gapiDidLoad();
      } else {
        // Register for an event letting us know that gapi has been loaded, and then do the thing
        window.addEventListener('hs-gapi-did-load', this.gapiDidLoad);
      }
    }

    componentWillUnmount() {
      clearTimeout(this.renderTimeout);
    }

    shouldComponentUpdate(nextProps, nextState) {
      // We don't want to re-render when state hasn't changed since that would override the content injected by Google
      return nextState.renderStatus !== this.state.renderStatus;
    }

    renderButton() {
      this.setState({ renderStatus: 'rendering' });
      gapi.signin2.render(
        this.state.id, {
          scope: 'profile',
          width: 200,
          height: 36,
          longtitle: true,
          theme: 'dark',
          onsuccess: this.onSignIn,
          onfailure: this.onSignInFailure,
        },
      );
    }

    // Things we want to do once gapi has been loaded
    gapiDidLoad() {
      if (this.state.hasAuthLoaded === true) {
        this.renderButton();
      } else {
        // First we need to load auth2
        gapi.load('auth2', () => {
          // Initialize auth2
          gapi.auth2.init({
            client_id: this.props.clientId,
          }).then(() => {
            // Tell our Google Auth2 context to sign out just to have a clean slate
            const auth2 = gapi.auth2.getAuthInstance();
            auth2.signOut().then(() => {
              // Tell gapi to render the button
              this.renderButton();
              this.setState({ hasAuthLoaded: true }); // We only ever want to load auth once.
            });
          }, (error) => {
            this.fallbackGoogleButton(`Error initializing gapi.auth2: ${error.error}, ${error.details}`);
          });
        });
      }
    }

    // Called by gapi.signin2 after successful Google sign-in
    onSignIn(googleUser) {
      const authResponse = googleUser.getAuthResponse();
      window.authResponse = authResponse;
      const id_token = authResponse.id_token;

      const post_body = {
        id_token,
        form_type: this.props.formType,
        redirect_url: this.props.redirectUrl,
        opt_in: this.props.getIsOptedIn(),
        csrf_token: this.props.csrfToken,
      };

      // Send id_token to backend for HelloSign login
      const url = HfReactHelper.urlHelper('account/processGoogleSignIn');
      superagent
        .post(url)
        .type('form')
        .send(stringify(post_body))
        .on('request', () => {
          this.setState({
            renderStatus: 'loading',
            mockedButtonText: <FormattedMessage id='' defaultMessage='Signing you in...' description='sign in with google button' />,
          });
        })
        .end((err, response) => {
          if (!response.ok) {
            // Error here means some kind of http status error (4xx or 5xx)
            this.onSignInFailure();
          }

          try {
            const response_json = JSON.parse(response.text);
            const redirect_to = response_json.redirect_url;
            window.location.replace(redirect_to);
          } catch (e) {
            console.log(`Exception processing google signin: ${e}`);
            this.onSignInFailure();
          }
        });
    }

    onSignInFailure(result) {
      if (result && result.error === 'popup_closed_by_user') {
        return;
      }

      this.setState({
        renderStatus: 'failure',
        mockedButtonText: <FormattedMessage id='' defaultMessage='Sign-in currently unavailable' description='sign in with google button' />,
      });
    }

    showGoogleButton() {
      return this.state.renderStatus === 'rendering';
    }

    render() {
      return (this.state.renderStatus === 'fallback' || this.props.forceFallback) ? (
        <div className="m-google-signin-button">
          <GoogleLoginOrSignup redirectUrl={this.props.redirectUrl} />
        </div>
      ) : (
        <div>
          <div className="mocked-google-signin-button" style={{ display: !this.showGoogleButton() ? '' : 'none' }}>
            <div className="mocked-google-signin-button--placeholder">{this.state.mockedButtonText}</div>
          </div>
          <div className="m-google-signin-button" id={this.state.id}
            style={{ display: this.showGoogleButton() ? '' : 'none' }}
            da-category={this.props.buttonAttrs['da-category'] ? this.props.buttonAttrs['da-category'] : null}
            da-action={this.props.buttonAttrs['da-action'] ? this.props.buttonAttrs['da-action'] : null}
            da-label={this.props.buttonAttrs['da-label'] ? this.props.buttonAttrs['da-label'] : null} />
        </div>
      );
    }
}
