/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
/**
Billing/Pricing Constants
* */

module.exports = {
  // These values mirror what's in SubscriptionInfo.class.php
  BILLING_TYPE_STANDARD: 'S',
  BILLING_TYPE_ALTERNATIVE_CHARGE_INDIVIDUAL: 'AI',
  BILLING_TYPE_ALTERNATIVE_CHARGE_PARTNER: 'AP',
  BILLING_TYPE_ALTERNATIVE_CHARGE_MASTER_SUB: 'AM',
  ALTERNATIVE_BILLING_TYPES: ['AI', 'AP', 'AM'],

  BILLING_METHOD_CREDIT_CARD: 'CC',
  BILLING_METHOD_INVOICE: 'IN',
};
