/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len,
  no-console,
  no-void,
  prefer-promise-reject-errors
*/
// TODO - break these endpoints out to separate files

/**
 * API Mappings. These are typically used in ../routes/index.js
 */

import qs from 'qs';
import _mapAPIOperation from 'common/utils/map-http-operation';
import HfReactHelper from 'common/utils/hf-react-helper';
import request from 'superagent';
import serializeSignatureRequest from './serialize-signature-request';


function makeUrl(uri) {
  let prefix = '';
  if (document.location && document.location.href.indexOf('/webapp_dev.php') > 0) {
    prefix = '/webapp_dev.php';
  }
  return prefix + HfReactHelper.attachSessionInfoToUrl(uri);
}

function deserializeAPIResponseText(serialized) {
  let deserialized = {};
  if (serialized) {
    try {
      deserialized = JSON.parse(serialized.text);
      if (deserialized.data) {
        deserialized = deserialized.data;
      }
    } catch (e) {
      console.error('Error parsing JSON response', e);
    }
  }
  return deserialized;
}

/**
 * Defines the various backend APIs/endpoints that get called via XHR in the app
 */

export default {

  create(app) {

    return {

      /**
             * Signature object
             */

      // TODO: Convert insert to use new superagent helper and remove superagent require from this file

      signature: {

        insert(op) {
          return new Promise(((resolve, reject) => {

            const model = op.target;
            const url = makeUrl('/signature/upload');

            if (model.file) {
              request
                .post(url)
                .attach('qq_file', model.file, model.file.name)
                .field('create_type_code', model.createType)
                .field('type_code', model.type)
                .field('ux_version', 2)
                .field('csrf_token', app.csrfToken)
                .field('is_in_person', app.isInPerson)
                .end((err, data) => {
                  if (err) {
                    // we need to handle this more cleanly and display an error specific to the signature upload, potentially reusing signModal.upload.instructions.fileFormats
                    return reject(err);
                  } else {
                    model.file = void 0;
                  }
                  resolve(deserializeAPIResponseText(data));
                });

              return;
            }

            const body = qs.stringify({
              create_type_code: model.createType,
              type_code: model.type,
              data: model.signature,
              ux_version: 2,
              csrf_token: app.csrfToken,
              is_in_person: app.isInPerson,
            });

            request
              .post(url)
              .send(body)
              .end((err, data) => {

                // If we get back an error _without_ any data, we'll
                // treat that as a general network error and just
                // resolve the error itself.  However, if we get
                // data back, we'll deserialize it to handle
                // expected error cases from the server, e.g.
                // signatures that are too small.  Without this,
                // network errors in the signature modal cause all
                // sorts of other side effects because the app
                // thinks the call to /upload was successful.  This
                // needs to be part of the TODO refactoring listed
                // above.
                const response = !data && err
                  ? err
                  : deserializeAPIResponseText(data);

                resolve(response);
              });
          }));
        },

        remove(op) {
          return _mapAPIOperation({
            url: makeUrl('/signature/remove'),
            method: 'POST',
            query: {
              guid: op.target.guid,
              ux_version: 2,
              csrf_token: app.csrfToken,
            },
            transformResponse() {
              // API doesn't give anything useful - return a blank object - this
              // will get ignored by the model calling it (CC)
              return Promise.resolve({});
            },
          });
        },

        rotate(op) {
          return {
            url: makeUrl('/signature/rotate'),
            method: 'POST',
            query: {
              guid: op.target.guid,
              ux_version: 2,
              csrf_token: app.csrfToken,
            },
          };
        },

        markAsPrimary(op) {
          return {
            url: makeUrl('/signature/markAsPrimary'),
            method: 'POST',
            query: {
              guid: op.target.guid,
              ux_version: 2,
              csrf_token: app.csrfToken,
            },
          };
        },

        edit(op) {

          const model = op.target;
          const type = model.type || 'S';

          return {
            url: makeUrl('/signature/upload'),
            method: 'POST',
            data: qs.stringify({
              is_edit: true,
              guid: model.guid,
              create_type_code: model.createType,
              type_code: type,
              data: model.getEditParams(),
              ux_version: 2,
              csrf_token: app.csrfToken,
            }),
          };
        },

        checkEmailSignatureStatus(op) {

          const model = op.target;

          return {
            url: makeUrl('/signature/emailSignatureStatus'),
            method: 'POST',
            query: {
              email_guid: model.emailGuid,
              ux_version: 2,
              csrf_token: app.csrfToken,
            },
          };
        },
      },


      /**
             * Signature collection
             */

      signatures: {

        load(op) {
          const type = op.target.type;
          return {
            url: makeUrl('/signature/list'),
            method: 'GET',
            query: {
              type_code: type,
              ux_version: 2,
            },
          };
        },
      },

      /**
             */

      signatureRequest: {

        load(op) {
          const model = op.target;

          return {
            url: makeUrl('/signer/load'),
            method: 'GET',
            query: {
              guid: model.guid,
              access_code: model.accessCode,
              signature_id: model.embeddedData.signatureId,
              selected_signer: model.selectedSigner || void 0,
              tsm_guid: model.transmissionGuid || void 0,
              status_token: app.statusToken,
            },
          };
        },

        update(op) {

          const model = op.target;
          let data = model.toData();

          data = serializeSignatureRequest(data, model.settings);

          return _mapAPIOperation({
            url: makeUrl('/signer/save'),
            method: 'POST',
            data: {
              guid: data.guid,
              data: JSON.stringify(data.data),
              csrf_token: app.csrfToken,
            },
            transformResponse() {
              // API doesn't give anything useful - return a blank object - this
              // will get ignored by the model calling it (CC)
              return Promise.resolve({});
            },
          });
        },

        getSignatureRequestProgress(op) {

          return {
            url: makeUrl('/signer/ready'),
            method: 'GET',
            query: {
              tsm_guid: op.transmissionGuid,
              token: app.statusToken,
            },
          };
        },

        decline(op) {

          const model = op.target;
          let data = model.toData();
          data = serializeSignatureRequest(data, model.settings);

          return _mapAPIOperation({
            url: makeUrl('/signer/decline'),
            method: 'POST',
            query: {
              tsm_guid: model.transmissionGuid,
              access_code: model.accessCode,
              decline_reason: model.declineReason,
              selected_signer: model.selectedSigner,
            },
            data: {
              data: JSON.stringify(data.data),
              csrf_token: app.csrfToken,
            },
            transformResponse(ret) {

              if (ret.success === false) {
                const error = new Error('error.general.message');
                return Promise.reject(error);
              }

              return Promise.resolve(ret);
            },
          });
        },

        delegate(op) {

          const model = op.target;
          let data = model.toData();
          data = serializeSignatureRequest(data, model.settings);

          return _mapAPIOperation({
            url: makeUrl('/signer/delegate'),
            method: 'POST',
            query: {
              tsm_guid: model.transmissionGuid,
              access_code: model.accessCode,
              new_signer_email: model.newSignerEmail,
              new_signer_name: model.newSignerName,
              delegate_reason: model.delegateReason,
            },
            data: {
              data: JSON.stringify(data.data),
              csrf_token: app.csrfToken,
            },
            transformResponse(ret) {

              if (ret.success === false) {
                const error = new Error('error.general.message');
                return Promise.reject(error);
              }

              return Promise.resolve(ret);
            },
          });
        },

        uploadAttachment(op) {

          // TODO MAKE ABSTRACT WIHT MAPPER THING
          return new Promise(((resolve, reject) => {

            request
              .post(makeUrl('/attachment/UploadAttachment'))
              .attach('file', op.file, op.file.name)
              .field('csrf_token', app.csrfToken)
              .query({ guid: op.guid })
              .end((err, data) => {
                if (err) {
                  return reject({ success: false, message: 'BAD_REQUEST' });
                }

                resolve(JSON.parse(data.text));
              });
          }));
        },

        complete(op) {

          const model = op.target;
          let data = model.toData();

          data = serializeSignatureRequest(data, model.settings);

          return _mapAPIOperation({
            url: makeUrl('/signer/finalize'),
            method: 'POST',
            query: {
              tsm_guid: model.transmissionGuid,
              access_code: model.accessCode,
            },
            data: {
              data: JSON.stringify(data.data),
              csrf_token: app.csrfToken,
            },
            transformResponse(ret) {

              if (ret.is_valid === false) {

                // If fields are missing, they'll be returned in the error map
                // listed as components
                const fieldsMissing = ret.error_msg_map && Object.keys(ret.error_msg_map).filter((key) => {
                  return key.indexOf('component') !== -1;
                }).length;

                const errorType = fieldsMissing ? 'error.field.fieldsMissing' : 'error.general.message';
                return Promise.reject(new Error(errorType));
              }

              return Promise.resolve(ret);
            },
          });
        },

        optIn(op) {
          const model = op.target;

          return _mapAPIOperation({
            url: makeUrl('/signer/optIn'),
            method: 'POST',
            query: {
              tsm_guid: model.transmissionGuid,
              esign_disclosure_id: op.esignDisclosureId,
            },
            data: {
              csrf_token: app.csrfToken,
            },
            transformResponse(ret) {

              if (ret.success === false) {
                const error = new Error('error.general.message');
                return Promise.reject(error);
              }

              return Promise.resolve(ret);
            },
          });
        },
      },
    };
  },
};
