import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const HsApiLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 400 40"
    className={classnames(styles.icon, props.className)}
  >
    <defs>
      <polygon id="path-1" points="0 26.6666667 230.784 26.6666667 230.784 0.00533333333 0 0.00533333333"></polygon>
    </defs>
    <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HelloSign-API-Static-Navigation" transform="translate(-150.000000, -50.000000)">
        <g id="Page-1" transform="translate(150.000000, 50.000000)">
          <path d="M0,0.3336 L13.5373333,20.2482667 L28.2626667,0.3336 L0,0.3336 Z M8.32,4.73893333 L19.5226667,4.73893333 L13.6826667,12.6309333 L8.32,4.73893333 Z" id="Fill-1" fill="#00B3E6"></path>
          <polygon id="Fill-2" fill="#00B3E6" points="4.34 26.994 24.0813333 26.994 24.0813333 22.5886667 4.34 22.5886667"></polygon>
          <polygon id="Fill-3" fill="#414141" points="45.35 22.5804 45.35 15.4350667 38.494 15.4350667 38.494 22.5804 34.3633333 22.5804 34.3633333 4.7684 38.494 4.7684 38.494 11.7110667 45.35 11.7110667 45.35 4.7684 49.4806667 4.7684 49.4806667 22.5804"></polygon>
          <polygon id="Fill-4" fill="#414141" points="54.5753333 22.5804 54.5753333 4.7684 65.8513333 4.7684 65.8513333 8.55773333 58.706 8.55773333 58.706 11.7110667 65.2273333 11.7110667 65.2273333 15.3337333 58.706 15.3337333 58.706 18.8124 65.8513333 18.8124 65.8513333 22.5804"></polygon>
          <polygon id="Fill-5" fill="#414141" points="70.7061333 22.5804 70.7061333 4.7684 74.8368 4.7684 74.8368 18.8124 81.9821333 18.8124 81.9821333 22.5804"></polygon>
          <polygon id="Fill-6" fill="#414141" points="85.5765333 22.5804 85.5765333 4.7684 89.7072 4.7684 89.7072 18.8124 96.8525333 18.8124 96.8525333 22.5804"></polygon>
          <g id="Group-18" transform="translate(0.000000, 0.327333)">
            <path d="M108.2732,22.5506667 C102.758533,22.5506667 99.2292,18.5866667 99.2292,13.3613333 C99.2292,8.136 102.9252,4.136 108.446533,4.136 C113.9692,4.136 117.491867,8.10133333 117.491867,13.3466667 C117.683867,18.2386667 113.874533,22.3586667 108.983867,22.5506667 C108.746533,22.56 108.510533,22.56 108.2732,22.5506667 Z M108.346533,7.84666667 C105.526533,7.84666667 103.627867,10.1586667 103.627867,13.3613333 C103.627867,16.564 105.526533,18.876 108.346533,18.876 C111.1652,18.876 113.063867,16.5426667 113.063867,13.3613333 C113.063867,10.18 111.1652,7.84 108.346533,7.84 L108.346533,7.84666667 Z" id="Fill-7" fill="#414141"></path>
            <path d="M126.3976,22.5506667 C124.270933,22.484 122.200267,21.8546667 120.3976,20.724 L121.5416,16.9853333 C122.990933,18.052 124.7296,18.6533333 126.528267,18.7093333 C127.9776,18.7093333 128.5496,18.028 128.5496,17.152 C128.5496,16.2746667 128.0856,15.5933333 125.3616,14.4986667 C122.222933,13.2386667 121.1296,11.412 121.1296,9.27333333 C121.1296,6.672 123.2456,4.144 127.3976,4.144 C129.1536,4.148 130.876267,4.62666667 132.382933,5.528 L131.332267,9.20933333 C130.170933,8.384 128.8096,7.884 127.390933,7.76 C125.882933,7.76 125.2736,8.484 125.2736,9.20933333 C125.2736,10.376 126.1736,10.7666667 128.412267,11.7093333 C131.7376,13.1226667 132.760267,14.724 132.760267,17.0066667 C132.7376,19.848 130.6736,22.5506667 126.3976,22.5506667" id="Fill-9" fill="#414141"></path>
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-12"></g>
            <polygon id="Fill-11" fill="#414141" mask="url(#mask-2)" points="136.962667 22.2533333 141.093333 22.2533333 141.093333 4.44133333 136.962667 4.44133333"></polygon>
            <path d="M161.340933,12.2453333 L161.340933,20.7533333 C159.2996,21.9653333 156.960933,22.588 154.5876,22.5506667 C149.362267,22.5506667 145.2316,18.8106667 145.2316,13.3613333 C145.2316,7.912 149.404933,4.136 154.796933,4.136 C156.886267,4.136 158.942267,4.66266667 160.7756,5.66533333 L159.0516,8.992 C157.820933,8.34533333 156.454267,8.00133333 155.064933,7.992 C151.514267,7.992 149.622267,10.2026667 149.622267,13.34 C149.622267,16.7453333 151.904933,18.8546667 154.826267,18.8546667 C155.7276,18.924 156.6316,18.7573333 157.448933,18.3693333 L157.448933,15.732 L153.876933,15.732 L153.876933,12.2453333 L161.340933,12.2453333 Z" id="Fill-13" fill="#414141" mask="url(#mask-2)"></path>
            <polygon id="Fill-14" fill="#414141" mask="url(#mask-2)" points="180.754933 22.5942667 170.008267 12.9489333 170.008267 22.2529333 165.906933 22.2529333 165.906933 4.19426667 166.464267 4.09293333 177.066933 13.6729333 177.066933 4.44093333 181.1976 4.44093333 181.1976 22.5582667"></polygon>
            <path d="M199.830533,9.88653333 L197.302533,15.8385333 L202.3572,15.8385333 L199.830533,9.88653333 Z M205.078533,22.3252 L203.3532,18.2452 L196.307867,18.2452 L194.582533,22.3252 L191.447867,22.3252 L199.6612,4.27586667 L200.0972,4.27586667 L208.283867,22.3252 L205.078533,22.3252 Z" id="Fill-15" fill="#A0A0A0" mask="url(#mask-2)"></path>
            <path d="M216.644267,7.19053333 C215.962933,7.19053333 215.282933,7.23853333 214.650933,7.33586667 L214.650933,13.8478667 C215.2576,13.8958667 215.8176,13.9438667 216.352267,13.9438667 C219.0976,13.9438667 220.360267,12.5105333 220.360267,10.4945333 C220.360267,8.7452 219.1216,7.19053333 216.644267,7.19053333 M216.4256,16.5918667 C215.8416,16.5918667 215.2336,16.5438667 214.650933,16.5198667 L214.650933,22.3252 L211.614933,22.3252 L211.614933,4.6892 C213.1936,4.49453333 214.676267,4.39586667 216.1336,4.39586667 C221.016267,4.39586667 223.518933,7.0692 223.518933,10.3972 C223.518933,14.0172 220.968267,16.5918667 216.4256,16.5918667" id="Fill-16" fill="#A0A0A0" mask="url(#mask-2)"></path>
            <polygon id="Fill-17" fill="#A0A0A0" mask="url(#mask-2)" points="227.748 22.3253333 230.784 22.3253333 230.784 4.51866667 227.748 4.51866667"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(HsApiLogo);
