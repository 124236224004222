import HfReactHelper from 'hellosign/components/hf-react-helper';

const webFlowUrl = 'https://www.hellosign.com';
const faqHsUrl = 'https://faq.hellosign.com';

const Constants = {
  topBarScreenThreshhold: 1024, // pixels
  contactUsUrl: {
    url: webFlowUrl.concat('/form/contact-us-general'),
    daCategory: 'contactus-form-start',
    daAction: location.pathname,
    daLabel: '/contact-us-general',

  },
  topBarData: [
    {
      url: '',
      key: 'products',
      text: 'Products',
      daCategory: 'menu',
      daAction: 'products',
      options: {
        class: 'm-link-list--link-break-1',
        containerClass: 'external-top-bar',
        subMenuItems: [
          [
            {
              url: webFlowUrl,
              text: 'HelloSign',
              daLabel: 'hs',
              img: 'hslogo',
              detail: {
                text: 'Legally Binding eSignature',
              },
            },
            {
              url: webFlowUrl.concat('/features'),
              text: 'Features',
              daLabel: 'hs-features',
            },
            {
              url: HfReactHelper.urlHelper('info/pricing', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'Pricing',
              daLabel: 'hs-pricing',
            },
            {
              url: webFlowUrl.concat('/enterprise'),
              text: 'Enterprise',
              daLabel: 'hs-enterprise',
            },
            {
              url: webFlowUrl.concat('/integrations/salesforce'),
              text: 'HelloSign for Salesforce',
              daLabel: 'hs-sfdc',
            },
            {
              url: webFlowUrl.concat('/integrations'),
              text: 'Integrations',
              daLabel: 'hs-integrations',
            },
          ],
          [
            {
              url: webFlowUrl.concat('/products/api'),
              text: 'HelloSign API',
              daLabel: 'hsapi',
              img: 'hs-api-logo',
              detail: {
                text: 'Fully Integrated eSignatures',
              },
            },
            {
              url: webFlowUrl.concat('/products/api/features'),
              text: 'Features',
              daLabel: 'hsapi-features',
            },
            {
              url: HfReactHelper.urlHelper('api/pricing', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'Pricing',
              daLabel: 'hsapi-pricing',
            },
            {
              url: HfReactHelper.urlHelper('api/documentation', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'Documentation',
              daLabel: 'hsapi-documentation',
            },
            {
              url: HfReactHelper.urlHelper('api/libraries', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'Libraries',
              daLabel: 'hsapi-libraries',
            },
            {
              url: HfReactHelper.urlHelper('api/reference', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'API Help',
              daLabel: 'hsapi-help',
            },
          ],
          [
            {
              url: webFlowUrl.concat('/products/helloworks'),
              text: 'HelloWorks',
              daLabel: 'hw',
              img: 'hwlogo',
              detail: {
                text: 'Intelligent Mobile Forms',
              },
            },
            {
              url: webFlowUrl.concat('/products/helloworks/features'),
              text: 'Features',
              daLabel: 'hw-features',
            },
            {
              url: webFlowUrl.concat('/products/helloworks-pricing'),
              text: 'Pricing',
              daLabel: 'hw-pricing',
            },
            {
              url: 'https://docs.helloworks.com/reference',
              text: 'Documentation',
              daLabel: 'hw-documentation',
            },
          ],
          [
            {
              url: webFlowUrl.concat('/products/hellofax'),
              text: 'HelloFax',
              daLabel: 'hf',
              img: 'hflogo',
              detail: {
                text: 'Easy Online Faxing',
              },
            },
            {
              url: webFlowUrl.concat('/products/hellofax/features'),
              text: 'Features',
              daLabel: 'hf-features',
            },
            {
              url: HfReactHelper.urlHelper('info/pricing', HfReactHelper.HfSites.SITE_CODE_HF),
              text: 'Pricing',
              daLabel: 'hf-pricing',
            },
            {
              url: faqHsUrl.concat('/hc/en-us/categories/200353237-HelloFax-Help'),
              text: 'FAQ',
              daLabel: 'hf-faq',
            },
          ],
          [
            {
              url: '',
              text: 'Resources',
            },
            {
              url: webFlowUrl.concat('/trust'),
              text: 'Trust & Security',
              daLabel: 'trust-security',
            },
            {
              url: faqHsUrl.concat('/hc/en-us'),
              text: 'Support',
              daLabel: 'support',
            },
            {
              url: faqHsUrl.concat('/hc/en-us/articles/360018757611-Getting-Started-Guide-'),
              text: 'Getting Started',
              daLabel: 'getting-started',
            },
            {
              url: '',
              text: '',
              daLabel: ' ',
            },
            {
              url: webFlowUrl.concat('/blog'),
              text: 'Blog',
              daLabel: 'blog',
            },
            {
              url: HfReactHelper.urlHelper('api/documentation', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'Developers',
              daLabel: 'developers',
            },
            {
              url: webFlowUrl.concat('/digitalstrength'),
              text: 'Digital Strength',
              daLabel: 'digital-strength',
            },
            {
              socialLinks: [
                {
                  img: 'facebook',
                  url: 'https://www.facebook.com/hellosignapp',
                  daLabel: 'facebook',
                },
                {
                  img: 'twitter',
                  url: 'https://www.twitter.com/HelloSign',
                  daLabel: 'twitter',
                },
                {
                  img: 'linkedin',
                  url: 'https://www.linkedin.com/company/hellosign',
                  daLabel: 'linkedin',
                },
              ],
            },
          ],
        ],
      },
    },
    {
      url: '',
      key: 'solutions',
      text: 'Solutions',
      daCategory: 'menu',
      daAction: 'solutions',
      options: {
        class: 'm-link-list--link-break-1',
        containerClass: 'external-top-bar',
        subMenuItems: [
          [
            {
              url: '',
              key: 'integrations',
              text: 'Integrations',
            },
            {
              url: webFlowUrl.concat('/integrations/dropbox'),
              text: 'Dropbox',
              img: 'dropbox',
              daLabel: 'integration-dropbox',
            },
            {
              url: webFlowUrl.concat('/integrations/gmail'),
              text: 'Gmail',
              img: 'gmail',
              daLabel: 'integration-gmail',
            },
            {
              url: webFlowUrl.concat('/integrations/google-docs'),
              text: 'Google Doc',
              img: 'googleDoc',
              daLabel: 'integration-google-docs',
            },
            {
              url: webFlowUrl.concat('/integrations/oracle'),
              text: 'Oracle',
              img: 'oracle',
              daLabel: 'integration-oracle-cpq',
            },
            {
              url: webFlowUrl.concat('/integrations/salesforce'),
              text: 'Sales Force',
              img: 'salesforce',
              daLabel: 'integration-sfdc',
            },
            {
              url: webFlowUrl.concat('/integrations/slack'),
              text: 'slack',
              img: 'slack',
              daLabel: 'integration-slack',
            },
          ],
          [
            {
              url: '',
              key: 'use cases',
              text: 'Use Cases',
            },
            {
              url: webFlowUrl.concat('/use-case/sales-teams'),
              text: 'Sales Contracts',
              daLabel: 'use-case-sales-contracts',
            },
            {
              url: webFlowUrl.concat('/use-case/onboarding'),
              text: 'Onboarding Paperwork',
              daLabel: 'use-case-onboarding-paperwork',
            },
            {
              url: webFlowUrl.concat('/solutions/real-estate-tech'),
              text: 'Mortgage and Escrow',
              daLabel: 'use-case-mortgage-escrow',
            },
            {
              url: webFlowUrl.concat('/solutions/fintech'),
              text: 'Financial Agreements',
              daLabel: 'use-case-financial-agreements',
            },
            {
              url: webFlowUrl.concat('/solutions/marketplaces'),
              text: 'On-Demand Services',
              daLabel: 'use-case-on-demand-services',
            },
            {
              url: webFlowUrl.concat('/solutions/insurance'),
              text: 'Insurance Documents',
              daLabel: 'use-case-insurance-documents',
            },
          ],
          [
            {
              url: '',
              key: 'customer stories',
              text: 'Customer Stories',
            },
            {
              url: webFlowUrl.concat('/customers/vehiculum'),
              text: 'Vehiculum',
              daLabel: 'customer-stories-vehiculum',
              detail: {
                text: 'Digitizing Sales conracts',
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('/customers/instacart'),
              text: 'Instacart',
              daLabel: 'customer-stories-instacart',
              detail: {
                text: 'Speeding up onboarding',
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('/customers/fountain'),
              text: 'Fountain',
              daLabel: 'customer-stories-fountain',
              detail: {
                text: 'Simplifying hiring paperwork',
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('/customers/climb-credit'),
              text: 'Climb Credit',
              daLabel: 'customer-stories-climb-credit',
              detail: {
                text: 'Light fast loan approvals',
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('/customers/advicepay'),
              text: 'Advice Pay',
              daLabel: 'customer-stories-advice-pay',
              detail: {
                text: 'Improving conract management',
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('/customers/cgia'),
              text: 'CGIA',
              daLabel: 'customer-stories-cgia',
              detail: {
                text: 'Streaming insurance forms',
                desktopOnly: true,
              },
            },
          ],
          [
            {
              url: '',
              text: 'Resources',
            },
            {
              url: webFlowUrl.concat('/trust'),
              text: 'Trust & Security',
              daLabel: 'trust-security',
            },
            {
              url: faqHsUrl.concat('/hc/en-us'),
              text: 'Support',
              daLabel: 'support',
            },
            {
              url: faqHsUrl.concat('/hc/en-us/articles/360018757611-Getting-Started-Guide-'),
              text: 'Getting Started',
              daLabel: 'getting-started',
            },
            {
              url: '',
              text: '',
            },
            {
              url: webFlowUrl.concat('/blog'),
              text: 'Blog',
              daLabel: 'blog',
            },
            {
              url: HfReactHelper.urlHelper('api/documentation', HfReactHelper.HfSites.SITE_CODE_HS),
              text: 'Developers',
              daLabel: 'developers',
            },
            {
              url: webFlowUrl.concat('/digitalstrength'),
              text: 'Digital Strength',
              daLabel: 'digital-strength',
            },
            {
              socialLinks: [
                {
                  img: 'facebook',
                  url: 'https://www.facebook.com/hellosignapp',
                  daLabel: 'facebook',
                },
                {
                  img: 'twitter',
                  url: 'https://www.twitter.com/HelloSign',
                  daLabel: 'twitter',
                },
                {
                  img: 'linkedin',
                  url: 'https://www.linkedin.com/company/hellosign',
                  daLabel: 'linkedin',
                },
              ],
            },
          ],
        ],
      },
    },
  ],
};

export default Constants;
