/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  camelcase,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  react/prop-types
*/
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'common/components/forms';
import {
  actions, Errors, Control, Form,
} from 'react-redux-form';
import HfReactHelper from 'common/utils/hf-react-helper';
import { stringify } from 'qs';
import superagent from 'superagent';
import { connect } from 'react-redux';
import Button from 'common/components/button';
import GoogleSignInButton from './google-signin-button';
import DropboxSignInButton from './dropbox-signin-button';
import LogInCaptcha from './login-captcha';
import './log-in-form.scss';

const FORM_NAME = 'logIn';

const messages = defineMessages({
  rememberMe: {
    id: '',
    defaultMessage: 'Remember me',
    description: 'remember me text on login page',
  },
  emailAddress: {
    id: '',
    defaultMessage: 'EMAIL ADDRESS',
    description: 'email address text on login page',
  },
  emailErrorMessage: {
    id: '',
    defaultMessage: 'Please enter a valid email address',
    description: 'email error message on login page',
  },
  forgotPassword: {
    id: '',
    defaultMessage: 'Forgot password?',
    description: 'forgot message on login page',
  },
  password: {
    id: '',
    defaultMessage: 'PASSWORD',
    description: 'password label on login page',
  },
  continue: {
    id: '',
    defaultMessage: 'Continue',
    description: 'continue button on login page',
  },
  login: {
    id: '',
    defaultMessage: 'login',
    description: 'login button on login page',
  },
});

export class LogInForm extends Component {
    static propTypes = {
      // URL used to submit the form via POST
      submitUrl: PropTypes.string.isRequired,

      // Provided from Redux state by connect
      pending: PropTypes.bool.isRequired,

      // Redux dispatcher object, provided from Redux by connect
      dispatch: PropTypes.func.isRequired,

      // Needed by the GoogleSignInButton component
      googleSignInClientId: PropTypes.string.isRequired,

      // needed for DropboxSigninButton component
      dropboxSSO: PropTypes.bool,

      googleSignInFallback: PropTypes.bool,

      // URL used to check if account is SAML enabled
      samlCheckUrl: PropTypes.string.isRequired,

      // Indicates SAML login
      samlLogin: PropTypes.bool,

      // Needed by the LogInCaptcha component
      recaptchaPublicKey: PropTypes.string.isRequired,

      // Decides whether the CAPTCHA should be displayed initially
      displayCaptcha: PropTypes.bool,
      intl: PropTypes.object,
    };

    constructor(props) {
      super(props);

      const { displayCaptcha } = this.props;

      this.state = {
        displayCaptcha,
        email: '',
        password: '',
      };

      this.handleGeneralError = this.handleGeneralError.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleResponse = this.handleResponse.bind(this);
      this.handleContinue = this.handleContinue.bind(this);
      this.handleSamlVerificationResponse = this.handleSamlVerificationResponse.bind(this);
      this.handleEditEmailAddress = this.handleEditEmailAddress.bind(this);
    }

    /**
     * Handles any generic/fallback errors we want to handle
     */
    handleGeneralError() {
      this.props.dispatch(actions.setSubmitFailed(FORM_NAME));
    }

    handleResponse(err, response) {
      // Error here means some kind of network error (4xx or 5xx)
      if (err) {
        return this.handleGeneralError(err);
      }

      try {
        // Here we can errors back from the server and display errors if
        // necessary
        const resp = response.body;

        // Create notification if backend provided one
        if (resp.notification) {
          hellofaxJS.createNotification({
            text: resp.notification.message,
            class_name: resp.notification.type,
          });
        }

        // Handle success and failure
        if (resp.success === true) {
          this.props.dispatch(actions.setSubmitted(FORM_NAME));

          // Redirect to on_login_redirect_url
          if (resp.on_login_redirect_url) {
            window.location = resp.on_login_redirect_url;
            Cookie.clear('deferred_login_redirect_url'); // Clear cookie since we don't need to defer the redirect
          }
        } else {
          this.props.dispatch(actions.setSubmitFailed(FORM_NAME));

          const hasErrorMsg = !!(resp.error && resp.error.message);

          if (this.state.displayCaptcha) {
            // Refresh CAPTCHA
            grecaptcha.reset();
          }

          if (resp.on_error_redirect_url) {
            // Redirect to on_error_redirect_url
            window.location = resp.on_error_redirect_url;
          } else if (hasErrorMsg && !resp.notification_message) {
            // Show CAPTCHA if backend said to
            this.setState({
              displayCaptcha: resp.display_captcha,
            });

            // Display error message
            this.props.dispatch(
              actions.setErrors(FORM_NAME, {
                server: resp.error.message,
              }),
            );
          }
        }

        if (response.error) {
          this.props.dispatch(
            actions.setErrors(FORM_NAME, {
              server: resp.error.message,
            }),
          );
        }
      } catch (e) {
        return this.handleGeneralError(err);
      }
    }

    handleSamlVerificationResponse(err, response) {
      try {
        const resp = response.body;
        if (resp.saml === true) {
          // Redirect the user to the SAML login
          window.location = resp.login_url;
        } else if (resp.error) {
          this.props.dispatch(
            actions.setErrors(FORM_NAME, {
              server: resp.error,
            }),
          );
        } else {
          // Open the organic login flow
          this.props.dispatch(actions.setInitial(FORM_NAME));
          this.props.dispatch(actions.change(`${FORM_NAME}.samlLogin`, false));
        }
      } catch (e) {
        return this.handleGeneralError(err);
      }
    }

    handleSubmit(values) {
      if (this.props.samlLogin !== false) {
        // Navigate the execution to the Continue click since user clicked ENTER key from the email field
        return this.handleContinue();
      }
      // Grab recaptcha response if present
      const captcha = document.getElementById('g-recaptcha-response');
      const captcha_response = captcha ? captcha.value : null;

      const data = stringify({
        login: {
          email_address: values.emailAddress,
          password: values.password,
          should_remember_me: values.rememberMe,
          _csrf_token: values.csrfToken,
          pending_tsm_group_guid: values.pendingTsmGroupGuid,
          redirect_url: values.redirectUrl,
        },
        async: 1,
        'g-recaptcha-response': captcha_response,
      });

      return superagent
        .post(this.props.submitUrl)
        .type('form')
        .send(data)
        .on('request', () => {
          this.props.dispatch(actions.setPending(FORM_NAME));
        })
        .end(this.handleResponse);
    }

    handleContinue() {
      const data = stringify({
        account: this.props.emailAddress,
        csrfToken: this.props.csrfToken,
        redirect_url: this.props.redirectUrl,
      });

      return superagent
        .post(this.props.samlCheckUrl)
        .type('form')
        .send(data)
        .on('request', () => {
          this.props.dispatch(actions.setPending(FORM_NAME));
        })
        .end(this.handleSamlVerificationResponse);
    }

    handleEditEmailAddress() {
      this.props.dispatch(actions.change(`${FORM_NAME}.samlLogin`, null));
    }

    render() {
      const {
        pending,
        recaptchaPublicKey,
      } = this.props;

      const captcha = this.state.displayCaptcha
            && <div className="l-margin-v-20">
              <LogInCaptcha
                recaptchaPublicKey={recaptchaPublicKey}
                tabIndex={4} />
            </div>;

      // Map site codes to site names
      const textVersions = {
        S: 'HelloSign',
        F: 'HelloFax',
        W: 'HelloWorks',
      };

      const emailInputField = <Control.input
        type="email"
        model="logIn.emailAddress"
        value={this.state.email}
        onChange={(event) => this.setState({ email: event.target.value })}
        autoFocus={!!(this.props.samlLogin === null && this.props.emailAddress)}
        onFocus={function (e) { const val = e.target.value; e.target.value = ''; e.target.value = val; }}
        validators={{
          isEmail: HfReactHelper.isValidEmailAddress,
        }} />;

      const emailStaticField = <div>
        <span className="m-email-static-field">{this.props.emailAddress}</span>
        <span className="m-edit-icon" onClick={this.handleEditEmailAddress}></span>
      </div>;

      // Determine which email block to use based on the state
      let emailField = emailInputField;
      let emailLabel = this.props.intl.formatMessage(messages.emailAddress);
      if (this.props.samlLogin === false && this.props.emailAddress) {
        emailField = emailStaticField;
        emailLabel = '';
      }

      // Invisible in the first step when we determine if the user is SAML
      const passwordField = this.props.samlLogin === false
            && <Field label={this.props.intl.formatMessage(messages.password)}>
              <Control.input
                type="password"
                value={this.state.password}
                onChange={(event) => this.setState({ password: event.target.value })}
                autoFocus={!!(this.props.samlLogin === false && this.props.emailAddress)}
                onKeyDown={this.handleKeyDown}
                model="logIn.password" />
              <a
                className="m-log-in-form--forgot-password-link"
                href="/account/recoverPassword"
                onClick={() => {
                  // Save email field value to sessionStorage for prefill on the next page
                  // (Only if emailAddress is not null, since sessionStorage casts that to "null")
                  if (this.props.emailAddress) {
                    window.sessionStorage.email = this.props.emailAddress;
                  }
                  return true;
                }}
              >
                {this.props.intl.formatMessage(messages.forgotPassword)}
              </a>
            </Field>;

      const loginBtn = this.props.samlLogin === false
            && <div className="m-log-in-form--submit small-6 columns">
              <Button
                buttonText={this.props.intl.formatMessage(messages.login)}
                buttonColor="azure"
                type="submit"
                disabled={pending}
                buttonAttrs={
                    {
                      'da-category': 'login',
                      'da-action': textVersions[this.props.siteCode],
                    }
                  } />
            </div>;

      const continueBtn = this.props.samlLogin !== false
            && <div className="m-log-in-form--continue small-6 columns">
              <Button
                buttonText={this.props.intl.formatMessage(messages.continue)}
                buttonColor="azure"
                onClick={this.handleContinue}
                disabled={pending}
                buttonAttrs={
                      {
                        'data-qa-ref': 'continue-button-login-page',
                        'da-category': 'login',
                        'da-action': textVersions[this.props.siteCode],
                      }
                  } />
            </div>;

      const formControlBtn = this.props.samlLogin === false ? loginBtn : continueBtn;

      const submitControls = <div className="m-log-in-form--submit-controls row">
        <div className="m-log-in-form--remember-me small-6 columns">
          <label>
            <Control.checkbox model="logIn.rememberMe" />{' '}
            {this.props.intl.formatMessage(messages.rememberMe)}
          </label>
        </div>
        {formControlBtn}
      </div>;

      return (
        <div className="m-log-in-form">
          <GoogleSignInButton
            clientId={this.props.googleSignInClientId}
            forceFallback={this.props.googleSignInFallback}
            redirectUrl={this.props.redirectUrl}
            csrfToken={this.props.csrfToken}
            buttonAttrs={
              {
                'da-category': 'login',
                'da-action': textVersions[this.props.siteCode],
                'da-label': null,
              }
            } />
          {this.props.dropboxSSO ? (
            <div className="l-margin-t-20">
              <DropboxSignInButton redirectUrl={this.props.redirectUrl} />
            </div>
          ) : null }
          <hr className="l-margin-v-40" />
          <Form model={FORM_NAME} onSubmit={this.handleSubmit}>
            <Field label={emailLabel}>
              {emailField}
              <Errors
                model="logIn.emailAddress"
                show="touched"
                messages={{
                  isEmail: this.props.intl.formatMessage(messages.emailErrorMessage),
                }}
                className="m-field--error" />
            </Field>

            {passwordField}

            {captcha}

            <div className="l-margin-v-20">
              <Errors
                model={FORM_NAME}
                show={true}
                className="c-blood"
                component={(props) => <span data-qa-ref="invalid-login-message">{props.children}</span>} />
            </div>

            {submitControls}

          </Form>
        </div>
      );
    }
}

function mapStateToProps(state) {
  const { $form } = state.forms.logIn;

  return {
    pending: $form.pending,
    emailAddress: state.logIn.emailAddress,
    csrfToken: state.logIn.csrfToken,
    redirectUrl: state.logIn.redirectUrl,
    samlLogin: state.logIn.samlLogin,
  };
}

export default injectIntl(connect(mapStateToProps)(LogInForm));
