/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  camelcase,
  guard-for-in,
  import/no-extraneous-dependencies,
  import/no-unresolved,
  max-len,
  no-restricted-syntax,
*/
import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import MktoForms2 from 'marketo-form';
import createReactClass from 'create-react-class';

/**
 * MarketoForm
 *
 * React component for embedding a Marketo Forms 2.0 form
 *
 * Marketo's API docs: http://developers.marketo.com/documentation/websites/forms-2-0/
 *
 * Normally, you embed a Marketo form like this:
 *
 *     <script src="//app-ab07.marketo.com/js/forms2/js/forms2.min.js"></script>
 *     <form id="mktoForm_1115"></form>
 *     <script>MktoForms2.loadForm("//app-ab07.marketo.com", "925-YQB-645", 1115);</script>
 *
 * This React component wraps that functionality for you. All you need to provide are the arguments to
 *
 *     MktoForms2.loadForm(baseUrl, munchkinId, formId, [onLoad callback])
 *
 * (as seen in the example above) as React props.
 *
 * TODO: Show some visual indication that the form is loading before Marketo injects it?
 * TODO: (That could be accomplished by defining a callback function here, which removes a loading indicator after load)
 */
const MarketoForm = createReactClass({

  // mixins: [watchAppNotifierMixin],

  propTypes: {
    baseUrl: PropTypes.string, // All of our forms use the same base URL so far, so this is optional
    munchkinId: PropTypes.string, // All of our forms use the same munchkinId so far, so this is optional
    formId: PropTypes.number.isRequired, // Numeric, typically 4-digit form ID
    className: PropTypes.string, // Optional class name(s) for the form element
    onLoad: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    trackingId: PropTypes.string, // Optional, will be used for sending as tracking action to GA
  },

  getInitialState() {
    return {
      showBlockedError: false,
      formLoaded: false,
    };
  },

  getDefaultProps() {
    return {
      baseUrl: '//app-ab07.marketo.com',
      munchkinId: '925-YQB-645',
      onLoad: null,
      className: '',
      trackingId: 'marketoform',
    };
  },

  componentDidMount() {
    // Then call loadForm on our div
    MktoForms2.loadForm(this.props.baseUrl, this.props.munchkinId, this.props.formId, this.onLoad);
    setTimeout(() => {
      if (!this.state.formLoaded) {
        this.setState({ showBlockedError: true });
      }
    }, 3000);
  },

  shouldComponentUpdate(newProps, newState) {
    if (newState.formLoaded) {
      // We don't want to re-render, since that would override the content injected by Marketo's script
      return false;
    }
    return true;
  },

  onLoad(form) {
    this.setState({ formLoaded: true });
    // Add values to hidden form fields
    // TODO: Ask Paul to add a hidden field for this, and supply timePeriod into it
    // Example: form.vals({"userIsAwesome":"true", "enrollDate":"2014-01-01"});

    // TODO: Also set email/name from account profile if logged in

    // Extract UTM tags from cookies
    const utm_cookies = {
      hf_source: '',
      hf_campaign: '',
      hf_medium: '',
      hf_channel: '',
      hf_source_lt: '',
      hf_campaign_lt: '',
      hf_medium_lt: '',
      hf_channel_lt: '',
    };
    const pairs = document.cookie.split(';');
    for (const k in pairs) {
      const parts = $.trim(pairs[k]).split('=');
      const name = parts.shift();
      const value = parts.join('=');
      if (name in utm_cookies) {
        utm_cookies[name] = value;
      }
    }

    // Add extracted UTM values to form submission
    form.setValues({
      UTM_Source__c: utm_cookies.hf_source,
      UTM_Campaign__c: utm_cookies.hf_campaign,
      UTM_Medium__c: utm_cookies.hf_medium,
      UTM_Channel__c: utm_cookies.hf_channel,
      LT_UTM_Source__c: utm_cookies.hf_source_lt,
      LT_UTM_Campaign__c: utm_cookies.hf_campaign_lt,
      LT_UTM_Medium__c: utm_cookies.hf_medium_lt,
      LT_UTM_Channel__c: utm_cookies.hf_channel_lt,
    });

    let onLoadFn = this.props.onLoad;
    if (onLoadFn && typeof onLoadFn !== 'function') {
      // onLoadFn refers to a function on window
      onLoadFn = window[this.props.onLoad];
    }
    if (onLoadFn) {
      // Execute onLoad function
      onLoadFn(form);
    }

    form.onSuccess(this.onFormSuccess);
  },

  onFormSuccess() {
    hellofaxJS.trackGoogleAnalyticsEvent(this.props.trackingId, 'Form', window.location.pathname + window.location.search);
  },

  render() {
    const formId = `mktoForm_${this.props.formId}`;
    const classNames = `rx-marketo-form ${this.props.className}`;

    const content = this.state.showBlockedError ? (
      'Due to your privacy settings we were unable to load this form. To see the form, please view on another browser'
    ) : null;

    return (
      <div className={'m-marketo-form'}>
        <form id={formId} className={classNames}>
          {content}
        </form>
      </div>
    );
  },
});

module.exports = MarketoForm;
