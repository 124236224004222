/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import BadgeComponent from 'hello-ui/badge';
import HsStyleProvider from 'hellosign/common/style/hs-style-provider';

export default class Badge extends React.Component {
  render() {
    return (
      <HsStyleProvider>
        <BadgeComponent type={this.props.type} >{this.props.children}</BadgeComponent>
      </HsStyleProvider>
    );
  }
}

Badge.propTypes = {
  children: PropTypes.string.isRequired, // The content
  type: PropTypes.string, // alert, success
};
