/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import createReactClass from 'create-react-class';

module.exports = createReactClass({
  displayName: 'API User Images',

  getDefaultProps() {
    return {
      apiUsersHeaderText: 'LOOK WHO\'S USING HELLOSIGN',
    };
  },

  renderImages() {
    const imageData = this.props.apiUsersImageData;
    return imageData.map((data, index) => {
      const imageProps = {
        className: 'l-vertical-align',
        src: data.imageUrl,
        height: data.style.height,
        width: data.style.width,
      };
      return <li className='m-feature-grid--list-item small-text-center' key={index}>
        <div className='m-feature-grid--item-image-wrapper'>
          <img {...imageProps} />
        </div>
      </li>;
    });
  },

  render() {
    return <div>
      <div className='small-12 small-centered small-text-center columns'>
        <div className='l-padding-b-10'>
          <h6 className='m-marketing-page--subheader-small c-ashen' style={{ fontSize: 18 }}>
            {this.props.apiUsersHeaderText}
          </h6>
        </div>
      </div>
      <div className='bg-plume m-feature-grid m-hs-companies--grid m-hs-companies--api--grid medium-12 columns'>
        <div className='row'>
          <div className='small-text-center'>
            <ul className='m-feature-grid--list-item-group small-block-grid-2 medium-block-grid-6'>
              {this.renderImages()}
            </ul>
          </div>
        </div>
      </div>
    </div>;
  },
});
