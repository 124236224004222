/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
module.exports = {
  NETWORK: 'network_error', // Lost connectivity
  CREDENTIALS: 'credentials_required', // Login required
  VERIFY_EMAIL: 'verify_email', // Verify email step for template links
  NOT_FOUND: 'not_found', // Resource not found
  SIGNER_CHOICE: 'signer_choice', // Must specify which signer they are
  NO_VALID_SIG_TYPES: 'no_valid_signature_types', // No signature type allowed due to settings
  SERVER: 'server_error', // Generic server error
  ACCESS_CODE_REQUIRED: 'access_code_required', // Must enter access code
  ACCESS_CODE_INVALID: 'access_code_invalid', // Access code entered is invalid
  ACCESS_CODE_BLOCKED: 'access_code_blocked', // Invalid access code entered too many times
  NOTHING_TO_SIGN: 'nothing_to_sign', // Nothing to signer or already signer
  BAD_REQUEST: 'bad_request', // Invalid request parameter(s)
  BAD_METHOD: 'bad_method', // Unexpected HTTP method
  UNKNOWN: 'unknown', // Unknown exception
};
