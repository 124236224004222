/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import createReactClass from 'create-react-class';

module.exports = createReactClass({
  displayName: 'NotificationMessage',

  getInitialState() {
    return { isShowing: true };
  },

  hideNotification() {
    this.props.handleRemove();
    this.setState({ isShowing: false });
  },

  getClassName() {
    let className = this.props.notification.data.class_name || 'info';
    className += this.state.isShowing ? ' notification-enter' : ' notification-leave';
    return className;
  },

  render() {

    const notification = this.props.notification;
    const className = this.getClassName();
    const styles = this.props.style;
    const text = {
      __html: notification.data.text || '', // NOTE: We trust this content since it's hard-coded messages
    };

    return (
      <div id={notification.id} className={className} style={styles}>
        <div className='columns small-12 text-center'>
          <div className='l-vertical-align-wrapper'>
            <div className='l-vertical-align'>
              <span className='m-notification--close' onClick={this.hideNotification}>&#215;</span>
              <p><strong>{ notification.data.header }</strong></p>
              <p dangerouslySetInnerHTML={text}></p>
            </div>
          </div>
        </div>
      </div>
    );
  },
});
