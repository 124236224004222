/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies
*/
import React from 'react';
import HfReactHelper from 'common/utils/hf-react-helper';

import createReactClass from 'create-react-class';
import Button from './button';
import SwapOutButton from './swap-out-button';
import ButtonTextSubmit from './button-text-submit';

const MobileLinkSubmit = createReactClass({

  propTypes: {},

  render() {
    const initialBtn = (<Button buttonText='EMAIL ME THE LINK' buttonClass='m-sign-mobile--send' buttonColor='grasshopper' />);

    const submitUrl = HfReactHelper.urlHelper('info/sendDownloadLink', HfReactHelper.HfSites.SITE_CODE_HS);
    const actionBtn = (<Button buttonText='SEND' buttonColor='grasshopper' />);
    const validateFunc = function (val) {
      if (!HfReactHelper.isValidEmailAddress(val)) {
        return 'Invalid email address.';
      } else {
        return false;
      }
    };
    const swapInChildren = (<ButtonTextSubmit
      actionButton={actionBtn}
      textPlaceholder='Enter email address'
      inputName='email'
      textClass='m-sign-mobile--text-field'
      url={submitUrl}
      divClass='m-sign-mobile--send'
      formName='mobileLinkFrm'
      validate={validateFunc} />);
    return (
      <SwapOutButton initialButton={initialBtn} swapInChildren={swapInChildren} />
    );
  },


});
module.exports = MobileLinkSubmit;
