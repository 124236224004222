/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-console,
  no-param-reassign,
  no-var,
  no-void,
  prefer-rest-params,
  vars-on-top
*/
import extend from 'lodash/object/extend';
import {
  INSERT, REMOVE, UPDATE, LOAD, RequestMessage,
} from 'common/messages';

module.exports = {

  /**
    * isNew is either explicitly set by you, or set via the existence of the data property, which is always set when when we have retrieved from the backend.
    */

  initialize() {

    if (!this.notifier && !process.env.NODE_ENV === 'test') {
      console.error('notifier does not exist for model apiEndpoints: %s, data: ', this.apiEndpoints, this.data);
    }

    if (!this.isNew) {
      this.isNew = function () {
        return !this.data;
      };
    }
  },

  /**
    */

  toJSON() {
    return this.toData();
  },

  /**
    */

  save() {
    const method = this.isNew() ? this.insert : this.update;
    return method.apply(this, arguments);
  },

  /**
    */

  insert() {
    return this._run(INSERT, ...arguments);
  },

  /**
    */

  update() {
    return this._run(UPDATE, ...arguments);
  },

  /**
    */

  load() {
    return this._run(LOAD, ...arguments);
  },

  /**
    */

  remove() {
    return this._run(REMOVE, ...arguments);
  },

  /**
    */

  async fetch(operationName, options, onRun) {

    if (typeof options === 'function') {
      onRun = options;
      options = {};
    }
    // KEEP THIS HERE. If notifier does not exist, throw a very descriptive & debuggable message. (CC)
    if (!this.notifier) {
      throw new Error(`notifier does not exist for model with apiEndpointAlias: ${this.apiEndpointAlias}`);
    }

    try {
      var [data] = await this.notifier.notify(
        RequestMessage.create(
          operationName,
          this.apiEndpointAlias,
          { target: this, ...options },
        ),
      );

      if (onRun) onRun(void 0, this);
    } catch (e) {
      if (onRun) onRun(e);
      throw e;
    }

    return data || {};
  },

  /**
    */

  _run(operationName, options, onRun) {

    if (typeof options === 'function') {
      onRun = options;
      options = {};
    }

    if (!onRun) {
      onRun = function () { };
    }

    const beforeOnRun = function (error, data) {

      const props = {
        loading: false,
      };

      if (error) {
        props.error = error;

        // check if there's any data at all
      } else if (Object.keys(data).length) {
        props.data = extend({}, data);
      }

      this.setProperties(props);
      onRun.apply(this, arguments);
    }.bind(this);

    this.setProperties({
      error: void 0,
      loading: true,
    });

    const ret = this.fetch(operationName, options);
    ret.then(beforeOnRun.bind(this, void 0), beforeOnRun);
    return ret;
  },
};
