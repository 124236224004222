/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  import/no-extraneous-dependencies,
  no-redeclare,
  no-var,
  vars-on-top
*/
import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import HfReactHelper from 'common/utils/hf-react-helper';

const PricingLevelFeature = createReactClass({
  render() {
    const classes = classNames({
      'm-pricing-level--feature': true,
      'small-12': true,
      columns: true,
      block: true,
      expanded: this.props.expanded,
    });

    const primaryColor = HfReactHelper.isHelloSign() ? 'c-cerulean' : 'c-goldenrod';
    if (this.props.planName && this.props.planName === 'Pro') {
      var numberClasses = classNames({
        number: true,
        [primaryColor]: this.props.name.toLowerCase().indexOf('per month') === 0,
      });
    } else {
      var numberClasses = classNames({
        number: true,
        [primaryColor]: !this.props.isFree,
      });
    }


    const nameClasses = classNames({
      name: true,
    });

    const number = (<div className={numberClasses}>{ this.props.number }</div>);
    const name = (<div className={nameClasses}>{ this.props.name }</div>);
    const detail = (<div className='detail'>{ this.props.detail }</div>);
    return (
      <div className={classes}>
        { number }
        { name }
        { detail }
      </div>
    );
  },
});

module.exports = PricingLevelFeature;
