/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-void,
  react/no-string-refs
*/
import React from 'react';
import setInterval from 'common/utils/set-interval';
import createReactClass from 'create-react-class';

require('./index.scss');

const Loader = createReactClass({

  getInitialState() {
    return {
      numCircles: 5,
      currentIndex: 0,
      circleRadius: 10,
    };
  },

  componentDidMount() {

    const numBubbles = this.refs.dots.querySelectorAll('circle').length;
    let i = 0;

    this._timer = setInterval(() => {
      this.setState({
        currentCircleIndex: i,
      });
      i = (i + 1) % numBubbles;
    }, 500);
  },

  componentWillUnmount() {
    clearInterval(this._timer);
  },

  render() {

    const circles = [];
    const radius = this.state.circleRadius;
    const circWidth = radius * 2;
    const padding = radius;

    // width and padding
    const wandp = circWidth + padding;

    for (let i = this.state.numCircles; i--;) {
      circles.push(
        <circle
          key={`circle${i}`}
          className={i === this.state.currentCircleIndex ? 'current-dot' : void 0}
          ref='5'
          cx={wandp * i + radius}
          cy={radius}
          r={radius}
        ></circle>,
      );
    }

    const width = (wandp * circles.length) - padding;
    let classNames = ' m-loader-dots whitelabel-dot-loader';

    if (this.props.className) {
      classNames += ` ${this.props.className}`;
    }

    return (
      <div className={classNames}>
        <svg width={'100%'} viewBox={`0 0 ${width} ${circWidth}`} version='1.1'>
          <g ref='dots'>{ circles }</g>
        </svg>
        { this.props.children ? <span className='message'>{ this.props.children }</span> : void 0 }
      </div>
    );
  },

});

module.exports = Loader;
