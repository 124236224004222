import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import hsFetch from 'hellospa/hs-fetch';
import HelloModal from 'common/components/hello-modal';
import EntityAccessList from './components/entity-access-list';
import EntityChooser from './components/entity-chooser';
import keymap from './constants/keymap';
import * as types from './constants/types';

/**
 * @typedef {Object} Entity
 * @property {string} guid
 * @property {string} name
 * @property {boolean} hasExplicitTemplateAccess
 * @property {string} type
 * @property {boolean} isAccessDirty
 * @property {boolean} isAccessDirtyFromSearch
 * @property {boolean} willAccessBeGranted
 * @property {boolean} willAccessBeRevoked
 * @property {?string} email
 * @property {?boolean} isAdmin
 * @property {?boolean} isOwner
 * @property {?boolean} isSelf
 */

/**
 * Remaps object properties to new keys.
 *
 * @param {Object} obj
 * @param {Object.<string>} keymap
 * @returns {Object}
 */
function normalizeKeys(obj, keymap) {
  return Object.entries(obj).reduce((acc, [key, value]) => ({
    ...acc,
    [keymap[key] || key]: value,
  }), {});
}

/**
 * @callback updateItemInArrayPredicate
 * @param {any} item
 * @returns {boolean}
 */

/**
 * @callback updateItemInArrayUpdater
 * @param {any} item
 * @returns {any}
 */

/**
 * Updates any item in the array whose predicate is truthy.
 *
 * @param {Array} arr
 * @param {updateItemInArrayPredicate} predicate
 * @param {updateItemInArrayUpdater} fn
 * @returns {Array}
 */
function updateItemInArray(arr, predicate, fn) {
  return arr.map((item) => (predicate(item) ? fn(item) : item));
}

/**
 * Updates an entity with the given GUID from the list of
 * all entities.
 *
 * @param {string} guid
 * @param {Entity[]} entities
 * @param {Object} obj
 * @returns {Entity[]}
 */
function updateEntityByGuid(guid, entities, obj) {
  return updateItemInArray(entities, (e) => e.guid === guid, (entity) => ({
    ...entity,
    ...obj,
  }));
}

/**
 * Grants template access to the given entity. If
 * `fromSearch` is true, access was granted via the entity
 * chooser.
 *
 * @param {Entity} entity
 * @param {Entity[]} entities
 * @param {boolean} [fromSearch]
 * @returns {Entity[]}
 */
function grantEntityAccess(entity, entities, fromSearch) {
  return updateEntityByGuid(entity.guid, entities, {
    isAccessDirty: true,
    isAccessDirtyFromSearch:
      fromSearch || entity.isAccessDirtyFromSearch,
    willAccessBeGranted: true,
    willAccessBeRevoked: false,
    dateLastSearched:
      fromSearch
        ? Date.now()
        : entity.dateLastSearched,
  });
}

/**
 * Revokes template access from the given entity.
 *
 * @param {Entity} entity
 * @param {Entity[]} entities
 * @returns {Entity[]}
 */
function revokeEntityAccess(entity, entities) {
  return updateEntityByGuid(entity.guid, entities, {
    isAccessDirty: true,
    willAccessBeGranted: false,
    willAccessBeRevoked: true,
  });
}

/**
 * Resets template access for the given entity.
 *
 * @param {Entity} entity
 * @param {Entity[]} entities
 * @returns {Entity[]}
 */
function resetEntityAccess(entity, entities) {
  return updateEntityByGuid(entity.guid, entities, {
    willAccessBeGranted: false,
    willAccessBeRevoked: false,
  });
}

/**
 * Sorts entities by account email address or team name
 * alphabetically.
 *
 * @param {Entity[]} entities
 * @returns {Entity[]}
 */
function sortEntitiesByLabel(entities) {
  return entities.sort((a, b) => {
    const aLabel = a.type === types.TEAM ? a.name : a.email;
    const bLabel = b.type === types.TEAM ? b.name : b.email;

    if (aLabel < bLabel) {
      return -1;
    } else if (aLabel > bLabel) {
      return 1;
    } else {
      return 0;
    }
  });
}

/**
 * Merges accounts and teams into a single entities array.
 *
 * @param {{ accounts: Object[], teams: Object[] }} data
 * @returns {Entity[]}
 */
function mergeEntities(data) {
  return [
    ...data.accounts.map((obj) => ({
      type: types.ACCOUNT,
      ...obj,
    })),
    ...data.teams.map((obj) => ({
      type: types.TEAM,
      ...obj,
    })),
  ];
}

/**
 * Returns a sorted array of entities from the given data.
 *
 * @param {{ accounts: Object[], teams: Object[] }} data
 * @param {string} accountGuid
 * @returns {Entity[]}
 */
function getEntitiesFromData(data, accountGuid) {
  return sortEntitiesByLabel(
    mergeEntities(data).map((entity) => normalizeKeys({
      ...entity,
      isAccessDirty: false,
      isAccessDirtyFromSearch: false,
      isSelf: entity.guid === accountGuid,
      willAccessBeGranted: false,
      willAccessBeRevoked: false,
    }, keymap)),
  );
}

/**
 * Returns entities that have new permissions.
 *
 * @param {Entity[]} entities
 * @returns {Entity[]}
 */
function getEntitiesWithNewPermissions(entities) {
  return entities.filter((entity) => (
    entity.isAccessDirty && (entity.willAccessBeGranted || entity.willAccessBeRevoked)
  ));
}

/**
 * Returns an object of new entity permissions which we
 * will use to send to the backend.
 *
 * @param {Entity[]} entities
 * @returns {Object.<number>}
 */
function getPermissions(entities) {
  return getEntitiesWithNewPermissions(entities).reduce((acc, entity) => {
    if (entity.type === types.ACCOUNT) {
      acc.accts = {
        ...acc.accts,
        [entity.guid]: entity.willAccessBeGranted ? '1' : '0',
      };
    } else {
      acc.teams = {
        ...acc.teams,
        [entity.guid]: entity.willAccessBeGranted ? '1' : '0',
      };
    }

    return acc;
  }, {
    accts: {},
    teams: {},
  });
}

const messages = defineMessages({
  teamAccess: {
    id: '',
    description: 'header plain text in team access modal of doc template page',
    defaultMessage: 'Template Access',
  },
  cancel: {
    id: '',
    description: 'text on button that closes the team access modal of doc template page',
    defaultMessage: 'Cancel',
  },
  save: {
    id: '',
    description: 'text on button to save changes of template access list of doc template page',
    defaultMessage: 'Save',
  },
  done: {
    id: '',
    description: 'text on button of team access loading modal to stop and close it',
    defaultMessage: 'Done',
  },
  saving: {
    id: '',
    description: 'text on button of team access modal incicating the changes are being saved',
    defaultMessage: 'Saving…',
  },
  loading: {
    id: '',
    description: 'content plain text in team access modal indicating modal is loading template access list',
    defaultMessage: 'Loading…',
  },
});

/**
 * Renders the team access modal.
 *
 * @param {Object} props
 * @param {string} props.accountGuid
 * @param {string} props.templateGuid
 * @param {string} props.csrfToken
 * @returns {JSX.Element}
 */
function TeamAccessModal({ accountGuid, templateGuid, csrfToken }) {
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [entities, setEntities] = React.useState([]);
  const [recentEntity, setRecentEntity] = React.useState(null);
  const intl = useIntl();

  const canSave = React.useMemo(() => {
    return getEntitiesWithNewPermissions(entities).length >= 1;
  }, [entities]);

  React.useEffect(() => {
    setIsLoading(true);

    hsFetch(`/template/permissions?guid=${templateGuid}`).then((response) => {
      if (response.ok) {
        response.json().then((obj) => {
          const allEntities = getEntitiesFromData(obj, accountGuid);

          setIsEditable(obj.is_editable_by_account);
          setEntities(allEntities);
          setIsLoading(false);
        });
      }
    });
  }, [accountGuid, templateGuid]);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const saveModal = React.useCallback(() => {
    const permissions = getPermissions(entities);

    setIsSaving(true);

    if (Object.keys(permissions.accts).length || Object.keys(permissions.teams).length) {
      hsFetch('/home/saveTemplatePermissions?json=1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          csrf_token: csrfToken,
          guid: templateGuid,
          permissions,
        }),
      }).then((response) => {
        setIsSaving(false);

        if (response.ok) {
          closeModal(false);
        }
      });
    } else {
      closeModal(false);
    }
  }, [entities, csrfToken, templateGuid, closeModal]);

  const grantAccess = React.useCallback((entity, fromSearch) => {
    setRecentEntity(entity.guid);

    setEntities((currentState) => (
      grantEntityAccess(entity, currentState, fromSearch)
    ));
  }, []);

  const revokeAccess = React.useCallback((entity) => {
    setRecentEntity(entity.guid);

    setEntities((currentState) => (
      revokeEntityAccess(entity, currentState)
    ));
  }, []);

  const resetAccess = React.useCallback((entity) => {
    setRecentEntity(entity.guid);

    setEntities((currentState) => (
      resetEntityAccess(entity, currentState)
    ));
  }, []);

  return (
    <HelloModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      extraClasses="team-access-modal"
      contentLabel={intl.formatMessage(messages.teamAccess)}
      buttons={
        isEditable ? [
          {
            type: 'secondary',
            text: intl.formatMessage(messages.cancel),
            onClick: closeModal,
          },
          {
            type: 'primary',
            // eslint-disable-next-line max-len
            text: isSaving ? intl.formatMessage(messages.saving) : intl.formatMessage(messages.save),
            disabled: !canSave || isSaving,
            onClick: saveModal,
          },
        ] : [
          {
            type: 'primary',
            text: intl.formatMessage(messages.done),
            onClick: closeModal,
          },
        ]
      }
    >
      {isLoading ? <p>{intl.formatMessage(messages.loading)}</p> : (
        <React.Fragment>
          {isEditable ? (
            <EntityChooser
              entities={entities}
              grantAccess={grantAccess}
              resetAccess={resetAccess} />
          ) : null}
          <EntityAccessList
            entities={entities}
            recentEntity={recentEntity}
            isEditable={isEditable}
            grantAccess={grantAccess}
            revokeAccess={revokeAccess}
            resetAccess={resetAccess} />
        </React.Fragment>
      )}
    </HelloModal>
  );
}

export default TeamAccessModal;
