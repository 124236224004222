/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-bitwise,
  no-continue,
  no-restricted-syntax,
  prefer-rest-params
*/
import { createFactory, extend } from 'common/utils/class';
import { ChangeMessage } from 'common/messages';

function BaseCollection(properties, values = []) {
  this.push(...values);
  this.setProperties(properties);
}

Object.assign(BaseCollection.prototype, {

  setProperties(properties) {
    Object.assign(this, properties);
  },

  push() {
    return this.splice(this.length, 0, ...arguments);
  },

  unshift() {
    return this.splice(0, 0, ...arguments);
  },

  shift() {
    return this.splice(0, 1);
  },

  pop() {
    return this.splice(this.length - 1, 1);
  },

  remove(...values) {
    for (const value of values) {
      const i = this.indexOf(value);
      if (~i) this.splice(i, 1);
    }
  },

  /**
    * all mutation methods go through here
    */

  splice() {
    const ret = Array.prototype.splice.apply(this, arguments);
    if (this.notifier) this.notifier.notify(ChangeMessage.create(this));
    return ret;
  },

});

BaseCollection.create = createFactory(Array);
BaseCollection.extend = extend;

// CANNOT extend array. Copy props instead
for (const prop of Object.getOwnPropertyNames(Array.prototype)) {
  if (BaseCollection.prototype[prop]) continue;
  const value = Array.prototype[prop];
  if (typeof value === 'function') BaseCollection.prototype[prop] = value;
}

BaseCollection.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];

export default BaseCollection;
