import React from 'react';
import { makeContextCacher } from 'common/utils/context';
import { withAppContext } from 'hellospa/app-context';
import PropTypes from 'prop-types';
import LoaderSpinner from 'hellospa/components/loader-spinner';
import signatureContext from './context';
import { Type } from './prop-types';
import CONSTANTS from './constants';
import HfReactHelper from '../../hellosign/components/hf-react-helper';

class SignatureProvider extends React.PureComponent {
  static propTypes = {
    csrfToken: PropTypes.string.isRequired,

    isMobile: PropTypes.bool,
    signature: PropTypes.shape({
      /**
       * Email guid and email upload address are used in the signature modal for
       * the "Use Smartphone" tab
       */
      signatureEmailGuid: PropTypes.string.isRequired,
      initialsEmailGuid: PropTypes.string.isRequired,
      emailUploadAddress: PropTypes.string.isRequired,
      type: Type,
    }),
    appContext: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    defaultSignatureType: PropTypes.string,
    allowedSignatureTypes: PropTypes.object,
    hideSpinner: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    isDropbox: PropTypes.bool,
    canInsertEverywhere: PropTypes.bool,
  };

  retries = 0;

  contextCache = makeContextCacher();

  /** Convert signature data list to signatures list */
  fromSignaturesData(data) {
    const signatures = [];
    if (data) {
      return data.map((item) => ({
        type: item.type_code,
        createType: item.create_type_code,
        guid: item.signature_id,
        createdAt: item.created_at ? new Date(item.created_at * 1000) : null,
        lastUsedAt: item.last_used_at ? new Date(item.last_used_at * 1000) : null,
        width: item.width,
        height: item.height,
        signature: item.signature || null,
      }));
    }
    return signatures;
  }

  getInitialValue(userSettings, type = 'signature') {
    let initialValue = '';
    if (userSettings.firstName) {
      initialValue += type === 'signature' ? userSettings.firstName : userSettings.firstName[0];
    }

    if (userSettings.lastName) {
      initialValue += type === 'signature' ? ` ${userSettings.lastName}` : userSettings.lastName[0];
    }

    return initialValue;
  }

  async componentDidMount() {
    const signaturesData = await this.props.appContext.fetchSignatures('S');
    const initialsData = await this.props.appContext.fetchSignatures('I');
    const signatures = this.fromSignaturesData(signaturesData);
    const initials = this.fromSignaturesData(initialsData);

    const user = this.props.appContext.data.user;
    const selected = user.primarySignatureGuid;
    const initiallySelectedSignature = signatures.find((item) => item.guid === selected);

    // allowed signature types are passed in as an object with key as the type and a boolean value,
    const allowedSignatureTypes = this.props.allowedSignatureTypes != null
      ? Object.keys(this.props.allowedSignatureTypes).filter((t) => {
        return this.props.allowedSignatureTypes[t] === true;
      }) : CONSTANTS.TABS;

    // if it's embedded, don't default to the Saved Signatures tab
    const defaultTab = (this.props.isEmbedded && !this.props.isDropbox)
      ? CONSTANTS.TAB_DRAW : CONSTANTS.TAB_SAVED;
    // check if the default type is part of allowed types
    const defaultSignatureType = allowedSignatureTypes.includes(this.props.defaultSignatureType)
      ? this.props.defaultSignatureType
      : defaultTab;

    this.setState({
      // Set initial data
      savedSignatures: signatures,
      savedInitials: initials,
      selectedSavedSignature: initiallySelectedSignature || signatures[0],
      signatureEmailGuid: this.props.signature.signatureEmailGuid,
      initialsEmailGuid: this.props.signature.initialsEmailGuid,
      emailUploadAddress: this.props.signature.emailUploadAddress,
      csrfToken: this.props.csrfToken,
      // Default states
      initialsInitialTypeInValue: this.getInitialValue(user.settings, 'initial'),
      signatureInitialTypeInValue: this.getInitialValue(user.settings),
      isCreatingDrawnSignature: false,
      isCreatingTypedSignature: false,
      isUploadingSignature: false,
      isCheckingEmailedSignatureStatus: false,
      uploadedSignature: null,
      emailedSignature: null,
      selectedTab: defaultSignatureType,
      enabledTabs: allowedSignatureTypes,
      isEmbedded: this.props.isEmbedded,
      isDropbox: this.props.isDropbox,
      canInsertEverywhere: this.props.canInsertEverywhere,
    });
  }


  handleSelectTab = (selectedTab) => this.setState({ selectedTab });

  getSignatureUrl = (signature, threshold, degrees) => {
    let thresholdAndDegrees = '';
    if (threshold !== undefined && degrees !== undefined) {
      thresholdAndDegrees = `&threshold=${threshold}&degrees=${degrees}`;
    }
    return HfReactHelper.attachSessionInfoToUrl(`/attachment/view?sig_guid=${signature.guid}${thresholdAndDegrees}`);
  };

  onCheckEmailedSignatureStatus = async (type) => {
    this.setState({ isCheckingEmailedSignatureStatus: true });

    const emailGuid = type === CONSTANTS.TYPE_SIGNATURE
      ? this.state.signatureEmailGuid
      : this.state.initialsEmailGuid;

    const sig = {
      emailGuid,
      type,
      createType: CONSTANTS.SIGNATURE_TYPE_EMAIL,
    };

    try {
      const response = await this.props.appContext.checkEmailedSignatureStatus(emailGuid);
      if (response.data.status === 'ok') {
        // Setup the signature obj and refresh guid
        sig.guid = response.data.signature_guid;
        sig.width = response.data.width;
        sig.height = response.data.height;

        this.setState({
          emailedSignature: sig,
          isCheckingEmailedSignatureStatus: false,
        });

        // Refresh email guid
        this.refreshGuid(response.data.new_email_guid, type);
      } else if (response.data.status === 'pending') {
        // Pending
        // Repeat the cycle as retries permit
        this.retries++;
        if (this.retries < CONSTANTS.EMAIL_STATUS_MAX_RETRIES) {
          setTimeout(() => {
            this.onCheckEmailedSignatureStatus(type);
          }, CONSTANTS.EMAIL_STATUS_RETRY_PERIOD);
        } else {
          // Error
          this.setState({ isCheckingEmailedSignatureStatus: false });
          // eslint-disable-next-line no-console
          console.error('Error with email guid status');
        }
      } else {
        this.setState({ isCheckingEmailedSignatureStatus: false });
        // eslint-disable-next-line no-console
        console.error('Error with email guid status');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return sig;
  };

  onSavedSignatureRemove = async (signature) => {
    try {
      const success = await this.props.appContext.removeSignature(
        signature.guid,
        this.props.csrfToken,
      );
      if (success) {
        if (signature.type === CONSTANTS.TYPE_SIGNATURE) {
          this.setState({
            savedSignatures: this.state.savedSignatures.filter((s) => s !== signature),
          });
        } else {
          this.setState({
            savedInitials: this.state.savedInitials.filter((s) => s !== signature),
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  onSavedSignatureSelect = (selectedSavedSignature) => {
    this.setState({
      selectedSavedSignature,
    });
  };

  onRotateSignature = (signature) => {
    return this.props.appContext.rotateSignature(
      signature.guid,
      CONSTANTS.ROTATE_DEGREES_AMOUNT,
      this.props.csrfToken,
    );
  };

  onUploadSignature = async (file, type) => {
    this.setState({ isUploadingSignature: true });

    const sig = {
      file,
      type,
      createType: CONSTANTS.SIGNATURE_TYPE_UPLOAD,
    };

    const response = await this.props.appContext.uploadSignatureFile(sig, this.props.csrfToken);
    // Setup the signature obj from the response
    if (response) {
      sig.guid = response.signature_id;
      sig.width = response.width;
      sig.height = response.height;

      this.setState({
        uploadedSignature: sig,
        isUploadingSignature: false,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Could not upload the signature file');
      this.setState({ isUploadingSignature: false });
    }
  };

  onSignatureData = (data) => {
    const uploadFunc = data.create_type_code === CONSTANTS.SIGNATURE_TYPE_CANVAS
      ? this.props.appContext.uploadCanvas
      : this.props.appContext.upload;
    return uploadFunc(data, this.props.csrfToken)
      .then((obj) => {
        return this.props.appContext.fetchSignatures(data.type_code)
          .then((signaturesArr) => {
            const signatures = this.fromSignaturesData(signaturesArr);

            if (typeof obj.signature_id !== 'undefined') {
              // Replace signature_id with signature_guid,
              // because that's how we expect it in the code later
              obj.signature_guid = obj.signature_id;
              delete obj.signature_id;
            }

            if (data.type_code === CONSTANTS.TYPE_SIGNATURE) {
              this.setState({
                savedSignatures: signatures,
              });
            } else {
              this.setState({
                savedInitials: signatures,
              });
            }

            // Reset the emailed signature tab
            this.setState({
              emailedSignature: null,
              uploadedSignature: null,
            });

            return obj;
          });
      });
  };

  /**
   * Assigns a new email guid to the Use Smartphone option
   * for either Signature or Initials
   */
  refreshGuid = (guid, type) => {
    if (type === CONSTANTS.TYPE_SIGNATURE) {
      this.setState({
        signatureEmailGuid: guid,
      });
    } else {
      this.setState({
        initialsEmailGuid: guid,
      });
    }
  };

  render() {
    if (this.state == null && !this.props.hideSpinner) {
      return (
        <LoaderSpinner />
      );
    }

    const {
      savedSignatures,
      savedInitials,
      selectedSavedSignature,
      signatureEmailGuid,
      initialsEmailGuid,
      emailUploadAddress,
      csrfToken,
      isUploadingSignature,
      isCheckingEmailedSignatureStatus,
      uploadedSignature,
      emailedSignature,
      initialsInitialTypeInValue,
      signatureInitialTypeInValue,
      selectedTab,
      enabledTabs,
      isEmbedded,
      isDropbox,
      canInsertEverywhere,
    } = this.state || {};

    const value = this.contextCache({
      getSignatureUrl: this.getSignatureUrl,
      onCheckEmailedSignatureStatus: this.onCheckEmailedSignatureStatus,
      onSavedSignatureRemove: this.onSavedSignatureRemove,
      onSavedSignatureSelect: this.onSavedSignatureSelect,
      onRotateSignature: this.onRotateSignature,
      onUploadSignature: this.onUploadSignature,
      onSignatureData: this.onSignatureData,
      onTabSelect: this.handleSelectTab,
      savedSignatures,
      savedInitials,
      selectedSavedSignature,
      signatureEmailGuid,
      initialsEmailGuid,
      emailUploadAddress,
      csrfToken,
      isMobile: this.props.isMobile || false,
      isUploadingSignature,
      isCheckingEmailedSignatureStatus,
      uploadedSignature,
      emailedSignature,
      initialsInitialTypeInValue,
      signatureInitialTypeInValue,
      selectedTab,
      enabledTabs,
      isEmbedded,
      isDropbox,
      canInsertEverywhere,
    });

    return (
      <signatureContext.Provider value={value}>
        {this.props.children}
      </signatureContext.Provider>
    );
  }
}

export default withAppContext(SignatureProvider);
