/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  prefer-rest-params
*/
import BaseNotifier from './base';

function AttachDefaultsNotifier(defaults, notifier) {
  this.defaults = defaults;
  this.notifier = notifier;
}

BaseNotifier.extend(AttachDefaultsNotifier, {
  notify(message) {
    Object.assign(message, this.defaults, message);
    return this.notifier.notify(message);
  },
  push() {
    return this.notifier.push(...arguments);
  },
});

export default AttachDefaultsNotifier;
