/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';

function PricingCTA(p) {
  const className = `seo-cloud-integrations__main__free_accounts__${p.position}`;

  const text = p.text ? p.text : 'Free accounts include three documents a month. Upgrade to a business plan for unlimited documents.';
  const linkUrl = p.linkUrl ? p.linkUrl : 'info/pricing';
  const linkText = p.linkText ? p.linkText : 'See Our Plans';
  const link = <a href={linkUrl} style={{ fontWeight: 500 }} onClick={p.onClick}>{linkText}</a>;

  return (
    <div className={className} style={p.style}>
      {text}&nbsp;{link}.
    </div>
  );
}

module.exports = PricingCTA;
