import React from 'react';
// @ts-ignore
import deepExtend from 'lodash/object/merge';
// @ts-ignore
import flattenMessages from 'common/utils/flatten-messages';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import hsFetch from 'hellospa/hs-fetch';
// @ts-ignore
import HfReactHelper from 'common/utils/hf-react-helper';

let defaultMessages: Record<string, string>;
export const getDefaultMessages = () => {
  if (defaultMessages == null) {
    /* eslint-disable global-require */
    defaultMessages = flattenMessages(deepExtend(
      require('signer/translations/en-US.json'),
      require('ui/signature-modal/translations/en-US.json'),
      require('hellospa/components/editor/translations/en-US.json'),
    ));
    /* eslint-enable global-require */
  }
  return defaultMessages;
};


type SmartlingMessages = {
  smartling: Object,
  messages : {
    [key: string] : {
      translation : string,
      instruction?: string
    },
  }
}
declare global {
  interface Window {
    smartling?: SmartlingMessages
    locale?: string,

    config?: {
      intl?: {
        locales: string[],
        messages: Object,
      }
    }
  }
}

const { configLocale, configMessages } = (function ReadWindowConfig() {
  let configLocale = 'en-US';
  let configMessages: Record<string, string> | null = null;

  if (window.config && window.config.intl) {
    const { locales, messages } = window.config.intl;
    if (Array.isArray(locales) && locales.length > 0) {
      configLocale = locales[0];
    }

    configMessages = flattenMessages(messages);
  }

  return { configLocale, configMessages };
}());

export { configMessages };

// To activate this in development run this in the console and refresh the page:
// localStorage.intlMessages = {}
const storageKey = 'intlMessages';

let updatingIntlMessages = false;
function updateIntlMessages() {
  // localStorage my throw if it's being blocked
  try {
    if (
      process.env.NODE_ENV === 'production'
      || updatingIntlMessages
      || window.location.host !== 'app.dev-hellosign.com'
      || !localStorage[storageKey]
    ) {
      return;
    }
    // Assume it will complete so this doesn't start multiple fetches
    updatingIntlMessages = true;

    // eslint-disable-next-line no-console
    console.warn(`Updating localStorage.${storageKey}`);
    // This might not work outside of development due to CORS. The development
    // CDN allows it using `Access-Control-Allow-Origin: *``
    hsFetch(`https://${HfReactHelper.hsCdn}/build/hellosign-web-messages.json`)
      .then((response) => response.json())
      .then((debugMessages) => {
        Object.entries(debugMessages.messages).forEach(([_key, value]: any[]) => {
          value.translation = `[${value.translation}]`;
        });

        localStorage[storageKey] = JSON.stringify(debugMessages);
      });
  } catch (e) {
    // ignore localStorage errors
  }

}

export const { messages, locale } = (function ConvertMessages() {
  const { locale = configLocale } = window;
  const messages = {} as Record<string, string>;

  function collectSmartlingMessages(smartling?: SmartlingMessages) {
    if (smartling) {
      const keys = Object.keys(smartling.messages);

      for (let i = 0; i < keys.length; i++) {
        const id = keys[i];
        messages[id] = smartling.messages[id].translation;
      }
    }
  }

  if (
    // QA uses a development build
    process.env.NODE_ENV !== 'production'
    && window.location.host === 'app.dev-hellosign.com'
  ) {
    // localStorage my throw if it's being blocked
    try {
      if (localStorage[storageKey] != null) {
        const debugMessages = JSON.parse(localStorage[storageKey]);
        collectSmartlingMessages(debugMessages as SmartlingMessages);
      }
    } catch (e) {
      // do nothing
    }
  }
  collectSmartlingMessages(window.smartling);

  return { messages, locale };
}());


// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

// const [language] = locale.split('-');
const language = 'foo';
/* eslint-disable global-require, comma-dangle, import/no-dynamic-require */
// SyntaxError: Trailing comma is disallowed inside import(...) arguments
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  try {
    require(`@formatjs/intl-pluralrules/dist/locale-data/${language}`);
  } catch (e) {
    require('@formatjs/intl-pluralrules/dist/locale-data/en');
  }
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  try {
    require(`@formatjs/intl-relativetimeformat/dist/locale-data/${language}`);
  } catch (e) {
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  }
}
/* eslint-enable global-require, comma-dangle, import/no-dynamic-require */


const errors: Record<string, boolean> = {};
export function onError(error: string) {
  if (process.env.NODE_ENV !== 'production') {
    // Suppress errors about missing messages if we haven't enabled smartling.
    // This checks for a hash so we do see an error if any signer translations
    // are missing.
    if (!window.smartling && error.match(/Missing message: "[0-9a-f]{64}"/)) {
      updateIntlMessages();
      return;
    }

    // Only show the error once. The default implementation spams the console
    // on every render.
    if (!errors[error]) {
      errors[error] = true;
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export const intl = createIntl({
  locale,
  messages: {
    ...getDefaultMessages(),
    ...messages,
  },
  onError,
}, cache);

type Props = {
  children: React.ReactNode,
}

export default function HSIntlProvider({ children }: Props) {
  return (
    <RawIntlProvider value={intl}>
      {children}
    </RawIntlProvider>
  );
}
