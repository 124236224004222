/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const FormattedFaxNumber = createReactClass({

  propTypes: {
    faxNumber: PropTypes.string.isRequired,
  },

  render() {
    const formattedFaxNumber = hellofaxJS.formatInternationalFaxNumber(this.props.faxNumber);
    return <span className="fax-number">{formattedFaxNumber}</span>;
  },

});
module.exports = FormattedFaxNumber;
