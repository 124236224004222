/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import HfReactHelper from 'hellosign/components/hf-react-helper';

module.exports = {
  TYPE_SIGNATURE: 'S',
  TYPE_INITIALS: 'I',
  ORIENTATION_PORTRAIT: 'P',
  ORIENTATION_LANDSCAPE: 'L',
  SCREEN_SMALL: 'small',
  SCREEN_MEDIUM: 'medium',
  SCREEN_LARGE: 'large',
  TAB_SAVED: 'S',
  TAB_DRAW: 'C',
  TAB_TYPE: 'T',
  TAB_UPLOAD: 'U',
  TAB_EMAIL: 'E',
  TAB_ERROR: 'ERROR',
  TABS: ['S', 'C', 'T', 'U', 'E', 'ERROR'],
  MOBILE_TABS: ['S', 'C', 'T'], // FIXME: remove once tests are revised
  SIGNATURE_TYPE_CANVAS: 'C',
  SIGNATURE_TYPE_TYPED: 'T',
  SIGNATURE_TYPE_UPLOAD: 'U',
  SIGNATURE_TYPE_EMAIL: 'E',
  CAROUSEL_LENGTH: 3,
  CURSIVE_FONTS: Object.keys(HfReactHelper.FONT_CHAR_MAP),
  CONTRAST_MIN_THRESHOLD: 16,
  CONTRAST_MAX_THRESHOLD: 64,
  CONTRAST_STEP_AMOUNT: 12,
  ROTATE_DEGREES_AMOUNT: 90,
  EMAIL_STATUS_MAX_RETRIES: 20,
  EMAIL_STATUS_RETRY_PERIOD: 3000,
  DRAW_TAB_REDRAW_MAX_RETRIES: 5,
  DRAW_TAB_REDRAW_INITIAL_INTERVAL: 100, // ms
  ACCEPTED_UPLOAD_TYPES: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
  MAX_UPLOAD_SIZE: 40000000, // 40MB
};
