/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  global-require,
  max-len,
  no-param-reassign,
  no-void
*/
import BaseModel from 'common/models/base/model';
import HfReactHelper from 'common/utils/hf-react-helper';

/**
 * Signature object
 *
 * @property String type               Signature or initials
 * @property String createType         Method used to create the signature
 * @property Array signature           Raw signature data (only set when new)
 * @property String guid               Identifier
 * @property Date createdAt            Creation time
 * @property Date lastUsedAt           Last time it was used (null = never)
 * @property Integer width             Image width in pixels
 * @property Integer height            Image height in pixels
 * @property String  file              File path to upload (null except when uploading)
 * @property Object  editParams        Parameters (x, y, width, height, threshold, degrees) to send for image editing (null when not editing)
 * @property String  emailGuid         signature_email_guid or initials_email_guid
 */

module.exports = BaseModel.extend({

  apiEndpointAlias: 'signature',

  TYPE_INITIALS: 'I',
  TYPE_SIGNATURE: 'S',

  mixins: [
    require('common/models/mixins/model'),
  ],

  /*
     * Takes optional threshold parameters for signature editing
     */
  getUrl(threshold, degrees) {

    let uri = `/attachment/view?sig_guid=${this.guid}`;

    if (arguments.length > 0) {
      uri = `/attachment/view?sig_guid=${this.guid}`;
      uri += `&threshold=${threshold}&degrees=${degrees || 0}`;
    }

    return HfReactHelper.attachSessionInfoToUrl(uri);
  },


  //  ----  DATA  ---------------------------------------

  toData() {
    return {
      guid: this.guid,
      createdAt: this.createdAt,
      lastUsedAt: this.lastUsedAt,
      width: this.width,
      height: this.height,
      type: this.type,
      signature: this.signature,
      file: this.file,
      emailGuid: this.emailGuid,
    };
  },

  fromData(data) {
    if (data) {
      return {
        type: data.type_code,
        createType: data.create_type_code,
        guid: data.signature_id,
        createdAt: data.created_at ? new Date(data.created_at * 1000) : null,
        lastUsedAt: data.last_used_at ? new Date(data.last_used_at * 1000) : null,
        width: data.width,
        height: data.height,
        signature: data.signature || null,
      };
    }
  },

  getEditParams() {
    return {
      width: this.width,
      height: this.height,
      editParams: this.editParams,
    };
  },


  //  ----  ACTIONS  ------------------------------------

  rotate(onRotate) {
    onRotate = onRotate || function () {};
    return this.fetch('rotate')
      .then(onRotate.bind(null, void 0))
      .catch(onRotate);
  },

  markAsPrimary() {
    return this.fetch('markAsPrimary');
  },

  /*
     * Used for saving cropping / contrast / rotation results
     * TODO - make this more descriptive -- edit() is a bit vague (CC)
     */

  edit() {
    return this.fetch('edit');
  },

  /*
     * Sets options for the crop endpoint edit step
     */
  setEditParams(editParams) {
    this.editParams = editParams;
  },

  /*
     * Gets status / signature guids / new email guids
     */
  checkEmailSignatureStatus() {
    return this.fetch('checkEmailSignatureStatus');
  },


  //  ----  HELPERS  ------------------------------------

  isInitials() {
    return (this.type === this.TYPE_INITIALS);
  },

  isSignature() {
    return (this.type === this.TYPE_SIGNATURE);
  },

});
