/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-void
*/
import React from 'react';

module.exports = class HeaderText extends React.Component {
  constructor(props) {
    super(props);
    this.name = 'HeaderText';
  }

  getParentStyle() {
    return this.props.isComponentOutlined(this.props, this.name) ? this.props.outlineStyle : void 0;
  }

  getParagraphStyle(colors) {
    return { color: colors[this.name] };
  }

  render() {
    const parentStyle = this.getParentStyle();
    const paragraphStyle = this.getParagraphStyle(this.props.colors);
    return <div style={parentStyle} onClick={(event) => this.props.handleClick(event, this.name)}>
      <p style={paragraphStyle}>
                Welcome to the demo.
      </p>
      <p style={paragraphStyle}>
                You can change the color of this text by picking a color on the right.
      </p>
    </div>;
  }
};
