/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  react/no-unescaped-entities
*/
import './real-estate.scss';
import React from 'react';
import Button from 'common/components/button';
import RealEstateIllustration from '../../assets/clouds_real_estate.svg';
import TeamsIcon from '../../assets/teams_icon.svg';
import AuditTrailIcon from '../../assets/audit_trail_icon.svg';
import TemplatesIcon from '../../assets/templates_icon.svg';

function RealEstate(p) {
  return (
    <div className='seo-cloud-integrations real-estate'>
      <TopArea {...p} />
      <div className='seo-cloud-integrations__main' style={{ backgroundColor: 'white' }}>
        <PricingPlansCTA position='top' />
        <Benefits />
        <HowItWorks />
        <Testimonial />
        <FAQ />
        <PricingPlansCTA position='bottom' />
      </div>
    </div>
  );
}

function TopArea(p) {
  return (
    <div className='top row'>
      <div className='top-content-container large-5 large-push-6 columns'>
        <div className='title'>
          <h1>HelloSign helps Agents & Brokers close deals</h1>
        </div>
        <div className='subtitle'>
          <h2 style={{ fontWeight: 400, fontSize: 16, maxWidth: 600 }}>
                        Real estate agents and brokers love HelloSign because it’s
                        simple to use and helps get offer letters, counter offers,
                        and listing agreements signed. There are enough headaches
                        getting contracts signed, so our easy-to-use interface lets
                        you track the status of your documents out for signature
                        and notifies you once it’s been signed. HelloSign’s legally
                        binding signatures have an average turnaround time of 44
                        minutes, so you can close deals faster.
          </h2>
        </div>
        <SignUpButton {...p} />
      </div>
      <div className='clouds-container large-5 large-pull-4 columns' style={{ float: 'left' }}>
        <img src={RealEstateIllustration} alt='cloud left' style={{ marginTop: 60, width: 550 }} />
      </div>
    </div>
  );
}

function SignUpButton(p) {
  return (
    <Button
      buttonText='Get started'
      buttonColor='cerulean'
      buttonBorderColor='cerulean'
      buttonHoverColor='white'
      buttonHoverTextColor='cerulean'
      buttonLink={p.signUpUrl} />
  );
}

function PricingPlansCTA(p) {
  const position = p.position;
  const border = '2px solid #D7D7D7';
  const style = {
    borderTop: border,
    padding: '10px 0',
  };
  if (position === 'top') {
    style.borderBottom = border;
  }
  return (
    <div style={style}>
      <p style={{ fontWeight: 600, lineSpacing: 0 }}>
              Free accounts include three documents a month. Upgrade for unlimited
              documents and more features. <a href='/info/pricing' target='_blank' rel='noopener noreferrer'
                style={{ fontWeight: 500 }}
              >See Our Plans</a>.
      </p>
    </div>
  );
}

function Benefits(p) {
  return (
    <div className='seo-cloud-integrations__benefits'>
      <h2>Benefits</h2>
      <div className='row'>
        <section className='large-4 columns'>
          <div className='benefits-image-container'>
            <img src={TeamsIcon} alt='teams icon' />
          </div>
          <h3>
                        Add your whole office
          </h3>
          <p>
                        Admins can share templates, track the status of team members’
                        open documents, and configure team settings and custom branding.
          </p>
        </section>
        <section className='large-4 columns'>
          <div className='benefits-image-container'>
            <img src={AuditTrailIcon} alt='audit trail icon' />
          </div>
          <h3>
                        Track signatures at every stage
          </h3>
          <p>
                        Stay up-to-date with notifications when your documents are viewed
                        and signed. These activities are also logged in the document’s
                        legally binding audit trail.
          </p>
        </section>
        <section className='large-4 columns end'>
          <div className='benefits-image-container'>
            <img src={TemplatesIcon} alt='templates icon' />
          </div>
          <h3>
                        Templates for frequently used documents
          </h3>
          <p>
                        Auto-populate high volume documents with information using our
                        templates and merge fields. Create the document once, reuse again
                        and again.
          </p>
        </section>
      </div>
    </div>
  );
}

function HowItWorks(p) {
  const videoProps = {
    src: 'https://www.youtube.com/embed/sYpuROHdPbQ',
    frameBorder: 0,
    style: {
      width: '100%',
      maxWidth: 800,
      height: 430,
    },
  };
  const paragraphStyle = {
    marginLeft: 20,
  };
  return (
    <div className='seo-cloud-integrations__how-it-works'>
      <h2>How it Works</h2>
      <div className='row'>
        <div className='screenshot-explanation large-4 large-push-8 columns'>
          <p style={paragraphStyle}>
                        It’s easy to send a document with HelloSign. To get started,
                        figure out who has to sign your document: just you, just
                        other people, or both you and others.
          </p>
          <p style={paragraphStyle}>
                        You can upload the document from your hard drive, use a saved
                        template, or bring in a document from one of your cloud
                        storage accounts. Then, drag and drop the fields you want in
                        the document.
          </p>
          <p style={paragraphStyle}>
                        When the document is prepared, add your signers, set the order
                        for signatures, and send! HelloSign’s responsive design helps
                        get the signatures you need on phones, tablets, or desktops.
          </p>
        </div>
        <div className='dropbox-screenshot large-8 large-pull-4 columns'>
          <iframe {...videoProps} />
        </div>
      </div>
    </div>
  );
}

function Testimonial(p) {
  return (
    <div className='seo-cloud-integrations__testimonial' style={{ marginBottom: 70 }}>
      <p style={{ fontSize: 14, fontWeight: 600 }}>
        <span style={{ fontSize: 22, fontWeight: 300 }}>
                    "Every one of our agents has a HelloSign Account."
        </span>
                &nbsp;&nbsp;TOM TOGNOLI, Founder & CEO Intero Real Estate Services
      </p>
    </div>
  );
}

function FAQ(p) {
  const button = (
    <Button
      buttonText='Visit our FAQ'
      buttonColor='cerulean'
      buttonBorderColor='cerulean'
      buttonHoverColor='white'
      buttonHoverTextColor='cerulean'
      buttonLink='https://faq.hellosign.com/hc/en-us/categories/200353538-HelloSign'
      openInNewWindow={true} />
  );
  return (
    <div className='seo-cloud-integrations__faq'>
      <p>Questions about this integration? We have answers.</p>
      {button}
    </div>
  );
}

module.exports = RealEstate;
