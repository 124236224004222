/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-use-before-define
*/
// @flow
import * as React from 'react';
import { Provider, ThemeProvider } from 'react-fela';
import createRenderer from './create-renderer';
import defaultTheme from './theme';
import type { Theme } from './theme';

const defaultRenderer = createRenderer();

/**
 * The style provider component providers the style renderer and theme to all
 * other components in the library. It _must_ be set at the root level of your
 * app as part of the initial setup.
 *
 * @example
 * import { StyleProvider } from 'hello-ui/style';
 *
 * const theme = {
 *   colors: {
 *     primary: 'blue'
 *   }
 * };
 *
 * function MyApp() {
 *   return <StyleProvider theme={theme}>
 *     <div>...</div>
 *   </StyleProvider>;
 * }
 */
export class StyleProvider extends React.Component<Props> {
    theme: Theme;

    static defaultProps = {
      renderer: defaultRenderer,
      theme: defaultTheme,
    };

    constructor(props: Props) {
      super(props);

      this.theme = {
        ...defaultTheme,
        ...this.props.theme,
      };
    }

    render() {
      const { children, renderer } = this.props;

      return <Provider renderer={renderer}>
        <ThemeProvider theme={this.theme}>
          {React.Children.only(children)}
        </ThemeProvider>
      </Provider>;
    }
}

type Props = {
    children?: React.Node,
    renderer: Object,
    theme: Theme
};

export default StyleProvider;
