/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  no-restricted-globals,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';
import CancelSubscriptionForm from './cancel-subscription-form';

const CancelSubscriptionPage = createReactClass({

  displayName: 'CancelSubscriptionPage',

  propTypes: {
    tabOffset: PropTypes.number,
    cancelSubscriptionForm: PropTypes.object.isRequired, // Serialized sfForm
    isHf: PropTypes.bool, // maybe get dynamically from helpers
    isTeamAdmin: PropTypes.bool,
    hasHipaa: PropTypes.bool,
    hasBothApiAndUiPlans: PropTypes.bool,
    redirectUrl: PropTypes.string,
    showCustomerCancelOffer: PropTypes.bool,
    termsURL: PropTypes.string,
  },

  getInitialState() {
    return {
    };
  },

  getDefaultProps() {
    return {
      isIFrame: false,
    };
  },

  componentDidMount() {
    if (this.props.isIFrame) {
      parent.hellofaxJS.resizeFancyBoxPopup({ width: 500, height: 450 });
      parent.$('#fancybox-frame').width(480);
    }
  },

  render() {
    const siteCode = this.props.isHf ? HfReactHelper.HfSites.SITE_CODE_HF : HfReactHelper.HfSites.SITE_CODE_HS;

    const form = (<CancelSubscriptionForm
      tabOffset={this.props.tabOffset}
      isHf={this.props.isHf}
      isTeamAdmin={this.props.isTeamAdmin}
      hasHipaa={this.props.hasHipaa}
      hasBothApiAndUiPlans={this.props.hasBothApiAndUiPlans}
      redirectUrl={this.props.redirectUrl}
      showCustomerCancelOffer={this.props.showCustomerCancelOffer}
      method='POST'
      namespace={this.props.cancelSubscriptionForm.namespace}
      action={HfReactHelper.urlHelper('account/cancelSubscription', siteCode)}
      fields={this.props.cancelSubscriptionForm.fields}
      values={this.props.cancelSubscriptionForm.values}
      errors={{ global: this.state.error }}
      termsURL={this.props.termsURL}
    ></CancelSubscriptionForm>);

    return (
      <div className='m-settings--cancel'>
        { form }
      </div>
    );
  },
});

module.exports = CancelSubscriptionPage;
