/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import Button from 'common/components/button';

function FAQ(p) {
  return (
    <div className='seo-cloud-integrations__faq'>
      <p>Questions about this integration? We have answers.</p>
      <Button
        buttonText='View our FAQ'
        buttonLink={p.link}
        buttonColor='cerulean'
        openInNewWindow={true} />
    </div>
  );
}

module.exports = FAQ;
