/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';

const PeekABoo = createReactClass({

  propTypes: {
    imageUrl: PropTypes.string,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
  },

  componentDidMount() {
    const offset = $('.m-peek-a-boo--parallax img').offset();
    if (offset) {
      const peekYPos = offset.top;
      const scrollBottom = $(window).scrollTop() + $(window).height();
      if (scrollBottom > (peekYPos - 150)) {
        $('.m-peek-a-boo--parallax img').addClass('is-visible');
      }
      $(window).scroll(() => {
        const scrollBottom = $(window).scrollTop() + $(window).height();
        if (scrollBottom > (peekYPos - 150)) {
          $('.m-peek-a-boo--parallax img').addClass('is-visible');
        }
      });
    }
  },

  render() {
    return (
      <div className='m-peek-a-boo ' data-equalizer-watch>
        <div className='l-vertical-align m-peek-a-boo--parallax m-feature--outer-container'>
          <img className='m-peek-a-boo--image' data-speed='.4' height={this.props.imageHeight} width={this.props.imageWidth} src={HfReactHelper.imgHelper(this.props.imageUrl)} />
        </div>
      </div>
    );
  },
});
module.exports = PeekABoo;
