/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  global-require,
  import/no-extraneous-dependencies
*/
if (!global._babelPolyfill) {
  require('@babel/polyfill');
}

// This allows the React dev tools to work through an iframe. This must run
// before React loads.
if (process.env.NODE_ENV === 'development' && window.parent !== window) {
  try {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;
  } catch (e) {
    // Ignore blocked origin DOMException.
  }
}
require('intl');
require('isomorphic-fetch');
require('./element-closest');
