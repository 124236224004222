/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  array-callback-return,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

/*
* Wrapper class for images on HelloSign.
* Deprecated - <img /> elements work just fine now since image sources are resolved via webpack. (CC)
*/

const VALID_IMAGE_PROPS = {
  src: true, className: true, style: true, meta: true,
};

const Image = createReactClass({

  propTypes: {
    src: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
  },

  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      src: null,
      className: '',
      style: {},
    };
  },

  getInitialState() {
    return {};
  },

  render() {

    // Only add valid keys; prevents browser warnings
    const propsKeys = Object.keys(this.props);
    const imageProps = {};
    propsKeys.filter((key) => {
      if (VALID_IMAGE_PROPS[key] === true) {
        imageProps[key] = this.props[key];
      }
    });


    return (
      <img {...imageProps} />
    );
  },
});

module.exports = Image;
