export default function checkPlatform(forceMobile) {

  let platform;

  // for mobile testing
  if (forceMobile) {
    platform = 'mobile';
  } else {

    const w = window.innerWidth;
    const ua = navigator.userAgent;

    // c.f. http://stackoverflow.com/questions/16443380/common-css-media-queries-break-points
    if (w < 767 || ua.match(/iphone/gi) !== null) {
      // Mobile phone
      platform = 'mobile';
    } else if (ua.match(/ipad|android|tablet/gi) !== null) {
      // Tablet
      platform = 'mobile';
    } else {
      // Desktop
      platform = 'desktop';
    }
  }

  return platform;
}
