/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  array-callback-return,
  eqeqeq,
  import/no-extraneous-dependencies,
  no-prototype-builtins
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const DropDownMenu = createReactClass({

  propTypes: {
    dropDownMenuLinkData: PropTypes.array.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
  },

  render() {
    const dropDownMenuLinks = this.props.dropDownMenuLinkData.map(
      (dropDownMenuLink, i, linkArray) => {
        if ((!dropDownMenuLink.hasOwnProperty('options')) || (dropDownMenuLink.hasOwnProperty('options') && !dropDownMenuLink.options.mobileMenuOnly)) {
          const target = dropDownMenuLink.options ? dropDownMenuLink.options.target : null;
          return (
            <a key={i} className={`m-arrow-box--link hc-cerulean c-ashen${i == (linkArray.length - 1) ? ' last' : ''}`} href={dropDownMenuLink.url} target={target}>
              {dropDownMenuLink.text}
            </a>
          );
        }
      },
    );
    return (
      <div
        style={this.props.style}
        className={`m-arrow-box ${this.props.className || ''}`} id={this.props.id || 'google-dropdown-menu'}
      >
        {dropDownMenuLinks}
      </div>
    );
  },
});
module.exports = DropDownMenu;
