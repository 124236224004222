/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  camelcase,
  eqeqeq,
  import/named,
  import/no-extraneous-dependencies,
  max-len,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'common/components/toggle';
import flagIcon from 'common/images/flag.svg';
import HfReactHelper from 'common/utils/hf-react-helper';
import PricingLevels from './pricing-levels';
import CouponDisplay from './coupon-display';

class PricingArea extends React.Component {

    static propTypes = {
      signupUrl: PropTypes.string,
      pricingSource: PropTypes.string,
      paymentForm: PropTypes.object.isRequired, // Serialized sfForm
      signupForm: PropTypes.object.isRequired, // Serialized sfForm
      dbxPaymentMethod: PropTypes.object,
      mustContactSales: PropTypes.bool,
      skipCC: PropTypes.bool,
      trialPeriod: PropTypes.number,
      corpsLevelName: PropTypes.string,
      changePlanRestricted: PropTypes.bool,
      googleSignInClientId: PropTypes.string,
      csrfToken: PropTypes.string,
      hasApiPlan: PropTypes.bool,
    };

    constructor(props) {
      super(props);
      const { selectedTimePeriod, timePeriodChoices } = this.props;
      this.state = {
        timePeriod: selectedTimePeriod || timePeriodChoices[1].value,
      };
    }

    componentDidMount() {
      if (this.props.mustContactSales) $('#contactUsButton').click();
    }

    onSelection = (selection) => this.setState({ timePeriod: selection });

    isAnnual = () => this.state.timePeriod == 'Y';

    isMonthly = () => this.state.timePeriod == 'M';

    billingPlans = () => this.props.pricingData.billing_plans;

    pricingLevelCount = () => this.billingPlans().length;

    shouldDisplayToggle = () => this.props.timePeriodChoices.length > 1;

    hasValidCoupon = () => {
      const isAnnual = this.isAnnual();
      const isMonthly = this.isMonthly();
      const { coupon } = this.props;
      if (coupon) {
        const { monthly_rate, annual_rate } = coupon;
        const invalidMonthlyRate = isMonthly && monthly_rate == 0;
        const invalidAnnualRate = isAnnual && annual_rate == 0;
        const invalidCouponCode = !coupon.coupon_code;
        if (invalidMonthlyRate || invalidAnnualRate || invalidCouponCode) return false;
      }
      return !!coupon;
    };

    couponDisplay = () => {
      const { coupon } = this.props;
      return <CouponDisplay couponCode={coupon.coupon_code} couponDescription={coupon.description} />;
    };

    toggleButton = () => {
      const { timePeriodChoices, selectedTimePeriod } = this.props;

      return <Toggle choices={timePeriodChoices}
        initialSelection={selectedTimePeriod}
        onSelection={this.onSelection} />;
    };

    apiMessage = () => {
      if (HfReactHelper.isHelloSign() && this.props.pricingSource === 'web' && !this.props.hasApiPlan) {
        return (
          <div className="row text-center l-padding-t-20">
            <img className="pricing-area__flag-icon" src={flagIcon} style={{
              width: '12px', display: 'inline-block', marginRight: '10px', marginTop: '-2px',
            }} />
            <p style={{ display: 'inline-block' }}>Planning to use our API? <a href={this.props.apiPricingUrl}>Check out our API pricing</a>.</p>
          </div>
        );
      }
    };

    errorMessage = () => {
      let errors;
      if (this.props.errors) {
        let errorMsg = 'Sorry, we could not process your payment.';
        if (this.props.errors['payment[submit]']) {
          errorMsg += ` ${this.props.errors['payment[submit]']}`;
        }
        if (this.props.errors['payment[cc_number]']) {
          errorMsg += ` ${this.props.errors['payment[cc_number]']}`;
        }
        if (this.props.errors['payment[0]']) {
          errorMsg += ` ${this.props.errors['payment[0]']}`;
        }
        errors = <div data-test-id='errors' className='row text-center l-padding-v-20'>
          <p className='c-red'>{errorMsg}</p>
        </div>;
      }
      return errors;
    };

    render() {
      return <div>
        <div className='row'>
          {this.hasValidCoupon() ? this.couponDisplay() : null}
          {this.shouldDisplayToggle() ? this.toggleButton() : null}
        </div>
        { this.apiMessage() }
        { this.errorMessage() }
        <div className='row levels l-padding-v-60 text-center' style={{ paddingTop: 0 }}>
          <PricingLevels isMonthly={this.isMonthly()}
            hasValidCoupon={this.hasValidCoupon()}
            billingPlans={this.billingPlans()}
            {...this.props}
            {...this.state} />
        </div>
      </div>;
    }
}

module.exports = PricingArea;
