// @flow
import camelCase from 'lodash/string/camelCase';
import { config, combineRules } from '../../style';

const arrowHeight = 9;

const leftOrRight = (pos) => pos === 'left' || pos === 'right';
const topOrBottom = (pos) => pos === 'top' || pos === 'bottom';
const oppositePosition = (pos) => ({
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
}[pos]);

const rootRule = () => ({
  position: 'relative',
});

function positionRule({ position = 'bottom' }) {
  const opposingPosition = oppositePosition(position);
  const margin = camelCase(`margin-${opposingPosition}`);
  const borderProp = camelCase(`border-${position}-color`);

  const result = {
    [opposingPosition]: '100%',
    [margin]: `${arrowHeight}px`,
    ':before': {
      [position]: '100%',
      [borderProp]: config.colors.muted,
      marginLeft: topOrBottom(position)
        ? `-${arrowHeight}px`
        : null,
    },
    ':after': {
      [position]: '100%',
      [borderProp]: 'white',
      marginLeft: topOrBottom(position)
        ? `-${arrowHeight - 2}px`
        : null,
    },
  };

  return result;
}

function justifyRule({ justify = 'left', position = 'bottom' }) {
  const styles = {
    left: {
      left: 0,
      ':before': {
        left: '20%',
      },
      ':after': {
        left: '20%',
      },
    },
    right: {
      right: 0,
      ':before': {
        left: '80%',
      },
      ':after': {
        left: '80%',
      },
    },
    center: {
      left: '50%',
      transform: 'translateX(-50%)',
      ':before': {
        left: '50%',
      },
      ':after': {
        left: '50%',
      },
    },
  };

  return topOrBottom(position)
    ? styles[justify]
    : {};
}

function alignRule({ align = 'top', position = 'bottom' }) {
  const styles = {
    top: {
      top: 0,
      ':before': {
        top: 0,
        marginTop: `${arrowHeight - 2}px`,
      },
      ':after': {
        top: 0,
        marginTop: `${arrowHeight}px`,
      },

    },
    center: {
      top: '50%',
      transform: 'translateY(-50%)',
      ':before': {
        top: '50%',
        marginTop: `-${arrowHeight}px`,
      },
      ':after': {
        top: '50%',
        marginTop: `-${arrowHeight - 2}px`,
      },

    },
  };

  return leftOrRight(position)
    ? styles[align]
    : {};
}

const baseContentRule = () => ({
  background: 'white',
  borderRadius: '2px',
  border: `1px solid ${config.colors.muted}`,
  position: 'absolute',
  minWidth: '120px',
  zIndex: 1000,
  ':after': {
    content: '" "',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: `${arrowHeight - 2}px`,
    height: 0,
    width: 0,
    position: 'absolute',
    pointerEvents: 'none',
  },
  ':before': {
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: `${arrowHeight}px`,
    content: '" "',
    height: 0,
    width: 0,
    position: 'absolute',
    pointerEvents: 'none',
  },
});

const contentRule = combineRules(
  baseContentRule,
  positionRule,
  justifyRule,
  alignRule,
);

export default {
  root: rootRule,
  content: contentRule,
};
