/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';

module.exports = class HeaderButton extends React.Component {
  constructor(props) {
    super(props);
    this.buttonName = 'Button';
    this.buttonTextName = 'ButtonText';
  }

  getButtonStyle() {
    let buttonStyle = { background: this.props.colors[this.buttonName] };
    if (this.props.isComponentOutlined(this.props, this.buttonName)) {
      buttonStyle = this.outline(buttonStyle);
    }
    return buttonStyle;
  }

  getButtonTextStyle() {
    let buttonTextStyle = { color: this.props.colors[this.buttonTextName] };
    if (this.props.isComponentOutlined(this.props, this.buttonTextName)) {
      buttonTextStyle = this.outline(buttonTextStyle);
    }
    return buttonTextStyle;
  }

  outline(style) {
    return Object.assign(style, this.props.outlineStyle);
  }

  render() {
    const buttonStyle = this.getButtonStyle();
    const buttonTextStyle = this.getButtonTextStyle();
    return <button onClick={(event) => this.props.handleClick(event, this.buttonName)}
      style={buttonStyle}
    >
      <span onClick={(event) => this.props.handleClick(event, this.buttonTextName)}
        style={buttonTextStyle}
      >
            BUTTON TEXT
      </span>
    </button>;
  }
};
