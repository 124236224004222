/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import { Flex } from 'hello-ui/blocks';
import HsStyleProvider from '../style/hs-style-provider';

export function HellosignLayout({ children, responsive }) {
  const style = responsive ? {} : { minWidth: 1180 };

  // The 'site-wrapper' is needed as a js-hook for the notifications component
  return <HsStyleProvider><Flex
    id='site-wrapper'
    style={style}
    flexDirection='column'
    className='hellosign-layout-container'
    px={responsive ? 3 : 1}
  >
    {children}
  </Flex></HsStyleProvider>;
}

export default HellosignLayout;
