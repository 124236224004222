import BaseNotifier from './base';

function NoopNotifier() {

}

BaseNotifier.extend(NoopNotifier, {
  notify() {

  },
});

export default NoopNotifier;
