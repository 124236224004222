/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import { activate } from '../widget';

/**
 * A higher-order component for turning a component into a zendesk widget
 * activator.
 *
 * @example
 *
 * // The `onActivate` prop is available because we've wrapped the component
 * // with the `asZendeskActivator` function
 * const MyComponent = function(props) {
 *      return <div onClick={props.onActivate}>Whatever I want here</div>;
 * }
 *
 * const MyWrappedComponent = asZendeskActivator(MyComponent);
 *
 * // later, in a render function....
 * render() {
 *      return <div>
 *          <MyWrappedComponent />
 *      </div>;
 * }
 *
 */
function asZendeskActivator(Component) {
  function ZendeskActivator(props) {
    return <Component {...props} onActivate={activate} />;
  }

  const wrappedComponentName = Component.displayName
        || Component.name
        || 'Component';

  ZendeskActivator.displayName = `asZendeskActivator(${wrappedComponentName})`;

  return ZendeskActivator;
}

export default asZendeskActivator;
