/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import HelloSignLogo from 'landing-pages/assets/HS_logo_white.svg';
import GoogleDocsLogo from 'landing-pages/assets/googledocs_logo.svg';
import Illustration from 'landing-pages/assets/clouds_googledocs.svg';
import OverlayLeft from 'landing-pages/assets/overlay_left.svg';
import OverlayRight from 'landing-pages/assets/overlay_right.svg';
import ApprovalIcon from 'landing-pages/assets/approval_icon.svg';
import DropSignatureIcon from 'landing-pages/assets/drop_signature_icon.svg';
import SecurityIcon from 'landing-pages/assets/security_icon.svg';
import Screenshot from 'landing-pages/assets/screenshot_googledocs.png';
import Social from 'landing-pages/components/common/social-section';
import FAQ from 'landing-pages/components/common/faq-section';
import Benefits from 'landing-pages/components/common/benefits-section';
import PricingCTA from 'landing-pages/components/common/pricing-cta-section';
import CTAButton from 'landing-pages/components/common/cta-button';

function GoogleDocs(p) {
  // Google Docs constants
  const benefits = [
    {
      icon: ApprovalIcon,
      title: 'Simplify signing',
      text: 'Remove extra steps from the document lifecycle by writing, editing, sending a document for signature, and signing it in Google Docs.',
    },
    {
      icon: DropSignatureIcon,
      title: 'Click and insert your signature',
      text: 'Before you send or download your document, just click to insert your signature wherever it needs to be.',
    },
    {
      icon: SecurityIcon,
      title: 'HelloSign docs are safe and secure',
      text: 'All signed documents are legally binding and automatically backed up in your HelloSign account.',
    },
  ];
  const howItWorks = [
    'Connect your Google Drive and HelloSign accounts to start signing documents from within Google Drive. Then you’ll be able to sign or send any document in your drive folders.',
    'On HelloSign.com, you’ll also be able to import documents stored in Google Drive.',
    'All of the docs you send through the extension will show up in the documents view on HelloSign.com.',
  ];
  const twitterLink = 'https://twitter.com/intent/tweet?original_referer=https%3a%2f%2fwww.hellosign.com%2fgoogle-docs-add-on&ref_src=twsrc%5Etfw&text=Did%20you%20know%20%40HelloSign%20integrates%20with%20%40GoogleDocs%3F%20Sign%20documents%20directly%20from%20Google%20Docs.&tw_p=tweetbutton&url=https%3a%2f%2fwww.hellosign.com%2fgoogle-docs-add-on%3futm_medium%3Ds2s%26utm_source%3Dtwitter';
  const facebookLink = 'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.hellosign.com/google-docs-add-on?utm_source=facebook%26utm_medium=s2s';
  const linkedInLink = 'https://www.linkedin.com/cws/share?url=https%3a%2f%2fwww.hellosign.com%2fgoogle-docs-add-on%3futm_medium%3Ds2s%26utm_source%3Dlinkedin&original_referer=https%3a%2f%2fwww.hellosign.com%2fgoogle-docs-add-on&token=&isFramed=false&lang=en_US&_ts=1462214138386.6147&xd_origin_host=https%3A%2F%2Fwww.hellosign.com';
  const border = '2px solid #D7D7D7';

  return (
    <div className='seo-cloud-integrations'>
      <IllustrationArea {...p} />
      <div className='seo-cloud-integrations__main' style={{ backgroundColor: 'white' }}>
        <PricingCTA position='top' style={{ borderBottom: border, backgroundColor: '#F7F8F9', fontWeight: 600 }} />
        <Benefits benefits={benefits} />
        <HowItWorks
          image={Screenshot}
          explanation={howItWorks} />
        <Social
          title='Share with your networks!'
          twitterLink={twitterLink}
          facebookLink={facebookLink}
          linkedInLink={linkedInLink} />
        <FAQ link='https://faq.hellosign.com/hc/en-us/articles/205794398-HelloSign-For-Google-Docs-Add-on' />
        <PricingCTA position='bottom' style={{ borderTop: border, fontWeight: 600 }} />
      </div>
    </div>
  );
}

function HowItWorks(p) {
  const linkURL = 'https://www.hellosign.com/info/googleDocsInstall';
  const link = <a href={linkURL} target='_blank' rel='noopener noreferrer'>Add the free HelloSign add-on</a>;

  return (
    <div className='seo-cloud-integrations__how-it-works'>
      <h2>How it Works</h2>
      <div className='row'>
        <div className='screenshot-explanation large-3 large-push-9 columns'>
          <p>
            {link} for Google Docs to start signing directly inside Google Docs.
                        From the HelloSign sidebar, you’ll be able to sign documents or send
                        them for signature.
          </p>
          <p>
                        To sign a document, draw a new signature or insert any previously
                        saved signatures. Then email it as a legally binding PDF.
          </p>

          <p>
                        If someone else needs to sign your Google Doc, this extension
                        automatically imports your document into HelloSign to add recipients
                        and signature fields.
          </p>

          <p>
                        All of the docs you send through the extension will show up in the
                        documents view on HelloSign.com.
          </p>
        </div>
        <div className='dropbox-screenshot large-9 large-pull-3 columns'>
          <img src={Screenshot} alt='google drive screenshot' />
        </div>
      </div>
    </div>
  );
}

function IllustrationArea(p) {
  return (
    <div className='top row' style={{ backgroundColor: '#B1DCF5' }}>
      <div className='top-content-container large-5 large-push-6 columns'>
        <div className='hellosign-dropbox'>
          <img src={HelloSignLogo} alt='HelloSign Logo' className='hs-logo' />
          <span className='plus'>+</span>
          <img src={GoogleDocsLogo} alt='GoogleDocs Logo' className='dropbox-logo' />
        </div>
        <div className='title'>
          <h1>Sign and send Google Docs in seconds</h1>
        </div>
        <div className='subtitle'>
          <h2>
                        Add your signature to a Google Doc or add fields for others to
                        fill out and sign. Now you can write, invite others to comment
                        and redline, and send a document for signature entirely from
                        within Google Docs — there’s no need to export and upload.
          </h2>
        </div>
        <div className='signup-button'>
          <CTAButton
            {...p}
            buttonText='Try it free' />
        </div>
      </div>
      <div className='clouds-container large-5 large-pull-4 columns'>
        <img src={Illustration} alt='cloud left' />
      </div>
      <img src={OverlayLeft} style={{ transform: 'translateY(1px)' }} alt='cloud left' className='cloud-left' />
      <img src={OverlayRight} style={{ transform: 'translateY(1px)' }} alt='cloud right' className='cloud-right' />
    </div>
  );
}

module.exports = GoogleDocs;
