/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  global-require,
  import/extensions,
  import/no-extraneous-dependencies,
  no-console,
  no-script-url,
  no-void,
*/
import React from 'react';
import PropTypes from 'prop-types';
import makeComponentIntl from 'common/utils/make-component-intl';
import HfReactHelper from 'common/utils/hf-react-helper';
import CONSTANTS from 'ui/signature-modal/constants';
import classNames from 'classnames';
import Button from 'common/components/button';
import TetherToggle from 'hello-ui/tether-toggle';
import SignatureModal from 'signer/components/common/signature-modal/edit-signature-modal';
import { Box, Flex } from 'hello-ui/blocks';
import createReactClass from 'create-react-class';
import { FormattedMessage } from 'react-intl';

import './style.scss';
import HsStyleProvider from 'hellosign/common/style/hs-style-provider';
import Logo from './logo.jsx';

const MenuLink = (props) => <Box
  className="m-app-header--menu-item m-app-header--menu-item--hoverable"
  is="a"
  py={3}
  px={3}
  display="block"
  {...props} />;

// TODO: HelloFax & HelloSign header files should NOT be together - separate this stuff (CC)
// In-app top bar
const AppTopBar = createReactClass({

  propTypes: {
    siteCode: PropTypes.string.isRequired, // S or F
    showUpgradeButton: PropTypes.bool,
    emailAddress: PropTypes.string,
    name: PropTypes.string,
    primarySignatureGuid: PropTypes.string,
    numDocsLeft: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    numApiDocsLeft: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    numTemplatesLeft: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    numFaxPagesLeft: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    minimalDisplay: PropTypes.bool, // Only show the account menu (default is false)
    disableAccountMenu: PropTypes.bool, // Disable the account menu dropdown menu (default is false)
    hideEmailAddress: PropTypes.bool, // Hide the email address (default is false)
    signatureModalProps: PropTypes.object, // Signature modal props
    showApiCredits: PropTypes.bool,
    canAccessAdminConsole: PropTypes.bool,
    app: PropTypes.object,
    intl: PropTypes.object,
  },


  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      showUpgradeButton: true,
      minimalDisplay: false,
      hideEmailAddress: false,
      disableAccountMenu: false,
      showApiCredits: false,
    };
  },

  getInitialState() {
    return {
      signatureModalIsShown: false,
      signatureModalTab: CONSTANTS.TAB_SAVED,
    };
  },

  componentDidMount() {
    // CSRF Token is used in the in-app signature modal.
    if (this.props.app && this.props.signatureModalProps) {
      this.props.app.csrfToken = this.props.signatureModalProps.csrfToken;
    }
  },

  //  ----  BEHAVIOR  -----------------------------------

  showHowItWorks() {
    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    document.location = HfReactHelper.urlHelper('account/sendDemo', site);
  },

  showSignatureModal(evt) {

    evt.preventDefault();
    evt.stopPropagation();

    this.setState({
      signatureModalIsShown: true,
    });
  },

  hideSignatureModal() {
    this.setState({
      signatureModalIsShown: false,
      signatureModalTab: CONSTANTS.TAB_SAVED,
    });
  },

  showInviteFriendsModal(evt) {

    evt.preventDefault();

    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    const url = HfReactHelper.urlHelper('home/inviteFriends', site);

    $.fancybox({
      type: 'iframe',
      showCloseButton: false,
      scrolling: 'no',
      href: url,
      padding: 0,
      margin: 0,
      autoDimensions: true,
      onStart() {
        $('#fancybox-overlay').addClass('m-fancybox-modal-overlay settings-modal invite-friends is-hidden');
        $('#fancybox-wrap').addClass('m-fancybox-modal-wrap settings-modal invite-friends is-hidden');
        $('#fancybox-inner').addClass('m-fancybox-modal-inner settings-modal invite-friends');
      },
      onComplete() {
        $.fancybox.center();
        $('#fancybox-overlay, #fancybox-wrap').removeClass('is-hidden');
      },
      onClosed() {
        $('#fancybox-overlay, #fancybox-wrap, #fancybox-inner').attr('class', '');
      },
    });
  },

  updatePrimarySignature(signature) {

    window.primarySignatureGuid = signature ? signature.guid : void 0;

    if (this.state.signatureModalTab == CONSTANTS.TAB_SAVED) {

      // Mark the current signature as primary and close the modal
      signature.markAsPrimary().then(() => {
        this.hideSignatureModal();
      }).catch((err) => {
        // Fail silently
        console.error('Could not mark signature as primary', err);
      });
    } else {

      // Show the saved signature tab after a new one has been created
      this.setState({
        signatureModalTab: CONSTANTS.TAB_SAVED,
      });
    }
  },

  onSignatureModalTabSelected(tabId) {
    this.setState({
      signatureModalTab: tabId,
    });
  },

  //  ----  RENDERING  ----------------------------------

  renderAccountMenu(site, trigger) {
    const { disableAccountMenu } = this.props;
    const settingsUrl = HfReactHelper.urlHelper('home/myAccount', site);
    const logoutUrl = HfReactHelper.urlHelper('account/logOut', site);
    const adminUrl = HfReactHelper.urlHelper('admin', site);
    let signatureGuid;
    let freeDocsLink;

    if (window.primarySignatureGuid === null) {
      // In this case we don't have a primary signature because it just got deleted
      signatureGuid = null;
    } else {
      signatureGuid = window.primarySignatureGuid || this.props.primarySignatureGuid;
    }
    const items = [
      undefined,
      undefined,
      <MenuLink key="settings-link" href={settingsUrl} fontWeight="normal" data-qa-ref="settings">
        <FormattedMessage
          id=""
          description="webapp, homepage header dd my/  setting"
          defaultMessage="{canAccessAdminConsole, select, true {My } other {} }Settings"
          values={{
            canAccessAdminConsole: this.props.canAccessAdminConsole,
          }} />
      </MenuLink>,
      undefined,
      <MenuLink key="logout-link" href={logoutUrl} fontWeight="normal" data-qa-ref="log-out">
        <FormattedMessage id="" description="webapp, homepage header dd logout" defaultMessage="Logout" />
      </MenuLink>,
    ];

    if (HfReactHelper.HfSites.isHelloSign(site)) {

      // Signature
      let name;
      let sig;
      const sigClassNames = classNames({
        'm-app-topbar--menu--signature--sig': true,
        'no-sig': !signatureGuid,
        'l-margin-t-20': !this.props.name,
      });
      if (this.props.name) {
        name = (<Box className="m-app-topbar--menu--signature--name">{ this.props.name }</Box>);
      }
      if (signatureGuid) {
        sig = (
          <div className={sigClassNames}>
            <div className="m-app-topbar--menu--signature--image">
              <img src={HfReactHelper.urlHelper(`attachment/view?sig_guid=${signatureGuid}`, site)} />
            </div>
            <div className="m-app-topbar--menu--signature--edit">
              <a href="javascript:;" onClick={this.showSignatureModal}><FormattedMessage id="" description="webapp, homepage header dd edit sign" defaultMessage="Edit signature" /></a>
            </div>
          </div>
        );
      } else {
        sig = (
          <div className={sigClassNames} onClick={this.showSignatureModal}>
            <img src={require('./plus_icon@2x.png')} />
            <Box is="span">
              <FormattedMessage id="" description="webapp, homepage header dd creating sign" defaultMessage="Create a signature" />
            </Box>
          </div>
        );
      }
      items[0] = (
        <Box px={3} key="signature-link" className="m-app-header--menu-item">
          <div className="m-app-topbar--menu--signature">
            { name }
            { sig }
          </div>
        </Box>
      );

      // HS Credits
      const numDocsLeft = this.props.numDocsLeft === null ? 'Unlimited' : this.props.numDocsLeft;
      const numTemplatesLeft = this.props.numTemplatesLeft === null ? 'Unlimited' : this.props.numTemplatesLeft;
      if (this.props.showUpgradeButton) {
        freeDocsLink = (<Box is="a" display="block" py={2} href="" onClick={this.showInviteFriendsModal} ><FormattedMessage id="" description="webapp, homepage header dd get free docs" defaultMessage="Get free documents" /></Box>);
      }

      let apiCredits = '';
      if (this.props.showApiCredits) {
        const numApiDocsLeft = this.props.numApiDocsLeft === null ? 'Unlimited' : this.props.numApiDocsLeft;
        apiCredits = (<div>
          <div className="m-app-topbar--menu--credits--title">
            <FormattedMessage id="" description="webapp, homepage header dd api credits remain" defaultMessage="REMAINING API CREDITS" />
          </div>
          <div className="m-app-topbar--menu--credits--line">
            <FormattedMessage
              id=""
              description="webapp, homepage header dd no. of api"
              defaultMessage="{numApiDocsLeft} API {numApiDocsLeft, plural, =1 {document} other {documents}}"
              values={{
                numApiDocsLeft,
              }} />
          </div>
        </div>);
      }

      items[1] = (
        <Box px={3} key="free-docs-link" className="m-app-header--menu-item">
          <div className="m-app-topbar--menu--credits">
            { apiCredits }
            <div className="m-app-topbar--menu--credits--title">
              <FormattedMessage id="" description="webapp, homepage header dd credits remain" defaultMessage="REMAINING CREDITS" />
            </div>
            <div className="m-app-topbar--menu--credits--line">
              <FormattedMessage
                id=""
                description="webapp, homepage header dd no. of docs"
                defaultMessage="{numDocsLeft} {numDocsLeft, plural, =1 {document} other {documents}}"
                values={{
                  numDocsLeft,
                }} />
            </div>
            <div className="m-app-topbar--menu--credits--line" data-qa-ref="template-credit">
              <FormattedMessage
                id=""
                description="webapp, homepage header dd no. of templates"
                defaultMessage="{numTemplatesLeft} {numTemplatesLeft, plural, =1 {template} other {templates}}"
                values={{
                  numTemplatesLeft,
                }} />
            </div>
            { freeDocsLink }
          </div>
        </Box>
      );

      if (this.props.canAccessAdminConsole) {
        items[3] = (
          <MenuLink key="admin-console-link" href={adminUrl} fontWeight="normal" data-qa-ref="admin-console-button">
            <FormattedMessage id="" description="webapp, homepage header dd admin console link" defaultMessage="Admin Console" />
          </MenuLink>
        );
      }

    } else {

      // HF Credits
      const getFreePagesUrl = HfReactHelper.urlHelper('account/gettingStarted', site);
      const numFaxPagesLeft = this.props.numFaxPagesLeft === null ? 'Unlimited' : this.props.numFaxPagesLeft;
      items[1] = (
        <Box px={3} key="get-free-pages-link" className="m-app-header--menu-item">
          <div className="m-app-topbar--menu--credits">
            <div className="m-app-topbar--menu--credits--title">REMAINING CREDITS</div>
            <div className="m-app-topbar--menu--credits--line">{ `${numFaxPagesLeft} fax page${numFaxPagesLeft !== 1 ? 's' : ''}` }</div>
            <Box display="block" is="a" py={2} href={getFreePagesUrl}>Get free pages</Box>
          </div>
        </Box>
      );

    }

    return (
      <TetherToggle
        anchor={trigger}
        justify="right"
        disabled={disableAccountMenu}
      >
        <div className="m-app-topbar--account-menu">
          {items}
        </div>
      </TetherToggle>
    );
  },

  signatureModalProps() {
    const app = this.props.app || window.app;
    if (this.props.signatureModalProps.settings) {
      app.isMobile = false;
      app.user.settings = Object.assign(
        app.user.settings || {},
        this.props.signatureModalProps.settings,
      );
    }
    return {
      app,
      buttonText: this.state.signatureModalTab === CONSTANTS.TAB_SAVED ? 'Select' : 'Save',
      emailGuid: this.props.signatureModalProps.emailGuid,
      emailUploadAddress: this.props.signatureModalProps.emailUploadAddress,
      type: this.props.signatureModalProps.signatureType,
      onComplete: this.updatePrimarySignature,
      onSelectTab: this.onSignatureModalTabSelected,
      onClose: this.hideSignatureModal,
      initialTab: this.state.signatureModalTab,
      intl: this.props.intl,
    };

  },

  render() {
    const isHf = HfReactHelper.HfSites.isHelloFax(this.props.siteCode);
    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    const homeUrl = HfReactHelper.urlHelper('home/index', site);
    const logoUrl = isHf ? require('./logo-hf.svg') : require('./logo.svg');
    let signDocs;
    let seeHowBtn;
    let upgradeBtn;
    let acctMenu;
    let emailAddressLink;
    let signatureModal;

    const topBarMenuClasses = classNames({
      'm-app-topbar--menu': true,
      'is-disabled': this.props.disableAccountMenu,
    });

    const meAndOthersParams = 'me=1';

    if (!this.props.minimalDisplay) {

      if (isHf) {

        seeHowBtn = (<Button
          key="see-how-it-works-button"
          buttonText="See how it works"
          buttonLink=""
          buttonColor="white"
          buttonTextColor="ashen"
          buttonClass="border-1-ashen"
          buttonModuleClass="m-app-topbar--see-how-btn"
          onClick={this.showHowItWorks}
        ></Button>);

      } else {

        const items = [
          (<MenuLink key="me" href={HfReactHelper.urlHelper('sign', site)}><FormattedMessage id="" description="webapp, homepage header sign doc dd just me link" defaultMessage="Just me" /></MenuLink>),
          (<MenuLink key="me-and-others" href={HfReactHelper.urlHelper(`home/request?${meAndOthersParams}`, site)}><FormattedMessage id="" description="webapp, homepage header sign doc dd me and others link" defaultMessage="Me &amp; others" /></MenuLink>),
          (<MenuLink key="others" href={HfReactHelper.urlHelper('home/request', site)}><FormattedMessage id="" description="webapp, homepage header sign doc dd just others link" defaultMessage="Just others" /></MenuLink>),
        ];

        items.push(
          <MenuLink key="bulk-send" href={HfReactHelper.urlHelper('bulksend', site)}>
            <FormattedMessage id="" description="webapp, homepage header sign doc dd bulk send link" defaultMessage="Bulk send" />
          </MenuLink>,
        );

        const signDocsTrigger = (
          <div className="m-app-topbar--signdocs">
            <div className="m-app-topbar--signdocs--title">
              <span className="m-app-topbar--signdocs--icon">
                <img className="m-app-topbar--signdocs--icon--img" src={require('./signdoc_icon@2x.png')} />
              </span>
              <span className="m-app-topbar--signdocs--title--text">
                <FormattedMessage id="" description="webapp, homepage header sign docs link" defaultMessage="Sign documents" />
              </span>
            </div>
          </div>
        );

        signDocs = <Box display="inline-block">
          <TetherToggle
            anchor={signDocsTrigger}
            justify="right"
          >
            {items}
          </TetherToggle>
        </Box>;

      }
    }

    if (!this.props.minimalDisplay && this.props.showUpgradeButton) {
      upgradeBtn = (<a href={HfReactHelper.urlHelper('info/pricing', site)} className="m-button bg-cerulean m-app-topbar--upgrade-btn hbg-castle-rock hc-white c-white progress-button state-loading"><FormattedMessage id="" description="webapp, homepage header upgrade link" defaultMessage="Upgrade" /></a>);
    }

    if (!this.props.hideEmailAddress) {

      // Hidden signature modal
      if (!isHf && this.state.signatureModalIsShown) {
        signatureModal = <SignatureModal {...this.signatureModalProps()} />;
      }

      // Render the email address link
      emailAddressLink = (
        <div className="m-app-topbar--menu--title m-app-topbar--menu--title-email">
          <span className="m-app-topbar--menu--title--text" data-qa-ref="logged-in-email-element">{ this.props.emailAddress }</span>
          <span className="m-app-topbar--menu--title--icon" data-qa-ref="account-drop-down">
            <img src={require('./menu_arrow@2x.png')} />
          </span>
        </div>
      );

      acctMenu = this.renderAccountMenu(site, emailAddressLink);
    }

    return <HsStyleProvider>
      <div className="m-app-topbar">
        { signatureModal }
        <Flex justify="space-between" align="center">
          <Logo href={homeUrl} src={logoUrl} alt={isHf ? 'HelloFax' : 'HelloSign'} width="229" height="35" />
          <div className="m-app-topbar--actions">
            { signDocs }
            { seeHowBtn }
            { upgradeBtn }
            <div className={topBarMenuClasses}>
              <Box display="inline-block">{ acctMenu }</Box>
            </div>
          </div>
        </Flex>
      </div>
    </HsStyleProvider>;
  },
});

module.exports = makeComponentIntl(AppTopBar);
