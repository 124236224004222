/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-param-reassign,
  no-void
*/
import assert from 'assert';
import BaseNotifier from './base';
import CallbackNotifier from './callback';

function TypeNotifier(type, notifier) {
  assert.notEqual(type, void 0, 'type value cannot be undefined');

  BaseNotifier.call(this);

  this.type = type;

  if (typeof notifier === 'function') {
    notifier = CallbackNotifier.create(notifier);
  }

  this.notifier = notifier;
}

BaseNotifier.extend(TypeNotifier, {
  notify(message) {
    if (message.type !== this.type) return;
    return this.notifier.notify(message);
  },
});

export default TypeNotifier;
