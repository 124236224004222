/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';


/* -----------  PASSWORD RECOVERY  ------------------------ */

const PasswordRecoveryResponse = createReactClass({

  propTypes: {
    // Currently ignored for security reasons (message ambiguity)
    isSuccessful: PropTypes.bool,
  },

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      $(document).foundation();
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  componentWillReceiveProps() {
    if (process.env.NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  render() {

    const supportEmail = HfReactHelper.HfConstants.supportEmail[HfReactHelper.HfSites.SITE_CODE_HS];
    const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent('Password Reset')}`;
    const msg = (<span><p>If that is a valid address, we have sent an email to it to reset your password.</p><p>If you&#39;re still experiencing difficulties, please contact us at&nbsp;<a href={mailtoLink}>{ supportEmail }</a>.</p></span>);

    return (
      <div className='row text-center'>
        <div className='columns small-12'>
          <h4>{'Thanks!'}</h4>
          {msg}
        </div>
      </div>
    );
  },
});

module.exports = PasswordRecoveryResponse;
