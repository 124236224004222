/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  eqeqeq,
  max-len,
  no-script-url,
  no-use-before-define,
  no-var,
  no-void,
  vars-on-top
*/
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'common/components/button';
import createReactClass from 'create-react-class';
import { ActionBar as ActionBarPropTypes } from './prop-types';
import CONSTANTS from './constants';

const OFFSET_MARGIN = 65;
const DEFAULT_STYLE = {
  top: 'auto',
  bottom: 0,
};

const ActionBar = createReactClass({

  propTypes: ActionBarPropTypes,

  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      canInsertEverywhere: true,
      disableButtons: false,
      continueInsteadOfInsert: false,
      onContinue: null,
    };
  },

  //  ----  BEHAVIOR  -----------------------------------

  insertSignature(insertEverywhere) {
    this.props.onInsert(insertEverywhere);
  },

  close() {
    this.props.onClose();
  },

  captureRef(node) {
    if (node) {
      this.node = node;
    }
  },

  componentDidMount() {
    const height = this.node.clientHeight;
    this.setState({ height });
  },

  getStyle() {
    const { topOffset, isEmbedded, deviceOrientation } = this.props;
    const isAppleDevice = !!(/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));
    const isGoogleDevice = !!(/nexus|pixel/i.test(navigator.userAgent.toLowerCase()));

    // If Google or Apple device then calculate position otherwise use the default styles, to fix the action bar to the
    // bottom of the window
    if (isGoogleDevice || isAppleDevice) {
      const windowHeight = window.innerHeight;
      const elemHeight = this.state
        ? this.state.height
        : OFFSET_MARGIN;

      if (isEmbedded) {
        // For embedded signing on Google device or when topOffset is not set, return default style
        if (isGoogleDevice || !topOffset) {
          return DEFAULT_STYLE;
        } else { // For embedded signing on Apple device calculate action bar position
          // For portrait mode, double the topOffset value for margin
          // For landscape mode, use topOffset value for margin
          const EMBEDDED_MARGIN = (deviceOrientation === CONSTANTS.ORIENTATION_PORTRAIT) ? (topOffset * 2) : topOffset;

          if (elemHeight + topOffset + EMBEDDED_MARGIN > windowHeight) {
            return DEFAULT_STYLE;
          }

          return {
            top: topOffset + EMBEDDED_MARGIN,
            bottom: 'auto',
          };
        }
      } else { // non-embedded signing
        return {
          top: windowHeight - elemHeight,
          bottom: 'auto',
        };
      }
    } else {
      return DEFAULT_STYLE;
    }
  },

  //  ----  RENDERING  ----------------------------------

  render() {
    const style = this.getStyle();
    const buttons = [];
    const buttonProps = [
      {
        buttonId: 'insertButton',
        buttonColor: 'white',
        buttonHoverColor: this.props.isMobile ? 'white' : 'cerulean',
        buttonTextColor: 'cerulean',
        buttonHoverTextColor: this.props.isMobile ? 'cerulean' : 'white',
        className: this.props.disableButtons ? void 0 : 'whitelabel-secondary-button',
        buttonBorderColor: this.props.disableButtons ? 'cinder-block' : 'cerulean',
        disabled: this.props.disableButtons,
        intl: this.props.intl,
      },
      this.props.canInsertEverywhere ? {
        buttonId: 'insertEverywhereButton',
        buttonColor: 'cerulean',
        buttonTextColor: 'white',
        buttonHoverColor: this.props.isMobile ? 'cerulean' : null,
        buttonHoverTextColor: 'white',
        className: this.props.disableButtons ? void 0 : 'whitelabel-primary-button',
        buttonBorderColor: this.props.disableButtons ? 'cinder-block' : 'cerulean',
        disabled: this.props.disableButtons,
        intl: this.props.intl,
      } : void 0,
    ];

    const continueButtonProps = {
      buttonId: 'continueButton',
      buttonColor: 'cerulean',
      buttonTextColor: 'white',
      buttonHoverColor: this.props.isMobile ? 'cerulean' : null,
      buttonBorderColor: this.props.disableButtons ? 'cinder-block' : 'cerulean',
      buttonHoverTextColor: 'white',
      className: 'whitelabel-primary-button',
      intl: this.props.intl,
    };

    if (this.props.continueInsteadOfInsert) {

      buttons.push(
        (<Button
          key={'sign-modal-button-continue'}
          buttonText={this.props.intl.formatMessage({ id: 'signModal.actions.continue.labelText' }, { everywhere: i })}
          disabled={this.props.disableButtons}
          onClick={this.props.onContinue}
          {...continueButtonProps} />),
      );

    } else {

      // Normal insert buttons
      for (var i = 0; i < 2; i++) {
        if (buttonProps[i]) {
          var buttonText;
          var dataQaRef;
          if (i === 0 && !this.props.canInsertEverywhere && this.props.buttonText) {
            buttonText = this.props.buttonText;
            dataQaRef = 'button-insert';
          } else {
            dataQaRef = 'button-insert-everywhere';
            buttonText = this.props.intl.formatMessage({ id: 'signModal.actions.insert.linkText' }, { everywhere: i });
          }
          buttons.push(
            (<Button
              data-qa-ref={dataQaRef}
              key={`sign-modal-button-${i}`}
              buttonText={buttonText}
              onClick={this.props.disableButtons ? null : this.insertSignature.bind(null, (i === 1))}
              {...buttonProps[i]} />),
          );
        }
      }
    }

    let message = this.props.message;
    if (message == void 0) {
      // null = don't show a message
      message = (
        <FormattedMessage id='signModal.actions.legal.labelText' values={{ type: this.props.type }} />
      );
    }

    return (
      <div className='m-sign-modal--action-bar' style={style} ref={this.captureRef}>
        <div className='m-sign-modal--action-bar--inner'>
          <div data-qa-ref='button-back' className='m-sign-modal--action-bar--close-link m-sign-modal--action-bar--left'>
            <a href='javascript:;' onClick={this.close}>
              <span className='m-sign-modal--action-bar--close-link--arrow'></span>
              <span className='m-sign-modal--action-bar--close-link--text'>
                <FormattedMessage id='signModal.actions.back.linkText' />
              </span>
            </a>
          </div>
          <div className='m-sign-modal--action-bar--right'>
            <div className='m-sign-modal--action-bar--message'>{ message || '' }</div>
            <div className='m-sign-modal--action-bar--buttons'>
              { buttons }
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = ActionBar;
