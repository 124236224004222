/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const hsSrc = require('common/images/logo/hellosign.svg');
const hfSrc = require('common/images/logo/hellofax.svg');
const hwSrc = require('common/images/logo/helloworks.svg');

// Map sitecodes to images
const logoMap = { S: hsSrc, F: hfSrc, W: hwSrc };

// Map site codes to alt/title text versions of logo
const textVersions = {
  S: 'HelloSign',
  F: 'HelloFax',
  W: 'HelloWorks',
};


/**
 * A scalable HelloSign/HelloFax logo that uses SVG. Can be given size/layout styling via CSS.
 * This could be extended later to include props for overriding the color, e.g. all-white/all-black versions.
 */
class Logo extends React.Component {
  static propTypes = {
    // Site code to determine which product's logo to use
    siteCode: PropTypes.oneOf(['S', 'F', 'W']).isRequired,
    // If set, the <img> will be wrapped in a <a> which links to the homepage by default
    isLink: PropTypes.bool,
    // Optional override for the href of the <a> tag
    href: PropTypes.string,
  };

  /**
  * Get attribute for selenium based on page
  *
  * @returns {string}
  * @private
  */
  get qaAttribute() {
    const pathname = window.location.pathname;
    let qaAttr = '';

    if (pathname.indexOf('signUp') >= 0) {
      qaAttr = 'hello-sign-logo-sign-up-page';
    } else if (pathname.indexOf('logIn') >= 0) {
      qaAttr = 'hello-sign-logo-login-page';
    }

    return qaAttr;
  }

  render() {
    const text = textVersions[this.props.siteCode];
    const src = logoMap[this.props.siteCode];

    let contents = <img src={src} alt={text} title={text} data-qa-ref={this.qaAttribute} />;

    if (this.props.isLink) {
      contents = <a href={this.props.href}>{contents}</a>;
    }

    return <div className='m-logo'>{contents}</div>;
  }
}
Logo.defaultProps = {
  isLink: false,
  href: '/',
};
export default Logo;
