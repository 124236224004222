/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import './small-toggle.scss';
import createReactClass from 'create-react-class';

import React from 'react';

const Toggle = createReactClass({
  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  },

  render() {
    return <div className={`rx-toggle ${this.props.on ? ' on' : 'off'}`} onClick={this.handleClick}>
      <span className='off-label uppercase'>{this.props.offLabel}</span>
      <div className='switch'>
        <div className='switch-button'></div>
      </div>
      <span className='on-label uppercase'>{this.props.onLabel}</span>
    </div>;
  },
});

module.exports = Toggle;
