/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-mutable-exports,
  no-var
*/
import { create, extend } from 'common/utils/class';

// cannot use const - doesn't work in IE. Use var instead.
export var CHANGE = 'change';
export var RESPONSE = 'response';
export var ERROR = 'error';

export var LOAD = 'load';
export var INSERT = 'insert';
export var UPDATE = 'update';
export var REMOVE = 'remove';

export var INITIALIZE = 'initialize';

export function BaseMessage(type, props = {}) {
  Object.assign(this, {
    type,
    ...props,
  });
}

BaseMessage.create = create;
BaseMessage.extend = extend;

export function ChangeMessage(target, changes = []) {
  BaseMessage.call(this, CHANGE, {
    target,
    changes,
  });
}

// make instanceof work
BaseMessage.extend(ChangeMessage);

export function ResponseMessage(response, requestMessage) {
  BaseMessage.call(this, RESPONSE, {
    data: response,
    requestMessage,
  });
}

BaseMessage.extend(ResponseMessage);

export function RequestMessage(type, apiEndpointAlias, props) {
  BaseMessage.call(this, type, {
    apiEndpointAlias,
    ...props,
  });
}

BaseMessage.extend(RequestMessage, {
  resolve() {
  },
  reject() {
  },
});

export function ErrorMessage(error, target) {
  BaseMessage.call(this, ERROR, {
    error,
    target,
  });
}

BaseMessage.extend(ErrorMessage);

export function InitializeMessage() {
  BaseMessage.call(this, INITIALIZE);
}

BaseMessage.extend(InitializeMessage);
