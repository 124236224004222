/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { combineForms } from 'react-redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import SimpleGrayLayout from 'common/components/simple-gray-layout';
import RecoverPasswordForm from './recover-password-form';

/**
 * Top-level component for standalone Recover Password (Forgot Password) page.
 * Expects some props from the backend, such as CSRF token.
 */
class RecoverPasswordPage extends React.Component {
    static propTypes = {
      // CSRF Token - Sent from the server
      csrfToken: PropTypes.string.isRequired,

      // Email value - This is useful to provide for testing purposes
      email: PropTypes.string,

      siteCode: PropTypes.string.isRequired,
      submitUrl: PropTypes.string.isRequired,
      autoSubmit: PropTypes.bool,
      redirectUrl: PropTypes.string,
    };

    constructor(props) {
      super(props);

      // Prepare initial form state
      const initialState = {
        email: this.props.email || window.sessionStorage.email,
        csrfToken: this.props.csrfToken,
      };
      window.sessionStorage.removeItem('email');

      // Set up Redux store
      this.store = createStore(
        combineForms({
          recoverPassword: initialState,
        }),
        composeWithDevTools(
          applyMiddleware(thunk),
        ),
      );
    }

    render() {
      return (
        <Provider store={this.store}>
          <SimpleGrayLayout siteCode={this.props.siteCode}>
            <RecoverPasswordForm
              submitUrl={this.props.submitUrl}
              siteCode={this.props.siteCode}
              autoSubmit={this.props.autoSubmit}
              redirectUrl={this.props.redirectUrl} />
          </SimpleGrayLayout>
        </Provider>
      );
    }
}

module.exports = RecoverPasswordPage;
