/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  no-console,
  no-redeclare,
  no-var,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Portal from 'common/components/portal';
import createReactClass from 'create-react-class';

const Croppable = createReactClass({

  propTypes: {
    restrictToParent: PropTypes.bool,
    callback: PropTypes.func,
    className: PropTypes.string,
  },

  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      restrictToParent: false,
      callback() {
      },
      containerClassName: 'croppable-container',
    };
  },

  getInitialState() {
    return {
      startX: null,
      startY: null,
      endX: null,
      endY: null,
      dragging: false,
      boxIsShown: false,
    };
  },

  componentDidMount() {
  },

  componentDidUpdate(props, state) {
    this.addListeners(state);
  },


  //  ----  BEHAVIOR  -----------------------------------

  // point is {x: x, y: y}
  isInBounds(point) {

    if (!this.props.restrictToParent) {
      return true;
    }

    const parent = ReactDOM.findDOMNode(this).parentNode;

    if (parent) {

      const rect = parent.getBoundingClientRect();

      if (((rect.left < point.x) && (point.x < rect.right))
            && ((rect.top < point.y) && (point.y < rect.bottom))) {
        return true;
      }

    } else {
      console.error('restrictToParent specified, but no parent found');
    }

    return false;

  },

  mousedown(e) {

    const point = {
      x: e.pageX,
      y: e.pageY,
    };

    if (this.isInBounds(point)) {
      this.setState({
        dragging: true,
        boxIsShown: true,
        startX: point.x,
        startY: point.y,
        endX: point.x,
        endY: point.y,
      });
    }


    e.stopPropagation();
    e.preventDefault();
  },

  mouseup(e) {

    this.setState({
      dragging: false,
    });

    this.props.callback({
      left: this.state.startX,
      top: this.state.startY,
      width: (this.state.endX - this.state.startX),
      height: (this.state.endY - this.state.startY),
    });

    e.stopPropagation();
    e.preventDefault();
  },

  mousemove(e) {
    if (this.state.dragging) {

      const point = {
        x: e.pageX,
        y: e.pageY,
      };

      if (this.isInBounds(point)) {
        this.updateEndpoint(point);
      }

      e.stopPropagation();
      e.preventDefault();
    }
  },

  updateEndpoint(point) {
    this.setState({
      endX: point.x,
      endY: point.y,
    });
  },

  addListeners(state) {
    var state = state || {};
    if (this.state.dragging && !state.dragging) {
      document.addEventListener('mousemove', this.mousemove);
      document.addEventListener('mouseup', this.mouseup);
    } else if (!this.state.dragging && state.dragging) {
      document.removeEventListener('mousemove', this.mousemove);
      document.removeEventListener('mouseup', this.mouseup);
    }
  },

  //  ----  RENDERING  ----------------------------------

  render() {

    let style = {};

    if (this.state.boxIsShown) {

      style.display = 'block';

      style = {
        border: 'dashed 1px black',
        position: 'absolute',
        zIndex: 50000,
        width: (this.state.endX - this.state.startX),
        height: (this.state.endY - this.state.startY),
        left: this.state.startX,
        top: this.state.startY,
      };
    } else {
      style.display = 'none';
    }

    const cropBox = (
      <div className='cropbox' style={style}>

      </div>
    );

    return (
      <div className={this.props.containerClassName}
        onMouseDown={this.mousedown}
      >
        { this.props.children }
        <Portal rerenderOnUpdate={true}>{ cropBox }</Portal>
      </div>
    );
  },
});

module.exports = Croppable;
