/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import './or-divider.scss';

/**
 * A horizontal rule with the word "OR" appearing over the center
 */
export default class OrDivider extends React.Component {
  render() {
    return (
      <div className='m-or-divider'>
        <div className='m-or-divider--or'>or</div>
        <div className='m-or-divider--rule'></div>
      </div>
    );
  }
}
