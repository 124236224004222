/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  react/no-string-refs
*/
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import countries from '../../constants/countries';
import './index.scss';

const messages = defineMessages({
  selectCountry: {
    id: '',
    defaultMessage: 'Select your country',
    description: 'countries dropdown',
  },
});

class CountriesDropdown extends React.Component {
    static propTypes = {
      selectedCountryCode: PropTypes.string,
      onCountryCodeChange: PropTypes.func,
      intl: PropTypes.object,
    };

    constructor(props) {
      super(props);

      let initialCountryName = <FormattedMessage id='' defaultMessage='Select your country?' description='select country dropdown on signup' />;
      if (this.props.selectedCountryCode !== '') {
        initialCountryName = countries[this.props.selectedCountryCode];
      }

      this.state = {
        selectedCountryName: initialCountryName,
      };
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
      const selectedCountryCode = evt.currentTarget.value;

      this.setState({
        selectedCountryName: countries[selectedCountryCode],
      });

      if (this.props.onCountryCodeChange !== undefined) {
        this.props.onCountryCodeChange(selectedCountryCode);
      }
    }

    componentDidUpdate() {
      const width = this.getWidth();
      if (width !== this.state.width) {
        this.setState({ width });
      }
    }

    getWidth() {
      const el = this.refs.measureWidthEl;
      if (el.clientWidth === 0) {
        // Don't change the width when the component is hiddent with `display: none`.
        return this.state.width;
      }
      return Math.min(300, Math.max(50, el.clientWidth));
    }

    render() {
      return (
        <div>
          <div ref="measureWidthEl" style={{
            position: 'absolute', display: 'inline-block', padding: '10px', top: '-9999px', left: '-9999px',
          }}
          >{this.state.selectedCountryName}</div>
          <select className="countries-dropdown--select" defaultValue={this.props.selectedCountryCode}
            style={{ width: this.state.width }}
            onChange={this.handleChange}
          >
            <option value="" disabled="disabled">{this.props.intl.formatMessage(messages.selectCountry)}</option>
            {Object.entries(countries).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </div>

      );

    }
}

export default injectIntl(CountriesDropdown);
