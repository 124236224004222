/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  no-script-url,
  no-sequences,
  no-useless-constructor,
  no-useless-escape,
  one-var,
  prefer-const,
  prefer-rest-params
*/
import React from 'react';

module.exports = class ContactLink extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const contactText = this.props.contactText || 'Contact';
    return <a href='https://faq.hellosign.com/hc/en-us/requests/new'>{contactText}</a>;
  }
};
