/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-use-before-define
*/
import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import HfReactHelper from 'common/utils/hf-react-helper';
import PricingLevel from '../pricing-level';
import CorporatePricingLevel from '../corporate-pricing-level';
import Arrow from '../arrow';

class PricingLevels extends React.Component {

    static propTypes = {
      billingPlans: PropTypes.array.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        currentRowIndex: 0,
      };
    }

    shouldBeCarousel = () => this.props.billingPlans.length;

    shouldRenderLevel = (level) => level && level.name && level.is_displayed == 'true';

    validBillingPlans = () => this.props.billingPlans.filter(this.shouldRenderLevel);

    rows = () => {
      const maxLength = HfReactHelper.isHelloSign() ? 3 : 4;

      return this.validBillingPlans()
        .map(pricingLevel.bind(this, this.props))
        .reduce((previousValue, currentValue) => {
          const lastRow = previousValue[previousValue.length - 1];
          if (lastRow && lastRow.length < maxLength) {
            lastRow.push(currentValue);
            previousValue[previousValue.lenth - 1] = lastRow;
            return previousValue;
          } else {
            previousValue.push([currentValue]);
            return previousValue;
          }
        }, []).map((row, index) => {
          return <div className='row' key={index}>
            {row}
          </div>;
        });
    };

    handleClick = () => {
      const nextIndex = (this.state.currentRowIndex + 1) % this.rows().length;
      this.setState({ currentRowIndex: nextIndex });
    };

    shouldRenderArrow = () => this.rows().length > 1;


    render() {
      const currentRow = this.rows()[this.state.currentRowIndex];
      const animationDirection = this.state.currentRowIndex === 1 ? 'left' : 'right';
      const arrow = <Arrow handleClick={this.handleClick} direction={animationDirection} />;
      const showCorporatePricing = HfReactHelper.isHelloSign();
      const totalColumns = this.props.billingPlans.length;
      const className = classNames({
        'm-pricing-level': true,
        'large-12': !showCorporatePricing && totalColumns >= 3,
        'large-9': showCorporatePricing && totalColumns >= 3,
        'large-8': totalColumns == 2,
        'large-6': totalColumns == 1,
        columns: true,
        [`pricing-animation-${animationDirection}`]: true,
      });
      const wrapperStyle = {
        overflow: 'hidden',
        position: 'relative',
        paddingTop: 60,
      };
      return <div>
        <div className={className} style={wrapperStyle}>
          <CSSTransitionGroup transitionName='pricing-row'
            transitionEnterTimeout={240}
            transitionLeaveTimeout={240}
          >
            {currentRow}
          </CSSTransitionGroup>
        </div>
        { this.shouldRenderArrow() ? arrow : null }
        { showCorporatePricing ? corporatePricingLevel(this.props) : null }
      </div>;
    }
}

function pricingLevel(props, level, i) {
  const annualPrice = (level.annual_price / 12).toFixed(2);
  const annualCouponPrice = level.coupon_annual_price && (level.coupon_annual_price / 12).toFixed(2);
  const monthlyCouponPrice = level.coupon_monthly_price && (level.coupon_monthly_price).toFixed(2);

  // TODO:  Refactor PricingLevel so all you need to is pass "level" to
  // "PricingLevel" and let "PricingLevel" figure out all these props. In
  // other words, don't tell "PricingLevel" what it needs. "PricingLevel"
  // should be the one that know's what it needs. (FR)
  return <PricingLevel key={i}
    totalColumns={props.billingPlans.length}
    signupUrl={props.signupUrl}
    cardHolderName={props.cardHolderName}
    errors={props.errors}
    paymentForm={props.paymentForm}
    dbxPaymentMethod={props.dbxPaymentMethod}
    signupForm={props.signupForm}
    mustContactSales={props.mustContactSales}
    changePlanRestricted={props.changePlanRestricted}
    isAdmin={props.isAdmin}
    isDelinquent={props.isDelinquent}
    timePeriod={props.timePeriod}
    skipCC={props.skipCC}
    trialPeriod={props.trialPeriod}
    name={level.name}
    isApi={level.is_api}
    isUpgrade={level.is_upgrade}
    mainFeature={level.main_feature}
    features={level.features}
    monthlyPrice={level.monthly_price}
    annualPrice={annualPrice}
    monthlyCouponPrice={monthlyCouponPrice}
    annualCouponPrice={annualCouponPrice}
    price={props.isMonthly ? level.monthly_price : annualPrice}
    couponPrice={props.isMonthly ? monthlyCouponPrice : annualCouponPrice}
    monthlyExpectedRate={level.monthly_expected_rate}
    annualExpectedRate={level.annual_expected_rate}
    monthlyProratedRate={level.monthly_prorated_rate}
    annualProratedRate={level.annual_prorated_rate}
    maxSeats={level.max_seats}
    mostPopular={level.is_api && i == 1}
    coupon={props.hasValidCoupon}
    couponEligible={level.coupon}
    couponCode={props.hasValidCoupon ? level.coupon_code : null}
    isFreeTrialEligible={level.is_free_trial_eligible}
    numFreeDays={level.num_free_days}
    isTrialing={level.is_trialing}
    proratio={level.proratio}
    planId={level.plan_id}
    isDisplayed={level.is_displayed}
    monthlyRedirect={level.monthly_redirect}
    annualRedirect={level.annual_redirect}
    ccSuffix={level.cc_suffix}
    ccExpiration={level.cc_expiration}
    isCurrentPlan={level.current_plan == level.plan_id}
    ApiPendingCancel={level.api_pending_cancel}
    UiPendingCancel={level.ui_pending_cancel}
    currentMonthlyRate={level.current_monthly_rate}
    currentAnnualRate={level.current_annual_rate}
    currentPlanName={props.pricingData.current_plan_name}
    isLoggedIn={level.is_logged_in}
    acctModalError={level.acct_modal_error}
    wasSelectedBeforeLogIn={level.is_logged_in && level.was_selected_before_login}
    tosUrl={props.tosUrl}
    privacyPolicyUrl={props.privacyPolicyUrl}
    refundPolicyUrl={props.refundPolicyUrl}
    csrfToken={props.csrfToken}
    googleSignInClientId={props.googleSignInClientId}
    dropboxSSO={props.dropboxSSO} />;
}

function corporatePricingLevel(props) {
  return <CorporatePricingLevel key='corporate'
    isApi={props.isApi}
    pricingSource={props.pricingSource}
    coupon={props.coupon}
    corporateFeatures={props.corporateFeatures}
    timePeriod={props.timePeriod}
    totalColumns={props.billingPlans.length}
    corpLevelName={props.corpLevelName}
    changePlanRestricted={props.changePlanRestricted} />;
}

module.exports = PricingLevels;
