/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-alert,
  no-console,
  react/jsx-no-target-blank,
  react/no-unescaped-entities,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';

import Code from './components/code';
import Form from './components/form';
import client from './client';
import { withAppContext, withAppContextProvider } from './contexts/app';

import './index.scss';

class EmbeddedTestingTool extends React.PureComponent {

  static propTypes = {
    getSampleURL: PropTypes.string.isRequired,
    csrfToken: PropTypes.string.isRequired,
    cultures: PropTypes.object.isRequired,
  };

  launchEmbedded = () => {
    const { form, isSampleDocument, hasLaunchedSampleDocument } = this.props.appContext.state;

    if (!hasLaunchedSampleDocument && isSampleDocument) {
      this.props.appContext.actions.launchSampleEmbeddedDocument();
    }

    const opts = {
      allowCancel: form.allowCancel,
      clientId: form.clientID,
      hideHeader: form.hideHeader,
      locale: form.locale,
      testMode: form.testMode,
      timeout: form.timeout,
    };

    if (form.redirectURL.length) {
      opts.redirectTo = form.redirectURL;
    }

    if (form.requestingEmail.length) {
      opts.requestingEmail = form.requestingEmail;
    }

    if (form.whiteLabelingCode.length) {
      try {
        opts.whiteLabeling = JSON.parse(form.whiteLabelingCode);
      } catch (err) {
        // Not valid JSON.
      }
    }

    client.once('close', () => {
      this.props.appContext.actions.resetEmbedded();
    });

    client.open(form.signURL, opts);
  };

  componentDidMount() {
    const { csrfToken, cultures, getSampleURL } = this.props;

    this.props.appContext.actions.hydrateStore({
      getSampleURL,
      csrfToken,
      cultures,
    });
  }

  render() {
    return (
      <div className="embedded-testing-tool clearfix">
        <div className="m-api-docs-section">
          <p>
            You can use this page to quickly test any of HelloSign's Embedded flows without having
            to write a single line of JavaScript. Submitting the form below will trigger HelloSign
            Embedded's <code>open()</code> method with the options you specify.
          </p>
          <p>
            If you already have a client ID and embedded URL, enter them in the fields below. Or
            if this is your first time, we can generate this data for you by pressing the blue
            &ldquo;Get a sample document&rdquo; button.
          </p>
        </div>
        <Form launchEmbedded={this.launchEmbedded} />
        <Code launchEmbedded={this.launchEmbedded} />
      </div>
    );
  }
}

export default withAppContextProvider(withAppContext(EmbeddedTestingTool));
