/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  global-require,
  guard-for-in,
  import/no-extraneous-dependencies,
  max-len,
  no-prototype-builtins,
  no-restricted-syntax,
  no-var,
  no-void,
  react/no-find-dom-node,
  react/prop-types,
  vars-on-top
*/
import './button.scss';
import cx from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';

const Button = createReactClass({

  propTypes: {
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.node,
    ]),
    buttonLink: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonHoverColor: PropTypes.string,
    throbber: PropTypes.bool,
    buttonTextColor: PropTypes.string,
    buttonTextUppercase: PropTypes.bool,
    buttonLinkUsePost: PropTypes.bool,
    buttonLinkPostParams: PropTypes.object,
    buttonAttrs: PropTypes.object,
    buttonHoverTextColor: PropTypes.string,
    buttonHoverBorderColor: PropTypes.string,
    buttonBorderColor: PropTypes.string,
    buttonBorderWidth: PropTypes.string,
    buttonFont: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonId: PropTypes.string,
    buttonModuleClass: PropTypes.string,
    buttonTabIndex: PropTypes.any,
    buttonMountPoint: PropTypes.string,
    type: PropTypes.string,
    wrapText: PropTypes.bool,
    buttonInner: PropTypes.object,
    iconUri: PropTypes.string,
    openInNewWindow: PropTypes.bool,
    disabled: PropTypes.bool,
  },


  getInitialState() {
    return {
      isProcessing: false,
    };
  },

  getDefaultProps() {
    return {
      buttonColor: 'ashen',
      buttonHoverColor: 'castle-rock',
      buttonTextColor: 'white',
      buttonTextUppercase: true,
      buttonModuleClass: '',
      buttonTabIndex: 0,
      type: 'button',
      wrapText: false,
      buttonLinkInNewWindow: false,
      iconUri: null,
    };
  },

  linkToLocation(evt) {

    if (this.props.throbber) {
      this.setState({ isProcessing: true });
    }
    document.location.href = this.props.buttonLink;

    // TODO: don't do this - users cannot ctrl+click a link and open a new
    // tab with this approach (CC)
    evt.preventDefault();
  },

  // TODO: Button should call onClick handler instead of handling service-level calls. (CC)
  submitAsPost(evt) {
    evt.preventDefault();
    const form = $(document.createElement('form')).attr('action', this.props.buttonLink).attr('method', 'POST');
    $('body').append(form);
    for (const i in this.props.buttonLinkPostParams) {
      if (this.props.buttonLinkPostParams.hasOwnProperty(i)) {
        var key = i;
        var value = this.props.buttonLinkPostParams[i];
      }
      $(document.createElement('input')).attr('type', 'hidden').attr('name', key).attr('value', value)
        .appendTo(form);
    }
    $(form).submit();
  },

  updateAttributes() {
    // I'm pretty sure everything about this is wrong, but I need a release fix
    // right now. This used to only be called from `componentDidMount`, and
    // these should probably just be attached in render.
    if (this.props.buttonAttrs) {
      const node = this.props.openInNewWindow ? ReactDOM.findDOMNode(this).firstElementChild : ReactDOM.findDOMNode(this);
      for (const attr in this.props.buttonAttrs) {
        node.setAttribute(attr, this.props.buttonAttrs[attr]);
      }
    }
  },

  componentDidUpdate() {
    this.updateAttributes();
  },

  componentDidMount() {
    this.updateAttributes();

    if (this.props.throbber) {

      // TODO: remove? No idea where this is actually coming from. This sorta functionality
      // should be left up to the parent where the parent is setting 'isProcessing' explicitly (CC)
      $(document).bind('cancelProcessing', () => {
        this.setState({
          isProcessing: false,
        });
      });
    }
  },

  render() {
    const buttonColorClass = `bg-${this.props.buttonColor}`;
    const buttonHoverTextColorClass = this.props.buttonHoverTextColor ? `hc-${this.props.buttonHoverTextColor}` : '';
    const buttonBorderColorClass = this.props.buttonBorderColor ? `border-${this.props.buttonBorderWidth ? this.props.buttonBorderWidth : '1'}-${this.props.buttonBorderColor}` : '';
    const buttonHoverBorderColorClass = this.props.buttonHoverBorderColor ? `hborder-${this.props.buttonBorderWidth ? this.props.buttonBorderWidth : '1'}-${this.props.buttonHoverBorderColor}` : '';
    const buttonHoverColorClass = `hbg-${this.props.buttonHoverColor}`;
    const buttonTextColor = `c-${this.props.buttonTextColor}`;
    const buttonTextUppercase = this.props.buttonTextUppercase ? '' : 'm-button-normalcase';

    const buttonClasses = { 'm-button': true };

    if (typeof this.props.className !== 'undefined') {
      buttonClasses[this.props.className] = true;
    }

    buttonClasses[buttonColorClass] = true;
    buttonClasses[this.props.buttonModuleClass] = true;
    buttonClasses[buttonHoverTextColorClass] = this.props.buttonHoverTextColor;
    buttonClasses[buttonBorderColorClass] = this.props.buttonBorderColor;
    buttonClasses[buttonHoverBorderColorClass] = this.props.buttonHoverBorderColor;
    buttonClasses[buttonHoverColorClass] = true;
    buttonClasses[buttonTextColor] = true;
    buttonClasses[buttonTextUppercase] = true;
    buttonClasses['progress-button'] = true;
    buttonClasses['state-loading'] = true;

    if (typeof this.props.buttonClass !== 'undefined') {
      buttonClasses[this.props.buttonClass] = true;
    }

    buttonClasses['with-icon'] = (this.props.iconUri !== null);

    const innerSpanClasses = cx({
      'l-nowrap': !this.props.wrapText,
    });

    let throbber;
    const font = this.props.buttonFont ? { fontFamily: this.props.buttonFont } : { };
    if (this.props.throbber) {
      const throbberUrl = require('./transparent-throbber.gif');
      const throbberClassName = 'm-button-throbber';
      throbber = (
        <img src={throbberUrl} className={throbberClassName} width='16' height='16' />
      );
    }

    let inner;
    if (this.props.buttonInner) {
      inner = (
        <span className={innerSpanClasses} style={font}>{ this.props.buttonInner }</span>
      );
    } else if (this.props.iconUri) {
      const iconUrl = HfReactHelper.imgHelper(this.props.iconUri);
      const iconClassName = this.props.buttonText ? 'm-button--icon-with-text' : '';
      const iconHtml = <span className={iconClassName}><img src={iconUrl} className='m-button--icon' /></span>;
      inner = (
        <div>
          {iconHtml}
          <span className={innerSpanClasses} style={font}>
            { this.props.buttonText }
          </span>
        </div>
      );
    } else {
      inner = (
        <span className={innerSpanClasses} style={font}>
          { this.props.buttonText }
        </span>
      );
    }

    const hasButtonLink = this.props.buttonLink && typeof (this.props.buttonLink) === 'string';
    const handlerProvided = !!(this.props.onClick || this.state.onClick);

    // TODO: This has been refactored for clarity, but we still need to move the submit handler totally out of this
    // per (CC)'s comment above (DB)
    let onClick;
    if (!handlerProvided) {
      if (this.props.buttonLinkUsePost) {
        onClick = this.submitAsPost;
      } else if (hasButtonLink && this.props.openInNewWindow) {
        onClick = void 0; // Use native link logic
      } else if (hasButtonLink) {
        onClick = this.linkToLocation; // Our specific link logic
      }
    } else {
      onClick = this.props.onClick || this.state.onClick;
    }

    // Only pass the data-test-ref if one was provided. If we always pass it
    // then most buttons will have a `data-test-ref="undefined"` property in the
    // DOM
    const testRef = (this.props['data-test-ref'] != null)
      ? { 'data-test-ref': this.props['data-test-ref'] }
      : {};
    const qaRef = (this.props['data-qa-ref'] != null)
      ? { 'data-qa-ref': this.props['data-qa-ref'] }
      : {};
    let btn = (
      <button
        {...testRef}
        {...qaRef}
        className={cx(buttonClasses)}
        onClick={onClick}
        id={this.props.buttonId}
        type={this.props.type}
        disabled={this.props.disabled}
        tabIndex={this.props.buttonTabIndex}
      >{ inner }</button>
    );

    if (this.props.openInNewWindow) {
      btn = (
        <a href={this.props.buttonLink} target='_blank' rel='noopener noreferrer'>{ btn }</a>
      );
    }

    if (this.props.throbber && this.state.isProcessing) {
      return (
        <div className='m-button-submit'>
          {throbber}
        </div>
      );
    } else {
      return btn;
    }

  },
});

module.exports = Button;
