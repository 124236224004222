/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-use-before-define,
  react/no-unescaped-entities
*/
import './index.scss';
import React from 'react';
import Button from 'common/components/button';
import APIIconWhite from 'images/v2/modules/home/api-icon-white@2x.png';
import Number1 from './assets/number_1.svg';

const ANIMATION_INTERVAL = 7000;
const ANIMATION_FADEOUT_SPEED = 320;
const ANIMATE_IN = 'animate-in';
const ANIMATE_OUT = 'animate-out';
const ANNOUNCEMENTS = [G2CrowdMediumBar, G2CrowdSmallBar, APIBar];

const baseButtonProps = {
  buttonText: 'Learn more',
  buttonColor: 'white',
  buttonHoverColor: 'grasshopper',
  buttonTextColor: 'black',
  buttonHoverTextColor: 'white',
};

function G2CrowdMediumBar(props) {
  const { animation } = props;
  const buttonProps = Object.assign(
    {},
    baseButtonProps,
    { buttonLink: 'http://blog.hellosign.com/hellosign-number-one-esignature-solution-for-SMBs-and-mid-market-businesses' },
  );
  return <div className={`m-home-api--inner row ${animation}`}>
    <span className='m-home-api--icon'>
      <img src={Number1} />
    </span>
    <span className='m-home-api--text'>
            Voted #1 Software Product for Mid-Market Businesses
    </span>
    <span className='m-home-api--cta'>
      <Button {...buttonProps} />
    </span>
  </div>;
}

function G2CrowdSmallBar(props) {
  const { animation } = props;
  const buttonProps = Object.assign(
    {},
    baseButtonProps,
    { buttonLink: 'http://blog.hellosign.com/hellosign-ranked-top-esignature-solution-by-g2-crowd' },
  );
  return <div className={`m-home-api--inner row ${animation}`}>
    <span className='m-home-api--icon'>
      <img src={Number1} />
    </span>
    <span className='m-home-api--text'>
            HelloSign Ranked #1 eSignature Solution for Small Businesses.
    </span>
    <span className='m-home-api--cta'>
      <Button {...buttonProps} />
    </span>
  </div>;
}

function APIBar(props) {
  const { animation } = props;
  const buttonProps = Object.assign(
    {},
    baseButtonProps,
    { buttonLink: '/info/api' },
  );
  return <div className={`m-home-api--inner row ${animation}`}>
    <span className='m-home-api--icon'>
      <img src={APIIconWhite} />
    </span>
    <span className='m-home-api--text'>
            Integrate eSignatures into your website with our API.
    </span>
    <span className='m-home-api--cta'>
      <Button {...buttonProps} />
    </span>
  </div>;
}


class BlackBarAnnouncements extends React.Component {
  constructor(props) {
    super(props);
    this.clearInterval = this.clearInterval.bind(this);
    this.initiateAnimations = this.initiateAnimations.bind(this);
    this.animate = this.animate.bind(this);
    this.incrementIndex = this.incrementIndex.bind(this);
    this.state = {
      currentComponentIndex: this.props.initialComponentIndex || 0,
      currentAnimation: this.props.shouldAnimate ? ANIMATE_IN : null,
      timerId: null,
    };
  }

  componentDidMount() {
    if (this.props.shouldAnimate) this.initiateAnimations();
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  initiateAnimations() {
    const interval = setInterval(() => this.animate(), ANIMATION_INTERVAL);
    this.setState({ timerId: interval });
  }

  clearInterval() {
    const { timerId } = this.state;
    if (timerId) clearTimeout(timerId);
    this.setState({ timerId: null });
  }

  animate() {
    this.setState({ currentAnimation: ANIMATE_OUT });
    setTimeout(() => this.incrementIndex(), ANIMATION_FADEOUT_SPEED);
  }

  incrementIndex() {
    const { currentComponentIndex } = this.state;
    const nextComponentIndex = (currentComponentIndex + 1) % ANNOUNCEMENTS.length;
    this.setState({
      currentComponentIndex: nextComponentIndex,
      currentAnimation: ANIMATE_IN,
    });
  }

  render() {
    const { currentComponentIndex, currentAnimation } = this.state;
    const CurrentAnnouncement = ANNOUNCEMENTS[currentComponentIndex];
    return <CurrentAnnouncement animation={currentAnimation} />;
  }
}

module.exports = BlackBarAnnouncements;
