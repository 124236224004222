/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  no-restricted-globals
*/
import React from 'react';
import createReactClass from 'create-react-class';
import Button from './button';

const CloseFancyBoxButton = createReactClass({
  render() {
    const closeFancyBox = function () {
      parent.$.fancybox.close();
    };
    return <Button {...this.props} onClick={closeFancyBox} />;
  },
});

module.exports = CloseFancyBoxButton;
