/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  func-names,
  no-redeclare,
  no-restricted-globals,
  no-var,
  one-var,
  react/no-find-dom-node,
  vars-on-top
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import baseform from 'common/components/base-form';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import classNames from 'classnames';
import setTimeout from 'common/utils/set-timeout';
import logger from 'common/logger';
import CountriesDropdown from 'common/components/countries-dropdown';
import pushGoogleTagManagerEvent from 'common/utils/pushGoogleTagManagerEvent';
import LogInTrigger from './login-trigger';
import GoogleSignInButton from './google-signin-button';
import DropboxSignInButton from './dropbox-signin-button';


/* -----------  SIGNUP  ----------------------------------- */

/**
 * NOTE: This form does not follow the proper way to use
 * React and Symfony Forms, a better example can be found
 * by looking at ForgotPasswordForm
 */

const CreateAccountForm = baseform.createFormClass({

  displayName: 'CreateAccountForm',

  propTypes: {
    header: PropTypes.string,
    buttonText: PropTypes.string,
    isInline: PropTypes.bool,
    isVertical: PropTypes.bool, // only works for inline
    inlineSideText: PropTypes.string,
    csrfToken: PropTypes.string,
    googleSignInClientId: PropTypes.string,
    dropboxSSO: PropTypes.bool,
    redirectUrl: PropTypes.string,
    isFromApiPricing: PropTypes.bool,
    planId: PropTypes.string,
    planName: PropTypes.string,
    skipCC: PropTypes.bool,
    period: PropTypes.string,
    trialPeriod: PropTypes.number,
    tabOffset: PropTypes.number,
    async: PropTypes.bool,
    recoverPasswordForm: PropTypes.object, // Serialized sfForm
    loginForm: PropTypes.object, // Serialized sfForm
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      async: true,
    };
  },

  getInitialState() {
    return {
      errors: this.props.errors,
      countryCode: 'US',
      emailAddress: '',
      isProcessing: false,
      isInline: false,
      isVertical: false,
      isOptedIn: true,
    };
  },

  componentDidMount() {
    hellofaxJS.trackGAPageViewEvent('accountSignUp', null, this.props.period, this.props.planName, 'HelloSign');
    logger.track('CreateAccountForm.componentDidMount', {
      location: location.pathname,
    });

    if (!this.props.isInline) {
      // This is a modal, so focus on the email address input element
      setTimeout(() => {
        $(ReactDOM.findDOMNode(this)).find('input[name="signup[email_address]"]').focus();
      }, 500);
    }
  },

  onEmailChange(event) {
    this.setState({
      emailAddress: event.target.value.trim(),
    });
  },

  onOptInChange(event) {
    this.setState({
      isOptedIn: event.target.checked,
    });
  },

  onCountryCodeChange(countryCode = 'US') {
    this.setState({
      countryCode,
      isOptedIn: countryCode === 'US',
    });
  },

  onSubmit(evt) {
    evt.preventDefault();


    pushGoogleTagManagerEvent({
      category: 'signup',
      action: `create account inapp - ${this.props.isApi
        ? 'hsapi'
        : 'hs'}`,
      label: 'create an account',
    });

    if (this.state.isProcessing === false) {

      // Retrieve the values from the email field one more time
      // prior to submit. This fixes an issue where Safari auto-complete
      // would not trigger the on*Change events for these fields.
      const root = ReactDOM.findDOMNode(this);
      const emailAddress = $('#sign_up_email_address_input', root).val();

      this.setState({
        errors: null,
        isProcessing: true,
        emailAddress,
      });

      if (!HfReactHelper.isValidEmailAddress(emailAddress)) {

        this.setState({
          errors: { named: { email_address: 'Invalid email address' } },
          isProcessing: false,
        });

      } else if (!this.props.async) {
        logger.track('CreateAccountForm._onSubmit');
        // Inline submit
        $('#sign_up_form', root).submit();

      } else {

        // Async submit
        this.submitAsync(function (resp) {

          this.setState({
            isProcessing: false,
          });

          // Display notification
          if (resp.notification) {
            hellofaxJS.createNotification({
              text: resp.notification.message,
              class_name: resp.notification.type,
            });
          }

          // Close the signup modal and show the login one
          if (resp.show_login_form) {
            $('.m-modal--close', '#create_account_modal').click();
            $(document).trigger('login-trigger', { displayCaptcha: resp.display_captcha });
            return;
          }

          // Redirect to redirect_url
          if (resp.success === true) {
            logger.track('CreateAccountForm._onSubmit');
            if (resp.redirect_url) {
              document.location = resp.redirect_url;
            }
          }

          // Failure
          if (!resp.success) {

            // Display error message
            if (resp.error && !resp.notification_message) {
              this.setState({
                errors: { global: resp.error.message },
              });
            }

          }

        }, function () {

          // Unexpected error
          this.setState({
            errors: { global: 'An unexpected error occurred' },
            isProcessing: false,
          });

        });

      }
    }
  },

  render() {
    // is vertical (even if not inline)
    const isVertical = !this.props.isInline || this.props.isVertical;
    const tabOffset = parseInt(this.props.tabOffset || 0, 10);
    const rowClassNames = classNames({
      row: true,
      'text-center': true,
      'bg-plume': !!this.props.isInline,
      'm-create-account--inline-container': this.props.isInline && !this.props.isVertical,
      'm-create-account--inline-container-vertical': this.props.isInline && this.props.isVertical,
      'l-padding-v-20': !!this.props.isInline,
    });

    const tosStyles = {
      marginTop: '16px',
      fontSize: '11px',
      paddingLeft: '5%',
      textAlign: 'center',
    };

    const includeInlineText = function (text) {
      return (
        <div className="m-create-account--login-side-text columns small-12 large-4 l-margin-v-20 ">
          <h2 className="c-ashen">{ text }</h2>
        </div>
      );
    };

    const formColumnClassNames = classNames({
      'text-left m-create-account--sign-up': !isVertical,
      'columns small-12 l-margin-v-20 text-center': true,
      'large-5 large-left': !isVertical,
    });
    const buttonColumnClassNames = classNames({
      'columns small-12 l-margin-v-20 text-center': true,
      'large-3 large-left': !isVertical,
      'm-create-account--inline-google-login': !isVertical,
      'm-create-account--modal-google-login': isVertical,
    });
    const submitButtonClassNames = classNames({
      'm-create-account--inline-create-account-button': !isVertical,
      'm-create-account--modal-create-account-button': isVertical,
    });

    let redirectUrl;
    if (this.props.redirectUrl) {
      redirectUrl = (<input type="hidden" name="signup[redirect_url]" value={this.props.redirectUrl} />);
    }

    let isFromApiPricing;
    if (this.props.isFromApiPricing) {
      isFromApiPricing = (<input type="hidden" name="is_from_api_pricing" value={this.props.isFromApiPricing ? '1' : ''} />);
    }

    let planId;
    if (this.props.planId) {
      planId = (<input type="hidden" name="plan_id" value={this.props.planId} />);
    }

    let skipCC;
    if (this.props.skipCC) {
      skipCC = (<input type='hidden' name='skip_cc' value={this.props.skipCC} />);
    }

    let period;
    if (this.props.period) {
      period = (<input type='hidden' name='period' value={this.props.period} />);
    }

    let trialPeriod;
    if (this.props.trialPeriod) {
      trialPeriod = (<input type='hidden' name='trial_period' value={this.props.trialPeriod} />);
    }

    let errors;
    if (this.state.errors) {
      errors = (
        <div className="row">
          <div className="columns l-margin-b-10 small-10 small-offset-1 text-center m-modal--error">
            { this.renderErrorText() }
          </div>
        </div>
      );
    }

    let buttonText = this.props.buttonText ? this.props.buttonText : 'SIGN UP FREE';
    if (this.state.isProcessing) {
      buttonText = 'PROCESSING...';
    }

    const loginUrl = HfReactHelper.urlHelper('account/logIn');
    var loginTrigger,
      loginPrompt;
    if (this.props.loginForm) {
      var loginTrigger = (
        <LogInTrigger
          linkText="Login"
          buttonText="Sign In"
          header="Welcome back"
          googleSignInClientId={this.props.googleSignInClientId}
          loginUrl={loginUrl}
          redirectUrl={this.props.redirectUrl}
          recoverPasswordForm={this.props.recoverPasswordForm}
          loginForm={this.props.loginForm}
          hideRememberMe={this.props.hideRememberMe}
          hideGoogleOpenId={this.props.hideGoogleOpenId}
        >
        </LogInTrigger>
      );

      var loginPrompt = (
        <div className="columns small-12">
          <div className="m-create-account--login-prompt">Already have an account? { loginTrigger }</div>
        </div>
      );
    }

    const optInStyle = this.state.countryCode === 'US' ? {
      display: 'none',
    } : {};

    const signupForm = <div className={formColumnClassNames}>
      <div className="small-10 small-offset-1 text-center">
        { this.renderField('email_address', {
          id: 'sign_up_email_address_input',
          className: `m-create-account--text-input ${isVertical ? 'm-create-account--email-vertical' : ''}`,
          maxLength: '99',
          placeholder: 'Enter your email',
          onChange: this.onEmailChange,
          tabIndex: tabOffset,
          value: this.state.emailAddress,
          'data-format': 'email',
        }) }
      </div>
      <div className="small-10 small-offset-1 small-text-left">
        <div className="m-create-account--opt-in-container">
          <div
            className={`m-create-account--opt-in-align${isVertical ? '-vertical' : ''}`}
            style={optInStyle}
          >
            <label>
              { this.renderField('is_opted_in_marketing_emails', {
                className: 'm-create-account--text-input',
                tabIndex: tabOffset + 2,
                onChange: this.onOptInChange,
                checked: this.state.isOptedIn,
              }) }
                        &nbsp;Send me HelloSign updates
            </label>
          </div>
        </div>
      </div>
      {errors}
      { this.renderHiddenFields() }
      <input type="hidden" name="async" value={this.props.async ? '1' : '0'} />
      <input type="hidden" name="force_delay_analytics_js" value="true" />
      <div className={`l-margin-b-10 small-10 small-offset-1 small-text-center ${!isVertical ? 'large-text-left' : ''}`}>
        <Button type="submit"
          buttonClass={submitButtonClassNames}
          buttonText={buttonText}
          buttonColor="grasshopper"
          buttonHoverColor="castle-rock"
          buttonTabIndex={tabOffset + 3} />

        <p style={tosStyles}>
                    By signing up, I accept the HelloSign{' '}
          <a href={this.props.tosUrl} target="_blank" rel="noopener noreferrer">Terms</a> &{' '}
          <a href={this.props.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </p>
      </div>
    </div>;

    const googleLoginImage = <div className={!isVertical ? 'm-create-account--inline-google-login-border' : 'm-create-account--modal-google-login-border'}>
      <div className={!isVertical ? 'm-create-account--inline-google-login-or-image' : 'm-create-account--modal-google-login-or-image'} />
    </div>;

    let extraPadding = 'm-create-account__inline-google-login--extra-top-padding';
    if (this.props.dropboxSSO) {
      extraPadding = 'm-create-account__inline-google-login--minimal-top-padding';
    }

    const formType = 'signup';
    const signInButtons = this.props.googleSignInClientId
      ? (
        <div className={buttonColumnClassNames}>
          { this.props.fields ? googleLoginImage : null }
          <div className={`${!isVertical ? 'm-create-account--inline-google-login-button' : 'm-create-account--modal-google-login-button'} ${extraPadding}`}>
            <GoogleSignInButton
              clientId={this.props.googleSignInClientId}
              redirectUrl={this.props.redirectUrl}
              csrfToken={this.props.csrfToken}
              formType={formType}
              buttonAttrs={{
                'da-category': 'signup',
                'da-action': `create account inapp - ${this.props.isApi
                  ? 'hsapi'
                  : 'hs'}`,
                'da-label': 'sign in with google',
              }} />
            {this.props.dropboxSSO ? (
              <div className="l-margin-t-20">
                <DropboxSignInButton redirectUrl={this.props.redirectUrl} />
              </div>
            ) : null }
          </div>
        </div>
      ) : '';

    return (
      <React.Fragment>
        <div className="m-create-account">
          <div className={rowClassNames}>
            <div className="columns small-12">
              <h4>{ this.props.header }</h4>
            </div>
            { !isVertical ? includeInlineText(this.props.inlineSideText) : '' }
            <CountriesDropdown
              selectedCountryCode={this.state.countryCode}
              onCountryCodeChange={this.onCountryCodeChange} />
            { signInButtons }
            <form id="sign_up_form" className="m-create-account--form" onSubmit={this.onSubmit} action={this.props.action} method="POST">
              { this.props.fields ? signupForm : null }
              { isVertical && this.props.loginForm ? loginPrompt : '' }
              { redirectUrl }
              { isFromApiPricing }
              { planId }
              { skipCC }
              { period }
              { trialPeriod }
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  },
});

module.exports = CreateAccountForm;
