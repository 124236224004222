/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  max-len,
  no-bitwise
*/
// TODO - use 'export' instead
// export var NONE = 1;

exports.NONE = 1;
exports.NOTICE = exports.NONE << 1;
exports.INFO = exports.NOTICE << 1;
exports.WARN = exports.INFO << 1;
exports.ERROR = exports.WARN << 1;
exports.VERBOSE = exports.ERROR << 1;

exports.ALL = exports.NONE | exports.INFO | exports.NOTICE | exports.WARN | exports.ERROR | exports.VERBOSE;
exports.PRODUCTION = exports.NONE | exports.WARN | exports.ERROR;
exports.STAGING = exports.NOTICE | exports.INFO | exports.PRODUCTION;
exports.DEVELOPMENT = exports.ALL;

exports.fromString = function (level) {
  return level.toUpperCase().split('|').map((level) => {
    return exports[level] || exports.NONE;
  }).reduce((a, b) => {
    return a | b;
  });
};

export default module.exports;
