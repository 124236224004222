/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  import/no-extraneous-dependencies,
  no-redeclare,
  no-script-url,
  no-var,
  react/prop-types,
  vars-on-top
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import CreateAccountTrigger from '../../auth/create-account-trigger';

const SignUpButton = createReactClass({

  propTypes: {
    googleSignInClientId: PropTypes.string.isRequired,
    useWhite: PropTypes.bool,
    useBlack: PropTypes.bool,
    isCompact: PropTypes.bool,
    isMobile: PropTypes.bool,
    signupUrl: PropTypes.string,
    useRegularLink: PropTypes.bool,
    buttonText: PropTypes.string,
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    async: PropTypes.bool,
    isNavBarButton: PropTypes.bool,
  },

  getInitialState() {
    return {
      useWhite: false,
      isNavBarButton: false,
    };
  },

  render() {

    const classSet = classNames({
      'm-link-list--link': !this.props.isMobile,
      'm-link-list--signup': !this.props.isMobile,
      'm-link-list--link-break-2': !this.props.isMobile,
      'm-mobile-menu--signup-link': this.props.isMobile,
      'bg-cerulean': !this.props.useWhite,
      'bg-white': this.props.useWhite && !this.props.useBlack,
      'bg-ashen': this.props.useBlack,
      'c-white': !this.props.useWhite || this.props.useBlack,
      'c-cerulean': this.props.useWhite,
      'hc-cerulean': this.props.useWhite,
      'hc-white': !this.props.useWhite,
      'hbg-velvet-evening': !this.props.useWhite,
      'hbg-warm-chinchilla': !this.props.useBlack && this.props.useWhite,
      'hbg-ashen': this.props.useBlack,
      'nav-bar-button': this.props.isNavBarButton,
    });

    const triggerClasses = classNames({
      'm-link-list--link--container': true,
      'is-compact': this.props.isCompact,
    });
    const trigger = (
      <div className={triggerClasses}>
        <a
          className={classSet}
          href={this.props.useRegularLink
            ? this.props.signupUrl
            : 'javascript:;'
          }
          da-category="signup"
          da-action="create account start - hs"
          da-label="unknown plan"
          tabIndex='-1'
          data-qa-ref='sign-up'
        >
          { this.props.buttonText ? this.props.buttonText : 'Sign Up' }
        </a>
      </div>
    );

    if (this.props.useRegularLink) {
      var returnValue = trigger;
    } else {
      var returnValue = (
        <CreateAccountTrigger
          key='signup_trigger'
          trigger={trigger}
          header='Let&apos;s get started'
          buttonText='Create an account'
          googleSignInClientId={this.props.googleSignInClientId}
          signupUrl={this.props.signupUrl}
          signupForm={this.props.signupForm}
          loginForm={this.props.loginForm}
          recoverPasswordForm={this.props.recoverPasswordForm}
          async={this.props.async} />
      );
    }

    return returnValue;

  },
});

module.exports = SignUpButton;
