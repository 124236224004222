import * as React from 'react';
import { Box } from '../../blocks';
import { withStyle } from '../../style';
import style from './style';

/**
 * Badge Component
 *
 * A badge component is a component that shows statuses and all kinds of elements
 * which provide some information with just a box of color and some content.
 * It's used for example to show Approved / Not Approved flags
 */
export function BadgeComponent(props) {
  // Populating this vars from the props
  const { styles } = props;

  return (
    <Box
      className={styles.main}
    >
      {props.children}
    </Box>
  );
}

BadgeComponent.defaultProps = {
  type: 'success',
};

export default withStyle(style)(BadgeComponent);
