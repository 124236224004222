/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from 'ui/signature-modal/constants';
import createReactClass from 'create-react-class';
import SignatureModal from './signature-modal';

require('./edit-signature-modal.scss');

const EditSignatureModal = createReactClass({

  propTypes: {
    app: PropTypes.object.isRequired, // Application
    type: PropTypes.string, // Signature or initials (see SignatureModal.TYPE_*)
    isMobile: PropTypes.bool, // Whether it should have mobile behavior
    initialTab: PropTypes.string, // The tab shown at first (see SignatureModal.TAB_*)
    onComplete: PropTypes.func.isRequired, // Callback to interact with the opener function(signature, insertEverywhere, shouldClose)
    locales: PropTypes.array, // Selected locale (list of one)
    buttonText: PropTypes.string, // Text to be displayed on the CTA
    emailGuid: PropTypes.string, // Email guid for signature upload
  },


  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {

    // NOTE: It does not make sense for canInsertEverywhere to be true here
    // since this component is meant to be used outside the signer page.

    return {
      type: CONSTANTS.TYPE_SIGNATURE,
      isMobile: false,
      initialTab: CONSTANTS.TAB_SAVED,
      settings: {},
      canInsertEverywhere: false,
      locales: ['en-US'],
    };
  },


  //  ----  RENDERING  ----------------------------------

  render() {
    // Render the signature modal
    return (
      <div className='m-edit-signature-modal'>
        <SignatureModal {...this.props} />
      </div>
    );
  },

});

module.exports = EditSignatureModal;
