/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const ImageModal = createReactClass({

  propTypes: {
    imagePath: PropTypes.string,
  },

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      $(document).foundation();
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  componentWillReceiveProps() {
    if (process.env.NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  render() {
    return (
      <img src={this.props.imagePath} />
    );
  },
});

module.exports = ImageModal;
