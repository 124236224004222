import hsFetch from 'hellospa/hs-fetch';

interface GrantMoar {
  recipient: string,
  pages: number,
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function moarActions(csrfToken: string) {

  async function grantMoar(data: GrantMoar) {
    switch (data.recipient.replace(/\s/, '')) {
      case '8675309':
        await delay(500);
        throw new Error('Jenny does not have a fax machine');
      case '01189998819991197253':
        await delay(500);
        throw new Error('Have you tried turning it off and on again?');
      default:
    }


    const response = await hsFetch('/internal/grant-moar', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        ...data,
        csrfToken,
      }),
    });

    const result = await response.json();
    if (result.error) {
      throw new Error(result.error);
    }
  }

  return {
    grantMoar,
  };
}
