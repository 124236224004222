/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  react/no-unescaped-entities
*/
import React from 'react';
import { FormattedMessage } from 'react-intl';
import HfReactHelper from 'common/utils/hf-react-helper';
import Logo from 'common/components/logo';
import './split-signup-layout.scss';
import PromoDropboxImage from 'common/images/dropbox_promo@3x.png';
import DropboxGrowthImage from 'common/images/dropbox_growth@3x.png';

/**
 * A custom sign up page layout comp which displays the children in the right side.
 * On the left side it displays a header and a picture under it
 * There's no footer.
 */
export default class SplitSignUpLayout extends React.Component {

  getPromoHeading() {
    switch (this.props.signupSource) {
      case 'dropbox':
        if (HfReactHelper.HfSites.isHelloSign(this.props.siteCode)) {
          return <h2><FormattedMessage id='' defaultMessage='Your Dropbox file is ready. Sign up and get a free month of a Pro plan.' description='split sign up page' /></h2>;
        }
        return <h2><FormattedMessage id='' defaultMessage='Your Dropbox file is ready. Sign up and get 5 free fax pages total.' description='split sign up page' /></h2>;
      case 'dbx-promo':
        return <h2><FormattedMessage id='' defaultMessage='HelloSign is now a Dropbox company!' description='split sign up page' /></h2>;
      default:
        return <h2><FormattedMessage id='' defaultMessage='Simple. Secure. Send in Minutes.' description='split sign up page' /></h2>;
    }
  }

  getPromoSubHeading() {
    switch (this.props.signupSource) {
      case 'dropbox':
        if (HfReactHelper.HfSites.isHelloSign(this.props.siteCode)) {
          return <p><FormattedMessage id='' defaultMessage='Thanks for trying us on Dropbox!
          Sign up and send unlimited document requests,
          create a template and more with your free trial.' description='split sign up page' /></p>;
        }
        return <p><FormattedMessage id='' defaultMessage='Thanks for trying us on Dropbox!
        Sign up and send up to 5 fax pages to 70+ countries for free each month.
        Upgrade to get more pages and receive faxes.' description='split sign up page' /></p>;
      case 'dbx-promo':
        return <p><FormattedMessage id='' defaultMessage='Start your free trial to start sending legally binding eSignatures.' description='split sign up page' /></p>;
        /* Updated Dropbox SSO rollout copy:
          Get started with HelloSign using your Dropbox account, you're just a click away.;
        */
      default:
        return <p><FormattedMessage id='' defaultMessage='Start your free trial to start sending legally binding eSignatures.' description='split sign up page' /></p>;
        /* Updated Dropbox SSO rollout copy:
          Use your Dropbox account to start sending legally binding eSignatures.</p>;
        */
    }
  }

  getPromoImage() {
    switch (this.props.signupSource) {
      case 'dropbox': {
        const title = <FormattedMessage id='' defaultMessage='Sign up and get a free month of a Pro plan!' description='split sign up page' />;
        return <div className="m-sign-up--promo-image"><img src={PromoDropboxImage} title={title} alt={title} /></div>;
      }
      case 'dbx-promo': {
        const title = <FormattedMessage id='' defaultMessage='Start your free trial now' description='split sign up page' />;
        /* Updated Dropbox SSO rollout alt text:
          'Sign up using your Dropbox account';
        */
        return <div className="m-sign-up--promo-image"><img src={DropboxGrowthImage} title={title} alt={title} /></div>;
      }
      default: {
        const title = <FormattedMessage id='' defaultMessage='Start your free trial now' description='split sign up page' />;
        /* Updated Dropbox SSO rollout alt text:
          'Sign up using your Dropbox account';
        */
        return <div className="m-sign-up--promo-image"><img src={PromoDropboxImage} title={title} alt={title} /></div>;
      }
    }
  }

  render() {
    const externalHomepageUrl = HfReactHelper.urlHelper('', null, 'www');
    return (
      <div className="m-split-signup-layout">
        <div className="row l-padding-b-30 l-padding-t-40">
          <div className="small-12 columns">
            <Logo
              siteCode={this.props.siteCode}
              isLink
              href={externalHomepageUrl} />
          </div>
        </div>
        <div className="l-margin-v-20"></div>
        <div className="row">
          <div className="columns medium-6 m-sign-up--left">
            <div>
              {this.getPromoHeading()}
              {this.getPromoSubHeading()}
              {this.getPromoImage()}
            </div>
          </div>
          <div className="columns medium-6 small-12 m-sign-up--right">
            <div className='m-split-signup-layout--card'>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
