/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import SignupOrLoginButton from './signup-or-login-button';

function CTAButton(p) {
  const buttonClass = 'm-button bg-cerulean hc-white c-white progress-button state-loading m-login-or-signup--sign-up-button';
  const linkURL = '/home/myAccount#integrations';

  return (
    <SignupOrLoginButton
      {...p}
      buttonText={p.buttonText}
      homeUrl={linkURL}
      redirectUrl={linkURL}
      googleSignInClientId={p.googleSignInClientId}
      buttonClass={buttonClass}
      isIntegration={true}
      buttonTextColor='white'
      buttonColor='cerulean'
      buttonBorderColor='cerulean'
      buttonHoverColor='cerulean'
      buttonHoverTextColor='white' />
  );
}

module.exports = CTAButton;
