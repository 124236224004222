/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-named-as-default
*/
import makeComponentIntl from 'common/utils/make-component-intl';
import SignatureModal from 'ui/signature-modal';
import connectToLegacyApp from './legacy-app-connector';

module.exports = makeComponentIntl(connectToLegacyApp(SignatureModal));
