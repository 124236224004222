// @ts-ignore
import HfReactHelper from 'hellosign/components/hf-react-helper';

export default function hsFetch(url: string, options: RequestInit = {}) {
  // If this is running in an iframe on another site, the browser may choose to
  // not include the "3rd party" cookies. This attaches the session info so the
  // session isn't lost.
  if (window.top !== window) {
    // eslint-disable-next-line no-param-reassign
    url = HfReactHelper.attachSessionInfoToUrl(url);
  }

  /* eslint-disable no-restricted-globals */
  return fetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    ...options,
  });
}
