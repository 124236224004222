/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  global-require,
  import/no-extraneous-dependencies
*/
import React from 'react';

export default function LogoOnlyHeader() {
  return (
    <div className='row l-header l-padded l-padding-b-30 l-padding-t-40'>
      <div className='small-12 columns logo'>
        <div>
          <img src={require('common/images/logo@2x.png')} />
        </div>
      </div>
    </div>
  );
}
