/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-alert,
  no-console,
  no-plusplus,
  no-prototype-builtins,
  no-restricted-syntax,
  no-var,
  no-void,
  react/prop-types,
  vars-on-top
*/
import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from 'ui/signature-modal/constants';
import get from 'lodash/object/get';
import errorCodes from 'signer/api/errors';
import { FormattedMessage } from 'react-intl';
import Signature from 'hellosign/models/signature';
import createReactClass from 'create-react-class';

/**
 * Legacy application connector
 *
 * This is a wrapper component that handles the business logic for the legacy
 * application.  It is, for the most part, a direct rip of the logic in the
 * previous signature modal.  We prepare and handle all state in this component
 * and pass down any props needed to the "detached" signature modal.
 */
export function connectToLegacyApp(SignatureModal) {
  return createReactClass({
    displayName: 'connectToLegacyApp(SignatureModal)',

    propTypes: {

      /**
             * Application
             */
      app: PropTypes.object.isRequired,

      /**
             * Text to be displayed on the CTA (only when canInsertEverywhere=false)
             */
      buttonText: PropTypes.string,

      /**
             * Whether to show the option to insert everywhere
             */
      canInsertEverywhere: PropTypes.bool,

      /**
             * Email guid for signature upload,
             */
      emailGuid: PropTypes.string,

      /**
             * Email address to send signatures to
             */
      emailUploadAddress: PropTypes.string,

      /**
             * Whether it should have mobile behavior
             */
      isMobile: PropTypes.bool,

      /**
             * The tab shown at first (see SignatureModal.TAB_*)
             */
      initialTab: PropTypes.string,

      /**
             * True if the modal is within the embedded context
             */
      isEmbedded: PropTypes.bool,

      /**
             * DO NOT USE
             *
             * This is here as a workaround for the smart forms demo.  It should not be
             * used for other apps.
             */
      onBeforeInsert: PropTypes.func,

      /**
             * Called when the signature modal is closed by the user
             */
      onClose: PropTypes.func,

      /**
             * Called when the user has selected a signature for action(signature, insertEverywhere)
             */
      onComplete: PropTypes.func,

      /**
             * Define which tabs should be shown { tabId => true/false }
             */
      settings: PropTypes.object,

      /**
             * Signature or initials (see SignatureModal.TYPE_*)
             */
      type: PropTypes.string,
    },

    getDefaultProps() {
      return {
        initialTab: CONSTANTS.TAB_SAVED,
      };
    },

    getInitialState() {
      return Object.assign({
        isCreatingDrawnSignature: false,
        isCreatingTypedSignature: false,
        isUploadingSignature: false,
        isCheckingEmailedSignatureStatus: false,
        selectedSavedSignature: null,
        uploadedSignature: null,
        emailedSignature: null,
      }, this.getStates());
    },

    componentDidMount() {
      this.mounted = true;

      const isLoading = this.isLoading();
      if (isLoading) { // Load the user signatures (will only fetch them if not already cached)
        this.props.app.user.getSignatures(this.props.type).load().then(() => {

          if (this.mounted) {
            this.setState(this.getStates());
          }
        });
      }
    },

    componentWillUnmount() {
      this.mounted = false;
    },

    componentWillReceiveProps(newProps) {
      this.setState(this.getStates(newProps));
    },

    createSignature(signatureData) {
      return this.props.app.user.getSignatures(this.props.type).add(signatureData);
    },

    editSignature(signature, signatureData) {
      // Set parameters from edit pane
      signature.setEditParams(signatureData);
      // Then get list of signatures, and use the edit endpoint to add
      return this.props.app.user.getSignatures(this.props.type).addFromEdit(signature);
    },

    getEmailGuid() {
      let guid = this.props.emailGuid;

      if (!guid) {
        guid = this.props.type === CONSTANTS.TYPE_SIGNATURE
          ? get(this.props.app, ['emailGuids', 'signature_email_guid'])
          : get(this.props.app, ['emailGuids', 'initials_email_guid']);
      }

      return guid;
    },

    getEmailUploadAddress() {
      return this.props.emailUploadAddress || this.props.app.emailUploadAddress;
    },

    getEnabledTabs() {
      const { settings } = this.props;

      return CONSTANTS.TABS.filter((tabId) => settings[tabId] !== false);
    },

    getInitialTab(props, signatures) {

      let tabId = props.initialTab;
      let hasVisibleTab = false;
      let i;

      for (i in CONSTANTS.TABS) {
        if (props.settings[CONSTANTS.TABS[i]] !== false) {
          hasVisibleTab = true;
          break;
        }
      }

      if (!hasVisibleTab) {
        // No visible tab due to restrictions from settings
        tabId = CONSTANTS.TAB_DRAW;
      } else if (tabId === CONSTANTS.TAB_SAVED && signatures.length === 0) {
        // Default to the next visible tab if there aren't any saved signatures
        for (i in CONSTANTS.TABS) {
          if (CONSTANTS.TABS[i] !== CONSTANTS.TAB_SAVED && props.settings[CONSTANTS.TABS[i]] !== false) {
            tabId = CONSTANTS.TABS[i];
            break;
          }
        }
      } else if (props.settings[tabId] === false) {
        // Select the first visible tab if the specified can't be displayed
        for (i in CONSTANTS.TABS) {
          if (CONSTANTS.TABS[i] !== tabId && props.settings[CONSTANTS.TABS[i]] !== false) {
            tabId = CONSTANTS.TABS[i];
            break;
          }
        }
      }

      return tabId;
    },

    getInitialTypeInValue() {
      const { app, type } = this.props;
      const user = app.user;
      const fullName = user.getFullName();
      const value = (type === CONSTANTS.TYPE_INITIALS ? user.getInitials() : fullName) || '';

      return value;
    },

    getLoaderContent() {
      const { isCreatingDrawnSignature, isCreatingTypedSignature } = this.state;

      if (isCreatingDrawnSignature || isCreatingTypedSignature) {
        return <FormattedMessage
          id='signModal.loader.text'
          defaultMessage="Saving your { type, select, S {signature} I {initials} }"
          values={{ type: this.props.type }} />;
      }
    },

    getStates(newProps) {
      const props = newProps || this.props;
      const user = props.app.user;
      const signatures = user.getSignatures(props.type);
      const isLoading = (!signatures.data || signatures.loading === true);

      const hasSignatures = signatures && signatures.length > 0;
      let selectedSignatureIndex = 0;
      if (window.primarySignatureGuid && hasSignatures) {
        for (let i = 0; i < signatures.length; i++) {
          if (signatures[i].guid === window.primarySignatureGuid) {
            selectedSignatureIndex = i;
            break;
          }
        }
      }

      const selectedSavedSignature = hasSignatures
        ? signatures[selectedSignatureIndex]
        : null;

      return {
        isLoadingSignatures: isLoading,
        selectedTab: isLoading ? null : this.getInitialTab(props, signatures),
        savedSignatures: isLoading ? [] : signatures,
        selectedSavedSignature,
      };
    },

    handleCheckEmailedSignatureStatus() {
      const { type, app } = this.props;
      const emailGuid = this.getEmailGuid();

      this.setState({ isCheckingEmailedSignatureStatus: true });

      const sig = Signature.create({
        notifier: app.notifier,
        emailGuid,
        type,
        createType: CONSTANTS.SIGNATURE_TYPE_EMAIL,
      });

      var callback = function (err, data) {

        if (err) {
          this.handleError(err);
        }

        if (data.status) {

          if (data.status == 'pending') {

            // Repeat the cycle as retries permit
            this.retries++;
            if (this.retries < this.retryLimit) {
              setTimeout(function () {
                sig.checkEmailSignatureStatus().then(callback.bind(this, void 0)).catch(callback);
              }, this.retryPeriod);
            } else {
              this.setState({ isCheckingEmailedSignatureStatus: false });
              this.handleError(new Error('Retries timed out'));
            }

          }

          if (data.status == 'ok') {

            // End and assign id
            sig.guid = data.signature_guid;
            sig.width = data.width;
            sig.height = data.height;

            this.setState({
              emailedSignature: sig,
              isCheckingEmailedSignatureStatus: false,
            });

            // Refresh Guids
            this.refreshGuids(data);
          }

          if (data.status == 'error') {
            this.refreshGuids(data);
            this.handleError(new Error('Server Error'));

            this.setState({ isCheckingEmailedSignatureStatus: false });
          }
        }

      }.bind(this);

      sig.checkEmailSignatureStatus().then((...args) => callback(undefined, ...args));
    },

    /**
         * This is not the ideal way to handle this.  In the type-in tab, we're
         * first trying to save the signature request to see if we're online or
         * not.  We do this because if this request fails, we'll show a nice
         * error message to the user.  But, it's only handled for mobile and
         * only when there's a signature request present.  It's very specialized
         * for this one scenario.  Leaving it in for now for legacy reasons.
         */
    handleCheckNetworkHealth(cb) {
      if (this.props.isMobile && this.props.app.signatureRequest) {
        // Make sure we have connectivity before loading fonts on mobile
        // NOTE: We need to trigger a save beforehand because loading font does not
        // go through the API service and will not bring up the connectivity screen
        // when network is down.
        this.props.app.signatureRequest.save(cb);
      } else {
        cb();
      }
    },

    handleDrawnSignatureInsert(signatureData, insertEverywhere) {
      this.setState({ isCreatingDrawnSignature: true });

      this.createSignature(signatureData).then((signature) => {
        this.setState({ isCreatingDrawnSignature: false });
        this.props.onComplete(signature, insertEverywhere);
      })
        .catch((error) => {
          this.setState({ isCreatingDrawnSignature: false });
          let errorMessage;

          // Lodash `get` is a safe way to dig deeply into the error
          // object.  If any of these properties are not defined there
          // will be no error code and we'll just display a general
          // message.
          const originalCode = get(error, ['error', 'data', 'original_code']);

          if (originalCode) {
            errorMessage = this.props.intl.formatMessage({ id: `signModal.drawn.error.${originalCode}` }, { type: this.props.type });
          } else {
            errorMessage = this.props.intl.formatMessage({ id: 'error.general.message' });
          }

          this.handleError({ message: errorMessage });
        });
    },

    handleEmailedSignatureInsert(signatureData, insertEverywhere) {
      const { emailedSignature } = this.state;
      const finalSignatureData = {
        ...emailedSignature.data,
        guid: emailedSignature.guid,
        ...signatureData,
      };

      this.setState({ isCreatingEmailedSignature: true });

      this.editSignature(emailedSignature, finalSignatureData).then((signature) => {
        this.setState({ isCreatingEmailedSignature: false });
        this.props.onComplete(signature, insertEverywhere);
      }).catch((e) => this.handleError(e));
    },

    handleError(error) {
      this.setState({ error });

      if (error.code === errorCodes.NETWORK || error.code === errorCodes.CREDENTIALS) {
        // No need to trigger error reporting in this case since a special error screen
        // will be shown instead.
        return;
      }

      // Right now, tabbed error handling is only turned on for Desktop clients
      if (!this.props.isMobile) {
        this.selectTab(CONSTANTS.TAB_ERROR);
      } else {
        console.error(`Signature Modal Error: ${error ? error.message : ''}`);
      }
    },

    handleSavedSignatureRemove(signature) {

      if (!window.confirm(this.props.intl.formatMessage({ id: `signModal.saved.delete.confirmMessage.${this.props.type}` }))) {
        return;
      }
      const signatures = this.state.savedSignatures;
      let selectedSignature = this.state.selectedSavedSignature;

      signature.remove((error) => {
        if (error) this.props.onError(error);
        else {

          // If the signature we're removing is the current selected sig
          if (signature.guid === selectedSignature.guid) {

            // If there are signatures other than the one that just got
            // deleted
            if (signatures.length > 1) {

              // Find the signature in the state that matches the
              // selected signature
              let selectedIndex = 0;
              for (let i = 0; i < signatures.length; i++) {
                if (signatures[i].guid === selectedSignature.guid) {
                  selectedIndex = i;
                  break;
                }
              }

              // Make the selected signature the signature after the
              // removed signature
              selectedSignature = signatures[selectedIndex + 1];
              if (selectedSignature === undefined) {
                selectedSignature = signatures[selectedIndex - 1];
              }

              // TODO - remove this - this is breaking encapsulation majorly. This sort
              // of logic should be within the signatures collection (CC)
              signatures.splice(selectedIndex, 1);
            } else {

              // The last signature just got deleted so pop the last one out
              signatures.splice(0, 1);
              selectedSignature = null;
              window.primarySignatureGuid = void 0;
            }
          }

          const sigReq = this.props.app.signatureRequest;
          let selectedSig;
          if (sigReq) selectedSig = sigReq.getSelectedSignature(this.props.type);
          if (selectedSig && selectedSig.guid === signature.guid) {
            // Reset the selected signature if it'a been deleted
            sigReq.setSelectedSignature(this.props.type, null);
          }

          this.setState({
            savedSignatures: signatures,
            selectedSavedSignature: selectedSignature,
          });
        }
      });
    },

    handleSignatureRotate(signature) {
      signature.rotate();
    },

    handleSavedSignatureSelect(signature) {
      this.setState({
        selectedSavedSignature: signature,
      });
    },

    handleSignatureData(signatureData, insertEverywhere) {
      const { create_type_code: type } = signatureData;

      // Keeps support for the smart-forms demo
      if (typeof this.props.onBeforeInsert === 'function') {
        return this.props.onBeforeInsert(signatureData, insertEverywhere);
      }

      if (type === CONSTANTS.SIGNATURE_TYPE_CANVAS) {
        return this.handleDrawnSignatureInsert(signatureData, insertEverywhere);
      } else if (type === CONSTANTS.SIGNATURE_TYPE_TYPED) {
        return this.handleTypedSignatureInsert(signatureData, insertEverywhere);
      } else if (type === CONSTANTS.SIGNATURE_TYPE_UPLOAD) {
        return this.handleUploadedSignatureInsert(signatureData, insertEverywhere);
      } else if (type === CONSTANTS.SIGNATURE_TYPE_EMAIL) {
        return this.handleEmailedSignatureInsert(signatureData, insertEverywhere);
      }
    },

    async handleSignatureUpload(data) {
      this.setState({ isUploadingSignature: true });

      const sig = Signature.create({
        notifier: this.props.app.notifier,
        file: data,
      });

      sig.createType = CONSTANTS.SIGNATURE_TYPE_UPLOAD;
      sig.type = this.props.type;

      try {
        await sig.save({ handleError: false });

        this.setState({
          uploadedSignature: sig,
        });

      } catch (e) {
        this.handleError(e);
      }

      this.setState({ isUploadingSignature: false });
    },

    handleTypedSignatureInsert(signatureData, insertEverywhere) {
      this.setState({ isCreatingTypedSignature: true });

      this.createSignature(signatureData).then((signature) => {
        this.setState({ isCreatingTypedSignature: false });
        this.props.onComplete(signature, insertEverywhere);
      }).catch((error) => {
        this.setState({ isCreatingTypedSignature: false });

        // The error here may not be parseable, so if not just
        // display the general message
        const err = error.response
          ? JSON.parse(error.response.text)
          : error;
        const msg = err.response
          ? this.props.intl.formatMessage({ id: `signModal.typed.error.${err.name}` })
          : this.props.intl.formatMessage({ id: 'error.general.message' });
        this.handleError({ message: msg });
      });
    },

    handleUploadedSignatureInsert(signatureData, insertEverywhere) {
      const { uploadedSignature } = this.state;
      const finalSignatureData = {
        ...uploadedSignature.data,
        guid: uploadedSignature.guid,
        ...signatureData,
      };

      this.setState({ isCreatingUploadedSignature: true });

      this.editSignature(uploadedSignature, finalSignatureData).then((signature) => {
        this.setState({ isCreatingUploadedSignature: false });
        this.props.onComplete(signature, insertEverywhere);
      }).catch((e) => this.handleError(e));
    },

    isLoading() {
      const {
        isCreatingDrawnSignature,
        isCreatingTypedSignature,
        isLoadingSignatures,
      } = this.state;

      return isLoadingSignatures
                || isCreatingDrawnSignature
                || isCreatingTypedSignature;
    },

    refreshGuids(data) {
      if (data.hasOwnProperty('new_email_guid')) {

        const newGuid = data.new_email_guid;

        if (typeof this.props.app.emailGuids === 'undefined') {
          this.props.app.emailGuids = [];
        }
        if (this.props.type === CONSTANTS.TYPE_SIGNATURE) {
          this.props.app.emailGuids.signature_email_guid = newGuid;
        } else {
          this.props.app.emailGuids.initials_email_guid = newGuid;
        }
      }
    },

    retries: 0,
    retryLimit: CONSTANTS.EMAIL_STATUS_MAX_RETRIES,
    retryPeriod: CONSTANTS.EMAIL_STATUS_RETRY_PERIOD,

    selectSavedSignature(signature) {
      this.setState({
        selectedSignature: signature,
      });
    },

    selectTab(tabId) {
      this.setState({
        selectedTab: tabId,
      });
    },

    render() {
      const {
        error,
        savedSignatures,
        selectedSavedSignature,
        selectedTab,
        uploadedSignature,
        isUploadingSignature,
        isCheckingEmailedSignatureStatus,
        emailedSignature,
      } = this.state;
      const {
        app,
        buttonText,
        canInsertEverywhere,
        intl,
        isEmbedded,
        isMobile,
        onClose,
        onComplete,
        settings,
        type,
      } = this.props;

      const loading = this.isLoading();
      const loaderContent = this.getLoaderContent();
      const emailGuid = this.getEmailGuid();
      const emailUploadAddress = this.getEmailUploadAddress();
      const enabledTabs = this.getEnabledTabs();
      const filteredSavedSignatures = savedSignatures.filter((sig) => {
        return settings[sig.createType] !== false;
      });

      return <SignatureModal
        buttonText={buttonText}
        canInsertEverywhere={canInsertEverywhere}
        emailedSignature={emailedSignature}
        emailGuid={emailGuid}
        emailUploadAddress={emailUploadAddress}
        enabledTabs={enabledTabs}
        error={error}
        getSignatureUrl={(signature, ...args) => signature.getUrl(...args)}
        initialTypeInValue={this.getInitialTypeInValue()}
        isCheckingEmailedSignatureStatus={isCheckingEmailedSignatureStatus}
        isEmbedded={isEmbedded}
        isMobile={isMobile || app.isMobile}
        intl={intl}
        isUploadingSignature={isUploadingSignature}
        loaderContent={loaderContent}
        loading={loading}
        onCheckEmailedSignatureStatus={this.handleCheckEmailedSignatureStatus}
        onClose={onClose}
        onError={this.handleError}
        onInsert={onComplete}
        onCheckNetworkHealth={this.handleCheckNetworkHealth}
        onRotateSignature={this.handleSignatureRotate}
        onSignatureData={this.handleSignatureData}
        onSavedSignatureRemove={this.handleSavedSignatureRemove}
        onSavedSignatureSelect={this.handleSavedSignatureSelect}
        onTabSelect={this.selectTab}
        onUploadSignature={this.handleSignatureUpload}
        savedSignatures={filteredSavedSignatures}
        settings={settings}
        selectedSavedSignature={selectedSavedSignature}
        selectedTab={selectedTab}
        type={type}
        uploadedSignature={uploadedSignature} />;
    },
  });
}

export default connectToLegacyApp;
