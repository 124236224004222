/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-useless-escape
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

//  ----------  CODE EXAMPLES  ----------------------------

const CodeTabPane = createReactClass({

  propTypes: {
    languageName: PropTypes.string.isRequired,
    codeStringLines: PropTypes.array.isRequired,
    codeClassNames: PropTypes.string,
  },

  getDefaultProps() {
    return {
      codeClassNames: '',
    };
  },

  render() {

    const classes = classNames('m-code-example', this.props.languageName.replace(/\#|\./g, ''));
    const preClasses = classNames('m-code-example--code', 'prettyprint', this.props.codeClassNames);

    return <div className={classes}>
      <pre className={preClasses}>{ this.props.codeStringLines.join('\n').replace(/\&quot\;/g, '"') }</pre>
    </div>;
  },
});

module.exports = CodeTabPane;
