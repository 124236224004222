/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import SignupOrLoginButton from './signup-or-login-button';

module.exports = createReactClass({
  displayName: 'SEO Title Section',

  propTypes: {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
  },

  render() {
    const props = this.props;
    const signupOrLoginButtonProps = {
      buttonText: props.signupButtonText,
    };
    return <div>
      <div className='m-marketing-page--title-section row text-center l-padding-t-60 l-padding-b-10'>
        <div className='columns small-12'>
          <h1 className='m-marketing-page--title'>{props.title}</h1>
          <h4 className='m-marketing-page--subtitle downsize'>{props.subtitle}</h4>
        </div>
      </div>
      <SignupOrLoginButton {...signupOrLoginButtonProps} {...props} />
    </div>;
  },
});
