import React from 'react';
import TopBarMenuLink from './top-bar-menu-link';
import Constants from './top-bar-data';
import DropDownMenuItem from './drop-down-item';

function MobileNavBar(props) {
  const mobileMenuData = Constants.topBarData;
  const subMenuItem = mobileMenuData[0].options.subMenuItems;
  const mobileMenuResourceData = subMenuItem[subMenuItem.length - 1];

  const topBarMenuLinks = mobileMenuData.map((topBarMenuLink) => {
    if (topBarMenuLink.key === 'solutions') {
      const dropDownSolution = topBarMenuLink.options.subMenuItems
        .slice(0, topBarMenuLink.options.subMenuItems.length - 1)
        .map((item) => {
          return (
            <TopBarMenuLink
              key={item[0].key}
              daAction={topBarMenuLink.daAction}
              daCategory={topBarMenuLink.daCategory}
              linkClassName="fn-dropdown-grid-item"
              useWhite={props.useWhite}
              linkText={item[0].text}
              linkUrl={topBarMenuLink.url}
              dropDownMenuLinkData={[item.slice(1, item.length)]}
              isMobile={true} />
          );
        });

      return (
        <TopBarMenuLink
          key={topBarMenuLink.text}
          daAction={topBarMenuLink.daAction}
          daCategory={topBarMenuLink.daCategory}
          linkClassName="nav-bar-dropdown-header"
          useWhite={props.useWhite}
          linkText={topBarMenuLink.text}
          linkUrl={topBarMenuLink.url}
          dropDownMenuLinkData={[]}
          isMobile={true}
        >
          {dropDownSolution}
        </TopBarMenuLink>
      );
    }

    // getting first element of each array
    const productMobileItems = (
      topBarMenuLink.options.subMenuItems
        .slice(0, topBarMenuLink.options.subMenuItems.length - 1)
        .map((productItem) => productItem[0])
    );

    return (
      <TopBarMenuLink
        key={topBarMenuLink.text}
        daAction={topBarMenuLink.daAction}
        daCategory={topBarMenuLink.daCategory}
        useWhite={props.useWhite}
        linkClassName="nav-bar-dropdown-header"
        linkText={topBarMenuLink.text}
        linkUrl={topBarMenuLink.url}
        dropDownMenuLinkData={[productMobileItems]}
        isMobile={true} />
    );
  });

  // add resource in mobile menu
  const resourceReturnItem = mobileMenuResourceData.slice(1, mobileMenuResourceData.length)
    .map((dropDownMenuLink, i) => {
      if (dropDownMenuLink.url === '' && dropDownMenuLink.text === '') {
        return (
          <div key={i} className="fn-dropdown-col-item">
            <TopBarMenuLink
              linkText="Login"
              dropDownMenuLinkData={props.loginDropDownMenuLinkData}
              daCategory='login'
              useWhite={props.useWhite}
              isMobile={true} />
          </div>
        );
      }

      return (
        <div key={i} className="fn-dropdown-col-item">
          <DropDownMenuItem daAction='solutions' daCategory='menu' value={dropDownMenuLink} index={i} />
        </div>
      );
    });

  return (
    <div className="nav-bar-mobile" >
      {topBarMenuLinks}
      <div className="nav-bar-mobile-resource">
        {resourceReturnItem}
      </div>
    </div>
  );
}

export default MobileNavBar;
