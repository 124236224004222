/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/extensions,
  max-len
*/
import React, { Component } from 'react';
import classnames from 'classnames';
import AdvancedForm from './advanced-form.jsx';
import caret from './dropdown-caret-12.svg';
import './index.scss';

export default class AdvancedSearch extends Component {
    state = {
      pendingSearch: false,
      query: '',
      open: false,
    };

    handleQuery = (event) => this.setState({
      query: event.target.value,
    });

    handleToggle = () => this.setState({
      open: !this.state.open,
    });

    handleAdvancedSearch = (parameters) => {
      const parts = [parameters.contains];

      if (parameters.filter) {
        parts.push(`filter:${parameters.filter}`);
      }

      if (parameters.signer) {
        parts.push(`to:"${parameters.signer}"`);
      }
      if (parameters.sender) {
        parts.push(`from:"${parameters.sender}"`);
      }
      if (parameters.created) {
        parts.push(`created:${parameters.created}`);
      }
      if (parameters.owner) {
        parts.push(`owner:"${parameters.owner}"`);
      }
      if (parameters.testMode) {
        parts.push('test_mode:true');
      }


      this.setState({
        pendingSearch: true,
        open: false,
        query: parts.join(' ').trim(),
      });

    };

    componentDidUpdate() {
      // The search needs to happen AFTER the DOM updated
      if (this.state.pendingSearch) {
        this.setState({ pendingSearch: false });

        if (typeof this.props.onSearch === 'function') {
          this.props.onSearch();
        }
      }
    }

    render() {
      const {
        onSearch, siteCode, includeAllTeam, ...inputProps
      } = this.props;

      return (
        <div className="advanced-search">
          <input
            {...inputProps}
            className={classnames('query', this.props.className)}
            type="text"
            value={this.state.query}
            onChange={this.handleQuery} />
          <a className="advanced-search__toggle" onClick={this.handleToggle}>
            <img src={caret} alt="Advanced Search" />
          </a>
          {this.state.open
            ? <AdvancedForm onSearch={this.handleAdvancedSearch} siteCode={siteCode} includeAllTeam={includeAllTeam} />
            : null
                }
        </div>
      );

    }
}
