/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
import React from 'react';
import HeaderText from './header-text';
import HeaderButton from './header-button';

const outlineStyle = { outline: '8px solid #3b99fc' };
const isComponentOutlined = (props, component) => props.selectedComponent === component;

module.exports = class DocumentHeader extends React.Component {

  constructor(props) {

    super(props);
    this.intervals = [];
    this.name = 'Header';

    this.state = {
      selectedComponent: null,
      isHeaderOutlined: false,
      isBlinking: true,
      colors: {
        Header: '#000000',
        HeaderText: '#FFFFFF',
        Button: '#00B3E6',
        ButtonText: '#FFFFFF',
      },
    };
  }

  clearIntervals() {
    this.intervals.forEach(clearInterval);
    this.intervals = [];
  }

  componentDidMount() {
    const blink = () => this.setState({ isHeaderOutlined: !this.state.isHeaderOutlined });
    this.intervals.push(setInterval(blink, 500));
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  handleClick(event, component) {
    event.stopPropagation();
    const newState = Object.assign({}, this.state, { selectedComponent: component });
    if (this.state.isBlinking) {
      this.clearIntervals();
      newState.isHeaderOutlined = false;
      newState.isBlinking = false;
      this.props.changeBlinkingComponent('DocumentHeader');
    }
    this.props.setColor(this.state.colors[component]);
    this.setState(newState);
  }

  getColors() {
    const colors = this.state.colors;
    colors[this.state.selectedComponent] = this.props.selectedColor;
    return colors;
  }

  getStyle(colors) {
    const state = this.state;
    let headerStyle = {
      backgroundColor: colors[this.name],
    };
    if (state.isHeaderOutlined || isComponentOutlined(state, this.name)) {
      headerStyle = Object.assign(headerStyle, outlineStyle);
    }
    return headerStyle;
  }

  render() {

    const colors = this.getColors();
    const headerStyle = this.getStyle(colors);
    const props = {
      handleClick: this.handleClick.bind(this),
      colors,
      isComponentOutlined,
      outlineStyle,
      ...this.state,
    };

    return <div className='m-document-header' onClick={(event) => this.handleClick(event, this.name)} style={headerStyle} >
      <div className='m-document-header-left'>
        <div>
          <img src={require('./assets/your_logo.png')} aria-hidden={true} />
        </div>
        <HeaderText {...props} />
      </div>
      <div className='m-document-header-right'>
        <HeaderButton {...props} />
      </div>
    </div>;
  }
};
