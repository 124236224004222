/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import setTimeout from 'common/utils/set-timeout';
import createReactClass from 'create-react-class';

const TwoOptionTabPane = createReactClass({
  propTypes: {
    image: PropTypes.string,
    frozenImage: PropTypes.string,
    title: PropTypes.string.isRequired,
    heading_1: PropTypes.string.isRequired,
    description_1: PropTypes.string.isRequired,
    heading_2: PropTypes.string.isRequired,
    description_2: PropTypes.string.isRequired,
  },
  getInitialState() {
    return {
      animating: true,
    };
  },
  componentDidMount() {

    // TODO: refactor this bit - defer animation handling to whatever
    // is actually animating this component vs making this assumption (CC)
    setTimeout(() => {
      this.setState({ animating: false });
    }, 15000);
  },

  render() {
    const button = (
      <Button
        buttonText='Learn more'
        buttonLink={this.props.link || 'http://info.hellosign.com/api-demo.html'}
        openInNewWindow={true} />
    );

    return (
      <div>
        <div className='show-for-large-up large-6 columns'>
          <img src={this.state.animating ? this.props.image : this.props.frozenImage} className='embedded-image' />
        </div>
        <div className='small-12 large-6 small-text-center large-text-left columns'>
          <h3>{this.props.title}</h3>
          <div className='row l-padding-v-20'>
            <div className='small-10 large-12 small-centered large-uncentered columns'>
              <div className='small-5 columns l-no-gutter'>
                <p className='b'>{this.props.heading_1}</p>
                <p>{this.props.description_1}</p>
              </div>
              <div className='small-2 columns'>
                <img src={require('./or.png')} className='or-image' />
              </div>
              <div className='small-5 columns l-no-gutter'>
                <p className='b'>{this.props.heading_2}</p>
                <p>{this.props.description_2}</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              { button }
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = TwoOptionTabPane;
