// @flow
import {
  alignItems,
  borderColor,
  borderWidth,
  borderRadius,
  color,
  flex,
  flexDirection,
  flexWrap,
  fontSize,
  hover,
  justifyContent,
  space,
  responsiveStyle,
  util,
  width,
} from 'styled-system';

/**
 * Style Transforms
 *
 * These are the different style transformations that are in use.  Some are from
 * the `styled-system` library directly and some are custom transformations.
 */
const display = responsiveStyle({
  prop: 'display',
  cssProperty: 'display',
});

const fontWeight = responsiveStyle({
  prop: 'fontWeight',
  cssProperty: 'fontWeight',
});

const order = responsiveStyle({
  prop: 'order',
  cssProperty: 'order',
});

const position = responsiveStyle({
  prop: 'position',
  cssProperty: 'position',
});

const textAlign = responsiveStyle({
  prop: 'textAlign',
  cssProperty: 'textAlign',
});

const textTransform = responsiveStyle({
  prop: 'textTransform',
  cssProperty: 'textTransform',
});

const verticalAlign = responsiveStyle({
  prop: 'verticalAlign',
  cssProperty: 'verticalAlign',
});

const transforms = [
  alignItems,
  borderColor,
  borderRadius,
  borderWidth,
  color,
  display,
  flex,
  flexDirection,
  flexWrap,
  fontSize,
  fontWeight,
  hover,
  justifyContent,
  order,
  position,
  space,
  textAlign,
  textTransform,
  verticalAlign,
  width,
];

/**
 * Rules
 */

/**
 * The main style rule for rendering the box style.  The transforms are defined
 * above and each transform operates on a different style realm.
 */
function boxRule(props: {}) {
  return (
    transforms

    // Apply the props to each transform
      .map((transform) => transform(props))

    // Some transforms may return `null`, so filter these out first
      .filter(Boolean)

    // Merge everything into one big object.  We need a deep merge here
    // because each transform could return an object with the same key when
    // dealing with responsive styles, e.g.
    //
    //  {
    //      '@media screen and (min-width: 40em) {
    //          backgroundColor: 'red'
    //      }
    //  }
    //
    //  and
    //
    //  {
    //      '@media screen and (min-width: 40em) {
    //          display: 'block'
    //      }
    //  }
    //
      .reduce(util.merge)
  );
}

export default {
  box: boxRule,
};
