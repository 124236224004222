/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-undef,
  no-useless-constructor
*/
import React from 'react';
import PropTypes from 'prop-types';
import FixedModal from 'common/components/fixed-modal';
import { Box, Text, Flex } from 'hello-ui/blocks';
import Button from 'common/components/button';
import CloseImageSrc from 'common/assets/modal-close@2x.png';
import DownloadImageSrc from 'common/assets/download-24.svg';
import PrintImageSrc from 'common/assets/print-24.svg';
import StyleProvider from 'hello-ui/style';
import './index.scss';

export class DisclosureModal extends React.Component {
    static defaultProps = {
      rounded: true,
      showPrintButton: false,
      showDownloadButton: false,
      onPrint: () => { window.print(); },
    };

    static propTypes = {
      header: PropTypes.string,
      content: PropTypes.string,
      onSubmit: PropTypes.func,
      submitButton: PropTypes.string,
      onClose: PropTypes.func,
      rounded: PropTypes.bool,
      showPrintButton: PropTypes.bool,
      showDownloadButton: PropTypes.bool,
      onPrint: PropTypes.func,
      onDownload: PropTypes.func,
    };

    constructor(props) {
      super(props);

    }

    render() {
      const {
        header,
        content,
        rounded,
        onSubmit,
        submitButton,
        onClose,
        showPrintButton,
        showDownloadButton,
        onDownload,
        onPrint,
      } = this.props;

      const modalStyle = { borderRadius: rounded ? 5 : 0 };
      const downloadButton = (
        <Box is="a" onClick={onDownload}>
          <Box is="img" src={DownloadImageSrc} />
        </Box>
      );
      const printButton = (
        <Box is="a" onClick={onPrint}>
          <Box is="img" src={PrintImageSrc} />
        </Box>
      );


      return (
        <StyleProvider>
          <FixedModal width={600} modalStyle={modalStyle}>
            <div
              className="disclosure-modal-close-button"
              onClick={onClose}
            >
              <img src={CloseImageSrc} />
            </div>
            <Box p={3} className="disclosure-modal-header">
              <Text
                fontSize={3}
                fontWeight="bolder"
                textAlign="center"
              >
                { _.unescape(header) }
              </Text>
            </Box>

            <Box py={2} px={4} className="disclosure-modal-content">
              <Box
                dangerouslySetInnerHTML={{ __html: content }}
              >
              </Box>
            </Box>

            <Box p={3} className="disclosure-modal-footer">
              <Flex justify="space-between" align="center">
                <Box ml={2}>
                  { showDownloadButton ? downloadButton : null }
                  { showPrintButton ? printButton : null }
                </Box>
                <Box>
                  <Button
                    buttonColor="cerulean"
                    buttonText={submitButton || 'Got it'}
                    onClick={onSubmit} />
                </Box>
              </Flex>
            </Box>
          </FixedModal>
        </StyleProvider>
      );
    }
}

export default DisclosureModal;
