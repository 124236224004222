/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  no-useless-escape
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

const CodeContainer = createReactClass({

  propTypes: {
    codeStringLines: PropTypes.array.isRequired,
    classNames: PropTypes.string,
  },

  render() {
    const lines = this.props.codeStringLines.join('\n').replace(/\&quot\;/g, '"');
    return (
      <pre className={this.props.classNames}>{ lines }</pre>
    );
  },
});

const CodeTabPaneWithResponse = createReactClass({

  propTypes: {
    languageName: PropTypes.string.isRequired,
    requestCodeStringLines: PropTypes.array.isRequired,
    responseCodeStringLines: PropTypes.array.isRequired,
    containerClassNames: PropTypes.string,
    codeClassNames: PropTypes.string,
    isCollapsed: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      containerClassNames: '',
      codeClassNames: '',
      isCollapsed: true,
    };
  },

  getInitialState() {
    return {
      isCollapsed: this.props.isCollapsed,
    };
  },

  toggleResponse(evt) {
    evt.preventDefault();
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  },

  render() {

    const classes = [classNames({
      'm-code-example': true,
      'with-response': true,
      'is-collapsed': this.state.isCollapsed,
    }), this.props.containerClassNames].join(' ');

    const reqClassNames = [classNames({
      'm-code-example--code': true,
      prettyprint: true,
    }), this.props.codeClassNames].join(' ');

    const respClassNames = [classNames({
      'm-code-example--code--json': true,
    }), this.props.codeClassNames].join(' ');

    const request = <CodeContainer classNames={reqClassNames} codeStringLines={this.props.requestCodeStringLines} />;
    let response = '';

    // TODO: At some point, we'll want to create custom "response" examples
    // for each wrapper. For now, since the response is JSON, let's only display
    // this if the example is using cURL. -Chris (5/21/15)
    if (this.props.languageName === 'curl') {
      response = (<div>
        <CodeContainer classNames={respClassNames} codeStringLines={this.props.responseCodeStringLines} />
        <span className='m-code-example--expand-link' onClick={this.toggleResponse}>
          <span className='m-code-example--expand-link--dots'>...</span>
          <a className='m-code-example--expand-link--link' href='#'>Show response</a>
        </span>
      </div>);
    }

    return <div className={classes}>
      { request }
      { response }
    </div>;

  },
});

module.exports = CodeTabPaneWithResponse;
