/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  max-len,
  react/no-unescaped-entities
*/
import React from 'react';
import createReactClass from 'create-react-class';
import TitleSection from '../common/title-section';
import BodySection from '../common/body-section';
import CTASection from '../common/cta-section';

module.exports = createReactClass({
  displayName: 'Electronic Signature SEO Page',

  renderTitleSection() {
    const titleSectionProps = {
      title: 'HelloSign\'s Guide To Electronic Signatures',
      subtitle: 'Jump in and try electronic signing for yourself! Click here to create your free HelloSign account.',
      buttonText: 'LET\'S DO IT!',
    };
    return <TitleSection {...titleSectionProps} {...this.props} />;
  },

  renderBodySection() {
    const headerClass = 'm-marketing-page--header';
    const listClass = 'm-marketing-page--list';
    return <BodySection>
      <h2 className={headerClass}>What is an electronic signature?</h2>

      <p>
                According to the <a
                  href='http://www.techlawjournal.com/cong106/digsig/Default.htm'
                  target='_blank' rel='noopener noreferrer'
                >U.S. Federal ESIGN Act</a> passed in 2000, an
                electronic signature is an "electronic sound, symbol, or process,
                attached to or logically associated with a contract or other record
                and executed or adopted by a person with the intent to sign the
                record."
      </p>

      <p>
                In layman's terms, an electronic signature, often referred to as an
                eSignature, is a person's electronic expression of his or her
                agreement to the terms of a particular document.
      </p>

      <h2 className={headerClass}>What are electronic signatures used for?</h2>

      <p>
                Electronic signatures are used on every sort of document you can
                imagine. For instance, <a href='/' target='_blank' rel='noopener noreferrer'>HelloSign</a> users
                use electronic signatures to:
      </p>

      <ul className={listClass}>
        <li>Esign NDAs, timesheets, and employee onboarding paperwork</li>
        <li>Esign sales contracts, invoices, and approve proposals</li>
        <li>Fill out and sign leases and other rental and housing agreements</li>
        <li>Electronically sign tax documents, bank forms, and insurance paperwork</li>
        <li>Fill out and sign permission slips, school forms, and release</li>
      </ul>

      <h2 className={headerClass}>How do electronic signatures work with HelloSign?</h2>

      <p>
                There are <a href='http://blog.hellosign.com/signature-maker'
                  target='_blank' rel='noopener noreferrer'
                >4 ways to create an electronic signature</a> using
                HelloSign.
      </p>

      <ul className={listClass}>
        <li>Draw your signature using your computer mouse or touchpad</li>
        <li>Take a photo of your signature using your smartphone and upload it to HelloSign</li>
        <li>Type your name and select any one of our fonts to give your typed signature an authentic touch</li>
        <li>Sign using your finger either on our mobile app for iOS and Android, or using our in-person signing feature on the iPad</li>
      </ul>

      <p>
                Once created, an electronic signature can be added to any document along
                with other annotations such as check marks or freeform text.
      </p>

      <div className='text-center l-padding-t-60'>
        <img
          src={require('images/info/signature_modal@2x.png')}
          alt='Signature Modal'
          className='m-marketing-page--image-signature-modal' />
      </div>

      <h2 className={headerClass}>Are electronic signatures legal?</h2>

      <p>
                Yes, <a
                  href='http://blog.hellosign.com/esignatures-legal-handwritten-signatures'
                  target='_blank' rel='noopener noreferrer'
                >electronic signatures are valid and legal</a> in the
                United States, Canada, the European Union, the UK, and many other areas
                of the world. View more about the legality ofelectronic signatures <a
                  href='/info/legal' target='_blank' rel='noopener noreferrer'
                >here</a>.
      </p>

      <h2 className={headerClass}>What are the benefits of electronic signatures?</h2>

      <p>
                There are many benefits to using an electronic signature. Using
                electronic signatures through HelloSign you can:
      </p>

      <ul className={listClass}>
        <li>Get documents signed fast</li>
        <li>Sign documents from anywhere</li>
        <li>
                    Collect <a href='http://blog.hellosign.com/are-esignatures-secure'
                      target='_blank' rel='noopener noreferrer'
                    >secure and legally binding signatures</a>
        </li>
        <li>And <a href='/info/benefits' target='_blank' rel='noopener noreferrer'>many more</a></li>
      </ul>
    </BodySection>;
  },

  renderCTASection() {
    const ctaSectionProps = {
      title: 'Get started: eSign a document for free',
      subtitle: 'Ready to dive in and try electronic signing for yourself? Want to see more?',
      signupButtonText: 'SIGN UP FOR A HELLOSIGN ACCOUNT',
      signupButtonUrl: this.props.homeUrl,
      secondButtonText: 'SEE PRO PLANS',
      secondButtonUrl: this.props.pricingUrl,
      apiUsersHeaderText: 'OUR HAPPY USERS',
      embeddedTweetsHeaderText: 'SOCIAL LOVE',
    };
    return <CTASection {...ctaSectionProps} {...this.props} />;
  },

  render() {
    return <div>
      {this.renderTitleSection()}
      {this.renderBodySection()}
      {this.renderCTASection()}
    </div>;
  },
});
