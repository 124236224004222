import hsFetch from 'hellospa/hs-fetch';
// @ts-ignore
import HfReactHelper from '../../../hellosign/components/hf-react-helper';
// @ts-ignore
import { postMessage, messages } from 'common/utils/messages';

export default function editorActions(
  csrfToken: string,
  csrfTokenAttachmentDelete: string,
  preLoadedTsmGroupKey: string,
) {

  async function loadData(cachedParamsToken: string) {
    // Fetch the data from the server
    const url = `/editor/getData?cached_params_token=${cachedParamsToken}`;
    const response = await hsFetch(url, {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    const data = await response.json();

    return data.data;
  }

  async function loadAutoSuggestionData() {

    const autocompleteResponse = await hsFetch('/editor/asyncAutocomplete', {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    return autocompleteResponse.json();
  }

  async function keepSessionAlive() {
    // keep session alive
    await hsFetch('/account/keepSessionAlive', {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  }

  async function deleteDocument(snapshotGuid: string) {
    if (snapshotGuid) {
      const formData = new FormData();
      formData.append('snapshot_guid', snapshotGuid);
      formData.append('csrf_token', csrfTokenAttachmentDelete);
      formData.append('preloaded_tsm_group_key', preLoadedTsmGroupKey);

      const response = await hsFetch('/attachment/delete', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }

      // Posting message to event listener
      // to remove the deleted document from
      // the prep & send page
      postMessage({
        type: messages.USER_PREP_SEND_EDITOR_DELETE_DOC,
        guid: result.guid,
        parent_guid: result.parent_guid,
      }, window.parent.location.protocol + HfReactHelper.urlHelper(''));
    }
  }

  type OptState = {
    optState: boolean,
    whyOptout?: string,
    comments?: string,
  }

  async function saveOptState(data: OptState) {
    // save opt-in / opt-out data
    const response = await hsFetch('/editor/saveOptState', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        csrf_token: csrfToken,
        opt_state: data.optState,
        why_optout: data.whyOptout,
        comments: data.comments,
      }),
    });

    const result = await response.json();
    if (result.error) {
      throw new Error(result.error);
    }
    return result;
  }

  async function saveData(data: any) {
    data.csrf_token = csrfToken;

    const response = await hsFetch('/editor/saveData', {
      credentials: 'same-origin',
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return response.json();
  }

  return {
    loadData,
    loadAutoSuggestionData,
    keepSessionAlive,
    deleteDocument,
    saveOptState,
    saveData,
  };
}
