/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-param-reassign,
  no-useless-escape
*/
/**
 * String helper class
 *
 * Contains helper methods to convert strings
 */
class StringHelper {
  /**
   * Converts string to camelCase if snake_case or kebab-case
   * @param   {string}
   * @returns {string}
   * @static
   */
  static toCamelCase(str) {
    // standard variable naming convention _ or -
    const arr = str.replace(/\_|-/g, ' ').split(' ');

    if (!(arr.length > 0)) {
      return str;
    }

    let value = arr[0]; // first element
    // make the first letter uppercase for all of the elements
    // except the first one and just append to build a "camel cased" value
    arr.slice(1).forEach((val) => {
      value += val.charAt(0).toUpperCase() + val.slice(1);
    });

    return value;
  }

  /**
   * Converts string to snake_case
   * @param  {string}
   * @return {string}
   * @static
   */
  static toSnakeCase(str) {
    // replace all dashes with underscores
    str = str.replace(/\-/g, '_');
    // positive lookahead against uppercase to replace with delimeter
    const value = str.replace(/(.)(?=[A-Z])/g, '$1_');

    return value.toLowerCase();
  }

  /**
   * Converts string to kebab-case
   * @param   {string}
   * @returns {string}
   * @static
   */
  static toKebabCase(str) {
    // replace all underscores with dashes
    str = str.replace(/\_/g, '-');
    // positive lookahead against uppercase to replace with delimeter
    const value = str.replace(/(.)(?=[A-Z])/g, '$1-');

    return value.toLowerCase();
  }
}

export default StringHelper;
