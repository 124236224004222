/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  camelcase,
  eqeqeq,
  func-names,
  global-require,
  import/no-extraneous-dependencies,
  max-len,
  no-mixed-operators,
  no-redeclare,
  no-use-before-define,
  no-var,
  one-var,
  radix,
  react/no-string-refs,
  react/prop-types,
  vars-on-top
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Button from 'common/components/button';
import classNames from 'classnames';
import HSIntlProvider from 'hellospa/components/hs-intl-provider';
import HfReactHelper from 'common/utils/hf-react-helper';
import CreateAccount from '../auth/create-account';
import PaymentModal from './payment-modal';
import PricingLevelFeature from './pricing-level-feature';
import MailToButton from './mail-to-button';

const PricingLevel = createReactClass({

  propTypes: {
    signupUrl: PropTypes.string,
    paymentForm: PropTypes.object.isRequired, // Serialized sfForm
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    dbxPaymentMethod: PropTypes.object,
    mustContactSales: PropTypes.bool,
    isUpgrade: PropTypes.bool,
    isDelinquent: PropTypes.bool,
    changePlanRestricted: PropTypes.bool,
    googleSignInClientId: PropTypes.string,
    dropboxSSO: PropTypes.bool,
    csrfToken: PropTypes.string,
    monthlyRedirect: PropTypes.string,
    annualRedirect: PropTypes.string,
  },

  getInitialState() {
    return {
      expanded: false,
    };
  },

  componentDidMount() {
    if (this.props.acctModalError) {
      this.launchCreateAccountModal();
    }
    if (this.props.wasSelectedBeforeLogIn) {
      this.launchPaymentModal();
    }
  },

  onLevelClick() {
    this.setState({
      expanded: !this.state.expanded,
    });
  },

  launchNoCCTrial() {
    // Submit the skip cc trial form
    this.refs[`no_cc_trial_${this.props.planId}`].submit();
  },

  launchPaymentModal() {
    const attachPoint = HfReactHelper.getRevealAttachPoint('payment_modal', 'medium');
    ReactDOM.render(
      (
        <HSIntlProvider>
          <PaymentModal key={this.props.planId}
            isUpgrade={this.props.isUpgrade}
            planId={this.props.planId}
            planName={this.props.name}
            isFreeTrialEligible={this.props.isFreeTrialEligible}
            isApi={this.props.isApi}
            numFreeDays={this.props.numFreeDays}
            price={this.props.price}
            timePeriod={this.props.timePeriod}
            monthlyExpectedRate={this.props.monthlyExpectedRate}
            annualExpectedRate={this.props.annualExpectedRate}
            monthlyProratedRate={this.props.monthlyProratedRate}
            annualProratedRate={this.props.annualProratedRate}
            currentMonthlyRate={this.props.currentMonthlyRate}
            currentAnnualRate={this.props.currentAnnualRate}
            isTrialing={this.props.isTrialing}
            proratio={this.props.proratio}
            couponCode={this.props.couponCode}
            ccSuffix={this.props.ccSuffix}
            ccExpiration={this.props.ccExpiration}
            cardHolderName={this.props.cardHolderName ? this.props.cardHolderName : ''}
            name={this.props.name}
            paymentForm={this.props.paymentForm}
            dbxPaymentMethod={this.props.dbxPaymentMethod}
            errors={this.props.errors}
            currentPlanName={this.props.currentPlanName}
            tosUrl={this.props.tosUrl}
            refundPolicyUrl={this.props.refundPolicyUrl}
            privacyPolicyUrl={this.props.privacyPolicyUrl} />
        </HSIntlProvider>
      ),
      attachPoint,
    );
  },

  launchCreateAccountModal() {

    const attachPoint = HfReactHelper.getRevealAttachPoint('create_account_modal');

    let redirectUrl = (this.props.timePeriod === 'M') ? this.props.monthlyRedirect : this.props.annualRedirect;

    if (this.props.monthlyExpectedRate > 0) {
      redirectUrl = `${this.props.isApi ? 'api/' : 'info/'}pricing?plan_id=${this.props.planId}&period=${this.props.timePeriod}${this.props.couponCode ? `&coupon_code=${this.props.couponCode}` : ''}`;
      if (this.props.skipCC && this.props.trialPeriod > 0) {
        redirectUrl += `&skip_cc=1&trial_period=${this.props.trialPeriod}`;
      }
    }

    ReactDOM.render(
      (
        <HSIntlProvider>
          <CreateAccount
            key={this.props.planId}
            planId={this.props.planId}
            isApi={this.props.isApi}
            planName={this.props.name}
            skipCC={this.props.skipCC}
            period={this.props.timePeriod}
            trialPeriod={this.props.trialPeriod}
            googleSignInClientId={this.props.googleSignInClientId}
            dropboxSSO={this.props.dropboxSSO}
            csrfToken={this.props.csrfToken}
            errorMsg={this.props.acctModalError}
            header='Let&apos;s get started'
            buttonText='Continue'
            isFromApiPricing={this.props.isApi}
            isFromPricing={!this.props.isApi}
            redirectUrl={redirectUrl}
            tabOffset={1000}
            signupUrl={this.props.signupUrl}
            tosUrl={this.props.tosUrl}
            privacyPolicyUrl={this.props.privacyPolicyUrl}
            signupForm={this.props.signupForm} />
        </HSIntlProvider>
      ),
      attachPoint,
    );
  },

  launchSalesModal() {
    $('#contactUsButton').click();
  },

  resumeSub() {
    const plan_param = this.props.isApi ? 'api' : 'ui';
    window.location.href = `/account/revertPendingCancellation/csrf_token/${this.props.csrfToken}/plan_type/${plan_param}`;
  },

  redirectToCardUpdate() {
    window.location.href = '/account/updateCard';
  },

  render() {
    const isFree = this.props.monthlyExpectedRate <= 0;
    const mostPopular = this.props.mostPopular
      ? (<img className='most-popular show-for-large-up' src={require('./most_popular.png')} />)
      : '';
    const bodyClasses = classNames({
      'm-pricing-level--body': true,
      'small-12': true,
      columns: true,
      expanded: this.state.expanded,
    });

    let seatBadge;
    if (this.props.maxSeats && parseInt(this.props.maxSeats) > 1) {
      const seatBadgeText = `1 - ${this.props.maxSeats} SENDERS`;
      seatBadge = (
        <div className='m-pricing-level--seat-range hide-for-medium-down'>
          <p className='max-seats-badge'>
            { seatBadgeText }
          </p>
        </div>
      );
    }

    let price = '0',
      period = '',
      billingPeriod;

    if (!isFree) {
      if (this.props.timePeriod == 'M') {
        price = (this.props.monthlyCouponPrice ? this.props.monthlyCouponPrice : this.props.monthlyPrice);
        period = 'per month';
      } else {
        price = (this.props.annualCouponPrice ? this.props.annualCouponPrice : this.props.annualPrice);
        period = 'per month when paid annually';

        // If price has decimal places, add "* approx." to the label
        if ((parseFloat(price) % 1) > 0) {
          period = (
            <span>
              * approx.<br /> {period}
            </span>
          );
        }
      }
      billingPeriod = (
        <div className='billing-period'>
          { period }
        </div>
      );
    } else {
      billingPeriod = (<div className='no-billing-period'></div>);
    }

    const primaryPrice = parseInt(price);
    let secondaryPrice = Math.round((price % 1) * 100);
    if (secondaryPrice === 0) {
      secondaryPrice = '';
    } else if (secondaryPrice < 10) {
      secondaryPrice = '0'.secondaryPrice;
    }

    let oldPrice,
      oldPriceMobile;
    if (this.props.coupon) {

      const oldPriceMobileClasses = classNames({
        'show-for-medium-only': true,
        'small-1': true,
        'medium-3': true,
        columns: true,
        'level-price': true,
        'l-padding-t-35': true,
      });

      if (this.props.couponEligible) {
        oldPrice = (
          <div className='m-pricing-level--body small-4 large-12 small-centered columns level-price'>
            <div className='dollar crossed'>$</div>
            <div className='price crossed'>{this.props.price}</div>
          </div>
        );

        oldPriceMobile = (
          <div className={oldPriceMobileClasses}>
            <span className='dollar crossed'>$</span>
            <span className='price crossed'>{this.props.price}</span>
          </div>
        );
      } else {
        oldPrice = (
          <div className='m-pricing-level--body small-4 large-12 small-centered columns level-price l-padding-t-50'>
          </div>
        );

        oldPriceMobile = (
          <div className={oldPriceMobileClasses}>
          </div>
        );
      }
    }

    // Get started button logic
    let getStarted;
    var buttonAttrs = {
      'da-category': 'signup',
      'da-action': `create account start - ${this.props.isApi
        ? 'hsapi'
        : 'hs'}`,
      'da-label': this.props.name.toLowerCase(),
    };
    if (buttonAttrs['da-label'] === 'business') {
      buttonAttrs['da-label'] = 'b5';
    }

    var primaryColor = 'cerulean';
    var primaryColorClass = 'c-cerulean';
    if (!HfReactHelper.isHelloSign()) {
      primaryColor = 'goldenrod';
      primaryColorClass = 'c-goldenrod';
    }

    if (this.props.isCurrentPlan && !this.props.isDelinquent && ((!this.props.isApi) && this.props.UiPendingCancel || (this.props.isApi) && this.props.ApiPendingCancel) && this.props.isAdmin) {
      var buttonText = 'Resume';
      var buttonFunc = this.resumeSub;
      getStarted = (<Button buttonText={buttonText} onClick={buttonFunc} buttonHoverColor={primaryColor} buttonAttrs={buttonAttrs}></Button>);
      // Special cases for delinquent subscriptions
    } else if (this.props.isDelinquent && !this.props.changePlanRestricted) {
      var buttonText,
        buttonColor,
        buttonHoverColor,
        buttonFunc;
      // Allow access to CC update only on current plan
      if (this.props.isCurrentPlan) {
        buttonText = 'Update payment info';
        buttonFunc = this.redirectToCardUpdate;
        buttonColor = 'ashen';
        buttonHoverColor = primaryColor;
        // Disallow plan change
      } else {
        buttonText = 'Unavailable';
        buttonFunc = function () {}; // noop
        buttonColor = 'cinder-block';
        buttonHoverColor = 'cinder-block';
      }
      getStarted = (<Button buttonText={buttonText} onClick={buttonFunc} buttonColor={buttonColor} buttonHoverColor={buttonHoverColor} buttonAttrs={buttonAttrs}></Button>);
    } else if (this.props.isCurrentPlan) {
      getStarted = (<div className='current-plan'>You are currently on this plan</div>);
    } else if (this.props.changePlanRestricted) {
      const supportEmail = HfReactHelper.HfConstants.supportEmail[HfReactHelper.HfSites.SITE_CODE_HS];
      getStarted = (<MailToButton email={supportEmail} subject={'Alt Billing Plan Inquiry'} />);
    } else if (this.props.isAdmin && (!this.props.isApi && !this.props.UiPendingCancel || this.props.isApi && !this.props.ApiPendingCancel)) {
      const isSkipCC = this.props.isLoggedIn && this.props.skipCC && this.props.trialPeriod > 0;
      var buttonText = (isFree || this.props.isApi ? 'Get Started' : 'Start Free Trial');
      var buttonFunc = isSkipCC ? this.launchNoCCTrial : this.launchCreateAccountModal;
      if (this.props.isLoggedIn && !isSkipCC) {
        buttonText = 'Get Started';
        buttonFunc = this.launchPaymentModal;
      }
      if (this.props.mustContactSales && this.props.isUpgrade) {
        buttonFunc = this.launchSalesModal;
      }
      getStarted = (<Button buttonText={buttonText} onClick={buttonFunc} buttonHoverColor={primaryColor} buttonAttrs={buttonAttrs}></Button>); // FIXME: test
    } else {
      getStarted = (<div className='no-plan-option'></div>);
    }

    let freeTrial = '';
    if (this.props.isAdmin && this.props.isFreeTrialEligible && !this.props.isApi && !this.props.isCurrentPlan && !isFree) {
      const freeTrialPeriodLabel = this.props.skipCC && this.props.trialPeriod > 0 ? `${this.props.trialPeriod}-month` : `${this.props.numFreeDays}-day`;
      freeTrial = (<div className='m-pricing-level--num-trial-days'>{`${freeTrialPeriodLabel} free trial`}</div>);
    } else {
      const freeTrialClasses = classNames({
        'current-plan': this.props.isCurrentPlan,
        'm-pricing-level--no-free-trial': true,
      });
      freeTrial = (<div className={freeTrialClasses}></div>);
    }

    // No CC Free trial form
    let noCCTrialForm;
    if (this.props.isLoggedIn && this.props.skipCC && !isFree && this.props.trialPeriod > 0) {
      noCCTrialForm = (
        <form action="" method="POST" id={`no_cc_trial_${this.props.planId}`} ref={`no_cc_trial_${this.props.planId}`}>
          <input type="hidden" name="skip_free_trial_cc" value="1" />
          <input type="hidden" name="period" value={this.props.timePeriod} />
          <input type="hidden" name="free_trial_period" value={this.props.trialPeriod} />
          <input type="hidden" name="plan_id" value={this.props.planId} />
        </form>
      );
    }

    let mainFeature;
    if (this.props.mainFeature) {
      var name = (this.props.mainFeature.name ? this.props.mainFeature.name : name);
      const detail = (this.props.mainFeature.detail ? this.props.mainFeature.detail : null);
      const number = (this.props.mainFeature.number ? this.props.mainFeature.number : null);
      mainFeature = (<PricingLevelFeature name={name} planName={this.props.name} detail={detail} number={number} isFree={isFree} key={'main-feature'} expanded={this.state.expanded}></PricingLevelFeature>);
    }

    const features = this.props.features.map((feature, i) => {
      var name = (feature.name ? feature.name : name);
      const detail = (feature.detail ? feature.detail : null);
      const number = (feature.number ? feature.number : null);
      return (
        <PricingLevelFeature name={name} planName={this.props.name} detail={detail} number={number} key={`feature${i}`} isFree={isFree} expanded={this.state.expanded}></PricingLevelFeature>
      );
    });

    var name = this.props.name;

    const levelClasses = classNames({
      'small-5': true,
      'medium-4': !this.props.coupon,
      'medium-offset-1': true,
      'medium-3': this.props.coupon,
      'large-12': true,
      'large-offset-0': true,
      columns: true,
      'level-title': true,
    });
    const arrowClasses = classNames({
      'l-vertical-align': true,
      arrow: true,
      expanded: this.state.expanded,
    });

    const dollarClasses = classNames(primaryColorClass, {
      dollar: true,
    });

    const priceClasses = classNames(primaryColorClass, {
      price: true,
    });

    return (
      <div data-test-id='pricing-level' className={classNames({
        'm-pricing-level': true,
        'small-12': true,
        'medium-12': true,
        'large-3': !HfReactHelper.isHelloSign() && this.props.totalColumns >= 3,
        'large-4': HfReactHelper.isHelloSign() && this.props.totalColumns >= 3,
        'large-6': this.props.totalColumns == 2,
        'large-12': this.props.totalColumns == 1,
        columns: true,
      })}
      >
        <div className='m-pricing-level--header small-12 medium-12 columns block' onClick={this.onLevelClick}>
          <div className={levelClasses}>
            <div className='level-title l-vertical-align-wrapper'>
              <div className='l-vertical-align'>{ name ? name.toUpperCase() : '' }</div>
            </div>
          </div>
          { seatBadge }
          { oldPriceMobile }
          <div className={classNames({
            'hide-for-large-up': true,
            'small-7': true,
            'medium-5': true,
            'medium-offset-0': this.props.coupon,
            'medium-offset-1': !this.props.coupon,
            columns: true,
            'level-price': true,
          })}
          >
            <div className='level-title l-vertical-align-wrapper'>
              <div className='l-vertical-align'>
                <span className={dollarClasses}>$</span>
                <span className={priceClasses}>{ price }
                  <span className='billing-period'>{ period }</span>
                </span>
              </div>
            </div>
          </div>
          <div className={classNames({
            'arrow-container': true,
            'hide-for-large-up': true,
            'hide-for-small': true,
            'small-2': true,
            'medium-1': true,
            columns: true,
          })}
          >
            <div className='level-title l-vertical-align-wrapper'>
              <div className={arrowClasses}>&nbsp;</div>
            </div>
          </div>
          { mostPopular }
        </div>
        <div className={bodyClasses}>
          <div className='first-section block'>
            { oldPrice }
            <div className='m-pricing-level--body small-3 large-12 small-centered columns block'>
              <div className={dollarClasses}>$</div>
              <div className={priceClasses}>{ primaryPrice }</div>
              <div className={dollarClasses}>{ secondaryPrice }</div>
              { billingPeriod }
            </div>
            <div className='block'>
              { getStarted }
              { noCCTrialForm }
              { freeTrial }
            </div>
            { mainFeature }
          </div>
        </div>
        { features }
      </div>
    );
  },
});

module.exports = PricingLevel;
