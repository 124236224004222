/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  no-alert,
  no-bitwise,
  no-constant-condition,
  no-inner-declarations,
  no-restricted-globals,
  no-void,
  react/no-string-refs
*/
import React from 'react';
import PropTypes from 'prop-types';
import watchAppNotifierMixin from 'common/components/mixins/watch-app-notifier';
import HfReactHelper from 'common/utils/hf-react-helper';
import FormattedFaxNumber from 'common/components/formatted-fax-number';
import Tooltip from 'common/components/tooltip';
import MultiSelect from 'common/components/multi-select';
import createReactClass from 'create-react-class';


const FaxLineConfigEntry = createReactClass({

  mixins: [watchAppNotifierMixin],

  propTypes: {
    hasMultiple: PropTypes.bool.isRequired,
    teamMembers: PropTypes.array.isRequired, // This should be allTeamMembers
    faxLine(props, propName, componentName) {
      if (props[propName].number === undefined) {
        throw new Error(`${propName}.number must be defined for ${componentName}`);
      }
      if (props[propName].statusCode === undefined) {
        throw new Error(`${propName}.statusCode must be defined for ${componentName}`);
      }
      if (props[propName].guid === undefined) {
        throw new Error(`${propName}.guid must be defined for ${componentName}`);
      }
    },
  },

  getInitialState() {
    return {
      hasHover: false,
      isDeleting: false,
      statusMessage: null, // when not null, should be object in form of { text: '', className: '' }
      isPorting: (this.props.faxLine.statusCode !== HfReactHelper.HfConstants.team_fax_lines.STATUS_CODES.STATUS_CODE_ACTIVE),
    };
  },

  handleMouseEnter() {
    this.setState({ hasHover: true });
  },

  handleMouseLeave() {
    this.setState({ hasHover: false });
  },

  handleDelete() {
    if (!window.confirm(`Are you sure you want to ${this.state.isPorting ? 'cancel porting for this number?' : 'remove this fax line? You will no longer be able to receive faxes at this number.'}`)) {
      return;
    }

    this.setState({ isDeleting: true });

    this.props.faxLine.remove().then((response) => {
      if (response.success === 'success') {
        location.reload();
      }
    });
  },

  handleSelectionChange(selectedTeamMemberGuids) {

    if (selectedTeamMemberGuids.length === 0) {
      this.setState({
        statusMessage: {
          text: 'Cannot save. At least one recipient is required.',
          className: 'error',
        },
      });
    } else {

      function onResult(err) {
        if (!err) {
          this.setState({ statusMessage: { text: 'Saved', className: 'success' } });
        } else {
          this.setState({ statusMessage: { text: 'Error. Could not save.', className: '' } });
        }
      }

      this.props.faxLine.setMembers(this.props.teamMembers.filter((tm) => {
        return !!~selectedTeamMemberGuids.indexOf(tm.guid);
      })).then(onResult.bind(this, void 0), onResult.bind(this));
    }
  },

  render() {
    let hintText;
    let hintMsg;

    if (this.props.faxLine.isDenied()) {
      hintMsg = <span>Thank you for your interest in switching over to HelloFax! Unfortunately, we are unable to port this number at this time.</span>;
      hintText = (
        <span>Porting not possible
          <Tooltip
            content={hintMsg}
            text='?'
            arrowPosition='left' />
        </span>
      );
    } else if (this.props.faxLine.isPorting()) {
      hintText = <span>Checking portability</span>;
    } else if (this.props.faxLine.isAccepted()) {
      hintMsg = <span>
        <b>Your number is portable!</b>
        <br />
                Please check your email for the steps to complete your porting request.
      </span>;
      hintText = (
        <span>Porting in progress
          <Tooltip
            content={hintMsg}
            text='?'
            arrowPosition='left' />
        </span>
      );
    } else if (this.props.faxLine.isActive()) {
      hintText = <span>Email inbound faxes to:</span>;
    }

    let deleteBtn = null;
    if (true || (this.props.faxLine.statusCode === HfReactHelper.HfConstants.team_fax_lines.STATUS_CODES.STATUS_CODE_PORTING_STARTED) || (this.props.hasMultiple && (this.state.hasHover || this.state.isDeleting))) {
      deleteBtn = (
        <span onClick={this.handleDelete} className={`remove-fax-line${this.state.isDeleting ? ' loading' : ''}`} data-fax-line-guid={this.props.faxLine.guid} data-fax-number={this.props.faxLine.number}>
          <img className='spinner' src={HfReactHelper.HfConstants.team_fax_lines.URLS.spinnerUrl} width='16' height='16' />
        </span>
      );
    }

    const disabled = HfReactHelper.inArray(
      this.props.faxLine.statusCode,
      [
        HfReactHelper.HfConstants.team_fax_lines.STATUS_CODES.STATUS_CODE_PORTING_DENIED,
        HfReactHelper.HfConstants.team_fax_lines.STATUS_CODES.STATUS_CODE_PORTING_DENIED,
      ],
    ) > -1;

    return (
      <div className={`fax_line_select status-${this.props.faxLine.statusCode}`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <h4>
          <span>
            <FormattedFaxNumber faxNumber={this.props.faxLine.number} />
            { ' - ' }
            <span className='text'>{hintText}</span>
          </span>
          {deleteBtn}
        </h4>
        <MultiSelect ref='multiSelect'
          className='fax_line_select'
          disabled={disabled}
          statusMessage={this.state.statusMessage}
          options={this.props.teamMembers.map((teamMember) => {
            return { key: teamMember.guid, descr: teamMember.emailAddress };
          })}
          onSelectionChange={this.handleSelectionChange}
          initiallySelectedOptionValues={this.props.faxLine.teamMembers.map((v) => {
            return v.guid;
          })}
          data-fax-line-guid={this.props.faxLine.guid} />
      </div>
    );
  },
});

module.exports = FaxLineConfigEntry;
