import React from 'react';
import ReactModal from 'react-modal';
import { defineMessages, useIntl } from 'react-intl';
import Button from 'common/components/button';
import classnames from 'classnames';
import './hello-modal.scss';

const messages = defineMessages({
  close: {
    id: '',
    description: 'title text of closing button of modal',
    defaultMessage: 'Close',
  },
});

export function isHidden(domNode) {
  // When the modal is open it marks the site wrapper as hidden.
  const hidden = document.querySelectorAll('[aria-hidden="true"]');
  for (let i = 0; i < hidden.length; i++) {
    if (hidden[i].contains(domNode)) {
      return true;
    }
  }
  return false;
}

let initialized = false;
export function initAppElement() {
  if (!initialized) {
    // React based pages have a root element,
    // PHP based pages use a site-wrapper,
    // Signer app uses signer-mobile-application
    const wrapper = (
      document.getElementById('root')
      || document.getElementById('site-wrapper')
      || document.getElementById('signer-mobile-application')
    );
    if (wrapper != null) {
      initialized = true;
      ReactModal.setAppElement(wrapper);
    }
  }
}

export { ReactModal };

const className = {
  base: 'hello-modal__content',
  afterOpen: 'hello-modal__content--afterOpen',
  beforeClose: 'hello-modal__content--beforeClose',
};
const overlayClassName = {
  base: 'hello-modal__overlay',
  afterOpen: 'hello-modal__overlay--afterOpen',
  beforeClose: 'hello-modal__overlay--beforeClose',
};


function ModalButton(incomingProps) {
  const {
    type, text, onClick, buttonLink, extraClasses, disabled, openInNewWindow,
    onRequestClose,
  } = incomingProps;
  let props = { };

  switch (type) {
    case 'cancel':
      props = {
        buttonBorderColor: 'cinder-block',
        buttonColor: 'cinder-block',
        buttonTextColor: 'white',
        buttonHoverBorderColor: 'black',
        onClick: onRequestClose,
      };
      break;
    case 'primary':
      props = {
        buttonBorderColor: 'color-primary-button',
        buttonColor: 'color-primary-button',
        buttonTextColor: 'white',
        buttonHoverBorderColor: 'black',
      };
      break;
    case 'secondary':
      props = {
        buttonBorderColor: 'plume',
        buttonColor: 'plume',
        buttonTextColor: 'black',
      };
      break;
    case 'delete':
      props = {
        buttonBorderColor: 'red',
        buttonColor: 'red',
        buttonTextColor: 'white',
      };
      break;
    // This is specific button settings for Dropbox where we had to follow their colors.
    // For all HS buttons use one of the provided types from above
    case 'dropbox':
      props = {
        buttonBorderColor: 'dropbox-blue',
        buttonColor: 'dropbox-blue',
        buttonTextColor: 'white',
        iconUri: 'v2/modules/home/integrations/dropbox_icon_white.svg',
        buttonId: 'view_on_dropbox_link',
      };
      break;
    default:
      throw new Error(`Invalid button type ${type}`);
  }

  if (disabled) {
    props = {
      buttonBorderColor: 'cinder-block',
      buttonColor: 'cinder-block',
      buttonTextColor: 'white',
      buttonHoverBorderColor: 'cinder-block',
      disabled: true,
    };
  }

  if (incomingProps['data-qa-ref']) {
    props['data-qa-ref'] = incomingProps['data-qa-ref'];
  }
  return (
    <Button
      className={extraClasses || ''}
      key={text}
      buttonTextUppercase={false}
      onClick={onClick}
      buttonText={text}
      buttonLink={buttonLink}
      openInNewWindow={openInNewWindow}
      {...props} />
  );
}

// This wrapper exists so that we can apply our application-standard styling and
// behaviors in one location.
export default function HelloModal(allModalProps) {
  initAppElement();
  const {
    isOpen,
    isCompact,
    buttons,
    width = '540px',
    scrollable = true,
    contentLabel,
    onRequestClose,
    children,
    extraClasses,
    showCloseIcon = true,
    ...props
  } = allModalProps;

  const intl = useIntl();

  const footer = (buttons ? (
    <div className="hello-modal__footer">
      {buttons.map((button) => (
        <ModalButton
          key={button.text}
          {...button}
          onRequestClose={allModalProps.onRequestClose} />
      ))}
    </div>
  ) : null);

  const style = { overlay: {}, content: {} };
  if (width) {
    style.content.width = width;
    // width doesn't do anything if it's set outside of minWidth/maxWidth
    style.content.maxWidth = width;
    style.content.minWidth = width;
  }

  return (
    <ReactModal
      style={style}
      {...props /* props should be able to override style, but you probably just want width */}
      className={className}
      ariaHideApp={
        // This doesn't work in an enzyme test because document.getElementById()
        // doesn't look at enzyme's DOM. Even if you add an appropriate div to
        // the test, it can't be found.
        process.env.NODE_ENV !== 'test'
      }
      overlayClassName={overlayClassName}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div
        data-qa-ref="hello-modal"
        className={classnames('hello-modal', {
          'hello-modal--compact': isCompact,
        }, extraClasses)}
      >
        <div className="hello-modal__title" data-qa-ref="hello-modal-title">
          <span>
            {contentLabel}
          </span>
          {showCloseIcon ? (
            <button
              title={intl.formatMessage(messages.close)}
              className="hello-modal__close"
              onClick={onRequestClose}
              data-qa-ref="hello-modal--close-button" />
          ) : null}
        </div>
        <div className={classnames('hello-modal__body', {
          'hello-modal__body--scrollable': scrollable,
        })}
        >
          {children}
        </div>
        {footer}
      </div>
    </ReactModal>
  );
}
