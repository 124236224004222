/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len,
  no-use-before-define
*/
import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'common/components/error-boundary';
import Application from 'hellosign/detached-application';
import AppTopBar from 'hellosign/components/app/header';
import AppFooter from 'hellosign/components/app/footer';
import AppSideBar from 'hellosign/components/app/sidebar';
import { Flex, Box } from 'hello-ui/blocks';
import Layout from './layout';
import Main from './main';


const contextValueType = PropTypes.shape({
  /**
   * Application instance
   */
  app: PropTypes.instanceOf(Application).isRequired,

  csrfToken: PropTypes.string.isRequired,

  /**
   * Indicates either HelloSign or HelloFax.
   */
  siteCode: PropTypes.oneOf(['S', 'F']).isRequired,
  dropboxSSO: PropTypes.bool,

  signature: PropTypes.shape({

    /**
     * Email guid and email upload address are used in the signature modal for
     * the "upload" tab
     */
    signatureEmailGuid: PropTypes.string.isRequired,
    emailUploadAddress: PropTypes.string.isRequired,

    /**
     * Indicates a type of either "signature" or "initial."  Used in the
     * signature modal
     */
    type: PropTypes.oneOf(['S', 'I']).isRequired,
  }),

  /**
   * Properties of the currently logged-in user.
   */
  user: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isFree: PropTypes.bool,
    name: PropTypes.string.isRequired,
    numApiDocsLeft: PropTypes.number,
    numDocsLeft: PropTypes.number,
    numTemplatesLeft: PropTypes.number,
    primarySignatureGuid: PropTypes.string,
    settings: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    showApiCredits: PropTypes.bool,
  }).isRequired,
});

// Everything the layout needs is injected to the `<Root`, but that component
// needs to handle routing, then each page decides whether or not to use the
// <DefaultLayout. Instead of haivng to pass the props down, `<Root` will just
// inject the data into the context so that each route can just render
// `<DefaultLayout>{content}`
export const { Provider: DefaultLayoutProvider, Consumer } = React.createContext();

DefaultLayout.propTypes = {
  layoutContext: contextValueType,
  children: PropTypes.node.isRequired,
  activeItemId: PropTypes.string,
};

/**
 * Root HelloSign Home app component.
 */
function DefaultLayout(props) {
  const {
    app,
    csrfToken,
    signature,
    siteCode,
    user,
    termsURL,
    privacyURL,
  } = props.layoutContext;

  const signatureModalProps = {
    signatureType: signature.type,
    emailGuid: signature.signatureEmailGuid,
    emailUploadAddress: signature.emailUploadAddress,
    settings: user.settings,
    csrfToken,
  };

  return <Layout responsive={false}>
    <Box mt={4} mb={5} flex="none">
      {
        /**
        * The position relative is necessary for the bubble menu
        * in the account menu to be positioned correctly.
        */
      }
      <Box position="relative">
        <AppTopBar
          app={app}
          siteCode={siteCode}
          showUpgradeButton={user.isFree}
          emailAddress={user.emailAddress}
          name={user.name}
          numDocsLeft={user.numDocsLeft}
          numApiDocsLeft={user.numApiDocsLeft}
          numTemplatesLeft={user.numTemplatesLeft}
          primarySignatureGuid={user.primarySignatureGuid}
          showApiCredits={user.showApiCredits}
          signatureModalProps={signatureModalProps}
          canAccessAdminConsole={user.canAccessAdminConsole}
          responsive />
      </Box>
    </Box>

    <Main>
      <Flex flexDirection="row">
        <Box>
          <AppSideBar
            app={app}
            canAccessBulkSendUI={user.canAccessBulkSendUI}
            canAccessAdminConsole={user.canAccessAdminConsole}
            activeItemId={
              // At some point I'd like to just have the AppSideBar just use
              // ReactRouter to figure out which item is active.
              props.activeItemId
            }
            siteCode={siteCode}
            emailAddress={user.emailAddress}
            name={user.name} />
        </Box>

        <Box flex={1}>
          <ErrorBoundary>
            {props.children}
          </ErrorBoundary>
        </Box>
      </Flex>
    </Main>
    <Box px={10}>
      <AppFooter
        siteCode={siteCode}
        termsURL={termsURL}
        privacyURL={privacyURL} />
    </Box>
  </Layout>;
}

export default function DefaultLayoutWrapper(props) {
  return <Consumer>{(layoutContext) => <DefaultLayout {...props} layoutContext={layoutContext} />}</Consumer>;
}
