/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import ButtonTextSubmit from 'common/components/button-text-submit';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import createReactClass from 'create-react-class';

const HomePageManualSignup = createReactClass({

  propTypes: {
    redirectUrl: PropTypes.string,
    initialErrorMessage: PropTypes.string,
    initialValue: PropTypes.string,
    utmCampaign: PropTypes.string,
    utmMedium: PropTypes.string,
    utmSource: PropTypes.string,
    utmTerm: PropTypes.string,
    campaignCode: PropTypes.string,
    onOptInChange: PropTypes.func,
    tabOffset: PropTypes.number,
    targetUrl: PropTypes.string,
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
  },

  render() {

    const targetUrl = this.props.targetUrl || '/account/signUp';
    const tabOffset = parseInt(this.props.tabOffset || 0, 10);

    const formData = {
      'signup[redirect_url]': this.props.redirectUrl,
      'signup[pending_tsm_group_guid]': this.props.pendingTsmGroupGuid, // for if the user sends a TsmGroup after they've gotten logged out somehow
      'signup[utm_campaign]': this.props.utmCampaign,
      'signup[utm_medium]': this.props.utmMedium,
      'signup[utm_source]': this.props.utmSource,
      'signup[utm_term]': this.props.utmTerm,
      'signup[campaign_code]': this.props.campaignCode,
      'signup[coupon_code]': '',
      'signup[billing_plan_quota_short_guid]': '',
      'signup[billing_period]': '',
      'signup[first_name]': '',
      'signup[last_name]': '',
      'signup[is_from_external]': '',
      should_show_password: '0',
    };

    const validate = function (val) {
      if (!HfReactHelper.isValidEmailAddress(val)) {
        return 'The email address that you\'ve entered is invalid. Please try again.';
      } else {
        return false;
      }
    };

    const actionBtn = (<Button
      buttonText='SIGN UP FREE'
      buttonClass='m-login-or-signup--sign-up-button'
      buttonColor='cerulean'
      buttonTabIndex={tabOffset + 1} />);

    // FIXME: too hardcoded to signup[] for my taste
    // FIXME: There's lot of things that could be refactored in here to make better use of BaseForm
    return (
      <ButtonTextSubmit
        formName='newaccount'
        textPlaceholder='Email'
        textClass='m-login-or-signup--text-field'
        inputName='signup[email_address]'
        additionalFormData={formData}
        validate={validate}
        divClass='m-sign-mobile--send'
        url={targetUrl}
        initialValue={this.props.initialValue}
        actionButton={actionBtn}
        initialErrorMessage={this.props.initialErrorMessage}
        errorClass='c-warm-chinchilla'
        tabOffset={tabOffset}
        namespace={this.props.signupForm.namespace}
        fields={this.props.signupForm.fields}
        values={this.props.signupForm.values}
      >

        <br />
        <input id='homepage_opt_in'
          type='checkbox'
          name='signup[is_opted_in_marketing_emails]'
          value='1'
          defaultChecked={false}
          onChange={this.props.onOptInChange}
          tabIndex={tabOffset + 2} />
        <label htmlFor='homepage_opt_in' className='m-login-or-signup--opt-in-label'>Send me HelloSign updates</label>

      </ButtonTextSubmit>
    );
  },

  // FIXME: 'Send me HelloSign updates' should be a constant since it is used elsewhere
});

module.exports = HomePageManualSignup;
