// @flow
/**
 * Theme
 *
 * This object defines the themeable properties throughout the component
 * library.  The theme is provided by the `StyleProvider` from the top level to
 * all child components.
 */

export type Theme = {

    /**
     * An array of media query strings.  There can be as many breakpoints as
     * necessary for your app.  This property is mainly used with the `Box`
     * component for specifying responsive props.  The array values will
     * correspond to the props given, e.g. `<Box margin={[0, 1, 2]} />`
     */
    breakpoints?: Array<number>,

    /**
     * A scale from smallest to largest.  The scale defines the spacing rhythm
     * for all components in the library.  In the `Box` component, it allows for
     * specifying margin and padding by the index of the scale as a shorthand.
     * For example, `<Box margin={1} />` would mean the margin applied would
     * correlate to the `1` index in the theme scale.
     */
    space?: Array<string>,

    /**
     * Like the scale, the type scale is a special scale for typography.
     */
    fontSizes?: Array<string>,

    /**
     * An object of colors.  This should be an object mapping color names to
     * color values.  The `primary` and `secondary` keys are required, but you
     * may also provide other custom colors if neeeded.
     */
    colors?: {
        primary: string,
        secondary: string,
        [name: string]: string
    }
};

const defaultTheme = {
  breakpoints: [40, 52, 64],
  space: ['0rem', '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem'],
  fontSizes: ['0.85rem', '1rem', '1.25rem', '1.5rem', '2.25rem', '3rem'],
  colors: {
    primary: 'black',
    secondary: 'black',
  },
};

export default defaultTheme;
