/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-multi-assign,
  no-param-reassign,
  no-prototype-builtins,
  no-restricted-syntax,
  prefer-rest-params
*/
import protoclass from 'protoclass';
import { EventEmitter } from 'events';
import createDebug from 'debug';
import _mixin from './_mixin';

const debugCall = createDebug('hs:models:call');
const debugSet = createDebug('hs:models:set');

const proxyHandler = {
  get(target, propKey, receiver) {
    const targetValue = Reflect.get(target, propKey, receiver);

    // Some functions are just too noisy
    if (
      propKey === 'hasValue'
      || propKey === 'hasDataValue'
      || propKey === 'equals'
    ) {
      return targetValue;
    }

    if (typeof targetValue === 'function') {
      return function (...args) {
        let resolvePromise;
        // This promise allows for logging BEFORE calling the function so that
        // logs are in the order the functions were called instead of the order
        // they returned. To examine a return value, right click it and "Store
        // as a global variable" then `await temp1` in the console.
        const returnPromise = new Promise((r) => { resolvePromise = r; });
        if (args.length > 0) {
          debugCall('%s.%s(...%o) => %o', target.guid, propKey, args, returnPromise);
        } else {
          debugCall('%s.%s() => %o', target.guid, propKey, returnPromise);
        }
        const returnValue = targetValue.apply(this, args);
        resolvePromise(returnValue);
        return returnValue;
      };
    } else {
      return targetValue;
    }
  },
  set(target, propKey, value) {
    debugSet('%s.%s = %o', target.guid, propKey, value);
    return Reflect.set(...arguments);
  },
};

function BaseModel(properties) {
  debugCall('CONSTRUCTOR(%o) => %o', properties, this);

  // TODO - remove this - use notifier pattern instead (CC)
  EventEmitter.call(this);
  this._disposables = [];
  if (properties) this.setProperties(properties);
  this.initialize();
}

/**
*/

protoclass(BaseModel, EventEmitter.prototype, {

  /**
     */

  idProperty: 'guid',

  /**
    */

  __isWatchableObject: true,

  /**
    */

  initialize() {

  },

  /**
     */

  addDisposable(disposable) {
    this._disposables.push(disposable);
    return disposable;
  },

  /**
    */

  equals(model) {
    return model[this.idProperty] === this[this.idProperty];
  },

  /**
    */

  set(key, value) {
    if (this[key] !== value) {
      const oldProps = {};
      const newProps = {};
      oldProps[key] = this[key];
      this[key] = newProps[key] = value;
      this.onPropertiesChange(oldProps, newProps);
    }
  },

  /**
    */

  setProperties(properties) {

    const oldValues = {};
    let hasChange = false;

    for (const key in properties) {
      const newValue = properties[key];
      if (newValue !== this[key]) {
        oldValues[key] = this[key];
        this[key] = properties[key];
        hasChange = true;
      }
    }

    if (hasChange) {
      this.onPropertiesChange(oldValues, properties);
    }
  },

  /**
     * disposes the model
     */

  dispose() {
    for (const disposable of this._disposables.concat()) {
      disposable.dispose();
    }
  },

  /**
    */

  onPropertiesChange(oldProps, newProps) {
    if (newProps.data) {
      this.onDataChange(newProps.data);
    }
  },

  /**
     */

  onDataChange(data) {
    const props = this.fromData(data);
    this.setProperties(props);
  },

  /**
    */

  fromData(data) {
    return data;
  },

  /**
    */

  toData() {
    const data = {};
    const target = this.data ? this.data : this;
    for (const key in target) {
      if (target.hasOwnProperty(key)) {
        data[key] = this[key];
      }
    }
    return data;
  },

  /**
    */

  toJSON() {
    return this.toData();
  },
});


const oldExtend = BaseModel.extend;

BaseModel.extend = function (properties) {

  const self = this;

  function ChildModel() {
    self.apply(this, arguments);
  }

  if (properties && properties.mixins) {
    properties = _mixin([properties].concat(properties.mixins));
  }

  oldExtend.call(self, ChildModel, properties);
  ChildModel.extend = self.extend;
  ChildModel.create = self.create;
  return ChildModel;
};

BaseModel.createClass = BaseModel.extend.bind(BaseModel);

// factory method
BaseModel.create = function (properties) {
  // To turn on model proxies run this in the dev tools console and refresh:
  // localStorage.debug = 'hs:models:*'
  if (
    process.env.NODE_ENV === 'development'
    && (debugCall.enabled || debugSet.enabled)
  ) {
    const model = Object.create(this.prototype);
    // The proxy must exist before the constructor runs because models create
    // other models and pass themselves to their children. If the model doesn't
    // run with the proxy context, you don't end up proxying all of the models.
    const proxy = new Proxy(model, proxyHandler);

    // Call the constructor using `proxy` as the context.
    model.constructor.call(proxy, properties);

    return proxy;
  }
  return new this(properties);
};

module.exports = BaseModel;
