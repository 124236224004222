/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require
*/


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// You must register a React component here before calling
// render_react_component in PHP
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// ESNext imports
import Badge from 'common/components/badge';
import AdvancedSearch from 'ui/advanced-search';
import AttachmentButton from 'ui/attachment-button';
import DropboxSuccessModal from 'common/components/dropbox-success-modal';
import TeamAccessModal from 'common/components/team-access-modal';
import EmbeddedTestingToolV2 from './api-docs/embedded-testing-tool-v2';
import MobileNavBar from './home/top-bar-menu/marketing-nav-bar/mobile-nav-bar';
import TopBarMenuLinkV2 from './home/top-bar-menu/marketing-nav-bar/top-bar-menu-link';
import TopBarMenu from './home/top-bar-menu';

module.exports = {

  /**
    * common components used between HS and HF
    */

  Button: require('common/components/button'),
  ButtonTextSubmit: require('common/components/button-text-submit'),
  Input: require('common/components/input'),
  FillEmptySpace: require('common/components/fill-empty-space'),
  PeekABoo: require('common/components/peekaboo'),
  DropDownMenu: require('common/components/drop-down-menu'),
  MobileLinkSubmit: require('common/components/mobile-link-submit'),
  Notification: require('common/components/notifications/index'),
  CloseFancyBoxButton: require('common/components/close-fancy-box-button'),
  IntegrationActivationButton: require('common/components/integration-activation-button'),
  Tooltip: require('common/components/tooltip'),
  Badge,
  DropboxSuccessModal,
  TeamAccessModal,
  FixedModal: require('common/components/fixed-modal'),
  SimpleGrayLayout: require('common/components/simple-gray-layout'),

  /**
     * HS
     */

  AppTopBar: require('./app/header'),
  AppLeftMenu: require('./app/sidebar').default,
  AppFooter: require('./app/footer'),
  HSTeamPage: require('./app/content/team'),

  TwoOptionTabPane: require('./api/two-option-tab-pane'),
  StandardTabPane: require('./api/standard-tab-pane'),

  ApiMenu: require('./api-docs/api-menu'),
  CodeTabPane: require('./api-docs/code-tab-pane'),
  CodeTabPaneWithResponse: require('./api-docs/code-tab-pane-with-response'),
  ImageModalTrigger: require('./api-docs/image-modal-trigger'),
  EmbeddedTestingTool: require('./api-docs/embedded-testing-tool'),
  EmbeddedTestingToolV2,

  ConfirmRequiredPage: require('./auth/confirm-required-page'),
  CreateAccount: require('./auth/create-account'),
  LogInVerificationForm: require('./auth/login-verification-form'),
  LogInModal: require('./auth/login-modal'),
  CreateAccountTrigger: require('./auth/create-account-trigger'),
  LogInTrigger: require('./auth/login-trigger'),
  GoogleSignInButton: require('./auth/google-signin-button'),
  LogInForm: require('./auth/login-form'),
  VerifyEmail: require('./auth/verify-email'),
  LogInInPerson: require('./auth/login-in-person'),
  SignUpPage: require('./auth/sign-up-page'),
  RecoverPasswordPage: require('./auth/recover-password-page'),
  LogInPage: require('./auth/log-in-page'),
  LogInCaptcha: require('./auth/login-captcha'),

  HomePageSignup: require('./home/signup'),
  HomePageManualSignup: require('./home/manual-signup'),
  TopBarMenu,
  TopBarMenuLink: require('./home/top-bar-menu/top-bar-menu-link'),
  TopBarMenuLinkV2,
  LogInButton: require('./home/top-bar-menu/login-button'),
  SignUpButton: require('./home/top-bar-menu/signup-button'),
  CancelSubscriptionPage: require('./home/cancel-subscription/cancel-subscription-page'),
  CustomerCancelOffer: require('./pricing/customer-cancel-offer'),
  BlackBarAnnouncements: require('./home/black-bar-announcements'),
  EsignDisclosurePreview: require('./home/esign-disclosure-preview'),

  PricingArea: require('./pricing'),
  PricingContactUsButton: require('./pricing/contact-us-button'),
  PaymentModal: require('./pricing/payment-modal'),
  EnterpriseAgreement: require('./pricing/enterprise-agreement'),
  TabbedArea: require('./tabbed-area'),
  MarketoForm: require('./marketo-form'),

  ChooseSignupPlan: require('./pricing/choose-signup-plan'),
  CollectInfo: require('./home/collect-info'),
  TeamReporting: require('../team-reporting').default,
  DownloadPage: require('../../ui/download-page').default,
  AdvancedSearch,
  AttachmentButton,
  MobileNavBar,
  /**
     * HF
     */

  FaxLineConfigList: require('./app/content/team/team-fax-lines'),

  /**
     * Landing Pages
     */
  ElectronicSignature: require('landing-pages/components/seo/electronic-signature'),
  WhiteLabelingLandingPage: require('landing-pages/components/seo/white-labeling'),
  RealEstate: require('landing-pages/components/seo/real-estate'),
  GoogleDocs: require('landing-pages/components/seo/google-docs'),

  /**
   * OAuth
   */
  OauthRequest: require('./auth/oauth/oauth-request').default,
};
