/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* global dataLayer */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './collect-info-modal.scss';
import React, { Component } from 'react';
import FixedModal from 'common/components/fixed-modal';
import HfReactHelper from 'common/utils/hf-react-helper';
import CollectInfoForm from './collect-info-form';

export default class CollectInfoModal extends Component {

  componentDidMount() {
    this.reportAnalytics();
  }

  reportAnalytics() {
    dataLayer.push({
      event: 'modal_popup',
      pageName: '/signatureProfileModal',
      pageType: 'signatureProfileModal',
      virtualPagePath: '/signatureProfileModal',
    });
  }

  render() {
    const { fields, values, namespace } = this.props.collectInfoForm;
    const form = (
      <CollectInfoForm
        fields={fields}
        values={values}
        action={HfReactHelper.urlHelper(
          'home/collectInfo',
          HfReactHelper.HfSites.SITE_CODE_HS,
        )}
        onSuccess={this.props.onSuccess}
        namespace={namespace}
        isEnterprise={this.props.isEnterprise}
      ></CollectInfoForm>
    );

    return (
      <FixedModal hideOnOverlayClick={true} >
        <div className='m-collect-info-modal'>
          <div className='m-collect-info-modal--header'>
            <h1>Create your eSignature profile</h1>
            <div className='subheader'>
              <small>
                                This information helps us personalize your experience.
              </small>
            </div>
          </div>
          { form }
        </div>
      </FixedModal>
    );
  }
}
