// eslint-disable-next-line import/prefer-default-export
export async function getAllEntities(actionIteratee, page = 1, seed = []) {
  const [entities, numPages] = await actionIteratee(page);

  if (page + 1 <= numPages) {
    return getAllEntities(actionIteratee, page + 1, seed.concat(entities));
  } else {
    return seed.concat(entities);
  }
}
