/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import createReactClass from 'create-react-class';

const StandardTabPane = createReactClass({
  propTypes: {
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    link: PropTypes.string,
    linkText: PropTypes.string,

    // TODO: this prop needs to be required, otherwise React
    // will throw an exception since render() will return undefined (CC)
    paneType: PropTypes.string,
    bottomImage: PropTypes.string,
  },

  render() {

    let tileLinkButton = null;
    if (this.props.link && this.props.linkText) {
      tileLinkButton = (
        <div className='l-margin-t-15'>
          <Button
            buttonText={this.props.linkText}
            buttonLink={this.props.link}
            buttonHoverColor='cerulean'
            wrapText={true}
            openInNewWindow={true} />
        </div>
      );
    }

    const tileReturnType = <div className='columns small-8 small-text-center small-centered'>
      <div className='m-tabs_tiles--copy-container'>
        <h4 className='m-tabs_tiles--pane-header b'>{this.props.title}</h4>
        <p>{this.props.description}</p>
        {tileLinkButton}
      </div>
    </div>;
    const tabReturnType = <div>
      <div className='show-for-large-up large-6 columns'>
        <img src={this.props.image} className='embedded-image' />
      </div>
      <div className='small-12 large-6 small-text-center large-text-left columns'>
        <div className='row'>
          <div className='small-10 small-centered large-uncentered columns'>
            <h3>{this.props.title}</h3>
          </div>
        </div>
        <div className='row'>
          <div className='small-10 small-centered large-uncentered columns'>
            <div className='l-padding-v-20'>
              <p>{this.props.description}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='small-12 columns'>
            <Button
              buttonText={this.props.linkText ? this.props.linkText : 'LEARN MORE'}
              buttonLink={this.props.link ? this.props.link : '/api/features'}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              openInNewWindow={true} />
          </div>
        </div>
      </div>
    </div>;

    if (this.props.paneType == 'tile') {
      return (tileReturnType);
    } else if (this.props.paneType == 'tab') {
      return (tabReturnType);
    }


  },
});

module.exports = StandardTabPane;
