/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  global-require,
  max-len,
  no-redeclare,
  no-var,
  react/no-string-refs
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import extend from 'lodash/object/extend';
import throttle from 'lodash/function/throttle';
// Named so as not to have collisions with the native Image, which we use here too
import ImageComponent from 'common/components/image';
import createReactClass from 'create-react-class';
import Croppable from './croppable';
import CONSTANTS from './constants';

import {
  GetSignatureUrl,
  Type,
  SignatureType,
} from './prop-types';

const SignatureEditArea = createReactClass({

  propTypes: {
    getSignatureUrl: GetSignatureUrl,
    intl: PropTypes.object.isRequired,
    onRotate: PropTypes.func.isRequired,
    signature: SignatureType,
    type: Type,
  },


  //  ----  LIFECYCLE  ----------------------------------

  getInitialState() {
    return {
      isSignature: (this.props.type === CONSTANTS.TYPE_SIGNATURE),
      isInitials: (this.props.type === CONSTANTS.TYPE_INITIALS),
      selectedFontIndex: 2,
      isSpinnerShown: false,
      contrastValue: this.getContrastMidValue(),
      degreesValue: 0,
      isCropped: false,
      cropX: null,
      cropY: null,
      cropWidth: null,
      cropHeight: null,
    };
  },

  componentDidMount() {
  },

  componentDidUpdate() {
  },


  //  ----  BEHAVIOR  -----------------------------------

  getContrastMidValue() {
    return ((CONSTANTS.CONTRAST_MAX_THRESHOLD + CONSTANTS.CONTRAST_MIN_THRESHOLD) / 2);
  },

  scaleSignatureToFit(origDims, restrictDims) {
    var origDims = extend({}, origDims);

    origDims.ratio = parseFloat(origDims.width / origDims.height);

    if (origDims.width > origDims.height) {

      origDims.width = restrictDims.width;
      origDims.height = parseInt(origDims.width / origDims.ratio, 10);

      if (origDims.height > restrictDims.height) {

        origDims.height = restrictDims.height;
        origDims.width = parseInt(origDims.height * origDims.ratio, 10);
      }
    } else {
      origDims.height = restrictDims.height;
      origDims.width = parseInt(origDims.height * origDims.ratio, 10);

      if (origDims.width > restrictDims.width) {

        origDims.width = restrictDims.width;
        origDims.height = parseInt(origDims.width / origDims.ratio, 10);
      }
    }

    return origDims;
  },

  // Stores prefeched images
  images: {},

  /*
    * Throttled for performance. This may need to be adjusted in the future to > 1s
    */

  prefetchImages: throttle(function () {
    this._prefetchImages();
  }, 1000),

  /*
    * Private prefetch images function
    *
    * Simply gets and stores all contrast variants on load or rotate.
    * The browser will take care of using the cached images instead of reloading from
    * the server each time.
    */

  _prefetchImages() {
    if (!this.props.signature) {
      return;
    }

    const degrees = this.state.degreesValue || 0;
    let contrastStep = CONSTANTS.CONTRAST_MIN_THRESHOLD;

    while (contrastStep <= CONSTANTS.CONTRAST_MAX_THRESHOLD) {

      const key = `c${contrastStep}d${degrees}`;

      // Don't even need to check if already set - we want to overwrite on
      // subsequent uploads
      this.images[key] = new window.Image();
      this.images[key].src = this.props.getSignatureUrl(this.props.signature, contrastStep, degrees);

      contrastStep += CONSTANTS.CONTRAST_STEP_AMOUNT;
    }

  },

  renderSignatureImage() {

    this.prefetchImages();

    const threshold = this.state.contrastValue || 100;
    const degrees = this.state.degreesValue || 0;

    let url;
    if (this.props.signature) {
      url = this.props.getSignatureUrl(this.props.signature, threshold, degrees);
    }

    let style = {};

    // Hardcoded to preview container area size, with 2px padding
    const previewAreaDimensions = {
      width: 398,
      height: 120,
    };

    if (this.props.signature) {

      const imageDims = {
        width: this.props.signature.width,
        height: this.props.signature.height,
      };

      // flip dimensions if rotating vs saved wh
      if (degrees == 90 || degrees == 270) {
        imageDims.height = this.props.signature.width;
        imageDims.width = this.props.signature.height;
      }

      style = this.scaleSignatureToFit(imageDims, previewAreaDimensions);

    }

    style.backgroundImage = `url(${url})`;
    style.backgroundSize = '100%';

    if (!this.uploadSignaturePreviewImage) {
      this.uploadSignaturePreviewImage = React.createRef();
    }
    return (
      <div className='m-sign-modal--signature-edit-area--preview--image'
        ref={this.uploadSignaturePreviewImage}
        style={style} />
    );
  },

  rotateSignature() {
    this.props.onRotate(this.props.signature);

    this.incrementDegrees();
  },

  // Reset to 0 if we come full circle
  incrementDegrees() {
    let newValue;

    if ((this.state.degreesValue + CONSTANTS.ROTATE_DEGREES_AMOUNT) >= 360) {
      newValue = 0;
    } else {
      newValue = this.state.degreesValue + CONSTANTS.ROTATE_DEGREES_AMOUNT;
    }

    this.setState({
      degreesValue: newValue,
    });

  },

  adjustContrast(event) {
    this.setState({
      contrastValue: event.target.value,
    });
  },

  setCropBounds(cropRect) {

    const imageBounds = this.uploadSignaturePreviewImage.current.getBoundingClientRect();

    const preScaledCrop = {
      x: cropRect.left - imageBounds.left,
      y: cropRect.top - imageBounds.top,
      width: cropRect.width,
      height: cropRect.height,
    };

    if (preScaledCrop.width > 0 && preScaledCrop.height > 0) {

      const originalImageBounds = { width: this.props.signature.width, height: this.props.signature.height };

      const scaledParams = this.scaleCrop(imageBounds, originalImageBounds, preScaledCrop);

      this.setState({
        isCropped: true,
        cropX: scaledParams.x,
        cropY: scaledParams.y,
        cropWidth: scaledParams.width,
        cropHeight: scaledParams.height,
      });
    } else {
      this.setState({
        isCropped: false,
      });
    }

  },

  scaleCrop(imageScaledSize, imageOriginalSize, cropSize) {

    const scaledCropParams = {};

    const scaleY = parseFloat(imageOriginalSize.height / imageScaledSize.height);
    const scaleX = parseFloat(imageOriginalSize.width / imageScaledSize.width);
    let scaledPositionX = 0;
    let scaledPositionY = 0;
    let scaledWidth = imageOriginalSize.width;
    let scaledHeight = imageOriginalSize.height;

    scaledPositionX = parseInt(scaleX * cropSize.x, 10);
    scaledPositionY = parseInt(scaleY * cropSize.y, 10);
    scaledWidth = parseInt(scaleX * cropSize.width, 10);
    scaledHeight = parseInt(scaleY * cropSize.height, 10);

    if (scaledWidth > imageOriginalSize.width) {
      scaledWidth = imageOriginalSize.width;
    }

    if (scaledWidth + scaledPositionX > imageOriginalSize.width) {
      scaledWidth = imageOriginalSize.width - scaledPositionX;
    }

    if (scaledHeight > imageOriginalSize.height) {
      scaledHeight = imageOriginalSize.height;
    }

    if (scaledHeight + scaledPositionY > imageOriginalSize.height) {
      scaledHeight = imageOriginalSize.height - scaledPositionY;
    }

    scaledCropParams.x = scaledPositionX;
    scaledCropParams.y = scaledPositionY;
    scaledCropParams.width = scaledWidth;
    scaledCropParams.height = scaledHeight;

    return scaledCropParams;
  },


  //  ----  RENDERING  ----------------------------------

  renderControls() {

    const type = this.props.type;

    const contrastControl = (
      <div className='m-sign-modal--signature-edit-area--controls--contrast'>
        <input type='range'
          min={CONSTANTS.CONTRAST_MIN_THRESHOLD}
          max={CONSTANTS.CONTRAST_MAX_THRESHOLD}
          step={CONSTANTS.CONTRAST_STEP_AMOUNT}
          defaultValue={this.getContrastMidValue()}
          onMouseUp={this.adjustContrast} />
        <div>
          <FormattedMessage
            id='signModal.signatureEditArea.controls.contrast.labelText' />
        </div>
      </div>
    );

    const rotationControl = (
      <div className='m-sign-modal--signature-edit-area--controls--rotate'
        onClick={this.rotateSignature}
      >
        <ImageComponent src={require('./sigcreation_modal_rotate_clockwise_@2x.png')} />
        <div>
          <FormattedMessage
            id='signModal.signatureEditArea.controls.rotate.labelText'
            values={{ type }} />
        </div>
      </div>
    );

    return (
      <div className='m-sign-modal--signature-edit-area--controls'>
        { contrastControl }
        { rotationControl }
      </div>
    );
  },

  render() {

    const type = this.props.type;

    const instructions = (
      <div className='m-sign-modal--signature-edit-area--instructions'>
        <FormattedMessage
          id='signModal.signatureEditArea.instructions'
          values={{ type }} />
      </div>
    );

    const previewAndCrop = (
      <Croppable
        {...this.props}
        restrictToParent={true}
        callback={this.setCropBounds}
        containerClassName='m-sign-modal--signature-edit-area--preview'
      >
        { this.renderSignatureImage() }
      </Croppable>
    );

    return (
      <div className='m-sign-modal--signature-edit-area'>
        { instructions }
        { previewAndCrop }
        { this.renderControls() }
      </div>
    );
  },
});

export default SignatureEditArea;
