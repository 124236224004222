/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
import './index.scss';
import React from 'react';
import DocumentUI from './document-ui';
import ColorPickerUI from './color-picker-ui';

class WhiteLabelingApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: '#000000',
      blinkingComponent: 'DocumentHeader',
    };
  }

  handleChange(color) {
    this.setColor(`${color.hex}`);
  }

  setColor(color) {
    this.setState({ selectedColor: color });
  }

  changeBlinkingComponent(component) {
    if (component === 'DocumentHeader') {
      this.setState({ blinkingComponent: 'ColorPickerUI' });
    } else {
      this.setState({ blinkingComponent: null });
    }
  }

  render() {
    const props = {
      handleChange: this.handleChange.bind(this),
      setColor: this.setColor.bind(this),
      changeBlinkingComponent: this.changeBlinkingComponent.bind(this),
      ...this.state,
    };
    return <div className='m-white-labeling-app'>
      <img
        src={require('./assets/white_labeling_social_media.gif')}
        aria-hidden={true}
        className='white-labeling-gif' />
      <DocumentUI {...props} />
      <ColorPickerUI {...props} />
    </div>;
  }
}

module.exports = WhiteLabelingApp;
