/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
/**
 * DropboxSuccessModal
 * Adds a loading stage to the HelloModal with updating a button with a link
 */

import './dropbox-success-modal.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import superagent from 'superagent';
import HelloModal from 'common/components/hello-modal';
import HfReactHelper from 'common/utils/hf-react-helper';
import loadingImg from 'images/v2/common/loader_@2x.gif';

class DropboxSuccessModal extends Component {
  static propTypes = {
    pollingUrl: PropTypes.string.isRequired,
    siteCode: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    loadingTitle: PropTypes.string,
    content: PropTypes.string,
    moreContent: PropTypes.string,
    contentLabel: PropTypes.string,
    icon: PropTypes.string,
    showPricingLink: PropTypes.bool,
  };

  pollTimeout = null;

  constructor(...args) {
    super(...args);
    // Set the polling state based on whether or not we have pollingUrl
    this.state = {
      isPolling: this.props.pollingUrl.length > 1,
      showModal: true,
      errorMsg: null,
      pollIterations: 0,
      viewFileUrl: null,
    };
  }

  /**
   * Polls for a view_file_url from the specified url.
   */
  poll = () => {
    if (!this.state.showModal) {
      // Stop polling if the user has closed the modal
      return;
    }
    // Prepare the url
    const url = HfReactHelper.urlHelper(
      this.props.pollingUrl,
      this.props.siteCode,
    );

    // Make sure we don't poll forever
    const iteration = this.state.pollIterations;
    if (iteration > 20) {
      this.setState({ isPolling: false, errorMsg: 'It looks like there was a problem with the file. Please contact support@hellosign.com if the problem persists.' });
      return;
    }

    // Make a request to the polling url to get the view_file_url if ready
    superagent
      .get(url)
      .end((err, res) => {
        if (res) {
          const data = JSON.parse(res.text);
          if (data.view_file_url) {
            this.setState({ isPolling: false, viewFileUrl: data.view_file_url });
          } else if (data.error_msg) {
            this.setState({ isPolling: false, errorMsg: data.error_msg });
          } else {
            this.setState({ pollIterations: (iteration + 1) });
            this.pollTimeout = setTimeout(this.poll, 2000);
          }
        }
      });
  };

  getLoader() {
    return (<img src={loadingImg} className="hello-modal__loading-img" />);
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  getBtnLink() {
    return this.state.viewFileUrl ? this.state.viewFileUrl : 'https://www.dropbox.com/';
  }

  getButtons() {
    if (this.state.isPolling) {
      return null;
    } else {
      const btnLink = HfReactHelper.isHelloFax()
        ? this.props.buttonLink : this.getBtnLink();
      const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
      const pricingUrl = HfReactHelper.urlHelper('info/pricing', site);

      const actionBtn = {
        type: 'dropbox',
        text: 'Back to Dropbox',
        buttonLink: btnLink,
      };

      const plansPricingBtn = {
        type: 'secondary',
        buttonLink: pricingUrl,
        text: 'View plans and pricing',
      };

      const cancelBtn = {
        type: 'secondary',
        buttonLink: '',
        text: 'Close',
        onClick: this.handleCloseModal,
      };

      if (this.props.showPricingLink) {
        return [cancelBtn, plansPricingBtn, actionBtn];
      } else {
        return [cancelBtn, actionBtn];
      }
    }
  }

  // Start polling
  componentWillMount() {
    if (this.props.pollingUrl) {
      this.poll();
    }
  }

  componentWillUnmount() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout);
    }
  }

  render() {
    let content;
    let title;
    let icon;
    let moreContent;
    if (this.state.errorMsg) {
      title = 'Aww, there was a problem.';
      content = this.state.errorMsg;
    } else {
      content = this.state.isPolling ? this.getLoader() : this.props.content;
      moreContent = this.state.isPolling ? null : this.props.moreContent;
      title = this.state.isPolling ? this.props.loadingTitle : this.props.contentLabel;
      icon = this.state.isPolling ? null : HfReactHelper.imgHelper(this.props.icon);
    }
    const buttons = this.getButtons();
    const wrapperClassName = this.state.isPolling ? 'dropbox-success-modal__loading-content-wrapper' : 'dropbox-success-modal__content-wrapper';

    return (
      <HelloModal
        {...this.props}
        isOpen={this.state.showModal}
        onRequestClose={this.handleCloseModal}
        contentLabel={title}
        buttons={buttons}
      >
        <div className={wrapperClassName}>
          <p className="dropbox-success-modal__icon-wrap"><img className="dropbox-success-modal__icon" src={icon} /></p>
          <p className="dropbox-success-modal__content_padding">{content}</p>
          <p>{moreContent}</p>
        </div>
      </HelloModal>
    );
  }
}

module.exports = DropboxSuccessModal;
