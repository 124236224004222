/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  camelcase,
  global-require,
  react/no-string-refs
*/
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'common/components/image';
import Dropzone from 'react-dropzone';
import createReactClass from 'create-react-class';
import { FormattedMessage } from 'react-intl';
import ActionBar from './action-bar';
import CONSTANTS from './constants';
import SignatureEditArea from './signature-edit-area';

import {
  CommonTabProps as CommonTabPropTypes,
  GetSignatureUrl,
  OnSignatureData,
  SignatureType,
} from './prop-types';


const UploadTab = createReactClass({

  propTypes: {
    ...CommonTabPropTypes,
    getSignatureUrl: GetSignatureUrl,
    isUploading: PropTypes.bool,
    onRotateSignature: PropTypes.func.isRequired,
    onSignatureData: OnSignatureData,
    onUpload: PropTypes.func.isRequired,
    signature: SignatureType,
  },


  //  ----  LIFECYCLE  ----------------------------------

  getInitialState() {
    return {
      isSignature: (this.props.type === CONSTANTS.TYPE_SIGNATURE),
      isInitials: (this.props.type === CONSTANTS.TYPE_INITIALS),
      isSpinnerShown: false,
      isEditAreaShown: false,
      signature: null,
    };
  },

  //  ----  BEHAVIOR  -----------------------------------

  insertSignature(insertEverywhere) {

    const uploadEditArea = this.uploadEditArea.current;
    let signatureData = {
    };
    const isCropped = uploadEditArea.state.isCropped;

    if (isCropped) {
      signatureData = {
        is_cropped: isCropped,
        x: uploadEditArea.state.cropX,
        y: uploadEditArea.state.cropY,
        width: uploadEditArea.state.cropWidth,
        height: uploadEditArea.state.cropHeight,
      };
    } else {
      signatureData.width = this.props.signature.width;
      signatureData.height = this.props.signature.height;
    }

    signatureData.guid = this.props.signature.guid;
    signatureData.type_code = this.props.signature.type;
    signatureData.create_type_code = CONSTANTS.SIGNATURE_TYPE_UPLOAD;
    signatureData.threshold = uploadEditArea.state.contrastValue;
    signatureData.degrees = uploadEditArea.state.degreesValue;

    return this.props.onSignatureData(signatureData, insertEverywhere);
  },


  //  ----  HELPERS  ------------------------------------

  async onDrop(files) {

    const file = files[0]; // We only accept one

    const has_errors = !this.checkTypeAndSize(file);

    if (!has_errors) {
      const type = this.state.isSignature ? CONSTANTS.TYPE_SIGNATURE : CONSTANTS.TYPE_INITIALS;
      return this.props.onUpload(file, type);
    }
  },

  showEditArea() {
    this.setState({
      isEditAreaShown: true,
    });
  },

  hideEditArea() {
    this.setState({
      isEditAreaShown: false,
    });
  },

  showSpinner() {
    this.setState({
      isSpinnerShown: true,
    });
  },

  hideSpinner() {
    this.setState({
      isSpinnerShown: false,
    });
  },

  checkTypeAndSize(file) {
    return this.checkFileExtension(file) && this.checkFileSize(file);
  },

  checkFileExtension(file) {
    if (file.type && CONSTANTS.ACCEPTED_UPLOAD_TYPES.indexOf(file.type) === -1) {

      const errorMessage = `${this.props.intl.formatMessage({ id: 'signModal.upload.error.fileType' }, { fileType: file.type })} ${
        this.props.intl.formatMessage({ id: 'signModal.upload.instructions.fileFormats' })}`;

      this.props.onError(new Error(errorMessage));
      return false;
    }
    return true;
  },

  checkFileSize(file) {
    if (file.size && file.size > CONSTANTS.MAX_UPLOAD_SIZE) {

      const formattedSize = `${Math.round(file.size / 1000000)}MB`;

      const errorMessage = `${this.props.intl.formatMessage({ id: 'signModal.upload.error.fileSize' }, { fileSize: formattedSize })} ${
        this.props.intl.formatMessage({ id: 'signModal.upload.instructions.fileSize' })}`;

      this.props.onError(new Error(errorMessage));
      return false;
    }
    return true;
  },

  //  ----  RENDERING  ----------------------------------

  renderUploadArea() {

    const show = this.props.signature ? { display: 'none' } : {};
    const btnText = this.props.intl.formatMessage({ id: 'signModal.upload.button.text' });

    const uploadButton = (
      <Dropzone onDrop={this.onDrop}
        multiple={false}
        className='whitelabel-primary-button dropzone'
        style={{
          width: 60 + btnText.length * 10,
          height: 40,
        }}
        data-qa-ref="signing-modal--image-upload-button"
      >
        <div
          data-qa-ref='signing-modal--image-file'
        >
          <Image src={require('./upload_icon@2x.png')} />
          <FormattedMessage id='signModal.upload.button.text' />
        </div>
      </Dropzone>
    );


    return (
      <div className='m-sign-modal--tabs--upload--fileupload' style={show}>

        <span className='m-sign-modal--tabs--upload--header-text'>
          <FormattedMessage
            id='signModal.upload.instructions.header'
            values={{ type: this.props.type }} />
        </span>

        { uploadButton }

        <span className='m-sign-modal--tabs--upload--file-formats-text'>
          <FormattedMessage id='signModal.upload.instructions.fileSize' />
          <br />
          <FormattedMessage id='signModal.upload.instructions.fileFormats' />
        </span>

      </div>
    );
  },

  renderEditArea() {
    const {
      getSignatureUrl,
      intl,
      onRotateSignature,
      signature,
      type,
    } = this.props;

    const show = !signature ? { display: 'none' } : {};

    if (!this.uploadEditArea) {
      this.uploadEditArea = React.createRef();
    }
    return (
      <div style={show}>

        <SignatureEditArea
          getSignatureUrl={getSignatureUrl}
          intl={intl}
          onRotate={onRotateSignature}
          ref={this.uploadEditArea}
          signature={signature}
          type={type} />

      </div>
    );
  },

  render() {
    const { actionBarProps, isUploading } = this.props;

    let spinner;

    const inputClasses = {
      'm-sign-modal--tabs--upload--input': true,
    };
    const classes = {
      'm-sign-modal--tabs--upload': true,
    };
    const containerClasses = {
      'm-sign-modal--tabs--tab': true,
      'is-selected': this.props.isSelected,
    };

    containerClasses[CONSTANTS.TAB_UPLOAD] = true;

    if (isUploading) {
      spinner = (
        <img src={require('./loading-spinner-16.gif')} className='m-sign-modal--tabs--upload--output--spinner' />
      );
    }

    return (
      <div className={cx(containerClasses)}>

        <span className='m-sign-modal--tabs--tab-title'>
          <FormattedMessage
            id='signModal.upload.title'
            values={{ type: this.props.type }} />
        </span>

        <div className={cx(classes)}>
          <div className={cx(inputClasses)}>
            <div className='m-sign-modal--tabs--content' ref={this.props.captureTabContentRef}>
              { this.renderUploadArea() }
              { spinner }
              { this.renderEditArea() }
            </div>
          </div>
        </div>

        <ActionBar
          {...actionBarProps}
          disableButtons={!this.props.signature}
          onInsert={this.insertSignature}
          values={{ type: this.props.type }} />
      </div>
    );
  },
});

module.exports = UploadTab;
