/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  no-redeclare,
  no-var,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Button from '../../../common/components/button';

module.exports = createReactClass({
  displayName: 'SEO Signup or Login Button',

  propTypes: {
    isUserLoggedIn: PropTypes.bool.isRequired,
    homeUrl: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
  },

  renderButton() {
    const defaultButtonClass = {
      buttonTextColor: 'white',
      buttonColor: 'goldenrod',
      buttonBorderColor: 'goldenrod',
      buttonHoverColor: 'white',
      buttonHoverTextColor: 'goldenrod',
    };
    const buttonContent = {
      buttonText: this.props.buttonText,
      buttonLink: this.props.homeUrl,
    };
    return <Button {...defaultButtonClass} {...buttonContent} {...this.props} />;
  },

  renderSignupButton() {
    var linkClassName = 'm-button bg-goldenrod hc-goldenrod border-1-goldenrod'
        + ' hbg-white c-white progress-button state-loading m-marketing-page--button';
    var linkClassName = this.props.buttonClass || linkClassName;
    const buttonContent = {
      buttonText: this.props.linkText,
      buttonLink: this.props.signUpUrl,
    };
    return <Button className={linkClassName} {...buttonContent} {...this.props} />;
  },

  render() {
    let className;
    if (!this.props.isIntegration) {
      className = 'l-padding-t-10 l-padding-b-10 text-center m-marketing-page--try-it-free';
    }
    return <div className={className}>
      {this.props.isUserLoggedIn ? this.renderButton() : this.renderSignupButton()}
    </div>;
  },
});
