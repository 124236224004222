/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* global dataLayer */
/* eslint-disable
  import/no-extraneous-dependencies,
*/
import './plan-card.scss';
import React from 'react';
import Button from 'common/components/button';
import ContactUsButton from 'hellosign/components/pricing/contact-us-button';
import pushGoogleTagManagerEvent from 'common/utils/pushGoogleTagManagerEvent';
import PlanFeaturesCard from './plan-features-card';

const PLANS = {
  Pro: 'trial-version-pro-account',
  'Business 5': 'trial-version-small-business5',
  'Business 10': 'trial-version-small-business10',
};

const PLAN_ABBREVIATION = {
  Pro: 'pro',
  'Business 5': 'b5',
  'Business 10': 'b10',
};

function getPrice(plan, period) {
  if (plan.coupon) {
    if (period === 'Y') {
      return plan.coupon_annual_price;
    } else {
      return plan.coupon_monthly_price;
    }
  } else if (period === 'Y') {
    return plan.annual_price;
  } else {
    return plan.monthly_price;
  }
}

export default function PlanCard(props) {
  const plan = props.plan;
  const period = props.period || 'M';
  const features = plan.features;

  function reportAnalytics() {
    pushGoogleTagManagerEvent({
      category: 'contactus-form-start',
      action: window.location.pathname,
      label: '/enterprise-lp-contact-us',
    });

    dataLayer.push({
      event: 'modal_popup',
      pageName: '/enterprise-lp-contact-us',
      pageType: 'enterprise-lp-contact-us',
      virtualPagePath: '/enterprise-lp-contact-us',
      productPlan: 'enterprise',
      productType: 'hs',
      subscriptionPlan: null,
    });
  }

  let periodText;
  let price = getPrice(plan, period);
  if (period === 'M') {
    periodText = 'per month when paid monthly';
  } else {
    price /= 12;
    periodText = 'per month when paid annually';
  }
  price = Math.ceil(price);

  let preCouponPrice;
  if (plan.coupon) {
    const oldPrice = props.period === 'Y' ? Math.ceil(plan.annual_price / 12) : plan.monthly_price;

    preCouponPrice = (
      <div className='m-pricing-level'>
        <div className='m-pricing-level--body small-4 large-12 small-centered columns level-price'>
          <div className='dollar crossed'>$</div>
          <div className='price crossed'>{ oldPrice }</div>
        </div>
      </div>
    );
  }

  let details;
  if (plan.is_enterprise) {
    const buttonOptions = {
      category: 'contactus-form-start',
      action: 'create account - plan selected - hs',
      label: 'enterprise',
    };

    details = (
      <div className='plan-details'>
        <div className='plan-name'>
          { plan.name }
        </div>
        <div className='enterprise-plan-text'>
          <h1>Looking for more?</h1>
          <p>Our Sales team can help find the perfect plan for you.</p>
        </div>
        <ContactUsButton
          timePeriod={period}
          pricingSource={props.pricingSource}
          modalHeaderText='Contact Us for an Enterprise Plan'
          onSuccess={props.onEnterpriseSuccess}
          onClick={() => reportAnalytics()}
          buttonText='Contact Us'
          buttonAttrs={
            {
              'da-category': buttonOptions.category,
              'da-action': buttonOptions.action,
              'da-label': buttonOptions.label,
            }
          } />
      </div>
    );
  } else {
    const planChoiceParameters = {
      planId: plan.plan_id,
      timePeriod: period,
      price: getPrice(plan, period),
      planName: PLAN_ABBREVIATION[plan.name],
      category: 'signup',
      action: 'create account - plan selected - hs',
      label: PLAN_ABBREVIATION[plan.name],
    };

    const buttonAttrs = {
      'da-category': 'signup',
      'da-action': 'create account - plan selected - hs',
      'da-label': PLAN_ABBREVIATION[plan.name],
    };

    buttonAttrs['data-qa-ref'] = PLANS[plan.name];

    details = (
      <div className='plan-details'>
        <div className='plan-name'>
          { plan.name }
        </div>
        { preCouponPrice }
        <div className='price'>
          <span className='currency'>$</span>
          <span className='amount'>{ price }</span>
        </div>
        <div className='period'>
          { periodText }
        </div>
        <Button
          buttonText='START 30-DAY FREE TRIAL'
          onClick={() => props.onPlanChoice(planChoiceParameters)}
          buttonAttrs={buttonAttrs}
        >
        </Button>
      </div>
    );

    hellofaxJS.trackGAPageViewEvent('accountSignUp', null, period, plan.name, 'hs');
  }

  return (
    <div className='m-plan-card'>
      { details }
      <PlanFeaturesCard features={features}></PlanFeaturesCard>
    </div>
  );

}
