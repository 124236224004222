/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-restricted-syntax,
  react/no-string-refs
*/
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Input from './input';
import baseform from './base-form';

const ButtonTextSubmit = baseform.createFormClass({

  displayName: 'ButtonTextSubmit',

  propTypes: {
    actionButton: PropTypes.element.isRequired,
    initialValue: PropTypes.string,
    textPlaceholder: PropTypes.string,
    textClass: PropTypes.string,
    inputName: PropTypes.string,
    additionalFormData: PropTypes.object,
    validate: PropTypes.func,
    initialErrorMessage: PropTypes.string,
    errorClass: PropTypes.string,
    divClass: PropTypes.string,
    url: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    initiallyShowField: PropTypes.bool,
    tabOffset: PropTypes.number,
  },

  onSubmit(evt) {
    // always validate before acting
    evt.preventDefault();
    const isValid = this.doValidation();
    if (!isValid) {
      return false;
    }

    this.setState({
      isSending: true,
      inputError: false,
    });

    if (this.props.additionalFormData) {
      for (const key in this.props.additionalFormData) {
        const input = $('<input>')
          .attr('type', 'hidden')
          .attr('key', key)
          .attr('name', key)
          .val(this.props.additionalFormData[key]);
        $(document.forms[this.props.formName]).append($(input));
      }
    }

    document.forms[this.props.formName].submit();

    return false;
  },

  getInitialState() {
    return {
      isSending: false,
      inputError: false,
      errorMessage: this.props.initialErrorMessage,
    };
  },

  handleKeyDown(evt) {
    this.setState({ inputError: false, errorMessage: null });
    if (evt.keyCode == 13) {
      return this.onSubmit();
    }
  },

  handleTextInput(value) {
    this.setState({ text: value.trim() });
  },

  handleOnBlur() {
    if (typeof this.state.text !== 'undefined') {
      this.doValidation();
    }
  },

  doValidation() {
    // FIXME: should probably move validation down into the Input component
    if (this.props.validate) {
      const inputError = this.props.validate(this.state.text);
      if (inputError) {
        this.setState({ inputError: inputError !== false, errorMessage: inputError });
        return false;
      }
    }
    return true;
  },

  render() {

    const tabOffset = parseInt(this.props.tabOffset || 0, 10);

    let divClasses = {
      'm-button-submit': true,
      'is-active': true,
    };
    divClasses[this.props.divClass] = true;
    const standardInput = (
      <Input
        ref="textEntry"
        inputName={this.props.inputName}
        handleChange={this.handleTextInput}
        className={this.props.textClass}
        defaultValue={this.props.initialValue}
        placeholder={this.props.textPlaceholder}
        onBlur={this.handleOnBlur}
        onKeyDown={this.handleKeyDown}
        disabled={this.state.isSending}
        error={this.state.inputError}
        errorClass={this.props.errorClass}
        errorMessage={this.state.errorMessage}
        tabIndex={tabOffset} />
    );

    divClasses = cx(divClasses);

    const newBtnProps = {
      onClick: this.onSubmit,
      disabled: this.state.isSending,
    };
    if (this.state.isSending) {
      newBtnProps.buttonText = 'Sending';
    }
    const btn = React.cloneElement(this.props.actionButton, newBtnProps);

    return (
      <form name={this.props.formName} method="POST" action={this.props.url}>
        <div className={divClasses}>
          { this.renderCsrfField() }
          { standardInput }
          { btn }
          { this.props.children }
        </div>
      </form>
    );
  },
});
module.exports = ButtonTextSubmit;
