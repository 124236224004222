import React from 'react';

import Button from 'common/components/button';
import Advanced from './advanced';
import Basic from './basic';

import { withAppContext } from '../../contexts/app';

import './index.scss';

class Form extends React.PureComponent {

  onFormSubmit = (evt) => {
    evt.preventDefault();

    this.props.launchEmbedded();
  };

  onToggleAdvancedOptionsButtonPress = (_evt) => {
    this.props.appContext.actions.toggleAdvancedOptions();
  };

  onToggleCodeButtonPress = (_evt) => {
    this.props.appContext.actions.toggleCode();
  };

  render() {
    const {
      form,
      isGettingSampleDocument,
      showAdvancedOptions,
    } = this.props.appContext.state;

    return (
      <form className="embedded-testing-tool__form" onSubmit={this.onFormSubmit}>
        <Basic />
        <Advanced />
        <Button
          buttonText="Launch Embeddeded"
          buttonColor="cerulean"
          buttonHoverColor="black"
          buttonHoverTextColor="white"
          type="submit"
          marginless={true}
          disabled={isGettingSampleDocument || !form.clientID.length || !form.signURL.length} />
        <Button
          buttonText={`${showAdvancedOptions ? 'Hide' : 'Show'} advanced options`}
          buttonTextColor="ashen"
          buttonColor="white"
          buttonHoverColor="black"
          buttonHoverTextColor="white"
          type="button"
          onClick={this.onToggleAdvancedOptionsButtonPress} />
      </form>
    );
  }
}

export default withAppContext(Form);
