/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  eqeqeq,
  global-require,
  guard-for-in,
  max-len,
  no-continue,
  no-restricted-syntax,
  no-void,
  react/no-find-dom-node,
  react/prop-types
*/
import './signature-modal.scss';

import cx from 'classnames';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loader from 'signer/components/common/loader';
import DrawTab from './draw-tab';
import SavedTab from './saved-tab';
import ErrorTab from './error-tab';
import EmailTab from './email-tab'; // 'Use Smartphone'
import TypeInTab from './type-in-tab';
import UploadTab from './upload-tab';
import CONSTANTS from './constants';

import {
  GetSignatureUrl,
  SignatureType,
  Tab,
  Type,
} from './prop-types';


/**
 * UI Signature Modal
 *
 * This component is a presentational version of the signature modal.  It
 * expects all state management to be handled by a parent component.
 *
 * NOTE: This component assumes that it has been wrapped within an Intl
 * Provider _and_ a component injected with the imperative intl API.
 *
 * @example
 *
 * import { IntlProvider, injectIntl } from 'react-intl';
 *
 * // Wraps the UI signature modal and handles all the state logic
 * class MyStatefulSignatureModal extends React.Component {
 *      render() {
 *          return <SignatureModal
 *              //...props
 *          />
 *      }
 *
 * }
 * const IntlSignatureModal = injectIntl(MyStatefulSignatureModal);
 *
 * <IntlProvider locale='en-US' messages={messages}>
 *      <IntlSignatureModal />
 * </IntlProvider>
 */
class UiSignatureModal extends React.Component {

  constructor(...args) {
    super(...args);

    this.captureMenu = this.captureMenu.bind(this);
    this.captureActiveTab = this.captureActiveTab.bind(this);
    this.getTopOffset = this.getTopOffset.bind(this);
    this.detectDeviceOrientation = this.detectDeviceOrientation.bind(this);
    this.detectScreenSize = this.detectScreenSize.bind(this);
    this.adjustDimensions = this.adjustDimensions.bind(this);
    this.shouldShowTab = this.shouldShowTab.bind(this);
    this.errorOnContinue = this.errorOnContinue.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.onOrientationChange = this.onOrientationChange.bind(this);
    this.onResizeAfterOrientationChange = this.onResizeAfterOrientationChange.bind(this);

    this.state = {
      deviceOrientation: this.detectDeviceOrientation(),
      screenSize: this.detectScreenSize(),
    };
  }

  componentDidMount() {
    this.mounted = true;

    if (this.props.isMobile) {
      window.addEventListener('orientationchange', this.onOrientationChange);

      this.adjustOrientationAndSize();
    }
  }

  componentWillUnmount() {
    this.mounted = false;

    window.removeEventListener('orientationchange', this.onOrientationChange);
    window.removeEventListener('resize', this.onResizeAfterOrientationChange);
  }

  onOrientationChange() {
    window.addEventListener('resize', this.onResizeAfterOrientationChange);
  }

  onResizeAfterOrientationChange() {
    this.adjustOrientationAndSize();
    window.removeEventListener('resize', this.onResizeAfterOrientationChange);
  }

  adjustOrientationAndSize() {
    const orientation = this.detectDeviceOrientation();
    const screenSize = this.detectScreenSize();

    this.setState({
      deviceOrientation: orientation,
      screenSize,
    }, () => {
      this.adjustDimensions();
      this.forceUpdate();
    });
  }


  //  ----  HELPERS  ------------------------------------
  captureMenu(node) {
    this.menuRef = node;
  }

  captureActiveTab(node) {
    this.activeTabRef = node;
  }

  /**
   * Returns the bottom offset of the menu node:
   *
   * We use this calculation to display the `ActionBar` at the correct height
   * for embedded signing flow for Apple devices
   */
  getTopOffset() {
    // Note, React may provide `undefined` for ref callbacks if the nodes
    // have been unmounted, so we need to check for their existence before
    // trying to call DOM methods.
    if (this.props.isMobile && this.menuRef) {
      return this.menuRef.getBoundingClientRect().bottom;
    }
  }

  detectDeviceOrientation() {
    if (this.props.isMobile && window.screen) {
      if ((window.orientation !== void 0 && Math.abs(window.orientation) === 90)
                || (window.screen && window.screen.width > window.screen.height)) { // NOTE: Only used for in-browser mobile simulators because they don't emulate orientation changes correctly
        return CONSTANTS.ORIENTATION_LANDSCAPE;
      } else {
        return CONSTANTS.ORIENTATION_PORTRAIT;
      }
    } else {
      return CONSTANTS.ORIENTATION_LANDSCAPE;
    }
  }

  detectScreenSize() {
    if (window.screen) {
      const w = window.screen.width;
      if (w <= 690) {
        return CONSTANTS.SCREEN_SMALL;
      } else if (w > 690 && w <= 900) {
        return CONSTANTS.SCREEN_MEDIUM;
      }
    }
    return CONSTANTS.SCREEN_LARGE;
  }

  adjustDimensions() {
    if (window.screen && this.props.isMobile && this.state.screenSize === CONSTANTS.SCREEN_SMALL) {

      // NOTE: The height is must be at least 270px to prevent the action bar from running into the menu.
      // Also, in such case, the keyboard is most likely up, so the user can dismiss it to see the rest of the modal.

      const root = $(ReactDOM.findDOMNode(this));

      root.css({
        width: '100vw',
        height: '100vh',
        'min-height': '270px',
        top: 0,
        left: 0,
      });
    }
  }

  shouldShowTab(tabId) {
    const { enabledTabs } = this.props;

    // Block Upload and Smartphone (email) on mobile
    if (this.props.isMobile && (tabId === CONSTANTS.TAB_UPLOAD || tabId === CONSTANTS.TAB_EMAIL)) {
      return false;
    }

    // Never show error tab in menu
    if (tabId === CONSTANTS.TAB_ERROR) return false;

    // Show if true or not specified
    return this.props.selectedTab === tabId || enabledTabs.includes(tabId);
  }

  //  ----  BEHAVIOR  -----------------------------------

  errorOnContinue() {
    this.props.onTabSelect(CONSTANTS.TAB_SAVED);
  }


  //  ----  RENDERING  ----------------------------------

  renderMenu() {

    const menuItems = [];
    let classes;

    for (const k in CONSTANTS.TABS) {

      const tabId = CONSTANTS.TABS[k];
      if (!this.shouldShowTab(tabId)) {
        continue;
      }

      if ((this.props.isEmbedded && !this.props.isDropbox) && tabId === CONSTANTS.TAB_SAVED) {
        menuItems.push((
          <div key={`sign-modal-menuitem-${tabId}`}></div>
        ));
      } else {
        classes = {
          'm-sign-modal--menu--item': true,
          'is-compact': this.props.isMobile,
          'is-selected': (this.props.selectedTab === tabId),
          'whitelabel-signature-modal-tab': true,
        };

        classes[tabId] = true;

        menuItems.push((
          <div key={`sign-modal-menuitem-${tabId}`}
            className={cx(classes)}
            onClick={this.props.onTabSelect.bind(null, tabId)}
            data-qa-ref={`signing-modal--${tabId}`}
          >
            <span className='m-sign-modal--menu--item--icon'></span>
            <span className='m-sign-modal--menu--item--label'>
              <FormattedMessage id='signModal.menu.labelText'
                values={{ tabId: tabId + (tabId == CONSTANTS.TAB_SAVED ? this.props.type : '') }} />
            </span>
          </div>
        ));
      }
    }

    return (
      <div className='m-sign-modal--menu' ref={this.captureMenu}>
        { menuItems }
      </div>
    );
  }

  renderTabs() {
    const tabs = CONSTANTS.TABS.filter((id) => this.props.selectedTab === id)
      .map((id) => this.renderTab(id));
    return <div className='m-sign-modal--tabs'>{ tabs }</div>;
  }

  renderTab(tabId) {
    const {
      buttonText,
      canInsertEverywhere,
      emailedSignature,
      emailGuid,
      emailUploadAddress,
      error,
      getSignatureUrl,
      initialTypeInValue,
      intl,
      isCheckingEmailedSignatureStatus,
      isMobile,
      isUploadingSignature,
      onCheckEmailedSignatureStatus,
      onCheckNetworkHealth,
      onClose,
      onError,
      onInsert,
      onRotateSignature,
      onSavedSignatureRemove,
      onSavedSignatureSelect,
      onSignatureData,
      onUploadSignature,
      savedSignatures,
      selectedSavedSignature,
      selectedTab,
      type,
      uploadedSignature,
      isEmbedded,
    } = this.props;

    const { deviceOrientation, screenSize } = this.state;

    const topOffset = this.getTopOffset();
    const displayOrientation = isMobile
      ? CONSTANTS.ORIENTATION_PORTRAIT
      : CONSTANTS.ORIENTATION_LANDSCAPE;

    let tab;
    const key = `tab-${tabId}`;
    const isSelected = selectedTab === tabId;

    const commonActionBarProps = {
      buttonText,
      canInsertEverywhere,
      intl,
      isMobile,
      onClose,
      topOffset,
      type,
      isEmbedded,
      deviceOrientation,
    };

    const commonProps = {
      actionBarProps: commonActionBarProps,
      buttonText,
      canInsertEverywhere,

      // Provided to the Tab components so that they can report their
      // content to this component.  The tabs should apply this as a ref
      // callback on the div that wraps the content, e.g.
      // <div ref={this.props.captureTabContentRef}>
      captureTabContentRef: this.captureActiveTab,
      deviceOrientation,
      emailGuid,
      emailUploadAddress,
      isMobile,
      intl,
      isSelected,
      onClose,
      onError,
      screenSize,
      type,
    };

    switch (tabId) {

      case CONSTANTS.TAB_SAVED:
        tab = (
          <SavedTab
            {...commonProps}
            key={key}
            getSignatureUrl={getSignatureUrl}
            onInsert={onInsert}
            onRemove={onSavedSignatureRemove}
            onSelect={onSavedSignatureSelect}
            selectedSignature={selectedSavedSignature}
            signatures={savedSignatures} />
        );
        break;

      case CONSTANTS.TAB_DRAW:
        tab = (
          <DrawTab
            {...commonProps}
            displayOrientation={displayOrientation}
            key={key}
            onSignatureData={onSignatureData} />
        );
        break;

      case CONSTANTS.TAB_TYPE:
        tab = (
          <TypeInTab
            {...commonProps}
            key={key}
            initialValue={initialTypeInValue}
            onCheckNetworkHealth={onCheckNetworkHealth}
            onSignatureData={onSignatureData} />
        );
        break;

      case CONSTANTS.TAB_UPLOAD:
        tab = (
          <UploadTab
            {...commonProps}
            getSignatureUrl={getSignatureUrl}
            key={key}
            isUploading={isUploadingSignature}
            onRotateSignature={onRotateSignature}
            onSignatureData={onSignatureData}
            onUpload={onUploadSignature}
            signature={uploadedSignature} />
        );
        break;

      case CONSTANTS.TAB_EMAIL:
        tab = (
          <EmailTab
            {...commonProps}
            emailGuid={emailGuid}
            key={key}
            getSignatureUrl={getSignatureUrl}
            isCheckingStatus={isCheckingEmailedSignatureStatus}
            onCheckStatus={onCheckEmailedSignatureStatus}
            onRotateSignature={onRotateSignature}
            onSignatureData={onSignatureData}
            signature={emailedSignature}
            uploadAddress={emailUploadAddress} />
        );
        break;

      case CONSTANTS.TAB_ERROR:
        tab = (
          <ErrorTab
            key={key}
            {...commonProps}
            error={error}
            onContinue={this.errorOnContinue} />
        );
        break;

      default:
        throw new Error(`Invalid tab id: ${tabId}`);

    }

    return tab;
  }

  render() {
    const { loading: isLoading, loaderContent, onClose } = this.props;
    const classes = {
      'm-sign-modal': true,
      'is-mobile': this.props.isMobile,
      'is-desktop': !this.props.isMobile,
      'can-insert-everywhere': this.props.canInsertEverywhere,
    };
    classes[`screen-${this.state.screenSize}`] = true;
    classes[`orientation-${this.state.deviceOrientation}`] = true;

    const loader = (
      <div className='m-sign-modal--loader'>
        <Loader className='m-sign-modal--loader--image whitelabel-dot-loader' />
        <span className='m-sign-modal--loader--text'>{ loaderContent }</span>
      </div>
    );

    const closeBox = (
      <div className='m-sign-modal--close-box' onClick={onClose} data-qa-ref="m-sign-modal--close-box" >
        <img src={require('./x@2x.png')} />
      </div>
    );

    // Render the signature modal
    return (
      <div className={cx(classes)}>
        { isLoading ? loader : null }
        { isLoading ? null : this.renderMenu() }
        { isLoading || this.props.isMobile ? null : closeBox}
        { isLoading ? null : this.renderTabs() }
      </div>
    );
  }
}

UiSignatureModal.propTypes = {

  /**
        * Text to be displayed on the CTA (only when canInsertEverywhere=false)
        */
  buttonText: PropTypes.string,

  /**
        * If true, there will be a button displayed to insert everywhere
        */
  canInsertEverywhere: PropTypes.bool,

  /**
        * The emailed signature that's been sent in by the user.
        */
  emailedSignature: SignatureType,

  /**
        * For signature upload
        */
  emailGuid: PropTypes.string,
  emailUploadAddress: PropTypes.string,

  enabledTabs: PropTypes.arrayOf(Tab),

  /**
        * Provided to the error tab if present.
        */
  error: PropTypes.shape({
    message: PropTypes.string,
  }),

  getSignatureUrl: GetSignatureUrl,

  /**
     * Provided to the TypeInTab
     */
  initialTypeInValue: PropTypes.string.isRequired,


  /**
     * Intl object provided by react-intl
     */
  intl: PropTypes.object.isRequired,

  /**
        * If true, it will signal to the email tab that we're awaiting an
        * emailed signature.
        */
  isCheckingEmailedSignatureStatus: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  isMobile: PropTypes.bool,

  /**
        * If true, it will signal to the upload tab that we're awaiting a
        * signature upload.
        */
  isUploadingSignature: PropTypes.bool,

  /**
        * If provided and the `loading` prop is true, it will be used as the
        * content for the loading section.  Can be used to pass custom copy,
        * e.g. "Creating your signature..."
        */
  loaderContent: PropTypes.element,
  loading: PropTypes.bool,

  /**
        * This will kick off the API poll to check for emailed signatures.
        */
  onCheckEmailedSignatureStatus: PropTypes.func,

  /**
        * Called to close the modal
        */
  onClose: PropTypes.func,

  /**
        * Called with the finalized created signature from one of the tabs.
        */
  onInsert: PropTypes.func,

  /**
        * This prop should be used with caution. It's expected to take a
        * callback and somehow check if we're currently online.  If so, it
        * calls the callback.  It's used with the Webfont Loader in the type in
        * tab because we load the webfonts because they load using their own
        * network layer logic.
        */
  onCheckNetworkHealth: PropTypes.func,

  onRotateSignature: PropTypes.func,

  /**
        * Called with the siganture data from one of the tabs.  Each type will
        * have a "create_type_code" indicating which tab the data came from.
        * Use this to create or edit a signature.
        */
  onSignatureData: PropTypes.func.isRequired,

  /**
        * Called to remove a saved signature from the Saved Tab
        */
  onSavedSignatureRemove: PropTypes.func.isRequired,

  /**
        * Called to select a saved signature from the saved tab carousel
        */
  onSavedSignatureSelect: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  onUploadSignature: PropTypes.func,

  /**
        * Provided to the saved tab to display as a carousel or list
        */
  savedSignatures: PropTypes.arrayOf(SignatureType).isRequired,

  /**
        * The signature in the saved tab that's currently selected
        */
  selectedSavedSignature: SignatureType,

  /**
        * The currently selected tab
        */
  selectedTab: PropTypes.oneOf(CONSTANTS.TABS),
  type: Type,
  uploadedSignature: SignatureType,
};

UiSignatureModal.defaultProps = {
  enabledTabs: CONSTANTS.TABS.filter((tab) => tab !== CONSTANTS.TAB_ERROR),
  type: CONSTANTS.TYPE_SIGNATURE,
  isMobile: true,
  canInsertEverywhere: true,
  isEmbedded: false,
  isDropbox: false,
};


export default UiSignatureModal;
