/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  eqeqeq,
  react/jsx-no-target-blank
*/
import React, { Component } from 'react';
import Button from 'common/components/button';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import './index.scss';

const dateFilter = (input) => input
  .replace('/', '-')
  .replace(/[^\d-]/g, '');

const validDate = /\d{4}-\d{2}-\d{2}/;

const messages = defineMessages({
  all: { id: '', description: 'webapp, document page search modal dd all', defaultMessage: 'All' },
  allTeamDocs: { id: '', description: 'webapp, document page search modal dd allTeamDocs', defaultMessage: 'All Team documents' },
  completed: { id: '', description: 'webapp, document page search modal dd completed', defaultMessage: 'Completed' },
  declined: { id: '', description: 'webapp, document page search modal dd declined', defaultMessage: 'Declined' },
  awaitingMySignature: { id: '', description: 'webapp, document page search modal dd awaitingMySignature', defaultMessage: 'Awaiting my signature' },
  outForSignature: { id: '', description: 'webapp, document page search modal dd outForSignature', defaultMessage: 'Out for signature' },
  draft: { id: '', description: 'webapp, document page search modal dd draft', defaultMessage: 'Draft' },
  template: { id: '', description: 'webapp, document page search modal dd template', defaultMessage: 'Template' },
  templateLink: { id: '', description: 'webapp, document page search modal dd templateLink', defaultMessage: 'Template link' },
  searchDD: { id: '', description: 'webapp, document page search modal dd search', defaultMessage: 'Search' },
  createdFrom: { id: '', description: 'webapp, document page search modal dd createdFrom', defaultMessage: '2012-01-31' },
  createdTo: { id: '', description: 'webapp, document page search modal dd createdTo', defaultMessage: '2018-01-15' },
  status: { id: '', description: 'webapp, document page search modal dd status', defaultMessage: 'Status' },
  type: { id: '', description: 'webapp, document page search modal dd type', defaultMessage: 'Type' },
});

class AdvancedForm extends Component {
    state = this.getEmptyState();


    getEmptyState() {
      return {
        filter: 'all',
        signer: '',
        sender: '',
        createdFrom: '',
        createdTo: '',
        contains: '',
        owner: '',
        testMode: false,
      };
    }

    handleClear = () => this.setState(this.getEmptyState());

    handleFilter = (event) => this.setState({ filter: event.target.value });

    handleSigner = (event) => this.setState({ signer: event.target.value });

    handleSender = (event) => this.setState({ sender: event.target.value });

    handleContains = (event) => this.setState({ contains: event.target.value });

    handleOwner = (event) => this.setState({ owner: event.target.value });

    handleTestMode = (event) => this.setState({ testMode: event.target.checked });

    handleCreatedFrom = (event) => this.setState({
      createdFrom: dateFilter(event.target.value),
    });

    handleCreatedTo = (event) => this.setState({
      createdTo: dateFilter(event.target.value),
    });

    handleSearch = () => {
      const data = {
        // Don't send "all" as it's not a valid filter
        filter: (this.state.filter !== 'all' ? this.state.filter : null),
        signer: this.state.signer,
        sender: this.state.sender,

        ...(this.validDates() ? {
          created: `[${this.state.createdFrom} TO ${this.state.createdTo}]`,
        } : {}),

        contains: this.state.contains,
        owner: this.state.owner,
        testMode: this.state.testMode,
      };


      this.props.onSearch(data);
    };

    onSubmit = (event) => {
      event.preventDefault();
      this.handleSearch();
    };

    validDates() {
      if (this.state.createdFrom != '' || this.state.createdTo != '') {
        return validDate.test(this.state.createdFrom) && validDate.test(this.state.createdTo);
      }
    }

    renderFilters() {
      if (this.props.siteCode === 'F') {
        return (
          <React.Fragment>
            <option value="all">All</option>
            {(this.props.includeAllTeam === true
              ? <option value="all_team">All Team documents</option>
              : null)}
            <option value="sent">Sent</option>
            <option value="received">Received</option>
            <option value="draft">Drafts</option>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <option value="all">{this.props.intl.formatMessage(messages.all)}</option>
          <optgroup label={this.props.intl.formatMessage(messages.status)}>
            {(this.props.includeAllTeam === true
              ? <option value="all_team">{this.props.intl.formatMessage(messages.allTeamDocs)}</option>
              : null)}
            <option value="completed">{this.props.intl.formatMessage(messages.completed)}</option>
            <option value="declined">{this.props.intl.formatMessage(messages.declined)}</option>
            <option value="awaiting_my_signature">{this.props.intl.formatMessage(messages.awaitingMySignature)}</option>
            <option value="out_for_signature">{this.props.intl.formatMessage(messages.outForSignature)}</option>
            <option value="draft">{this.props.intl.formatMessage(messages.draft)}</option>
          </optgroup>
          <optgroup label={this.props.intl.formatMessage(messages.type)}>
            <option value="template">{this.props.intl.formatMessage(messages.template)}</option>
            <option value="template_link">{this.props.intl.formatMessage(messages.templateLink)}</option>
          </optgroup>
        </React.Fragment>
      );
    }

    render() {

      let submitDisabled = false;
      if (this.state.createdFrom != '' || this.state.createdTo != '') {
        submitDisabled = !this.validDates();
      }

      return <div className="advanced-search__popover">
        <form onSubmit={this.onSubmit}>
          <table className="advanced-search__inputs">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="advanced-search__type-status"><FormattedMessage id="" description="webapp, document page search modal type/status" defaultMessage="Type/Status" /></label>
                </td>
                <td>
                  <select id="advanced-search__type-status" value={this.state.filter} onChange={this.handleFilter}>
                    {this.renderFilters()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="advanced-search__signer"><FormattedMessage id="" description="webapp, document page search modal to" defaultMessage="To" /></label>
                </td>
                <td>
                  <input
                    type="text"
                    onChange={this.handleSigner}
                    value={this.state.signer}
                    id="advanced-search__signer" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="advanced-search__sender"><FormattedMessage id="" description="webapp, document page search modal From" defaultMessage="From" /></label>
                </td>
                <td>
                  <input
                    type="text"
                    onChange={this.handleSender}
                    value={this.state.sender}
                    id="advanced-search__signer" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="advanced-search__date-from">
                    <FormattedMessage id="" description="webapp, document page search modal Creation date" defaultMessage="Creation date" />
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    className="advanced-search__date"
                    placeholder={this.props.intl.formatMessage(messages.createdFrom)}
                    onChange={this.handleCreatedFrom}
                    value={this.state.createdFrom}
                    maxLength={10}
                    id="advanced-search__date-from" />
                  {' to '}
                  <input
                    type="text"
                    className="advanced-search__date"
                    placeholder={this.props.intl.formatMessage(messages.createdTo)}
                    onChange={this.handleCreatedTo}
                    value={this.state.createdTo}
                    maxLength={10} />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="advanced-search__contains"><FormattedMessage id="" description="webapp, document page search modal Contains" defaultMessage="Contains" /></label>
                </td>
                <td>
                  <input
                    type="text"
                    onChange={this.handleContains}
                    value={this.state.contains}
                    id="advanced-search__contains" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="advanced-search__template-owner"><FormattedMessage id="" description="webapp, document page search modal Template owner" defaultMessage="Template owner" /></label>
                </td>
                <td>
                  <input
                    type="text"
                    onChange={this.handleOwner}
                    value={this.state.owner}
                    id="advanced-search__template-owner" />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <label htmlFor="advanced-search__test-mode" className="advanced-search__test-mode-label">
                    <input type="checkbox" onChange={this.handleTestMode} checked={this.state.testMode} id="advanced-search__test-mode" />
                    <FormattedMessage id="" description="webapp, document page search modal Test mode (API)" defaultMessage="Test mode (API)" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="advanced-search__buttons">
            <div className="advanced-search__button-group advanced-search__button-group--left">
              <a href="https://faq.hellosign.com/hc/en-us/articles/115014893868" target="_blank"><FormattedMessage id="" description="webapp, document page search modal Learn more" defaultMessage="Learn more" /></a>
            </div>
            <div className="advanced-search__button-group advanced-search__button-group--right">
              <a onClick={this.handleClear}><FormattedMessage id="" description="webapp, document page search modal Clear" defaultMessage="Clear" /></a>
              <Button
                className="advanced-search__button-group__button"
                onClick={this.handleSearch}
                type="submit"
                buttonColor="cerulean"
                disabled={submitDisabled}
                buttonText={this.props.intl.formatMessage(messages.searchDD)} />
            </div>
          </div>
        </form>
      </div>;
    }

}

export default injectIntl(AdvancedForm);
