/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  prefer-const,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import createReactClass from 'create-react-class';
import HfReactHelper from 'common/utils/hf-react-helper';


const ToggleChoice = createReactClass({

  handleClick() {
    this.props.handleSelection(this.props.value);
  },

  render() {
    let isHs = HfReactHelper.isHelloSign();
    let activeClass = isHs ? 'is-active-cerulean' : 'is-active-goldenrod';
    let inactiveClass = isHs ? 'is-inactive-cerulean' : 'is-inactive-goldenrod';

    const classes = cx({
      'small-6': true,
      columns: true,
      'm-toggle--choice': true,
      [activeClass]: this.props.isActive,
      [inactiveClass]: !this.props.isActive,
    });

    return <div className={classes} onClick={this.handleClick}>{this.props.name}</div>;
  },
});

const Toggle = createReactClass({

  propTypes: {
    initialSelection: PropTypes.string,
  },

  getInitialState() {
    let selected = this.props.choices[0].value;
    if (this.props.initialSelection) {
      for (let i = 0; i < this.props.choices.length; i++) {
        if (this.props.initialSelection == this.props.choices[i].value) {
          selected = this.props.choices[i].value;
          break;
        }
      }
    }
    return { selected };
  },

  handleSelection(selected) {
    this.setState({ selected });
    this.props.onSelection(selected);
  },

  renderChoice(choice, i) {
    const { selected } = this.state;
    let { value, name } = choice;
    const isActive = selected === value;

    const props = {
      key: i,
      value,
      name,
      isActive,
      handleSelection: this.handleSelection,
    };

    return <ToggleChoice {...props} />;
  },

  render() {
    const classes = cx({
      'small-12': true,
      'small-centered': true,
      'large-3': true,
      'large-4': false,
      'large-centered': true,
      'columns m-toggle': true,
    });
    const choices = this.props.choices.map(this.renderChoice);
    return <div className={classes}>{choices}</div>;
  },
});

exports.Toggle = Toggle;
exports.ToggleChoice = ToggleChoice;
