import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';


const SalesforceLogo = (props) => (

  <svg
    {...props}
    viewBox="0 0 200 25"
    className={classnames(styles.icon, props.className)}
  >

    <g id="fn-salesforce" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="111" height="30"></rect>
      <g id="5cfac50f663bd88bf7ad1808_salesforce-black" transform="translate(0.000000, 5.000000)" fill="#222222">
        <path d="M32.343,14.8191 C32.2855,14.9917 32.3718,15.0205 32.4006,15.0493 C32.602,15.1932 32.8034,15.2795 33.0049,15.3946 C34.0695,15.9413 35.0479,16.114 36.1125,16.114 C38.2419,16.114 39.5655,14.9917 39.5655,13.1789 L39.5655,13.1501 C39.5655,11.4812 38.0692,10.8481 36.688,10.4165 L36.5154,10.359 C35.4507,10.0137 34.5587,9.72593 34.5587,9.03533 L34.5587,9.00655 C34.5587,8.40228 35.0766,7.97066 35.9111,7.97066 C36.8319,7.97066 37.9254,8.28718 38.616,8.66125 C38.616,8.66125 38.8174,8.80513 38.9037,8.6037 C38.9325,8.4886 39.3066,7.53903 39.3353,7.45271 C39.3641,7.33761 39.3066,7.25128 39.2202,7.22251 C38.4145,6.73333 37.3211,6.41681 36.1701,6.41681 L35.9687,6.41681 C34.012,6.41681 32.6596,7.59658 32.6596,9.26553 L32.6596,9.2943 C32.6596,11.0783 34.1558,11.6538 35.5658,12.0567 L35.796,12.1142 C36.8319,12.4308 37.6952,12.6897 37.6952,13.4091 L37.6952,13.4379 C37.6952,14.0997 37.1197,14.5889 36.1989,14.5889 C35.8536,14.5889 34.7026,14.5889 33.4653,13.812 C33.3214,13.7256 33.2351,13.6681 33.12,13.5818 C33.0624,13.553 32.9185,13.4667 32.8322,13.6681 L32.343,14.8191 Z" id="Path" fillRule="nonzero"></path>
        <path d="M63.4487,14.8191 C63.3912,14.9917 63.4775,15.0205 63.5063,15.0493 C63.7077,15.1932 63.9091,15.2795 64.1106,15.3946 C65.1752,15.9413 66.1536,16.114 67.2183,16.114 C69.3476,16.114 70.6713,14.9917 70.6713,13.1789 L70.6713,13.1501 C70.6713,11.4812 69.175,10.8481 67.7938,10.4165 L67.6211,10.359 C66.5564,10.0137 65.6644,9.72593 65.6644,9.03533 L65.6644,9.00655 C65.6644,8.40228 66.1824,7.97066 67.0168,7.97066 C67.9376,7.97066 69.0311,8.28718 69.7217,8.66125 C69.7217,8.66125 69.9231,8.80513 70.0094,8.6037 C70.0382,8.4886 70.4123,7.53903 70.4411,7.45271 C70.4698,7.33761 70.4123,7.25128 70.326,7.22251 C69.5203,6.73333 68.4268,6.41681 67.2758,6.41681 L67.0744,6.41681 C65.1177,6.41681 63.7653,7.59658 63.7653,9.26553 L63.7653,9.2943 C63.7653,11.0783 65.2616,11.6538 66.6715,12.0567 L66.9017,12.1142 C67.9376,12.4308 68.8009,12.6897 68.8009,13.4091 L68.8009,13.4379 C68.8009,14.0997 68.2254,14.5889 67.3046,14.5889 C66.9593,14.5889 65.8083,14.5889 64.571,13.812 C64.4271,13.7256 64.3408,13.6681 64.2257,13.5818 C64.1969,13.553 63.9955,13.4954 63.9379,13.6681 L63.4487,14.8191 Z" id="Path" fillRule="nonzero"></path>
        <path d="M86.4687,9.35185 C86.296,8.77635 86.0083,8.22963 85.663,7.798 C85.2889,7.36638 84.8573,7.02108 84.3105,6.7621 C83.7638,6.50313 83.1308,6.38803 82.4114,6.38803 C81.692,6.38803 81.059,6.50313 80.5122,6.7621 C79.9655,7.02108 79.5051,7.36638 79.1598,7.798 C78.7858,8.22963 78.5268,8.74757 78.3541,9.35185 C78.1815,9.92735 78.0952,10.5892 78.0952,11.251 C78.0952,11.9416 78.1815,12.5746 78.3541,13.1501 C78.5268,13.7256 78.7858,14.2436 79.1598,14.704 C79.5339,15.1356 79.9943,15.4809 80.5122,15.7399 C81.059,15.9989 81.692,16.114 82.4114,16.114 C83.1308,16.114 83.7638,15.9989 84.3105,15.7399 C84.8573,15.4809 85.3177,15.1356 85.663,14.704 C86.037,14.2724 86.296,13.7544 86.4687,13.1501 C86.6413,12.5746 86.7276,11.9128 86.7276,11.251 C86.7276,10.5892 86.6413,9.92735 86.4687,9.35185 Z M84.6846,11.251 C84.6846,12.2869 84.4832,13.0926 84.1091,13.6681 C83.735,14.2436 83.1595,14.5026 82.3826,14.5026 C81.6057,14.5026 81.0302,14.2148 80.6561,13.6681 C80.282,13.0926 80.1094,12.2869 80.1094,11.251 C80.1094,10.2151 80.3108,9.4094 80.6561,8.86267 C81.0302,8.28718 81.5769,8.0282 82.3826,8.0282 C83.1595,8.0282 83.735,8.28718 84.1091,8.86267 C84.4832,9.43817 84.6846,10.2439 84.6846,11.251 Z" id="Shape" fillRule="nonzero"></path>
        <path d="M101.029,14.2436 C100.971,14.0709 100.799,14.1285 100.799,14.1285 C100.54,14.2148 100.281,14.3299 99.9641,14.3587 C99.6763,14.4162 99.331,14.4162 98.9857,14.4162 C98.1225,14.4162 97.4319,14.1573 96.9715,13.6681 C96.4823,13.1501 96.1945,12.3444 96.2233,11.251 C96.2233,10.2439 96.4823,9.49573 96.9139,8.92023 C97.3455,8.34473 98.0074,8.05698 98.8706,8.05698 C99.59,8.05698 100.165,8.1433 100.741,8.31595 C100.741,8.31595 100.885,8.3735 100.942,8.20085 C101.086,7.76923 101.201,7.45271 101.374,6.99231 C101.432,6.84843 101.316,6.79088 101.259,6.79088 C101.029,6.70456 100.482,6.56068 100.079,6.50313 C99.7051,6.44558 99.2447,6.41681 98.7555,6.41681 C98.0074,6.41681 97.3455,6.53191 96.77,6.79088 C96.1945,7.04986 95.7341,7.39516 95.3313,7.82678 C94.9572,8.2584 94.6695,8.77635 94.468,9.38063 C94.2666,9.95612 94.1803,10.6179 94.1803,11.2798 C94.1803,12.7473 94.5831,13.9271 95.3601,14.8191 C96.137,15.6823 97.3168,16.1427 98.8706,16.1427 C99.7914,16.1427 100.712,15.9701 101.374,15.6823 C101.374,15.6823 101.489,15.6248 101.46,15.4809 L101.029,14.2436 Z" id="Path"></path>
        <path d="M110.093,9.00655 C109.949,8.43105 109.575,7.85555 109.316,7.59658 C108.942,7.19373 108.539,6.8772 108.165,6.73333 C107.676,6.53191 107.1,6.38803 106.438,6.38803 C105.69,6.38803 105,6.50313 104.453,6.7621 C103.906,7.02108 103.446,7.36638 103.072,7.82678 C102.698,8.2584 102.439,8.80512 102.266,9.38062 C102.093,9.9849 102.007,10.6179 102.007,11.3085 C102.007,11.9991 102.093,12.6322 102.266,13.2365 C102.439,13.812 102.755,14.3299 103.129,14.7615 C103.532,15.1932 104.021,15.5385 104.626,15.7687 C105.23,15.9989 105.949,16.114 106.813,16.114 C108.539,16.114 109.46,15.7111 109.834,15.5097 C109.891,15.4809 109.978,15.3946 109.891,15.2219 L109.489,14.1285 C109.431,13.9558 109.258,14.0134 109.258,14.0134 C108.827,14.186 108.223,14.4738 106.784,14.445 C105.863,14.445 105.172,14.1573 104.741,13.7256 C104.309,13.294 104.079,12.6322 104.05,11.7402 L110.064,11.7402 C110.064,11.7402 110.208,11.7402 110.237,11.5963 C110.294,11.5387 110.496,10.359 110.093,9.00655 Z M104.136,10.2726 C104.223,9.69715 104.367,9.23675 104.626,8.86267 C105,8.28718 105.546,7.99943 106.352,7.99943 C107.129,7.99943 107.647,8.31595 108.021,8.86267 C108.28,9.23675 108.366,9.72592 108.424,10.2726 L104.136,10.2726 Z" id="Shape"></path>
        <path d="M62.0099,9.00655 C61.8661,8.43105 61.492,7.85555 61.233,7.59658 C60.8589,7.19373 60.4561,6.8772 60.082,6.73333 C59.5929,6.53191 59.0174,6.38803 58.3555,6.38803 C57.6074,6.38803 56.9168,6.50313 56.3701,6.7621 C55.8233,7.02108 55.3629,7.36638 54.9889,7.82678 C54.6148,8.2584 54.3558,8.80512 54.1832,9.38062 C54.0105,9.9849 53.9242,10.6179 53.9242,11.3085 C53.9242,11.9991 54.0105,12.6322 54.1832,13.2365 C54.3558,13.812 54.6723,14.3299 55.0464,14.7615 C55.4493,15.1932 55.9384,15.5385 56.5427,15.7687 C57.147,15.9989 57.8664,16.114 58.7296,16.114 C60.4561,16.114 61.3769,15.7111 61.751,15.5097 C61.8085,15.4809 61.8661,15.3946 61.8085,15.2219 L61.4057,14.1285 C61.3481,13.9558 61.1755,14.0134 61.1755,14.0134 C60.7439,14.186 60.1396,14.4738 58.7008,14.445 C57.78,14.445 57.0894,14.1573 56.6578,13.7256 C56.2262,13.294 55.996,12.6322 55.9672,11.7402 L61.9812,11.7402 C61.9812,11.7402 62.125,11.7402 62.1538,11.5963 C62.2114,11.5387 62.4128,10.359 62.0099,9.00655 Z M56.0535,10.2726 C56.1399,9.69715 56.2837,9.23675 56.5427,8.86267 C56.9168,8.28718 57.4635,7.99943 58.2692,7.99943 C59.0461,7.99943 59.5641,8.31595 59.9382,8.86267 C60.1971,9.23675 60.2834,9.72592 60.341,10.2726 L56.0535,10.2726 Z" id="Shape"></path>
        <path d="M45.4644,9.9849 C45.2342,9.95612 44.9177,9.95612 44.5148,9.95612 C43.9969,9.95612 43.5077,10.0137 43.0473,10.1575 C42.5869,10.2726 42.1553,10.4741 41.7812,10.733 C41.4071,10.992 41.1194,11.3373 40.918,11.7114 C40.7165,12.1142 40.6014,12.5746 40.6014,13.0926 C40.6014,13.6105 40.6878,14.0997 40.8892,14.4738 C41.0618,14.8479 41.3496,15.1644 41.6661,15.4234 C42.0114,15.6536 42.4143,15.855 42.9034,15.9413 C43.3638,16.0564 43.9106,16.114 44.486,16.114 C45.0903,16.114 45.7234,16.0564 46.2989,15.9701 C46.8744,15.8838 47.6225,15.7111 47.8239,15.6823 C48.0254,15.6248 48.2556,15.5672 48.2556,15.5672 C48.3994,15.5385 48.3994,15.3658 48.3994,15.3658 L48.3994,9.8698 C48.3994,8.66125 48.0829,7.76923 47.4499,7.2225 C46.8168,6.67578 45.896,6.38803 44.6875,6.38803 C44.2271,6.38803 43.5077,6.44558 43.0761,6.53191 C43.0761,6.53191 41.7524,6.79088 41.2345,7.2225 C41.2345,7.2225 41.1194,7.30883 41.1769,7.4527 L41.6086,8.6037 C41.6661,8.74757 41.81,8.69003 41.81,8.69003 C41.81,8.69003 41.8675,8.66125 41.8963,8.63248 C43.0473,7.99943 44.5148,8.0282 44.5148,8.0282 C45.1766,8.0282 45.6658,8.17208 46.0111,8.40228 C46.3276,8.66125 46.5003,9.03532 46.5003,9.84102 L46.5003,10.1 C45.9248,10.0424 45.4644,9.9849 45.4644,9.9849 Z M43.0761,14.2148 C42.8459,14.0134 42.8171,13.9846 42.7308,13.8695 C42.6157,13.6969 42.5581,13.4091 42.5581,13.0926 C42.5581,12.5746 42.7308,12.1718 43.1049,11.9416 C43.1049,11.9416 43.6228,11.4812 44.8601,11.51 C45.7234,11.51 46.5003,11.6538 46.5003,11.6538 L46.5003,14.3875 C46.5003,14.3875 45.7234,14.5601 44.8889,14.5889 C43.594,14.6177 43.0761,14.2148 43.0761,14.2148 Z" id="Shape" fillRule="nonzero"></path>
        <path d="M93.8925,6.90599 C93.9501,6.76211 93.835,6.70456 93.8062,6.67579 C93.6911,6.64701 93.202,6.53191 92.7991,6.50314 C92.051,6.44559 91.6193,6.58946 91.2453,6.76211 C90.8712,6.93476 90.4684,7.19374 90.2382,7.51026 L90.2382,6.79089 C90.2382,6.70456 90.1806,6.61824 90.0655,6.61824 L88.5404,6.61824 C88.4541,6.61824 88.3678,6.70456 88.3678,6.79089 L88.3678,15.7399 C88.3678,15.8262 88.4541,15.9125 88.5404,15.9125 L90.1231,15.9125 C90.2094,15.9125 90.2957,15.8262 90.2957,15.7399 L90.2957,11.251 C90.2957,10.6467 90.3533,10.0425 90.4971,9.66838 C90.641,9.29431 90.8136,9.00656 91.0151,8.77636 C91.2453,8.57493 91.4755,8.40228 91.7632,8.34473 C92.051,8.25841 92.3387,8.22963 92.5689,8.22963 C92.8854,8.22963 93.2307,8.31596 93.2307,8.31596 C93.3458,8.31596 93.4034,8.25841 93.4609,8.14331 C93.5472,7.88433 93.835,7.04986 93.8925,6.90599 Z" id="Path"></path>
        <path d="M79.0735,2.73362 C78.8721,2.67607 78.6995,2.6473 78.4693,2.58975 C78.2391,2.56097 77.9513,2.5322 77.6636,2.5322 C76.5701,2.5322 75.7356,2.84872 75.1314,3.45299 C74.5271,4.05727 74.1242,4.97807 73.9228,6.18661 L73.8365,6.58946 L72.4841,6.58946 C72.4841,6.58946 72.3114,6.58946 72.2827,6.76211 L72.0525,7.99943 C72.0237,8.11453 72.0812,8.20086 72.2539,8.20086 L73.5775,8.20086 L72.2251,15.7111 C72.11,16.3154 71.9949,16.8046 71.8798,17.1786 C71.7359,17.5527 71.6208,17.8405 71.4482,18.0419 C71.3043,18.2433 71.1604,18.3872 70.9015,18.4735 C70.7,18.5311 70.4698,18.5886 70.1821,18.5886 C70.0382,18.5886 69.8368,18.5598 69.6929,18.5311 C69.549,18.5023 69.4627,18.4735 69.3764,18.416 C69.3764,18.416 69.2325,18.3584 69.1462,18.5023 C69.0886,18.6174 68.7433,19.5957 68.7146,19.7108 C68.6858,19.8259 68.7433,19.9123 68.8009,19.941 C68.9735,19.9986 69.1174,20.0561 69.3476,20.1137 C69.6929,20.2 69.9519,20.2 70.2396,20.2 C70.7864,20.2 71.3043,20.1137 71.7072,19.9698 C72.1388,19.8259 72.4841,19.567 72.8294,19.1929 C73.1747,18.8188 73.4049,18.416 73.6063,17.8405 C73.8077,17.2937 73.9804,16.6031 74.1243,15.8262 L75.4767,8.17208 L77.4621,8.17208 C77.4621,8.17208 77.6348,8.17208 77.6636,7.99943 L77.8938,6.76211 C77.9225,6.64701 77.865,6.56069 77.6923,6.56069 L75.7644,6.56069 C75.7644,6.50314 75.8507,5.84131 76.0809,5.20827 C76.1673,4.94929 76.3399,4.71909 76.5126,4.57522 C76.6564,4.43134 76.8291,4.31624 77.0017,4.25869 C77.2032,4.20114 77.4046,4.17237 77.6348,4.17237 C77.8074,4.17237 77.9801,4.20114 78.124,4.22992 C78.2966,4.25869 78.3829,4.28747 78.4405,4.31624 C78.6419,4.37379 78.6707,4.31624 78.6995,4.22992 L79.1599,2.96382 C79.2462,2.81995 79.1311,2.7624 79.0735,2.73362 Z" id="Path"></path>
        <path d="M52.2553,15.7399 C52.2553,15.8262 52.1977,15.9125 52.0826,15.9125 L50.5,15.9125 C50.4137,15.9125 50.3273,15.8262 50.3273,15.7399 L50.3273,2.90626 C50.3273,2.81994 50.3849,2.73361 50.5,2.73361 L52.0826,2.73361 C52.1689,2.73361 52.2553,2.81994 52.2553,2.90626 L52.2553,15.7399 Z" id="Path"></path>
        <path d="M11.6826,2.12934 C12.5746,1.17977 13.8407,0.604273 15.2507,0.604273 C17.0923,0.604273 18.7037,1.64017 19.5669,3.16524 C20.3151,2.81994 21.1496,2.64729 22.0128,2.64729 C25.3507,2.64729 28.0556,5.38091 28.0556,8.74758 C28.0556,12.1142 25.3507,14.8479 22.0128,14.8479 C21.61,14.8479 21.2071,14.8191 20.833,14.7328 C20.0849,16.0852 18.6462,17.006 16.9772,17.006 C16.2866,17.006 15.6248,16.8333 15.0493,16.5744 C14.2724,18.3872 12.4883,19.6533 10.4165,19.6533 C8.2584,19.6533 6.38803,18.2721 5.69743,16.3442 C5.38091,16.4017 5.06439,16.4305 4.74786,16.4305 C2.10057,16.4305 0,14.3011 0,11.7114 C0,9.95613 0.949573,8.43105 2.33077,7.62536 C2.04302,6.96353 1.87037,6.24416 1.87037,5.46724 C1.89914,2.44587 4.34501,0 7.33761,0 C9.09288,0 10.6755,0.834473 11.6826,2.12934 Z" id="Path" fillRule="nonzero"></path>
      </g>
    </g>
  </svg>
);

export default React.memo(SalesforceLogo);
