/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  react/no-string-refs
*/
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'signer/components/common/loader';
import { FormattedMessage } from 'react-intl';
import createReactClass from 'create-react-class';
import ActionBar from './action-bar';
import CONSTANTS from './constants';
import SignatureEditArea from './signature-edit-area';

import {
  CommonTabProps as CommonTabPropTypes,
  SignatureType,
  OnSignatureData,
} from './prop-types';

const EmailTab = createReactClass({

  propTypes: {
    ...CommonTabPropTypes,
    isCheckingStatus: PropTypes.bool,
    onCheckStatus: PropTypes.func.isRequired,
    onSignatureData: OnSignatureData,
    onRotateSignature: PropTypes.func.isRequired,
    signature: SignatureType,
    uploadAddress: PropTypes.string.isRequired,
  },


  //  ----  BEHAVIOR  -----------------------------------

  insertSignature(insertEverywhere) {

    const emailEditArea = this.refs.emailEditArea;
    let signatureData = {};
    const isCropped = emailEditArea.state.isCropped;

    if (isCropped) {
      signatureData = {
        is_cropped: isCropped,
        x: emailEditArea.state.cropX,
        y: emailEditArea.state.cropY,
        width: emailEditArea.state.cropWidth,
        height: emailEditArea.state.cropHeight,
      };
    } else {
      signatureData.width = this.props.signature.width;
      signatureData.height = this.props.signature.height;
    }

    signatureData.guid = this.props.signature.guid;
    signatureData.type_code = this.props.signature.type;
    signatureData.create_type_code = CONSTANTS.SIGNATURE_TYPE_EMAIL;
    signatureData.threshold = emailEditArea.state.contrastValue;
    signatureData.degrees = emailEditArea.state.degreesValue;

    return this.props.onSignatureData(signatureData, insertEverywhere);
  },

  //  ----  HELPERS  ------------------------------------

  startSignatureFlow(type) {
    return this.props.onCheckStatus(type);
  },

  shouldDisableButtons() {
    return !!this.props.signature;
  },

  onContinue() {
    this.startSignatureFlow(this.props.type);
  },

  //  ----  RENDERING  ----------------------------------

  render() {
    const {
      actionBarProps,
      getSignatureUrl,
      intl,
      isCheckingStatus,
      onRotateSignature,
      signature,
      type,
    } = this.props;

    let spinner;

    const emailAddress = this.props.uploadAddress;

    const inputClasses = { 'm-sign-modal--tabs--email--input': true }; const classes = { 'm-sign-modal--tabs--email': true };
    const containerClasses = {
      'm-sign-modal--tabs--tab': true,
      'is-selected': this.props.isSelected,
    };

    containerClasses[CONSTANTS.TAB_EMAIL] = true;

    if (isCheckingStatus) {
      spinner = (
        <Loader
          className='m-sign-modal--tabs--email--loader'
          data-test-ref='loader' />
      );
    }

    const steps = <div className='m-sign-modal--tabs--email--steps'>
      <div className='m-sign-modal--tabs--email--steps--header'>
        <FormattedMessage id='signModal.email.instructions.header' />
      </div>
      <div className='m-sign-modal--tabs--email--steps--step'>
        <FormattedMessage id='signModal.email.instructions.step_1' values={{ type: this.props.type }} />
      </div>
      <div className='m-sign-modal--tabs--email--steps--step'>
        <p style={{
          display: 'inline-block', width: 180, fontWeight: 'normal', lineHeight: '18px', fontSize: '14px',
        }}
        >
          <FormattedMessage id='signModal.email.instructions.step_2' />
        </p>
        <strong>{ emailAddress }</strong>
      </div>
      <div className='m-sign-modal--tabs--email--steps--subject'>
        <FormattedMessage id='signModal.email.instructions.subject' />
        <strong>{ this.props.emailGuid }</strong>
      </div>
      <div className='m-sign-modal--tabs--email--steps--step'>
        <FormattedMessage id='signModal.email.instructions.step_3' />
      </div>
    </div>;

    return <div className={cx(containerClasses)}>

      <span className='m-sign-modal--tabs--tab-title'>
        <FormattedMessage
          id='signModal.email.title'
          values={{ type: this.props.type }} />
      </span>

      <div className={cx(classes)}>
        <div className={cx(inputClasses)}>
          <div className='m-sign-modal--tabs--content' ref={this.props.captureTabContentRef}>
            {!signature && !isCheckingStatus && <div
              data-test-ref='instructions'
              className='m-sign-modal--tabs--email--part1'
            >
              { steps }
            </div>}
            {isCheckingStatus && spinner}
            {signature && <SignatureEditArea
              data-test-ref='edit-area'
              getSignatureUrl={getSignatureUrl}
              intl={intl}
              onRotate={onRotateSignature}
              ref='emailEditArea'
              signature={signature}
              type={type} />}
          </div>
        </div>
      </div>

      <ActionBar
        {...actionBarProps}
        continueInsteadOfInsert={!signature}
        disableButtons={isCheckingStatus}
        onContinue={this.onContinue}
        onInsert={this.insertSignature} />

    </div>;
  },
});

module.exports = EmailTab;
