/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
// needed for react intl otherwise stuff busts

import React from 'react';
import HSIntlProvider from 'hellospa/components/hs-intl-provider';
import Application from './detached-application';

require('intl');

module.exports = Application;

// All HelloSign components need to be attached to the application for legacy
// purposes.
Application.components = require('./components');

// I'm wrapping these here instead of inside ./components so that they only get
// the provider when called from PHP's render_react_components. If there is code
// somewhere in `hellosign-web` that imports components, it should already have
// a provider near the top of the tree.
Application.components = Object.keys(Application.components).reduce((componentMap, name) => {
  const Component = Application.components[name];

  const IntlComponent = (props) => (
    <HSIntlProvider>
      <Component {...props} />
    </HSIntlProvider>
  );
  IntlComponent.displayName = `Intl(${name})`;

  componentMap[name] = IntlComponent;
  return componentMap;
}, {});

Application.mixins = {
  AdjustScrollingMixin: require('./components/api-docs/api-menu/adjust-scrolling-mixin'),
};
