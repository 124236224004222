/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-console
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'hello-ui/blocks';
import { Layout, Main } from 'hellosign/common/layout';
import Logo from 'common/components/logo';
import './download-page.scss';
import StyleProvider from 'hello-ui/style';

const iconSrc = require('./folder_clip.svg');

/**
 * This is a full-page component for a file download screen with an automatic download.
 */
export class DownloadPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleDownloadLinkClick = this.handleDownloadLinkClick.bind(this);
  }

  componentDidMount() {
    const { downloadUrl, onDownload } = this.props;
    if (downloadUrl.startsWith('https://')) {
      window.location = downloadUrl;
    } else {
      console.log(
        'Skipping download redirect because URL is missing or invalid',
      );
    }

    if (typeof onDownload === 'function') {
      onDownload({ automatic: true });
    }
  }

  handleDownloadLinkClick(event) {
    const { onDownload } = this.props;
    if (typeof onDownload === 'function') {
      onDownload({ automatic: false });
      event.preventDefault(); // Prevent actual navigation
    }
  }

  render() {
    const {
      heading, downloadUrl, backLabel, backUrl,
    } = this.props;
    return (
      <StyleProvider>
        <Layout responsive={true}>
          <Box pb={5} pt={5}>
            <Logo siteCode="S" isLink href="/" />
          </Box>
          <Main style={{ textAlign: 'center' }}>
            <Box m={2}>
              <img
                alt="File icon"
                src={iconSrc}
                style={{ height: '100px' }} />
            </Box>
            <Box
              mb={4}
              style={{ fontSize: '26px', fontWeight: 'bold' }}
            >
              {heading}
            </Box>
            <Box>
                            Your download should begin shortly. If not,
              {' '}
              <a
                href={downloadUrl}
                onClick={this.handleDownloadLinkClick}
              >
                <strong>click here</strong>
              </a>
                            .
            </Box>
            <Box mt={5} mb={5}>
              <a href={backUrl}>Go back to {backLabel}</a>
            </Box>
          </Main>
        </Layout>
      </StyleProvider>
    );
  }
}

DownloadPage.propTypes = {
  heading: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  backLabel: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  onDownload: PropTypes.func, // Used for tests/storybook
};

export default DownloadPage;
