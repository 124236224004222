/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-console
*/
import { extend, create } from 'common/utils/class';
import { ChangeMessage } from 'common/messages';

/**
 * BaseObject
 */

function BaseObject(properties) {
  if (properties) {
    this.setProperties(properties);
  }
}

Object.assign(BaseObject.prototype, {

  /**
     * Sets properties on the object, and broadcasts a change
     * notification if the object has changed.
     */

  setProperties(properties) {
    Object.assign(this, properties);

    // TODO - need to diff properties here to see if anything has actually changed (CC)
    if (this.notifier) {
      this.notifier.notify(ChangeMessage.create(this));
    }
  },

  /**
     * Sets one property on the object.
     * @deprecated use setProperties() instead
     */

  set(keypath, value) {
    console.error('DEPRECATED: calling set() on object. Use setProperties instead');
    this.setProperties({
      [keypath]: value,
    });
  },
});

BaseObject.extend = extend;
BaseObject.create = create;

export default BaseObject;
