/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/


//  ----------  MENU  -------------------------------------

const AdjustScrollingMixin = {

  adjustScrolling() {

    const footer = $('.footer-wrapper-2019');
    const menu = $('.l-api-docs-menu');
    const isFixed = menu.attr('class').includes('is-fixed');
    const threshold = 50;
    const defaultMenuTop = 100;
    const footerTop = footer.offset().top;
    const menuBottom = menu.offset().top + menu.height();

    const menuTop = parseInt(menu.css('top'), 10);
    const delta = Math.round(footerTop - menuBottom);

    if (!isFixed) {
      menu.css('top', '');
    } else if (delta < threshold) {
      // Move menu up
      menu.css('top', menuTop - (threshold - delta));
    } else if (menuTop < defaultMenuTop) {
      // Move menu down
      menu.css('top', menuTop + (footerTop - threshold - menuBottom));
    }
  },
};

module.exports = AdjustScrollingMixin;
