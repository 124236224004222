import uiActions from './ui';

/**
 * global
 */

export default {
  create() {
    return {
      ui: uiActions,
    };
  },
};
