/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  no-use-before-define,
  prefer-rest-params
*/

/**
*/

module.exports = function (mixins) {

  const prototype = {};

  for (let i = 0, n = mixins.length; i < n; i++) {

    const mixin = mixins[i];
    const keys = Object.keys(mixin);

    for (let j = 0, n2 = keys.length; j < n2; j++) {
      const key = keys[j];
      let value = mixin[key];
      const tov = typeof value;


      if (tov === 'function') {
        if (prototype[key]) {
          value = combineFunction(prototype[key], value);
        }
      }

      prototype[key] = value;
    }
  }

  return prototype;
};

/**
*/

function combineFunction(oldFn, newFn) {
  return function () {
    oldFn.apply(this, arguments);
    return newFn.apply(this, arguments);
  };
}
