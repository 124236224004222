/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  func-names
*/
import logger from 'common/logger';

// catch any global unhandled promise rejections
window.onunhandledrejection = function (event) {
  const isTesting = process.env.NODE_ENV === 'test';
  const rethrowUnhandled = process.env.RETHROW_UNHANDLED_REJECTIONS;
  if (isTesting) {
    if (!rethrowUnhandled) {
      event.preventDefault();
    }
  } else {
    logger.warn('An unexpected error occurred. This has been reported to our engineering team. For further support, please contact apisupport@hellosign.com.', event.reason);
  }
};
