export default function pushGoogleTagManagerEvent(options) {
  /* global dataLayer */
  /* global l */
  try {
    if (dataLayer != null) {
      dataLayer.push({
        event: 'track_event',
        eventAction: options.action || '',
        eventCategory: options.category || '',
        eventLabel: options.label || '',
      });
    }
  } catch (e) {
    l(`ERROR: Could not report GA event (${e.message})`);
  }
}
