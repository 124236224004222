/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len,
  react/no-unescaped-entities
*/
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  actions, Errors, Control, Form,
} from 'react-redux-form';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import { stringify } from 'qs';
import superagent from 'superagent';
import { Field } from 'common/components/forms';
import './recover-password-form.scss';
import EmailLink from '../../../common/components/email-link';

const FORM_NAME = 'recoverPassword';

const messages = defineMessages({
  emailAddress: {
    id: '',
    description: 'remember me text on login page',
    defaultMessage: 'EMAIL ADDRESS',
  },
});

/**
 * Recover Password Form
 *
 * Allows a user to submit an email address for a password reset, then shows a confirmation message.
 */
class RecoverPasswordForm extends React.Component {
    static propTypes = {
      // React Redux Form object, provided from Redux state by connect
      form: PropTypes.object.isRequired,

      // Redux dispatcher object, provided from Redux by connect
      dispatch: PropTypes.func.isRequired,

      siteCode: PropTypes.string.isRequired,
      submitUrl: PropTypes.string.isRequired,
      autoSubmit: PropTypes.bool,
      redirectUrl: PropTypes.string,
      intl: PropTypes.object,
    };

    constructor(props) {
      super(props);

      // Pre-bind custom methods
      this.handleGeneralError = this.handleGeneralError.bind(this);
      this.handleResponse = this.handleResponse.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
      if (this.props.autoSubmit) {
        this.props.dispatch(actions.submit(FORM_NAME));
      }
    }

    /**
     * Handle any generic/fallback errors
     */
    handleGeneralError() {
      this.props.dispatch(actions.setSubmitFailed(FORM_NAME));

      // Display error message
      this.props.dispatch(
        actions.setErrors(FORM_NAME, {
          server: 'derp',
        }),
      );
    }

    /**
     * Handles the response from the server after submitting the form
     */
    handleResponse(err) {
      // Error here means some kind of network error (4xx or 5xx)
      if (err) {
        return this.handleGeneralError(err);
      }

      try {
        // Here we can get errors back from the server and display errors if necessary
        // (This particular form never returns anything useful, so just proceed to the confirmation message now)
        this.props.dispatch(actions.setSubmitted(FORM_NAME));
      } catch (e) {
        return this.handleGeneralError(err);
      }
    }

    /**
     * This handler is called _after_ react-redux-form has validated the form.
     */
    handleSubmit(values) {
      // Prepare POST payload
      const data = stringify({
        recover: {
          email_address: values.email,
          redirect_url: this.props.redirectUrl,
          _csrf_token: values.csrfToken,
        },
      });

      // Submit the POST
      return superagent
        .post(this.props.submitUrl)
        .type('form')
        .send(data)
        .on('request', () => {
          this.props.dispatch(actions.setPending(FORM_NAME));
        })
        .end(this.handleResponse);
    }

    render() {
      const { submitted, pending: submitting } = this.props.form.$form;
      const site = HfReactHelper.HfSites.getSite(this.props.siteCode);

      const submittedMessage = (
        <div className="row text-center" data-test-ref="recovery-message">
          <div className="columns small-12">
            <h2>Check your inbox</h2>
            <span>
              <p className="l-400">
                <FormattedMessage id='' defaultMessage='If that is a valid address, we have sent an email to it to reset your password.' description='recover password page' />
              </p>
              <hr className="m-recover-password-form--rule" />
              <p className="l-400">
                <FormattedMessage id='' defaultMessage="If you're still experiencing difficulties, please
                contact us at" description='recover password page' />&nbsp;
                <EmailLink email={HfReactHelper.HfConstants.supportEmail[site]} emailSubject='Password Reset' />.
              </p>
            </span>
          </div>
        </div>
      );

      return (
        <div className="m-recover-password-form">
          {!submitted
                    && <div data-test-ref="form-div">
                      <div className="l-text-centered">
                        <h2><FormattedMessage id='' defaultMessage='Reset password' description='recover password page' /></h2>
                        <p className="l-400">
                          <FormattedMessage id='' defaultMessage='Please enter your email address to request a
                                password reset.' description='recover password page' />
                        </p>
                      </div>

                      <hr className="m-recover-password-form--rule" />

                      <Form
                        model={FORM_NAME}
                        onSubmit={this.handleSubmit}
                        data-test-ref="form"
                      >
                        <Field label={this.props.intl.formatMessage(messages.emailAddress)}>
                          <Control.input
                            type="email"
                            model="recoverPassword.email"
                            validators={{
                              isEmail:
                                            HfReactHelper.isValidEmailAddress,
                            }} />
                          <Errors
                            model="recoverPassword.email"
                            show="touched"
                            messages={{
                              isEmail: <FormattedMessage id='' defaultMessage='Please enter a valid email address' description='recover password page' />,
                            }}
                            className="m-field--error"
                            data-test-ref="email-input-errors" />
                        </Field>
                        <Button
                          buttonText={<FormattedMessage id='' defaultMessage='Reset Password' description='recover password page' />}
                          buttonColor="azure"
                          buttonClass="m-recover-password-form--button"
                          type="submit"
                          disabled={submitting} />
                        <Errors
                          model={FORM_NAME}
                          show={true}
                          className="c-blood" />
                      </Form>
                    </div>}

          {submitted && submittedMessage}
        </div>
      );
    }
}

// This mapping is used by connect to expose (as props) parts of state that we need in this component
function mapStateToProps(state) {
  return {
    form: state.forms.recoverPassword,
  };
}

// Export as default the connected form
export default injectIntl(connect(mapStateToProps)(RecoverPasswordForm));
