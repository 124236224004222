/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-string-refs
*/
import './notification.scss';
import React from 'react';
import NotificationHandler from 'common/utils/notification-handler';
import createReactClass from 'create-react-class';
import Message from './message';
import LegacyNotification from './legacy-notification';

module.exports = createReactClass({
  displayName: 'NotificationHandler',

  getInitialState() {
    this.notification = new NotificationHandler();
    const notifications = this.notification.notifications;

    return {
      notifications,
      siteTransform: 0,
    };
  },

  componentDidMount() {
    this.notification.removeNotifications();
    LegacyNotification.attach();
  },

  renderNotification(notification, index) {
    const styles = Object.assign(
      { zIndex: (this.state.notifications.length - index) },
      notification.styles,
    );
    return <Message
      key={index}
      ref={index}
      notification={notification}
      handleRemove={this.removeNotification.bind(this, index)}
      style={styles} />;
  },

  removeNotification(notificationIndex) {
    const notificationHeight = this.refs[notificationIndex].scrollHeight;
    const siteTransform = this.state.siteTransform + notificationHeight;
    const notifications = this.slideOtherNotifications(notificationIndex, siteTransform);

    this.slideSiteWrapper(siteTransform);

    this.setState({
      siteTransform,
      notifications,
    });

  },

  slideSiteWrapper(siteTransform) {
    const siteWrapper = document.getElementById('site-wrapper');
    if (siteWrapper) {
      siteWrapper.style.transform = `translateY(${-siteTransform}px)`;
    }
  },

  slideOtherNotifications(notificationIndex, siteTransform) {
    return this.state.notifications.map((notification, index) => {
      if (index > notificationIndex) {
        notification.styles = {
          transform: `translateY(${-siteTransform}px)`,
        };
      }
      return notification;
    });
  },

  render() {
    return <div className='m-notification'>
      {this.state.notifications.map(this.renderNotification)}
    </div>;
  },
});
