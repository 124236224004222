/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import baseform from 'common/components/base-form';
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';

const LogInInPerson = baseform.createFormClass({

  propTypes: {
    guid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  },

  onEmailChange(event) {
    this.setState({
      emailAddress: event.target.value.trim(),
    });
  },

  onSubmit(e) {
    e.preventDefault();

    const emailAddress = $('#email_address').val();

    if (!HfReactHelper.isValidEmailAddress(emailAddress)) {
      this.setState({
        errors: { named: { email_address: 'Invalid email address' } },
      });
    } else {
      $('#login-form').submit();
    }
  },

  render() {
    let errors = '';
    if (this.state.errors) {
      errors = (
        <div className='row'>
          <div className='columns small-12 text-center m-login-in-person--errors'>
            { this.renderErrorText() }
          </div>
        </div>
      );
    }
    return (
      <div className='m-login-in-person row'>
        <h4>{ this.props.title }</h4>
        <div>To review and sign this document, enter your email address:</div>
        <form id='login-form' className='m-login-in-person--form' action={HfReactHelper.urlHelper('signer/signInPerson')} method='POST'>
          { this.renderField('email_address', {
            id: 'email_address',
            className: '',
            maxLength: '99',
            placeholder: 'email@example.com',
            onChange: this.onEmailChange,
            tabIndex: '0',
            'data-format': 'email',
          }) }
          <div className='row'>
            <div className='columns small-12'>
              <Button onClick={this.onSubmit}
                buttonClass='right'
                buttonText='Continue'
                buttonColor='cerulean'
                buttonHoverColor='castle-rock'
                buttonTabIndex='1' />
            </div>
          </div>
          <input type='hidden' name='short_guid' value={this.props.guid} />
        </form>
        { errors }
      </div>
    );
  },
});

module.exports = LogInInPerson;
