/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  eqeqeq,
  import/extensions
*/
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import classnames from 'classnames';
import HelloModal from 'common/components/hello-modal';
import Button from 'common/components/button';
import uuid from 'uuid/v4';
import Attachment from './attachment.jsx';

const MAX_ATTACHMENTS = 5;

const messages = defineMessages({
  textareaPlaceholder: {
    id: '',
    defaultMessage: 'Enter any additional information here',
    description: 'Placeholder for textarea for addional information on pop up modal for enterprise account',
  },
  nameOfDocumentPlaceholder: {
    id: '',
    defaultMessage: 'Name of document',
    description: 'Placeholder for Name of document on the pop up modal for enterprise account',

  },
});
class AttachmentModal extends React.Component {

  constructor(props, context) {
    super(props, context);


    this.state = {
      // https://medium.com/@justintulk/react-anti-patterns-props-in-initial-state-28687846cc2e
      attachments: props.attachments,
      id: null,
      name: '',
      instructions: '',
      required: false,
      isDirty: false,
    };
  }

    idPrefix = uuid();

    htmlId = (name) => `${this.idPrefix}-${name}`;

    changeName = (event) => this.setState({ isDirty: true, name: event.target.value });

    changeInstructions = (event) => this.setState({ instructions: event.target.value });

    changeRequired = (event) => this.setState({ required: event.target.checked });

    editAttachment = (attachment) => this.setState(attachment);

    deleteAttachment = (id) => this.setState((state) => {
      return {
        attachments: state.attachments.filter((a) => a.id !== id),
      };
    });

    handleSave = (event) => {
      event.preventDefault();
      this.saveForm(() => {
        this.props.setAttachments(this.state.attachments);
        this.props.onRequestClose();
      });
    };

    saveForm = (callback) => {
      let attachments = this.state.attachments;
      const attachment = {
        id: this.state.id,
        name: this.state.name,
        instructions: this.state.instructions,
        required: this.state.required,
      };

      if (attachment.name.trim().length > 0) {
        if (attachment.id != null) {
          attachments = attachments.map(
            (a) => ((a.id == attachment.id) ? attachment : a),
          );
        } else {
          attachments = [...attachments, {
            ...attachment,
            id: uuid(),
          }];
        }
      }

      this.setState({
        attachments,
        id: null,
        name: '',
        instructions: '',
        required: false,
        isDirty: false,
      }, callback);
    };

    handleSubmit = (event) => {
      event.preventDefault();
      this.saveForm();
    };

    render() {
      const {
        readOnly, changeName, changeRequired, onRequestClose,
      } = this.props;

      const attachments = this.state.attachments.map((a) => (
        <Attachment
          key={a.id}
          attachment={a}
          changeName={changeName}
          readOnly={readOnly}
          deleteAttachment={this.deleteAttachment}
          editAttachment={this.editAttachment}
          changeRequired={changeRequired} />
      ));

      const attached = (attachments.length === 0 ? null : (
        <div>
          <FormattedMessage id='' defaultMessage='Requested attachments' description='Title for request attachment pop up modal, enterprise account when the attachment have been requested already' />
          <div className="attachment-button__requested-attachments-line" />
          <ul className="attachment-modal__attachment-list">
            {attachments}
          </ul>
        </div>
      ));

      const showForm = (
        !readOnly && (attachments.length < MAX_ATTACHMENTS || this.state.id != null)
      );

      const form = (showForm ? (
        <form onSubmit={this.handleSubmit}>
          <div className="attachment-button__form-group">
            <label className="attachment-button__form-label attachment-button__form-label--required" htmlFor={this.htmlId('name')}> <FormattedMessage id='' defaultMessage='Attachment Name' description='Attachment Name on the pop up modal for enterprise account' /></label>
            <input
              className={classnames('attachment-button__form-field', {
                'attachment-button__form-field--is-dirty': this.state.isDirty,
              })}
              id={this.htmlId('name')}
              value={this.state.name}
              placeholder={this.props.intl.formatMessage(messages.nameOfDocumentPlaceholder)}
              onChange={this.changeName}
              required={true} />
          </div>
          <div className="attachment-button__form-group">
            <label className="attachment-button__form-label" htmlFor={this.htmlId('instructions')}> <FormattedMessage id='' defaultMessage='Instructions'description='Instructions text for the textarea on the pop up modal for enterprise account' /> </label>
            <textarea
              className="attachment-button__form-field c-black"
              id={this.htmlId('instructions')}
              value={this.state.instructions}
              placeholder={this.props.intl.formatMessage(messages.textareaPlaceholder)}
              rows="5"
              onChange={this.changeInstructions} />
          </div>

          <div>
            <input id={this.htmlId('required')} type="checkbox" value={this.state.required} onChange={this.changeRequired} />
            <label className="attachment-button__form-label" htmlFor={this.htmlId('required')}><FormattedMessage id='' defaultMessage='Required' description='Required checkbox on the pop up modal for enterprise account' /></label>
          </div>

          <Button
            buttonBorderColor="cerulean"
            buttonColor="white"
            buttonTextColor="cerulean"
            buttonTextUppercase={false}
            buttonBorderHoverColor="cerulean"
            buttonHoverColor="white"
            buttonTextHoverColor="cerulean"
            className="attachment-button__submit"
            type="submit"
            disabled={(!this.state.id && !this.state.isDirty) || !this.state.name.trim().length}
            buttonText={this.state.id ? <FormattedMessage id='' defaultMessage='Update Attachment' description='Update attachment button on the pop up modal for enterprise account' />
              : <FormattedMessage id='' defaultMessage='Add Attachment' description='Add attachment button on the pop up modal for enterprise account' />} />
        </form>
      ) : null);

      let instructionText = <FormattedMessage id='' defaultMessage='Which attachments should the signer upload when signing? You may request up to five.' description='instruction text on the pop up modal for enterprise account ' />;
      let contentLabelMsg = <FormattedMessage id='' defaultMessage='Requested attachments' description='Title for request attachment pop up modal, enterprise account when the attachment have been requested already' />;

      if (readOnly) {
        instructionText = <FormattedMessage id='' defaultMessage='This template request includes the following attachments. To make modifications, please edit the template.' description='instruction text on the pop up modal for enterprise account when there are templates' />;
        contentLabelMsg = <FormattedMessage id='' defaultMessage='Requested attachments' description='Title for request attachment pop up modal, enterprise account when the attachment have been requested already' />;
      }

      return (
        <HelloModal
          shouldCloseOnOverlayClick={false}
          isOpen={true}
          onRequestClose={onRequestClose}
          buttons={[
            {
              type: 'primary',
              onClick: this.handleSave,
              text: <FormattedMessage id='' defaultMessage='Done' description='Done button on the pop up modal for enterprise account' />,
            },
          ]}
          contentLabel={contentLabelMsg}
        >
          <p className="attachment-modal__instructions">
            {instructionText}
          </p>

          {attached}

          {form}
        </HelloModal>
      );
    }
}
export default injectIntl(AttachmentModal, { forwardRef: true });
