/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names
*/
module.exports = function (instance, factory, properties) {
  if (!instance || !instance.setProperties) return factory.create(properties);
  instance.setProperties(properties);
  return instance;
};
