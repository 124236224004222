/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import TwitterIcon from '../../assets/social_twitter@2x.png';
import FacebookIcon from '../../assets/social_facebook@2x.png';
import LinkedInIcon from '../../assets/social_linkedin@2x.png';

function Social(p) {
  const title = p.title ? (
    <h2 style={{ marginBottom: 30 }}>{p.title}</h2>
  ) : null;

  return (
    <div className='seo-cloud-integrations__social' style={{ marginTop: 50 }}>
      {title}
      <a href={p.twitterLink} target='_blank' rel='noopener noreferrer'>
        <img src={TwitterIcon} alt='twitter share' />
      </a>
      <a href={p.facebookLink} target='_blank' rel='noopener noreferrer'>
        <img src={FacebookIcon} alt='facebook share' className='facebook-share' />
      </a>
      <a href={p.linkedInLink} target='_blank' rel='noopener noreferrer'>
        <img src={LinkedInIcon} alt='linkedin share' />
      </a>
    </div>
  );
}

module.exports = Social;
