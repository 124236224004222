/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  no-useless-escape
*/
import React from 'react';
import KiteImageSource from './assets/kite.svg';

export default function CancelOfferPlanDescription(p) {

  const planName = p.currentPlanInfo.name;
  const calloutText = `If you downgrade, you\'ll miss out on these great ${planName} features:`;

  const callout = (
    <div className='m-cancel-offer-plan-description--callout'>
      { calloutText }
    </div>
  );

  const planFeatures = p.offerPlanInfo.features;
  const features = (
    <ul>
      { planFeatures.map((feat, index) => {
        return <li key={`feature-${index}`}>{ feat }</li>;
      })}
    </ul>
  );

  const readMoreLink = (
    <a href='/info/tour'>
            Read more about our features.
    </a>
  );

  const imageStyle = { marginTop: -5, marginLeft: -15 };
  const kiteImage = <img src={KiteImageSource} style={imageStyle} />;

  return (
    <div className='m-cancel-offer-plan-description'>
      { callout }
      { features }
      { readMoreLink }
      { kiteImage }
    </div>
  );
}
