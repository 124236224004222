/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './captive-footer.scss';
import React from 'react';
import HsIconBlue from 'common/images/HS_icon_Blue.svg';

export default function CaptiveFooter() {
  return (
    <div className='m-captive-footer columns'>
      <div className='row'>
        <img className="logo" src={HsIconBlue} />
        <a href='/info/aboutUs' target='_blank' rel='noopener noreferrer'>About</a>
        <a id='footer_contact' href='#'>Contact Support</a>
        <span className='copyright'>
                    © {(new Date()).getFullYear()} HelloSign
        </span>
      </div>
    </div>
  );
}
