import React from 'react';
import PropTypes from 'prop-types';

/**
 * A generic email link component rendered as HTML anchor element a.
 *
 * @extends React.Component
 * @class
 */
export default class EmailLink extends React.Component {
  /**
   * Prop type definitions for this component.
   *
   * @static
   */
  static propTypes = {
    email: PropTypes.string.isRequired,
    emailSubject: PropTypes.string,
  };

  /**
   * Renders the React component.
   *
   * @returns {JSX.Element}
   * @override
   */
  render() {
    return (
      <a
        href={`mailto:${this.props.email}?subject=${encodeURIComponent(this.props.emailSubject)}`}
      >
        {this.props.email}
      </a>
    );
  }
}
