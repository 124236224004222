/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-continue,
  no-useless-escape,
  no-void,
  react/no-find-dom-node,
  react/no-string-refs
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import classNames from 'classnames';
import setInterval from 'common/utils/set-interval';
import createReactClass from 'create-react-class';
import ApiMenuSection from './section';

const ApiMenu = createReactClass({

  propTypes: {
    sections: PropTypes.array.isRequired, // A list of objects formatted as { title, url, reference, items: [{ title, reference, url, subItems: [{ title, reference, url }] }] }
    initiallyActiveSection: PropTypes.string, // Reference of the section intially active
    initiallyActiveItem: PropTypes.string, // Reference of the section item initially active
    initiallyActiveSubItem: PropTypes.string, // Reference of the section sub item initially active
    isInitiallyCollapsed: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      isInitiallyCollapsed: false,
    };
  },

  getInitialState() {
    return {
      isFixed: false,
      isCollapsed: this.props.isInitiallyCollapsed,
      activeSection: this.props.initiallyActiveSection,
      activeItem: this.props.initiallyActiveItem,
      activeSubItem: this.props.initiallyActiveSubItem,
    };
  },

  stickMenu() {
    this.setState({
      isFixed: true,
    });
  },

  unstickMenu() {
    this.setState({
      isFixed: false,
    });
  },

  startHashSpy(interval) {

    const self = this;

    // NOTE: Initialize to null so we detect a 'changed' event right after loading the page
    this.__hash = null;
    this.__ref = null;

    const getSelectionFromRef = function (ref) {
      const selection = {
        activeSection: undefined,
        activeItem: undefined,
        activeSubItem: undefined,
      };

      let s;
      let i;
      let j;
      let k;
      for (i = 0; i < self.props.sections.length; i++) {
        s = self.props.sections[i];

        // Try to match a section
        if (s.reference.toLowerCase() == ref) {
          selection.activeSection = ref;
        }

        // Try to match a section item
        if (!selection.activeSection && s.items && s.items.length > 0) {
          for (j = 0; j < s.items.length; j++) {
            if (s.items[j].reference.toLowerCase() == ref) {
              selection.activeSection = s.reference;
              selection.activeItem = ref;
              break;
            }
          }
        }

        // Try to match a sub item if nothing else matched
        if (!selection.activeSection) {
          for (j = 0; j < s.items.length; j++) {
            if (s.items[j].subItems && s.items[j].subItems.length > 0) {
              for (k = 0; k < s.items[j].subItems.length; k++) {
                if (s.items[j].subItems[k].reference.toLowerCase() == ref) {
                  selection.activeSection = s.reference;
                  selection.activeItem = s.items[j].reference;
                  selection.activeSubItem = s.items[j].subItems[k].reference;
                  break;
                }
              }
            }
            if (selection.activeSection) {
              break;
            }
          }
        }

        // Match found
        if (selection.activeSection) {
          break;
        }

      }

      // Update menu selection
      if (selection.activeSection) {

        const newStates = HfReactHelper.extend(selection, {
          isFixed: self.state.isFixed,
        });
        self.setState(newStates);
      }
    };

    // Listen for for hash changes
    setInterval(() => {

      const h = document.location.hash;
      if (h != self.__hash) {

        self.__hash = h;

        const path = document.location.pathname.split('/').pop();
        let ref = h ? h.replace('#', '') : path;
        if (!h && path == 'documentation') {
          ref = 'quickstart';
        } else if (ref) {
          ref = ref.toLowerCase();
        }

        getSelectionFromRef(ref);

      }
    }, interval);

    // Listen for selection change from scrolling
    $(document).bind('menu_selection_change', function (evt, evtData) {
      if (evtData && evtData.ref && (!this.__ref || evtData.ref != this.__ref)) {
        getSelectionFromRef(evtData.ref.toLowerCase());
        this.__ref = evtData.ref;
      }
    });
  },

  /**
     * If user scrolls down a certain distance, update this.state.isFixed to
     * true. Also, if the window is smaller than the menu, the menu should be
     * fixed, otherwise you the user can't see the whole menu without scrolling
     * all the way down the page.
    */
  startStickinessSpy(interval) {

    const self = this;
    let lastTopOffset = 0;
    const fixedPadding = 16;
    let fixed = false;
    const menu = $(ReactDOM.findDOMNode(this));

    let el = $(document.body);
    const ua = navigator.userAgent.toLocaleLowerCase();
    if (/firefox/.test(ua) || /msie/.test(ua) || /Windows NT 6.3.*rv\:11\.0/gi.test(ua)) {
      // Special case for FF and IE (scrollTop is defined on html and not body)
      el = $('html');
    }

    setInterval(() => {

      const topOffset = el.scrollTop();

      if (topOffset != lastTopOffset) {

        lastTopOffset = topOffset;
        const menuContainer = $('#api-docs-menu-container');
        const componentHeight = self.refs.apiDocMenu.scrollHeight;
        const windowHeight = window.innerHeight;

        const shouldEnableStick = !fixed
                    && topOffset > menuContainer.offset().top - fixedPadding
                    && windowHeight > componentHeight;

        const shouldDisableStick = fixed
                    && topOffset <= menuContainer.offset().top - fixedPadding;

        if (shouldEnableStick) {
          self.stickMenu(menu);
          fixed = true;
        }

        if (shouldDisableStick) {
          self.unstickMenu(menu);
          fixed = false;
        }
      }
    }, interval);
  },

  startScrollSpy(interval) {

    const sections = $('.m-api-docs-section, .m-api-ref-section, .m-api-docs-sub-section[id]');
    const hasRefSections = {};
    const hasSubSections = {};
    let currentSection = null;
    const w = $(window);

    sections.each(function () {
      const el = $(this);
      const ref = el.attr('id');
      if ($('.m-api-ref-section', el).length > 0) {
        hasRefSections[ref] = true;
      }
      if ($('.m-api-docs-sub-section[id]', el).length > 0) {
        hasSubSections[ref] = true;
      }
    });

    setInterval(() => {

      let t;
      const top = w.scrollTop();

      // Check if it has changed before doing anything
      if (currentSection !== null) {
        const r = currentSection.attr('id');
        if (!hasSubSections[r] && !hasRefSections[r]) {
          t = currentSection.offset().top;
          if (top >= t && top < t + currentSection.height()) {
            // No change
            return;
          }
        }
      }

      // Reset current selection
      currentSection = null;

      let s;
      let delta = void 0;
      for (let i = 0; i < sections.length; i++) {

        s = $(sections[i]);
        t = s.offset().top;

        if (top >= t && top < t + s.height() && (delta === null || Math.abs(top - t) < delta)) {

          const ref = s.attr('id');

          delta = Math.abs(top - t);
          currentSection = s;

          if (hasRefSections[ref] || hasSubSections[ref]) {
            // Keep going there could be a sub section that's a better match
            continue;
          } else {
            break;
          }
        }
      }

      if (currentSection !== null) {
        // Update menu
        $(document).trigger('menu_selection_change', { ref: currentSection.attr('id') });
      }

    }, interval);
  },

  /**
     * After component mounts, initialize spies
    */
  componentDidMount() {
    this.startHashSpy(200);
    this.startScrollSpy(100);
    this.startStickinessSpy(100);
  },

  renderApiMenuSections(section) {
    const isActive = (this.state.activeSection && this.state.activeSection.toLowerCase() === section.reference.toLowerCase());
    const isCollapsed = section.items === undefined || (!isActive && section.items && this.state.isCollapsed);

    return <ApiMenuSection
      key={`section_${section.reference}`}
      title={section.title}
      reference={section.reference}
      url={section.url}
      items={section.items}
      initiallyActiveItem={this.state.activeItem}
      initiallyActiveSubItem={this.state.activeSubItem}
      isInitiallyActive={isActive}
      isInitiallyCollapsed={isCollapsed}
      menuIsFixed={this.state.isFixed} />;
  },

  render() {
    const sections = this.props.sections.map(this.renderApiMenuSections);
    const classes = classNames({
      'l-api-docs-menu': true,
      'is-fixed': this.state.isFixed,
    });

    return (
      <div ref='apiDocMenu' className={classes}>
        { sections }
      </div>
    );
  },
});

module.exports = ApiMenu;
