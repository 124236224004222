import { getEndpointResource, postEndpointResource, postJSONEndpointResource } from 'hellospa/common/api/endpoints';
import { queryParams } from 'hellospa/common/utils/fetch';

/**
 * @typedef {Object} UsersAPI~Entity
 * @property {string} guid
 * @property {string} name
 */
/**
 * @typedef {Object} UsersAPI~BasicUser
 * @property {string} guid
 * @property {string} emailAddress
 * @property {string} name
 */

/**
 * @typedef {UsersAPI~BasicUser} UsersAPI~User
 * @property {string} lastActive
 * @property {number} numTemplates
 * @property {UsersAPI~Entity} role
 * @property {string} status
 * @property {UsersAPI~Entity} team
 */

/**
 * @typedef {EndpointsAPI~PagedResponse} UsersAPI~PagedUsersResponse
 * @property {number} data.numUsers
 * @property {UsersAPI~User[]} data.users
 */

/**
 * @typedef {UsersAPI~Entity} UsersAPI~Template
 * @property {string} status
 */

/**
 * @typedef {EndpointsAPI~PagedResponse} UsersAPI~PagedTemplatesResponse
 * @property {number} data.numTemplates
 * @property {UsersAPI~Template[]} data.templates
 */

/**
 * @param {File} file
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {boolean} [opts.confirmPaidSeatCountIncrease]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function bulkInvite(file, csrfToken, opts) {
  const body = new FormData();

  body.append('file', file);
  body.append('csrfToken', csrfToken);

  // Force paid seat count increase if subscription does
  // not have enough seats to invite all users in CSV.
  if (opts.confirmPaidSeatCountIncrease) {
    body.append('confirmPaidSeatCountIncrease', 1);
  }

  return postEndpointResource('/org/bulkInvite', {
    body,
  });
}

/**
 * @param {number} page
 * @param {number} pageSize
 * @param {?string} team
 * @param {?boolean} recursive
 * @returns {Promise.<UsersAPI~PagedUsersResponse>}
 */
export function listUsers(page, pageSize, team, recursive) {
  const qs = queryParams({
    team,
    page,
    page_size: pageSize,
    recursive,
  });

  return getEndpointResource(`/org/listUsers${qs}`);
}

/**
 * @param {string} guid
 * @returns {Promise.<UsersAPI~GetTransferableUsersResponse}
 */
export function getTransferableUsers(guid) {
  const qs = queryParams({ guid });

  return getEndpointResource(`/org/getTransferableUsers${qs}`);
}

/**
 * @param {string} guid
 * @returns {Promise.<UsersAPI~User>}
 */
export function getUser(guid) {
  const qs = queryParams({ guid });

  return getEndpointResource(`/org/getUser${qs}`);
}

/**
 * @param {string} guid
 * @param {number} page
 * @param {number} pageSize
 * @returns {Promise.<UsersAPI~PagedTemplatesResponse>}
 */
export function listUserTemplates(guid, page, pageSize) {
  const qs = queryParams({ guid, page, page_size: pageSize });

  return getEndpointResource(`/org/listUserTemplates${qs}`);
}

/**
 * @typedef {UsersAPI~Entity} UsersAPI~Invite
 * @property {string} emailAddress
 * @property {string} dateInvited
 * @property {{ guid: string, name: string }} team
 * @property {?string} firstName
 * @property {?string} lastName
 */

/**
 * @typedef {EndpointsAPI~PagedResponse} UsersAPI~PagedInvitesResponse
 * @property {UsersAPI~Invite[]} data.invites
 * @property {number} data.numInvites
 */

/**
 * @param {number} page
 * @param {number} pageSize
 * @param {string} [team]
 * @param {boolean} [recursive]
 * @returns {Promise.<UsersAPI~PagedInvitesResponse>}
 */
export function listInvites(page, pageSize, team, recursive) {
  const qs = queryParams({
    page,
    page_size: pageSize,

    // These params are optional
    ...(team ? { team } : {}),
    ...(recursive ? { recursive } : {}),
  });

  return getEndpointResource(`/org/listInvitedUsers${qs}`);
}

/**
 * @param {string} emailAddress
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.role]
 * @param {string} [opts.teamGuid]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function sendInvite(emailAddress, csrfToken, opts) {
  return postJSONEndpointResource('/org/inviteUser', {
    body: JSON.stringify({
      csrfToken,
      emailAddress,
      ...opts,
    }),
  });
}

/**
 * @param {string} emailAddress
 * @param {string} teamGuid
 * @param {string} csrfToken
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function cancelInvite(emailAddress, teamGuid, csrfToken) {
  return postJSONEndpointResource('/org/cancelInvite', {
    body: JSON.stringify({
      csrfToken,
      emailAddress,
      teamGuid,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.recipientGuid]
 * @param {string} [opts.role]
 * @param {string} [opts.teamGuid]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function editUser(guid, csrfToken, opts) {
  return postJSONEndpointResource('/org/editUser', {
    body: JSON.stringify({
      csrfToken,
      guid,
      ...opts,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function lockUser(guid, csrfToken) {
  return postJSONEndpointResource('/org/lockUser', {
    body: JSON.stringify({
      csrfToken,
      guid,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function unlockUser(guid, csrfToken) {
  return postJSONEndpointResource('/org/unlockUser', {
    body: JSON.stringify({
      csrfToken,
      guid,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function resetPassword(guid, csrfToken) {
  return postJSONEndpointResource('/org/resetPassword', {
    body: JSON.stringify({
      csrfToken,
      guid,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {number} [opts.delete]
 * @param {string} [opts.recipientGuid]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function removeUser(guid, csrfToken, opts) {
  return postJSONEndpointResource('/org/removeUser', {
    body: JSON.stringify({
      csrfToken,
      guid,
      ...opts,
    }),
  });
}
