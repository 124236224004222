// @flow
import * as React from 'react';
import TetheredComponent from '../../tethered';

type Props = {
    anchor: React.Node,
    children: React.Node,
    disabled?: boolean
};

type State = {
    active: boolean
};


const { Component } = React;

export const DELAY = 100;

/**
 * This component takes the same props as the `Tethered` component, but toggles
 * the content when the `anchor` is interacted with.
 */
export class TetherToggle extends Component<Props, State> {
    /* global SyntheticMouseEvent */
    handleMouseEnter: (SyntheticMouseEvent<>) => void;

    handleMouseLeave: (SyntheticMouseEvent<>) => void;

    timeout: number;

    constructor(props: Props) {
      super(props);

      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);

      this.state = {
        active: false,
      };
    }

    /**
     * We wrap the mouse events in a short timer to prevent sudden closures of
     * the menu if the user moves outside of the component.  This can
     * happen if there's some space between the anchor and the menu itself.
     */
    handleMouseEnter() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.setState({ active: true });
      }, DELAY);
    }

    handleMouseLeave() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.setState({ active: false });
      }, DELAY);
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    render() {
      const { active } = this.state;
      const {
        anchor, children, disabled, ...rest
      } = this.props;

      return (
        <div
          onMouseEnter={!disabled && this.handleMouseEnter}
          onMouseLeave={!disabled && this.handleMouseLeave}
        >
          <TetheredComponent active={active} anchor={anchor} {...rest}>
            {children}
          </TetheredComponent>
        </div>
      );
    }
}

export default TetherToggle;
