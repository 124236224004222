import superagent from 'superagent';
import hsFetch from 'hellospa/hs-fetch';

export default function bulkSendActions(csrfToken) {

  async function bulkSendInit() {
    const response = await hsFetch('/bulksend/init', {
      credentials: 'same-origin',
    });

    if (response.status === 200) {
      const data = await response.json();

      return {
        signerKey: data.signer_key,
        isCCEnabled: data.is_cc_enabled,
        form: {
          lockedTitle: data.form.locked_title,
          lockedMessage: data.form.locked_message,
        },
      };
    }

    if (response.status >= 400 && response.status < 500) {
      return {
        signerKey: false,
        isCCEnabled: false,
        form: {},
      };
    }

    throw response;
  }

  async function bulkSendRename(guid, title) {

    // Anything returned here will get merged into the local document.
    return { title };
  }

  async function bulkSendUploadCSV({
    file, progressCallback, signerKey, templateIds,
  }) {

    // superAgent doesn't support `template_ids[]=...&template_ids[]=...`, if
    // you pass an array it just joins them with commas. This may be due to us
    // using a Superagent released in 2015, but I don't think its worth taking
    // the time to upgrade it now when I have this workaround. I'd also rather
    // just move to fetch as it's a web standard that is  available now in all
    // modern browsers.
    const templateParameter = templateIds
      .map((id) => `template_ids[]=${id}`)
      .join('&');


    return new Promise((resolve, reject) => {
      // I need to use superagent because fetch doesn't support reporting
      // progress (yet?). Somehow it might be possible in stream mode, but I
      // couldn't find any examples of uploading.
      superagent.post('/bulksend/signers')
        .query(templateParameter)
        .query({
          signer_key: signerKey,
        })
        .field('csrf_token', csrfToken)
        .attach('signer_file', file)
        .on('progress', (e) => progressCallback(e.percent))
        .end((err, res) => {
          if (err) {
            try {
              const { error } = JSON.parse(res.text);
              reject(new Error(error));
            } catch (e) {
              // Ignore the parse error and just throw the original error
              reject(err);
            }
          }
          return resolve();
        });
    });
  }

  async function bulkSendSubmit({
    signerKey, title, message,
  }) {
    const formData = new FormData();
    formData.append('signer_key', signerKey);
    formData.append('title', title);
    formData.append('message', message);
    formData.append('csrf_token', csrfToken);

    const response = await hsFetch('/bulksend', {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });

    await response.json();

    window.location = '/home/manage?filter=bulk';
  }

  /* eslint-disable no-console */
  async function bulkSendDownloadSigned(guid) {
    const formData = new FormData();
    formData.append('csrf_token', csrfToken);

    const result = await hsFetch(`/bulksend/download/bulk_send_id/${guid}`, {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });
    const data = await result.json();
    if (data.error) {
      throw new Error(data.error);
    }
  }

  async function bulkSendShare(guid, emails) {
    const formData = new FormData();
    formData.append('emails', emails);
    formData.append('bulk_send_id', guid);
    formData.append('csrf_token', csrfToken);

    const result = await hsFetch('/bulksend/share', {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });

    const data = await result.json();
    if (data.error) {
      throw new Error(data.error);
    }
  }

  function bulkSendCancelUnsigned(guid) {
    console.log('bulkSendCancelUnsigned', guid);
  }
  async function bulkSendDelete(guid) {
    const formData = new FormData();
    formData.append('csrf_token', csrfToken);

    const result = await hsFetch(`/bulksend/delete/bulk_send_id/${guid}`, {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });
    const data = await result.json();
    if (data.error) {
      throw new Error(data.error);
    }
  }

  async function bulkSendRemove(guid, signers) {
    const formData = new FormData();

    formData.append('bulk_send_job_id', guid);
    formData.append('super_group_ids', JSON.stringify(signers));
    formData.append('csrf_token', csrfToken);

    const result = await hsFetch('/bulksend/remove', {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });

    const data = await result.json();

    if (data.error) {
      throw new Error(data.error);
    }

    window.location = '/home/manage?filter=bulk';
  }

  async function bulkSendRemind(guid, signers) {
    const formData = new FormData();

    formData.append('bulk_send_job_id', guid);
    formData.append('super_group_ids', JSON.stringify(signers));
    formData.append('csrf_token', csrfToken);

    const result = await hsFetch('/bulksend/remind', {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });

    const data = await result.json();

    if (data.error) {
      throw new Error(data.error);
    }
  }

  return {
    bulkSendCancelUnsigned,
    bulkSendDelete,
    bulkSendDownloadSigned,
    bulkSendInit,
    bulkSendRename,
    bulkSendShare,
    bulkSendSubmit,
    bulkSendUploadCSV,
    bulkSendRemove,
    bulkSendRemind,
  };
}
