/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import baseform from 'common/components/base-form';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import setTimeout from 'common/utils/set-timeout';
import PasswordRecoveryResponse from './password-recovery-response';


/**
 * NOTE: This form is a good example of how to use
 * Symfony and React forms together
 */
const ForgotPasswordForm = baseform.createFormClass({

  displayName: 'ForgotPasswordForm',

  propTypes: {
    tabOffset: PropTypes.number,
    emailAddress: PropTypes.string,
  },

  getInitialState() {
    return {
      emailAddress: this.props.emailAddress,
    };
  },

  componentDidMount() {
    const root = $(ReactDOM.findDOMNode(this));
    setTimeout(() => {
      root.find('input').focus();
    }, 500);
  },

  onEmailChange(event) {
    this.setState({ emailAddress: event.target.value.trim() });
  },

  onSubmit(evt) {

    evt.preventDefault();

    // Validate email
    if (!HfReactHelper.isValidEmailAddress(this.state.emailAddress)) {
      this.setState({
        errors: { named: { email_address: 'Please enter a valid email address' } },
        hasErrors: true,
      });
      return;
    }

    this.setState({
      errors: null,
      hasErrors: false,
    });

    const root = $(ReactDOM.findDOMNode(this));

    this.submitAsync((resp) => {

      root.closest('.reveal-modal').foundation('reveal', 'close');
      const attachPoint = HfReactHelper.getRevealAttachPoint('password_recovery_success', 'tiny');
      ReactDOM.render(
        <PasswordRecoveryResponse
          isSuccessful={resp.success}
        >
        </PasswordRecoveryResponse>,
        attachPoint,
      );

    });
  },

  render() {

    const tabOffset = parseInt(this.props.tabOffset || 0, 10);

    const inputField = this.renderField('email_address', {
      tabIndex: tabOffset + 1,
      className: 'm-modal--text-input',
      placeholder: 'Email',
      'data-format': 'email',
      onChange: this.onEmailChange,
      value: this.state.emailAddress,
    });

    return (
      <form>
        { inputField }
        { this.renderHiddenFields() }
        <div className='m-modal--error'>
          { this.renderErrorText() }
        </div>
        <Button buttonText='Reset Password' buttonTabIndex={tabOffset + 2} onClick={this.onSubmit} buttonColor='grasshopper' />
      </form>
    );
  },
});

module.exports = ForgotPasswordForm;
