/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* global dataLayer */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  no-void,
  react/no-find-dom-node,
  react/no-unescaped-entities
*/
import './contact-us-modal.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import MarketoForm from 'hellosign/components/marketo-form';
import createReactClass from 'create-react-class';
import ContactUsSuccess from './contact-us-success';

/**
 * This is a Contact Us modal used on the HelloSign web and API pricing pages.
 */
const ContactUsModal = createReactClass({

  propTypes: {
    timePeriod: PropTypes.string, // FIXME: We aren't actually using this
    pricingSource: PropTypes.string,
    headerText: PropTypes.string,
    onSuccess: PropTypes.func,
    isApi: PropTypes.bool,
  },

  getInitialState() {
    return {
      timePeriod: 'Y',
    };
  },

  getDefaultProps() {
    return {
      onSuccess: void 0,
      headerText: 'Need to add more seats?',
    };
  },

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      const $root = $(ReactDOM.findDOMNode(this));
      const $modal = $root.closest('.reveal-modal');
      const $doc = $(document);

      $doc.foundation();

      $doc.on('open.fndtn.reveal', () => {
        document.body.classList.add('legacy-foundation-modal-visible');
      });

      $doc.one('closed.fndtn.reveal', () => {
        document.body.classList.remove('legacy-foundation-modal-visible');
      });

      $modal.foundation('reveal', 'open');
    }

    this.reportAnalytics();
  },

  componentWillReceiveProps() {
    if (process.env.NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  reportAnalytics() {
    if (process.env.NODE_ENV !== 'test') {
      dataLayer.push({
        event: 'modal_popup',
        pageName: this.props.isApi ? '/enterprise-api-contact-us' : '/enterprise-lp-contact-us',
        pageType: this.props.isApi ? 'enterprise-api-contact-us' : 'enterprise-lp-contact-us',
        virtualPagePath: this.props.isApi ? '/enterprise-api-contact-us' : '/enterprise-lp-contact-us',
        productPlan: 'enterprise',
        productType: this.props.isApi ? 'hsapi' : 'hs',
        subscriptionPlan: null,
      });
    }
  },

  // Set up handler for when form is successfully submitted
  handleMarketoFormLoaded(form) {
    form.onSuccess(() => {
      // Show thank you message
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'close');
      hellofaxJS.trackGoogleAnalyticsEvent('sales', 'contactus');
      if (this.props.onSuccess) {
        this.props.onSuccess();
      } else {
        const attachPoint = HfReactHelper.getRevealAttachPoint('contact_us_modal_success', 'medium');
        ReactDOM.render(<ContactUsSuccess />, attachPoint);
      }

      // Return false to prevent the submission handler from taking the lead to the follow up url
      return false;
    });
  },

  render() {
    // Change some stuff based on the pricingSource (web or api)
    let modalHeader = this.props.headerText;
    let marketoFormId = 1116; // Non-API HelloSign Pricing Page Form
    if (this.props.pricingSource == 'api') {
      modalHeader = 'Need more?';
      marketoFormId = 1115; // API Pricing Page Form
    } else if (HfReactHelper.isHelloFax()) {
      marketoFormId = 1379; // HelloFax Pricing Page form
    }

    return (
      <div className='m-contact-us row text-center'>
        <div className='columns small-12'>
          <h3>{ modalHeader }</h3>
        </div>
        <div className='row'>
          <div className='columns small-10 small-offset-1 l-padding-t-10 l-padding-b-20'>
            <p>
              Please contact us below and we'll get back to you right away!
            </p>
          </div>
          <div className='columns small-12'>
            <MarketoForm formId={marketoFormId} onLoad={this.handleMarketoFormLoaded} trackingId={'contactus'} />
          </div>
        </div>
      </div>
    );
  },
});

module.exports = ContactUsModal;
