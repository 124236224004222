/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  block-scoped-var,
  eqeqeq,
  max-len,
  no-redeclare,
  no-var,
  no-void,
  vars-on-top
*/
import _mapAPIOperation from 'common/utils/map-http-operation';

function _clone(data) {
  return data != void 0 ? JSON.parse(JSON.stringify(data)) : void 0;
}

export default {
  create(app) {

    return {
      user: {
        load() {
          return Promise.resolve(_clone(app.serverSideGeneratedData.users[0]));
        },
      },
      team: {
        load() {
          return Promise.resolve(_clone(app.serverSideGeneratedData.teams[0]));
        },
        update(message) {
          return _mapAPIOperation({
            url: '/account/editTeamName',
            method: 'POST',
            data: {
              team_name: message.target.name,
              csrfToken: message.target.data.csrfToken,
            },
          });
        },
      },
      faxLines: {
        load() {
          return Promise.resolve(_clone(app.serverSideGeneratedData.faxLines));
        },
        insert(message) {
          return _mapAPIOperation({
            url: '/content/registerFaxLine',
            method: 'POST',
            data: {
              register_fax_line: {
                _csrf_token: message.target.csrfToken,
              },
              fax_line_guid: message.target.guid,
            },
          });
        },
        update(message) {
          return _mapAPIOperation({
            url: '/home/updateFaxLinePermissions',
            method: 'POST',
            data: {
              account_guids: message.target.teamMembers.map((tm) => {
                return tm.guid;
              }),
              fax_line_guid: message.target.guid,
            },
          });
        },
        remove(message) {
          return _mapAPIOperation({
            url: '/content/removeFaxLine',
            method: 'POST',
            data: {
              guid: message.target.guid,
            },
          });
        },
      },
      teamMembers: {
        load() {
          // check for pagination count
          return Promise.resolve(_clone(app.serverSideGeneratedData.teamMembers));
        },
        insert(message) {

          return _mapAPIOperation({
            url: '/account/addTeamMember',
            method: 'POST',
            data: {
              emails: [message.target.emailAddress],
              csrf_token: message.target.csrfToken,
            },
            transformResponse(data) {

              if (data.success === 'success') {

                const modelData = message.target.toData();

                // update local cache
                app.serverSideGeneratedData.teamMembers.push(modelData);

                var result = {
                  success: true,
                  guid: data[0].acct_guid,
                  emailAddress: message.target.emailAddress,
                  pending: !!data[0].pending_acceptance || data[0].pending_email_confirmation,
                  pending_acceptance: data[0].pending_acceptance,
                  pending_email_confirmation: data[0].pending_email_confirmation,
                  warning: data[0].warning,
                };

                Object.assign(modelData, result);

                return result;
              } else {

                // Account was NOT added to the team
                var result = {};
                if (Array.isArray(data) && data.length > 0) {
                  result = data[0];
                } else if (typeof data === 'object') {
                  result = data;
                }
                result.success = false;
                return result;
              }

            },
          });
        },
        update(message) {

          // for role changes
          let pathname = {

            a: '/account/promoteMemberToAdmin',
            d: '/account/promoteMemberToDeveloper',
            m: '/account/demoteAdminToMember',
            r: '/account/promoteMemberToTeamManager',

          }[message.target.data.roleCode != message.target.roleCode ? message.target.roleCode : null];

          // for lock changes
          const isLockChange = (message.target.data.isLocked != message.target.isLocked);
          if (isLockChange) {
            pathname = message.target.isLocked ? '/account/lockTeamMember' : '/account/unlockTeamMember';
          }

          // for password reset
          if (message.target.shouldResetPassword) {
            pathname = '/account/resetPasswordTeamMember';
          }

          // update model
          const data = {
            isLocked: message.target.isLocked,
            roleCode: message.target.roleCode,
            shouldResetPassword: false,
          };
          app.serverSideGeneratedData.teamMembers.forEach((record) => {
            if (record.guid === message.target.data.guid) {
              Object.assign(record, data);
            }
          });

          return _mapAPIOperation({
            url: pathname,
            method: 'POST',
            data: {
              team_member_email: message.target.emailAddress,
              csrf_token: message.target.csrfToken,
            },
          });
        },
        remove(message) {
          app.serverSideGeneratedData.teamMembers.forEach((record, i) => {
            if (record.guid === message.target.data.guid
                            && record.emailAddress === message.target.data.emailAddress) {
              app.serverSideGeneratedData.teamMembers.splice(i, 1);
            }
          });

          return _mapAPIOperation({
            url: '/account/removeTeamMember',
            method: 'POST',
            data: {
              team_member_email: message.target.emailAddress,
              is_invite: message.target.pending ? 1 : void 0,
              csrf_token: message.target.csrfToken,
              transfer_to_id: message.target.transferToAcct || null,
              delete_after_removal: message.target.deleteAfterRemoval ? 1 : 0,
            },
          });
        },
      },
    };
  },
};
