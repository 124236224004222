/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  camelcase,
  eqeqeq,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  max-len,
  no-bitwise,
  no-multi-assign,
  no-param-reassign,
  no-prototype-builtins,
  no-restricted-globals,
  no-restricted-syntax,
  no-useless-escape,
  no-var,
  one-var,
  prefer-const,
  prefer-rest-params,
  vars-on-top
*/
import config from 'common/config';
import deepExtend from 'lodash/object/merge';

// fixes exception thrown in IE
// from this: http://stackoverflow.com/questions/25754622/reveal-standalone-module-not-working
if (typeof Foundation !== 'undefined') {
  Foundation.global.namespace = '';
}

const fontCharMapDecode = function (str) {
  let row;
  let key;
  const rows = str.split('\n');
  const chunks = {};
  for (let i = 0; i < rows.length; i++) {
    row = rows[i];
    // find the row identifier
    key = (row.match(/^([a-z0-9]{4}): /))[1];
    if (key.length != 4) {
      throw new Error('Key not found');
    }
    // find the 32 bit chunks
    chunks[key] = row.match(/[a-z0-9]{8}/g);
    for (let j = 0; j < chunks[key].length; j++) {
      chunks[key][j] = parseInt(chunks[key][j], 16);
    }
    if (chunks[key].length != 8) {
      throw new Error('Row data not found');
    }
  }
  return chunks;
};

var HfReactHelper = module.exports = deepExtend({}, config, {

  imgHelper(input_string) {

    let domain = HfReactHelper.hfCdn;
    if (typeof location !== 'undefined') {
      domain = String(location.hostname).indexOf('hellofax') !== -1 ? HfReactHelper.hfCdn : HfReactHelper.hsCdn;
    }

    return `//${domain}/images/${input_string}`;
  },

  urlHelper(input_string, site, use_subdomain) {
    if (site !== HfReactHelper.HfSites.SITE_CODE_HF && site !== HfReactHelper.HfSites.SITE_CODE_HS) {
      site = HfReactHelper.isHelloSign() ? HfReactHelper.HfSites.SITE_CODE_HS : HfReactHelper.HfSites.SITE_CODE_HF;
    }
    const subDomain = use_subdomain || HfReactHelper.getCurrentSubdomain();
    // If you aren't using `use_subdomain`, then `const whatever =
    // HfReactHelper.urlHelper('api/whatever/', site)` can just be replaced with
    // `const whatever = '/api/whatever/'`.
    const url = HfReactHelper.urlHelperUrl
      ? `${HfReactHelper.urlHelperUrl.replace('__subdomain__', subDomain).replace('__site__', site)}/${input_string}`
      : input_string;

    return url;
  },

  getCurrentSubdomain() {
    const hostParts = window.location.host.split('.');
    if (hostParts.length > 1) {
      return hostParts[0];
    } else {
      return HfReactHelper.defaultSubdomain;
    }
  },

  extend() {
    for (let i = 1; i < arguments.length; i++) {
      for (const key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) {
          arguments[0][key] = arguments[i][key];
        }
      }
    }
    return arguments[0];
  },

  trim(s) {
    if (s && s.length > 0) {
      for (var a = 0; a < s.length; a++) {
        if (s[a] !== ' ') {
          break;
        }
      }
      for (var b = s.length - 1; b >= 0; b--) {
        if (s[b] !== ' ') {
          break;
        }
      }
      return s.slice(a, b + 1);
    } else {
      return '';
    }
  },

  inArray(elem, array) {
    if (array.indexOf) {
      return array.indexOf(elem);
    }

    for (let i = 0, length = array.length; i < length; i++) {
      if (array[i] === elem) {
        return i;
      }
    }

    return -1;
  },

  isValidEmailAddress(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@\']+(\.[^<>()\[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  isValidPhoneNumber(number) {
    const re = /^(1[\s-]?)?[\(\[\s-]{0,2}?\d{3}[\)\]\s-]{0,2}?\d{3}[\s-]?\d{4}$/;
    return re.test(number);
  },

  /*
    * Helper function for Foundation modals - cf: http://foundation.zurb.com/sites/docs/v/5.5.3/components/reveal.html
    */
  getRevealAttachPoint(element_id, size, showCloseButton, className) {

    let attachPoint;
    let modalContainer = document.getElementById(element_id);

    if (modalContainer === null) {
      modalContainer = document.createElement('div');
      modalContainer.id = element_id;
      let sizeClass = 'm-modal-restricted-size';
      if (typeof size !== 'undefined') {
        sizeClass = `m-modal ${size}`;
      }
      modalContainer.className = `reveal-modal ${sizeClass}`;
      if (typeof className !== 'undefined') {
        modalContainer.className += ` ${className}`;
      }
      modalContainer.setAttribute('data-reveal', '');

      if (typeof showCloseButton === 'undefined' || showCloseButton !== false) {
        const closeButton = document.createElement('a');
        closeButton.className = 'close-reveal-modal m-modal--close';
        closeButton.innerHTML = '&#215;';
        modalContainer.appendChild(closeButton);
      }

      attachPoint = document.createElement('div');
      attachPoint.className = 'm-modal--container';
      modalContainer.appendChild(attachPoint);
    } else {
      attachPoint = modalContainer.getElementsByTagName('div')[0];
    }

    // Append the modal container when we're done so
    // that the browser is not painting and doing reflow
    // calculations unnecessarily.
    document.body.appendChild(modalContainer);

    return attachPoint;
  },

  formatMoney(amount, currency, isInCents) {

    if (isInCents === true) {
      amount /= 100.0;
    }

    const rounded = parseInt(amount, 10);
    const cents = parseInt((amount - rounded) * 100, 10);

    return (currency !== undefined ? currency : '') + rounded + (cents > 0 ? `.${cents}` : '');
  },

  isHelloFax() {
    return !!~location.hostname.indexOf('hellofax');
  },

  isHelloSign() {
    return !!~location.hostname.indexOf('hellosign');
  },

  // deprecate this (CC)
  HfSites: {

    isHelloFax(siteOrSiteCode) {
      return (siteOrSiteCode === HfReactHelper.HfSites.SITE_CODES[0] || siteOrSiteCode === HfReactHelper.HfSites.SITE_CODE_HF);
    },

    isHelloSign(siteOrSiteCode) {
      return (siteOrSiteCode === HfReactHelper.HfSites.SITE_CODES[1] || siteOrSiteCode === HfReactHelper.HfSites.SITE_CODE_HS);
    },

    isHelloWorks(siteOrSiteCode) {
      return (siteOrSiteCode === HfReactHelper.HfSites.SITE_CODES[2] || siteOrSiteCode === HfReactHelper.HfSites.SITE_CODE_HW);
    },

    getSite(siteCode) {
      return this[`SITE_CODE_H${siteCode}`];
    },
  },

  hasLocalStorage() {
    // in a try/catch block since this method of checking is more compatible with
    // other browsers. See http://stackoverflow.com/questions/16427636/check-if-localstorage-is-available.
    try {
      const k = '__local_storage_check__';
      localStorage.setItem(k, true);
      localStorage.removeItem(k);
      return true;
    } catch (e) {
      return false;
    }
  },

  FONT_CHAR_MAP: {
    'La Belle Aurore': fontCharMapDecode('0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n'
                                             + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                             + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                             + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                             + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                             + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                             + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
    'Mr De Haviland': fontCharMapDecode('0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n'
                                            + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                            + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                            + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                            + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                            + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                            + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
    'Nothing You Could Do': fontCharMapDecode('0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffff7fff ffffffff ffffffff\n'
                                                  + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                                  + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                                  + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                                  + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                                  + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                                  + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
    Zeyada: fontCharMapDecode('0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n'
                                    + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                    + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                    + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                    + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                    + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                    + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
    'Dawning of a New Day': fontCharMapDecode('0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffff7fff ffffffff ffffffff\n'
                                                  + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                                  + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                                  + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                                  + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                                  + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                                  + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
    'Herr Von Muellerhoff': fontCharMapDecode('0000: 00000000 ffffffff bfffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n'
                                                  + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                                  + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                                  + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                                  + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                                  + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                                  + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
    'Over the Rainbow': fontCharMapDecode('0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n'
                                              + '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n'
                                              + '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n'
                                              + '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n'
                                              + '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                              + '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n'
                                              + '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000'),
  },

  // Getter to safely retrieve localStorage and avoid throwing tons of exceptions
  // in private browsing mode or when stricter security rules are in application
  getLocalStorage() {

    try {
      if (typeof localStorage !== 'undefined') {
        return localStorage;
      }
    } catch (e) {
      if (e.name !== 'SecurityError') {
        // Only skip if it's a security error
        throw e;
      }
    }

    // Return stub
    return {
      setItem() {},
      getItem() {},
      removeItem() {},
    };
  },

  attachSessionInfoToUrl(url) {
    if (window.hellofaxJS) {
      url = hellofaxJS.attachSessionInfoToUrl(url);
    }
    return url;
  },

  Cookie: {
    get(key) {
      let parts,
        pairs = document.cookie.split(';');
      for (const k in pairs) {
        parts = $.trim(pairs[k]).split('=');
        if (parts.shift() == key) {
          return parts.join('=');
        }
      }
    },

    set(key, value, ttl_ms, path) {
      let c = `${key}=${value}`;
      if (ttl_ms !== undefined) {
        const d = new Date();
        d.setTime(d.getTime() + ttl_ms);
        c += `; expires=${d.toUTCString()}`;
      }
      if (path === undefined) {
        // Strip any characters that don't conform to RFC 3986 (http://www.ietf.org/rfc/rfc3986.txt)
        path = document.location.pathname.replace(/^[A-Za-z0-9\-\._~\:\/\?#\[\]\@\!\$\&\'\(\)\*\+,;\=]/g, '');
      }
      if (path) { // This way we can skip setting path by using path=null
        c += `; path=${path}`;
      }
      c += `; domain=${document.domain};`;
      document.cookie = c;
    },
  },

});


// IE9 shim
// TODO - move this to ./shims
if (typeof (window) !== 'undefined' && !window.console) { window.console = {}; window.console.log = function () {}; }
