/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-console
*/
import React from 'react';
import './error-boundary.scss';
import ContactLink from 'common/components/contact-link';
import Raven from 'raven-js';
import unexpectedError from './unexpected_error.png';

export default class ErrorBoundary extends React.Component {
  state = {
    errorTime: null,
    // https://reactjs.org/docs/react-component.html#componentdidcatch
    // Since componentDidCatch isn't going to be able to call setState, we need
    // an ID before that gets called.
    errorCode: `js${Math.random().toString(16).substring(2, 11)}`,
  };


  static getDerivedStateFromError() {
    return {
      errorTime: new Date(),
    };
  }

  componentDidCatch(error, info) {

    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
      console.error(info);
    }

    Raven.captureException(error);
    Raven.captureMessage(`ErrorCode: ${this.state.errorCode}\n${info.componentStack}`);
  }

  render() {
    if (this.state.errorTime != null) {
      // The HTML and CSS for this component were extracted from
      // https://app.dev-hellosign.com/webapp_prod.php/account/logIn using this
      // script. (This is a permalink to the exact version I used)
      // https://gist.github.com/AsaAyers/d50940bea80c6e279171a622a27d682f/7c4717a758083c19b981cc16563d5570533a4b8e
      // That script did not handle `margin: 0 auto` correctly for divs 4, 9,
      // and 15, so it had to be adjusted manually.
      return (
        <div className="error-boundary">
          <div className="error-boundary__div1">
            <div className="error-boundary__div2">
              <div className="error-boundary__div3">
                <div className="error-boundary__div4">
                  <div className="error-boundary__div5">&nbsp;</div>
                  <div className="error-boundary__div6">
                    <img className="error-boundary__image" src={unexpectedError} />
                  </div>
                  <div className="error-boundary__div7">&nbsp;</div>
                </div>
              </div>
              <div className="error-boundary__div8">
                <div className="error-boundary__div9">
                  <div className="error-boundary__div10">
                    <h2 className="error-boundary__h21">
                      Looks like an unexpected error has occurred.
                      <br className="error-boundary__br1" />
                      Sorry about that.
                    </h2>

                    <p className="error-boundary__p1">
                      Our development team has been notified. Please try once
                      more. If the error persists, email us with the error code
                      and time below:
                    </p>

                    <div className="error-boundary__div11">
                      <p className="error-boundary__p2">
                      Error code: <strong className="error-boundary__strong1">{this.state.errorCode}</strong>
                      </p>

                      <p className="error-boundary__p3">
                        Error time: <strong className="error-boundary__strong2">{String(this.state.errorTime.toUTCString())}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="error-boundary__div12">
                    <div id="contact-us" className="error-boundary__contact-us">
                      <ContactLink contactText="Contact us" />
                    </div>
                  </div>
                </div>
              </div>
              {window.top === window && (
                <div className="error-boundary__div14">
                  <div className="error-boundary__div15">
                    <div className="error-boundary__div16">
                      <a href="/" className="error-boundary__links">Home</a> |
                      <a href="/account/logIn" className="error-boundary__links">Login</a> |
                      <a href="/account/signUp" className="error-boundary__links">Sign Up</a> |
                      <a href="/info/pricing" className="error-boundary__links">Pricing</a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
