/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  no-useless-escape
*/
//  ----  CONSTANTS  --------------------------------------

// if you change the validation types here, also change them in HFApi.class

const ValidationTypes = {
  VALIDATION_TYPE_NUMBERS_ONLY: 'numbers_only',
  VALIDATION_TYPE_LETTERS_ONLY: 'letters_only',
  VALIDATION_TYPE_PHONE_NUMBER: 'phone_number',
  VALIDATION_TYPE_BANK_ROUTING_NUMBER: 'bank_routing_number',
  VALIDATION_TYPE_BANK_ACCOUNT_NUMBER: 'bank_account_number',
  VALIDATION_TYPE_EMAIL_ADDRESS: 'email_address',
  VALIDATION_TYPE_ZIP_CODE: 'zip_code',
  VALIDATION_TYPE_SOCIAL_SECURITY_NUMBER: 'social_security_number',
  VALIDATION_TYPE_EMPLOYER_IDENTIFICATION_NUMBER: 'employer_identification_number',
  VALIDATION_TYPE_CUSTOM_REGEX: 'custom_regex',
};

const ValidationRegex = {};
ValidationRegex[ValidationTypes.VALIDATION_TYPE_NUMBERS_ONLY] = /^[-]?\d+(\.\d+)?$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_LETTERS_ONLY] = /^[a-zA-Z\-\'\u00C0-\u00ff]+(\s+[a-zA-Z\-\'\u00C0-\u00ff]+)*$/i;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_PHONE_NUMBER] = /^(1[\s.-]?)?[\(\[\s.-]{0,2}?\d{3}[\)\]\s.-]{0,2}?\d{3}[\s.-]?\d{4}$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_BANK_ROUTING_NUMBER] = /^\d{9}$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_BANK_ACCOUNT_NUMBER] = /^\d{6,}$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_EMAIL_ADDRESS] = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_ZIP_CODE] = /^\d{5}([-]?\d{4})?$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_SOCIAL_SECURITY_NUMBER] = /^\d{3}[-]?\d{2}[-]?\d{4}$/;
ValidationRegex[ValidationTypes.VALIDATION_TYPE_EMPLOYER_IDENTIFICATION_NUMBER] = /^\d{2}[-]?\d{7}$/;

const validationTypesToDisplayNames = { };
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_NUMBERS_ONLY] = 'Numbers only';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_LETTERS_ONLY] = 'Letters only';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_PHONE_NUMBER] = 'Phone number';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_BANK_ROUTING_NUMBER] = 'Bank routing number';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_BANK_ACCOUNT_NUMBER] = 'Bank account number';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_EMAIL_ADDRESS] = 'Email address';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_ZIP_CODE] = 'Zip code';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_SOCIAL_SECURITY_NUMBER] = 'Social security number';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_EMPLOYER_IDENTIFICATION_NUMBER] = 'Employer identification number';
validationTypesToDisplayNames[ValidationTypes.VALIDATION_TYPE_CUSTOM_REGEX] = 'Custom regex';

const validationTypesToRules = { };
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_PHONE_NUMBER] = '10 digits will be required';
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_BANK_ROUTING_NUMBER] = '9 digits will be required';
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_BANK_ACCOUNT_NUMBER] = 'Minimum of 6 digits will be required';
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_ZIP_CODE] = '5 or 9 digits will be required';
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_SOCIAL_SECURITY_NUMBER] = '9 digits will be required';
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_EMPLOYER_IDENTIFICATION_NUMBER] = '9 digits will be required';
validationTypesToRules[ValidationTypes.VALIDATION_TYPE_CUSTOM_REGEX] = 'Specify a regular expression';

module.exports = {
  validationTypes: ValidationTypes,
  validationRegex: ValidationRegex,
  validationTypesToDisplayNames,
  validationTypesToRules,
};
