/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require
*/
import BaseCollection from 'common/models/base/collection';
import TeamMember from './team-member';

module.exports = BaseCollection.extend({


  apiEndpointAlias: 'teamMembers',


  /**
    */

  mixins: [
    require('common/models/mixins/collection'),
  ],

  /**
    */

  createModel(properties) {
    properties.user = this.user;
    properties.notifier = this.notifier;
    return TeamMember.create(properties);
  },

  /**
    */

  loggedInUserCanModifyTeam() {
    // return !!this.filter(function(member) {
    //   return member.isLoggedInUser() && member.canModifyTeam();
    // }.bind(this)).length;

    return this.team.isOwner || this.team.isManager;
  },

  /**
    */
  getLoggedInUser() {
    return this.filter((member) => {
      return member.isLoggedInUser();
    })[0];
  },

  /**
    */
  loggedInUserIsDeveloper() {
    return !!this.filter((member) => {
      return member.isLoggedInUser() && member.isDeveloper();
    }).length;
  },

  /**
    */
  loggedInUserIsAdmin() {
    return this.team.isOwner;
  },

  /**
    */
  loggedInUserIsManager() {
    return !!this.filter((member) => {
      return member.isLoggedInUser() && member.isManager();
    }).length;
  },

  /**
    */

  addMember(properties) {
    return this.createModel(properties).save();
  },
});
