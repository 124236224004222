/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import Logo from 'common/components/logo';
import './simple-gray-layout.scss';

/**
 * A top-level page layout component which tries to fill the entire page.
 * Features a simple header, a small centered card containing child content, and no footer.
 */
export default class SimpleGrayLayout extends React.Component {
  render() {
    const siteCode = this.props.siteCode || 'S';
    return (
      <div className='m-simple-gray-layout'>
        <Logo siteCode={siteCode} />
        <div className='m-simple-gray-layout--card'>
          {this.props.children}
        </div>
      </div>
    );
  }
}
