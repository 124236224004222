import React, { Component } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import { makeContextCacher } from 'common/utils/context';
// @ts-ignore
import fetchActions from './actions/fetch';
// @ts-ignore
import bulkSendActions from './actions/bulk-send';
// @ts-ignore
import signatureActions from './actions/signature';
// @ts-ignore
import adminActions from './actions/admin';
import editorActions from './actions/editor';
import moarActions from './actions/moar';

export type AppActions = (
  {}
  & ReturnType<typeof editorActions>
  & ReturnType<typeof moarActions>
  // & ReturnType<typeof bulkSendActions>
  // & ReturnType<typeof fetchActions>
  // & ReturnType<typeof signatureActions>
)

// @ts-ignore I can't really create a default context here
export const appContext: React.Context<AppActions> = React.createContext({});

interface AppContextProp {
  appContext: AppActions
}

export function withAppContext<P>(
  Component: React.ComponentType<P & AppContextProp & React.RefAttributes<{}>>,
) {
  // Using a named function will name the forwarded ref.
  // https://reactjs.org/docs/forwarding-refs.html#displaying-a-custom-name-in-devtools
  // eslint-disable-next-line react/display-name
  return React.forwardRef((props: P, ref) => {
    return (
      <appContext.Consumer>
        {(appContext: AppActions) => (
          <Component {...props} appContext={appContext} ref={ref} />
        )}
      </appContext.Consumer>
    );
  });
}

interface User {

}

interface Props {
  csrfToken: string,
  csrfTokenAttachmentDelete: string,
  preloadedTsmGroupKey: string,
  initialUser: User
  createMock?: <T>(key: keyof AppActions, orig: T) => T
}

interface State {
  user: User
}

export default class AppContext extends Component<Props, State> {
  static propTypes = {
    csrfToken: PropTypes.string.isRequired,
    csrfTokenAttachmentDelete: PropTypes.string,
    preloadedTsmGroupKey: PropTypes.string,
    initialUser: PropTypes.shape({
      primarySignatureGuid: PropTypes.string.isRequired,
      settings: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }),
    children: PropTypes.node,
    createMock: PropTypes.func,

    termsURL: PropTypes.string,
    privacyURL: PropTypes.string,
  };

  state = {
    user: this.props.initialUser,
  };

  contextCache = makeContextCacher();

  actions: AppActions;

  constructor(props: Props) {
    super(props);
    this.actions = this.buildActions();
  }

  buildActions = () => {
    const actions: AppActions = {
      ...fetchActions(),
      ...bulkSendActions(this.props.csrfToken),
      ...signatureActions(),
      ...adminActions(),
      ...moarActions(this.props.csrfToken),
      ...editorActions(
        this.props.csrfToken,
        this.props.csrfTokenAttachmentDelete,
        this.props.preloadedTsmGroupKey,
      ),
    };

    const createMock = this.props.createMock;
    if (createMock != null) {
      return Object.keys(actions).reduce((actions, key) => {
        // @ts-ignore
        actions[key] = createMock(key, actions[key]);
        return actions;
      }, actions);
    }
    return actions;
  };

  render() {
    const context = this.contextCache({
      data: this.state,
      ...this.actions,
    });

    return (
      <appContext.Provider value={context}>
        {this.props.children}
      </appContext.Provider>
    );
  }
}
