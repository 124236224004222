/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import createReactClass from 'create-react-class';

module.exports = createReactClass({
  displayName: 'SEO Body Section',

  render() {
    return <div className='m-marketing-page row'>
      <div className='columns small-12'>
        {this.props.children}
      </div>
    </div>;
  },
});
