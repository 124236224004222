/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-restricted-syntax,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import watchAppNotifierMixin from 'common/components/mixins/watch-app-notifier';
import HfReactHelper from 'common/utils/hf-react-helper';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import bulkDot from './assets/bulk@2x.png';
import allteamDot from './assets/allteam@2x.png';
import outforsignatureDot from './assets/outforsignature@2x.png';
import awaitingDot from './assets/awaiting@2x.png';
import completedDot from './assets/completed@2x.png';
import declinedDot from './assets/declined@2x.png';
import templatesDot from './assets/templates@2x.png';
import templatelinksDot from './assets/templatelinks@2x.png';
import draftsDot from './assets/drafts@2x.png';
import sentDot from './assets/sent@2x.png';
import receivedDot from './assets/received@2x.png';


export const FILTERS = {
  documentsbulk: 'bulk',
  documentsallteam: 'all_team',
  documentsoutforsignature: 'out_for_signature',
  documentsawaiting: 'awaiting_my_signature',
  documentscompleted: 'completed',
  documentsdeclined: 'declined',
  documentstemplates: 'template',
  documentstemplatelinks: 'template_link',
  documentsdrafts: 'draft',
  documentssent: 'sent',
  documentsreceived: 'received',
};

const messages = defineMessages({
  bulkSend: { id: '', description: 'webapp, homepage sidebar bulkSend link', defaultMessage: 'Bulk send' },
  outForSignature: { id: '', description: 'webapp, homepage sidebar outForSignature link', defaultMessage: 'Out for signature' },
  awaitingMySignature: { id: '', description: 'webapp, homepage sidebar awaitingMySignature link', defaultMessage: 'Awaiting my signature' },
  completed: { id: '', description: 'webapp, homepage sidebar completed link', defaultMessage: 'Completed' },
  declined: { id: '', description: 'webapp, homepage sidebar declined link', defaultMessage: 'Declined' },
  templates: { id: '', description: 'webapp, homepage sidebar templates link', defaultMessage: 'Templates' },
  templateLinks: { id: '', description: 'webapp, homepage sidebar templateLinks link', defaultMessage: 'Template links' },
  draft: { id: '', description: 'webapp, homepage sidebar draft link', defaultMessage: 'Draft' },
  documentsallteam: { id: '', description: 'webapp, homepage sidebar documentsallteam link', defaultMessage: 'All team documents' },
});

// In-app left side menu
const AppLeftMenu = createReactClass({

  mixins: [watchAppNotifierMixin],

  propTypes: {
    siteCode: PropTypes.string.isRequired, // S or F
    pageSize: PropTypes.number, // List size
    searchBackend: PropTypes.string, // Type of search backend to use
    activeItemId: PropTypes.string, // Menu item currently active
    hasTeamFilter: PropTypes.bool, // Whether the user can view all team docs
    hasCompletedFilter: PropTypes.bool, // Whether the completed filter is visible currently
    emailAddress: PropTypes.string.isRequired, // Email address for the current user
    faxNumbers: PropTypes.array, // List of fax numbers
    name: PropTypes.string, // Name of the user
    canAccessBulkSendUI: PropTypes.bool.isRequired,
    canAccessAdminConsole: PropTypes.bool,
    intl: PropTypes.object,
  },


  getInitialState() {

    const isHf = HfReactHelper.HfSites.isHelloFax(this.props.siteCode);
    const defaultItemId = isHf ? 'sendfax' : 'signdocs';
    let activeItemId = (this.props.activeItemId || defaultItemId);

    // Convert filters to section item ref
    for (const k in FILTERS) {
      if (FILTERS[k] === activeItemId) {
        activeItemId = k;
        break;
      }
    }

    return {
      activeItemId,
      faxLines: this.props.app.user.getFaxLines(),
    };
  },

  componentDidMount() {

    const root = $(ReactDOM.findDOMNode(this));
    const self = this;

    $('.m-app-leftmenu--section--title > a, .m-app-leftmenu--section--item > a', root).click(function (evt) {

      if (evt.target.tagName === 'A' || evt.target.parentNode.tagName === 'A') {
        return;
      }

      evt.preventDefault();

      const el = $(this);
      let itemId = el.attr('id');

      if (!itemId) {
        itemId = el.parent().attr('id');
      }

      self.setState({
        activeItemId: itemId,
      });
    });
  },

  getItemClassName(sectionName, isItem) {
    let s = `m-app-leftmenu--section${isItem ? '--item' : ''}`;
    if ((sectionName === 'signdocs' && $.inArray(this.state.activeItemId, ['signjustme', 'signmeandothers', 'signjustothers']) >= 0)
            || (sectionName === 'templates' && $.inArray(this.state.activeItemId, ['createtemplate', 'createtemplatelink', 'usetemplate', 'usetemplatelink']) >= 0)
            || ((sectionName === 'documents' && $.inArray(this.state.activeItemId, Object.keys(FILTERS)) >= 0))) {
      s += ' is-expanded';
    } else if (this.state.activeItemId === sectionName) {
      s += ' is-active';
    }
    return s;
  },

  makeFilter(id, label, dotUrl = null) {

    const params = [`filter=${FILTERS[id]}`];
    if (this.props.searchBackend) {
      params.push(`search_backend=${this.props.searchBackend}`);
    }
    if (this.props.pageSize) {
      params.push(`page_size=${this.props.pageSize}`);
    }

    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    const url = HfReactHelper.urlHelper(`home/manage?${params.join('&')}`, site);

    return (
      <div id={id} className={this.getItemClassName(id, true)}>
        <span className={'m-app-leftmenu--section--item--dot'}>
          { dotUrl && <img src={dotUrl} /> }
        </span>
        <a href={url}>{ label }</a>
      </div>
    );
  },

  render() {

    const isHf = HfReactHelper.HfSites.isHelloFax(this.props.siteCode);
    let templates;
    let sendFax;
    let recvFax;
    let getFreePages;
    let signDocs;
    let allTeamDocsFilter;
    let completedFilter;
    let faxNumbers;
    let siteSpecificFilters;
    let manageTeamLink;

    const meAndOthersParams = 'me=1';

    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    const teamUrl = HfReactHelper.urlHelper('home/team', site);
    const createTemplateUrl = HfReactHelper.urlHelper('home/createTemplate', site);
    const createLinkUrl = HfReactHelper.urlHelper('home/createReusableLink', site);
    const sendFaxUrl = HfReactHelper.urlHelper('home/index', site);
    const recvFaxUrl = HfReactHelper.urlHelper('home/upgrade', site);
    const freePagesUrl = HfReactHelper.urlHelper('account/gettingStarted', site);
    const useTemplateUrl = HfReactHelper.urlHelper('home/manage?filter=template', site);
    const useLinkUrl = HfReactHelper.urlHelper('home/manage?filter=template_link', site);
    const justMeUrl = HfReactHelper.urlHelper('sign', site);
    const meAndOthersUrl = HfReactHelper.urlHelper(`home/request?${meAndOthersParams}`, site);
    const justOthersUrl = HfReactHelper.urlHelper('home/request', site);
    const bulkSendUrl = HfReactHelper.urlHelper('bulksend', site);
    const signUrl = HfReactHelper.urlHelper('home/index', site);
    const templateUrl = HfReactHelper.urlHelper('home/createReusableDocs', site);
    const docsUrl = HfReactHelper.urlHelper('home/manage', site);
    const integrationsUrl = HfReactHelper.urlHelper('home/myAccount?current_tab=integrations', site);
    let documentsName = <FormattedMessage id="" description="webapp, homepage sidebar docs link" defaultMessage="Documents" />;
    let qaAttribute = '';

    if (isHf) {

      documentsName = <FormattedMessage id="" description="webapp, homepage sidebar faxes link" defaultMessage="Faxes" />;

      sendFax = (
        <div id='sendfax' className={this.getItemClassName('sendfax')}>
          <div className='m-app-leftmenu--section--title'>
            <a href={sendFaxUrl}><span>Send a fax</span></a>
          </div>
        </div>
      );

      if (this.props.showUpgradeButton == true) {
        recvFax = (
          <div id='recvfax' className={this.getItemClassName('recvfax')}>
            <div className='m-app-leftmenu--section--title'>
              <a href={recvFaxUrl}><span>Receive faxes</span></a>
            </div>
          </div>
        );
      } else {
        recvFax = '';
      }

      signDocs = (
        <div id='signdocs' className={this.getItemClassName('signdocs')}>
          <div className='m-app-leftmenu--section--title'>
            <a href={justOthersUrl}><span>Sign documents</span></a>
          </div>
        </div>
      );

      getFreePages = (
        <div id='getfreepages' className={`l-margin-t-50 ${this.getItemClassName('getfreepages')}`}>
          <div className='m-app-leftmenu--section--title'>
            <a href={freePagesUrl}><span>Get free pages</span></a>
          </div>
        </div>
      );
      if (this.props.hasTeamFilter) {
        allTeamDocsFilter = this.makeFilter('documentsallteam', 'All team faxes', allteamDot);
      }
      siteSpecificFilters = (
        <div className='m-app-leftmenu--section--items'>
          { allTeamDocsFilter }
          { this.makeFilter('documentssent', 'Sent', sentDot) }
          { this.makeFilter('documentsreceived', 'Received', receivedDot) }
          { this.makeFilter('documentsdrafts', 'Drafts', draftsDot) }
        </div>
      );

      if (this.state.faxLines && this.state.faxLines.length > 0) {

        const numbers = this.state.faxLines.map((faxLine, index) => {
          return <div key={index} className='m-app-leftmenu--fax-numbers--list--item'>{ faxLine.number }</div>;
        });

        const listClasses = classNames({
          'm-app-leftmenu--fax-numbers--list': true,
          'is-scrollable': (this.state.faxLines.length > 12),
        });

        faxNumbers = (
          <div className='m-app-leftmenu--fax-numbers'>
            <div className='m-app-leftmenu--fax-numbers--title'>{ this.state.faxLines.length > 1 ? 'YOUR FAX NUMBERS' : 'YOUR FAX NUMBER' }</div>
            <div className={listClasses}>
              { numbers }
            </div>
          </div>
        );
      }

    } else {

      signDocs = (
        <div id='signdocs' className={this.getItemClassName('signdocs')}>
          <div className='m-app-leftmenu--section--title'>
            <a href={signUrl}><span><FormattedMessage id="" description="webapp, homepage sidebar sign docs link" defaultMessage="Sign documents" /></span></a>
          </div>
          <div className='m-app-leftmenu--section--items'>
            <div id='signjustme' className={this.getItemClassName('signjustme', true)}>
              <a href={justMeUrl}><FormattedMessage id="" description="webapp, homepage sidebar just me link" defaultMessage="Just me" /></a>
            </div>
            <div id='signmeandothers' className={this.getItemClassName('signmeandothers', true)}>
              <a href={meAndOthersUrl}><FormattedMessage id="" description="webapp, homepage sidebar me and others link" defaultMessage="Me &amp; others" /></a>
            </div>
            <div id='signjustothers' className={this.getItemClassName('signjustothers', true)}>
              <a href={justOthersUrl}><FormattedMessage id="" description="webapp, homepage sidebar just others link" defaultMessage="Just others" /></a>
            </div>
            <div id='signbulksend' className={this.getItemClassName('signbulksend', true)}>
              <a href={bulkSendUrl}><FormattedMessage id="" description="webapp, homepage sidebar bulk send link" defaultMessage="Bulk send" /></a>
            </div>
          </div>
        </div>
      );

      templates = (
        <div id='templates' className={this.getItemClassName('templates')}>
          <div className='m-app-leftmenu--section--title m-app-leftmenu--templates'>
            <a href={templateUrl}><span><FormattedMessage id="" description="webapp, homepage sidebar templates link" defaultMessage="Templates" /></span></a>
          </div>
          <div className='m-app-leftmenu--section--items'>
            <div id='createtemplate' className={this.getItemClassName('createtemplate', true)}>
              <a href={createTemplateUrl}><FormattedMessage id="" description="webapp, homepage sidebar create template link" defaultMessage="Create template" /></a>
            </div>
            <div id='createtemplatelink' className={this.getItemClassName('createtemplatelink', true)}>
              <a href={createLinkUrl}><FormattedMessage id="" description="webapp, homepage sidebar create link link" defaultMessage="Create link" /></a>
            </div>
            <div id='usetemplate' className={this.getItemClassName('usetemplate', true)}>
              <a href={useTemplateUrl}><FormattedMessage id="" description="webapp, homepage sidebar use template link" defaultMessage="Use template" /></a>
            </div>
            <div id='usetemplatelink' className={this.getItemClassName('usetemplatelink', true)}>
              <a href={useLinkUrl}><FormattedMessage id="" description="webapp, homepage sidebar use link link" defaultMessage="Use link" /></a>
            </div>
          </div>
        </div>
      );

      if (this.props.hasTeamFilter) {
        allTeamDocsFilter = this.makeFilter('documentsallteam', 'All team documents', allteamDot);
      }
      if (this.props.hasCompletedFilter) {
        completedFilter = this.makeFilter('documentscompleted', this.props.intl.formatMessage(messages.completed), completedDot);
      }

      siteSpecificFilters = (
        <div className='m-app-leftmenu--section--items'>
          { this.props.canAccessBulkSendUI
             && this.makeFilter('documentsbulk', this.props.intl.formatMessage(messages.bulkSend), bulkDot)
           }
          { allTeamDocsFilter }
          { this.makeFilter('documentsoutforsignature', this.props.intl.formatMessage(messages.outForSignature), outforsignatureDot) }
          { this.makeFilter('documentsawaiting', this.props.intl.formatMessage(messages.awaitingMySignature), awaitingDot) }
          { completedFilter }
          { this.makeFilter('documentsdeclined', this.props.intl.formatMessage(messages.declined), declinedDot) }
          { this.makeFilter('documentstemplates', this.props.intl.formatMessage(messages.templates), templatesDot) }
          { this.makeFilter('documentstemplatelinks', this.props.intl.formatMessage(messages.templateLinks), templatelinksDot) }
          { this.makeFilter('documentsdrafts', this.props.intl.formatMessage(messages.draft), draftsDot) }
        </div>
      );

      qaAttribute = 'documents-left-menu';
    }

    if (!this.props.canAccessAdminConsole) {
      manageTeamLink = (
        <div id='manageteam' className={this.getItemClassName('manageteam')}>
          <div className='m-app-leftmenu--section--title m-app-leftmenu--team'>
            <a href={teamUrl}><span><FormattedMessage id="" description="webapp, homepage sidebar team link" defaultMessage="Team" /></span></a>
          </div>
        </div>
      );
    }

    const res = (
      <div className='m-app-leftmenu'>

        { sendFax }
        { recvFax }
        { signDocs }
        { templates }

        <div id='documents' className={this.getItemClassName('documents')}>
          <div className='m-app-leftmenu--section--title m-app-leftmenu--documents'>
            <a data-qa-ref={qaAttribute} href={docsUrl}>
              <span> {documentsName} </span>
            </a>
          </div>
          <div className='m-app-leftmenu--section--items'>
            {siteSpecificFilters}
          </div>
        </div>

        { manageTeamLink }

        <div id='manage_integrations' className={this.getItemClassName('manage_integrations')}>
          <div className='m-app-leftmenu--section--title m-app-leftmenu--integrations'>
            <a href={integrationsUrl}><span><FormattedMessage id="" description="webapp, homepage sidebar integration link" defaultMessage="Integrations" /></span></a>
          </div>
        </div>

        { getFreePages }

        { faxNumbers }

      </div>
    );

    return res;
  },

});

export default injectIntl(AppLeftMenu);
