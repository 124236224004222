/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-restricted-globals,
  no-useless-escape,
  react/no-string-refs,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';
import CreateAccount from './create-account';

const CreateAccountTrigger = createReactClass({

  propTypes: {
    linkText: PropTypes.string,
    trigger: PropTypes.element,
    triggerSelector: PropTypes.string,
    googleSignInClientId: PropTypes.string,
    buttonText: PropTypes.string,
    tabOffset: PropTypes.number,
    redirectUrl: PropTypes.string,
    signupUrl: PropTypes.string,
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    loginForm: PropTypes.object, // Serialized sfForm
    recoverPasswordForm: PropTypes.object, // Serialized sfForm
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
    async: PropTypes.bool,
  },

  componentDidMount() {
    if (window.handlingLogInTriggers) {
      // We only want to process this once per page
      return;
    }
    // If the 'login=1' URL parameter is present, display the modal
    if (/\?display_signup\=1|&display_signup\=1/i.test(location.href)) {
      window.handlingSignupTriggers = true;
      this.handleClick(null);
    }
  },

  handleClick(e) {
    if (e != null) {
      e.preventDefault();
    }
    const tabOffset = parseInt(this.props.tabOffset || 1000, 10);
    const attachPoint = HfReactHelper.getRevealAttachPoint('create_account_modal');
    ReactDOM.render(
      <CreateAccount
        header={this.props.header}
        buttonText={this.props.buttonText}
        googleSignInClientId={this.props.googleSignInClientId}
        isFromApiPricing={this.props.isFromApiPricing}
        tabOffset={tabOffset}
        redirectUrl={this.props.redirectUrl}
        signupUrl={this.props.signupUrl}
        tosUrl={this.props.tosUrl}
        privacyPolicyUrl={this.props.privacyPolicyUrl}
        signupForm={this.props.signupForm}
        loginForm={this.props.loginForm}
        recoverPasswordForm={this.props.recoverPasswordForm}
        hideRememberMe={this.props.hideRememberMe}
        hideGoogleOpenId={this.props.hideGoogleOpenId}
        async={this.props.async}
      >
      </CreateAccount>,
      attachPoint,
    );
  },

  renderTrigger() {
    if (this.props.triggerSelector) {
      return <DOMElementWrapper element={this.props.triggerSelector} />;
    } else if (this.props.trigger) {
      return this.props.trigger;
    } else {
      return (<a href='#'>{this.props.linkText}</a>);
    }
  },

  render() {
    const trigger = this.renderTrigger();
    return (<span ref='signUpTrigger' className='sign-up-trigger' onClick={this.handleClick}>
      {trigger}
    </span>);
  },
});

const DOMElementWrapper = createReactClass({
  componentDidMount() {
    const signUpTrigger = this.refs.container;
    signUpTrigger.appendChild($(this.props.element)[0]);
  },
  render() {
    return <span ref='container'></span>;
  },
});

module.exports = CreateAccountTrigger;
