import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as types from '../constants/types';

import deleteButtonSVG from '../svg/delete.svg';
import teamSVG from '../svg/team.svg';

/**
 * Renders a team access modal access list entity.
 *
 * @param {Entity} props.entity
 * @param {boolean} isEditable
 * @param {Function} props.grantAccess
 * @param {Function} props.revokeAccess
 * @param {Function} props.resetAccess
 * @returns {JSX.Element}
 */
function EntityAccessListItem({ entity, isEditable, grantAccess, revokeAccess, resetAccess }) {
  const {
    hasExplicitTemplateAccess,
    isAccessDirty,
    isAdmin,
    isOwner,
    isSelf,
    type,
    willAccessBeGranted,
    willAccessBeRevoked,
  } = entity;

  const entityIsTeam = React.useMemo(() => type === types.TEAM, [type]);
  const entityLabel = React.useMemo(() => (
    entityIsTeam
      ? `${entity.name} (Team)`
      : entity.email
  ), [entityIsTeam, entity]);

  const grantEntityAccess = React.useCallback((evt) => {
    evt.preventDefault();

    grantAccess(entity);
  }, [entity, grantAccess]);

  const revokeEntityAccess = React.useCallback((evt) => {
    evt.preventDefault();

    revokeAccess(entity);
  }, [entity, revokeAccess]);

  const resetEntityAccess = React.useCallback((evt) => {
    evt.preventDefault();

    resetAccess(entity);
  }, [entity, resetAccess]);

  function renderItem() {
    if (isAdmin || isOwner || isSelf) {
      if (isSelf) {
        return (
          <React.Fragment>
            <p>{entityLabel}</p>
            <div className="team-access-modal__entity-right">
              <p>
                <FormattedMessage
                  id=""
                  description="text in rightside of email in list of template accessor pane of team access modal indicating you have access to template"
                  defaultMessage="You" />
              </p>
            </div>
          </React.Fragment>
        );
      } else if (isAdmin) {
        return (
          <React.Fragment>
            <p>{entityLabel}</p>
            <div className="team-access-modal__entity-right">
              <p>
                <FormattedMessage
                  id=""
                  description="text in rightside of email in list of template accessor pane of team access modal indicating admin have access to template"
                  defaultMessage="Admin" />
              </p>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <p>{entityLabel}</p>
            <div className="team-access-modal__entity-right">
              <p>
                <FormattedMessage
                  id=""
                  description="text in rightside of email in list of template accessor pane of team access modal indicating owner have access to template"
                  defaultMessage="Owner" />
              </p>
            </div>
          </React.Fragment>
        );
      }
    } else if (isEditable && isAccessDirty) {
      if (hasExplicitTemplateAccess) {
        if (willAccessBeRevoked) {
          const a = (msg) => <a onClick={resetEntityAccess}>{msg}</a>;
          return (
            <React.Fragment>
              <p>
                <FormattedMessage
                  id=""
                  description="text in team access modal indicating the email access to template will be revoked and can be undone with the link"
                  defaultMessage="“{entityLabel}” will be removed – <a>Undo</a>"
                  values={{
                    entityLabel,
                    a,
                  }} />
              </p>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <p>{entityLabel}{entityIsTeam ? <img className="team-access-modal__entity-team-icon" src={teamSVG} aria-hidden="true" /> : null}</p>
              <div className="team-access-modal__entity-right">
                <div role="button" className="team-access-modal__entity-revoke-button" onClick={revokeEntityAccess}>
                  <img src={deleteButtonSVG} aria-hidden="true" />
                </div>
              </div>
            </React.Fragment>
          );
        }
      } else if (willAccessBeGranted) {
        return (
          <React.Fragment>
            <p>{entityLabel}{entityIsTeam ? <img className="team-access-modal__entity-team-icon" src={teamSVG} aria-hidden="true" /> : null}</p>
            <div className="team-access-modal__entity-right">
              <div role="button" className="team-access-modal__entity-revoke-button" onClick={resetEntityAccess}>
                <img src={deleteButtonSVG} aria-hidden="true" />
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        const a = (msg) => <a onClick={grantEntityAccess}>{msg}</a>;
        return (
          <React.Fragment>
            <p>
              <FormattedMessage
                id=""
                description="text in team access modal indicating the email will not be added to template accessor list and can be undone with the link"
                defaultMessage="“{entityLabel}” will not be added – <a>Undo</a>"
                values={{
                  entityLabel,
                  a,
                }} />
            </p>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <p>{entityLabel}{entityIsTeam ? <img className="team-access-modal__entity-team-icon" src={teamSVG} aria-hidden="true" /> : null}</p>
          {isEditable ? (
            <div className="team-access-modal__entity-right">
              <div role="button" className="team-access-modal__entity-revoke-button" onClick={revokeEntityAccess}>
                <img src={deleteButtonSVG} aria-hidden="true" />
              </div>
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  }

  return (
    <div className={classnames('team-access-modal__entity', {
      'team-access-modal__entity--is-unrevokable': isAdmin || isOwner || isSelf,
      'team-access-modal__entity--is-access-verbose':
        (hasExplicitTemplateAccess && willAccessBeRevoked)
        || (!hasExplicitTemplateAccess && !willAccessBeGranted),
    })}
    >
      {renderItem()}
    </div>
  );
}

export default EntityAccessListItem;
