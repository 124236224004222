/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require
*/

import BaseModel from 'common/models/base/model';

/*

'STATUS_CODE_ACTIVE': 'A',
'STATUS_CODE_PORTING_DENIED': 'D',
'STATUS_CODE_PORTING_LOA_COMPLETED': '4',
'STATUS_CODE_PORTING_ACCEPTED': '2',
'STATUS_CODE_PORTING_STARTED': '1'
*/

module.exports = BaseModel.extend({

  /**
     */

  apiEndpointAlias: 'faxLines',

  /**
    */

  mixins: [
    require('common/models/mixins/model'),
  ],

  /**
    */

  fromData(data) {
    return {
      guid: data.guid,
      number: `+${String(data.number).replace(/^\+/, '')}`,
      statusCode: data.statusCode || data.status,
      teamMembers: data.teamMembers || [],
    };
  },

  /**
    */

  toData() {
    return {
      guid: this.guid,
      number: this.number,
      statusCode: this.statusCode,
      teamMembers: this.teamMembers || [],
    };
  },

  /**
    * TODO - this works for now. Ideally we'd have faxline.getMembers() in the future. (CC)
    */

  setMembers(members) {
    this.teamMembers = members;
    return this.save();
  },

  /**
    */

  isDenied() {
    return this.statusCode === 'D';
  },

  /**
    */

  isActive() {
    return /A/.test(String(this.statusCode));
  },

  /**
    */

  isAccepted() {
    return /4|2/.test(String(this.statusCode));
  },

  /**
    */

  isPorting() {
    return /1/.test(String(this.statusCode));
  },

});
