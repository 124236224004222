import hsFetch from 'hellospa/hs-fetch';
import { queryParams } from 'hellospa/common/utils/fetch';

const normalizeTemplate = (template) => ({
  id: template.template_id,
  title: template.title,
  isLocked: template.is_locked,
});

// I don't know if I like "fetchActions". The idea is that these are calls that
// don't update application state, they just request external data.
export default function fetchActions() {


  async function fetchTemplate(id) {
    const url = `/template${queryParams({ id })}`;
    const result = await hsFetch(url, { credentials: 'same-origin' });
    const { template } = await result.json();
    return normalizeTemplate(template);
  }

  async function fetchTemplateList({ pageNum, filter = null }) {
    const url = `/template/list${queryParams({ page_num: pageNum, filter })}`;

    const response = await hsFetch(url, { credentials: 'same-origin' });

    if (response.status === 200) {
      const data = await response.json();

      return {
        total: data.list_info.num_results,
        pageSize: data.list_info.page_size,
        templates: data.templates.map(normalizeTemplate),
      };
    }

    throw new Error('Unexpected response');

  }

  async function fetchHubspotTemplateList({ pageNum, pageSize }) {
    const url = `/template/list${queryParams({ page_num: pageNum, page_size: pageSize })}`;

    const response = await hsFetch(url, { credentials: 'same-origin' });

    if (response.status === 200) {
      const data = await response.json();

      return {
        total: data.list_info.num_results,
        pageSize: data.list_info.page_size,
        templates: data.templates.map(normalizeTemplate),
      };
    }

    throw new Error('Unexpected response');

  }

  async function fetchManageDocumentsPage(filter, page, pageSize) {
    let documents = [];
    const url = `/home/manageDocs${queryParams({
      filter,
      page_num: page,
      page_size: pageSize,
    })}`;
    const response = await hsFetch(url, { credentials: 'same-origin' });
    const data = await response.json();

    documents = data.items.map((doc) => ({
      guid: doc.guid,
      type: doc.type,
      title: doc.title,
      email: doc.email,
      date: new Date(doc.date * 1000),
      totalSigners: doc.total_signers,
      totalSigned: doc.total_signed,
    }));

    return {
      documents,
      totalItems: data.total_items,
    };
  }

  async function fetchBulkSendSigners(id) {
    let signers = [];
    const url = `/bulksend/SignerList${queryParams({
      bulk_send_id: id,
    })}`;
    const response = await hsFetch(url, { credentials: 'same-origin' });
    const data = await response.json();

    signers = data.items.map((doc) => ({
      id: doc.guid,
      name: doc.signer_name,
      status: doc.signed ? 'signed' : 'not-signed',
    }));

    return {
      signers,
    };
  }

  return {
    fetchManageDocumentsPage,
    fetchTemplate,
    fetchTemplateList,
    fetchHubspotTemplateList,
    fetchBulkSendSigners,
  };
}
