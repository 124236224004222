/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import createReactClass from 'create-react-class';
import CTAButtons from './cta-buttons';
import APIUserImages from './api-user-images';
import EmbeddedTweets from './embedded-tweets';

module.exports = createReactClass({
  displayName: 'SEO CTA Section',

  render() {
    const props = this.props;
    return <div className='bg-plume'>
      <CTAButtons {...props} />
      <APIUserImages {...props} />
      <EmbeddedTweets {...props} />
    </div>;
  },
});
