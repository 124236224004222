import React from 'react';
import classnames from 'classnames';

import { withAppContext } from '../../contexts/app';

class Advanced extends React.PureComponent {

  onAllowCancelChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      allowCancel: evt.target.checked,
    });
  };

  onEmbedInPageChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      embedInPage: evt.target.checked,
    });
  };

  onHideHeaderChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      hideHeader: evt.target.checked,
    });
  };

  onLocaleChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      locale: evt.target.value,
    });
  };

  onRedirectURLChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      redirectURL: evt.target.value,
    });
  };

  onRequestingEmailChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      requestingEmail: evt.target.value,
    });
  };

  onTestModeChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      testMode: evt.target.checked,
    });
  };

  onTimeoutChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      timeout: Number(evt.target.value || 0),
    });
  };

  onWhiteLabelingCodeChange = (evt) => {
    this.props.appContext.actions.updateFormOptions({
      whiteLabelingCode: evt.target.value,
    });
  };

  renderRedirectURLField() {
    const value = this.props.appContext.state.form.redirectURL;

    return (
      <div className="m-api-docs-sub-section">
        <div className="m-api-docs-sub-section--title">Redirect URL</div>
        <input
          name="redirect-url"
          type="url"
          placeholder="https://app.hellosign.com/api/documentation"
          value={value}
          onChange={this.onRedirectURLChange} />
        <div className="m-api-docs-section--footnote">
          A URL pointing to where the user should be redirected to after the embedded signature
          request has been completed by the user.
        </div>
      </div>
    );
  }

  renderRequestingEmailField() {
    const value = this.props.appContext.state.form.requestingEmail;

    return (
      <div className="m-api-docs-sub-section">
        <div className="m-api-docs-sub-section--title">Requesting Email</div>
        <input
          name="requesting-email"
          type="email"
          value={value}
          onChange={this.onRequestingEmailChange} />
        <div className="m-api-docs-section--footnote">
          The email of the account issuing the signature request. Note: This
          option is required for <strong>Me and Others</strong> type signature requests.
        </div>
      </div>
    );
  }

  renderLanguage() {
    const { cultures, form } = this.props.appContext.state;
    const value = form.locale;

    return (
      <div className="m-api-docs-sub-section m-api-docs-sub-section--half-width">
        <div className="m-api-docs-sub-section--title">Language</div>
        <select name="locale" value={value} onChange={this.onLocaleChange}>
          {Object.entries(cultures).map(([key, val]) => (
            <option key={key} value={key}>{val}</option>
          ))}
        </select>
        <div className="m-api-docs-section--footnote">
          The language to use in the embedded request.
        </div>
      </div>
    );
  }

  renderTimeoutField() {
    const value = this.props.appContext.state.form.timeout;

    return (
      <div className="m-api-docs-sub-section m-api-docs-sub-section--half-width">
        <div className="m-api-docs-sub-section--title">Timeout (ms)</div>
        <input
          name="timeout"
          type="number"
          placeholder="30000"
          value={value}
          onChange={this.onTimeoutChange} />
        <div className="m-api-docs-section--footnote">
          An object used to customize the signer experience for premium users with <a href="/api/reference#WhiteLabeling" target="_blank" rel="noreferrer nofollow">white-labeling</a> capabilities.
        </div>
      </div>
    );
  }

  renderWhiteLabelingCodeField() {
    const value = this.props.appContext.state.form.whiteLabelingCode;

    let isInvalid = false;
    if (value.length) {
      try {
        JSON.parse(value);
      } catch (err) {
        isInvalid = true;
      }
    }

    const classNames = classnames('embedded-testing-tool__form-white-labeling-code', {
      'embedded-testing-tool__form-white-labeling-code--invalid': isInvalid,
    });

    return (
      <div className="m-api-docs-sub-section">
        <div className="m-api-docs-sub-section--title">Custom White Labeling Code</div>
        <textarea
          className={classNames}
          name="white-labeling-code"
          rows="5"
          placeholder={'{ "primary_button_color": "#FF0000" }'}
          value={value}
          onChange={this.onWhiteLabelingCodeChange} />
        <div className="m-api-docs-section--footnote">
          An object used to customize the signer experience for premium users with
          <a
            href="https://app.hellosign.com/api/reference#WhiteLabeling"
            rel="noopener noreferrer"
            target="_blank"
          >
            {' '}white-labeling
          </a>
          {' '}capabilities.
        </div>
      </div>
    );
  }

  renderAllowCancelField() {
    const checked = this.props.appContext.state.form.allowCancel;

    return (
      <div className="m-api-docs-list--item l-margin-v-15">
        <input
          id="allow-cancel"
          name="allow-cancel"
          type="checkbox"
          checked={checked}
          onChange={this.onAllowCancelChange} />
        <label htmlFor="allow-cancel">Allow Cancel</label>
        <div className="m-api-docs-section--footnote">
          Whether to allow the user to cancel the signature request by showing a close
          button.
        </div>
      </div>
    );
  }

  renderTestModeField() {
    const checked = this.props.appContext.state.form.testMode;

    return (
      <div className="m-api-docs-list--item l-margin-v-15">
        <input
          id="test-mode"
          name="test-mode"
          type="checkbox"
          checked={checked}
          onChange={this.onTestModeChange} />
        <label htmlFor="test-mode">Test Mode</label>
        <div className="m-api-docs-section--footnote">
          Whether or not to enable test mode. With this option enabled, domain verification
          will be skipped which is useful for testing signature requests on domains that
          don&rsquo;t match the API app&rsquo;s registered domain (like <code>localhost</code>).
          Only honored if the embedded request was created using <code>test_mode=1</code>.
        </div>
      </div>
    );
  }

  renderHideHeaderField() {
    const checked = this.props.appContext.state.form.hideHeader;

    return (
      <div className="m-api-docs-list--item l-margin-v-15">
        <input
          id="hide-header"
          name="hide-header"
          type="checkbox"
          checked={checked}
          onChange={this.onHideHeaderChange} />
        <label htmlFor="hide-header">Hide Header</label>
        <div className="m-api-docs-section--footnote">
          Whether to hide the signature request header. Only honored for customers with
          embedded branding.
        </div>
      </div>
    );
  }

  renderEmbedInPageField() {
    const checked = this.props.appContext.state.form.embedInPage;

    return (
      <div className="m-api-docs-list--item l-margin-v-15">
        <input
          id="embed-in-page"
          name="embed-in-page"
          type="checkbox"
          checked={checked}
          onChange={this.onEmbedInPageChange} />
        <label htmlFor="embed-in-page">Embed in Page</label>
        <div className="m-api-docs-section--footnote">
          Whether to embed the request into the page rather than in a modal.
        </div>
      </div>
    );
  }

  renderOptions() {
    return (
      <div className="m-api-docs-sub-section">
        <div className="m-api-docs-sub-section--title">Other Options</div>
        <div className="m-api-docs-list">
          {this.renderAllowCancelField()}
          {this.renderTestModeField()}
          {this.renderHideHeaderField()}
          {/* Not using this right now: */ }
          {/* this.renderEmbedInPageField() */}
        </div>
      </div>
    );
  }

  render() {
    const { showAdvancedOptions } = this.props.appContext.state;

    const rootClasses = classnames('embedded-testing-tool__form-advanced clearfix', {
      'embedded-testing-tool__form-advanced--shown': showAdvancedOptions,
    });

    return (
      <div className={rootClasses}>
        <div className="m-api-docs-section">
          <div className="m-api-docs-section--title">Advanced</div>
          <div className="m-api-docs-section--content">
            {this.renderRedirectURLField()}
            {this.renderRequestingEmailField()}
            <div className="m-api-docs-sub-section-group">
              {this.renderLanguage()}
              {this.renderTimeoutField()}
            </div>
            {this.renderWhiteLabelingCodeField()}
            {this.renderOptions()}
          </div>
        </div>
      </div>
    );
  }
}

export default withAppContext(Advanced);
