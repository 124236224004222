/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './enterprise-plan-customer-callout.scss';
import React from 'react';
import TwitterLogo from 'common/images/customers/logos/Twitter.svg';
import SamsungLogo from 'common/images/customers/logos/Samsung_Logo.svg.png';
import InstacartLogo from 'common/images/customers/logos/instacart.svg';
import LyftLogo from 'common/images/customers/logos/Lyft.png';
import IntuitLogo from 'common/images/customers/logos/intuit_95x30.png';
import OpenlegalLogo from 'common/images/customers/logos/openlegal_logo.svg';


export default function EnterprisePlanCustomerCallout() {

  const blurb = (
    <div className='blurb'>
            45,000 companies trust us with their most important transactions.
    </div>
  );

  const allLogos = [TwitterLogo, SamsungLogo, InstacartLogo, IntuitLogo, OpenlegalLogo, LyftLogo];

  const logos = (
    <div className='logos'>
      {
                allLogos.map(
                  (logosrc, idx) => {
                    return (
                      <div key={`logo-${idx}`}>
                        <img src={logosrc} />
                      </div>
                    );
                  },
                )
            }
    </div>
  );

  return (
    <div className='m-enterprise-plan-customer-callout'>
      { blurb }
      { logos }
    </div>
  );
}
