import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const HfLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 500 50"
    className={classnames(styles.icon, props.className)}
  >
    <defs></defs>
    <g id="Artboards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="About-Us" transform="translate(-1564.000000, -972.000000)" fillRule="nonzero">
        <g id="HelloFax_Logo_HelloFax_logo" transform="translate(1564.000000, 972.000000)">
          <polygon id="Shape" fill="#404040" points="65.5117303 30.3448806 65.5117303 20.5042816 56.0528959 20.5042816 56.0528959 30.3448806 50.3533607 30.3448806 50.3533607 5.81313374 56.0528959 5.81313374 56.0528959 15.4116207 65.5117303 15.4116207 65.5117303 5.81313374 71.2112655 5.81313374 71.2112655 30.3448806"></polygon>
          <polygon id="Shape" fill="#404040" points="77.7504436 30.3448806 77.7504436 5.81313374 93.3100303 5.81313374 93.3100303 10.9988771 83.4499788 10.9988771 83.4499788 15.3837692 92.4331788 15.3837692 92.4331788 20.3366843 83.4499788 20.3366843 83.4499788 25.1498535 93.3100303 25.1498535 93.3100303 30.3353525 77.7504436 30.3353525"></polygon>
          <polygon id="Shape" fill="#404040" points="99.5508048 30.3448806 99.5508048 5.81313374 105.25034 5.81313374 105.25034 25.1498535 115.110147 25.1498535 115.110147 30.3353525 99.5508048 30.3353525"></polygon>
          <polygon id="Shape" fill="#404040" points="119.522556 30.3448806 119.522556 5.81313374 125.222091 5.81313374 125.222091 25.1498535 135.082143 25.1498535 135.082143 30.3353525 119.522556 30.3353525"></polygon>
          <path d="M150.445894,10.4960852 C146.55612,10.4960852 143.916018,13.679945 143.916018,18.0941545 C143.916018,22.5083639 146.527969,25.6922237 150.445894,25.6922237 C154.36382,25.6922237 156.97577,22.4802682 156.97577,18.0941545 C156.97577,13.7080407 154.335668,10.4960852 150.445894,10.4960852 Z M150.343326,30.7450619 C142.731461,30.7450619 137.871324,25.289355 137.871324,18.0929329 C137.871324,10.8965108 142.964505,5.41319678 150.576125,5.41319678 C158.187745,5.41319678 163.048126,10.8689037 163.048126,18.0929329 C163.048126,25.3169621 157.916513,30.7450619 150.343081,30.7450619 L150.343326,30.7450619 Z" id="Shape" fill="#404040"></path>
          <polygon id="Shape" fill="#404040" points="174.447442 10.9988771 174.447442 16.4172044 182.898949 16.4172044 182.898949 21.4072547 174.447442 21.4072547 174.447442 30.307501 168.747906 30.307501 168.747906 5.81313374 184.307493 5.81313374 184.307493 10.9988771"></polygon>
          <path d="M196.480847,15.6537328 L194.036825,21.5470005 L198.896962,21.5470005 L196.480847,15.6537328 Z M202.544145,30.3446363 L200.678571,25.8945131 L192.199157,25.8945131 L190.333583,30.3446363 L184.531969,30.3446363 L196.098479,5.61768501 L196.872761,5.61768501 L208.439761,30.3446363 L202.544145,30.3446363 Z" id="Shape" fill="#404040"></path>
          <polygon id="Shape" fill="#404040" points="226.6206 30.3448806 221.695103 22.2823763 216.695188 30.3448806 209.988082 30.3448806 218.066523 18.3628965 209.988082 5.81313374 216.797757 5.81313374 221.760463 13.9127733 226.760377 5.81313374 233.430275 5.81313374 225.351833 17.7951179 233.430275 30.3448806"></polygon>
          <path d="M0.103058211,0.140234461 L0.103058211,36.0177799 L36.0517695,36.0177799 L36.0517695,0.140234461 L0.103058211,0.140234461 Z M3.50373438,3.53395728 L32.6513382,3.53395728 L32.6513382,9.8371787 L23.6882113,9.8371787 L9.81892614,23.6791018 L9.81892614,32.6240571 L3.50324479,32.6240571 L3.50373438,3.53395728 Z M22.6923899,15.6305233 L22.6923899,22.6847564 L15.6239675,22.6847564 L22.6923899,15.6305233 Z M13.2188679,32.6240571 L13.2188679,26.0787235 L26.0923317,26.0787235 L26.0923317,13.2306572 L32.6506038,13.2306572 L32.6506038,32.6240571 L13.2188679,32.6240571 Z" id="Shape" fill="#F3961F"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(HfLogo);
