import { intl } from 'hellospa/components/hs-intl-provider';
import { defineMessages } from 'react-intl';

window.hsIntl = intl;

window.hsMessages = defineMessages({
  hasNotEditedDocs: {
    id: '',
    description: 'Prepare doc for signing button text',
    defaultMessage: 'Prepare {countDocs, plural,=1{doc} other {docs}} for signing',
  },
  hasEditedDocs: {
    id: '',
    description: 'Prepare doc for signing button text',
    defaultMessage: 'Re-edit {countDocs, plural,=1{doc} few{docs} other {docs}} for signing',
  },
  attachmentError: {
    id: '',
    description: 'Don\'t forget to attach a file error',
    defaultMessage: 'Don\'t forget to attach a file',
  },
  waitUploadAndConvertError: {
    id: '',
    description: 'Please wait until your files finish uploading and converting error message',
    defaultMessage: 'Please wait until your files finish uploading and converting.',
  },
  externalServiceGoogleDriveContentModal: {
    id: '',
    description: 'Body message on Google Drive pop up modal for external service integration',
    defaultMessage: '<p class="auth_text">To use your Google Drive files, you must install and launch the {brandName} application. Click Continue to install the application, once there <span style="font-weight: bold;">Install</span> then <span style="font-weight: bold;">Launch</span> the application.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceEvernoteContentModal: {
    id: '',
    description: 'Body message on Evernote pop up modal, external service integration',
    defaultMessage: '<p class="auth_text">To use your Evernote files, you must first link your {brandName} account. We will also create a {brandName} notebook. Anytime you send or receive a document, it will be backed up as a note.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceDropboxContentModal: {
    id: '',
    description: 'Body message on Dropbox pop up modal, external service integration',
    defaultMessage: '<p class="auth_text">To use your Dropbox files, you must first link your {brandName} account. We will also create a {brandName} folder in your Dropbox. Anytime you send or receive a document, it will be backed up in your Dropbox account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href=" {authUrl} escape(\'?dropbox_set_sync=1\')">Continue</a></p>',
  },
  externalServiceBoxContentModal: {
    id: '',
    description: 'Body message on Dropbox pop up modal, external service integration',
    defaultMessage: '<p class="auth_text">To use your Box files, you must first authorize {brandName} to access files in your Box account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceOneDriveContentModal: {
    id: '',
    description: 'Body message on Dropbox pop up modal, external service integration',
    defaultMessage: '<p class="auth_text">To use your OneDrive files, you must first link your {brandName} account. We will also create a {brandName} folder in your OneDrive. Anytime you send or receive a document, it will be backed up in your OneDrive account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceGoogleDriveReauthorize: {
    id: '',
    description: 'Body message on Google Drive pop up modal for Re-authorization external service integration',
    defaultMessage: '<p class="auth_text"><u>Google Drive has upgraded the way they handle authorizations</u>. As a result you must reauthorize {brandName} to access files in your Drive account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Re-authorize</a></p>',
  },
  externalServiceBoxReauthorize: {
    id: '',
    description: 'Body message on Box pop up modal for Re-authorization external service integration',
    defaultMessage: '<p class="auth_text"><u>Box.net has upgraded the way they handle authorizations</u>. As a result you must reauthorize {brandName} to access files in your Box account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Re-authorize</a></p>',
  },
  externalServiceAuthError: {
    id: '',
    description: 'Authorization error on external services integration. not access denied',
    defaultMessage: 'Sorry something went wrong, please try again later. Error: { authResultError}',
  },
  attachmentRetrieving: {
    id: '',
    description: 'Status text while retrieving',
    defaultMessage: 'RETRIEVING',
  },
  attachmentConverting: {
    id: '',
    description: 'Status text while uploading',
    defaultMessage: 'CONVERTING',
  },
  attachmentUploadingAndName: {
    id: '',
    description: '\'Uploading + filename text\' while uploding the filename',
    defaultMessage: 'UPLOADING: { filename }',
  },
  addCC: {
    id: '',
    description: 'Add CC button text',
    defaultMessage: 'Add CC',
  },
  addTemplatePopUpModal: {
    id: '',
    description: 'Text body on the modal pop for add Template button',
    defaultMessage: '<div id="external_auth" class="dropbox"><p class="logo_text">Templates* </p><p class="auth_text">Asking people to sign the same document frequently?  Create a template!  It\'s great for NDAs, employee contracts and other standard documents.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" href="/home/createReusableDocs">Create a Template</a></p></div>',
  },
  forgetPrepareAndAssign: {
    id: '',
    description: 'Error message when user forgets to prepare the document and/or assign fields to signers',
    defaultMessage: 'Don\'t forget to prepare the documents and assign fields to all signers.',
  },
  forgetEditDocument: {
    id: '',
    description: 'Error message when user forgets to edit the document',
    defaultMessage: 'Don\'t forget to edit the documents.',
  },
  noFieldAssigned: {
    id: '',
    description: 'Error message when no field is assigned to signers',
    defaultMessage: 'No fields are currently assigned to {errorMessage}.',
  },
  missingRecipients: {
    id: '',
    description: 'Error message when there are missing recipients in the template',
    defaultMessage: 'At least one role is required. Please enter name and email address.',
  },
  maxAllowedPageError: {
    id: '',
    description: 'Error message to display how many pages are allowed',
    defaultMessage: 'The maximum total number of pages allowed is {maxPages}.',
  },
  fileWithTooManyPages: {
    id: '',
    description: 'Error message showing that a file has too many pages',
    defaultMessage: 'That file has too many pages.',
  },
  documentConvertError: {
    id: '',
    description: 'Error Message when there is document conversion error',
    defaultMessage: 'We could not convert your file. Try saving it in another format or email us at {supportEmail}',
  },
  passworDocumentError: {
    id: '',
    description: 'Error Message when the uploaded file/ file to convert is password protected',
    defaultMessage: 'This file requires a password. Please remove the password and try again.',
  },
  importFileTooLarge: {
    id: '',
    description: 'Error Message when file to be imported are too large',
    defaultMessage: 'This file is too large to be imported ({fileSize}MB max)',
  },
  fileRetrievalError: {
    id: '',
    description: 'Error Message when there is an error retrieving a file that needs to be converted',
    defaultMessage: 'There was an error retrieving the file. Please try again later.',
  },
  missingDocumentTitle: {
    id: '',
    description: 'Error message when document title is missing while requesting signature',
    defaultMessage: 'Please provide a document title.',
  },
  badAuthSync: {
    id: '',
    description: 'Error message when the user is signed out with the external services, or the synchronization is not working',
    defaultMessage: 'We could not retrieve this file, please re-establish sync for this service',
  },
  badRequesthSync: {
    id: '',
    description: 'Error message when the user is unable to retrieve file associated to an email from external services',
    defaultMessage: 'We could not retrieve this file, please re-establish sync for this service',
  },
});
