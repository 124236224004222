/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
// Keep this in sync with AccountExternalService.class.php

module.exports = {
  BOXNET: 'B',
  DROPBOX: 'D',
  GOOGLE_DRIVE: 'G',
  GOOGLE_APPS: 'A',
  GOOGLE_DOCS: 'F',
  EVERNOTE: 'E',
  MS_SP: 'S',
  MS_OFFICE: 'O',
  MS_OUTLOOK: 'L',
  ONEDRIVE: 'T',
  SKYDRIVE: 'K', // OneDrive
  QQ_CATALYST: 'Q',
  HUBSPOT: 'H',
  SLACK: 'C',
  SALESFORCE: 'M',
  ORACLECLOUD: 'R',
  ZAPIER: 'Z',
  ALL: 'ALL', // used when calling syncFileWrapper.php worker
};
