/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import baseform from 'common/components/base-form';
import HfReactHelper from 'common/utils/hf-react-helper';
import logger from 'common/logger';
import pushGoogleTagManagerEvent from 'common/utils/pushGoogleTagManagerEvent';

const convertPlanName = (planName) => {
  if (planName == null) {
    return planName;
  }

  switch (planName.toLowerCase()) {
    case 'business': return 'b5';
    default: return planName.toLowerCase();
  }
};

/**
 * NOTE: This form does not follow the proper way to use
 * React and Symfony Forms, a better example can be found
 * by looking at ForgotPasswordForm
 */
const PaymentForm = baseform.createFormClass({

  displayName: 'PaymentForm',

  propTypes: {
    stripeToken: PropTypes.string,
    dbxPaymentMethod: PropTypes.object,
    planId: PropTypes.string,
    planName: PropTypes.string.isRequired,
    couponCode: PropTypes.string,
    timePeriod: PropTypes.string,
    tabOffset: PropTypes.number,
    card: PropTypes.object,
    buttonText: PropTypes.string,
    headerText: PropTypes.string,
    billingString: PropTypes.object,
    ccExpiration: PropTypes.string,
    formAction: PropTypes.string,
    numSeats: PropTypes.number,
    currentPlanName: PropTypes.string,
    errors: PropTypes.object,
    isApi: PropTypes.bool.isRequired,
  },

  CARD_TYPES: [
    { long: 'MasterCard', short: 'mastercard' },
    { long: 'Visa', short: 'visa' },
    { long: 'American Express', short: 'amex' },
    { long: 'Discover', short: 'discover' },
  ],

  getDefaultProps() {
    return {
      formAction: '',
      numSeats: 1,
    };
  },

  getInitialState() {
    const globalError = (this.props.errors && this.props.errors['payment[submit]']) || null;
    return {
      card: $.extend({
        expYear: this.getDefaultExpYear(),
        expMonth: this.getDefaultExpMonth(),
        number: '0',
        cvv: '000',
        type: 'Unknown',
      }, this.props.card),
      isProcessing: false,
      stripeToken: this.props.stripeToken || '',
      errors: { global: globalError },
    };
  },

  // By default we set the expiration date to 1 month from today
  getDefaultExpMonth() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1); // Here 12 will result in January next year (0)
    // Get the next month with offset of +1 because js date stores months in 0-11
    const nextMonth = currentDate.getMonth() + 1;
    // For 1 digit months add leading 0 before returning
    return (nextMonth < 10) ? `0${nextMonth.toString()}` : nextMonth.toString();
  },

  getDefaultExpYear() {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    // If december -> return next year
    return currentMonth == 11 ? currentYear + 1 : currentYear;
  },

  onContinueClick(evt) {
    evt.preventDefault();

    // Detect if the payment method is DBX and submit the form
    // without CC validation
    const dbxPaymentMethod = this.props.dbxPaymentMethod;
    if (!this.state.enterManually && dbxPaymentMethod && dbxPaymentMethod.method_id) {
      this.submitForm();
      return;
    }

    pushGoogleTagManagerEvent({
      category: 'signup',
      action: `credit card submitted - ${this.props.isApi
        ? 'hsapi'
        : 'hs'}`,
      label: convertPlanName(this.props.planName),
    });

    const card = this.state.card;

    if (!card.suffix && (!card.name || card.name.length < 1)) {
      this.setState({
        errors: { global: 'Cardholder name is required.' },
        isProcessing: false,
      });
      return;
    }

    if (this.state.isProcessing === false) {

      this.setState({
        errors: null,
        isProcessing: true,
      });

      if (card.suffix) {
        this.submitForm();
        return;
      }

      Stripe.card.createToken({
        number: card.number,
        cvc: card.cvv,
        exp_month: card.expMonth,
        exp_year: card.expYear,
        address_zip: card.zip,
        name: card.name,
      }, (status, response) => {
        if (response.error) {
          this.setState({
            errors: { global: response.error.message },
            isProcessing: false,
          });
        } else {
          const token = response.id;
          this.setState({
            stripeToken: token,
          });


          pushGoogleTagManagerEvent({
            category: 'signup',
            action: `account created - ${this.props.isApi
              ? 'hsapi'
              : 'hs'}`,
            label: convertPlanName(this.props.planName),
          });

          this.submitForm();
        }
      });
    }

    return false;
  },

  submitForm() {
    logger.track('PaymentForm._onSuccess', {
      previousPlanName: this.props.currentPlanName,
      newPlanName: this.props.name,
    });
    $('#payment_form').submit();
  },

  onCardType(event) {
    const card = this.state.card;
    card.type = Stripe.cardType(event.target.value);
    card.number = event.target.value;
    this.setState({ card });
  },

  onExpMonthChange(event) {
    const card = this.state.card;
    card.expMonth = event.target.value;
    this.setState({ card });
  },

  onExpYearChange(event) {
    const card = this.state.card;
    card.expYear = event.target.value;
    this.setState({ card });
  },

  onCvvChange(event) {
    const card = this.state.card;
    card.cvv = event.target.value;
    this.setState({ card });
  },

  onZipChange(event) {
    const card = this.state.card;
    card.zip = event.target.value;
    this.setState({ card });
  },

  onCardholderNameChange(event) {
    const card = this.state.card;
    card.name = event.target.value;
    this.setState({ card });
  },

  onSwitch() {
    this.setState({ enterManually: !this.state.enterManually });
  },

  getCCBrandLogoClassSuffix(brand) {
    switch (brand) {
      case 'American Express':
        return 'amex';
      case 'Discover':
        return 'discover';
      case 'Elo':
        return 'elo';
      case 'JCB':
        return 'jcb';
      case 'Maestro':
        return 'maestro';
      case 'MasterCard':
        return 'mastercard';
      case 'UnionPay':
        return 'unionpay';
      case 'Visa':
        return 'visa';
      case 'Unknown':
      default:
        return '';
    }
  },

  render() {
    let formFields;
    let stripeTokenField;
    const card = this.state.card;
    const tabOffset = parseInt(this.props.tabOffset || 1000, 10);
    const dbxPaymentMethod = this.props.dbxPaymentMethod;
    const enterManually = this.state.enterManually;

    // Stripe token
    if (this.state.stripeToken) {
      stripeTokenField = (<input type='hidden' name='payment[stripe_token]' value={this.state.stripeToken} />);
    }

    const longDescription = (
      <div className='m-payment--left-border columns small-12 text-left'>
        <p>{ this.props.billingString }</p>
      </div>
    );

    const cardDisplays = this.CARD_TYPES.map((type, i) => {
      return (<img key={i} src={HfReactHelper.imgHelper(`api/pricing/upgrade_popup/${type.short}_${(card.type == type.long) ? '' : 'in'}active.png`)} />);
    });

    const hasDbxPaymentMethod = dbxPaymentMethod && dbxPaymentMethod.method_id;

    // Form fields
    if (!card.suffix) {

      const switchToDbxMethod = hasDbxPaymentMethod ? (
        <div className='row'>
          <div className='m-payment--dbx-switch columns small-12' onClick={this.onSwitch}>
            I&apos;d like to use my Dropbox payment method
          </div>
        </div>
      ) : '';

      if (hasDbxPaymentMethod && !enterManually) {
        const dbxCCBrandLogo = this.getCCBrandLogoClassSuffix(dbxPaymentMethod.brand);
        // Show user their existing DBX payment method
        formFields = (
          <div>
            <div className='row m-payment--wrapper'>
              <div className='columns small-12 large-7'>
                <div className='row'>
                  <div className='columns small-12 text-left'>
                    <h5 className="m-payment--dbx-header">Use your Dropbox payment method on HelloSign</h5>
                    <p className="m-payment--dbx-subheader">We&apos;re a part of Dropbox so we&apos;ve made it easier to use the same credit card.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='m-payment--dbx-card-wrapper columns small-12'>
                    <div className={`m-payment--cc-logo m-payment--cc-logo--brand-${dbxCCBrandLogo}`}></div>
                    <div>
                      <span>{dbxPaymentMethod.name}</span><br />
                      <span>Card ending in</span> <span className="m-payment--dbx-card-last-four-digits">****{dbxPaymentMethod.last_four_digits}</span><br />
                      <span>Expires {dbxPaymentMethod.expiration_date}</span>
                      <input type='hidden' name='dbx_payment_method_id' value={dbxPaymentMethod.method_id} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='m-payment--manual-switch columns small-12' onClick={this.onSwitch}>
                    I&apos;d like to use another card
                  </div>
                </div>
              </div>
              <div className='columns small-12 large-5'>
                { longDescription }
              </div>
            </div>
          </div>
        );
      } else {
        // A regular CC form
        const curYear = new Date().getFullYear();
        const numYears = 10;
        const yearOptions = Array(...Array(numYears)).map((val, offset) => {
          const year = curYear + offset;
          return (<option value={year} key={offset}>{ year }</option>);
        });

        formFields = (
          <div>
            <div className='row m-payment--wrapper'>
              <div className='columns small-12 large-7'>
                <div className='row mt20'>
                  <div className='columns small-12 text-left'>
                    <p>Enter your credit card information</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='columns small-12'>
                    <input type='text'
                      placeholder='Cardholder Name'
                      className='m-payment--cardholder-name columns small-8'
                      value={card.name}
                      onChange={this.onCardholderNameChange}
                      tabIndex={tabOffset} />
                  </div>
                </div>
                <div className='row'>
                  <div className='columns small-12 m-payment--cc'>
                    <input type='text' placeholder='Credit card number' onInput={this.onCardType} tabIndex={tabOffset + 1} />
                    <div className='m-payment--ccs show-for-medium-up'>
                      { cardDisplays }
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='columns small-12 large-6 small-text-left large-text-right'>
                    <select className='m-payment--cc-exp-month columns small-4'
                      value={card.expMonth}
                      onChange={this.onExpMonthChange}
                      tabIndex={tabOffset + 2}
                      data-qa-ref='credit-card-expiry-month'
                    >
                      <option value='01'>01</option>
                      <option value='02'>02</option>
                      <option value='03'>03</option>
                      <option value='04'>04</option>
                      <option value='05'>05</option>
                      <option value='06'>06</option>
                      <option value='07'>07</option>
                      <option value='08'>08</option>
                      <option value='09'>09</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                      <option value='12'>12</option>
                    </select>
                    <select className='m-payment--cc-exp-year columns small-8'
                      value={card.expYear}
                      onChange={this.onExpYearChange}
                      tabIndex={tabOffset + 3}
                      data-qa-ref='credit-card-expiry-year'
                    >
                      {yearOptions}
                    </select>
                  </div>
                  <div className='columns small-12 large-6 large-text-left small-text-right'>
                    <input type='text' placeholder='CVV' className='m-payment--cvv columns small-4' onChange={this.onCvvChange} tabIndex={tabOffset + 4} />
                    <input type='text' placeholder='Zip code' name='payment[cc_zip]' className='m-payment--zip columns small-8' onChange={this.onZipChange} tabIndex={tabOffset + 5} />
                  </div>
                </div>
                { switchToDbxMethod }
              </div>
              <div className='columns small-12 large-5'>
                { longDescription }
              </div>
            </div>
          </div>
        );
      }
    } else if (this.props.monthlyExpectedRate == 0) {
      formFields = (
        <div>
          <div className='row'>
            <div className='columns small-12 text-left'>
              <p>{this.props.billingString}</p>
            </div>
          </div>
        </div>
      );
    } else {
      formFields = (
        <div>
          <div className='row'>
            <div className='columns small-12 text-left'>
              <p>The following card will be charged:</p>
            </div>
          </div>
          <div className='row'>
            <div className='columns small-12 text-left'>
              <h4>xxxx xxxx xxxx {this.state.card.suffix}</h4>
              <h4>Expires {this.props.ccExpiration} </h4>
            </div>
            {longDescription}
          </div>
        </div>
      );
    }

    return (
      <div className='m-payment'>
        <form id='payment_form' action={this.props.formAction} method='POST' onSubmit={this.onContinueClick}>
          <div className='row'>
            <div className='columns small-12 text-center l-padding-v-20'>
              <h3>{ this.props.headerText }</h3>
            </div>
          </div>
          { formFields }
          <div className='row l-margin-v-10'>
            <div className='columns small-12 large-7 text-left m-payment--error'>
              { this.renderErrorText() }
            </div>
          </div>
          <div className='row l-padding-v-20'>
            <div className='columns small-12 text-center'>
              <Button
                buttonText={this.props.buttonText || 'Continue'}
                buttonColor='grasshopper'
                buttonHoverColor='castle-rock'
                buttonTabIndex={tabOffset + 7}
                type='submit'
                disabled={this.state.isProcessing}
                buttonAttrs={{
                  'data-qa-ref': 'continue-button-credit-card-window',
                }} />
            </div>
          </div>
          { stripeTokenField }
          { this.renderCsrfField() }
          <input type='hidden' name='cardHolderName' value={card.name} />
          <input type='hidden' name='payment[num_seats]' value={this.props.numSeats} />
          <input type='hidden' name='payment[target_plan]' value={this.props.planId} />
          <input type='hidden' name='payment[coupon_code]' value={this.props.couponCode || ''} />
          <input type='hidden' name='payment[period]' value={this.props.timePeriod} />
        </form>
      </div>
    );
  },
});

module.exports = PaymentForm;
