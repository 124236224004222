/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  eqeqeq,
  no-restricted-syntax,
  no-void
*/
import Collection from 'common/collection';

function NotifierCollection() { }

Collection.extend(NotifierCollection, {
  notify(message) {

    const promises = [];

    for (const notifier of this) {
      promises.push(notifier.notify(message));
    }

    // some notifiers might not return anything, so filter them out
    return Promise.all(promises).then((responses) => {
      return responses.filter((response) => {
        return response != void 0;
      });
    });
  },
});

export default NotifierCollection;
