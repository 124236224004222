/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const ClickableImage = createReactClass({

  propTypes: {
    imgSourceActive: PropTypes.string.isRequired,
    imgSourceInactive: PropTypes.string.isRequired,
    imgSourceActiveHeight: PropTypes.number,
    imgSourceActiveWidth: PropTypes.number,
    imgSourceInactiveHeight: PropTypes.number,
    imgSourceInactiveWidth: PropTypes.number,
    imgText: PropTypes.string,
    isActive: PropTypes.bool,

  },
  getInitialState() {
    return { isHover: false };
  },
  __onMouseEnter() {
    this.setState({ isHover: true });
  },
  __onMouseLeave() {
    this.setState({ isHover: false });
  },
  render() {

    if (this.props.isActive || this.state.isHover) {
      return <div>
        <div onMouseEnter={this.__onMouseEnter} onMouseLeave={this.__onMouseLeave} className='m-tabs_tiles--tileWrapper'>
          <span className='m-tabs_tiles--center-helper'></span>
          <img width={this.props.imgSourceActiveWidth} height={this.props.imgSourceActiveHeight} className='m-tabs_tiles--clickable-img' src={this.props.imgSourceActive} />
        </div>
        <div className='m-tabs_tiles--tile-text-container '>
          <p>{this.props.imgText}</p>
        </div>
      </div>;
    } else {
      return <div>
        <div onMouseEnter={this.__onMouseEnter} onMouseLeave={this.__onMouseLeave} className='m-tabs_tiles--tileWrapper show-for-large-up'>
          <span className='m-tabs_tiles--center-helper'></span>
          <img width={this.props.imgSourceInactiveWidth} height={this.props.imgSourceInactiveHeight} className='m-tabs_tiles--clickable-img' src={this.props.imgSourceInactive} />
        </div>
        <div className='m-tabs_tiles--tile-text-container show-for-large-up'>
          <p>{this.props.imgText}</p>
        </div>
      </div>;
    }
  },

});

module.exports = ClickableImage;
