import React from 'react';
import fuzzy from 'fuzzy';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import * as directions from '../constants/directions';
import * as keys from '../constants/keys';
import * as types from '../constants/types';

/**
 * Filters the list of entities to show within the entity
 * chooser.
 *
 * @param {Entity[]} entities
 * @returns {Entity[]}
 */
function getScopedEntities(entities) {
  return entities.filter((entity) => !entity.isSelf);
}

/**
 * Fuzzy searches all entities by entity name.
 *
 * @param {string} query
 * @param {Entity[]} entities
 * @returns {Object[]}
 */
function fuzzySearchEntityNames(query, entities) {
  const entitiesWithNames = entities.filter((entity) => entity.type === types.TEAM);

  const results = fuzzy.filter(query, entitiesWithNames, {
    extract(entity) {
      return entity.name;
    },
  });

  return results;
}

/**
 * Fuzzy searches all entities by entity email (if
 * applicable).
 *
 * @param {string} query
 * @param {Entity[]} entities
 * @returns {Object[]}
 */
function fuzzySearchEntityEmails(query, entities) {
  const entitiesWithEmails = entities.filter((entity) => !!entity.email);

  const results = fuzzy.filter(query, entitiesWithEmails, {
    pre: '<span class="team-access-modal__entity-access-list-item-fuzzy">',
    post: '</span>',
    extract(entity) {
      return entity.email;
    },
  });

  return results;
}

/**
 * Removes all duplicate entities within the search
 * results. We are searching for both name and email so it
 * could be the case that an entity matches both -- we
 * don't want to show the same entity twice.
 *
 * @param {Object[]} results
 * @returns {Object[]}
 */
function dedupeSearchResultsByGuid(results) {
  const dedupedResults = [];

  for (let i = 0, dirtyGuids = [], len = results.length; i < len; i++) {
    const result = results[i];
    const entity = result.original;

    if (!dirtyGuids.includes(entity.guid)) {
      dirtyGuids.push(entity.guid);
      dedupedResults.push(result);
    }
  }

  return dedupedResults;
}

/**
 * Fuzzy searches all entities by name and email.
 *
 * @param {string} query
 * @param {Entity[]} entities
 * @returns {Object[]}
 */
function fuzzySearchEntities(query, entities) {
  return dedupeSearchResultsByGuid([
    ...fuzzySearchEntityNames(query, entities),
    ...fuzzySearchEntityEmails(query, entities),
  ]);
}

const messages = defineMessages({
  searchByEmail: {
    id: '',
    description: 'placeholder text indicating to search by email or team name in team access modal in doc page',
    defaultMessage: 'Search by email or team name',
  },
});

/**
 * Renders the entity chooser.
 *
 * @param {Object} props
 * @param {entity[]} props.entities
 * @param {Function} props.grantAccess
 * @param {Function} props.resetAccess
 * @returns {JSX.Element}
 */
function EntityChooser({ entities, grantAccess, resetAccess }) {
  const queryRef = React.useRef();
  const resultsContainerRef = React.useRef();
  const selectedResultRef = React.useRef();
  const [lastNavigationDir, setLastNavigationDir] = React.useState(null);
  const [showResults, setShowResults] = React.useState(false);
  const [entityResults, setEntityResults] = React.useState([]);
  const [selectedResultIndex, setSelectedResultIndex] = React.useState(0);
  const entitiesInScope = React.useMemo(() => getScopedEntities(entities), [entities]);
  const intl = useIntl();

  const onDocumentClick = ({ target }) => {
    const closestInput = target.closest('.team-access-modal__entity-chooser-query');
    const closestResults = target.closest('.team-access-modal__entity-chooser-results-container');

    if (!closestInput && !closestResults) {
      // User clicked out of focus.
      setShowResults(false);
    }
  };

  const onQueryFocus = React.useCallback(() => {
    if (entityResults.length) {
      setShowResults(true);
    }
  }, [entityResults]);

  const updateChooserScrollOffset = React.useCallback(() => {
    const selResEl = selectedResultRef.current;
    const resContainerEl = resultsContainerRef.current;

    if (selResEl && resContainerEl) {
      const selResParentEl = selResEl.parentElent;

      const { height: selResElHght } = selResEl.getBoundingClientRect();
      const { height: resContainerElHght } = resContainerEl.getBoundingClientRect();
      const { height: selResParentElHght } = selResParentEl.getBoundingClientRect();

      switch (lastNavigationDir) {
        case directions.DOWN:
          if (selResEl.offsetTop > resContainerEl.scrollTop + resContainerElHght - selResElHght) {
            resContainerEl.scrollTop = Math.min(
              selResEl.offsetTop - resContainerElHght + (selResElHght * 3 / 2),
              selResParentElHght,
            );
          }
          break;
        case directions.UP:
          if (selResEl.offsetTop < resContainerEl.scrollTop) {
            resContainerEl.scrollTop = Math.max(selResEl.offsetTop - (selResElHght / 2), 0);
          }
          break;
        default: // No default.
      }
    }
  }, [lastNavigationDir]);

  React.useEffect(() => {
    updateChooserScrollOffset();
  }, [selectedResultIndex, updateChooserScrollOffset]);

  React.useEffect(() => {
    const currentQueryRef = queryRef.current;

    document.addEventListener('click', onDocumentClick);

    if (currentQueryRef) {
      currentQueryRef.addEventListener('focus', onQueryFocus);
    }

    return () => {
      document.removeEventListener('click', onDocumentClick);

      if (currentQueryRef) {
        currentQueryRef.removeEventListener('focus', onQueryFocus);
      }
    };
  });

  const resetSearch = React.useCallback(() => {
    queryRef.current.value = '';
    setEntityResults([]);
  }, []);

  const grantEntityAccess = React.useCallback((entity) => {
    grantAccess(entity, true);
    resetSearch();
  }, [grantAccess, resetSearch]);

  const resetEntityAccess = React.useCallback((entity) => {
    resetAccess(entity, true);
    resetSearch();
  }, [resetAccess, resetSearch]);

  const onSearchFormSubmit = React.useCallback((evt) => {
    evt.preventDefault();

    if (entityResults.length) {
      const result = entityResults[selectedResultIndex];

      if (result) {
        const entity = result.original;

        grantEntityAccess(entity);
      }
    }
  }, [entityResults, selectedResultIndex, grantEntityAccess]);

  const onSearchFieldChange = React.useCallback(() => {
    const query = queryRef.current.value;

    if (query.length) {
      const matchingEntities = fuzzySearchEntities(query, entitiesInScope);

      setShowResults(true);
      setEntityResults(matchingEntities);
      setSelectedResultIndex(0);
    } else {
      setShowResults(false);
      setEntityResults([]);
    }

    if (resultsContainerRef.current) {
      // Reset results list scroll offset.
      resultsContainerRef.current.scrollTop = 0;
    }
  }, [entitiesInScope]);

  const navigateDown = React.useCallback(() => {
    setLastNavigationDir(directions.DOWN);
    setSelectedResultIndex((currentState) => (
      Math.min(currentState + 1, entityResults.length - 1)
    ));
  }, [entityResults]);

  const navigateUp = React.useCallback(() => {
    setLastNavigationDir(directions.UP);
    setSelectedResultIndex((currentState) => (
      Math.max(currentState - 1, 0)
    ));
  }, []);

  const onSearchFieldKeyDown = React.useCallback((evt) => {
    if (entityResults.length && [keys.ARROW_DOWN, keys.ARROW_UP].includes(evt.key)) {
      evt.preventDefault();

      switch (evt.key) {
        case keys.ARROW_DOWN:
          navigateDown();
          break;
        case keys.ARROW_UP:
          navigateUp();
          break;
        default: // No default
      }
    }
  }, [entityResults.length, navigateDown, navigateUp]);

  const onClickResult = React.useCallback((entity) => (/* evt */) => {
    if (entity.hasExplicitTemplateAccess && entity.willAccessBeGranted) {
      resetEntityAccess(entity);
    } else {
      grantEntityAccess(entity);
    }
  }, [resetEntityAccess, grantEntityAccess]);

  return (
    <div className="team-access-modal__entity-chooser">
      <p className="team-access-modal__entity-chooser-header-text">
        <FormattedMessage
          id=""
          description="message on team access modal where template accessors are listed"
          defaultMessage="Grant or remove access to your templates for users or teams. The template owner and admins cannot be removed." />
      </p>
      <form className="team-access-modal__entity-chooser-form" onSubmit={onSearchFormSubmit}>
        <p className="team-access-modal__entity-chooser-query-label">
          <FormattedMessage
            id=""
            description="input header text stating email or team name can be added for template access in team access modal in doc page"
            defaultMessage="Add a person or team" />
        </p>
        <input
          className="team-access-modal__entity-chooser-query"
          name="query"
          type="text"
          placeholder={intl.formatMessage(messages.searchByEmail)}
          autoComplete="off"
          ref={queryRef}
          onChange={onSearchFieldChange}
          onKeyDown={onSearchFieldKeyDown} />
        {showResults && entityResults.length ? (
          <div className="team-access-modal__entity-chooser-results-container" ref={resultsContainerRef}>
            <ul className="team-access-modal__entity-chooser-results">
              {entityResults.map((result, i) => {
                const entity = result.original;
                const isSelected = selectedResultIndex === i;

                return (
                  <li
                    className={`team-access-modal__entity-chooser-results-item${isSelected ? ' team-access-modal__entity-chooser-results-item--selected' : ''}`}
                    key={entity.guid}
                    ref={isSelected ? selectedResultRef : null}
                    onClick={onClickResult(entity)}
                  >
                    <div className="team-access-modal__entity-chooser-item">
                      <p dangerouslySetInnerHTML={{ __html: result.string }} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default EntityChooser;
