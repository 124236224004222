/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './plan-features-card.scss';
import React from 'react';

export default function PlanFeaturesCard(p) {

  const features = p.features;

  return (
    <div className='m-plan-features-card'>
      <h1>
                KEY FEATURES
      </h1>
      <ul>
        {
                    features.map((feature, idx) => {

                      let featureNote;
                      if (feature.note) {
                        featureNote = <small>{ feature.note }</small>;
                      }

                      return (
                        <li key={`feat-${idx}`} >
                          {feature.number} { feature.name }
                          { featureNote }
                        </li>
                      );
                    })
                }
      </ul>
    </div>
  );
}
