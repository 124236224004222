// @flow
import * as React from 'react';
import asBlock from './as-block';

type Props = {
    is?: string | React.ComponentType<*>
};

/**
 * Box component.  This component is a low-level component that has convenience
 * properties for setting certain often-used style settings and operating with
 * the `theme` that's been defined for an app.  It's built on `styled-system` so
 * much of the documentation can be found there.
 *
 * @example
 * // Will set a margin and padding based on the elements at those points on the
 * // defined `space` scale
 * <Box m={2} p={3}>
 *      <MyComponent {...}  />
 * </Box>
 *
 * // Will set the background color the `primary` color defined on the theme and
 * // the text color will be the `secondary` color defined on the theme
 * <Box bg='primary' color='secondary'>
 *      <MyComponent {...}  />
 * </Box>
 *
 * See:  https://github.com/jxnblk/styled-system
 */
export function Box(props: Props) {
  const { is = 'div', ...rest } = props;

  return React.createElement(is, rest);
}

export default asBlock(Box);
