/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-console,
*/
import React from 'react';
import PropTypes from 'prop-types';
import './dropbox-signin-button.scss';
import DropboxSvg from '../../../../public/images/v2/modules/home/integrations/dropbox_icon.svg';

export default class DropboxSignInButton extends React.Component {

  static propTypes = {
    redirectUrl: PropTypes.string,
  };

  onSignIn = () => {
    const authUrl = `/account/dropboxLogin${this.props.redirectUrl ? `?on_login_redirect_url=${btoa(this.props.redirectUrl)}` : ''}`;
    window.location.replace(authUrl);
  };

  render() {
    return (
      <div className="dropbox-signin-button">
        <button className="dropbox-signin-button__button" onClick={this.onSignIn}>
          <div className="dropbox-signin-button__left">
            <img src={DropboxSvg} className="dropbox-signin-button__icon" alt="" aria-hidden="true" />
          </div>
          <div className="dropbox-signin-button__right">Sign in with Dropbox</div>
        </button>
      </div>
    );
  }
}
