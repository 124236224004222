/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  no-use-before-define
*/
import React from 'react';

export default function Benefits({ benefits = [] }) {
  return (
    <div className='seo-cloud-integrations__benefits'>
      <h2>Benefits</h2>
      <div className='row'>
        { benefits.map((benefit) => renderBenefit(benefit)) }
      </div>
    </div>
  );
}

function renderBenefit({ title, icon, text }) {
  return (
    <section key={title} className='large-4 columns'>
      <div className='benefits-image-container'>
        <img src={icon} />
      </div>
      <h3>{ title }</h3>
      <p>{ text }</p>
    </section>
  );
}
