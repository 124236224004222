/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  no-void,
  one-var,
  react/no-unescaped-entities
*/
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import Header from 'common/components/headers/logo-only-header';
import Footer from 'common/components/footers/captive-footer';
import pushGoogleTagManagerEvent from 'common/utils/pushGoogleTagManagerEvent';
import PaymentModal from '../payment-modal';
import SuccessTestDriveModal from './success-test-drive-modal';
import CouponDisplay from '../coupon-display';

// V2
import PlanChoiceAreaV2 from './v2/plan-choice-area-v2';

class ChooseSignupPlan extends React.Component {

  constructor(props) {
    super(props);
    // Get initial state
    this.state = {
      timePeriod: 'Y',
      teamSize: '',
      successModalVisible: false,
    };
  }

    onPlanChoice = (options) => {
      // There was an experiment here (A/B Testing) between PaymentModal and PaymentPage
      // At the end the winner was the Modal so the PaymentPage was removed
      if (options.skip_free_trial_cc) {
        window.location.href = `${window.location.href}?skip_cc=1&plan_guid=${options.planId}`;
      } else {
        this.launchPaymentModal(options);
      }
    };

    onTeamSizeSelect = (size) => {
      this.setState({
        teamSize: size,
      });
    };

    onEnterpriseSuccess = () => {
      pushGoogleTagManagerEvent({
        category: 'contactus-form-complete',
        action: '/enterprise-lp-contact-us',
        label: '/thankyou',
      });
      this.setState({
        successModalVisible: true,
      });
    };

    renderEnterpriseSuccessModal = () => {
      let modal;

      if (this.state.successModalVisible) {
        modal = (
          <SuccessTestDriveModal />
        );
      }

      return modal;
    };

    launchPaymentModal({
      planId, timePeriod, price, planName,
    }) {
      const attachPoint = HfReactHelper.getRevealAttachPoint('payment_modal', 'medium');
      const rates = this.calculateRates(price, timePeriod);
      ReactDOM.render(
        (<PaymentModal key={planId}
          planId={planId}
          planName={planName}
          isFreeTrialEligible={true}
          price={price}
          headerText='Start Free Trial'
          timePeriod={timePeriod}
          monthlyExpectedRate={rates.monthlyExpectedRate}
          annualExpectedRate={rates.annualExpectedRate}
          monthlyProratedRate={rates.monthlyProratedRate}
          annualProratedRate={rates.annualProratedRate}
          currentMonthlyRate={rates.currentMonthlyRate}
          currentAnnualRate={rates.currentAnnualRate}
          isTrialing={false}
          proratio={rates.proratio}
          couponCode={this.props.coupon ? this.props.coupon.coupon_code : void 0}
          ccSuffix={void 0}
          ccExpiration={void 0}
          cardHolderName={''}
          name={void 0}
          paymentForm={this.props.paymentForm}
          dbxPaymentMethod={this.props.dbxPaymentMethod}
          currentPlanName={void 0}
          tosUrl={this.props.tosUrl}
          refundPolicyUrl={this.props.refundPolicyUrl}
          privacyPolicyUrl={this.props.privacyPolicyUrl} />
        ), attachPoint,
      );
    }

    calculateRates(price, timePeriod) {

      let annualExpectedRate,
        monthlyExpectedRate;
      if (timePeriod === 'Y') {
        annualExpectedRate = price;
        monthlyExpectedRate = Math.floor(price / 12);
      } else {
        monthlyExpectedRate = price;
        annualExpectedRate = price * 12;
      }

      return {
        monthlyExpectedRate,
        annualExpectedRate,
        // These are the same as above, since this is a new plan by definition (DB)
        monthlyProratedRate: monthlyExpectedRate,
        annualProratedRate: annualExpectedRate,
        proratio: 0,
        currentMonthlyRate: 0,
        currentAnnualRate: 0,
      };
    }

    errorMessage = () => {
      let errors;
      if (this.props.errors) {
        let errorMsg = 'Sorry, we could not process your payment.';
        if (this.props.errors['payment[submit]']) {
          errorMsg += ` ${this.props.errors['payment[submit]']}`;
        }
        if (this.props.errors['payment[cc_number]']) {
          errorMsg += ` ${this.props.errors['payment[cc_number]']}`;
        }
        if (this.props.errors['payment[0]']) {
          errorMsg += ` ${this.props.errors['payment[0]']}`;
        }
        if (this.props.errors.dbx_payment) {
          errorMsg += ` ${this.props.errors.dbx_payment}`;
        }
        errors = <div data-test-id='errors' className='row text-center l-padding-v-20'>
          <p className='c-red'>{errorMsg}</p>
        </div>;
      }
      return errors;
    };

    renderCouponArea = () => {
      let couponArea;

      if (this.props.coupon) {
        couponArea = (
          <CouponDisplay couponCode={this.props.coupon.coupon_code} couponDescription={this.props.coupon.description} />
        );
      }
      return couponArea;
    };

    render() {
      const Blurb = (
        <div className='m-choose-signup-plan--blurb row text-center l-padding-t-40'>
          <div className='columns'>
            <h1 className='hide-for-small l-padding-b-70'>Pick a plan that's perfect for you</h1>
            <h2 className='v2-subtitle'>What's your team size?</h2>
          </div>
        </div>
      );

      const ChoiceArea = (
        <PlanChoiceAreaV2
          timePeriod={this.state.timePeriod}
          onTimeToggle={(value) => { this.setState({ timePeriod: value }); }}
          teamSize={this.state.teamSize}
          onTeamSizeSelect={this.onTeamSizeSelect}
          onPlanChoice={this.onPlanChoice}
          onEnterpriseSuccess={this.onEnterpriseSuccess}
          {...this.props}
        >
        </PlanChoiceAreaV2>
      );

      return (
        <div className='m-choose-signup-plan'>
          <Header />
          { Blurb }
          { this.errorMessage() }
          { this.renderCouponArea() }
          { ChoiceArea }
          { this.renderEnterpriseSuccessModal() }
          <Footer />
        </div>
      );
    }

}

module.exports = ChooseSignupPlan;
