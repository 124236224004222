import React from 'react';
import classnames from 'classnames';
import removeIcon from './remove-icon.svg';
import editIcon from './edit-icon.svg';

export default class Attachment extends React.Component {

    deleteAttachment = () => {
      this.props.deleteAttachment(this.props.attachment.id);
    };

    editAttachment = () => {
      this.props.editAttachment(this.props.attachment);
    };

    render() {
      const { attachment, readOnly } = this.props;

      const className = classnames({
        'attachment-button__attachment-name': true,
        'attachment-button__attachment-name--required': attachment.required,
      });


      return (
        <li className={classnames({
          'attachment-button__attached': true,
          'attachment-button__attached--read-only': readOnly,
        })}
        >
          <span className={className}>
            {attachment.name}
          </span>

          <img
            className="attachment-button__delete-icon"
            src={removeIcon}
            onClick={this.deleteAttachment}
            title="Delete attachment"
            alt="Delete attachment" />
          <img
            className="attachment-button__edit-icon"
            src={editIcon}
            onClick={this.editAttachment}
            title="Edit attachment"
            alt="Edit attachment" />
        </li>
      );
    }
}
