/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

const ApiMenuSectionSubItem = createReactClass({

  propTypes: {
    title: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isInitiallyActive: PropTypes.bool,
  },

  getInitialState() {
    return {
      isActive: this.props.isInitiallyActive,
    };
  },

  componentWillReceiveProps(newProps) {
    this.setState({
      isActive: newProps.isInitiallyActive,
    });
  },

  render() {

    const classes = classNames({
      'l-api-docs-menu--sub-items--sub-item': true,
      'is-active': this.state.isActive,
    });

    return (
      <div className={classes} data-ref={this.props.reference}>
        <a href={this.props.url}>{ this.props.title }</a>
      </div>
    );
  },
});

module.exports = ApiMenuSectionSubItem;
