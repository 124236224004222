/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  max-len,
  no-param-reassign,
  no-use-before-define,
  no-var,
  no-void,
  vars-on-top
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import createReactClass from 'create-react-class';
import ActionBar from './action-bar';
import CONSTANTS from './constants';
import {
  CommonTabProps as CommonTabPropTypes,
  GetSignatureUrl,
  SignatureType,
} from './prop-types';

function detectCarouselLength(orientation, screenSize) {
  if (screenSize === CONSTANTS.SCREEN_SMALL) {
    if (orientation === CONSTANTS.ORIENTATION_PORTRAIT) {
      // Only show one on small screen when in portrait mode
      return 1;
    } else {
      // But show two when in landscape mode
      return 3;
    }
  } else {
    // Default
    return CONSTANTS.CAROUSEL_LENGTH;
  }
}

const SavedTab = createReactClass({

  propTypes: {
    ...CommonTabPropTypes,
    getSignatureUrl: GetSignatureUrl,
    onInsert: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedSignature: SignatureType,
    signatures: PropTypes.arrayOf(SignatureType).isRequired,
  },


  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      canInsertEverywhere: true,
    };
  },

  getInitialState() {

    const carouselLength = detectCarouselLength(this.props.deviceOrientation, this.props.screenSize);

    return {
      isSignature: (this.props.type === CONSTANTS.TYPE_SIGNATURE),
      isInitials: (this.props.type === CONSTANTS.TYPE_INITIALS),
      carouselLength,
      carouselRange: [0, carouselLength - 1],
    };
  },

  statics: {
    // I think this either isn't needed, or should probably also update
    // carouselRange, but since I'm just trying to fix strict mode warnings I'm
    // leaving this as-is.
    getDerivedStateFromProps(newProps) {
      const signatures = newProps.signatures;
      const hasSignatures = (signatures && signatures.length > 0);
      let carouselLength = 0;

      if (hasSignatures) {
        carouselLength = detectCarouselLength(newProps.deviceOrientation, newProps.screenSize);
      }

      return {
        carouselLength,
      };
    },
  },

  //  ----  BEHAVIOR  -----------------------------------

  insertSignature(insertEverywhere) {
    const signature = this.props.selectedSignature;

    return this.props.onInsert(signature, insertEverywhere);
  },

  shiftCarousel(dir) {

    let range;
    const numItems = this.props.signatures.length;
    const selectedIndex = this.getSelectedCarouselIndex();

    switch (dir) {

      case 'next':
        range = [this.state.carouselRange[0] + 1, Math.min(this.state.carouselRange[1] + 1, numItems - 1)];
        break;

      case 'prev':
        range = [Math.max(0, this.state.carouselRange[0] - 1), this.state.carouselRange[1] - 1];
        break;

      default:
        throw new Error(`Invalid direction: ${dir}`);
    }

    // Ensure the current selected signature is still visible in the carousel.
    // Find the closest visible if not.
    // var selectedIndex = this.getSelectedCarouselIndex();
    let nextSignature;

    if (!this.isInCarouselRange(selectedIndex, range)) {
      if (selectedIndex < range[0]) {
        nextSignature = this.props.signatures[range[0]];
      }
      if (selectedIndex > range[1]) {
        nextSignature = this.props.signatures[range[1]];
      }
    }

    this.setState({
      carouselRange: range,
    }, () => {
      if (nextSignature) {
        this.props.onSelect(nextSignature);
      }
    });
  },


  //  ----  HELPERS  ------------------------------------

  getSelectedCarouselIndex() {
    if (typeof this.props.selectedSignature === 'undefined'
                || this.props.selectedSignature === null) {
      return 0;
    }
    const selectedSignature = this.props.selectedSignature;
    let selectedIndex = 0;
    for (let i = 0; i < this.props.signatures.length; i++) {
      if (this.props.signatures[i].guid === selectedSignature.guid) {
        selectedIndex = i;
        break;
      }
    }
    return selectedIndex;
  },


  scaleSignatureToFit(origDims, restrictDims) {
    origDims = Object.assign({}, origDims);
    origDims.ratio = parseFloat(origDims.width / origDims.height);
    if (origDims.width > origDims.height) {
      origDims.width = restrictDims.width;
      origDims.height = parseInt(origDims.width / origDims.ratio, 10);
      if (origDims.height > restrictDims.height) {
        origDims.height = restrictDims.height;
        origDims.width = parseInt(origDims.height * origDims.ratio, 10);
      }
    } else {
      origDims.height = restrictDims.height;
      origDims.width = parseInt(origDims.height * origDims.ratio, 10);
      if (origDims.width > restrictDims.width) {
        origDims.width = restrictDims.width;
        origDims.height = parseInt(origDims.width / origDims.ratio, 10);
      }
    }
    return origDims;
  },

  isInCarouselRange(i, range) {
    if (range === undefined) {
      range = this.state.carouselRange;
    }
    return (i >= range[0] && i <= range[1]);
  },


  //  ----  RENDERING  ----------------------------------

  renderSignatureImage(signature, isThumbnail) {
    if (!signature) return;

    const url = this.props.getSignatureUrl(signature);
    const containerDims = isThumbnail
      ? { width: 75, height: 40 }
      : { width: 380, height: 110 };

    const dims = this.scaleSignatureToFit({
      width: signature.editParams ? signature.editParams.width : signature.width,
      height: signature.editParams ? signature.editParams.height : signature.height,
    }, containerDims);

    const topMargin = Math.max(0, parseInt((containerDims.height - dims.height) / 2, 10));
    const style = {
      marginTop: `${topMargin}px`,
      width: `${dims.width}px`,
      height: `${dims.height}px`,
    };

    return (
      <img
        src={url}
        style={style} />
    );
  },

  renderSignatureCarousel() {

    const { onRemove } = this.props;
    const carouselItems = [];
    let classes;
    let signature;
    const carouselRange = this.state.carouselRange;

    const selectedSignature = this.props.selectedSignature ? this.props.selectedSignature.guid : void 0;

    for (let i = 0; i < this.props.signatures.length; i++) {

      signature = this.props.signatures[i];

      classes = {
        'm-sign-modal--tabs--saved--carousel--item': true,
        'whitelabel-signature-modal-save-carousel-tab-item': true,
        'is-selected': (selectedSignature === signature.guid),
        'is-hidden': !this.isInCarouselRange(i, carouselRange),
      };

      carouselItems.push(
        <li key={`carousel-item-${i}`} className={cx(classes)}>
          <div
            className='m-sign-modal--tabs--saved--carousel--item--content'
            onClick={this.props.onSelect.bind(null, signature)}
            data-qa-ref={`carousel-item-${i}`}
          >
            { this.renderSignatureImage(signature, true) }
          </div>
          <div
            className='m-sign-modal--tabs--saved--carousel--item--delete'
            onClick={onRemove.bind(null, signature)}
            data-qa-ref="signing-modal--delete"
          >
            <FormattedMessage id='signModal.saved.delete.linkText' />
          </div>
        </li>,
      );
    }

    classes = {
      'm-sign-modal--tabs--saved--carousel--list': true,
      'with-arrows': (this.props.signatures.length > CONSTANTS.CAROUSEL_LENGTH),
    };

    if (carouselRange[0] > 0) {
      leftArrow = (
        <li
          data-test-ref='signature-carousel-left-arrow'
          className='m-sign-modal--tabs--saved--carousel--arrow prev'
          onClick={this.shiftCarousel.bind(null, 'prev')}
        ></li>
      );
    }

    // Arrows
    const leftArrowEnabled = (carouselRange[0] > 0);
    const rightArrowEnabled = (carouselRange[1] < this.props.signatures.length - 1);
    var leftArrow = (
      <li
        data-test-ref='signature-carousel-left-arrow'
        className={cx({
          'm-sign-modal--tabs--saved--carousel--arrow': true,
          prev: true,
          'is-disabled': !leftArrowEnabled,
        })} onClick={leftArrowEnabled ? this.shiftCarousel.bind(null, 'prev') : null}
      ></li>
    );
    const rightArrow = (
      <li
        data-test-ref='signature-carousel-right-arrow'
        className={cx({
          'm-sign-modal--tabs--saved--carousel--arrow': true,
          next: true,
          'is-disabled': !rightArrowEnabled,
        })} onClick={rightArrowEnabled ? this.shiftCarousel.bind(null, 'next') : null}
      ></li>
    );

    return (
      <div
        className='m-sign-modal--tabs--saved--carousel--clip'
        data-test-ref='signature-carousel'
        data-qa-ref='signing-modal--saved-signatures'
      >
        <ul className={cx(classes)}>
          { leftArrow }
          { carouselItems }
          { rightArrow }
        </ul>
      </div>
    );
  },

  renderSignatureList() {

    const listItems = [];
    let classes;
    let isSelected;
    let signature;
    let deleteBtn;
    const { onRemove } = this.props;

    const selectedSignature = this.props.selectedSignature ? this.props.selectedSignature.guid : void 0;

    for (let i = 0; i < this.props.signatures.length; i++) {

      signature = this.props.signatures[i];
      isSelected = (signature.guid === selectedSignature);
      deleteBtn = isSelected ? (
        <div className='m-sign-modal--tabs--saved--list--item--delete' onClick={onRemove.bind(null, signature)}>
          <FormattedMessage id='signModal.saved.delete.linkText' />
        </div>
      ) : null;
      classes = {
        'm-sign-modal--tabs--saved--list--item': true,
        'is-selected': isSelected,
      };
      listItems.push(
        <div key={`sig-${i}`} className={cx(classes)} onClick={this.props.onSelect.bind(null, signature)}>
          <div className='m-sign-modal--tabs--saved--list--item--image'>
            { this.renderSignatureImage(signature) }
          </div>
          { deleteBtn }
        </div>,
      );
    }

    return (
      <div className='m-sign-modal--tabs--saved--list' data-test-ref='signature-list'>
        { listItems }
      </div>
    );
  },

  render() {
    const { actionBarProps } = this.props;
    let emptyMessage;
    let featuredSignature;
    let carousel;
    let carouselTip;
    let tabTitle;
    const hasSignatures = this.props.signatures.length;

    if (hasSignatures) {

      if (this.props.isMobile) {

        // Render the list of signatures
        carousel = this.renderSignatureList();

      } else {


        tabTitle = (
          <span className='m-sign-modal--tabs--tab-title'>
            <FormattedMessage
              id='signModal.saved.title'
              values={{ type: this.props.type }} />
          </span>
        );

        // Featured signature or initials
        featuredSignature = (
          <div className='m-sign-modal--tabs--saved--featured'>
            { this.renderSignatureImage(this.props.selectedSignature) }
          </div>
        );

        // Carousel tip
        carouselTip = (
          <div className='m-sign-modal--tabs--saved--carousel--tip'>
            <p>
              <FormattedMessage id='signModal.saved.tip.label' />: <br />
              <FormattedMessage id='signModal.saved.tip.text' values={{ type: this.props.type }} />
            </p>
          </div>
        );

        // Carousel
        carousel = (
          <div className='m-sign-modal--tabs--saved--carousel'>
            { carouselTip }
            { this.renderSignatureCarousel() }
          </div>
        );

      }

    } else {

      // No signatures or initials
      emptyMessage = (
        <div className='m-sign-modal--tabs--saved--empty' data-test-ref='empty-message'>
          <h2><FormattedMessage id='signModal.saved.empty.text' values={{ type: this.props.type }} /></h2>
          <p><FormattedMessage id='signModal.saved.empty.subText' values={{ type: this.props.type }} /></p>
        </div>
      );

    }

    const containerClasses = {
      'm-sign-modal--tabs--tab': true,
      'is-selected': this.props.isSelected,
    };

    containerClasses[CONSTANTS.TAB_SAVED] = true;

    const classes = {
      'm-sign-modal--tabs--saved': true,
      'has-signatures': this.props.signatures.length,
    };

    return (
      <div className={cx(containerClasses)}>
        {tabTitle}
        <div className={cx(classes)}>
          <div className='m-sign-modal--tabs--content' ref={this.props.captureTabContentRef}>
            { emptyMessage }
            { featuredSignature }
            { carousel }
          </div>
        </div>

        <ActionBar
          data-test-ref='action-bar'
          {...actionBarProps}
          onInsert={this.insertSignature}
          disableButtons={!hasSignatures} />

      </div>
    );
  },
});

module.exports = SavedTab;
