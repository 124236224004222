/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import { Box } from 'hello-ui/blocks';
import HsStyleProvider from '../style/hs-style-provider';


export default class Main extends React.Component {
  render() {
    return <HsStyleProvider>
      <Box is="main" flex={1} {...this.props}>{this.props.children}</Box>
    </HsStyleProvider>;
  }
}
