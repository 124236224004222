/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len
*/
import Button from 'common/components/button';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import HomePageManualSignup from './manual-signup';
import GoogleSignInButton from '../auth/google-signin-button';

const HomePageSignup = createReactClass({

  propTypes: {
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    googleSignInClientId: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    initialErrorMessage: PropTypes.string,
    initialValue: PropTypes.string,
    utmCampaign: PropTypes.string,
    utmMedium: PropTypes.string,
    utmSource: PropTypes.string,
    utmTerm: PropTypes.string,
    campaignCode: PropTypes.string,
    tabOffset: PropTypes.number,
    targetUrl: PropTypes.string,
  },

  getInitialState() {
    return {
      isOptedIn: true,
      homePageVersion: 'A',
    };
  },

  onOptInChange(event) {
    this.setState({
      isOptedIn: event.target.checked,
    });
  },

  componentDidMount() {
    document.addEventListener('toggleVersion', () => {
      this.setState({
        homePageVersion: 'B',
      });
    });
  },

  render() {

    let { initialValue } = this.props;

    // Cannot pass null to React input fields. Either pass in undefined for
    // uncontrolled components or an empty string for controlled components
    initialValue = this.props.initialValue || undefined;

    const homePageManualSignup = <HomePageManualSignup onOptInChange={this.onOptInChange}
      {...this.props}
      initialValue={initialValue} />;

    if (this.state.homePageVersion === 'A') {

      return (<div className='columns small-12 large-12 small-text-centered large-centered'>
        <div className='small-12 small-text-centered large-4 columns m-login-or-signup--left-button-container'>
          <div id='google-login-button-top' className='m-login-or-signup--left-button'>
            <GoogleSignInButton clientId={this.props.googleSignInClientId} redirectUrl={this.props.redirectUrl} />
          </div>
        </div>
        <div className='small-12 small-text-centered large-1 m-login-or-signup--or-graphic-container columns'>
        </div>
        <div className='m-login-or-signup--right-button-container small-12 small-centered large-uncentered small-text-centered large-7 last columns large-text-left'>
          { homePageManualSignup }
        </div>
      </div>);

    } else if (this.state.homePageVersion === 'B') {

      return (<div className='columns small-12 large-10 small-text-centered large-centered'>
        <Button buttonClass={'m-login-or-signup--takeATourButton'}
          buttonColor='white'
          buttonText='TAKE A TOUR'
          buttonLink={HfReactHelper.urlHelper('info/tour', 'hellosign')}
          buttonHoverColor='warm-chinchilla'
          buttonHoverTextColor='velvet-evening'
          buttonTextColor='velvet-evening' />
      </div>);

    }

  },
});

module.exports = HomePageSignup;
