import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';


const HwLogo = (props) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 400 40"
    className={classnames(styles.icon, props.className)}
  >
    <defs>
      <polygon id="path-1" points="13.0527747 0.358071318 13.0527747 19.5313426 0.158788933 19.5313426 0.158788933 0.358071318 13.0527747 0.358071318"></polygon>
      <polygon id="path-3" points="0 37.7674419 227.534988 37.7674419 227.534988 0.158263566 0 0.158263566"></polygon>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HelloWorks-Static-Navigation" transform="translate(-150.000000, -45.000000)">
        <g id="Group-4-Copy" transform="translate(150.000000, 45.000000)">
          <g id="Page-1">
            <polygon id="Fill-1" fill="#414042" points="57.0931826 28.0609395 57.0931826 20.6153581 49.9341628 20.6153581 49.9341628 28.0609395 45.6192854 28.0609395 45.6192854 9.49554419 49.9341628 9.49554419 49.9341628 16.7648775 57.0931826 16.7648775 57.0931826 9.49554419 61.4080601 9.49554419 61.4080601 28.0609395"></polygon>
            <polygon id="Fill-2" fill="#414042" points="65.7222166 28.0609395 65.7222166 9.49554419 77.4998134 9.49554419 77.4998134 13.4476372 70.0370941 13.4476372 70.0370941 16.7648775 76.8401455 16.7648775 76.8401455 20.5128465 70.0370941 20.5128465 70.0370941 24.134924 77.4998134 24.134924 77.4998134 28.0609395"></polygon>
            <polygon id="Fill-3" fill="#414042" points="81.5602862 28.0609395 81.5602862 9.49554419 85.8751636 9.49554419 85.8751636 24.134924 93.337883 24.134924 93.337883 28.0609395"></polygon>
            <polygon id="Fill-4" fill="#414042" points="96.0801012 28.0609395 96.0801012 9.49554419 100.394979 9.49554419 100.394979 24.134924 107.858599 24.134924 107.858599 28.0609395"></polygon>
            <g id="Group-25" transform="translate(0.000000, 0.741321)">
              <path d="M118.849551,12.300676 C115.904476,12.300676 113.925472,14.7061023 113.925472,18.0503194 C113.925472,21.3675597 115.904476,23.7990636 118.849551,23.7990636 C121.793725,23.7990636 123.77363,21.3675597 123.77363,18.0503194 C123.77363,14.7061023 121.793725,12.300676 118.849551,12.300676 Z M118.77295,27.6243659 C113.010768,27.6243659 109.330326,23.4951256 109.330326,18.0503194 C109.330326,12.5794357 113.188302,8.45109457 118.950484,8.45109457 C124.687432,8.45109457 128.394009,12.5794357 128.394009,18.0503194 C128.394009,23.4951256 124.509899,27.6243659 118.77295,27.6243659 Z" id="Fill-5" fill="#414042"></path>
              <polygon id="Fill-7" fill="#414042" points="149.053333 27.6243659 148.470266 27.6747225 142.75855 16.5558078 137.124337 27.6747225 136.565602 27.6243659 129.787783 8.75413333 134.737096 8.75413333 137.732637 18.5062264 142.581017 8.60216434 143.037017 8.60216434 147.910629 18.4297922 150.880938 8.75413333 155.729317 8.75413333"></polygon>
              <path d="M166.389714,12.300676 C163.443738,12.300676 161.463832,14.7061023 161.463832,18.0503194 C161.463832,21.3675597 163.443738,23.7990636 166.389714,23.7990636 C169.333888,23.7990636 171.313793,21.3675597 171.313793,18.0503194 C171.313793,14.7061023 169.333888,12.300676 166.389714,12.300676 M166.313113,27.6243659 C160.550931,27.6243659 156.870489,23.4951256 156.870489,18.0503194 C156.870489,12.5794357 160.728465,8.45109457 166.490647,8.45109457 C172.228496,8.45109457 175.934172,12.5794357 175.934172,18.0503194 C175.934172,23.4951256 172.04916,27.6243659 166.313113,27.6243659" id="Fill-9" fill="#414042"></path>
              <path d="M185.62967,12.4775535 C184.970002,12.4775535 184.563567,12.503631 183.954365,12.6043442 L183.954365,17.7200341 C184.5131,17.7712899 184.817701,17.7964682 185.325069,17.7964682 C187.380674,17.7964682 188.700911,16.8091194 188.700911,14.9342357 C188.700911,13.3902667 187.483409,12.4775535 185.62967,12.4775535 L185.62967,12.4775535 Z M189.463314,27.3192589 L186.288436,21.2665767 C186.035203,21.291755 185.807203,21.291755 185.553069,21.291755 C184.919535,21.291755 184.563567,21.2665767 183.954365,21.2413984 L183.954365,27.3192589 L179.639488,27.3192589 L179.639488,8.93191008 C181.212958,8.75386357 182.93963,8.62797209 185.122302,8.62797209 C190.908816,8.62797209 193.194223,11.3885922 193.194223,14.7822667 C193.194223,16.9862667 192.000152,18.9618636 189.995915,20.1524372 L194.361258,27.3192589 L189.463314,27.3192589 Z" id="Fill-11" fill="#414042"></path>
              <polygon id="Fill-13" fill="#414042" points="208.041349 27.3196186 201.213064 18.6322078 201.213064 27.3196186 196.898187 27.3196186 196.898187 8.75422326 201.213064 8.75422326 201.213064 16.8346574 207.736748 8.75422326 212.839262 8.75422326 205.199009 17.5936031 213.245696 27.3196186"></polygon>
              <g id="Group-17" transform="translate(214.482213, 8.093023)">
                <mask id="mask-2" fill="white">
                  <use href="#path-1"></use>
                </mask>
                <g id="Clip-16"></g>
                <path d="M6.42743715,19.5313426 C4.32046482,19.5313426 1.78272569,18.6950636 0.158788933,17.6312806 L1.35105771,13.7313426 C2.84882846,14.8203039 4.80259921,15.528893 6.55450435,15.528893 C8.0775083,15.528893 8.66147668,14.8203039 8.66147668,13.9075907 C8.66147668,12.9454202 8.17844111,12.2871876 5.33610119,11.1469705 C2.06209328,9.8296062 0.919389723,7.93134264 0.919389723,5.70216434 C0.919389723,2.99190078 3.12819605,0.358071318 7.46830672,0.358071318 C9.49957945,0.358071318 11.5813186,1.06666047 12.6726545,1.80132713 L11.5813186,5.65090853 C10.5143146,4.91624186 8.78854387,4.13211783 7.46830672,4.13211783 C5.89483636,4.13211783 5.2595004,4.86588527 5.2595004,5.62662946 C5.2595004,6.84148217 6.19853597,7.24703256 8.53440949,8.2343814 C12.0111842,9.70371473 13.0529549,11.3753736 13.0529549,13.7556217 C13.0529549,16.7194667 10.8955162,19.5313426 6.42743715,19.5313426" id="Fill-15" fill="#414042" mask="url(#mask-2)"></path>
              </g>
              <mask id="mask-4" fill="white">
                <use href="#path-3"></use>
              </mask>
              <g id="Clip-19"></g>
              <polygon id="Fill-18" fill="#FEFEFE" mask="url(#mask-4)" points="5.89916206 29.1061085 29.2975494 29.1061085 29.2975494 5.75863566 5.89916206 5.75863566"></polygon>
              <path d="M4.06434783,30.9369302 L31.1323636,30.9369302 L31.1323636,3.92781395 L4.06434783,3.92781395 L4.06434783,30.9369302 Z M7.73397628,27.2752868 L27.4636364,27.2752868 L27.4636364,7.58855814 L7.73397628,7.58855814 L7.73397628,27.2752868 Z" id="Fill-20" fill="#37B249" mask="url(#mask-4)"></path>
              <path d="M8.58631779,15.4624403 C4.86351937,15.4624403 1.83463399,12.4401457 1.83463399,8.72544806 C1.83463399,5.01075039 4.86351937,1.98845581 8.58631779,1.98845581 C12.3091162,1.98845581 15.3380016,5.01075039 15.3380016,8.72544806 C15.3380016,12.4401457 12.3091162,15.4624403 8.58631779,15.4624403" id="Fill-21" fill="#FEFEFE" mask="url(#mask-4)"></path>
              <path d="M8.58631779,0.158083721 C3.84427826,0.158083721 -0.000180237153,3.99417674 -0.000180237153,8.72499845 C-0.000180237153,13.4576186 3.84427826,17.2928124 8.58631779,17.2928124 C13.3283573,17.2928124 17.1719146,13.4576186 17.1719146,8.72499845 C17.1719146,3.99417674 13.3283573,0.158083721 8.58631779,0.158083721 M8.58631779,3.81882791 C11.2970846,3.81882791 13.5031874,6.02013023 13.5031874,8.72499845 C13.5031874,11.4307659 11.2970846,13.631169 8.58631779,13.631169 C5.87555099,13.631169 3.66944822,11.4307659 3.66944822,8.72499845 C3.66944822,6.02013023 5.87555099,3.81882791 8.58631779,3.81882791" id="Fill-22" fill="#37B249" mask="url(#mask-4)"></path>
              <polygon id="Fill-23" fill="#FEFEFE" mask="url(#mask-4)" points="22.294615 22.4757643 35.7538245 22.4757643 29.0237692 34.106338"></polygon>
              <path d="M38.9313154,20.6448527 L19.1169439,20.6448527 L29.0236791,37.7678915 L38.9313154,20.6448527 Z M32.5761534,24.3064961 L29.0236791,30.4446047 L25.4721059,24.3064961 L32.5761534,24.3064961 Z" id="Fill-24" fill="#37B249" mask="url(#mask-4)"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(HwLogo);
