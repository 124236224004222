/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';

/**
 * "Contact Us" Modal
 *
 * May want to break this out into its own component, but for now
 * it's only being used in the CorporatePricingLevel component so
 * we'll keep it here for now.
 *
 * Also uses 'contact-us-modal.scss'
 */
const ContactUsSuccess = createReactClass({
  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      $(document).foundation();
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },
  componentWillReceiveProps() {
    if (process.env.NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },
  render() {
    return (
      <div className="m-contact-us text-center l-padding-h-80">
        <img className="l-padding-v-20 m-contact-us--checkmark" src={require('./checkmark_green@2x.png')} />
        <h4>
          Thanks for reaching out.
          <br />
          We&apos;ll get back to you shortly.
        </h4>
      </div>
    );
  },
});

module.exports = ContactUsSuccess;
