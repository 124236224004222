/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  camelcase,
  eqeqeq,
  global-require,
  import/no-extraneous-dependencies,
  max-len,
  no-alert,
  no-plusplus,
  no-restricted-globals,
  no-void,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import HfReactHelper from 'common/utils/hf-react-helper';
// Importing intl from below path is not the preferred method, but injectIntl breaks test method. so for DEV-7412 we can use that
import { intl } from 'hellospa/components/hs-intl-provider';
import cx from 'classnames';
import setTimeout from 'common/utils/set-timeout';
import Tooltip from 'common/components/tooltip';
import createReactClass from 'create-react-class';
import RemoveEnterpriseMember from './remove-enterprise-member';

const messages = defineMessages({
  lastAdminAlert: {
    id: '',
    defaultMessage: 'You are the last team admin. Since there must always be at least one admin present, please promote another member to the admin role before attempting to leave the team.',
    description: 'Team Page, alert popup when remove member',
  },
  downgradingToTeamManager: {
    id: '',
    defaultMessage: 'Are you sure you want to revoke your admin privileges? You will no longer be able to view all team documents.',
    description: 'Team Page, alert popup when downgrading to Team Manager',
  },
  allOtherAdminRole: {
    id: '',
    defaultMessage: 'Are you sure you want to revoke your Admin privileges? You will no longer be able to modify your team.',
    description: 'Team Page, alert popup set member role',
  },
  resetPassword: {
    id: '',
    defaultMessage: 'Are you sure you want to reset the password for this account?',
    description: 'Team Page, alert popup reset password',
  },
  lockMember: {
    id: '',
    defaultMessage: 'Are you sure you want to lock this account?',
    description: 'Team Page, alert popup when lock member',
  },
  removeMember: {
    id: '',
    defaultMessage: 'Are you sure you want to remove this user from your team? Note: this user\'s account will still exist. However, they will no longer be',
    description: 'Team Page, alert popup when remove member',
  },
  memberPending: {
    id: '',
    defaultMessage: ' invited to join your team.',
    description: 'Team Page, alert popup when remove member',
  },
  memberNotPending: {
    id: '',
    defaultMessage: ' part of your team.',
    description: 'Team Page, alert popup when remove member',
  },
  hsMemberNotPending: {
    id: '',
    defaultMessage: ' IMPORTANT: Any templates this user created will be transferred to you and their signatures will be removed from them.',
    description: 'Team Page, alert popup when remove member',
  },
  userLocked: {
    id: '',
    defaultMessage: 'This user is currently locked',
    description: 'Team Page, show text when hover over lock account',
  },
});

const TeamMember = createReactClass({


  /**
     */

  propTypes: {
    member: PropTypes.object.isRequired,
  },

  /**
     */

  getInitialState() {
    return {};
  },

  /**
     */

  setMemberRole(code, event) {

    if (this.props.member.isLoggedInUser() && this.props.member.canModifyTeam()) {
      switch (code) {
        // For downgrading to Team Manager (code=r)
        case 'r':
          if (!confirm(intl.formatMessage(messages.downgradingToTeamManager))) {
            return;
          }
          break;
          // For all other admin roles
        default:
          if (!confirm(intl.formatMessage(messages.allOtherAdminRole))) {
            return;
          }
      }
    }

    this.props.member.promote(code);
    event.preventDefault();
    event.stopPropagation();
  },

  /**
     */

  resetPassword(event) {

    event.preventDefault();
    if (!confirm(intl.formatMessage(messages.resetPassword))) {
      return;
    }

    this.props.member.resetPassword();
  },

  /**
     */

  unlockMember(event) {

    event.preventDefault();
    this.props.member.unlock();
  },

  /**
     */

  lockMember(event) {

    event.preventDefault();
    if (!confirm(intl.formatMessage(messages.lockMember))) {
      return;
    }

    this.props.member.lock();
  },

  /**
     */

  removeMember(event) {

    event.preventDefault();

    if (this.props.member.isLoggedInUser() && this.getNumUnlockedAdmins() < 2) {
      return alert(intl.formatMessage(messages.lastAdminAlert));
    }

    let removeText = intl.formatMessage(messages.removeMember);

    removeText += this.props.member.pending ? intl.formatMessage(messages.memberPending) : intl.formatMessage(messages.memberNotPending);
    if (HfReactHelper.isHelloSign() && !this.props.member.pending) {
      removeText += intl.formatMessage(messages.hsMemberNotPending);
    }

    if (!confirm(removeText)) {
      return;
    }

    this.props.member.remove();
  },

  /**
     */

  removeEnterpriseMember(event) {

    event.preventDefault();

    this.props.app.actions.ui.showModal(
      <RemoveEnterpriseMember {...this.props} />,
      { showCloseButton: false },
      'remove-enterprise-member',
    );

  },

  /**
     */

  toggleMenu(show) {
    clearTimeout(this._hideTimeout);

    // TODO: clean me - hack to prevent menu from hiding too quickly - this
    // needs to be placed within a menu component (CC)
    const self = this;
    if (show) {
      this.setState({ showMenu: show });
    } else {
      this._hideTimeout = setTimeout(() => {
        self.setState({ showMenu: show });
      }, 100);
    }
  },

  /**
     */

  showTemplates() {

    // TODO: reactify this component - here to support legacy stuff
    // for now
    const a = document.createElement('a');
    const guid = this.props.member.guid || this.props.member.uid;
    a.setAttribute('href', `/home/viewTemplatePermissionsForAccount?guid=${guid}`);

    $(a).fancybox({
      showCloseButton: false,
      type: 'iframe',
      height: 375,
      width: 500,
    }).click();

  },

  /**
     */

  toggleCloseButton(show) {
    this.setState({
      showClose: show,
    });
  },

  /**
    * Returns true for Admins (owners) and Developers
    */
  shouldShowActionMenu() {
    return (this.props.teamMembers && this.props.teamMembers.loggedInUserCanModifyTeam());
  },

  /**
    *
    */
  userIsAdmin() {
    return (this.props.teamMembers && this.props.teamMembers.loggedInUserIsAdmin());
  },

  /**
    */
  userIsManager() {
    return (this.props.teamMembers && this.props.teamMembers.loggedInUserIsManager());
  },

  /**
     */

  isEnterprise() {
    return this.props.app.user.getTeam().isEnterprise();
  },

  /**
     */

  isHighPayingEnterprise() {
    return this.props.app.user.getTeam().isHighMrrEnterprise;
  },

  getNumUnlockedAdmins() {
    let count = 0;
    const members = this.props.allTeamMembers;
    for (let i = 0; i < members.length; i++) {
      if (members[i].isAdmin && !members[i].isLocked) {
        count++;
      }
    }
    return count;
  },

  /**
     */

  render() {
    const member = this.props.member;
    const loggedInUser = this.props.teamMembers.getLoggedInUser(); // This is the team member that is also the current user (may be null)
    const isSoleUnlockedAdmin = (typeof loggedInUser !== 'undefined')
            && (member.emailAddress === loggedInUser.emailAddress)
            && (this.getNumUnlockedAdmins() < 2 && member.roleCode == 'a');

    const classNames = cx({
      team_member: true,
      show: this.state.showMenu,
    });

    const sections = {};

    // Templates
    // Don't allow Team Managers to see Template option other than theirs
    const isSelf = (typeof loggedInUser !== 'undefined') && (loggedInUser.emailAddress === member.emailAddress);
    if (HfReactHelper.isHelloSign() && !member.isLocked && (!this.userIsManager() || isSelf)) {
      sections.templateMenuItem = (
        <li key='menu' className='menuLink template_access'>
          <a href='#' className='show_template_access_link' onClick={this.showTemplates}>
            <b className='ico'></b><FormattedMessage id='' defaultMessage='Templates' description='Team Page, text in arrow dropdown right side of templates owned' />
          </a>
        </li>
      );
    }

    // User administration functions only available to Admins and some to Team Managers, not Devs
    if (this.userIsAdmin() || this.userIsManager()) {

      // Make Admin
      if (!member.isAdmin() && !this.userIsManager() && !member.isLocked) {
        sections.adminMenuItem = (
          <li key='make-admin' className='menuLink make-admin'>
            <a href='#' onClick={this.setMemberRole.bind(this, 'a')}>
              <b className='ico'></b><FormattedMessage id='' defaultMessage='Make Admin' description='Team Page, text in arrow dropdown right side of templates owned, assign role' />
            </a>
          </li>
        );
      }

      // Make Developer
      if (!member.isDeveloper() && !member.isLocked && !isSoleUnlockedAdmin) {
        sections.devMenuItem = (
          <li key='make-developer' className='menuLink make-developer'>
            <a href='#' onClick={this.setMemberRole.bind(this, 'd')}>
              <b className='ico'></b><FormattedMessage id='' defaultMessage='Make Developer' description='Team Page, text in arrow dropdown right side of templates owned, assign role' />
            </a>
          </li>
        );
      }

      // Make Team Manager
      if (this.isEnterprise() && !member.isManager() && !member.isLocked && !isSoleUnlockedAdmin) {
        sections.teamManagerMenuItem = (
          <li key='make-team-manager' className='menuLink make-team-manager'>
            <a href='#' onClick={this.setMemberRole.bind(this, 'r')}>
              <b className='ico'></b><FormattedMessage id='' defaultMessage='Make Team Manager' description='Team Page, text in arrow dropdown right side of templates owned, assign role' />
            </a>
          </li>
        );
      }

      // Make Member
      if (!member.isMember() && !member.isLocked && !isSoleUnlockedAdmin) {
        sections.memberMenuItem = (
          <li key='make-member' className='menuLink make-member'>
            <a href='#' onClick={this.setMemberRole.bind(this, 'm')}>
              <b className='ico'></b><FormattedMessage id='' defaultMessage='Make Member' description='Team Page, text in arrow dropdown right side of templates owned, assign role' />
            </a>
          </li>
        );
      }

      // high paying enterprise options
      if (this.isHighPayingEnterprise() && !member.isLocked && !this.props.member.isLoggedInUser()) {
        // Lock Account
        sections.lockMenuItem = (
          <li className='menuLink'>
            <a href='#' className='lock_team_member' onClick={this.lockMember}>
              <b className='ico'></b><FormattedMessage id='' defaultMessage='Lock Account' description='Team Page, text in arrow dropdown right side of templates owned' />
            </a>
          </li>
        );

        // Reset Password
        sections.resetPasswordMenuItem = (
          <li className='menuLink'>
            <a href='#' className='reset_password_team_member' onClick={this.resetPassword}>
              <b className='ico'></b><FormattedMessage id='' defaultMessage='Reset Password' description='Team Page, text in arrow dropdown right side of templates owned' />
            </a>
          </li>
        );
      }
    }

    // Unlock Account
    if (member.isLocked) {
      sections.unlockMenuItem = (
        <li className='menuLink'>
          <a href='#' className='unlock_team_member' onClick={this.unlockMember}>
            <b className='ico'></b><FormattedMessage id='' defaultMessage='Unlock Account' description='Team Page, text in arrow dropdown right side of templates owned' />
          </a>
        </li>
      );
    }

    // Remove
    if ((this.userIsAdmin() || this.userIsManager()) && !(this.isEnterprise() && isSelf)) {
      const removeHandler = (this.isEnterprise() ? this.removeEnterpriseMember : this.removeMember);
      sections.removeMenuItem = (
        <li className='menuLink'>
          <a href='#' className='remove_team_member' onClick={removeHandler}>
            <b className='ico'></b><FormattedMessage id='' defaultMessage='Remove' description='Team Page, text in arrow dropdown right side of templates owned, remove from team' />
          </a>
        </li>
      );
    }

    if (member.pending) {

      const closClassNames = cx({
        action_col: true,
        show: this.state.showClose,
      });

      // TODO - move to scss file when scss is organized (CC)
      sections.actionsColumn = (
        <td className={closClassNames} onMouseOver={this.toggleCloseButton.bind(this, true)} onMouseOut={this.toggleCloseButton.bind(this, false)}>
          <img src={require('./team_x_icon@2x.png')} onClick={this.removeMember} title='Remove' alt='Remove' className='delete_team_invite_link' />
        </td>
      );

    } else {
      sections.actionsColumn = <td className='action_col' style={{ opacity: 1 }}>
        { this.shouldShowActionMenu()
          ? <div className='arrow' onMouseOver={this.toggleMenu.bind(this, true)} onMouseOut={this.toggleMenu.bind(this, false)}>
            <ul>
              { sections.templateMenuItem }
              { sections.adminMenuItem }
              { sections.devMenuItem }
              { sections.teamManagerMenuItem }
              { sections.memberMenuItem }
              { sections.resetPasswordMenuItem }
              { sections.lockMenuItem }
              { sections.unlockMenuItem }
              { sections.removeMenuItem }
            </ul>
          </div>
          : void 0 }
      </td>;
    }

    // TODO - don't do this - need to use CSS ellipsis instead
    let emailAddress = member.emailAddress.toLowerCase();
    if (emailAddress.length > 30) {
      emailAddress = `${emailAddress.substring(0, 27)}...`;
    }

    let role_label;
    if (member.pending) {
      role_label = (member.pending_email_confirmation)
        ? <FormattedMessage id='' defaultMessage='(Pending)' description='Team Page, under Role column, status pending' />
        : <FormattedMessage id='' defaultMessage='(Invited)' description='Team Page, under Role column, status invited' />;
    } else {
      role_label = member.getRoleTypeLabel();
    }

    const lockId = `lock_${this.props.member.guid}`;
    const lockedIcon = (<img id={lockId} className='team-page-lock' src={require('./lock.png')} />);
    const tooltip = (<Tooltip
      content={intl.formatMessage(messages.userLocked)}
      lockToElement={`#${lockId}`}
      arrowPosition='left' />);
    const emailStyle = member.isLocked ? { position: 'relative', top: '-2px' } : { };
    return <tr className={classNames} style={{ display: 'table-row' }}>
      <td className='email_col'>
        <span style={emailStyle}>{ member.emailAddress }</span>
        { member.isLocked ? lockedIcon : void 0 }
        { member.isLocked ? tooltip : void 0 }
      </td>
      <td className='role_col'>{ role_label }</td>
      { HfReactHelper.isHelloSign() ? <td className='templates_col'>{ member.numTemplates }</td> : void 0 }
      { sections.actionsColumn }
    </tr>;
  },
});

module.exports = TeamMember;
