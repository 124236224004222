/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  default-case,
  eqeqeq,
  max-len,
  no-plusplus,
  no-script-url,
  react/no-string-refs
*/
import cx from 'classnames';
import React from 'react';
import setTimeout from 'common/utils/set-timeout';
import createReactClass from 'create-react-class';
import { FormattedMessage } from 'react-intl';
import ActionBar from './action-bar';
import CONSTANTS from './constants';

import {
  CommonTabProps as CommonTabPropTypes,
  DeviceOrientation,
  OnSignatureData,
} from './prop-types';

const DrawTab = createReactClass({

  triesRemaining: CONSTANTS.DRAW_TAB_REDRAW_MAX_RETRIES,
  retryTime: CONSTANTS.DRAW_TAB_REDRAW_INITIAL_INTERVAL,

  propTypes: {
    ...CommonTabPropTypes,
    displayOrientation: DeviceOrientation,
    onSignatureData: OnSignatureData,
  },


  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      canInsertEverywhere: true,
    };
  },

  getInitialState() {
    return {
      isSignature: (this.props.type === CONSTANTS.TYPE_SIGNATURE),
      isInitials: (this.props.type === CONSTANTS.TYPE_INITIALS),
      hasDrawn: false,
    };
  },

  componentDidMount() {
    this.isMounted = true;
    this.initCanvas();
  },

  componentWillUnmount() {
    this.isMounted = false;
    clearTimeout(this.redrawTimer);
  },

  componentDidUpdate(prevProps) {
    const orientationHasChanged = (prevProps.deviceOrientation != this.props.deviceOrientation || prevProps.displayOrientation != this.props.displayOrientation);
    const screenSizeHasChanged = (prevProps.screenSize != this.props.screenSize);
    if (orientationHasChanged || screenSizeHasChanged) {
      // Only reset the canvas if the orientation or size of the screen has changed
      if (this.props.isMobile) {
        this.redrawCanvas();
      } else {
        this.initCanvas();
      }
    }
  },


  //  ----  BEHAVIOR  -----------------------------------

  initCanvas() {
    const canvasEl = this._getDrawSignatureCanvasElement();
    const canvas = $(canvasEl);

    if (!this.isCanvasNativelySupported() && window.G_vmlCanvasManager) {
      G_vmlCanvasManager.initElement(canvasEl);
    }

    // Preventive reset
    if (canvas.data('canvas')) {
      canvas.canvas('clearCanvas');
      canvas.canvas('destroy');
    }

    // Init
    if (canvas.canvas) { // NOTE: Necessary for running unit tests
      canvas.canvas({
        callback: this.canvasCallback,
      });
    }

    canvas.attr('height', parseInt(canvas.height(), 10));
    canvas.attr('width', parseInt(canvas.width(), 10) + 10);
    // NOTE: +10 to account for the padding on canvas-holder
  },

  /**
    * redrawCanvas
    *
    * These handle making sure any user entered paths remain during phone rotation
    * We do this by:
    * 1) Saving off the Base64 data from the canvas
    * 2) Reinitializing the canvas to the new, rotated dimensions
    * 3) Set our previous data on the canvas again (with retries to account for animation)
    */

  redrawCanvas() {
    const hasDrawn = !!this.state.hasDrawn;

    // Grab existing signature paths as Base64
    const data = this.getCanvasData();

    this.initCanvas();

    if (this.redrawTimer) {
      clearTimeout(this.redrawTimer);
    }

    this.retryTime = CONSTANTS.DRAW_TAB_REDRAW_INITIAL_INTERVAL;
    this.triesRemaining = CONSTANTS.DRAW_TAB_REDRAW_MAX_RETRIES;

    // Reapply changes
    this._redraw(data);

    // Make sure we show the clear button if we had real data before
    if (hasDrawn) {
      this.setState({
        hasDrawn: true,
      });
    }
  },

  _redraw(imageData) {
    const sourceImage = new Image();
    sourceImage.src = imageData;

    const ctx = this._getDrawSignatureCanvasElement().getContext('2d');
    ctx.drawImage(sourceImage, 0, 0);

    const currentData = this.getCanvasData();

    // Set a timeout to retry if the data doesn't match what we've intended to insert
    // This handles cases where the phone rotation animation hasn't finished
    if (currentData !== imageData && this.triesRemaining > 0) {
      this.retryTime += this.retryTime;
      this.redrawTimer = setTimeout(() => this._redraw(imageData), this.retryTime);
      this.triesRemaining--;
    } else {
      this.retryTime = CONSTANTS.DRAW_TAB_REDRAW_INITIAL_INTERVAL;
      this.triesRemaining = CONSTANTS.DRAW_TAB_REDRAW_MAX_RETRIES;
    }
  },

  _getDrawSignatureCanvasElement() {
    return this.drawSignatureCanvas.current;
  },

  canvasCallback(eventName) {
    // NOTE: We can't use setState() to keep track of hasDrawn
    // because change a state value triggers render(), which resets the canvas.

    switch (eventName) {

      case 'start':
        this.setState({
          hasDrawn: true,
        });
        break;

      case 'drag':
        break;

      case 'stop':
        break;

      case 'clear':
        this.setState({
          hasDrawn: false,
        });
        break;

    }
  },

  clearCanvas() {
    const canvas = $(this._getDrawSignatureCanvasElement());
    if (canvas.data('canvas')) {
      canvas.canvas('clearCanvas');
    }
    if (this.redrawTimer) {
      clearTimeout(this.redrawTimer);
    }
    if (this.props.onClear) {
      this.props.onClear();
    }
  },

  insertSignature(insertEverywhere) {

    const signatureData = {
      type_code: this.props.type,
      create_type_code: CONSTANTS.SIGNATURE_TYPE_CANVAS,
      signature: {
        image: this.getCanvasData(),
        is_vml: this.isCanvasNativelySupported() ? 0 : 1,
      },

    };

    return this.props.onSignatureData(signatureData, insertEverywhere);
  },

  //  ----  HELPERS  ------------------------------------

  isCanvasNativelySupported() {
    return !!document.createElement('canvas').getContext;
  },

  getCanvasData() {
    const canvasElement = this._getDrawSignatureCanvasElement();
    if (canvasElement) {
      return (this.isCanvasNativelySupported() ? canvasElement.toDataURL() : canvasElement.innerHTML);
    }
  },


  //  ----  RENDERING  ----------------------------------

  render() {
    const { actionBarProps } = this.props;
    const containerClasses = {
      'm-sign-modal--tabs--tab': true,
      'is-selected': this.props.isSelected,
    };

    const clearBtnClasses = {
      'm-sign-modal--tabs--draw--clear-link': true,
      'm-button': true,
      'bg-white': true,
      'border-1-light-grey': true,
      'hbg-white': true,
      'hc-light-grey': true,
      'c-light-grey': true,
    };

    const tip = this.props.isMobile ? (
      <div className='m-sign-modal--tabs--draw--tip'>
        <span className='m-sign-modal--tabs--draw--tip--icon'></span>
        <span className='m-sign-modal--tabs--draw--tip--text'>{ this.props.intl.formatMessage({ id: 'signModal.drawn.tip.labelText' }) }</span>
      </div>
    ) : null;

    containerClasses[CONSTANTS.TAB_DRAW] = true;

    const clearBtn = this.state.hasDrawn ? (
      <div
        data-test-ref='clear-btn'
        onClick={this.clearCanvas}
        className={cx(clearBtnClasses)}
        data-qa-ref='signing-modal--clear'
      >
        <a href='javascript:;'>
          <FormattedMessage id='signModal.drawn.clear.linkText' />
        </a>
      </div>
    ) : null;

    return (
      <div className={cx(containerClasses)}>

        <span className='m-sign-modal--tabs--tab-title'>
          <FormattedMessage id='signModal.drawn.title' values={{ type: this.props.type }} />
        </span>

        <div className='m-sign-modal--tabs--draw'>

          <div className='m-sign-modal--tabs--content' ref={this.props.captureTabContentRef}>
            <div className='m-sign-modal--tabs--draw--canvas-holder'>
              { this.renderCanvas() }
              <div className='m-sign-modal--tabs--draw--x'>&times;</div>
              { clearBtn }
            </div>

            { tip }

          </div>

        </div>

        <ActionBar
          data-test-ref='action-bar'
          {...actionBarProps}
          disableButtons={!this.state.hasDrawn}
          onInsert={this.insertSignature} />

      </div>
    );
  },

  renderCanvas() {
    // Strict mode was warning about the string reference, so I'm replacing it
    // with a ref. but createReactClass doesn't appear to support constructors,
    // so I guess this seems like the next best place to create this.
    if (!this.drawSignatureCanvas) {
      this.drawSignatureCanvas = React.createRef();
    }
    return (
      <canvas ref={this.drawSignatureCanvas}
        id='drawSignatureCanvas'
        className='m-sign-modal--tabs--draw--canvas'
        data-qa-ref="signing-modal--draw-canvas" />
    );
  },
});

module.exports = DrawTab;
