/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  camelcase,
  func-names,
  no-console,
  no-multi-assign,
  no-param-reassign,
  prefer-rest-params,
  prefer-spread
*/
const logger = global.logger = require('./index');
const pkg = require('../../../package.json');

/**
 * Helper function to return the URL of the parent window.
 *
 * This is required for cross-window messaging (XWM).
 *
 * @return string|null
 */
function getParentUrl() {
  // If the embedded page has loaded completely, the parent URL will be
  // stored in the window context.
  if (typeof window.parentUrl === 'string') {
    return window.parentUrl;
  }
  // Look for the parent URL in this page's GET parameters
  const parts = document.location.search.replace(/^\?/, '').split('&');
  let p;
  for (let i = 0; i < parts.length; i++) {
    p = parts[i].split('=');
    if (p && p.length === 2 && p[0] === 'parent_url') {
      return decodeURIComponent(p[1]);
    }
  }
  return null;
}

/**
 * Handler for 'error' events.
 *
 * Called on logger.error('message');
 */
logger.on('error', (err) => {
  const message = err.args.join(' ');

  // Report the error to the parent window, if it exists
  if (typeof HelloSign !== 'undefined' && typeof HelloSign.reportError === 'function') {
    const parent_url = getParentUrl();
    if (parent_url) {
      HelloSign.reportError(message, parent_url);
    }
  }

  const [errorObject] = err.args;
  // This error is already handled by showing the user the login page. There
  // is no need to Sentry
  if (errorObject != null && errorObject.code === 'credentials_required') {
    return;
  }

  // Report the error to Sentry
  if (typeof Raven !== 'undefined') {
    Raven.captureMessage(message);
  }

  // TODO: POST back to HS to track error for analytics
});

/**
 * Handler for 'log' events.
 *
 * Called on logger.log/info/warn/error('message')
 */
logger.on('log', function (log) {
  log.type = log.type || 'log';
  const msg = [`[${log.type.toUpperCase()}]`].concat(log.args);
  const method = console[log.type] || console.log;

  if (!log.silent) {
    method.apply(console, msg);
  }

  if (log.type !== 'log') {
    // Trigger any level-specific events
    this.emit.apply(this, [log.type].concat(Array.prototype.slice.call(arguments)));
  }
});


/**
 * Handler for 'track' events. Sends the eventName and eventData
 * to Heap Analytics.
 *
 * Called on logger.track('eventName', { data });
 */
logger.on('track', (eventName, eventData) => {
  // heap may throw an exception if we're calling track() and it
  // has not loaded yet. Re-track if this happens up to N times (CC)
  eventData = Object.assign({}, eventData, {
    appVersion: pkg.version,
  });

  function trackNow(callsLeft, trackFn) {
    if (!callsLeft) return;
    try {
      trackFn();
    } catch (e) {
      setTimeout(trackNow, 1000, callsLeft - 1, trackFn);
    }
  }
  const maxAttempts = 3;
  if (global.ga) {
    trackNow(maxAttempts, () => {
      // docs: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
      global.ga('send', 'event', 'Views', eventName, eventName, eventData);
    });
  }

  if (global.heap) {
    trackNow(maxAttempts, () => {
      global.heap.track(eventName, eventData);
    });
  }

});

if (process.env.NODE_ENV === 'test') module.exports = logger;
