/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, injectIntl } from 'react-intl';
import flattenMessages from 'common/utils/flatten-messages';
import {
  getDefaultMessages, messages as smartlingMessages, configMessages, locale, onError,
} from 'hellospa/components/hs-intl-provider';

const makeComponentIntl = (component) => {
  const InjectedComponent = injectIntl(component);

  // Needs to be a class because refs are added by React Intl
  class IntlComponent extends React.Component {

    static propTypes = {
      intl: PropTypes.shape({
        messages: PropTypes.object,
      }),
      messages(props, _propName, componentName) {
        if (props.intl && props.intl.messages) {
          return;
        }

        if (!props.messages) {
          return new Error(
            `${componentName} did not recieve intl or messages. Without supplied messages all components will be forced to en-US`,
          );
        }
      },
    };

    render() {
      const intlMessages = this.props.intl ? flattenMessages(this.props.intl.messages) : null;

      // In order of priority this will use:
      // 1. messages passed directly to the component
      //    maybe unused
      // 2. messages passed through the intl prop -- Probably only passed
      //    Probably only passed to `<RootComponent` in `signer/application`
      // 3. messages that came from window.config.intl.messages
      //    Seems to only be populated in the signer app
      // 4. The en-US messages compiled into the bundle.
      const messages = (
        this.props.messages || intlMessages || configMessages || getDefaultMessages()
      );

      return <IntlProvider locale={locale} onError={onError} messages={{
        // Keep the existing behavior, use the messages passed in or fall back
        // to the default
        ...messages,
        // But when we enable smartling, it will override our old translations.
        ...smartlingMessages,
      }}
      >
        <InjectedComponent {...this.props} />
      </IntlProvider>;
    }
  }

  return IntlComponent;
};

export default makeComponentIntl;
module.exports = makeComponentIntl;
