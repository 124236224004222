/**
 * @enum {string}
 * @readonly
 */
export const messages = {
  APP_CONFIGURE: 'hellosign:configure',
  APP_ERROR: 'hellosign:error',
  APP_INITIALIZE: 'hellosign:initialize',
  USER_CANCEL_REQUEST: 'hellosign:userCancelRequest',
  USER_CREATE_TEMPLATE: 'hellosign:userCreateTemplate',
  USER_DECLINE_REQUEST: 'hellosign:userDeclineRequest',
  USER_FINISH_REQUEST: 'hellosign:userFinishRequest',
  USER_PREP_SEND_EDITOR_CONTINUE: 'hellosign:userPrepSendEditorContinue',
  USER_REASSIGN_REQUEST: 'hellosign:userReassignRequest',
  USER_SEND_REQUEST: 'hellosign:userSendRequest',
  USER_SIGN_REQUEST: 'hellosign:userSignRequest',
  USER_PREP_SEND_EDITOR_DELETE_DOC: 'hellosign:userPrepSendEditorDeleteDoc',
};

/**
 * Natively sends a cross-origin window message if the
 * user's navigator supports it, otherwise fails silently.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 * @param {any} message
 * @param {string} targetOrigin
 * @param {Transferable} [transfer]
 * @returns {void}
 */
export function postMessage(message, targetOrigin, ...rest) {
  if (window.postMessage && targetOrigin) {
    window.parent.postMessage(message, targetOrigin, ...rest); // NOSONAR
  }
}
