/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';
import ForgotPasswordForm from './forgot-password-form';

const ForgotPasswordModal = createReactClass({

  propTypes: {
    tabOffset: PropTypes.number,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    emailAddress: PropTypes.string,
  },

  getInitialState() {
    return {
    };
  },

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      $(document).foundation();
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  componentWillReceiveProps() {
    if (process.env.NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this))
        .closest('.reveal-modal')
        .foundation('reveal', 'open');
    }
  },

  render() {

    const form = (<ForgotPasswordForm
      tabOffset={this.props.tabOffset}
      method='POST'
      namespace={this.props.recoverPasswordForm.namespace}
      action={HfReactHelper.urlHelper('account/recoverPassword', HfReactHelper.HfSites.SITE_CODE_HS)}
      fields={this.props.recoverPasswordForm.fields}
      values={this.props.recoverPasswordForm.values}
      emailAddress={this.props.emailAddress}
      errors={{ global: this.state.error }}
    ></ForgotPasswordForm>);

    return (
      <div className='row text-center'>
        <div className='columns small-12'>
          <h4>No worries.</h4>
          <p className='l-padding-b-10'>Enter your email address to request a password reset.<br /></p>
          { form }
        </div>
      </div>
    );
  },
});

module.exports = ForgotPasswordModal;
