/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  max-len,
  no-console,
  no-nested-ternary
*/
export default class NotificationHandler {
  constructor(element) {
    this.notification_element = element || document.getElementById('notification_content');
    this.notifications = this._parseNotifications() || [];
  }

  removeNotifications() {
    if (!this.notification_element) {
      return false;
    }

    this.notifications.forEach((notification) => {
      // fancybox notification are handled in JQuery
      if (notification.data.class_name !== 'fancybox') {
        this.removeNotification(notification);
      }
    });

    this.notification_element.innerHTML = JSON.stringify(this.encodedNotification);
  }

  removeNotification(notification) {
    this.encodedNotification.splice(this.notifications.indexOf(Base64.encode(JSON.stringify(notification))), 1);
  }

  /**
      "Private" Methods
   */

  _parseNotifications() {
    try {
      this.encodedNotification = this.notification_element
        ? (this.notification_element.innerHTML.trim() ? JSON.parse(this.notification_element.innerHTML) : [])
        : [];

      return this.encodedNotification
        .map(this._parseNotification.bind(this))
        .filter((notification) => typeof notification !== 'undefined')
        .filter((notification) => notification.data.class_name !== 'fancybox'); // Filter out fancybox which is handled by jquery
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') {
        console.error('Could not parse notification', e);
      }
      return false;
    }
  }

  _parseNotification(notification) {
    try {
      return { data: JSON.parse(Base64.decode(notification)) };
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') {
        console.error('Could not parse notification data', e);
      }
    }
  }
}
