/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import omit from 'lodash/object/omit';
import createReactClass from 'create-react-class';

const Logo = createReactClass({
  render() {
    return <div className='m-app-topbar--logo'>
      <a href={this.props.href}><img {...omit(this.props, ['app'])} /></a>
    </div>;
  },
});

module.exports = Logo;
