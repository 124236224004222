/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  import/no-extraneous-dependencies,
  max-len,
  no-prototype-builtins,
  no-redeclare,
  no-var,
  no-void,
  one-var,
  react/jsx-key,
  react/no-unescaped-entities,
  vars-on-top
*/
import baseform from 'common/components/base-form';
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import { cancelStrings, cancelConstants } from './strings-and-constants';

require('./cancel-subscription-form.scss');


const CancelSubscriptionForm = baseform.createFormClass({

  displayName: 'CancelSubscriptionForm',

  propTypes: {
    isHf: PropTypes.bool, // maybe get dynamically from helpers
    isTeamAdmin: PropTypes.bool,
    hasHipaa: PropTypes.bool,
    hasBothApiAndUiPlans: PropTypes.bool,
    redirectUrl: PropTypes.string,
    showCustomerCancelOffer: PropTypes.bool,
    termsURL: PropTypes.string,
  },

  getInitialState() {
    return {
      supportMessage: '',
      justApi: false,
      justUi: false,
      // Individual fields to hide (not sections, such as competitor or support contact)
      showFields: {
        'cancel_reason_features-detail': false,
      },
      // Competitor info
      cancelForCompetitor: false,
      competitorName: '',
      competitorAutocompleteMatches: [],
      shouldShowCompetitorAutocomplete: false,
      shouldShowCompetitorReasons: false,
      // Validation
      hasAttemptedSubmit: false,
      supportShouldContactFilled: false,
      atLeastOneReasonChecked: false,
      errors: {},
      // Null means don't set it to be selected to begin with
      supportShouldContact: null,
      canWeFollowUp: null,
    };
  },

  /**
    * Handlers
    */

  onCompetitorAutocompleteItemClick(e) {
    const text = e.target.innerHTML;
    this.setState({
      competitorName: text,
      shouldShowCompetitorAutocomplete: false,
    });
  },

  onSupportMessageChange(e) {
    const message = e.target.value;

    this.setState({
      supportMessage: message,
    });
  },

  onPlanSwitchChange(e) {

    const newState = {};

    if (e.target.name === 'cancel[just_ui]') {
      newState.justUi = e.target.checked;
    } else if (e.target.name === 'cancel[just_api]') {
      newState.justApi = e.target.checked;
    }

    this.setState(newState);
  },

  onReasonListChange(e) {

    const newState = {};
    newState.atLeastOneReasonChecked = (document.querySelectorAll('input[type="checkbox"]:checked').length > 0);

    if (e.target.name === 'cancel[cancel_reason_features]') {
      newState.showFields = {};
      newState.showFields['cancel_reason_features-detail'] = e.target.checked;
    }

    if (e.target.name === 'cancel[cancel_reason_competitor]') {
      newState.cancelForCompetitor = e.target.checked;
    }

    this.setState(newState);
  },

  onCompetitorNameChange(e) {
    const name = e.target.value;
    let list = [];

    if (name.length > cancelConstants.AUTOCOMPLETE_MIN_THRESHOLD) {
      // Cheap implentation of autocomplete, but enough for this case
      const re = new RegExp(name, 'i');
      list = cancelConstants.competitorList.filter((competitor) => {
        return competitor.match(re);
      });
    }

    this.setState({
      competitorName: name,
      competitorAutocompleteMatches: list,
      shouldShowCompetitorAutocomplete: (name.length > cancelConstants.AUTOCOMPLETE_MIN_THRESHOLD),
      shouldShowCompetitorReasons: (name.length > cancelConstants.AUTOCOMPLETE_MIN_THRESHOLD),
    });
  },
  onSupportShouldContactChange(e) {
    const shouldContact = e.target.value === 'yes';
    this.setState({
      supportShouldContactFilled: true,
      supportShouldContact: shouldContact,
    });
  },

  onCanWeFollowUpChange(e) {
    const canWeFollowUp = e.target.value === 'yes';
    this.setState({
      canWeFollowUp,
    });
  },

  onSubmit(e) {

    // Validation
    this.setState({ hasAttemptedSubmit: true });
    const valid = this.validateForm() && this.validateSupportMessage() && this.validatePlanSwitch();

    e.preventDefault();
    if (valid) {
      $('#cancel_form').submit();
    }
  },

  /**
    * Validation
    */

  validateForm() {
    if (this.state.supportShouldContactFilled && this.state.atLeastOneReasonChecked) {
      return true;
    }
    return false;
  },

  validateSupportMessage() {

    // Don't need to fill in if no contact needed
    if (!this.state.supportShouldContact) {
      return true;
    }

    if (this.state.supportMessage.length >= cancelConstants.SUPPORT_MESSAGE_MIN_THRESHOLD) {
      return true;
    }

    return false;

  },

  validatePlanSwitch() {

    if (!this.props.hasBothApiAndUiPlans) {
      return true;
    }

    if (this.state.justUi || this.state.justApi) {
      return true;
    }

    return false;
  },

  /**
    * Sub render methods
    */

  renderCancelWarning() {
    const legalText = this.props.isHf ? cancelStrings.legalText.HF : cancelStrings.legalText.HS;
    return (
      <span>
        { legalText.head }
        <a href={this.props.termsURL}> { legalText.link } </a>
        { legalText.tail }
      </span>
    );
  },

  renderPlanSwitch() {

    const self = this;

    const error = this.state.hasAttemptedSubmit && (!this.state.justUi && !this.state.justApi);

    const justApiToggle = this.renderField('just_api', {
      'data-format': 'inputcheckbox',
      value: this.state.justApi,
      onChange: self.onPlanSwitchChange,
    });

    const justUiToggle = this.renderField('just_ui', {
      'data-format': 'inputcheckbox',
      value: this.state.justUi,
      onChange: self.onPlanSwitchChange,
    });

    return (
      <div className='m-settings--cancel--plan-switch-container'>
        <div className='m-settings--cancel--api-option'>
          { justApiToggle }
          <label htmlFor='cancel_just_api'>Cancel API plan &nbsp;</label>
          { justUiToggle }
          <label htmlFor='cancel_just_ui'>Cancel web plan</label>
        </div>
        <div className='m-settings--cancel--error'>
          { error ? cancelStrings.errors.noSelection : void 0}
        </div>
      </div>
    );
  },

  renderYesNoToggle(fieldName, sectionLabelName, onChange) {

    const sectionLabel = (<h2>{ sectionLabelName }</h2>);

    let yesSelected = false,
      noSelected = false;
    if (fieldName === 'support_should_contact' && this.state.supportShouldContact !== null) {
      yesSelected = this.state.supportShouldContact;
      noSelected = !yesSelected;
    } else if (fieldName === 'can_provide_more_info' && this.state.canWeFollowUp !== null) {
      yesSelected = this.state.canWeFollowUp;
      noSelected = !yesSelected;
    }

    const yes = this.renderField(fieldName, {
      tabIndex: 2,
      'data-format': 'selectradio',
      onChange,
      name: fieldName,
      value: 'yes',
      checked: yesSelected,
    });
    const yesLabel = (<label htmlFor={fieldName}>Yes</label>);
    const no = this.renderField(fieldName, {
      tabIndex: 2,
      'data-format': 'selectradio',
      onChange,
      name: fieldName,
      value: 'no',
      checked: noSelected,
    });
    const noLabel = (<label htmlFor={fieldName}>No</label>);
    return (<div className='m-settings--cancel--toggle-container'>
      { sectionLabel }
      { yes }
      { yesLabel }
      { no }
      { noLabel }
    </div>);
  },

  renderReasonForCancelList() {
    return this.renderReasonsList('cancel_reason', cancelStrings.cancelReasons);
  },

  renderCompetitorReasonList() {
    return this.renderReasonsList('competitor_reason', cancelStrings.competitorReasons);
  },

  renderReasonsList(namePrefix, namesToReasonLabelMap) {
    const self = this;
    const regex = new RegExp(namePrefix);
    const reasons = this.props.fields.filter((field) => { return field.name && field.name.match(regex); });

    const list = [];
    reasons.forEach((reason, index) => {
      let component;
      switch (reason.type) {

        case 'inputcheckbox':
          var field = self.renderField(reason.name, {
            tabIndex: 2,
            'data-format': 'inputcheckbox',
            onChange: self.onReasonListChange,
            name: reason.name,
            key: reason.name,
          });
          var label = (<label
            htmlFor={reason.name}
          >
            { namesToReasonLabelMap[reason.name] }
          </label>);
          component = (<li key={index} className='m-settings--cancel--reason-list--item'>
            { field }
            { label }
          </li>);
          break;

        case 'textarea':
          if (self.state.showFields.hasOwnProperty(reason.name) && !self.state.showFields[reason.name]) {
            component = void 0;
          } else {
            var field = self.renderField(reason.name, {
              tabIndex: 2,
              'data-format': 'textarea',
              onChange: self.onReasonListChange,
              name: reason.name,
              key: reason.name,
              placeholder: cancelStrings.placeholders[reason.name],
            });
            component = (<li key={index}>{ field }</li>);
          }
          break;

        default:
          component = (<li key={index}></li>);

      }
      if (component) {
        list.push(component);
      }
    });

    return (<ul className='m-settings--cancel--reason-list'>{list}</ul>);
  },

  renderTeamAdminWarning() {
    return (<span><br />{ cancelStrings.teamAdminWarning }</span>);
  },

  renderCompetitorAutocomplete() {
    const matches = this.state.competitorAutocompleteMatches;
    if (this.state.shouldShowCompetitorAutocomplete) {
      return (<div className='m-settings--cancel--competitor-autocomplete'>
        <ul>
          { matches.map((match) => {
            return (<li className='m-settings--cancel--competitor-autocomplete--item'
              onClick={this.onCompetitorAutocompleteItemClick}
            >
              { match }
            </li>);
          })}
        </ul>
      </div>);
    }
  },

  /**
    * Main render
    */

  render() {

    const tabOffset = parseInt(this.props.tabOffset || 0, 10);
    const siteCode = this.props.isHf ? HfReactHelper.HfSites.SITE_CODE_HF : HfReactHelper.HfSites.SITE_CODE_HS;

    const reminderList = this.props.isHf ? void 0 : (
      <div className='m-settings--cancel--reminder'>
        <div className='m-settings--cancel--reminder--title'>
                    FREE ACCOUNTS DON'T INCLUDE
        </div>
        <ul>
          <li>Unlimited documents</li>
          <li>Templates</li>
          <li>In-person signing</li>
          <li>Two-factor Authentication</li>
          { this.props.hasHipaa ? <li>HIPAA compliance</li> : void 0 }
        </ul>
      </div>
    );

    const supportShouldContactToggle = this.renderYesNoToggle(
      'support_should_contact',
      'Do you need a Support Representative to follow up with you?',
      this.onSupportShouldContactChange,
    );

    const supportMessageTextArea = this.renderField('support_message', {
      tabIndex: tabOffset + 1,
      placeholder: 'Please briefly describe the issue',
      'data-format': 'textarea',
      onChange: this.onSupportMessageChange,
    });

    const supportError = (!this.state.supportShouldContactFilled && this.state.hasAttemptedSubmit)
      ? cancelStrings.errors.noSelection : void 0;
    const supportMessageError = (!this.validateSupportMessage() && this.state.hasAttemptedSubmit) ? cancelStrings.errors.messageTooShort : void 0;

    const customerSupportInfo = (
      <div className='m-settings--cancel--support'>
        { supportShouldContactToggle }
        { this.state.supportShouldContact ? supportMessageTextArea : void 0 }
        <div className='m-settings--cancel--error'>
          { supportError }
          { supportMessageError }
        </div>
      </div>
    );

    const canWeFollowUpToggle = this.renderYesNoToggle(
      'can_provide_more_info',
      'Would you be willing to provide more information about your experience if needed?',
      this.onCanWeFollowUpChange,
    );

    const competitorNameField = this.renderField('competitor_name', {
      tabIndex: tabOffset + 1,
      placeholder: 'Please enter the name of the company you\'ve chosen',
      'data-format': 'input',
      onChange: this.onCompetitorNameChange,
      value: this.state.competitorName,
    });

    const competitorAutocomplete = this.renderCompetitorAutocomplete();

    const competitorReasons = this.state.shouldShowCompetitorReasons ? (
      <div className='m-settings--cancel--reason-container'>
        <h2>Please indicate your reasons for choosing them :</h2>
        <h4>Please select all that apply.</h4>
        { this.renderCompetitorReasonList() }
      </div>
    ) : void 0;

    const competitorInfo = this.state.cancelForCompetitor ? (
      <div className='m-settings--cancel--competitor-info'>
        { competitorNameField }
        { competitorAutocomplete }
        { competitorReasons }
      </div>
    ) : void 0;

    const reasonsError = (!this.state.atLeastOneReasonChecked && this.state.hasAttemptedSubmit)
      ? cancelStrings.errors.noSelection : void 0;

    const cancellationAndCompetitorReasons = (
      <div className='m-settings--cancel--reason-container'>
        <h2>Why are you canceling your service with us?</h2>
        <h4>Please select all that apply.</h4>
        { this.renderReasonForCancelList() }
        { competitorInfo }
        <div className='m-settings--cancel--error'>
          { reasonsError }
        </div>
      </div>
    );

    const customerComments = (<div>
      <h2>Anything else you'd like to tell us?</h2>
      {this.renderField('customer_comments', {
        tabIndex: tabOffset + 1,
        placeholder: 'Thank you so much for taking the time to provide us with feedback.',
        'data-format': 'textarea',
      })}
    </div>);

    const cancelLegalWarning = (
      <p className='m-settings--cancel--warning'>
        { this.renderCancelWarning() }
        { this.props.isTeamAdmin ? this.renderTeamAdminWarning() : void 0 }
      </p>
    );

    const cancelButtonText = this.props.showCustomerCancelOffer ? 'Continue' : 'Cancel my subscription';
    const ctaButtons = (
      <div className='m-settings--cancel--actions'>
        <div id='cancelSubscriptionButton'>
          <Button
            buttonText={cancelButtonText}
            buttonLink='#'
            buttonColor='grasshopper'
            buttonClass='border-1-grasshopper'
            buttonTextColor='white'
            buttonHoverTextColor='white'
            buttonHoverColor='castle-rock'
            buttonHoverBorderColor='castle-rock'
            type='submit'
            onClick={this.onSubmit} />
        </div>
        <div id='nevermindButton'>
          <Button
            buttonText='Never mind'
            buttonLink={HfReactHelper.urlHelper('home/myAccount?current_tab=billing', siteCode)}
            buttonColor='white'
            buttonClass='border-1-grasshopper'
            buttonTextColor='grasshopper'
            buttonHoverTextColor='white'
            buttonHoverColor='grasshopper' />
        </div>
      </div>
    );

    return (
      <form id='cancel_form' name='cancel_form' className='account-form' method='POST'>

        { this.renderHiddenFields() }

        <h1>
                        Are you sure you want to cancel?
        </h1>
        <h3 className='m-cancel--subtitle'>
                        Canceling your paid account will downgrade your service to a Free account.
        </h3>

        { reminderList }

        <div className='m-settings--cancel--hr' />

        <h3>
                        If you do decide to cancel, we would really appreciate learning about your experience using HelloSign.
        </h3>

        { customerSupportInfo }
        { cancellationAndCompetitorReasons }
        { canWeFollowUpToggle }
        { customerComments }

        { cancelLegalWarning }

        { this.props.hasBothApiAndUiPlans ? this.renderPlanSwitch() : void 0 }

        <input type='hidden' name='redirect_url' value={this.props.redirectUrl ? this.props.redirectUrl : ''} />
        { this.renderErrorText() }

        { ctaButtons }

      </form>
    );
  },


});

module.exports = CancelSubscriptionForm;
