/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/

module.exports = {
  initialize() {

    // attach to the global notifier
    this.notifier.push(this);

    this.addDisposable({
      dispose: () => {
        this.notifier.remove(this);
      },
    });
  },
  notify() {
    // OVERRIDE ME
  },
};
