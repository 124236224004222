import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropDownMenu from './nav-drop-down-menu';

class TopBarMenuLink extends React.Component {

  state = {
    menuOpen: false,
  };

  wrapperRef = React.createRef();

  overLayDiv = document.getElementById('fn-overlay-blur-background');

  static propTypes = {
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string,
    linkClassName: PropTypes.string,
    linkOverrideColors: PropTypes.object,
    pageToHighLight: PropTypes.string,
    useWhite: PropTypes.bool,
    dropDownMenuLinkData: PropTypes.array,
    isMobile: PropTypes.bool,
    daAction: PropTypes.string,
    daCategory: PropTypes.string,
    children: PropTypes.array,
    dropDownGridClasses: PropTypes.array,
  };

  componentDidMount() {
    document.addEventListener('mouseup', this.onClickEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onClickEvent);
  }

  onClickEvent = (evt) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(evt.target) && this.state.menuOpen) {
      this.closeMenu();
    }
  };

  showOverlay() {
    this.overLayDiv.classList.add('fn-overlay-show');
  }

  hideOverlay() {
    this.overLayDiv.classList.remove('fn-overlay-show');
  }

  openMenu() {
    this.setState({ menuOpen: true });
    this.wrapperRef.current.classList.add('w-open');
    this.showOverlay();
  }

  closeMenu() {
    this.setState({ menuOpen: false });
    this.wrapperRef.current.classList.remove('w-open');
    this.hideOverlay();
  }

  onClickNavBarMenu = () => {
    if (this.state.menuOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  };

  render() {
    const dropDownMenu = (
      this.props.dropDownMenuLinkData && this.state.menuOpen && (
        <DropDownMenu
          dropDownMenuLinkData={this.props.dropDownMenuLinkData}
          isMobile={this.props.isMobile}
          daAction={this.props.daAction}
          daCategory={this.props.daCategory}
        >
          {this.props.children}
        </DropDownMenu>
      )
    );

    const dropDownArrow = (
      <span
        className={classNames('nav-bar-dropdown-header-arrow', {
          'nav-bar-dropdown-header-arrow--mobile': this.props.isMobile,
        })} />
    );

    return (
      <div className={this.props.linkClassName} ref={this.wrapperRef}>
        <div className="nav-bar-dropdown-header-inner-tab"
          onClick={this.onClickNavBarMenu}
          da-category={this.props.daCategory}
          da-action={this.props.daAction}
          tabIndex="-1"
        >
          {this.props.linkText}
          {dropDownArrow}
        </div>
        {dropDownMenu}
      </div>
    );
  }
}

export default TopBarMenuLink;
