/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import createReactClass from 'create-react-class';
import ActionBar from './action-bar';
import CONSTANTS from './constants';

import {
  CommonTabProps as CommonTabPropTypes,
} from './prop-types';

const ErrorTab = createReactClass({

  propTypes: {
    ...CommonTabPropTypes,
    error: PropTypes.object,
    onContinue: PropTypes.func.isRequired,
  },

  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {
      error: new Error('Unknown error'),
    };
  },

  getInitialState() {
    return {
      isSignature: (this.props.type === CONSTANTS.TYPE_SIGNATURE),
      isInitials: (this.props.type === CONSTANTS.TYPE_INITIALS),
    };
  },

  //  ----  RENDERING  ----------------------------------

  render() {
    const { actionBarProps, onContinue } = this.props;

    const inputClasses = {
      'm-sign-modal--tabs--error--input': true,
    };
    const classes = {
      'm-sign-modal--tabs--error': true,
    };
    const containerClasses = {
      'm-sign-modal--tabs--tab': true,
      'is-selected': this.props.isSelected,
    };
    const errorClasses = {
      'm-sign-modal--tabs--error-content': true,
    };

    const errorMessage = this.props.error.message || '';

    return (
      <div className={cx(containerClasses)}>

        <span className='m-sign-modal--tabs--tab-title'>
          <FormattedMessage id='signModal.error.title' />
        </span>

        <div className={cx(classes)}>
          <div className={cx(inputClasses)}>
            <div className='m-sign-modal--tabs--content'>
              <div className={cx(errorClasses)}>
                <h1>
                  <FormattedMessage id='signModal.error.header' />
                </h1>
                <p>
                  <FormattedMessage id='signModal.error.body' />
                </p>
                <p data-test-ref='error-message'>
                  { errorMessage }
                </p>
              </div>
            </div>
          </div>
        </div>

        <ActionBar
          data-test-ref='action-bar'
          {...actionBarProps}
          continueInsteadOfInsert
          onContinue={onContinue} />

      </div>
    );
  },
});

module.exports = ErrorTab;
