/* eslint-disable import/prefer-default-export */
import hsFetch from 'hellospa/hs-fetch';

const BASE = '/endpoint';

/**
 * @typedef {Object} EndpointsAPI~Response
 * @property {Object} [data]
 * @property {string} [error]
 * @property {boolean} success
 */

/**
 * @typedef {EndpointsAPI~Response} EndpointsAPI~PagedResponse
 * @property {number} [page]
 * @property {number} [numPages]
 */

/**
 * Generic wrapper for making GET requests to new endpoints
 * API.
 *
 * @param {USVString} resource
 * @param {Object} [init]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getEndpointResource(resource, init = {}) {
  return new Promise((resolve, reject) => {
    hsFetch(`${BASE}/${resource.replace(/^\//, '')}`, init).then((response) => {
      try {
        response.json().then((obj) => {
          if (obj.success) {
            resolve(obj);
          } else {
            reject(obj.error);
          }
        }, reject);
      } catch (err) {
        response.text().then((str) => {
          if (response.ok) {
            resolve(str);
          } else {
            reject(str);
          }
        }, reject);
      }
    }, reject);
  });
}

/**
 * Generic wrapper for making POST requests to new
 * endpoints API.
 *
 * @param {USVString} resource
 * @param {Object} [init]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function postEndpointResource(resource, init = {}) {
  return getEndpointResource(resource, {
    method: 'POST',
    ...init,
  });
}


/**
 * Generic wrapper for making POST requests with JSON
 * payloads to new endpoints API.
 *
 * @param {USVString} resource
 * @param {Object} [init]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function postJSONEndpointResource(resource, init = {}) {
  return postEndpointResource(resource, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init.headers,
    },
  });
}
