/* If you edit this file, please remove this header and clean up the resulting eslint errors. */
/* global dataLayer */
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-redeclare,
  no-var,
  react/no-find-dom-node,
  react/prop-types,
  vars-on-top
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import createReactClass from 'create-react-class';
import PaymentForm from './payment-form';

import './payment-modal.scss';

const PaymentModal = createReactClass({

  // NOTE: These propTypes are incomplete - see render() for all props
  propTypes: {
    tabOffset: PropTypes.number,
    paymentForm: PropTypes.object,
    numSeats: PropTypes.number,
    formAction: PropTypes.string,
    headerText: PropTypes.string,
    forceFreeTrial: PropTypes.bool,
    numFreeTrialDays: PropTypes.number,
    tosUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    planName: PropTypes.string,
    isApi: PropTypes.bool,
  },

  getInitialState() {
    return {
      stripe_token: '',
    };
  },

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      const $root = $(ReactDOM.findDOMNode(this));
      const $modal = $root.closest('.reveal-modal');
      const $doc = $(document);

      $doc.foundation();

      $doc.on('open.fndtn.reveal', () => {
        document.body.classList.add('legacy-foundation-modal-visible');

        const firstInput = $root.find('input:first');
        firstInput.focus();
      });

      $doc.one('closed.fndtn.reveal', () => {
        document.body.classList.remove('legacy-foundation-modal-visible');
      });

      $modal.foundation('reveal', 'open');
    }

    this.reportAnalytics();
  },

  componentWillReceiveProps() {
    if (process.env.NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this)).closest('.reveal-modal').foundation('reveal', 'open');
    }
  },

  checkIfDowngrade() {
    return (this.props.timePeriod == 'M' ? this.props.currentMonthlyRate > this.props.monthlyExpectedRate : this.props.currentAnnualRate > this.props.annualExpectedRate);
  },

  reportAnalytics() {
    if (this.props.isUpgrade === undefined || this.props.currentMonthlyRate === null || this.props.currentMonthlyRate === 0) {
      dataLayer.push({
        event: 'modal_popup',
        pageName: '/creditCardDetails',
        pageType: 'creditCardDetails',
        virtualPagePath: '/creditCardDetails',
        productPlan: this.props.planName.toLowerCase(),
        productType: 'hs',
        subscriptionPlan: this.props.timePeriod.toLowerCase(),
      });
    } else {
      dataLayer.push({
        event: 'modal_popup',
        pageName: this.props.isUpgrade ? '/upgradeModal' : '/downgradeModal',
        pageType: this.props.isUpgrade ? 'upgradeModal' : 'downgradeModal',
        virtualPagePath: this.props.isUpgrade ? '/upgradeModal' : 'downgradeModal',
        productPlan: this.props.planName.toLowerCase(),
        productType: 'hs',
        subscriptionPlan: this.props.timePeriod.toLowerCase(),
      });

    }
  },

  renderHelloSign() {
    const rateString = HfReactHelper.formatMoney(this.props.timePeriod == 'M' ? this.props.monthlyExpectedRate : this.props.annualExpectedRate, '$');
    const freeTrialDays = this.props.numFreeTrialDays || '30';

    let billingString = `Your ${freeTrialDays} day trial starts today. Within those ${freeTrialDays} days, if you change your mind, you can cancel your account and you won't be charged. After your free trial, we'll begin billing you ${rateString}/${this.props.timePeriod == 'M' ? 'mo' : 'yr'}.`;
    let downgradingToFree = false;
    if (!this.props.forceFreeTrial && this.props.isApi) {
      if (!this.props.isTrialing || this.props.proratio == 1) {
        billingString = `You will be billed ${rateString} today.`;
      } else {
        var proratedRateString = HfReactHelper.formatMoney(this.props.timePeriod == 'M' ? this.props.monthlyProratedRate : this.props.annualProratedRate, '$');
        billingString = `You will be billed a prorated amount of ${proratedRateString} today. On the start of your next billing cycle you will then be charged the full amount for the new period (${rateString}).`;
      }

    } else if (this.props.isTrialing) {
      if (rateString === '$0') {
        billingString = 'Downgrading will end your free trial and you will not be charged. You can upgrade at any time.';
        downgradingToFree = true;
      } else {
        billingString = `You will be billed ${rateString} at the end of your existing free trial.`;
        if (this.props.isEnterprise) {
          // When in a UI trial and switching to an Enterprise UI we charge in full today
          billingString = `You will be billed ${rateString} today.`;
        }
      }
    } else if ((this.props.timePeriod == 'M' && this.props.monthlyExpectedRate !== this.props.monthlyProratedRate)
            || (this.props.timePeriod == 'Y' && this.props.annualExpectedRate !== this.props.annualProratedRate)) {
      // need to display prorated information
      var isDowngrade = this.checkIfDowngrade();
      if (isDowngrade) {
        billingString = `At the beginning of your next billing cycle you will be billed ${rateString}.`;
      } else {
        var proratedRateString = HfReactHelper.formatMoney(this.props.timePeriod == 'M' ? this.props.monthlyProratedRate : this.props.annualProratedRate, '$');

        // If the update charge is specifically passed than we show it
        if (this.props.upgradeCharge) {
          proratedRateString = HfReactHelper.formatMoney(this.props.upgradeCharge, '$');
        }

        billingString = `You will be charged ${proratedRateString} for your current pro-rated billing cycle. Starting with your next billing cycle, you will be charged ${rateString}/${this.props.timePeriod == 'M' ? 'mo' : 'yr'}.`;
      }
    } else if (!this.props.isFreeTrialEligible) {
      var isDowngrade = this.checkIfDowngrade();
      if (isDowngrade) {
        billingString = `At the beginning of your next billing cycle you will be billed ${rateString}.`;
      } else {
        billingString = `You will be billed ${rateString} today.`;
      }
    } else if (!this.props.forceFreeTrial && this.props.monthlyExpectedRate <= 0) {
      billingString = (
        <span>
                    When you downgrade to a Free plan you will lose access to our paid features,
                    including templates. But fear not! You will still have full access to your
                    account and any signed documents you&#39;ve saved.
          <br />
          <br />
                    You can upgrade at any time to restore the applicable paid features and
                    increase the number of documents and templates you can use.
        </span>
      );
    }

    if (!downgradingToFree) {
      const supportEmail = HfReactHelper.HfConstants.supportEmail[HfReactHelper.HfSites.SITE_CODE_HS];
      billingString = (
        <span>
          { billingString }
          <br />
          <br />
                    If you have any questions, you can reach our support team at&nbsp;<a href={`mailto:${supportEmail}`}>{ supportEmail }</a>.
        </span>
      );
    }

    billingString = (
      <span>
        { billingString }
        <br />
        <br />
        By clicking continue, you agree to the HelloSign <a href={this.props.tosUrl} target="_blank" rel="noopener noreferrer">Terms</a>, <a href={this.props.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and <a href={this.props.refundPolicyUrl} target="_blank" rel="noopener noreferrer">Refund Policy</a>.
      </span>
    );

    let headerText;
    if (this.props.headerText) {
      headerText = this.props.headerText;
    } else {
      headerText = `Sign up for ${this.props.name}`;
      const currentRate = (this.props.timePeriod == 'Y')
        ? parseFloat(this.props.currentAnnualRate)
        : parseFloat(this.props.currentMonthlyRate);
      if (!this.props.isApi && currentRate == 0.0 && this.props.isFreeTrialEligible) {
        headerText = `Start ${this.props.name} Trial`;
      } else if (this.props.isUpgrade) {
        headerText = `Upgrade to ${this.props.name}`;
      } else {
        headerText = `Downgrade to ${this.props.name}`;
      }
    }

    const card = {
      name: this.props.cardHolderName,
      suffix: this.props.ccSuffix,
    };

    return <PaymentForm fields={this.props.paymentForm.fields}
      values={this.props.paymentForm.values}
      errors={this.props.errors}
      namespace={this.props.paymentForm.namespace}
      stripeToken={this.state.stripe_token}
      dbxPaymentMethod={this.props.dbxPaymentMethod}
      planId={this.props.planId}
      planName={this.props.planName}
      couponCode={this.props.couponCode}
      timePeriod={this.props.timePeriod}
      tabOffset={this.props.tabOffset}
      card={card}
      buttonText={this.props.buttonText}
      headerText={headerText}
      billingString={billingString}
      ccExpiration={this.props.ccExpiration}
      numSeats={this.props.numSeats}
      formAction={this.props.formAction}
      currentPlanName={this.props.currentPlanName}
      isApi={this.props.isApi}
      name={this.props.name} />;
  },

  renderHelloFax() {
    return <iframe
      style={{ width: '100%', border: '0', height: '850px' }}
      src={`/content/payment?is_from_pricing=1&period=${this.props.timePeriod}&target_plan=${this.props.planId}`} />;
  },

  render() {
    // HelloSign renders a modal with a payment form and other upgrade
    // details in react. This is the newer implementation which is preferred.

    // HelloFax falls back on an original implementation which uses an iframe.
    // We want to replace this at some point and it mostly works out of the box
    // but some of the messaging is HelloSign/HelloFax specific and hasn't been
    // reviewed.

    // An exception for this is if it's HelloFax and we're downgrading to a free plan
    // from a paid plan. The original HelloFax iframe doesn't support that functionality
    // because it used a different mechanism to downgrade to free accounts. But the new
    // HelloSign implementation it turns out works fine with HelloFax too so we'll use that.
    if (HfReactHelper.isHelloSign() || this.props.price === 0.0) {
      return this.renderHelloSign();
    } else {
      return this.renderHelloFax(); // Deprecated!
    }
  },

});

module.exports = PaymentModal;
