import React from 'react';
import HsLogo from 'hellospa/icons/hs-logo';
import HsApiLogo from 'hellospa/icons/hs-api-logo';
import HfLogo from 'hellospa/icons/hf-logo';
import HwLogo from 'hellospa/icons/hw-logo';
import Facebook from 'hellospa/icons/facebook-logo';
import Twitter from 'hellospa/icons/twitter-logo';
import Linkedin from 'hellospa/icons/linkedin-logo';
import Slack from 'hellospa/icons/slack-logo';
import Dropbox from 'hellospa/icons/dropbox-logo';
import Gmail from 'hellospa/icons/gmail-logo';
import GoogleDoc from 'hellospa/icons/googledoc-logo';
import Oracle from 'hellospa/icons/oraclecpq-logo';
import Salesforce from 'hellospa/icons/salesforce-logo';
import classNames from 'classnames';
import style from './custom-icons.scss';

const Icon = (props) => {
  switch (props.name) {
    case 'hslogo':
      return <HsLogo {...props} />;
    case 'hs-api-logo':
      return <HsApiLogo {...props} />;
    case 'hflogo':
      return <HfLogo {...props} />;
    case 'hwlogo':
      return <HwLogo {...props} />;
    case 'slack':
      return <Slack {...props} />;
    case 'dropbox':
      return <Dropbox {...props} />;
    case 'gmail':
      return <Gmail {...props} />;
    case 'googleDoc':
      return <GoogleDoc {...props} />;
    case 'oracle':
      return <Oracle {...props} />;
    case 'salesforce':
      return <Salesforce {...props} />;
    case 'facebook':
      return <Facebook {...props} />;
    case 'twitter':
      return <Twitter {...props} />;
    case 'linkedin':
      return <Linkedin {...props} />;
    default:
      throw new Error('Icon not found');
  }
};

function DropDownMenuItem(props) {
  const dropDownMenuLink = props.value;
  let returnItem = '';

  if (dropDownMenuLink.img) {
    returnItem = <Icon key={dropDownMenuLink.img}
      name={dropDownMenuLink.img}
      className={classNames(style['default-icon'], style[dropDownMenuLink.img])} />;
  } else {
    returnItem = dropDownMenuLink.text;
  }

  if (dropDownMenuLink.detail && !(props.isMobile && dropDownMenuLink.detail.desktopOnly)) {
    returnItem = [returnItem, (<div key={dropDownMenuLink.detail.text} className={'fn-dropdown-col-detail'} > {dropDownMenuLink.detail.text}</div>)];
  }

  if (dropDownMenuLink.url && dropDownMenuLink.url !== '') {
    returnItem = (
      <a
        key={props.index}
        da-category={props.daCategory}
        da-action={dropDownMenuLink.daAction ? dropDownMenuLink.daAction : props.daAction}
        da-label={dropDownMenuLink.daLabel}
        href={dropDownMenuLink.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {returnItem}
      </a>
    );
  } else if (dropDownMenuLink.socialLinks) {
    const socialLinks = dropDownMenuLink.socialLinks.map((link) => {
      return (
        <a key={link.img} href={link.url} da-category={props.daCategory} da-action={props.daAction} da-label={link.daLabel} target="_blank" rel="noopener noreferrer">
          <Icon name={link.img} className={style['fn-dropdown-social-links--icon']} />
        </a>
      );
    });

    returnItem = (<div className="fn-dropdown-social-links">{socialLinks}</div>);
  }

  return returnItem;
}

export default DropDownMenuItem;
