import hsFetch from 'hellospa/hs-fetch';
import { queryParams } from '../../common/utils/fetch';
// This has to import from /constants instead of /signature-modal to avoid a
// dependency loop
import CONSTANTS from '../../../ui/signature-modal/constants';

export default function signatureActions() {

  /**
   * Fetches all signatures of type (signature or initial)
   */
  async function fetchSignatures(type) {
    let signatures = [];
    const url = `/signature/list${queryParams({
      type_code: type,
      ux_version: 2,
    })}`;
    const response = await hsFetch(url, {
      credentials: 'same-origin',
    });

    const data = await response.json();
    if (data.success) {
      signatures = data.data.list;
    }
    return signatures;
  }

  /**
   * Removes a Saved Signature or Initial
   */
  async function removeSignature(guid, csrfToken) {
    const data = {
      guid,
      csrf_token: csrfToken,
      ux_version: 2,
    };
    try {
      const response = await hsFetch('/signature/remove?json=1', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
      });

      const resp = await response.json();
      if (resp.success) {
        return true;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  /**
   * Rotates a signature
   */
  async function rotateSignature(guid, degrees, csrfToken) {
    const data = {
      guid,
      degrees,
      csrf_token: csrfToken,
      ux_version: 2,
    };
    const response = await hsFetch('/signature/rotate?json=1', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response.json();
  }

  /**
   * Checks if the email signature was received and converted
   */
  async function checkEmailedSignatureStatus(emailGuid) {
    const response = await hsFetch(`/signature/emailSignatureStatus?ux_version=2&email_guid=${emailGuid}`, {
      credentials: 'same-origin',
      method: 'GET',
    });
    return response.json();
  }

  /**
   * Uploads a signature canvas
   */
  async function uploadCanvas(sigData, csrfToken) {
    const data = {
      type_code: sigData.type_code,
      signature: sigData.signature.image,
      is_vml: sigData.signature.is_vml,
      csrf_token: csrfToken,
    };

    try {
      const response = await hsFetch('/signature/uploadCanvas?json=1', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return response.json();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  /**
   * Uploads a type-in, file or emailed signature
   */
  async function upload(sigData, csrfToken) {

    const commonData = {
      create_type_code: sigData.create_type_code,
      type_code: sigData.type_code,
      csrf_token: csrfToken,
    };
    let data;
    switch (sigData.create_type_code) {
      case CONSTANTS.SIGNATURE_TYPE_TYPED:
        // Merge the basics for this type of data
        data = {
          ...commonData,
          // Add the specifics
          ...{
            data: {
              text: sigData.signature.text,
              font_family: sigData.signature.font_family,
            },
          },
        };
        break;
      case CONSTANTS.SIGNATURE_TYPE_EMAIL:
      case CONSTANTS.SIGNATURE_TYPE_UPLOAD:
        // Merge the basics for this type
        data = {
          ...commonData,
          // Add the specifics
          ...{
            is_edit: true,
            guid: sigData.guid,
            data: {
              editParams: {
                is_cropped: sigData.is_cropped,
                threshold: sigData.threshold,
                degrees: sigData.degrees,
                width: sigData.width,
                height: sigData.height,
                x: sigData.x,
                y: sigData.y,
              },
            },
          },
        };
        break;
      default:
        throw new Error(`Called upload with incorrect create_type_code ${sigData.create_type_code}`);
    }

    try {
      const response = await hsFetch('/signature/upload?json=1', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return response.json();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  /**
   * Uploads the signature file triggered
   * by the Upload image tab
   */
  async function uploadSignatureFile(signature, csrfToken) {
    const formData = new FormData();

    formData.append('type_code', signature.type);
    formData.append('create_type_code', signature.createType);
    formData.append('csrf_token', csrfToken);
    formData.append('qq_file', signature.file);
    try {
      const response = await hsFetch('/signature/upload', {
        credentials: 'same-origin',
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        return response.json();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return {
    fetchSignatures,
    removeSignature,
    rotateSignature,
    checkEmailedSignatureStatus,
    uploadCanvas,
    uploadSignatureFile,
    upload,
  };
}
