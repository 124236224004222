/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  global-require,
  max-len,
  no-plusplus,
  no-void
*/

import BaseModel from 'common/models/base/model';
import TeamMember from './team-member';
import TeamMembers from './team-members';

module.exports = BaseModel.extend({

  /**
    */

  mixins: [
    require('common/models/mixins/model'),
    require('common/models/mixins/watch-notifications'),
  ],

  /**
    */

  apiEndpointAlias: 'team',

  /**
    */

  getMembers() {

    const members = TeamMembers.create({
      user: this.user,
      team: this,
      app: this.app,
      notifier: this.notifier,
    });

    members.load();

    return members;
  },

  /**
    */

  onDataChange(data) {

    const properties = {
      // might exist
      isHighMrrEnterprise: data.isHighMrrEnterprise,
      enterpriseProRateAmount: data.enterpriseProRateAmount,
      enterprisePrice: data.enterprisePrice,
      enterprisePeriod: data.enterprisePeriod,

      // Note that Infinity is proper since we can do conditional operations against it.
      // See addMember() method below (CC)
      maxSize: Number(data.maxTeamSize == void 0 ? Infinity : data.maxTeamSize),

      // minimum team size. Agreed amount when paying for seats (enterprise only)
      minSize: Number(data.minTeamSize || 0),

      // Note that the number of team members can exceed the total number of member objects in the app
      size: data.teamSize || (data.members || []).length,
    };

    const { isOwner, isManager, name } = data;

    if (typeof isOwner !== 'undefined') properties.isOwner = !!isOwner;
    if (typeof isManager !== 'undefined') properties.isManager = !!isManager;
    if (typeof name !== 'undefined') properties.name = name;

    this.setProperties(properties);
  },

  /**
    */

  toData() {
    return {
      name: this.name,
      isOwner: this.isOwner,
      isManager: this.isManager,
      isHighMrrEnterprise: this.isHighMrrEnterprise,
      enterpriseProRateAmount: this.enterpriseProRateAmount,
      enterprisePrice: this.enterprisePrice,
      enterprisePeriod: this.enterprisePeriod,
      maxTeamSize: this.maxSize,
      minTeamSize: this.minSize,
      teamSize: this.size,
    };
  },

  /**
     * notification incoming from some other part of the app
     */

  notify(message) {
    if (message.apiEndpointAlias === 'teamMembers') {
      if (message.type === 'remove') {
        this.setProperties({ size: this.size - 1 });
      } else if (message.type === 'insert') {
        this.setProperties({ size: this.size + 1 });
      } else if (message.type === 'update') {

        const tm = TeamMember.create({
          notifier: this.notifier,
          data: message.target.data,
        });

        if (tm.isUser(this.user)) {
          this.setProperties({ isOwner: tm.canModifyTeam() });
        }

      }
    }
  },

  /**
    */

  pastTeamMemberLimit() {
    return this.size >= (this.minSize || this.maxSize || 0);
  },

  /**
    */

  isEnterprise() {
    return (this.minSize !== void 0) && (this.maxSize === Infinity || this.maxSize === void 0);
  },

  /**
    */

  addMember(properties) {
    if (this.isEnterprise() && this.pastTeamMemberLimit()) {
      this.minSize++;
    }
    return TeamMember.create(Object.assign({ notifier: this.notifier }, properties)).save();
  },

  /**
    */

  importTeamMembers(file) {
    // do nothing for now - currently causes redirect
    return file.upload('/home/csvFileImport');
  },
});
