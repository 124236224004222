/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  no-script-url,
  react/no-find-dom-node,
  react/no-string-refs
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'common/utils/hf-react-helper';
import Button from 'common/components/button';
import setTimeout from 'common/utils/set-timeout';
import createReactClass from 'create-react-class';


/* -----------  LOGIN  ------------------------------------ */

const LogInVerificationForm = createReactClass({

  propTypes: {
    errorMsg: PropTypes.string,
    redirectUrl: PropTypes.string,
    tabOffset: PropTypes.number,
    emailAddress: PropTypes.string.isRequired,
    rememberMe: PropTypes.bool.isRequired,
    remember2FA: PropTypes.bool.isRequired,
    siteCode: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    phoneNumberLast2: PropTypes.string,
    isSmsEnabled: PropTypes.bool.isRequired,
    isAuthenticatorEnabled: PropTypes.bool.isRequired,
  },

  componentDidMount() {

    const self = this;
    const root = $(ReactDOM.findDOMNode(this));
    const codeInput = root.find('.m-login--verification--code input');

    setTimeout(() => {
      codeInput.focus();
    }, 500);

    $('.m-login--verification--links--modal')
      .overlay({
        mask: 'rgba(100, 100, 100, 0.65)',
        speed: 0,
        onClose() {
          self.hideHelp();
        },
      });
  },

  onSubmit() {
  },

  resendCode(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $('input[name=\'resend\']', root).val('1');
    $('#login_form', root).submit();
  },

  enterRecoveryCode(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--instructions', root).hide();
    $('.m-login--verification--recovery', root).show();
    $('.m-login--verification--code input', root).attr('placeholder', 'Enter recovery code');
    this.hideHelp();
  },

  contactSupport(evt) {
    evt.preventDefault();
    this.hideHelp();
    if (typeof zE === 'function') {
      zE(() => {
        zE.activate({ hideOnClose: true });
      });
    }
  },

  showHelp(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--links--modal', root).overlay().load();
    $('#problemLink', root).hide();
  },

  hideHelp() {
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--links--modal', root).overlay().close();
    $('#problemLink', root).show();
  },

  getPhoneNumberLast2Safe() {
    // Make sure we're only getting 2 characters. Since we're getting the prop from query params, it prevents text injection. (DEV-2313)
    return this.props.phoneNumberLast2 ? this.props.phoneNumberLast2.substr(0, 2) : null;
  },

  render() {

    const targetUrl = HfReactHelper.urlHelper('account/logInVerification', this.props.siteCode);
    const tabOffset = parseInt(this.props.tabOffset || 0, 10);
    const err = this.props.errorMsg ? (<p className='m-login--verification--error'>{ this.props.errorMsg }</p>) : null;

    const verificationInstructions = this.props.isAuthenticatorEnabled
      ? <span>Please enter the code from your 2-Factor Authentication application to log in.</span>
      : <span>We just sent you a text message to (***) ***-**{ this.getPhoneNumberLast2Safe() } with your code. Please enter the code to log in.</span>;

    return (
      <div className='m-login is-verification'>
        <form ref='login_form' className='m-login--form' id='login_form' action={targetUrl} method='POST'>
          <div>
            <div className='columns small-12'>
              <h3 className='m-login--verification--title'>2-step account verification</h3>
            </div>
            <p className='columns small-12 l-text-centered m-login--verification--instructions'>
              {verificationInstructions}
            </p>
            <p className='columns small-12 l-text-centered m-login--verification--recovery'>
                Enter the recovery code that was given to you when you enabled 2-Factor Authentication.&nbsp;
              <b>The code will become void and 2-Factor Authentication will be disabled.</b>
            </p>

            {this.props.phoneNumberLast2 ? <input type='hidden' name='verification[last2]' value={this.getPhoneNumberLast2Safe()} /> : ''}
            <input type='hidden' name='verification[email_address]' value={this.props.emailAddress} />
            <input type='hidden' name='verification[rm]' value={this.props.rememberMe ? '1' : '0'} />
            <input type='hidden' name='verification[redirect_url]' value={this.props.redirectUrl} />
            <input type='hidden' name='verification[signature]' value={this.props.signature} />
            <input type='hidden' name='verification[isSmsEnabled]' value={this.props.isSmsEnabled ? '1' : '0'} />
            <input type='hidden' name='verification[isAuthenticatorEnabled]' value={this.props.isAuthenticatorEnabled ? '1' : '0'} />
            <input type='hidden' name='resend' value='0' />

            <div className='m-login--verification--code'>
              <input type='text'
                name='verification[code]'
                className='m-login--text-input'
                placeholder='Enter code'
                maxLength='6'
                tabIndex={tabOffset} />
              { err }
            </div>

            <div className={'small-10 small-offset-1 text-center'}>
              <input type="checkbox"
                id="trust-device-checkbox"
                name='verification[remember2FA]'
                defaultChecked={this.props.remember2FA} />
              <label htmlFor="trust-device-checkbox">Remember this device for 30 days</label>
            </div>

            <div className={'small-10 small-offset-1 text-center'}>
              <Button onClick={this.onSubmit}
                type='submit'
                buttonClass=''
                buttonText='VERIFY'
                buttonColor='grasshopper'
                buttonHoverColor='castle-rock'
                buttonTabIndex={tabOffset + 1} />
            </div>

            <div className='m-login--verification--links'>
              <a href='javascript:;' id='problemLink' onClick={this.showHelp}>Problems with your code?</a>
              <div className='m-login--verification--links--modal form-overlay'>
                <div className='m-login--verification--links--modal--title'>Try one of these methods</div>
                {this.props.isSmsEnabled ? <a href='javascript:;' id='resendCodeLink' className='first small-4 column' onClick={this.resendCode}>{ this.props.isAuthenticatorEnabled ? 'Send' : 'Resend' } me the code</a> : ''}
                <a href='javascript:;' id='recoveryLink' className='middle small-4 column' onClick={this.enterRecoveryCode}>Use my recovery code</a>
                <a href='javascript:;' id='helpLink' className='last small-4 column' onClick={this.contactSupport}>Contact support for help</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  },
});

module.exports = LogInVerificationForm;
