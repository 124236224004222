/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  max-len,
  prefer-const,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';
import Pill from 'common/components/pill';
import './enterprise-agreement-pricing-summary.scss';
import Constants from './constants';

const periodShortNames = {
  month: 'mo',
  year: 'yr',
};
const cloneDate = (d) => new Date(d.getTime());
const isYearlyPlan = (periodName) => periodName === 'year';
const isPerpetualCoupon = (couponInfo) => couponInfo.duration === 9999;

function addMonths(amount, date) {
  const d = cloneDate(date);
  d.setMonth(d.getMonth() + amount);
  return d;
}

function addDays(count, date) {
  const d = cloneDate(date);
  d.setDate(d.getDate() + count);
  return d;
}

function subtractDays(count, date) {
  const d = cloneDate(date);
  d.setDate(d.getDate() - count);
  return d;
}

/**
 * Computes the date ranges for each scenario based on the defined scenarios.
 *
 * TODO: I'd like to try and find a cleaner way to write this section of code.
 */
function computeDateRanges(now, periodName, couponInfo, isFreeTrial, numFreeTrialDays) {
  let freeTrialStart;
  let freeTrialEnd;
  let freeTrialLength;
  let couponStart;
  let couponEnd;
  let listStart;

  // Pointer to the date that represents a day before the next range's start
  // date.  In each range, we compute the start date by adding one to this
  // pointer
  let nextEndDate = subtractDays(1, now);

  // Month offset is used to compute the coupon end date if applicable.
  let couponMonthOffset = 0;

  if (isFreeTrial) {

    freeTrialStart = addDays(1, nextEndDate);

    // For free trials, we're including the last day so we
    // subtract 1 day from numFreeTrialDays
    freeTrialLength = numFreeTrialDays - 1;

    freeTrialEnd = addDays(freeTrialLength, freeTrialStart);

    nextEndDate = freeTrialEnd;
  }

  if (couponInfo) {
    couponStart = addDays(1, nextEndDate);

    if (isYearlyPlan(periodName)) {
      couponMonthOffset = couponInfo.duration < 12 ? 12 : couponInfo.duration;
    } else {
      couponMonthOffset = couponInfo.duration;
    }

    couponEnd = subtractDays(1, addMonths(couponMonthOffset, couponStart));
    nextEndDate = couponEnd;
  }

  listStart = addDays(1, nextEndDate);

  return {
    freeTrialPrice: {
      start: freeTrialStart,
      end: freeTrialEnd,
    },
    couponPrice: {
      start: couponStart,
      end: couponEnd,
    },
    listPrice: {
      start: listStart,
    },
  };
}

const formatDate = (d) => fecha.format(d, 'MMM D, YYYY');
const dateRangeCopy = (from, to) => `${formatDate(from)} - ${formatDate(to)}`;


/**
 * Helper Component for displaying the rate
 */
function Rate(props) {
  const { rate } = props;
  const [dollars, cents] = String(rate).split('.');

  return <div className='m-ent-plan-pricing-summary-rate c-castle-rock'>
    <span className='m-ent-plan-pricing-summary-rate__currency'>$</span>
    <span className='m-ent-plan-pricing-summary-rate__amount'>
      { dollars }
      { cents
                && <span>
                    .{ cents }
                </span>
            }
    </span>
  </div>;
}

Rate.propTypes = {
  rate: PropTypes.string.isRequired,
  per: PropTypes.string,
};


/**
 * Helper component for displaying a pricing summary block.  Props here are
 * provided as elements or strings because they may differ depending on the
 * context.
 *
 */
function EnterprisePricingSummaryBlock(props) {
  const {
    label,
    copy,
    range,
    testRef,
    rate,
    per,
    isPartnerPlan,
  } = props;

  let pill;
  let rateBlock;
  if (!isPartnerPlan) {

    pill = (
      <div className='m-ent-plan-pricing-summary-block__pill'>
        <Pill velvet>{label}</Pill>
      </div>
    );

    rateBlock = (
      <div className='m-ent-plan-pricing-summary-block__rate' data-test-ref='rate'>
        {rate}
        {per && <span className='m-ent-plan-pricing-summary-block__per'>/{per}</span>}
      </div>
    );
  }

  return <div className={`m-ent-plan-pricing-summary-block border-1-warm-chinchilla c-castle-rock bg-plume${isPartnerPlan ? ' partner' : ''}`} data-test-ref={testRef}>
    { pill }
    { rateBlock }
    <div className='m-ent-plan-pricing-summary-block__copy' data-test-ref='copy'>{copy}</div>
    <div className='m-ent-plan-pricing-summary-block__range' data-test-ref='range'>{range}</div>
    <div className='clear-both'></div>
  </div>;
}

EnterprisePricingSummaryBlock.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  range: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  rate: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isPartnerPlan: PropTypes.bool,
  testRef: PropTypes.string,
};


/**
 * Displays the pricing info to the user for enterprise agreements.  There are a
 * lot of different rendering variations depending on the coupon, free trial,
 * and billing plan period.  Please see the tests for the different scenarios.
 */
export default function EnterpriseAgreementPricingSummary(props) {
  const {
    couponInfo,
    now = new Date(), // Helps for testing
    discountedRate,
    periodName,
    totalRate,
    isFreeTrial,
    numFreeTrialDays,
    billingType,
  } = props;

  const { freeTrialPrice, couponPrice, listPrice } = computeDateRanges(now, periodName, couponInfo, isFreeTrial, numFreeTrialDays);
  const shouldHideListPrice = couponInfo && isPerpetualCoupon(couponInfo);
  const isPartnerPlan = (billingType === Constants.BILLING_TYPE_ALTERNATIVE_CHARGE_PARTNER);

  return <div className='m-ent-plan-pricing-summary'>

    {/* Free Trial Price */}
    {!isPartnerPlan && isFreeTrial && <EnterprisePricingSummaryBlock
      copy={`For First ${numFreeTrialDays} Days`}
      label='Free Trial'
      range={dateRangeCopy(freeTrialPrice.start, freeTrialPrice.end)}
      rate={<Rate rate='0' />}
      isPartnerPlan={isPartnerPlan}
      testRef='free-trial-price' />}

    {/* Discount Price */}
    {!isPartnerPlan && couponInfo && <EnterprisePricingSummaryBlock
      copy={<span className='c-azure'>{`${isYearlyPlan(periodName)
        ? couponInfo.annual_percentage
        : couponInfo.percentage}% off`}
      </span>}
      label='Special Offer'
      per={isYearlyPlan(periodName) && !isPerpetualCoupon(couponInfo)
        ? ''
        : periodShortNames[periodName]}
      range={isPerpetualCoupon(couponInfo)
        ? `Starting ${fecha.format(couponPrice.start, 'MMM D, YYYY')}`
        : dateRangeCopy(couponPrice.start, couponPrice.end)}
      rate={<Rate rate={discountedRate} />}
      isPartnerPlan={isPartnerPlan}
      testRef='coupon-price' />}

    {/* List Price */}
    {!shouldHideListPrice && <EnterprisePricingSummaryBlock
      label='List Price'
      per={periodShortNames[periodName]}
      range={`Starting ${fecha.format(listPrice.start, 'MMM D, YYYY')}`}
      rate={<Rate rate={totalRate} />}
      isPartnerPlan={isPartnerPlan}
      testRef='list-price' />}

  </div>;
}

EnterpriseAgreementPricingSummary.propTypes = {
  couponInfo: PropTypes.shape({
    duration: PropTypes.number,
  }),
  isFreeTrial: PropTypes.bool,
  numFreeTrialDays: PropTypes.number,

  /**
     * This is includced as a prop for ease
     * of testing, but it will default to the current date for the general case.
     */
  now: PropTypes.instanceOf(Date),
  periodName: PropTypes.oneOf(['month', 'year']).isRequired,
  totalRate: PropTypes.string.isRequired,
  billingType: PropTypes.oneOf(['S', 'AI', 'AP', 'AM']),
  billingMethod: PropTypes.oneOf(['CC', 'IN']),
  discountedRate: PropTypes.string,
};
