/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  max-len,
  no-use-before-define,
  no-var,
  vars-on-top
*/
import './index.scss';
import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import pushGoogleTagManagerEvent from 'common/utils/pushGoogleTagManagerEvent';
import HfReactHelper from 'common/utils/hf-react-helper';
import PricingLevelFeature from '../pricing-level-feature';
import ContactUsButton from '../contact-us-button';
import MailToButton from '../mail-to-button';

const CorporatePricingLevel = createReactClass({

  getInitialState() {
    return { expanded: false };
  },

  getDefaultProps() {
    return {
      corpLevelName: 'Enterprise',
      changePlanRestricted: false,
    };
  },

  onLevelClick() {
    this.setState({ expanded: !this.state.expanded });
  },

  onContactSuccess() {
    pushGoogleTagManagerEvent({
      category: 'contactus-form-complete',
      action: window.location.pathname,
      label: '/thankyou',
    });
  },

  render() {
    const bodyClasses = classNames({
      'm-pricing-level--body': true,
      'small-12': true,
      columns: true,
      expanded: this.state.expanded,
    });
    const levelClasses = classNames({
      'small-6': true,
      'medium-4': true,
      'medium-offset-1': true,
      'large-12': true,
      'large-offset-0': true,
      columns: true,
      'level-title': true,
    });
    const arrowContainerClasses = classNames({
      'arrow-container': true,
      'hide-for-large-up': true,
      'hide-for-small': true,
      'small-2': true,
      'medium-1': true,
      columns: true,
    });
    const arrowClasses = classNames({
      'l-vertical-align': true,
      arrow: true,
      expanded: this.state.expanded,
    });
    const contactUsClasses = classNames({
      'phone-container': true,
      'hide-for-large-up': true,
      'small-6': true,
      'medium-5': true,
      'medium-offset-1': true,
      columns: true,
      'level-price': true,
    });
    const lookingForMoreClasses = classNames({
      'm-pricing-level--body': true,
      'small-10': true,
      'small-centered': true,
      columns: true,
      'looking-for-more': true,
      'l-padding-t-50': (this.props.coupon && this.props.coupon !== null),
    });

    let mainFeature = '';
    if (this.props.corporateFeatures && this.props.corporateFeatures.main_feature) {
      var name = (this.props.corporateFeatures.main_feature.name ? this.props.corporateFeatures.main_feature.name : name);
      const detail = (this.props.corporateFeatures.main_feature.detail ? this.props.corporateFeatures.main_feature.detail : null);
      const number = (this.props.corporateFeatures.main_feature.number ? this.props.corporateFeatures.main_feature.number : null);
      mainFeature = (<PricingLevelFeature name={name} planName={this.props.name} detail={detail} number={number} key={'main-feature'} expanded={this.state.expanded}></PricingLevelFeature>);
    }

    let features = '';
    if (this.props.corporateFeatures && this.props.corporateFeatures.features) {
      features = this.props.corporateFeatures.features.map((feature, i) => {
        var name = (feature.name ? feature.name : name);
        const detail = (feature.detail ? feature.detail : null);
        const number = (feature.number ? feature.number : null);
        return (
          <PricingLevelFeature name={name} planName={this.props.name} detail={detail} number={number} key={`feature${i}`} expanded={this.state.expanded}></PricingLevelFeature>
        );
      });
    }

    const corpLevelColumnClasses = classNames({
      'm-pricing-level': true,
      'small-12': true,
      'medium-12': true,
      'large-3': this.props.totalColumns >= 3,
      'large-4': this.props.totalColumns == 2,
      'large-6': this.props.totalColumns == 1,
      columns: true,
      end: true,
      'corporate-pricing-level': true,
    });

    let contactButton = {};
    if (this.props.changePlanRestricted) {
      const supportEmail = HfReactHelper.HfConstants.supportEmail[HfReactHelper.HfSites.SITE_CODE_HS];
      contactButton = (<MailToButton email={supportEmail} subject={'Alt Billing Plan Inquiry'} />);
    } else {
      contactButton = (<ContactUsButton
        isApi={this.props.isApi}
        timePeriod={this.state.timePeriod}
        pricingSource={this.props.pricingSource}
        onClick={(e) => e.stopPropagation()}
        onSuccess={this.onContactSuccess}
        buttonAttrs={
          {
            'da-category': 'contactus-form-start',
            'da-action': window.location.pathname,
            'da-label': (this.props.isApi
              ? '/enterprise-api-contact-us'
              : '/enterprise-lp-contact-us'
            ),
          }
        } />);
    }

    return (
      <div className={corpLevelColumnClasses}>
        <div className='m-pricing-level--header small-12 columns block' onClick={this.onLevelClick}>
          <div className={levelClasses}>
            <div className='level-title l-vertical-align-wrapper'>
              <div className='l-vertical-align'>{ this.props.corpLevelName.toUpperCase() }</div>
            </div>
          </div>
          <div className={contactUsClasses}>
            <div className='phone-container l-vertical-align-wrapper'>
              {!this.state.expanded && contactButton}
            </div>
          </div>
          <div className={arrowContainerClasses}>
            <div className='level-title l-vertical-align-wrapper'>
              <div className={arrowClasses}>&nbsp;</div>
            </div>
          </div>
        </div>
        <div className={bodyClasses}>
          <div className='first-section block'>
            <div className={lookingForMoreClasses}>
                        Looking for more?
            </div>
            <div className='block'>
              {contactButton}
            </div>
            { mainFeature }
          </div>
        </div>
        {features}
      </div>
    );
  },
});

module.exports = CorporatePricingLevel;
