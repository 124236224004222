/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { combineForms, actions } from 'react-redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import SimpleGrayLayout from 'common/components/simple-gray-layout';
import SignUpForm from 'hellosign/components/auth/sign-up-form';
import './sign-up-page.scss';
import StyleProvider from 'hello-ui/style';
import SplitSignupLayout from './split-signup-layout';
import CountriesDropdown from '../../../common/components/countries-dropdown';

const store = createStore(
  combineForms({
    signUp: {
      email: '',
      marketingOptIn: false,
      csrfToken: '',
      redirectUrl: '',
      pendingTsmGroupGuid: '',
      recaptchaPublicKey: '',
      includeInvisibleCaptcha: '',
    },
  }),
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
);

/**
 * Top-level component for standalone Sign Up page.
 * Expects some props from the backend, such as CSRF token.
 */
class SignUpPage extends React.Component {
    static propTypes = {
      // CSRF Token - Sent from the server
      csrfToken: PropTypes.string.isRequired,

      siteCode: PropTypes.string.isRequired,

      // Email value - This is useful to provide for testing purposes
      emailAddress: PropTypes.string,

      // Needed for the Invisible ReCaptcha
      recaptchaPublicKey: PropTypes.string.isRequired,
      // Decides whether the CAPTCHA should be displayed initially
      includeInvisibleCaptcha: PropTypes.bool,

      redirectUrl: PropTypes.string,
      pendingTsmGroupGuid: PropTypes.string,

      // Used if this is a team invite signup (note: if provided, emailAddress is also required)
      teamInviteGuid: PropTypes.string,
      teamInviteSenderEmail: PropTypes.string,

      // 2 char country code that should be used when rendering this page
      initialCountryCode: PropTypes.string,

      // indicates where the sign up is coming from
      signupSource: PropTypes.string,
    };

    constructor(props) {
      super(props);
      this.onCountryCodeChange = this.onCountryCodeChange.bind(this);

      // This initial state is for Redux
      const initialState = {
        email: this.props.emailAddress,
        csrfToken: this.props.csrfToken,
        redirectUrl: this.props.redirectUrl,
        pendingTsmGroupGuid: this.props.pendingTsmGroupGuid,
        recaptchaPublicKey: this.props.recaptchaPublicKey,
        includeInvisibleCaptcha: this.props.includeInvisibleCaptcha,
        countryCode: this.props.initialCountryCode,
        marketingOptIn: false,
      };

      // This is a hack for OAuth flows and ensures that we redirect
      // back to the referrer when the user signs up. This fixes an issue
      // where new users would be taken out of the OAuth flow when attempting
      // to create a new account (DEV-2687)
      const ref = document.referrer;
      if (ref.indexOf('oauth') > 0) {
        initialState.redirectUrl = ref;
      }

      store.dispatch(actions.change('signUp', initialState));

      // This is for local state
      this.state = {
        countryCode: this.props.initialCountryCode,
      };
    }

    componentDidMount() {
      // Set defaults based on initial state of country code
      this.onCountryCodeChange(this.state.countryCode);
    }

    /**
     * Set opt-ins based on the user's country
     */
    onCountryCodeChange(countryCode) {
      if (countryCode !== undefined) {
        const implicitOptIn = countryCode === 'US';
        store.dispatch(actions.change('signUp.marketingOptIn', implicitOptIn));
        store.dispatch(actions.change('signUp.implicitlyOptedIn', implicitOptIn));
        store.dispatch(actions.change('signUp.countryCode', countryCode));
        this.setState({ countryCode });
      }
    }

    getLayout() {
      let layout = SplitSignupLayout;

      if (HfReactHelper.HfSites.isHelloSign(this.props.siteCode) || this.props.signupSource) {
        layout = SplitSignupLayout;
      } else {
        layout = SimpleGrayLayout;
      }
      return layout;
    }

    getSignInLink() {
      if (HfReactHelper.HfSites.isHelloSign(this.props.siteCode) || this.props.signupSource) {
        // When on HelloSign or if signupSource defined, this link is inside the sign up form
        return;
      }
      const {
        teamInviteGuid,
        logInUrl,
      } = this.props;
      const isInvite = !!teamInviteGuid;
      const signInLink = !isInvite
            && logInUrl
            && <div className="m-sign-up-page--sign-in-link">
              <p>Already have an account? <a href={logInUrl}>Sign in ›</a></p>
            </div>;
      return signInLink;
    }

    render() {
      const LayoutTag = this.getLayout();

      return (
        <Provider store={store}>
          <StyleProvider>
            <div>
              <LayoutTag signupSource={this.props.signupSource}
                siteCode={this.props.siteCode}
              >
                <div className="m-sign-up-page--countries-dropdown">
                  <CountriesDropdown selectedCountryCode={this.state.countryCode}
                    onCountryCodeChange={this.onCountryCodeChange} />
                </div>
                <SignUpForm {...this.props} />
              </LayoutTag>
              {this.getSignInLink()}
            </div>
          </StyleProvider>
        </Provider>
      );
    }
}

module.exports = SignUpPage;
