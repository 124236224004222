
// These should eventually be put in a React.Intl translations file,
// when we have frontend app support
export const cancelStrings = {
  legalText: {
    HS: {
      head: 'By canceling, you will retain access to business features through the remainder of the period already paid. When this expires, you will lose access to these features. You will not receive a free trial if you choose to sign up for a new subscription. View our ',
      link: 'Terms of Service',
      tail: ' for more details. Also, we\'ll have to stop providing our employees with free cookies.',
    },
    HF: {
      head: 'By canceling, you will retain access to your paid features for the remainder of the period already paid. When this expires, you will lose your fax number(s), the ability to receive faxes, and your monthly allotment of fax pages. You will not receive a free trial if you choose to sign up for a new subscription. View our ',
      link: 'Terms of Service',
      tail: 'for more details. Also, we\'ll have to stop providing our employees with free cookies.',
    },
  },
  cancelReasons: {
    cancel_reason_no_use: 'Not using this service frequently',
    cancel_reason_ux: 'Service was hard to use',
    cancel_reason_customer_service: 'Unhappy with the customer service I received',
    cancel_reason_cost: 'The cost of this service',
    cancel_reason_features: 'The service did not have the features I need',
    cancel_reason_competitor: 'I started using a different service',
  },
  competitorReasons: {
    competitor_reason_ux: 'Their tool was easier to use',
    competitor_reason_customer_service: 'Better customer service',
    competitor_reason_features: 'More features',
    competitor_reason_cost: 'Better price',
    competitor_reason_quality: 'Better quality',
  },
  placeholders: {
    'cancel_reason_features-detail': 'What was missing?',
  },
  teamAdminWarning: 'Canceling your subscription will also disband your team and shared access to documents will be revoked.',
  errors: {
    noSelection: 'Please select at least one option',
    messageTooShort: 'Please enter a brief message for support',
  },
};

export const cancelConstants = {
  competitorList: [
    '17 Hats',
    'Adobe EchoSign',
    'AssureSign',
    'Cudasign',
    'DocuSign',
    'DotLoop',
    'eSignLive',
    'Legalesign',
    'Other',
    'PandaDoc',
    'RightSignature',
    'Sertifi',
    'Silanis',
  ],
  AUTOCOMPLETE_MIN_THRESHOLD: 2,
  SUPPORT_MESSAGE_MIN_THRESHOLD: 3,
};
