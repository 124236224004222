/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  react/no-unescaped-entities
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { combineForms } from 'react-redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import HfReactHelper from 'common/utils/hf-react-helper';
import Logo from 'common/components/logo';
import './log-in-page.scss';
import helloSvg from 'common/images/hello.svg';
import promoGraphic from 'common/images/g2_crowd_smallbiz_2016.png';
import LogInForm from './log-in-form';

/**
 * Top-level component for standalone Log In page.
 * Expects some props from the backend, such as CSRF token.
 */
class LogInPage extends React.Component {
    static propTypes = {
      // CSRF Token - Sent from the server
      csrfToken: PropTypes.string.isRequired,

      // send from server via Rollout
      dropboxSSO: PropTypes.bool,

      siteCode: PropTypes.string.isRequired,

      // Email value - This is useful to provide for testing purposes
      emailAddress: PropTypes.string,

      // URL used to submit the form via POST
      submitUrl: PropTypes.string.isRequired,

      // URL used to check if account is SAML enabled
      samlCheckUrl: PropTypes.string.isRequired,

      // Indicates SAML login
      samlLogin: PropTypes.bool,

      // Needed by the LogInCaptcha component
      recaptchaPublicKey: PropTypes.string.isRequired,

      // Needed by the GoogleSignInButton component
      googleSignInClientId: PropTypes.string.isRequired,

      redirectUrl: PropTypes.string,
      pendingTsmGroupGuid: PropTypes.string,

      // URLs displayed to the user as links for Privacy Policy
      privacyPolicyUrl: PropTypes.string.isRequired,

      signupUrl: PropTypes.string,
    };

    constructor(props) {
      super(props);

      const initialState = {
        emailAddress: this.props.emailAddress,
        password: '',
        csrfToken: this.props.csrfToken,
        dropboxSSO: this.props.dropboxSSO,
        rememberMe: false,
        samlLogin: null,
        pendingTsmGroupGuid: this.props.pendingTsmGroupGuid,
        redirectUrl: this.props.redirectUrl,
      };

      this.store = createStore(
        combineForms({
          logIn: initialState,
        }),
        composeWithDevTools(
          applyMiddleware(thunk),
        ),
      );
    }

    render() {
      const signupUrl = this.props.signupUrl || '/account/signUp';

      const externalHomepageUrl = HfReactHelper.urlHelper('', null, 'www');
      const hellosignHomepage = HfReactHelper.urlHelper(
        '',
        HfReactHelper.HfSites.SITE_CODE_HS,
        'www',
      );

      const a = (msg) => (<a className="m-log-in--sign-up-link" href={signupUrl}>{msg}</a>);
      const a1 = (msg) => (<a className="m-log-in--sign-up-link" data-qa-ref="privacy-policy-link-login-page" href={this.props.privacyPolicyUrl}>{msg}</a>);

      hellofaxJS.trackGAPageViewEvent('accountLogin');

      const isHelloFax = this.props.siteCode === 'F';
      const crossPromo = isHelloFax
            && <div>
              <p className="l-margin-v-20">
                <FormattedMessage id='' defaultMessage='HelloFax users also love our eSignature solution, HelloSign!' description='on login page' />
              </p>
              <p className="l-margin-v-20">
                <a href={hellosignHomepage} style={{ marginLeft: '0' }}>
                  <b><FormattedMessage id='' defaultMessage='Explore Now' description='on login page' /></b>
                </a>
              </p>
            </div>;

      return (
        <Provider store={this.store}>
          <div className="m-log-in">
            <div className="row l-padding-b-30 l-padding-t-40">
              <div className="small-12 columns">
                <Logo
                  siteCode={this.props.siteCode}
                  isLink
                  href={externalHomepageUrl} />
              </div>
            </div>
            <div className="row m-log-in--greeting l-margin-v-35">
              <div className="columns push-6 medium-6 text-centered m-log-in--right">
                <img className="l-img-centered" src={helloSvg} />
              </div>
            </div>
            <div className="row">
              <div className="columns medium-6 m-log-in--left">
                <div>
                  {crossPromo}
                  <p className="l-margin-v-20" data-qa-ref="hello-sign-small-business-crowd">
                    <FormattedMessage id='' defaultMessage='HelloSign Ranked #1 eSignature Solution for Small Business in G2 Crowd' description='on login page' />
                  </p>
                  <img
                    className="l-margin-v-20"
                    src={promoGraphic} />
                </div>
              </div>
              <div className="columns medium-6 small-12 m-log-in--right">
                <LogInForm {...this.props} />

                <hr className="l-margin-t-40 l-margin-b-15" />

                <div className="l-text-centered c-ashen m-log-in--sign-up-copy l-margin-b-15">
                  <FormattedMessage id='' defaultMessage="Don't have an account? <a>Sign up now ›</a>" description=' account logIn' values={{ a }} />
                </div>
                <div className="l-text-centered c-ashen m-log-in--sign-up-copy">
                  <FormattedMessage id='' defaultMessage="Please read our <a1>Privacy Policy</a1>" description=' account logIn' values={{ a1 }} />
                </div>
              </div>
            </div>
          </div>
        </Provider>
      );
    }
}

module.exports = LogInPage;
