/* global prettyPrint */

import React from 'react';

import Button from 'common/components/button';
import { withAppContext } from '../../contexts/app';

import './index.scss';

class Code extends React.PureComponent {

  codeExampleRef = React.createRef();

  componentDidUpdate() {
    this.updateSyntaxHighlighting();
  }

  updateSyntaxHighlighting() {
    const codeExampleEl = this.codeExampleRef.current;

    if (codeExampleEl) {
      try {
        prettyPrint(codeExampleEl);
      } catch (err) {
        // This throws sometimes and window.prettyPrint is
        // unreliable. Let's catch any errors here so that
        // we don't rerail the entire application if
        // prettyPrint fails.
      }
    }
  }

  renderEmbeddedCode() {
    const {
      allowCancel,
      clientID,
      hideHeader,
      locale,
      redirectURL,
      requestingEmail,
      signURL,
      testMode,
      timeout,
      whiteLabelingCode,
    } = this.props.appContext.state.form;

    const code = [];

    code.push('import HelloSign from "hellosign-embedded";\n\n');

    if (clientID.length) {
      code.push(`const client = new HelloSign({\n  clientId: ${JSON.stringify(clientID)}\n});\n\n`);

      if (signURL.length) {
        const options = [];

        code.push(`client.open(${JSON.stringify(signURL)}`);

        if (!allowCancel) {
          options.push('  allowCancel: false');
        }

        if (hideHeader) {
          options.push('  hideHeader: true');
        }

        if (locale.length && locale !== 'en_US') {
          options.push(`  locale: ${JSON.stringify(locale)}`);
        }

        if (redirectURL.length) {
          options.push(`  redirectUrl: ${JSON.stringify(redirectURL)}`);
        }

        if (requestingEmail.length) {
          options.push(`  requestingEmail: ${JSON.stringify(requestingEmail)}`);
        }

        if (testMode) {
          options.push('  testMode: true');
        }

        if (timeout !== 30000) {
          options.push(`  timeout: ${JSON.stringify(timeout)}`);
        }

        if (whiteLabelingCode.length) {
          try {
            const obj = JSON.parse(whiteLabelingCode);

            options.push(`  whiteLabeling: ${JSON.stringify(obj, null, 2).replace(/\n/g, '\n  ')}`);
          } catch (err) {
            // Shh is ok bby
          }
        }

        if (options.length) {
          code.push(', {\n');
          code.push(`${options.join(',\n')}\n`);
          code.push('});');
        } else {
          code.push(');');
        }
      } else {
        code.push('// Waiting for embedded URL...');
      }
    } else {
      code.push('// Waiting for client ID...');
    }

    return code.join('');
  }

  render() {
    const { clientID, signURL } = this.props.appContext.state.form;

    return (
      <div className="embedded-testing-tool__code">
        <div className="m-api-docs-section no-separator">
          <div className="m-code-example">
            <pre ref={this.codeExampleRef} className="embedded-testing-tool__code-example m-code-example--code prettyprint">
              {this.renderEmbeddedCode()}
            </pre>
          </div>
          {clientID.length && signURL.length ? (
            <Button
              buttonText="Launch Embeddeded"
              buttonColor="cerulean"
              buttonHoverColor="black"
              buttonHoverTextColor="white"
              type="submit"
              marginless={true}
              onClick={this.props.launchEmbedded} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withAppContext(Code);
