/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs
*/
import React from 'react';
import PropTypes from 'prop-types';
import pushGoogleTagManagerEvent from 'common/utils/pushGoogleTagManagerEvent';
import SimpleGrayLayout from '../../../common/components/simple-gray-layout';
import EmailLink from '../../../common/components/email-link';
import HfReactHelper from '../../../common/utils/hf-react-helper';

class ConfirmRequiredPage extends React.Component {
    static propTypes = {
      siteCode: PropTypes.string.isRequired,
    };

    componentDidMount() {
      pushGoogleTagManagerEvent({
        category: 'signup',
        action: 'check-email - hw',
        label: 'create an account',
      });
    }

    render() {
      const site = HfReactHelper.HfSites.getSite(this.props.siteCode);

      return (
        <SimpleGrayLayout siteCode={this.props.siteCode}>
          <h2>Check your inbox</h2>
          <p>If that is a valid address, we have sent an email to it to set your password.</p>
          <hr />
          <p>
            If you&#39;re still experiencing difficulties, please contact us at&nbsp;
            <EmailLink email={HfReactHelper.HfConstants.supportEmail[site]} emailSubject='Email Confirmation' />.
          </p>
        </SimpleGrayLayout>
      );
    }
}

module.exports = ConfirmRequiredPage;
