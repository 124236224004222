/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  no-use-before-define,
  no-void
*/
import qs from 'qs';
import extend from 'lodash/object/extend';

module.exports = function (operation) {


  return extend(operation, {

    // is multipart form data
    form: true,

    // cache buster
    query: qs.stringify(extend({ c: Date.now() }, operation.query || {}), { arrayFormat: 'brackets' }),

    // multipart form data
    data: operation.data && typeof operation.data === 'object' ? qs.stringify(extend({}, operation.data, { csrf_token: getCSRFToken(operation.data || {}) }), { arrayFormat: 'brackets' }) : operation.data,

    // must return an object - for now - do not return anything from API
    transform: operation.transform || function (ret) {
      return ret ? ret[0] || ret : void 0;
    },
    headers: {
      accept: 'application/json, text/javascript, */*',
    },
  });
};


function getCSRFToken(data) {

  // Grab from model first...
  if (data && data.csrf_token) {
    return data.csrf_token;
  }

  // And fall back to global if absernt
  const csrfTag = Array.prototype.filter.call(document.head.childNodes, (tag) => {
    return tag.nodeName === 'META' && tag.getAttribute('name') === 'csrf-token';
  }).pop();

  if (csrfTag) return csrfTag.getAttribute('content');
}
