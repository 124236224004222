/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
*/
import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from './constants';

export const SignatureType = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
});

export const Type = PropTypes.oneOf([
  CONSTANTS.TYPE_SIGNATURE,
  CONSTANTS.TYPE_INITIALS,
]);

export const DeviceOrientation = PropTypes.oneOf([
  CONSTANTS.ORIENTATION_PORTRAIT,
  CONSTANTS.ORIENTATION_LANDSCAPE,
]);

export const ScreenSize = PropTypes.oneOf([
  CONSTANTS.SCREEN_SMALL,
  CONSTANTS.SCREEN_MEDIUM,
  CONSTANTS.SCREEN_LARGE,
]);

export const Tab = PropTypes.oneOf(CONSTANTS.TABS);

export const ActionBar = {

  // Text to be displayed on the CTA (only when canInsertEverywhere=false)
  buttonText: PropTypes.string,
  canInsertEverywhere: PropTypes.bool,
  continueInsteadOfInsert: PropTypes.bool,
  disableButtons: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
  onInsert: PropTypes.func,
  type: Type,

  // If provided, this indicates that the action bar should be absolutely
  // positioned starting from this point.  If it's not provided, then it
  // will be absolutely positioned at the bottom of the screen
  topOffset: PropTypes.number,
  isEmbedded: PropTypes.bool,
  deviceOrientation: DeviceOrientation,
};

export const OnSignatureData = PropTypes.func;

export const GetSignatureUrl = PropTypes.func.isRequired;

export const CommonTabProps = {
  actionBarProps: PropTypes.shape(ActionBar),
  buttonText: PropTypes.string,
  canInsertEverywhere: PropTypes.bool,

  // Provided to the Tab components so that they can report their
  // content to this component.  The tabs should apply this as a ref
  // callback on the div that wraps the content, e.g.
  // <div ref={this.props.captureTabContentRef}>
  captureTabContentRef: PropTypes.func,
  deviceOrientation: DeviceOrientation,
  emailGuid: PropTypes.string,
  emailUploadAddress: PropTypes.string,
  intl: PropTypes.object,
  isMobile: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  screenSize: ScreenSize,
  type: Type,
  topOffset: PropTypes.number,
};
