/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './pill.scss';

/**
 * Pill component
 *
 * @example
 * <Pill>Some Pill</Pill>
 *
 * @example
 * <Pill velvet>Some Velvet Pill</Pill>
 */
export function Pill(props) {
  const { children } = props;
  const className = classnames(
    'pill',
    { 'bg-velvet-evening c-white': props.velvet },
  );

  return <span className={className}>{children}</span>;
}

Pill.propTypes = {
  children: PropTypes.string.isRequired,
  velvet: PropTypes.bool,
};

export default Pill;
