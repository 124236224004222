/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  no-useless-escape
*/
const hsHost = process.env.HS_DOMAIN;
const hfHost = process.env.HF_DOMAIN;
const cdnHost = process.env.CDN_URL;

// find something like dev-hellosign
let prefix = hsHost.match(/(\w+-)\w+.com/);
if (prefix) prefix = prefix[1];

const subdomain = hsHost.match(/^(\w+)/)[1];

module.exports = {
  hfCdn: cdnHost,
  hsCdn: cdnHost,
  defaultSubdomain: subdomain,
  urlHelperUrl: `\/\/__subdomain__.${prefix || ''}__site__.com`,
  HfSites: {
    SITE_CODE_HF: 'hellofax',
    SITE_CODE_HS: 'hellosign',
    SITE_CODE_HW: 'helloworks',
    SITE_CODES: [
      'F',
      'S',
      'W',
    ],
  },
  HfConstants: {
    csrfFieldName: '_csrf_token',
    supportPhoneNumber: '(415) 766-0273',
    supportEmail: {
      hellosign: 'support@hellosign.com',
      hellofax: 'support@hellofax.com',
      helloworks: 'hwsupport@hellosign.com',
    },
    team_fax_lines: {
      STATUS_CODES: {
        STATUS_CODE_ACTIVE: 'A',
        STATUS_CODE_PORTING_DENIED: 'D',
        STATUS_CODE_PORTING_LOA_COMPLETED: '4',
        STATUS_CODE_PORTING_ACCEPTED: '2',
        STATUS_CODE_PORTING_STARTED: '1',
      },
      URLS: {
        cancelPortingRequestUrl: `\/\/${hfHost}\/content\/cancelPortingRequestUrl`,
        removeFaxLineUrl: `\/\/${hfHost}\/content\/removeFaxLine`,
        updateFaxLinePermissionsUrl: `\/\/${hfHost}\/home\/updateFaxLinePermissions`,
        checkPortabilityStatusUrl: `\/\/${hfHost}\/content\/checkPortabilityStatus`,
        testUrl: `\/\/${hfHost}\/home\/manage`,
        spinnerUrl: `\/\/${cdnHost}\/images\/loading-spinner-16.gif`,
      },
    },
    URLS: {
      cancelPortingRequestUrl: `\/\/${hfHost}\/content\/cancelPortingRequestUrl`,
      removeFaxLineUrl: `\/\/${hfHost}\/content\/removeFaxLine`,
      updateFaxLinePermissionsUrl: `\/\/${hfHost}\/home\/updateFaxLinePermissions`,
      checkPortabilityStatusUrl: `\/\/${hfHost}\/content\/checkPortabilityStatus`,
      testUrl: `\/\/${hfHost}\/home\/manage`,
      spinnerUrl: `\/\/${cdnHost}\/\/images\/loading-spinner-16.gif`,
    },
    api_pricing: {
      URLS: {
        contactUsUrl: 'mailto:apisupport@hellosign.com',
        salesUrl: 'mailto:sales@hellosign.com',
      },
    },
    enterpriseFeatures: {
      HIPAA: 'HIPAA',
    },
  },
};
