/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-alert,
  no-restricted-syntax,
  no-tabs,
  no-void
*/
import React from 'react';
import PropTypes from 'prop-types';
import logger from 'common/logger';
import createReactClass from 'create-react-class';
import Button from './button';
import ExternalServiceTypes from '../constants/ExternalServiceTypes';

const ExternalServiceUpdateURL = '/account/updateExternalService';
const MyAccountIntegrationsHomeURL = '/home/myAccount?current_tab=integrations';

const IntegrationActivationButton = createReactClass({

  propTypes: {
    isActive: PropTypes.bool.isRequired,
    serviceTypeCode: PropTypes.string,
    buttonLink: PropTypes.string,
  },

  getDefaultProps() {
    return {
      buttonLink: '#',
    };
  },

  // Original logic moved from myAccount.php
  clickHandler(event) {
    event.stopPropagation();
    event.preventDefault();

    const el = $(event.target).closest('div');
    const isActivated = el.hasClass('is-activated');
    const textContainer = $('button .l-nowrap > span', el);
    const text = $.trim(textContainer.text());
    const servTypeCode = $(' > a button', el).attr('rel');

    const postData = {
      service_type: servTypeCode,
      is_deactivate: isActivated ? '1' : '0',
      csrf_token: $('#update_ext_serv_csrf_token').val(),
    };

    // If activating Google Drive
    if (!isActivated && servTypeCode == ExternalServiceTypes.GOOGLE_DRIVE) {
      if ($.browser.msie && parseInt($.browser.version, 10) < 8) {
        $.fancybox('<div class="browser_warning"><strong>This option requires a more up to date browser</strong><p>Download the latest <a href="http://windows.microsoft.com/en-US/internet-explorer/downloads/ie" target="_blank" rel="noopener noreferrer">Microsoft Internet Explorer</a> or <a href="http://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></p></div>', { padding: 20 });
        return;
      }
    }

    const showAsBusy = function (isBusy) {
      textContainer.html(isBusy ? 'Activating...' : text);
    };

    showAsBusy(true);

    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: ExternalServiceUpdateURL,
      data: postData,
      success(data) {

        let integration;
        for (const key in ExternalServiceTypes) {
          if (ExternalServiceTypes[key] === postData.service_type) {
            integration = key;
          }
        }
        const eventType = postData.is_deactivate == 0 ? 'activate' : 'deactivate';
        logger.track(`IntegrationActivationButton._${eventType}`, {
          integration,
        });

        showAsBusy(false);
        if (data.auth_url) {
          window.location = data.auth_url.replace(/&amp;/g, '&');	// FIXME: Needed because it gets returned HTML-encoded
        } else {
          window.location = MyAccountIntegrationsHomeURL;
        }
      },
      error() {
        showAsBusy(false);
        alert('An error occurred, try again or contact support at support@hellosign.com'); // FIXME - get email address dynamically, and localize text (DB)
      },
    });
  },

  render() {
    const hasCustomButtonLink = (this.props.buttonLink !== '#');
    const btn = (
      <Button buttonText={this.props.isActive ? 'Deactivate' : 'Activate'}
        buttonLink={hasCustomButtonLink ? this.props.buttonLink : void 0}
        buttonClass='border-1-grasshopper'
        buttonColor={this.props.isActive ? 'grasshopper' : 'white'}
        buttonTextColor={this.props.isActive ? 'white' : 'grasshopper'}
        buttonHoverTextColor='white'
        buttonHoverColor='grasshopper'
        openInNewWindow={true}
        buttonAttrs={this.props.serviceTypeCode ? { rel: this.props.serviceTypeCode } : null}
        buttonLinkUsePost={false}
        onClick={hasCustomButtonLink ? void 0 : this.clickHandler} />
    );

    return btn;
  },
});

module.exports = IntegrationActivationButton;
