/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  default-case,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-console,
  no-return-assign,
  react/no-unescaped-entities
*/
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'common/components/forms';
import {
  actions, Errors, Control, Form,
} from 'react-redux-form';
import HfReactHelper from 'common/utils/hf-react-helper';
import { stringify } from 'qs';
import superagent from 'superagent';
import { connect } from 'react-redux';
import Button from 'common/components/button';
import Recaptcha from 'react-google-invisible-recaptcha';
import OrDivider from 'common/components/or-divider';
import helloSvg from 'common/images/hello.svg';
import GoogleSignInButton from './google-signin-button';
import DropboxSignInButton from './dropbox-signin-button';
import './sign-up-form.scss';

const FORM_NAME = 'signUp';
let formValues = null;

const messages = defineMessages({
  emailAddress: {
    id: '',
    description: 'remember me text on login page',
    defaultMessage: 'EMAIL ADDRESS',
  },
});

export class SignUpForm extends React.Component {
  static propTypes = {
    siteCode: PropTypes.string.isRequired,
    csrfToken: PropTypes.string,

    // URL used to submit the form via POST
    submitUrl: PropTypes.string.isRequired,

    // URLs displayed to the user as links for Terms/Privacy Policy
    tosUrl: PropTypes.string.isRequired,
    privacyPolicyUrl: PropTypes.string.isRequired,

    // Provided from Redux state by connect
    pending: PropTypes.bool.isRequired,

    // Needed by the GoogleSignInButton component
    isOptedIn: PropTypes.bool.isRequired,

    // Implicitly Accept ToS
    implicitlyOptedIn: PropTypes.bool,

    // Redux dispatcher object, provided from Redux by connect
    dispatch: PropTypes.func.isRequired,

    // required to show dropbox button
    dropboxSSO: PropTypes.bool,

    // Needed by the GoogleSignInButton component
    googleSignInClientId: PropTypes.string.isRequired,

    // Needed for the Invisible ReCaptcha
    recaptchaPublicKey: PropTypes.string.isRequired,
    // Decides whether the CAPTCHA should be displayed initially
    includeInvisibleCaptcha: PropTypes.bool,

    // Used if this is a team invite signup (note: if provided, emailAddress is also required)
    isInvite: PropTypes.bool,
    teamInviteGuid: PropTypes.string,
    teamInviteSenderEmail: PropTypes.string,

    // Email address to pre-fill (required if teamInviteSenderEmail is provided)
    emailAddress: PropTypes.string,
    signupSource: PropTypes.string,

    // We need a logInUrl when we show the sign in link in the footer of this form
    logInUrl: PropTypes.string,

    countryCode: PropTypes.string,

    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);


    this.handleGeneralError = this.handleGeneralError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.resolvedReCaptcha = this.resolvedReCaptcha.bind(this);
    this.getIsOptedIn = this.getIsOptedIn.bind(this);

  }

  componentDidMount() {
    // Map site codes to alt/title text versions of logo
    const textVersions = {
      S: 'HelloSign',
      F: 'HelloFax',
      W: 'HelloWorks',
    };
    hellofaxJS.trackGAPageViewEvent('accountSignUp', null, null, null, textVersions[this.props.siteCode]);
  }

  /**
   * Handles any generic/fallback errors we want to handle
   */
  handleGeneralError() {
    this.props.dispatch(actions.setSubmitFailed(FORM_NAME));
  }

  handleResponse(err, response) {
    // Error here means some kind of network error (4xx or 5xx)
    if (err) {
      return this.handleGeneralError(err);
    }

    try {
      const resp = response.body;

      // Create notification if backend provided one
      if (resp.notification) {
        hellofaxJS.createNotification({
          text: resp.notification.message,
          class_name: resp.notification.type,
        });
      }

      // Handle success and failure
      if (resp.success === true) {
        this.props.dispatch(actions.setSubmitted(FORM_NAME));

        // Redirect to redirect_url
        if (resp.redirect_url) {
          window.location = resp.redirect_url;
        }
      } else {
        this.props.dispatch(actions.setSubmitFailed(FORM_NAME));

        if (resp.display_invisible_captcha) {
          console.log('Enable recaptcha');
          this.props.dispatch(actions.change(`${FORM_NAME}.includeInvisibleCaptcha`, true));
        }

        const hasErrorMsg = !!(resp.error && resp.error.message);
        if (hasErrorMsg && !resp.notification_message) {
          // Display error message
          this.props.dispatch(
            actions.setErrors(FORM_NAME, {
              server: resp.error.message,
            }),
          );
        }
      }

      if (response.error) {
        this.props.dispatch(
          actions.setErrors(FORM_NAME, {
            server: resp.error.message,
          }),
        );
      }
    } catch (e) {
      return this.handleGeneralError(err);
    }
  }

  preSubmit(values) {
    formValues = values;
    if (this.props.includeInvisibleCaptcha) {
      this.recaptcha.execute();
    } else {
      this.handleSubmit(values);
    }
  }

  resolvedReCaptcha(recaptchaToken) {
    this.handleSubmit(formValues, recaptchaToken);
    this.recaptcha.reset();
  }

  handleSubmit(values, recaptchaToken) {
    // Prepare the POST
    const data = stringify({
      signup: {
        email_address: values.email,
        is_opted_in_marketing_emails: values.marketingOptIn,
        redirect_url: values.redirectUrl,
        pending_tsm_group_guid: values.pendingTsmGroupGuid,
        team_invite_guid: this.props.teamInviteGuid,
        _csrf_token: values.csrfToken,
        country_code: this.props.countryCode,
      },
      'g-recaptcha-response': recaptchaToken,
      force_delay_analytics_js: true,
      async: 1,
    });

    // POST to the server
    return superagent
      .post(this.props.submitUrl)
      .type('form')
      .send(data)
      .on('request', () => {
        this.props.dispatch(actions.setPending(FORM_NAME));
      })
      .end(this.handleResponse);
  }

  getIsOptedIn() {
    return this.props.isOptedIn;
  }

  getHeader() {
    let header = <div>
      <h2><FormattedMessage id='' defaultMessage='Create your account' description='sign up page' /></h2>
    </div>;
    if (this.props.signupSource === 'dropbox') {
      header = <div className="row m-sign-up-form--greeting">
        <img src={helloSvg} />
      </div>;
    }
    return header;
  }

  getSignInLink() {
    if (!HfReactHelper.HfSites.isHelloSign(this.props.siteCode) && !this.props.signupSource) {
      return;
    }

    const { logInUrl } = this.props;

    const a = (msg) => (<a href={logInUrl}>{msg}</a>);

    return <div className="m-sign-up-form--sign-in-link">
      <div className="m-sign-up-form--h-divider"></div>
      <p><FormattedMessage id='' defaultMessage='Already have an account? <a>Sign in ›</a>' description='sign up page' values={{ a }} /></p>
    </div>;
  }

  render() {
    const {
      siteCode,
      tosUrl,
      privacyPolicyUrl,
      pending,
      emailAddress,
      teamInviteGuid,
      teamInviteSenderEmail,
      includeInvisibleCaptcha,
      googleSignInClientId,
    } = this.props;
    const isInvite = !!teamInviteGuid;
    const siteName = siteCode == 'F' ? 'HelloFax' : 'HelloSign';
    const formType = 'signup'; // used to associate backend CSRF form origination

    const header = this.getHeader();

    const signInLink = this.getSignInLink();

    // Map site codes to site names
    const siteAbbreviations = {
      S: 'hs',
      F: 'hf',
      W: 'hw',
    };

    const a1 = (msg) => (<a href={tosUrl} target="_blank" rel="noopener noreferrer">{msg}</a>);
    const a2 = (msg) => (<a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">{msg}</a>);

    const googleLoginOr = !isInvite
      && <div>
        <div className="m-create-account--modal-google-login text-center">
          <div className="m-create-account--modal-google-login-border">
            <div className="m-create-account--modal-google-login-or-image" />
          </div>
          <div className="m-create-account--modal-google-login-button">
            <GoogleSignInButton
              clientId={googleSignInClientId}
              getIsOptedIn={this.getIsOptedIn}
              formType={formType}
              csrfToken={this.props.csrfToken}
              buttonAttrs={
                {
                  'da-category': 'signup',
                  'da-action': `create account inapp - ${siteAbbreviations[this.props.siteCode]}`,
                  'da-label': 'sign in with google',
                }
              } />

            {this.props.dropboxSSO ? (
              <div className="l-margin-t-20">
                <DropboxSignInButton />
              </div>
            ) : null }
          </div>
        </div>
        <OrDivider />
      </div>;

    const emailField = !isInvite
      && <Field label={this.props.intl.formatMessage(messages.emailAddress)}>
        <Control.input
          type="email"
          model="signUp.email"
          validators={{ isEmail: HfReactHelper.isValidEmailAddress }}
          value={emailAddress || ''} />
        <Errors
          model="signUp.email"
          show="touched"
          messages={{
            isEmail: <FormattedMessage id='' defaultMessage='Please enter a valid email address' description='sign up page' />,
          }}
          className="m-field--error" />
      </Field>;

    const inviteText = isInvite
      && <div>
        <p className="l-margin-v-20">
          <FormattedMessage id='' defaultMessage="You've been invited by {teamInvitedSenderEmail} to join their team on {domain}"
            description='sign up page'
            values={{ teamInvitedSenderEmail: <strong>{teamInviteSenderEmail}</strong>, domain: siteName }} />
        </p>
        <p className="l-margin-v-20">
          <FormattedMessage id='' defaultMessage="If you accept, we'll create an account for you with the email address {emailAddress} and add you to the team automatically."
            description='sign up page'
            values={{ emailAddress: <strong>{emailAddress}</strong> }} />
        </p>
      </div>;

    const rejectInviteUrl = isInvite
      && HfReactHelper.urlHelper(
        `account/confirmTeamInvite?execute=1&does_accept=0&guid=${teamInviteGuid}`,
      );

    const a3 = (msg) => (<a href={rejectInviteUrl}>{msg}</a>);

    const rejectInviteLink = isInvite
      && <div className="m-sign-up-form--sign-in-link">
        <hr />
        <p>
          <FormattedMessage id='' defaultMessage='You may also <a3>decline this invitation.</a3>'
            description='sign up page'
            values={{ a3 }} />
        </p>
      </div>;

    const InvisibleReCaptcha = includeInvisibleCaptcha
      && <Recaptcha
        ref={(ref) => this.recaptcha = ref}
        sitekey={this.props.recaptchaPublicKey}
        onResolved={this.resolvedReCaptcha} />;

    const MarketingOptIn = !this.props.implicitlyOptedIn
      && <div className='m-sign-up-form--marketing-opt-in'>
        <label>
          <Control.checkbox model="signUp.marketingOptIn" />{' '}
          <FormattedMessage id='' defaultMessage='Send me {domain} updates' description='sign up page' values={{ domain: siteName }} />
        </label>
      </div>;

    return (
      <div className="m-sign-up-form">
        {header}
        {googleLoginOr}
        <Form model={FORM_NAME} onSubmit={this.preSubmit}>
          {emailField}
          {inviteText}
          {InvisibleReCaptcha}
          <Button
            buttonText={
              isInvite
                ? <FormattedMessage id='' defaultMessage='Sign up and join team' description='sign up page' />
                : <FormattedMessage id='' defaultMessage='Create an account' description='sign up page' />
            }
            buttonColor="cerulean"
            type="submit"
            disabled={pending}
            buttonAttrs={
              {
                'data-qa-ref': 'login-button-after-password',
                'da-category': 'signup',
                'da-action': `create account inapp - ${siteAbbreviations[this.props.siteCode]}`,
                'da-label': 'create an account',
              }
            } />

          <div className="l-margin-v-20">
            <Errors
              model={FORM_NAME}
              show={true}
              className="c-red l-margin-v-20" />
          </div>
          {MarketingOptIn}
          <p className="l-margin-t-10 l-margin-b-0 m-sign-up-form--tos">
            <FormattedMessage id='' defaultMessage='By signing up, I accept the {domain} <a1>Terms</a1> & <a2>Privacy Policy.</a2>'
              description='sign up page'
              values={{ domain: siteName, a1, a2 }} />
          </p>
          {rejectInviteLink}
          {signInLink}
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { $form } = state.forms.signUp;

  return {
    emailAddress: state.signUp.email,
    pending: $form.pending,
    isOptedIn: state.signUp.marketingOptIn,
    implicitlyOptedIn: state.signUp.implicitlyOptedIn,
    csrfToken: state.signUp.csrfToken,
    country_code: state.countryCode,
    recaptchaPublicKey: state.signUp.recaptchaPublicKey,
    includeInvisibleCaptcha: state.signUp.includeInvisibleCaptcha,
  };
}

export default connect(mapStateToProps)(injectIntl(SignUpForm));
