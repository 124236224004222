/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  func-names,
  prefer-const,
  prefer-rest-params
*/

/**
 * IE8+ compatible subclassing. See https://babeljs.io/docs/advanced/caveats/
 */

module.exports = function (parent, child) {

  let props;
  let pi;

  let c = child;
  let p = parent;

  if (typeof c === 'function') {
    pi = 2;
  } else {
    if (typeof p === 'object') {
      c = function () { p.apply(this, arguments); };
      pi = 0;
    } else {
      c = p || function () { };
      pi = 1;
    }

    p = typeof this === 'function' ? this : Object;
  }

  props = Array.prototype.slice.call(arguments, pi);

  function Ctor() {
    this.constructor = c;
  }

  Object.assign(c, p); // copy static props

  Ctor.prototype = p.prototype;
  c.prototype = new Ctor();

  Object.assign(c.prototype, Object.assign(...[{}].concat(props)));

  return c;
};
