/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  guard-for-in,
  import/no-extraneous-dependencies,
  max-len,
  no-plusplus,
  no-restricted-syntax,
  one-var,
  prefer-const,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import PaymentModal from './payment-modal';
import Constants from './constants';
import EnterpriseAgreementPricingSummary from './enterprise-agreement-pricing-summary';

const EnterpriseAgreement = createReactClass({

  propTypes: {
    fullName: PropTypes.string, // Full name of the person agreeing
    email: PropTypes.string.isRequired, // Their email address
    companyName: PropTypes.string, // Their company name
    billingName: PropTypes.string, // Optional billing contact name, if different
    billingEmail: PropTypes.string, // Optional billing contact email, if different
    currentNumSeats: PropTypes.number.isRequired, // Current number of seats on the plan
    numSeats: PropTypes.number.isRequired, // Number of seats on the plan
    totalRate: PropTypes.string.isRequired, // Total rate (formatted)
    periodName: PropTypes.string.isRequired, // Billing period (month or year)
    features: PropTypes.array.isRequired, // List of features { name, [code], [value], enabled }
    paymentForm: PropTypes.object.isRequired, // Serialized payment form
    paymentInfo: PropTypes.object.isRequired, // Payment information { card, subscription, ... }
    siteCode: PropTypes.string.isRequired, // Site code
    isApi: PropTypes.bool.isRequired, // Whether it's for an API plan
    termsAccepted: PropTypes.bool, // If they've already accepted the terms
    numApiSigRequests: PropTypes.number, // Number of API signature requests
    apiQuotaPeriodName: PropTypes.string, // API quota refresh period (month or year)
    couponInfo: PropTypes.object, // API coupon information { percentage, duration }
    isFreeTrial: PropTypes.bool, // Whether to give the user a free trial
    numFreeTrialDays: PropTypes.number,
    billingType: PropTypes.string,
    billingMethod: PropTypes.string,
    tosUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
  },

  getDefaultProps() {
    return {
      termsAccepted: false,
    };
  },

  continueClicked(evt) {
    if (this.isAlternateBilling()) {
      this.submitForm();
    } else {
      this.launchPaymentModal(evt);
    }
  },

  isAlternateBilling() {
    return (Constants.ALTERNATIVE_BILLING_TYPES.filter((type) => type === this.props.billingType).length > 0);
  },

  launchPaymentModal(evt) {

    const target = $(evt.target);
    if (!target.hasClass('m-button') && target.parents('.m-button').length === 0) {
      return;
    }

    evt.preventDefault();

    const attachPoint = HfReactHelper.getRevealAttachPoint('payment_modal', this.props.paymentInfo.card ? 'small' : 'medium');
    const d = this.props.paymentInfo;
    const cc = d.card;
    const s = d.subscription;
    const n = this.props.numSeats;
    const cn = this.props.currentNumSeats;

    let cardholder = '';
    if (cc) {
      cardholder = cc.holderName;
    } else if (this.props.billingName && (this.props.billingEmail !== this.props.email)) {
      cardholder = this.props.billingName;
    }

    // NOTE: isFreeTrial will force free-trial eligibility for enterprise customers, if it's been set
    ReactDOM.render(
      (<PaymentModal
        key={`paymentModal_${d.planId}`}
        planId={d.planId}
        planName={this.props.planName}
        isFreeTrialEligible={this.props.isFreeTrial}
        forceFreeTrial={this.props.isFreeTrial}
        numFreeTrialDays={this.props.numFreeTrialDays}
        price={d.price}
        timePeriod={s.timePeriod}
        monthlyExpectedRate={s.monthlyExpectedRate * n}
        annualExpectedRate={s.annualExpectedRate * n}
        monthlyProratedRate={s.monthlyProratedRate * n}
        annualProratedRate={s.annualProratedRate * n}
        currentMonthlyRate={s.currentMonthlyRate * cn}
        currentAnnualRate={s.currentAnnualRate * cn}
        upgradeCharge={s.upgradeCharge}
        proratio={s.proratio}
        couponCode={s.couponCode}
        ccSuffix={cc ? cc.suffix : ''}
        ccExpiration={cc ? cc.expiration : ''}
        cardHolderName={cardholder}
        name={d.name}
        isTrialing={d.isTrialing || false}
        isApi={this.props.isApi}
        isEnterprise={true}
        paymentForm={this.props.paymentForm}
        numSeats={this.props.numSeats}
        headerText='Enterprise Plan'
        tosUrl={this.props.tosUrl}
        refundPolicyUrl={this.props.refundPolicyUrl}
        privacyPolicyUrl={this.props.privacyPolicyUrl} />
      ), attachPoint,
    );
  },

  submitForm() {
    $('#agreementForm').submit();
  },

  render() {

    let name,
      company,
      onBehalfOf;
    let continueButton,
      ctaSubText;
    let paymentModal;
    const features = [];
    const hasSeparateBillingContact = (this.props.billingEmail && this.props.billingEmail !== this.props.email);

    // Header
    if (this.props.fullName || this.props.billingName) {
      name = (
        <div className='m-plan-agreement--header--name'>
          { hasSeparateBillingContact ? this.props.billingName : this.props.fullName }
        </div>
      );
    }
    if (this.props.companyName) {
      company = (
        <div className='m-plan-agreement--header--company'>{ this.props.companyName }</div>
      );
    }
    if (hasSeparateBillingContact) {
      onBehalfOf = (
        <div className='m-plan-agreement--header--on-behalf-of'>
                    On behalf of { this.props.fullName } ({ this.props.email })
        </div>
      );
    }

    // Amount of seats (UI) or signature requests (API)
    const planDetailText = this.props.isApi
      ? `${this.props.numApiSigRequests} signature requests per ${
        this.props.apiQuotaPeriodName
      }`
      : `${this.props.numSeats} senders`;
    const planDetail = (
      <p>
        <strong>{ planDetailText }</strong>
      </p>
    );

    // Features
    let i = -1;
    const n = this.props.features.length;
    let feature,
      featureClasses,
      featureValue,
      hasValue;
    let hasHipaa = false;
    for (const k in this.props.features) {
      feature = this.props.features[k];
      hasValue = false;
      if (feature.enabled === undefined || feature.enabled === true) {
        if (!hasHipaa && feature.code === HfReactHelper.HfConstants.enterpriseFeatures.HIPAA) {
          hasHipaa = true;
        }
        hasValue = (feature.value !== undefined);
        featureClasses = classNames({
          'm-plan-agreement--features--table--item': true,
          'with-value': hasValue,
          'is-left': (++i % 2 === 0),
          'is-bottom': ((n % 2 === 0 && i >= n - 2) || (n % 2 === 1 && i === n - 1)),
          'is-top': (i < 2),
        });
        featureValue = hasValue ? (
          <div className='m-plan-agreement--features--table--item--value'>{ feature.value === null ? 'UNLIMITED' : feature.value }</div>
        ) : undefined;
        features.push((
          <div key={`feature-${feature.name}`} className={featureClasses}>
            { featureValue }
            <div className='m-plan-agreement--features--table--item--name'>{ feature.name }</div>
          </div>
        ));
      }
    }

    // Continue button
    continueButton = (
      <Button
        key='agreementContinueButton'
        buttonText='Continue'
        buttonLink='#'
        buttonColor='cerulean'
        buttonTextColor='white' />
    );

    // HIPAA warning
    if (hasHipaa) {
      const siteName = HfReactHelper.HfSites.getSiteName(this.props.siteCode);
      ctaSubText = `${'Upon payment, your account will be configured for HIPAA compliance. '
                         + 'It is your responsibility to continue to use '}${siteName} in a HIPAA compliant manner.`;
    }

    return (
      <div className='m-plan-agreement small-12'>

        <div className='m-plan-agreement--header l-margin-b-40'>
          <div className='m-plan-agreement--header--title'>
                        PLAN DETAILS
          </div>
          { name }
          <div className='m-plan-agreement--header--email'>
            { hasSeparateBillingContact ? this.props.billingEmail : this.props.email }
          </div>
          { onBehalfOf }
          { company }
          { planDetail }
        </div>

        <div className='m-plan-agreement--summary l-margin-b-40 small-12'>
          <EnterpriseAgreementPricingSummary
            couponInfo={this.props.couponInfo}
            totalRate={this.props.totalRate}
            discountedRate={this.props.discountedTotalRate}
            periodName={this.props.periodName}
            isFreeTrial={this.props.isFreeTrial}
            numFreeTrialDays={this.props.numFreeTrialDays}
            billingType={this.props.billingType}
            billingMethod={this.props.billingMethod} />
        </div>

        <div className='m-plan-agreement--features small-12 l-margin-b-40'>
          <div className='m-plan-agreement--features--title'>FEATURES</div>
          <div className='m-plan-agreement--features--table'>
            { features }
          </div>
        </div>

        <div className='m-plan-agreement--cta small-12'>
          <div onClick={this.continueClicked}>
            { continueButton }
          </div>
          <div className='m-plan-agreement--cta--text'>{ ctaSubText }</div>
        </div>

        <form id='agreementForm' method='POST' action=''></form>

        { paymentModal }

      </div>
    );

  },

});

module.exports = EnterpriseAgreement;
