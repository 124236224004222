/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  array-callback-return,
  func-names,
  import/no-extraneous-dependencies,
  no-plusplus,
  no-void
*/
import React from 'react';
import PropTypes from 'prop-types';
import baseform from 'common/components/base-form';
import Button from 'common/components/button';
import Logger from 'common/logger';
import StringHelper from 'common/utils/string-helpers';

const CollectInfoForm = baseform.createFormClass({

  displayName: 'CollectInfoForm',

  propTypes: {
    tabOffset: PropTypes.number,
    emailAddress: PropTypes.string,
    fields: PropTypes.array,
    values: PropTypes.object,
  },

  getInitialState() {
    return {
      emailAddress: this.props.emailAddress,
      fieldMap: this.mapFieldsToNames(),
    };
  },

  // Creates a named map of field names so you can find by name, instead of just array index
  mapFieldsToNames() {
    if (!this.props.fields || !this.props.fields.length) return {};

    return this.props.fields.reduce((map, field) => {
      if (field.type !== 'inputhidden') {
        map[field.name] = field;
      }
      return map;
    }, {});
  },

  isRequired(field) {
    const classAttribute = field.attributes.className || field.attributes.class || '';
    return classAttribute.indexOf('required') >= 0;
  },

  fieldnameHasErrors(fieldname) {
    return this.state.hasErrors && fieldname in this.state.errors.named;
  },

  renderErrorsForFieldname(fieldname) {
    let errors;

    if (this.fieldnameHasErrors(fieldname)) {
      errors = <div className='error'>
        <p>This field is required</p>
      </div>;
    }

    return errors;
  },

  trackNumberFilledFields() {
    const nodes = document.querySelectorAll('input[type="text"], select');
    let filled = 0;
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].value && nodes[i].value !== '0') { // 0 is a placeholder for our dropdowns
        filled++;
      }
    }
    Logger.track(`CollectInfoModal.filled-${filled}`);
  },

  onSubmit(evt) {
    evt.preventDefault();

    // Check to make sure we filled in: First Name, Last Name, Company before submitting
    const firstNameVal = this.getDOMValueForFieldname('first_name');
    const lastNameVal = this.getDOMValueForFieldname('last_name');
    const companyNameVal = this.getDOMValueForFieldname('company_name');

    const namedErrors = {};
    // Validation
    if (!firstNameVal) {
      namedErrors.first_name = 'This field is required';
    }
    if (!lastNameVal) {
      namedErrors.last_name = 'This field is required';
    }
    if (!companyNameVal) {
      namedErrors.company_name = 'This field is required';
    }

    if (Object.keys(namedErrors).length) {
      this.setState({
        errors: { named: namedErrors },
        hasErrors: true,
      });
      return;
    }

    this.setState({
      errors: null,
      hasErrors: false,
    });

    this.trackNumberFilledFields();
    Logger.track('CollectInfoModal.submitted');

    this.submitAsync(function (resp) {
      if (resp.success) {
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      }

    });
  },

  render() {

    const initialTabValue = this.props.tabOffset || 0;
    let tabOffset = parseInt(initialTabValue, 10);

    const columnClasses = 'columns large-6 medium-6 small-12';
    const fullwidthClasses = 'columns large-12 medium-12 small-12';

    const fields = {};
    const fieldKeys = Object.keys(this.state.fieldMap); // Because Object.forEach is not available
    fieldKeys.map((key) => {
      const field = this.state.fieldMap[key];

      const options = {
        tabIndex: field.attributes.tabindex || ++tabOffset,
        className: this.fieldnameHasErrors(field.name) ? 'error' : void 0,
        defaultValue: field.default,
      };

      if (field.type === 'input') {
        options.placeholder = field.options.label;
        options['data-format'] = 'text';
      } else if (field.type === 'select') {
        options['data-format'] = 'select';
      }

      let asterisk;
      if (this.isRequired(field)) {
        asterisk = <span className='asterisk'>*</span>;
        options.required = 'required';
      }

      // convert snake_case to kebab-case
      options['data-qa-ref'] = StringHelper.toKebabCase(field.name);

      // Currently one place where we want to use a fullwidth field; the rest are half
      const clazz = field.name === 'use_case' ? fullwidthClasses : columnClasses;
      fields[key] = (
        <div className={clazz}>
          <label>{ field.options.label }{ asterisk }</label>
          { this.renderField(field.name, options) }
          { this.renderErrorsForFieldname(field.name) }
        </div>);
    });

    const ctaBar = (
      <div className='cta-bar'>
        <Button
          buttonText='Done'
          buttonTabIndex={10}
          onClick={this.onSubmit}
          buttonColor='grasshopper'
          type='submit'
          buttonAttrs={
                        { 'data-qa-ref': 'done-button' }
                    } />
      </div>
    );

    return (
      <form className='m-collect-info-form'>
        <div className='m-collect-info-form--field-container'>
          <div className='row'>
            { fields.first_name }
            { fields.last_name }
          </div>
          <div className='row'>
            { fields.job_title }
            { fields.department }
          </div>
          <div className='row'>
            { fields.company_name }
            { fields.industry }
          </div>
          <div className='row'>
            { fields.use_case }
          </div>
          { this.renderHiddenFields() }
        </div>
        { ctaBar }
      </form>
    );
  },
});

export default CollectInfoForm;
