/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const LogInCaptcha = createReactClass({

  propTypes: {
    recaptchaPublicKey: PropTypes.string.isRequired,
    tabIndex: PropTypes.number,
  },

  componentDidMount() {
    // We need to determine if recaptcha has already loaded or if we need to wait for it
    if (window.grecaptcha) {
      this.recaptchaDidLoad();
    } else {
      // Register for an event letting us know that it has been loaded, and then do the thing
      window.addEventListener('hs-recaptcha-did-load', this.recaptchaDidLoad);
    }
  },

  recaptchaDidLoad() {
    if (window.grecaptcha) {
      // Render the captcha
      const container = document.querySelector('.g-recaptcha');
      grecaptcha.render(
        container,
        {
          sitekey: this.props.recaptchaPublicKey,
          theme: 'custom',
        },
      );
    }
  },

  captchaReload(e) {
    e.preventDefault();
    if (window.grecaptcha) {
      grecaptcha.reset();
    }
  },

  render() {
    return (
      <div id='recaptcha_widget' className='m-login--captcha'>
        <div className='g-recaptcha'
          data-sitekey={this.props.recaptchaPublicKey}
          data-theme='custom'
        >
        </div>
      </div>
    );
  },
});

module.exports = LogInCaptcha;
