/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  react/no-unescaped-entities
*/
import React from 'react';
import DocumentHeader from './document-header';

const Space = <u>{'\u00a0'.repeat(10) }</u>;
const DocumentBody = <div className='m-document-body'>
  <h1>NON-DISCLOSURE AGREEMENT</h1>
  <p>
    THIS AGREEMENT (the "Agreement") is entered into on this
    {Space} day of {Space} by and between {Space}, located at
    {Space} (the "Disclosing Party"), and {Space} with and
    address at {Space} (the "Recipient" or the "Receiving
    Party").
  </p>
  <p>
    The Recipient hereto desires to participate in discussions
    regarding {Space} (the "Transaction").During these
    discussions, Disclosing Party may share certain proprietary
    information with the Recipient.Therefore, in consideration of
    the mutual promises and covenants contained in this
    Agreement, and other good and valuable consideration, the
    receipt and sufficiency of which is hereby acknowledged, the
    parties hereto agree as follows:
  </p>
</div>;

class DocumentUI extends React.Component {
  render() {
    return <section className='m-document-container'>
      <h2>
        <img src={require('./assets/1.png')} />
        <span>First, select an area or text below.</span>
        <img src={require('./assets/arrow_1.png')} className='arrow-1' />
      </h2>
      <div className='m-document'>
        <div className='m-document-inner'>
          <DocumentHeader {...this.props} />
          {DocumentBody}
        </div>
      </div>
    </section>;
  }
}
module.exports = DocumentUI;
