/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './plan-choice-area-v2.scss';
import React from 'react';
import PropTypes from 'prop-types';
import SmallToggle from 'common/components/small-toggle';
import TeamSizeSelect from './team-size-select';
import PlanCard from './plan-card';
import FreePlanLink from './free-plan-link';
import EnterprisePlanCustomerCallout from './enterprise-plan-customer-callout';


export default class PlanChoiceAreaV2 extends React.Component {

    static propTypes = {
      planData: PropTypes.array.isRequired,
      timePeriod: PropTypes.string,
      teamSize: PropTypes.string,
      onPlanChoice: PropTypes.func,
      onEnterpriseSuccess: PropTypes.func,
      onTimeToggle: PropTypes.func,
      onTeamSizeSelect: PropTypes.func,
    };

    toggleTime = () => {
      let value;
      if (this.props.timePeriod === 'M') {
        value = 'Y';
      } else {
        value = 'M';
      }
      this.props.onTimeToggle(value);
    };

    render() {

      const teamSize = this.props.teamSize;
      const annual = this.props.timePeriod === 'Y';

      let toggleAreaMiddle;
      if (teamSize === 'large-team') {
        toggleAreaMiddle = (
          <div className='middle placeholder'>
            <div className='line'></div>
          </div>
        );
      } else {
        toggleAreaMiddle = (
          <div className='middle'>
            <SmallToggle
              on={annual}
              onClick={this.toggleTime}
              offLabel='MONTHLY'
              onLabel='ANNUAL'
            >
            </SmallToggle>
          </div>
        );
      }

      let timeToggleArea;
      if (teamSize) {
        timeToggleArea = (
          <div className='time-toggle-area'>
            <div className='side'>
              <div className={teamSize === 'individual' ? 'line pointer leftside' : 'line'}></div>
            </div>
            { toggleAreaMiddle }
            <div className='side'>
              <div className={teamSize === 'large-team' ? 'line pointer rightside' : 'line'}></div>
            </div>
          </div>
        );
      }

      let planContent;
      let plans;
      switch (teamSize) {

        case 'individual':
          plans = this.props.planData.filter((plan) => plan.name === 'Pro');
          planContent = (
            <div className="one-up">
              <PlanCard
                plan={plans[0]}
                period={this.props.timePeriod}
                onPlanChoice={this.props.onPlanChoice}
              >
              </PlanCard>
              <FreePlanLink></FreePlanLink>
            </div>
          );
          break;

        case 'small-team':
          plans = this.props.planData.filter((plan) => plan.name.indexOf('Business') > -1);
          planContent = (
            <div className='two-up'>
              <PlanCard
                plan={plans[0]}
                period={this.props.timePeriod}
                onPlanChoice={this.props.onPlanChoice}
              >
              </PlanCard>
              <PlanCard
                plan={plans[1]}
                period={this.props.timePeriod}
                onPlanChoice={this.props.onPlanChoice}
              >
              </PlanCard>
            </div>
          );
          break;

        case 'large-team':
          plans = this.props.planData.filter((plan) => plan.name === 'Enterprise');
          planContent = (
            <div className='wide-card'>
              <PlanCard
                plan={plans[0]}
                period={this.props.timePeriod}
                onEnterpriseSuccess={this.props.onEnterpriseSuccess}
              >
              </PlanCard>
              <EnterprisePlanCustomerCallout></EnterprisePlanCustomerCallout>
            </div>
          );
          break;

        default:
                // No content

      }

      return (
        <div className='plan-choice-area-v2'>
          <TeamSizeSelect
            selected={teamSize}
            onTeamSizeSelect={this.props.onTeamSizeSelect} />
          { timeToggleArea }
          { planContent }
        </div>
      );
    }
}
