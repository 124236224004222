/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';

class GoogleLoginOrSignup extends React.Component {

  constructor(props) {
    super(props);

    this.handleButtonPress = this.handleButtonPress.bind(this);
  }

    /**
     * React prop type definitions.
     *
     * @static
     */
    static propTypes = {
      redirectUrl: PropTypes.string,
      isLogIn: PropTypes.bool,
      isOptedIn: PropTypes.bool,
    };

    /**
     * Called when the Google login or signup button is pressed.
     *
     * @private
     */
    handleButtonPress() {
      let url = `/account/openid/connect/google?optIn=${this.props.isOptedIn ? '1' : '0'}`;
      if (typeof this.props.redirectUrl === 'string' && this.props.redirectUrl.length > 0) {
        url = `${url}?on_login_redirect_url=${window.btoa(this.props.redirectUrl)}`;
      }
      window.hsGoogleOpenIdWindow = window.open(url, '_blank', 'width=640, height=480');
    }

    /**
     * Get attribute for selenium based on page
     *
     * @returns {string}
     * @private
     */
    get qaAttribute() {
      const pathname = window.location.pathname;
      let qaAttr = '';

      if (pathname.indexOf('signUp') >= 0) {
        qaAttr = 'sign-in-with-google-button-sign-up-page';
      } else if (pathname.indexOf('logIn') >= 0) {
        qaAttr = 'sign-in-with-google-button';
      }

      return qaAttr;
    }

    /**
     * Renders the React element into the DOM.
     *
     * @returns {HTMLElement}
     * @override
     */
    render() {
      return (
        <a className="m-google-button" onClick={this.handleButtonPress} data-qa-ref={this.qaAttribute} />
      );
    }
}

export default GoogleLoginOrSignup;
