/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-extraneous-dependencies
*/
import StyleProvider from 'hello-ui/style';
import React from 'react';
import hellosignTheme from './hellosign.theme';

export default class HsStyleProvider extends React.Component {
  render() {
    return <StyleProvider theme={hellosignTheme}>{this.props.children}</StyleProvider>;
  }
}
