/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/no-commonjs,
  global-require
*/
import './white-labeling.scss';
import cx from 'classnames';
import React from 'react';
import WhiteLabelingApp from 'hellosign/components/api/white-labeling';
import MarketoForm from 'hellosign/components/marketo-form';

module.exports = class WhiteLabelingLandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contactUsModalIsShown: false,
    };
  }

  componentDidMount() {

    const q = document.location.search;
    if (q && q.indexOf('aliId=') >= 0) {
      document.location.hash = '';
      this.closeContactUsModal();
    }

    this.timerId = setInterval(() => {
      const h = document.location.hash;
      if (h === '#contact-us') {
        this.openContactUsModal();
      }
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  openContactUsModal() {
    this.setState({
      contactUsModalIsShown: true,
    });
  }

  closeContactUsModal() {
    this.setState({
      contactUsModalIsShown: false,
    });
  }

  render() {

    const modalClassNames = cx({
      'm-white-labeling--contact-modal': true,
      'is-shown': (this.state.contactUsModalIsShown === true),
    });

    const contactUsModal = (
      <div className={modalClassNames}>
        <div className='m-white-labeling--contact-modal--overlay'></div>
        <div className='m-white-labeling--contact-modal--form'>
          <div className='m-white-labeling--contact-modal--form--header'>Contact us</div>
          <a href='#'
            onClick={this.closeContactUsModal.bind(this)}
            className='m-white-labeling--contact-modal--form--close'
          >
                        x
          </a>
          <MarketoForm formId={1139} trackingId={'whitelabel-contactus'} />
        </div>
      </div>
    );

    return <div className='m-white-labeling'>

      <header>
        <h1><span className='m-white-labeling-try'>Try </span>HelloSign&apos;s API White Labeling</h1>
        <h2>Our signer page is customizable so it matches your color scheme exactly.</h2>
        <a href='#contact-us'>
          <button className='bg-cerulean c-white hc-white hbg-velvet-evening'>Contact Us</button>
        </a>
      </header>

      <main>

        { contactUsModal }

        <WhiteLabelingApp />

        <div className='m-white-labeling-copy'>

          <div className='m-white-labeling-bulb-computer'>
            <img
              src={require('../../assets/bulb_icon.png')}
              aria-hidden={true}
              className='white-labeling-bulb-icon' />
            <span>To try the interactive demo, please use your computer.</span>
          </div>

          <div className='m-white-labeling-copy-text'>
            <p>
                          We&apos;re dedicated to powering effortless document workflows from
                          end-to-end. White labeling is just one feature empowering you to
                          make your product or service easier for your users.
            </p>
            <p>
                          HelloSign&apos;s white labeling feature allows API users to customize a
                          signing flow with their branding, colors, and logo. That means
                          your signers/users can seamlessly complete forms directly on your
                          site. HelloSign is the only eSignature provider offering this
                          level of flexibility in customization.
            </p>
            <p>
                          Connect with sales to learn more about adding this feature or
                          explore the <a href='/api/documentation'>API documentation</a>.
            </p>
          </div>

          <div className='m-white-labeling-copy-cta'>
            <div className='quote-and-documentation'>
              <div className='quote-container'>
                <h2>Let&apos;s get you set up.</h2>
                <div className='get-a-quote' id='m-get-a-quote'>
                  <h3>
                    <span className='mobile-cta'>SEE A DEMO</span>
                    <span className='regular-cta'>GET A QUOTE</span>
                  </h3>
                  <MarketoForm formId={1157} trackingId={'whitelabel-quote'} />
                </div>
              </div>
              <div className='documentation'>
                <div className='see-documentation'>
                  <h3>SEE DOCUMENTATION</h3>
                  <button className='bg-cerulean c-white hc-white hbg-velvet-evening'>
                    <a href='/api/reference#WhiteLabeling'>TAKE ME THERE</a>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </main>

    </div>;
  }
};
