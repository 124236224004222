/* If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/* eslint-disable
  import/extensions,
  no-nested-ternary,
  no-return-assign,
*/
import React from 'react';
import HelloModal from 'common/components/hello-modal';
import uuid from 'uuid/v4';

import { intl } from 'hellospa/components/hs-intl-provider';
import { defineMessages } from 'react-intl';

import AttachmentModal from './attachment-modal.jsx';

import disabledSVG from './Attachment Icon - Disabled.svg';
import enabledSVG from './Attachment Icon - Enabled.svg';
import activeSVG from './Attachment Icon - Active.svg';
import './attachment-button.scss';

const enforceDefaults = (attachment) => ({
  id: uuid(),
  name: '',
  instructions: '',
  required: false,
  ...attachment,
});

export default class AttachmentButton extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      attachments: [
        ...props.attachments,
      ].map(enforceDefaults),
      editOpen: false,
      showUpsell: false,
    };
  }

    handleClick = (event) => {
      event.preventDefault();

      if (this.props.enabled) {
        this.setState({ editOpen: true });
      } else {
        this.setState({ showUpsell: true });
      }
    };

    setAttachments = (attachments) => this.setState({ attachments });

    handleRequestClose = () => this.setState({ editOpen: false });

    handleUpsellClose = () => this.setState({ showUpsell: false });

    render() {
      const {
        enabled, tsmGroupFormId, signer, pricingUrl, readOnly, showIcon,
      } = this.props;

      if (!showIcon) {
        return null;
      }

      const icon = (enabled
        ? (this.state.attachments.length > 0
          ? activeSVG
          : enabledSVG)
        : disabledSVG
      );

      const attachmentFields = this.state.attachments.map((a) => (
        <React.Fragment key={a.id}>
          <input type="hidden" name={`${tsmGroupFormId}[attachments][${a.id}][name]`} value={a.name} />
          <input type="hidden" name={`${tsmGroupFormId}[attachments][${a.id}][instructions]`} value={a.instructions} />
          <input type="hidden" name={`${tsmGroupFormId}[attachments][${a.id}][signer_index]`} value={signer} />
          <input type="hidden" name={`${tsmGroupFormId}[attachments][${a.id}][required]`} value={a.required} />
        </React.Fragment>
      ));
      const messages = defineMessages({
        title: {
          id: '',
          description: 'title of the pop up modal on request attachments',
          defaultMessage: 'Request Attachments',
        },
        body: {
          id: '',
          description: 'body content of the pop up modal on request attachments',
          defaultMessage: 'Here you can request the signer to attach files when they sign (such as a copy of their photo ID). This feature is only available with our Enterprise plan.',
        },
        button: {
          id: '',
          description: 'view plans and pricing button of the pop up modal on request attachments',
          defaultMessage: 'View plans and pricing',
        },
      });
      const titleLabelText = intl.formatMessage(messages.title);
      const buttonText = intl.formatMessage(messages.button);
      const modalBodyText = intl.formatMessage(messages.body);

      return (
        <React.Fragment>
          <img className="attachment-button__icon" onClick={this.handleClick} src={icon} />

          {(this.state.editOpen ? (
            <AttachmentModal
              ref={(m) => this.modal = m}
              readOnly={readOnly}
              attachments={this.state.attachments}
              setAttachments={this.setAttachments}
              onRequestClose={this.handleRequestClose} />
          ) : null)}

          <HelloModal
            shouldCloseOnOverlayClick={false}
            isOpen={this.state.showUpsell}
            onRequestClose={this.handleUpsellClose}
            buttons={[
              {
                type: 'primary',
                text: titleLabelText,
                className: 'attachment-modal__save-button',
                buttonLink: pricingUrl,
              },
            ]}
            contentLabel={buttonText}
          >
            {modalBodyText}
          </HelloModal>

          {attachmentFields}
        </React.Fragment>
      );
    }
}
